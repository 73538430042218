import React, { useEffect, useState } from 'react';
import { Fade, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(196, 196, 196, 0.3)',
    zIndex: 9999,
  },
  container: {
    borderRadius: '10px',
    backgroundColor: '#3f002c',
    padding: '16px 20px 16px 20px',
  },
  text: {
    color: '#ffffff',
    fontSize: '12px',
  },
});

function SearchMapLoadingOverlay({
  isMapLoading,
  minShowTimeMs = 2000,
}: {
  isMapLoading: boolean;
  minShowTimeMs: number;
}) {
  const classes = useStyles();

  const [showTimeCompleted, setShowTimeCompleted] = useState(!isMapLoading);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowTimeCompleted(true);
    }, minShowTimeMs);

    return () => clearInterval(interval);
  }, [minShowTimeMs]);

  const show = isMapLoading || !showTimeCompleted;

  return (
    <Fade in={show}>
      <div className={classes.root}>
        <div className={classes.container}>
          <Typography className={classes.text}>Loading map...</Typography>
        </div>
      </div>
    </Fade>
  );
}

export { SearchMapLoadingOverlay };
