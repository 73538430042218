import { GQL, gql, paginate, AllAssigned } from 'src/utils/recoil/gql';
import * as T from './__generated__';

const keyFor = (txt: string) => `CORE-PROSPECT/GEOGRAPHY-PICKER/MSA/${txt}`;

const MSAQuery = gql`
  query msa($msa_name: String!, $offset: Int!, $limit: Int!) {
    usa_msa_census_boundary(
      order_by: { msa_name: asc }
      where: { msa_name: { _is_null: false, _ilike: $msa_name } }
      offset: $offset
      limit: $limit
    ) {
      msa_name
      cbsa_code: cbsa_fips_code
      geometry: cbsa_geom
    }
  }
` as GQL<AllAssigned<T.MsaQuery>, T.MsaQueryVariables>;

export const paginatedMSA = paginate({
  key: keyFor('PAGINATED-MSA'),
  query: MSAQuery,
  fieldName: 'usa_msa_census_boundary',
});
