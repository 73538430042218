import React, { Suspense } from 'react';

import LotPageLink from './LotPageLink';
import { goToUsaLotPage } from '../utils';
import { getMaskedValue } from 'src/utils/mask';

import PropertyLists from './PropertyLists';
import { cherrePropertyUseHierarchical } from 'src/const/lookups';
import { Loadable } from 'recoil';
import { LookupsByTableNamesSelector } from 'src/hooks/useLookupsByTableName';
import { DealColumn } from './DealColumn';
import { EditDealButton } from './EditDealButton';
import { DealsPortfolioAssetsByTaxId } from 'src/services/Dealcloud/hooks/useAllDealsPortfolioAssetsByTaxId';
import { DropboxIcon } from 'src/components/ui/icons/DropboxIcon';
import { IconButton } from '@material-ui/core';

const mortgagePrefix = `Last Mortgage`;

const getColumns = ({
  lookupsByTableNames,
  deals,
  dealsInfo,
}: {
  lookupsByTableNames: Loadable<typeof LookupsByTableNamesSelector>;
  deals?: boolean;
  dealsInfo?: DealsPortfolioAssetsByTaxId;
}) =>
  [
    {
      label: 'Address',
      dataKey: 'address',
      isSortable: true,
      exportCellRenderer: ({ cellData }) => cellData,
      cellRenderer: ({ cellData, rowData }) => {
        if (rowData.cherre_parcel_id && rowData.tax_assessor_id) {
          return (
            <LotPageLink
              value={cellData}
              onClick={async (e) => {
                e.preventDefault();

                goToUsaLotPage(
                  rowData.cherre_parcel_id,
                  rowData.tax_assessor_id
                );
              }}
            />
          );
        }

        return <span>{cellData}</span>;
      },
      width: 250,
      isResizable: true,
    },
    {
      label: 'City',
      dataKey: 'city',
      isSortable: true,
      width: 150,
      isResizable: true,
    },
    {
      label: 'State',
      dataKey: 'state',
      isSortable: true,
      width: 75,
      isResizable: true,
    },
    {
      label: 'Zip',
      dataKey: 'zip',
      isSortable: true,
      width: 75,
      isResizable: true,
      align: 'right',
    },
    {
      label: 'Portfolio',
      dataKey: 'deal_portfolio',
      width: 220,
      isResizable: true,
      align: 'left',
      hidden: !deals,
      exportCellRenderer: ({ rowData }) => {
        const deal = dealsInfo?.[rowData.tax_assessor_id];
        return deal && Number(deal.deal.numberOfAssets) > 1
          ? deal.deal.dealName
          : '';
      },
      cellRenderer: ({ rowData }) => (
        <Suspense fallback={null}>
          <DealColumn taxAssessorId={rowData.tax_assessor_id} column='name' />
        </Suspense>
      ),
    },
    {
      label: 'Building Area (sqft)',
      dataKey: 'building_sq_ft',
      width: 170,
      isResizable: true,
      isSortable: true,
      align: 'right',
      cellRenderer: ({ cellData }) => {
        return getMaskedValue(cellData, 'number');
      },
      exportCellRenderer: ({ cellData }) => cellData,
    },
    {
      label: 'Deal Description',
      dataKey: 'deal_description',
      width: 180,
      isResizable: true,
      align: 'left',
      hidden: !deals,
      exportCellRenderer: ({ rowData }) => {
        const deal = dealsInfo?.[rowData.tax_assessor_id];
        return deal?.deal.description || '';
      },
      cellRenderer: ({ rowData }) => (
        <DealColumn
          taxAssessorId={rowData.tax_assessor_id}
          column='description'
        />
      ),
    },
    {
      label: 'Deal Stage',
      dataKey: 'deal_stage',
      width: 150,
      isResizable: true,
      hidden: !deals,
      exportCellRenderer: ({ rowData }) => {
        const deal = dealsInfo?.[rowData.tax_assessor_id];
        return deal?.deal.stage || '';
      },
      cellRenderer: ({ rowData }) => (
        <Suspense fallback={null}>
          <DealColumn taxAssessorId={rowData.tax_assessor_id} column='stage' />
          <div style={{ marginLeft: '5px' }}>
            <EditDealButton
              cherreParcelId={rowData.cherre_parcel_id}
              taxAssessorId={Number(rowData.tax_assessor_id)}
            />
          </div>
        </Suspense>
      ),
    },
    {
      label: 'Sourcing Method',
      dataKey: 'deal_sourcing_method',
      width: 150,
      isResizable: true,
      align: 'left',
      hidden: !deals,
      cellRenderer: ({ rowData }) => {
        const deal = dealsInfo?.[rowData.tax_assessor_id];
        return deal?.deal.sourcingMethod || '';
      },
    },
    {
      label: 'Deadline',
      dataKey: 'deal_deadline',
      width: 100,
      isResizable: true,
      align: 'left',
      hidden: !deals,
      exportCellRenderer: ({ rowData }) => {
        const deal = dealsInfo?.[rowData.tax_assessor_id];
        return deal?.deal.deadline || '';
      },
      cellRenderer: ({ rowData }) => {
        const deal = dealsInfo?.[rowData.tax_assessor_id];
        return getMaskedValue(deal?.deal.deadline || '', 'date');
      },
    },
    {
      label: 'Latest Note',
      dataKey: 'deal_note',
      width: 180,
      isResizable: true,
      align: 'left',
      hidden: !deals,
      exportCellRenderer: ({ rowData }) => {
        const deal = dealsInfo?.[rowData.tax_assessor_id];
        return deal?.deal.lastActivityNote || '';
      },
      cellRenderer: ({ rowData }) => (
        <Suspense fallback={null}>
          <DealColumn taxAssessorId={rowData.tax_assessor_id} column='note' />
        </Suspense>
      ),
    },
    {
      label: 'Acq Associate',
      dataKey: 'deal_lead',
      width: 150,
      isResizable: true,
      hidden: !deals,
      exportCellRenderer: ({ rowData }) => {
        const deal = dealsInfo?.[rowData.tax_assessor_id];
        return deal?.deal.lead || '';
      },
      cellRenderer: ({ rowData }) => (
        <Suspense fallback={null}>
          <DealColumn taxAssessorId={rowData.tax_assessor_id} column='lead' />
        </Suspense>
      ),
    },
    {
      label: 'Broker',
      dataKey: 'deal_broker',
      width: 150,
      isResizable: true,
      align: 'left',
      hidden: !deals,
      cellRenderer: ({ rowData }) => {
        const deal = dealsInfo?.[rowData.tax_assessor_id];
        return deal?.deal.broker || '';
      },
    },
    {
      label: 'Broker Company',
      dataKey: 'deal_broker_company',
      width: 180,
      isResizable: true,
      align: 'left',
      hidden: !deals,
      cellRenderer: ({ rowData }) => {
        const deal = dealsInfo?.[rowData.tax_assessor_id];
        return deal?.deal.brokerCompany || '';
      },
    },
    {
      label: 'ILO',
      dataKey: 'deal_ilo',
      width: 150,
      isResizable: true,
      align: 'left',
      hidden: !deals,
      cellRenderer: ({ rowData }) => {
        const deal = dealsInfo?.[rowData.tax_assessor_id];
        return deal?.deal.ilo || '';
      },
    },
    {
      label: 'Dropbox Link',
      dataKey: 'deal_dropbox',
      width: 120,
      isResizable: true,
      align: 'left',
      hidden: !deals,
      exportCellRenderer: ({ rowData }) => {
        const deal = dealsInfo?.[rowData.tax_assessor_id];
        return deal?.deal.dropboxURL || '';
      },
      cellRenderer: ({ rowData }) => {
        const deal = dealsInfo?.[rowData.tax_assessor_id];
        return deal?.deal.dropboxURL ? (
          <IconButton
            component='a'
            href={deal?.deal.dropboxURL}
            size='small'
            color='primary'
            target='_blank'
          >
            <DropboxIcon />
          </IconButton>
        ) : null;
      },
    },
    {
      label: 'Property Type',
      dataKey: 'property_use_code_mapped',
      width: 220,
      isResizable: true,
      isSortable: true,
      cellRenderer: ({ rowData }) => {
        const lookup = cherrePropertyUseHierarchical.find((lookup) => {
          return lookup.lookup_code === rowData.property_use_code_mapped;
        });

        const taxAssessorType = lookup
          ? lookup.lookup_value
          : rowData.property_use_code_mapped;

        return taxAssessorType;
      },
    },
    {
      label: 'Property Subtype',
      dataKey: 'property_use_standardized_code',
      width: 220,
      isResizable: true,
      isSortable: true,
      cellRenderer: ({ rowData }) => {
        const lookups =
          lookupsByTableNames.contents?.lookups_by_table_name || [];
        const lookup = lookups.find((lookup) => {
          return (
            lookup.field_name === 'property_use_standardized_code' &&
            lookup.lookup_code === rowData.property_use_standardized_code
          );
        });

        const taxAssessorSubType = lookup ? lookup.lookup_value : undefined;

        return taxAssessorSubType;
      },
    },
    {
      label: 'Units',
      dataKey: 'units_count',
      width: 100,
      isResizable: true,
      isSortable: true,
      align: 'right',
      cellRenderer: ({ cellData }) => {
        return getMaskedValue(cellData, 'number');
      },
      exportCellRenderer: ({ cellData }) => cellData,
    },
    {
      label: 'Lot Size (acre)',
      dataKey: 'lot_size_acre',
      width: 130,
      isResizable: true,
      isSortable: true,
      align: 'right',
      cellRenderer: ({ cellData }) => {
        return getMaskedValue(cellData, 'number');
      },
      exportCellRenderer: ({ cellData }) => cellData,
    },
    {
      label: 'Year Built',
      dataKey: 'year_built',
      isSortable: true,
      width: 100,
      isResizable: true,
      align: 'right',
    },
    {
      label: 'Zoning',
      dataKey: 'zone_code',
      width: 100,
      isResizable: true,
      isSortable: true,
      align: 'right',
    },
    {
      label: 'Property Lists',
      dataKey: 'propertyListEntries',
      cellRenderer: ({ cellData }) => {
        return <PropertyLists cellData={cellData} />;
      },
      exportCellRenderer: ({ cellData = [] as { name: string }[] }) =>
        cellData.map((item) => item.name).join(','),
      width: 230,
      isResizable: true,
    },
    {
      label: 'Gross Area (sqft)',
      dataKey: 'gross_sq_ft',
      width: 150,
      isResizable: true,
      isSortable: true,
      align: 'right',
      cellRenderer: ({ cellData }) => {
        return getMaskedValue(cellData, 'number');
      },
      exportCellRenderer: ({ cellData }) => cellData,
    },
    {
      label: 'Lot Size (sqft)',
      dataKey: 'lot_size_sq_ft',
      width: 130,
      isResizable: true,
      isSortable: true,
      align: 'right',
      cellRenderer: ({ cellData }) => {
        return getMaskedValue(cellData, 'number');
      },
      exportCellRenderer: ({ cellData }) => cellData,
    },
    {
      label: 'Assessed Tax Year',
      dataKey: 'assessed_tax_year',
      isSortable: true,
      width: 160,
      isResizable: true,
      align: 'right',
    },
    {
      label: 'Tax Amount',
      dataKey: 'tax_bill_amount',
      isSortable: true,
      width: 120,
      isResizable: true,
      align: 'right',
      cellRenderer: ({ cellData }) => getMaskedValue(cellData, 'dollars'),
    },
    {
      label: 'Total Assessed Value',
      dataKey: 'assessed_value_total',
      isSortable: true,
      width: 180,
      isResizable: true,
      align: 'right',
      cellRenderer: ({ cellData }) => getMaskedValue(cellData, 'dollars'),
    },
    {
      label: 'Asssesed Improvements',
      dataKey: 'assessed_value_improvements',
      isSortable: true,
      width: 200,
      isResizable: true,
      align: 'right',
      cellRenderer: ({ cellData }) => getMaskedValue(cellData, 'dollars'),
    },
    {
      label: 'Assessed Land Value',
      dataKey: 'assessed_value_land',
      isSortable: true,
      width: 180,
      isResizable: true,
      align: 'right',
      cellRenderer: ({ cellData }) => getMaskedValue(cellData, 'dollars'),
    },
    {
      label: 'Market Value Year',
      dataKey: 'market_value_year',
      isSortable: true,
      width: 160,
      isResizable: true,
      align: 'right',
    },
    {
      label: 'Total Market Value',
      dataKey: 'market_value_total',
      isSortable: true,
      width: 160,
      isResizable: true,
      align: 'right',
      cellRenderer: ({ cellData }) => getMaskedValue(cellData, 'dollars'),
    },
    {
      label: 'Market Improvements ',
      dataKey: 'market_value_improvements',
      isSortable: true,
      width: 190,
      isResizable: true,
      align: 'right',
      cellRenderer: ({ cellData }) => getMaskedValue(cellData, 'dollars'),
    },
    {
      label: 'Market Land Value',
      dataKey: 'market_value_land',
      isSortable: true,
      width: 160,
      isResizable: true,
      align: 'right',
      cellRenderer: ({ cellData }) => getMaskedValue(cellData, 'dollars'),
    },
    {
      label: `Last Sale Date`,
      dataKey: 'recorder_summary',
      isSortable: true,
      isResizable: true,
      id: `recorder_summary.cherre_latest_deed_date`,
      cellRenderer: ({ cellData }) =>
        getMaskedValue(cellData?.[0]?.cherre_latest_deed_date, 'date'),
      width: 150,
    },
    {
      label: `Last Sale Price`,
      dataKey: 'recorder_summary',
      isSortable: true,
      isResizable: true,
      id: `recorder_summary.cherre_latest_deed_amount`,
      align: 'right',
      width: 180,
      cellRenderer: ({ cellData }) =>
        getMaskedValue(cellData?.[0]?.cherre_latest_deed_amount, 'dollars'),
    },
    {
      label: `Prior Sale Date`,
      dataKey: 'prior_sale_date',
      isSortable: true,
      isResizable: true,
      cellRenderer: ({ cellData }) => getMaskedValue(cellData, 'date'),
      width: 150,
    },
    {
      label: `Prior Sale Price`,
      dataKey: 'prior_sale_amount',
      isSortable: true,
      isResizable: true,
      width: 160,
      cellRenderer: ({ cellData }) => getMaskedValue(cellData, 'dollars'),
    },
    {
      label: `${mortgagePrefix} Date`,
      dataKey: 'recorder_summary',
      id: `recorder_summary.cherre_latest_mortgage_date`,
      isSortable: true,
      isResizable: true,
      cellRenderer: ({ cellData }) =>
        getMaskedValue(cellData?.[0]?.cherre_latest_mortgage_date, 'date'),
      width: 160,
    },
    {
      label: `${mortgagePrefix}`,
      dataKey: 'recorder_summary',
      isSortable: true,
      isResizable: true,
      id: `recorder_summary.cherre_latest_mortgage_amount`,
      align: 'right',
      width: 170,
      cellRenderer: ({ cellData }) =>
        getMaskedValue(cellData?.[0]?.cherre_latest_mortgage_amount, 'dollars'),
    },
    {
      label: `Last Lender`,
      dataKey: 'recorder_summary',
      // isSortable: true,
      isResizable: true,
      id: `recorder_summary.cherre_latest_mortgage_lender`,
      cellRenderer: ({ cellData }) => {
        const value = cellData?.[0]?.cherre_latest_mortgage_lender;
        return Array.isArray(value)
          ? value.join(', ')
          : value?.split('🞈').join(', ');
      },
      width: 170,
    },
    {
      label: `Last Borrower`,
      dataKey: 'recorder_summary',
      // isSortable: true,
      isResizable: true,
      id: `recorder_summary.cherre_latest_mortgage_borrower`,
      cellRenderer: ({ cellData }) => {
        const value = cellData?.[0]?.cherre_latest_mortgage_borrower;
        return Array.isArray(value)
          ? value.join(', ')
          : value?.split('🞈').join(', ');
      },
      width: 160,
    },
    {
      label: 'APN',
      isSortable: true,
      dataKey: 'assessor_parcel_number_raw',
      width: 150,
      align: 'right',
    },
    {
      label: 'MSA',
      isSortable: true,
      dataKey: 'msa_name',
      width: 250,
      align: 'right',
    },
    {
      label: 'Latitude',
      dataKey: 'location',
      id: `location.lat`,
      width: 100,
      align: 'right',
      cellRenderer: ({ cellData }) => cellData.lat,
      exportCellRenderer: ({ cellData }) => cellData.lat,
    },
    {
      label: 'Longitude',
      dataKey: 'location',
      id: `location.lon`,
      width: 100,
      align: 'right',
      cellRenderer: ({ cellData }) => cellData.lon,
      exportCellRenderer: ({ cellData }) => cellData.lon,
    },
  ].filter((c) => !c.hidden);

export default getColumns;
