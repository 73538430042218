import React from 'react';
import TimelineYear from './TimelineYear';
import { MAX_WIDTH } from '../common';
import grey from '@material-ui/core/colors/grey';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import Typography from '@material-ui/core/Typography';

const TimelineContent = ({
  visibleData = [],
  years,
  visibleDataByYear,
  isNyc,
}) => {
  return (
    <div id='timeline-groups'>
      {visibleData.length ? (
        years
          .sort()
          .reverse()
          .map((year, i) => {
            const items = visibleDataByYear[year];

            return (
              <TimelineYear key={i} year={year} items={items} isNyc={isNyc} />
            );
          })
      ) : (
        <div
          style={{
            height: 50,
            maxWidth: MAX_WIDTH,
            border: `${grey[300]} 1px solid`,
            borderRadius: 10,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ImageSearchIcon style={{ color: grey[600] }} />
          &nbsp; &nbsp;
          <Typography variant='body1' style={{ color: grey[600] }}>
            No records found for this property.
          </Typography>
        </div>
      )}
    </div>
  );
};

export default TimelineContent;
