import { useDispatch } from 'react-redux';
import { useRecoilCallback, useRecoilValueLoadable } from 'recoil';
import { showModal } from 'src/products/shell/redux/modals/actions';
import { sharedResourcesSelectorFamily } from '../recoil';
import { ResourceType, TargetType } from '../recoil/types';
import { useShareListCreateModal } from './useShareListCreate';

export const useShareListEditModal = (
  listId: number,
  onRequestClose: () => void,
  targets: { targetId: number; targetType: TargetType }[],
  mode: 'create' | 'edit'
) => {
  const value = useRecoilValueLoadable(
    sharedResourcesSelectorFamily({
      resourceId: listId,
      resourceType: ResourceType.PropertyList,
    })
  );

  const isLoadingSharedWith = value.state !== 'hasValue';
  const sharedWith = value.valueMaybe() || [];

  return {
    ...useShareListCreateModal(listId, onRequestClose, targets, mode),
    isLoadingSharedWith,
    sharedWith,
  };
};

export type EditShareListModalHook = typeof useShareListEditModal;

export const useShareListEdit = () => {
  const dispatch = useDispatch();

  return useRecoilCallback(
    () => (listId: number) => {
      dispatch(
        showModal('SHARE_LIST', {
          hook: useShareListEditModal,
          listId,
          mode: 'edit',
        })
      );
    },
    []
  );
};
