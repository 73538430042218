import React, { useState } from 'react';

import moment from 'moment';

import constants from 'src/const';
import {
  LOT_TRANSACTIONS,
  LOT_MORTGAGES,
  NYC_TRANSACTIONS,
  NYC_MORTGAGES,
  TREPP_LOANS,
} from './query';
import useCherreQuery from 'src/hooks/useCherreQuery';
import {
  TYPE_TRANSACTION,
  TYPE_MORTGAGE,
  TYPE_LOAN,
  INITIAL_LIMIT,
} from './common';
import { connect, useSelector } from 'react-redux';

import { getTreppData } from './getTreppData';

import TimelineFilterChips from './TimelineFilterChips';
import TimelineLoading from './TimelineLoading';
import TimelineContent from './TimelineContent';
import { useIsSuspended } from 'src/utils/suspense-companion/createSuspenseComponent';
import useStyles from './styles';
import { Typography } from '@material-ui/core';
import { panels } from '../../constants';
import TimelineLoadMore from './TimelineLoadMore';

const Timeline = ({ data: lotInfo }) => {
  if (!lotInfo?.lot_data) {
    return null;
  }

  const treppEnabled = useSelector(
    (state) => state.user?.profileForm?.value?.treppEnabled
  );

  const [limit, setLimit] = useState(INITIAL_LIMIT);
  const [filter, setFilter] = useState(null);
  const isNyc = Boolean(lotInfo.lot_data.nyc_bbl);
  const { data: usaTransactions, isLoading: isLoadingUsaTransactions } =
    useCherreQuery({
      query: LOT_TRANSACTIONS,
      queryName: 'timelineLotTransactions',
      getVariables: ({ orderBy }) => ({
        tax_assessor_id: lotInfo.lot_data.tax_assessor_id,
        orderBy,
      }),
      orderBy: {
        field: 'cherre_recorded_instrument_date',
        direction: constants.ORDER_BY_DIRECTION.DESC,
      },
      shouldExecute: !isNyc,
    });
  const { data: usaMortgages, isLoading: isLoadingUsaMortgages } =
    useCherreQuery({
      query: LOT_MORTGAGES,
      queryName: 'timelineLotMortgages',
      getVariables: ({ orderBy }) => ({
        tax_assessor_id: lotInfo.lot_data.tax_assessor_id,
        cherre_parcel_id: lotInfo.lot_data.cherre_parcel_id,
        orderBy,
      }),
      orderBy: {
        field: 'document_recorded_date',
        direction: constants.ORDER_BY_DIRECTION.DESC,
      },
      shouldExecute: !isNyc,
    });
  const { data: nycTransactions, isLoading: isLoadingNycTransactions } =
    useCherreQuery({
      query: NYC_TRANSACTIONS,
      queryName: 'timelineNycTransactions',
      getVariables: ({ orderBy }) => ({
        bbl: lotInfo.lot_data.nyc_bbl,
        orderBy,
      }),
      orderBy: {
        field: 'recorded_date',
        direction: constants.ORDER_BY_DIRECTION.DESC,
      },
      shouldExecute: isNyc,
    });
  const { data: nycMortgages, isLoading: isLoadingNycMortgages } =
    useCherreQuery({
      query: NYC_MORTGAGES,
      queryName: 'timelineNycMortagages',
      getVariables: ({ orderBy }) => ({
        bbl: lotInfo.lot_data.nyc_bbl,
        orderBy,
      }),
      orderBy: {
        field: 'recorded_date',
        direction: constants.ORDER_BY_DIRECTION.DESC,
      },
      shouldExecute: isNyc,
    });

  let treppLoans;
  let isLoadingTreppLoans;

  if (treppEnabled) {
    const {
      data: treppLoansFromQuery,
      isLoading: isLoadingTreppLoansFromQuery,
    } = useCherreQuery({
      query: TREPP_LOANS,
      queryName: 'timelineTreppLoans',
      getVariables: () => ({
        cherre_parcel_id: lotInfo.lot_data.cherre_parcel_id,
      }),
    });

    treppLoans = treppLoansFromQuery;
    isLoadingTreppLoans = isLoadingTreppLoansFromQuery;
  }

  const finalTreppLoans =
    treppLoans && treppLoans.tax_assessor_v2
      ? getTreppData(treppLoans.tax_assessor_v2)
      : [];

  const isLoading =
    isLoadingUsaTransactions ||
    isLoadingUsaMortgages ||
    isLoadingNycTransactions ||
    isLoadingNycMortgages ||
    isLoadingTreppLoans;
  const data = [
    ...(usaTransactions?.recorder_v2 || []).map((item) => ({
      type: TYPE_TRANSACTION,
      textType: TYPE_TRANSACTION,
      date: moment(item.cherre_recorded_instrument_date),
      amount: item.document_amount,
      ...item,
    })),
    ...(usaMortgages?.recorder_mortgage_v2 || []).map((item) => ({
      type: TYPE_MORTGAGE,
      textType: TYPE_MORTGAGE,
      date: moment(item.document_recorded_date),
      amount: item.document_amount,
      ...item,
    })),
    ...(nycTransactions?.acris_master || []).map((item) => ({
      type: TYPE_TRANSACTION,
      textType: TYPE_TRANSACTION,
      date: moment(item.recorded_date),
      amount: item.document_amount,
      ...item,
    })),
    ...(nycMortgages?.acris_master || []).map((item) => ({
      type: TYPE_MORTGAGE,
      textType: TYPE_MORTGAGE,
      date: moment(item.recorded_date),
      amount: item.document_amount,
      ...item,
    })),
    ...(finalTreppLoans.length ? finalTreppLoans : []).map((item) => ({
      type: TYPE_MORTGAGE,
      textType: TYPE_LOAN,
      date: moment(item.distribution_date),
      amount: item.actual_balance,
      ...item,
    })),
  ]
    .filter((item) => {
      if (item.type === TYPE_TRANSACTION && !item.amount) {
        if (isNyc && !item.grantors?.length) {
          return false;
        }

        if (!isNyc && !item.recorder_grantor_v2?.length) {
          return false;
        }
      }

      return filter ? item.type === filter : true;
    })
    .sort((a, b) => {
      const aValue = a.date.valueOf();
      const bValue = b.date.valueOf();

      if (aValue < bValue) {
        return 1;
      } else if (aValue > bValue) {
        return -1;
      }

      return 0;
    });
  const visibleData = data.slice(0, limit);
  const visibleDataByYear = visibleData.reduce((acc, item) => {
    const year = item.date.year();

    if (!acc[year]) {
      acc[year] = [];
    }

    acc[year].push(item);

    return acc;
  }, {});

  const allDataByYear = data.reduce((acc, item) => {
    const year = item.date.year();

    if (!acc[year]) {
      acc[year] = [];
    }

    acc[year].push(item);

    return acc;
  }, {});

  const visibleYears = Object.keys(visibleDataByYear);
  const allYears = Object.keys(allDataByYear);

  return (
    <div className='print-break-avoid'>
      <TimelineFilterChips setFilter={setFilter} filter={filter} />
      {isLoading ? (
        <TimelineLoading />
      ) : (
        <>
          <div className='print-only'>
            <TimelineContent
              visibleData={data}
              years={allYears}
              visibleDataByYear={allDataByYear}
              isNyc={isNyc}
            />
          </div>
          <div className='print-hide'>
            <TimelineContent
              visibleData={visibleData}
              years={visibleYears}
              visibleDataByYear={visibleDataByYear}
              isNyc={isNyc}
            />
          </div>
        </>
      )}
      {data.length > visibleData.length ? (
        <TimelineLoadMore setLimit={setLimit} limit={limit} />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { featureFlags } = state.user.profileForm.value;
  return { featureFlags };
};

const TimelineComp = connect(mapStateToProps)(Timeline);

const TimelineWrapper = (props) => {
  const isSuspended = useIsSuspended();
  const classes = useStyles();
  if (isSuspended) {
    return null;
  }
  return (
    <div id={panels[8].id} className={classes.container}>
      <Typography variant='h4' style={{ marginBottom: '25px' }}>
        Timeline
      </Typography>
      <TimelineComp {...props} />
    </div>
  );
};

export default TimelineWrapper;
