import { SourceProps } from 'react-map-gl';

export default function getCustomFeatureSource(
  feature?:
    | GeoJSON.Feature<GeoJSON.Geometry>
    | GeoJSON.FeatureCollection<GeoJSON.Geometry>
): SourceProps {
  return {
    id: 'custom-feature-collection',
    type: 'geojson',
    data: feature,
  };
}
