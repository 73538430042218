import {
  Checkbox,
  Chip,
  CircularProgress,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import { useRecoilValue } from 'recoil';
import {
  DEFAULT_SECTOR_NAME,
  getSectorName,
  getSectorValue,
  paginatedMarkets,
  paginatedSubMarkets,
  SectorName,
  sectors,
} from './recoil';
import { getSearchParameters } from 'src/products/core-prospect/search-pages/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { resetSearchParameters } from 'src/products/core-prospect/redux/query/actions';
import Search from '@material-ui/icons/Search';
import Clear from '@material-ui/icons/Clear';
import { removeGeoSearchParameters } from 'src/products/core-prospect/redux/query/utils';
import {
  FilterType,
  SearchEntity,
  SearchParameter,
  UsaMapParcelSearchField,
} from 'src/products/core-prospect/types/SearchParameter';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '10px 26px',
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'auto auto auto 1fr auto',
    overflow: 'auto',
    gap: 10,
  },
  reisContainer: {
    flex: 1,
    overflow: 'auto',
    borderRadius: 5,
    border: `solid 1px ${theme.palette.grey[500]}`,
  },
  reisCell: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    padding: '0px 5px',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.grey[100],
    },
  },
  searchCell: {
    padding: '5px 10px',
    width: '100%',
  },
  checkboxRoot: {
    padding: 5,
    color: theme.palette.primary.main,
  },
  progressContainer: {
    display: 'grid',
    placeItems: 'center',
    padding: 10,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.grey[900],
  },
  chipsContainer: {
    display: 'flex',
    gap: 5,
    maxHeight: 63,
    flexWrap: 'wrap',
    textTransform: 'capitalize',
    width: '280px',
  },
}));

type Market = {
  submarket_name: string;
  metro_code: string;
};

const getPersistedMarket = (id: string): Market | null => {
  const marketString = window.localStorage.getItem(`SUB-MARKET-SELECTED:${id}`);

  if (marketString) {
    try {
      return JSON.parse(marketString);
    } catch {
      return null;
    }
  }

  return null;
};

const setPersistedMarket = (id: string, newValue: Market | null) =>
  window.localStorage.setItem(
    `SUB-MARKET-SELECTED:${id}`,
    newValue ? JSON.stringify(newValue) : ''
  );

const REISSubmarketComponent: React.VFC = () => {
  const classes = useStyles();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const currentUser = useSelector((state: any) => state.user.profile.value);

  const [market, setMarket] = React.useState<Market | null>(
    getPersistedMarket(currentUser.id)
  );

  const [marketSearch, setMarketSearch] = React.useState(
    getPersistedMarket(currentUser.id)?.submarket_name || ''
  );

  const [sectorName, setSectorName] = React.useState(DEFAULT_SECTOR_NAME);

  const [submarketSearch, setSubmarketSearch] = React.useState<string>('');

  React.useEffect(() => {
    setPersistedMarket(currentUser.id, market);
    setSectorName(DEFAULT_SECTOR_NAME);
  }, [market]);

  const markets = useRecoilValue(
    paginatedMarkets({
      submarket_name: marketSearch ? `%${marketSearch}%` : '%',
    })
  );

  const submarkets = useRecoilValue(
    paginatedSubMarkets({
      submarket_name: submarketSearch ? `%${submarketSearch}%` : '%',
      metro_code: market?.metro_code || '%',
      sector: getSectorValue(sectorName),
    })
  );

  const searchParameters = useSelector(getSearchParameters);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectedSubMarkets: any[] = searchParameters.filter(
    (param) => 'submarket_name' in param.attributes
  );

  const isSubmarketSelected = (submarket_name: string) =>
    selectedSubMarkets.some(
      (param) =>
        param.attributes.submarket_name === `${submarket_name}` &&
        !param.attributes.isAutocomplete
    );

  const dispatch = useDispatch();

  const toggleSubmarket = async (
    submerketData: (typeof submarkets)['data'][0]
  ) => {
    let updatedSearchParameters: SearchParameter[];

    if (isSubmarketSelected(submerketData.submarket_name)) {
      updatedSearchParameters = [...searchParameters].filter(
        (param) =>
          param.attributes.submarket_name !== `${submerketData.submarket_name}`
      );

      dispatch(resetSearchParameters(updatedSearchParameters));
    } else {
      updatedSearchParameters = [
        ...removeGeoSearchParameters(searchParameters, {
          isProtected: (searchParameter) => {
            return Boolean(searchParameter.attributes.submarket_name);
          },
        }),
        {
          entity_name: SearchEntity.usaMapParcel,
          field_name: UsaMapParcelSearchField.customSearchArea,
          attributes: {
            filter_type: FilterType.customSearchArea,
            value: {
              geometry: submerketData.geometry,
              properties: {},
              type: 'Feature',
            },
            displayText: submerketData.submarket_name,
            ...submerketData,
          },
        },
      ];
      dispatch(resetSearchParameters(updatedSearchParameters));
    }
  };

  return (
    <div className={classes.container}>
      <Autocomplete
        options={markets.data}
        loading={markets.loading}
        getOptionSelected={(option, value) =>
          option.submarket_name === value?.submarket_name
        }
        getOptionLabel={({ submarket_name }) => submarket_name}
        inputValue={marketSearch}
        value={market}
        onInputChange={(_, value) => {
          setMarketSearch(value);
        }}
        renderInput={(props) => (
          <TextField
            {...props}
            label='Select REIS Market'
            variant='outlined'
            size='small'
          />
        )}
        onChange={(_, value) => {
          setMarket(value);
          setMarketSearch('');
        }}
        ListboxProps={{
          onScroll: markets.onScrollFetchMore,
        }}
      />

      <Autocomplete
        options={Object.keys(sectors)}
        getOptionLabel={(sector) => sector}
        value={sectorName}
        disableClearable
        renderInput={(props) => (
          <TextField
            {...props}
            label='Sector'
            variant='outlined'
            size='small'
          />
        )}
        onChange={(_, value) => {
          setSectorName(value as SectorName);
        }}
      />
      <Typography className={classes.title}>REIS Submarkets</Typography>
      <div
        className={classes.reisContainer}
        onScroll={submarkets.onScrollFetchMore}
      >
        <div className={classes.searchCell}>
          <TextField
            style={{ width: '100%' }}
            value={submarketSearch}
            onChange={(e) => setSubmarketSearch(e.target.value)}
            placeholder='Search'
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position='start'>
                  <Search fontSize='small' />
                </InputAdornment>
              ),
              endAdornment: submarketSearch ? (
                <InputAdornment position='end'>
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => setSubmarketSearch('')}
                  >
                    <Clear fontSize='small' />
                  </div>
                </InputAdornment>
              ) : undefined,
            }}
          />
        </div>
        {submarkets.data.map((submarket, index) => (
          <div
            key={index}
            className={classes.reisCell}
            onClick={() => toggleSubmarket(submarket)}
          >
            <Checkbox
              color='primary'
              classes={{ root: classes.checkboxRoot }}
              checked={isSubmarketSelected(submarket.submarket_name)}
            />
            <Typography>{submarket.submarket_name}</Typography>
          </div>
        ))}
        {submarkets.loading ? (
          <div className={classes.progressContainer}>
            <CircularProgress size={20} />
          </div>
        ) : null}
      </div>
      <div className={classes.chipsContainer}>
        {selectedSubMarkets.map((selectedSubmarket) => (
          <Chip
            style={{ maxWidth: '100%' }}
            key={selectedSubmarket.attributes.submarket_name}
            onDelete={() => toggleSubmarket(selectedSubmarket.attributes)}
            size='small'
            label={`${
              selectedSubmarket.attributes.submarket_name
            } - ${getSectorName(selectedSubmarket.attributes.sector)}`}
          />
        ))}
        <span style={{ height: '10px', width: '100%' }} />
      </div>
    </div>
  );
};

export default REISSubmarketComponent;
