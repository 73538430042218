import { selectorFamily } from 'recoil';
import { lotAddress } from '../../../recoil/lotAddress';
import { lotData } from '../../../recoil/lotData';
import { Tax_AssessorQueryVariables } from '../../../recoil/__generated__/taxAssessor';
import { TimelineData } from '../types';

export const timeline = selectorFamily<
  TimelineData,
  Tax_AssessorQueryVariables
>({
  key: 'LOT-PAGE/TIMELINE',
  get:
    (params) =>
    ({ get }) => {
      const lotAddressResult = get(lotAddress(params));
      const lotDataResult = get(lotData(params));
      return {
        data: { lot_data: lotDataResult, lot_address: lotAddressResult },
      };
    },
});
