import React from 'react';
import {
  FormControlLabel,
  FormGroup,
  makeStyles,
  Switch,
} from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import {
  changeSearchParameter,
  enableDealsFilteres,
} from 'src/products/core-prospect/redux/query/actions';
import { execQuery } from 'src/products/core-prospect/redux/query/actions/execQuery';
import { useRecoilCallback } from 'recoil';
import { isGroupOpenAtom } from './FilterGroup';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '5px 20px 10px 26px',
  },
  switchRoot: {
    width: 40,
    height: 18,
    padding: 0,
    marginRight: 10,
  },
  switchBase: {
    padding: 4,
  },
  switchTrack: {
    borderRadius: 20,
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
  switchThumb: {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 10,
    height: 10,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
}));

export const DealsSwitch = () => {
  const classes = useStyles();
  const dispath = useDispatch();

  const areDealsFiltersEnabled = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (state: any) => state.coreProspect.query.areDealsFiltersEnabled
  );

  const toggleDefaultOpenGroups = useRecoilCallback(
    ({ set }) =>
      (dealsEnabled: boolean) => {
        set(isGroupOpenAtom(12), dealsEnabled);
        set(isGroupOpenAtom(1), !dealsEnabled);
      },
    []
  );

  return (
    <div className={classes.root}>
      <FormGroup row>
        <FormControlLabel
          className='js-deals-toggle'
          control={
            <Switch
              classes={{
                root: classes.switchRoot,
                switchBase: classes.switchBase,
                track: classes.switchTrack,
                thumb: classes.switchThumb,
              }}
              checked={areDealsFiltersEnabled}
              onChange={(e) => {
                toggleDefaultOpenGroups(e.target.checked);
                dispath(enableDealsFilteres(e.target.checked));
                // set industrial deal team enabled by default
                if (e.target.checked) {
                  dispath(
                    changeSearchParameter(
                      {
                        id: 38,
                        entity: 'deal',
                        filter_set_id: 12,
                        filter_type: 'DealFilter',
                        field_name: 'team',
                      },
                      ['Industrial']
                    )
                  );
                }
                dispath(
                  execQuery({
                    id: 'DealsSwith',
                    cards: true,
                    count: true,
                    rebound: false,
                    results: true,
                    viewportOnly: true,
                  })
                );
              }}
              color='primary'
            />
          }
          label='Show deals'
        />
      </FormGroup>
    </div>
  );
};
