import axios from 'axios';
import { selectorFamily, atom } from 'recoil';
import { selectorFamilyWithDefaultValue } from 'src/utils/recoil/withDefaultValue';
import * as T from './types';

export const leasesSelectorFamily = selectorFamilyWithDefaultValue<
  T.Lease[],
  {
    latitude: number;
    longitude: number;
    compType: string;
    fileVersion: string;
  }
>({
  defaultValue: [],
  key: 'CORE-EXPLORE/LEASES',
  get:
    ({ latitude, longitude, compType, fileVersion }) =>
    async () => {
      const response = await axios.get<T.Lease[]>(`/api/v1/deals/leases`, {
        params: {
          latitude,
          longitude,
          compType,
          fileVersion,
        },
      });
      return response.data;
    },
});

export const shareWithAutocompleteOptionsSelectorFamily = selectorFamily<
  T.ShareWithAutocompleteResponse,
  { text: string }
>({
  key: 'CORE-EXPLORE/SHARE/AUTOCOMPLETE',
  get:
    ({ text }) =>
    async () => {
      if (text === '') {
        return [];
      }

      const response = await axios.get<T.ShareWithAutocompleteResponse>(
        `/api/v1/shared_resource/targets`,
        { params: { text } }
      );
      return response.data;
    },
});

export const sharedResourcesSelectorFamily = selectorFamily<
  T.SharedResourcesResponse,
  { resourceId: number; resourceType: string }
>({
  key: 'CORE-EXPLORE/SHARE/RESOURCES',
  get:
    ({ resourceId, resourceType }) =>
    async () => {
      if (resourceType !== T.ResourceType.PropertyList) {
        throw new Error('Resource type not implemented yet.');
      }

      const response = await axios.get<T.SharedResourcesResponse>(
        `/api/v1/propertyList/${resourceId}/shares`,
        { params: { resourceId, resourceType } }
      );
      return response.data;
    },
});

export const teamOrganizationMembersSelectorFamily = selectorFamily<
  T.TeamOrganizationMembersResponse,
  { text: string }
>({
  key: 'CORE-EXPLORE/TEAM/AUTOCOMPLETE',
  get:
    ({ text }) =>
    async () => {
      if (text === '') {
        return [];
      }

      const response = await axios.get<T.TeamOrganizationMembersResponse>(
        `/api/v1/teams/organizationMembers`,
        { params: { userName: text } }
      );
      return response.data;
    },
});

// TODO: Export count/limit should be handled on the server instead.
export const userExportFilesLimitAtom = atom({
  key: 'CORE-EXPLORE/USER/EXPORT-LIMIT',
  default: { date: '', count: 0 },
  effects: [
    ({ setSelf, onSet }) => {
      const key = 'exportHistory';

      const savedValue = localStorage.getItem(key);
      if (savedValue != null) {
        const exportHistory = JSON.parse(savedValue);

        const dateString = new Date().toDateString();

        if (exportHistory.date !== dateString) {
          exportHistory.date = dateString;
          exportHistory.count = 0;
        }

        setSelf(exportHistory);
      } else {
        setSelf({ date: new Date().toDateString(), count: 0 });
      }

      onSet((newValue, _, isReset) => {
        isReset
          ? localStorage.removeItem(key)
          : localStorage.setItem(key, JSON.stringify(newValue));
      });
    },
  ],
});
