import * as TYPES from '../types';
import { execQuery } from './execQuery';

export const resetAllFilters = () => {
  return (dispatch) => {
    dispatch({
      type: TYPES.RESET_ALL_FILTERS,
    });
    dispatch(
      execQuery({
        id: 'ResetAllFilters',
        cards: true,
        count: true,
        results: true,
        viewportOnly: true,
        rebound: false,
      })
    );
  };
};
