import React from 'react';
import useStyles from './styles';

interface MultiColumnRowProps {
  columns?: number;
  children: React.ReactNode;
}

const MultiColumnRow: React.FC<MultiColumnRowProps> = ({
  children,
  columns,
}) => {
  const columnCount = columns || React.Children.count(children);
  const classes = useStyles({ columnCount });
  return <div className={classes.container}>{children}</div>;
};

MultiColumnRow.displayName = 'MultiColumnRow';

export default MultiColumnRow;
