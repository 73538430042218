import { number } from '../../../../../utils';
import { TaxAssessorToBuildingDetailsFormatter } from '../types';

export const taxAssessorToBuildingDetails: TaxAssessorToBuildingDetailsFormatter =
  (data) => {
    const tax_assessor = data.tax_assessor_v2[0];

    const floor1sqft = number(tax_assessor.floor_1_sq_ft, 'sqft');
    const buildingsCount = number(tax_assessor.buildings_count);

    return {
      floor1sqft,
      buildingsCount,
    };
  };
