import React from 'react';
import { connect } from 'react-redux';

import Tooltip from '@material-ui/core/Tooltip';

import Button from '@material-ui/core/Button';
import { openSaveSearchDialog } from 'src/products/core-prospect/redux/view/actions';

const SaveButton = ({
  openSaveSearchDialog,
  disabled,
  children,
  ...otherProps
}) => (
  <Tooltip title={disabled ? 'No filters applied' : 'Save Search'}>
    <Button
      id='js-core-prospect-save-filter-button'
      disabled={disabled}
      variant='outlined'
      onClick={() => openSaveSearchDialog()}
      color='primary'
      style={{
        textTransform: 'capitalize',
      }}
      {...otherProps}
    >
      {children || 'save'}
    </Button>
  </Tooltip>
);

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, { openSaveSearchDialog })(SaveButton);
