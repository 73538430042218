import React from 'react';
import { makeStyles, Box, Chip, Typography } from '@material-ui/core';

import { Flex } from 'src/components/Flex';
import OwnerCard from './OwnerCard/index';
import { OwnerTypes, TYPE_PERSON } from './common';
import { OwnersQuery } from './__generated__/usaQuery';
import { LinkedAddressesQuery } from './__generated__/linkedAddressesQuery';
import { LotAddress } from '../../recoil';

const useStyles = makeStyles(() => ({
  root: {
    fontSize: 10,
    height: 16,
    borderRadius: 5,
    backgroundColor: '#dcaacc',
    color: '#ffffff',
  },
}));

type UnmaskedOwnersProps = {
  owners: OwnersQuery['tax_assessor_v2'][number]['owner'];
  address: LotAddress;
  linkedAddressData: LinkedAddressesQuery;
};

const UnmaskedOwners: React.FC<UnmaskedOwnersProps> = ({
  owners,
  address,
  linkedAddressData,
}) => {
  const classes = useStyles();

  return (
    <>
      {owners.length ? (
        <div>
          <Box display='flex' alignItems='center' marginBottom='5px'>
            <Typography variant='h6' style={{ marginRight: 10 }}>
              Unmasked Owners
            </Typography>
            <Chip label='ALPHA' size='small' classes={classes} />
          </Box>
          <Typography
            variant='body2'
            style={{ marginBottom: 10, color: '#757575' }}
          >
            This feature is currently in development. As we improve our
            unmasking algorithm, you may see some results that are inaccurate.
          </Typography>
          <Flex
            justifyContent='flex-start'
            flex='unset'
            style={{ flexWrap: 'wrap' }}
          >
            {owners.map((owner, i) => {
              return (
                <OwnerCard
                  key={i}
                  name={owner.owner_name || ''}
                  owner_id={owner.owner_id ?? undefined}
                  type={owner.owner_type as OwnerTypes}
                  pipl={owner.owner_type === TYPE_PERSON}
                  address={
                    owner.cherre_address__address?.[0]?.display_address ??
                    undefined
                  }
                  cherreParcelId={owner.cherre_parcel_id ?? undefined}
                  taxAssessorId={owner.tax_assessor_id ?? undefined}
                  linkedAddresses={linkedAddressData?.tax_assessor_v2}
                  lotAddress={address}
                />
              );
            })}
          </Flex>
        </div>
      ) : null}
    </>
  );
};

export default UnmaskedOwners;
