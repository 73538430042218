import { useLoadable } from 'src/products/core-prospect/hooks';
import { DealPortfolioAssestsQuery } from '../query';

export const useTaxAssessors = (assets: Array<{ taxAssessorId: number }>) => {
  return useLoadable(
    null,
    DealPortfolioAssestsQuery({
      tax_assessor_id: assets.map((asset) => asset.taxAssessorId),
    })
  );
};
