import { Map, Visibility } from 'mapbox-gl';
import { MapEvent } from 'react-map-gl';
import { Sources } from 'src/components/Map/types';
import { ORDERED_LAYERS_IDS } from './const';
import { LayerName } from './types';

export const getSortedSources = (sources: Sources): Sources => {
  const sortedSources = sources
    .reduce<Sources>((sources, source) => {
      return [
        ...sources,
        ...source.layers.map((layer) => {
          return {
            ...source,
            layers: [layer],
          };
        }),
      ];
    }, [])
    .sort(({ layers: [layer1] }, { layers: [layer2] }) => {
      const index1 = layer1.id ? ORDERED_LAYERS_IDS.indexOf(layer1.id) : 0;
      const index2 = layer2.id ? ORDERED_LAYERS_IDS.indexOf(layer2.id) : 0;

      return index1 > index2 ? -1 : 1;
    });

  return sortedSources;
};

export const getLayerVisibility = (
  layers: LayerName[],
  layerName: LayerName
): Visibility => {
  const isVisible = layers.find((name) => name === layerName);

  return isVisible ? 'visible' : 'none';
};

let hoveredFeature: any = null;

export const handleHover = (e: MapEvent, map?: Map): void => {
  if (!map) {
    return;
  }

  const feature = (e.features || []).filter((feature) =>
    Boolean(feature.id)
  )[0];

  if (hoveredFeature) {
    const isSourceExists = map.getSource(hoveredFeature.source);
    if (isSourceExists) {
      map.setFeatureState(
        {
          source: hoveredFeature.source,
          sourceLayer: hoveredFeature.sourceLayer,
          id: hoveredFeature.id,
        },
        { hover: false }
      );
    }
  }

  if (!feature) {
    return;
  }

  hoveredFeature = feature;

  const isSourceExists = map.getSource(hoveredFeature.source);

  if (isSourceExists) {
    map.setFeatureState(
      {
        source: feature.source,
        sourceLayer: feature.sourceLayer,
        id: feature.id,
      },
      { hover: true }
    );
  }
};
