import muiThemeSettings from 'src/const/theme';

import { createMuiTheme } from '@material-ui/core/styles';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';

const typography: TypographyOptions = {
  fontFamily: 'Montserrat',
  h1: {
    fontFamily: 'Montserrat',
    fontWeight: 700,
    fontSize: 48,
  },
  h2: {
    fontFamily: 'Montserrat',
    fontWeight: 700,
    fontSize: 40,
  },
  h3: {
    fontFamily: 'Montserrat',
    fontWeight: 700,
    fontSize: 27,
  },
  h4: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: 24,
  },
  h5: {
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: 20,
  },
  h6: {
    fontFamily: 'Montserrat',
    fontWeight: 700,
    fontSize: 16,
  },
  subtitle1: {
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: 18,
  },
  subtitle2: {
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: 16,
  },
  body1: {
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: 14,
    '@media (maxWidth:600px)': {
      fontSize: 12,
    },
  },
  body2: {
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: 12,
  },
  button: {
    fontFamily: 'Montserrat',
    fontWeight: 700,
    fontSize: 14,
    textTransform: 'capitalize',
  },
  caption: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: 10,
  },
  overline: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 10,
    textTransform: 'uppercase',
  },
};

const theme = createMuiTheme({
  ...muiThemeSettings,
  typography: {
    ...muiThemeSettings.typography,
    ...typography,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    MuiTable: {
      root: {
        fontFamily: 'Montserrat',
        fontSize: 12,
        fontWeight: 500,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#9e9e9e',
        '&$selected': {
          color: muiThemeSettings.palette?.primary?.main,
        },
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: muiThemeSettings.palette?.primary?.main,
        height: '5px',
        borderRadius: '5px 5px 0 0',
      },
    },
  },
  palette: {
    ...muiThemeSettings.palette,
    background: {
      default: '#eeeeee',
    },
  },
});

export default theme;
