import React from 'react';
import Contacts from '../components/ContactsCell';
import TaxRecordCell from '../components/TaxRecordsCells';
import { RowData } from '../types';
import { OwnerCell } from '../components/OwnerCell';

export const getColumns = (): any => {
  return [
    {
      label: 'Owner Name',
      dataKey: 'name',
      isSortable: true,
      width: 250,
      isResizable: true,
      cellRenderer: ({ rowData }) => <OwnerCell rowData={rowData} />,
    },
    {
      label: 'Owner Type',
      dataKey: 'owner-type-fake-data-key',
      width: 250,
      isResizable: true,
      cellRenderer: ({ rowData }) => {
        const type = rowData.ownerId?.split('::')[1];

        return (
          {
            I: 'Company',
            P: 'Individual',
            G: 'Government',
            E: 'Education',
          }[type] || 'Unknown'
        );
      },
    },
    {
      label: 'Matching Tax Records',
      dataKey: 'propertyCount',
      width: 200,
      isResizable: true,
      align: 'right',
      cellRenderer: ({ rowData }) => {
        return <TaxRecordCell rowData={rowData} type='matching' />;
      },
    },
    {
      label: 'Total Tax Records',
      dataKey: 'totalTaxRecordsCount',
      width: 150,
      isResizable: true,
      align: 'right',
      cellRenderer: ({ rowData }) => {
        return <TaxRecordCell rowData={rowData} type='all' />;
      },
    },
    {
      label: 'Owner Mailing Address',
      dataKey: 'address',
      isResizable: true,
      width: 150,
    },
    {
      label: 'Contacts',
      dataKey: 'contactsCount',
      isResizable: true,
      width: 150,
      cellRenderer: ({ rowData }: { rowData: RowData; cellData: number }) => {
        return (
          <Contacts
            ownerId={rowData.ownerId}
            street={rowData.street}
            city={rowData.city}
          />
        );
      },
    },
  ];
};
