import React from 'react';
import { DataGrid, GridSortModel } from '@material-ui/data-grid';
import { getMaskedValue } from 'src/utils/mask';
import * as T from './types';
import { useIsSuspended } from 'src/utils/suspense-companion/createSuspenseComponent';
import NumberFormat from 'react-number-format';

export const getFormattedNumber = (props) => {
  const numberFormat = new NumberFormat(props);
  return numberFormat.state.value;
};

const columns = [
  {
    headerName: 'TENANT',
    field: 'tenant_name',
    width: 200,
    valueGetter: (params) => {
      return params.value;
    },
  },
  { field: 'floors_occupied', headerName: 'FLOORS', width: 150 },
  {
    field: 'transaction_sq_ft',
    headerName: 'SQFT',
    width: 100,
    valueGetter: (params) => {
      const numberFormatOptions = {
        displayType: 'text',
        format: undefined,
        prefix: undefined,
        suffix: undefined,
        thousandSeparator: true,
        value: params.value,
      };

      return getFormattedNumber(numberFormatOptions);
    },
    sortComparator: (v1, v2) => {
      return v1 - v2;
    },
  },
  {
    field: 'execution_date',
    headerName: 'EXECUTION DATE',
    width: 145,
    valueGetter: (params) => {
      return getMaskedValue(params.value, 'date');
    },
    sortComparator: (v1, v2) => {
      return v1.localeCompare(v2);
    },
  },
  {
    headerName: 'LEASE START DATE',
    width: 145,
    field: 'commencement_date',
    valueGetter: (params) => {
      return getMaskedValue(params.value, 'date');
    },
    sortComparator: (v1, v2) => {
      return v1.localeCompare(v2);
    },
  },
  {
    field: 'lease_term',
    headerName: 'LEASE TERM',
    width: 120,
    valueGetter: (params) => {
      return params.value;
    },
  },
  {
    field: 'expiration_date',
    headerName: 'LEASE END DATE',
    width: 145,
    valueGetter: (params) => {
      return getMaskedValue(params.value, 'date');
    },
    sortComparator: (v1, v2) => {
      return v1.localeCompare(v2);
    },
  },
  {
    field: 'starting_rent_per_sq_ft',
    headerName: 'STARTING RENT',
    width: 150,
    valueGetter: (params) => {
      return getMaskedValue(params.value, 'dollars');
    },
  },
  {
    field: 'net_effective_rent',
    headerName: 'NET EFFECTIVE RENT',
    width: 170,
    valueGetter: (params) => {
      return getMaskedValue(params.value, 'dollars');
    },
  },
  {
    field: 'rent_increase_schedule',
    headerName: 'RENT INCREASE SCHEDULE',
    width: 350,
    valueGetter: (params) => {
      return getMaskedValue(params.value, 'dollars');
    },
  },
  {
    field: 'concessions_psf',
    headerName: 'CONCESSIONS (PSF)',
    width: 150,
    valueGetter: (params) => {
      const {
        free_rent_month_count,
        starting_rent_per_sq_ft,
        work_value,
        transaction_sq_ft,
      } = params?.row;
      const totalConcessions =
        free_rent_month_count * starting_rent_per_sq_ft +
        work_value / transaction_sq_ft;
      const roundedConcessions = Math.round(totalConcessions * 100) / 100;

      return getMaskedValue(roundedConcessions, 'dollars');
    },
  },
];

const sortModel: GridSortModel = [
  {
    field: 'commencement_date',
    sort: 'asc',
  },
];

const LeasesTable: T.LeasesTable = ({ leases = [] }) => {
  const isSuspended = useIsSuspended();
  return (
    <div style={{ height: 450, width: '100%', padding: '12px 0px' }}>
      <DataGrid
        loading={isSuspended}
        disableColumnSelector
        sortModel={sortModel}
        rows={leases}
        columns={columns}
      />
    </div>
  );
};

LeasesTable.displayName = 'LeasesTable';

export default LeasesTable;
