import { useParams } from 'react-router';
import { useRecoilValue } from 'recoil';
import { LotPageParams } from '../../types';
import { taxesData } from './recoil';
import * as T from './types';

const useTaxes: T.UseTaxes = () => {
  const { id } = useParams<LotPageParams>();
  return useRecoilValue(taxesData({ tax_assessor_id: Number(id) }));
};

export default useTaxes;
