import { GQL, gql } from 'src/utils/recoil/gql';
import * as T from './__generated__/reisSubmarkets';

export default gql`
  query getReisSubmarkets($reisBoundaries: [String!]) {
    coreexplore_reis_submarket_data(
      where: {
        cherre_reis_boundaries_pk: { _in: $reisBoundaries }
        latest_data: { _eq: 1 }
        submarket_id: { _neq: 0 }
      }
    ) {
      submarket_name
      sector
      absorption
      completions
      vacancy
      asking_rent
    }
  }
` as GQL<T.GetReisSubmarketsQuery, T.GetReisSubmarketsQueryVariables>;
