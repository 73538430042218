import React, { useEffect, useState } from 'react';
import { RCACompsList } from '../';
import RCAMap from 'src/products/core-prospect/components/ReactMap';
import PropertyDetailsList from 'src/products/core-prospect/CompanyDetails/Properties/List';
import { Flex } from 'src/components/Flex';
import CircularProgress from '@material-ui/core/CircularProgress';
import { WebMercatorViewport } from 'react-map-gl';
import { useSelector } from 'react-redux';

const RCAMapAndCompsList = ({
  bounds,
  rcaCompsList,
  getCherreParcelGemo,
  mapLatLongId,
  selectedParcelData,
  showPropertyDetails,
  hidePropertyDetails,
  loading,
}) => {
  const [viewport, setViewport] = useState();

  useEffect(() => {
    if (bounds && bounds.length > 0) {
      const viewport = new WebMercatorViewport({
        width: 500,
        height: 500,
      }).fitBounds(bounds || []);

      setViewport(viewport);
    }
  }, [bounds]);

  const [zoom, setZoom] = useState(viewport?.zoom);

  const isSatelliteVisible = useSelector(
    (state) => state.coreProspect.map.isSatelliteVisible
  );

  return (
    <React.Fragment>
      <div
        className='screen-hide-xs'
        style={{ height: '100%', width: '100%', position: 'relative' }}
      >
        {loading ? (
          <Flex
            style={{
              position: 'absolute',
              zIndex: 100,
              height: '100%',
              width: '100%',
            }}
            justifyContent={'center'}
            alignItems='center'
          >
            <CircularProgress />
          </Flex>
        ) : null}
        <RCAMap
          onViewportChange={(map) => setZoom(map.getZoom())}
          isLayersSelectorVisible
          height={'100%'}
          width={'100%'}
          satellite={isSatelliteVisible}
          latitude={viewport?.latitude}
          longitude={viewport?.longitude}
          zoom={zoom || viewport?.zoom}
          layerNames={['parcels']}
          parcels={mapLatLongId}
          clusters={(zoom || viewport?.zoom) < 13 ? mapLatLongId : []}
        />
      </div>
      <div
        style={{
          height: '100%',
          width: 300,
          marginLeft: 15,
          alignSelf: 'flex-start',
          overflow: 'auto',
        }}
      >
        {showPropertyDetails ? (
          <PropertyDetailsList
            selectedParcelId={
              selectedParcelData[0] && selectedParcelData[0].cherre_parcel_id
                ? selectedParcelData[0].cherre_parcel_id
                : ''
            }
            tax_assessors={selectedParcelData}
            onSelectTaxAssessor={hidePropertyDetails}
            showBackButton
            emptyResultsText={
              'No Cherre property data found for this transaction.'
            }
          />
        ) : (
          <RCACompsList
            rcaCompsList={rcaCompsList}
            getCherreParcelGemo={getCherreParcelGemo}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default RCAMapAndCompsList;
