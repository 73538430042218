export const TYPE_RECORDED = 'R';
export const TYPE_PERSON = 'P';
export const TYPE_COMPANY = 'I';
export const TYPE_INDIVIDUAL = 'INDIVIDUAL';

export type OwnerTypes =
  | typeof TYPE_RECORDED
  | typeof TYPE_PERSON
  | typeof TYPE_COMPANY
  | typeof TYPE_INDIVIDUAL;

export const INDIVIDUAL_OWNER_TYPES = [
  'NP',
  '|NP',
  'INDIVIDUAL',
  '|INDIVIDUAL',
  null,
  'UNKNOWN',
] as const;
