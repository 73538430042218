import { isNumber } from 'lodash';
import moment from 'moment';
import { Lease } from 'src/products/core-prospect/recoil/types';
import numberWithCommas from 'src/utils/numberWithCommas';

export const noValue = '—';

export const gridSettings: Array<{
  label: string;
  field: keyof Lease;
  format: (val: string | number | null | undefined, record: Lease) => string;
}> = [
  {
    label: 'Deal Size (SF)',
    field: 'deal_size_sf',
    format: (val) => (val ? `${numberWithCommas(val)}` : noValue),
  },
  {
    label: 'Year 1 Rent/SF',
    field: 'year_1_rent',
    format: (val, record) => {
      const year_1_rent = Number(record.year_1_rent);
      const recoveries = record.recoveries;

      if (!isNumber(year_1_rent) || !recoveries) {
        return noValue;
      }

      if (!isNumber(year_1_rent)) {
        return recoveries;
      }

      if (!recoveries) {
        return `$${numberWithCommas(year_1_rent.toFixed(2))}`;
      }

      return `$${numberWithCommas(year_1_rent.toFixed(2))}/${recoveries}`;
    },
  },
  {
    label: 'Sign Date',
    field: 'sign_date',
    format: (val) => (val ? moment(new Date(val)).format('ll') : noValue),
  },
  {
    label: 'Escalations',
    field: 'escalation',
    format: (val) => {
      return val && isNumber(+val)
        ? `${numberWithCommas((Number(val) * 100).toFixed(2))}%`
        : noValue;
    },
  },
  {
    label: 'Start Date',
    field: 'commencement_date',
    format: (val) => (val ? moment(new Date(val)).format('ll') : noValue),
  },
  {
    label: 'TI/SF',
    field: 'ti',
    format: (val) => {
      return val && isNumber(Number(val))
        ? `$${numberWithCommas(Number(val).toFixed(2))}`
        : noValue;
    },
  },
  {
    label: 'Term',
    field: 'term_months',
    format: (val) =>
      val && isNumber(Number(val)) && Number(val) > 0 ? `${val} Mos` : noValue,
  },
  {
    label: 'Free Rent',
    field: 'free_rent_months',
    format: (val) =>
      val && isNumber(Number(val)) && Number(val) > 0 ? `${val} Mos` : noValue,
  },
];
