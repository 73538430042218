import { useParams } from 'react-router';
import { useRecoilValue } from 'recoil';
import { LotPageParams } from '../../../../types';
import { buildingDetails } from './recoil';
import { UseBuildingDetails } from './types';

const hook: UseBuildingDetails = () => {
  const { id } = useParams<LotPageParams>();
  return useRecoilValue(buildingDetails({ tax_assessor_id: Number(id) }));
};

export default hook;
