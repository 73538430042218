import { DealStageImage, DealStageType } from 'src/services/Dealcloud/types';
import { UsaMapParcelSearchField } from './types/SearchParameter';
import { Permission } from '../shell/redux/reducers/user';

export const RESIDENTIAL_PROPERTY_USE_CODE_MAPPED = [8, 39, 40, 41, 42, 43, 44];
export const CONDO_PROPERTY_USE_CODE_MAPPED = 43;
export const COOPERATIVE_PROPERTY_USE_CODE_MAPPED = 39;

export const MAP_LAYERS_TITLE = 'Area boundaries';
export const REIS_BOUNDARIES_TITLE = 'REIS Submarkets';

export const longDash = '\u2014';

export const GEOGRAPHY_TYPES = {
  ZI: {
    label: 'ZIP',
  },
  CO: {
    label: 'County',
  },
  ST: {
    label: 'State',
  },
};

export const CORE_PROSPECT_VIEWS = {
  MAP: 'map',
  TABLE: 'table',
};

export const RESULTS_TYPES = {
  TAX_RECORDS: 'tax_records',
  OWNERS: 'owners',
  MARKETS: 'zipcodes',
};

export const MARKETS_TABS = {
  ZIPCODES: { value: 'zipcodes', title: 'Zip Codes' },
  SUBMARKETS: { value: 'submarkets', title: 'REIS Submarket' },
};

export const dealStageColorMap: Record<DealStageType, string> = {
  Closed: '#33CABE',
  'In Due Diligence': '#9F4182',
  Offered: '#FF6294',
  Underwriting: '#FEAFEA',
  'With IC': '#E88819',
  Tracking: '#BAB3E8',
  Inactive: '#BDBDBD',
};

export const dealStageImageMap: Record<
  Exclude<DealStageType, 'Inactive'>,
  DealStageImage
> = {
  Tracking: 'DealPin_Tracking',
  Closed: 'DealPin_Closed',
  'In Due Diligence': 'DealPin_InDueDiligence',
  Offered: 'DealPin_Offered',
  Underwriting: 'DealPin_Underwriting',
  'With IC': 'DealPin_WithIC',
};

export const geoSearchFields = [
  UsaMapParcelSearchField.city,
  UsaMapParcelSearchField.state,
  UsaMapParcelSearchField.customSearchArea,
];

export const CHERRE_ADMIN_PERMISSIONS: Permission[] = [
  'CherreAdminReadOnly',
  'CherreAdmin',
];

export const CHERRE_ADMIN_ALLOWED_ORGS = [
  1, // Cherre
];
