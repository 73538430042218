import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CompanyDetailsTopMarketsBar from './CompanyDetailsTopMarketsBar';
import { getMaskedValue } from 'src/utils/mask';

const CompanyDetailsTopMarketsRow = ({ city, maxValue }) => {
  return (
    <React.Fragment>
      <Grid item xs={4}>
        <Typography
          align={'left'}
          style={{
            fontWeight: 700,
            fontSize: 14,
          }}
        >
          {city.city}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <CompanyDetailsTopMarketsBar
          value={city.propertyCount}
          maxValue={maxValue}
        />
      </Grid>
      <Grid
        item
        xs={4}
        style={{
          paddingLeft: 0,
        }}
      >
        <Typography
          align={'right'}
          style={{
            fontWeight: 500,
            fontSize: 14,
          }}
        >
          {`${getMaskedValue(city.propertyCount, 'number')} properties`}
        </Typography>
      </Grid>
    </React.Fragment>
  );
};

export default CompanyDetailsTopMarketsRow;
