import React from 'react';
import {
  Box as MUIBox,
  Chip,
  makeStyles,
  Theme,
  Typography,
  useTheme,
  withStyles,
} from '@material-ui/core';
import { DealStage } from 'src/products/core-prospect/pages/lot-page/components';
import Truncate from 'src/components/Truncate';
import { grey } from '@material-ui/core/colors';
import { ViewPropertiesIcon } from 'src/components/ui/icons/ViewPropertiesIcon';
import { useDealPortfolioByTaxId } from 'src/services/Dealcloud/hooks';
import { PortfolioDeal } from 'src/services/Dealcloud/types';
import { startCase, toLower, uniq } from 'lodash';
import { useViewProperties } from 'src/services/Dealcloud/viewProperties';
import ButtonProgress from 'src/components/ButtonProgress';
import { longDash } from 'src/products/core-prospect/constants';

const Box = withStyles({
  root: {
    marginBottom: 20,
    '&:last-child': {
      marginBottom: 0,
    },
  },
})(MUIBox);

type StyleProps = {
  isPortfolio: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  container: {
    backgroundColor: grey[200],
    display: 'grid',
    padding: '10px',
    gridTemplateAreas: ({ isPortfolio }) => `
      'status lead'
      'ilo broker'
      'seller seller'
      'description description'
      'note note'
      ${isPortfolio ? "'properties . '" : ''}
    `,
    gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)',
    gridGap: '0px 5px',
    borderRadius: '10px',
  },
  header: {
    color: grey[700],
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '15px',
    marginBottom: '2px',
  },
  caption: {
    color: grey[700],
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '15px',
    marginBottom: '10px',
  },
  deal: {
    marginBottom: ({ isPortfolio }) => (isPortfolio ? -3 : '10px'),
  },
  card: {
    marginBottom: '30px',
  },
  portfolio: {
    color: '#9F4182',
    backgroundColor: '#FCEDF6',
    fontSize: '12px',
    height: '19px',
    marginBottom: '30px',
    fontWeight: 500,
  },
  propertiesButton: {
    fontSize: '14px',
    fontWeight: 500,
  },
  box: {
    marginBottom: 20,
  },
}));

export type DealInfoCardProps = {
  taxAssessorId: number;
};

export const getPortfolioSummary = (deal: PortfolioDeal) => {
  const locations = uniq(
    deal.assets.map((a) =>
      [startCase(toLower(a.dealCloudAsset.city)), a.dealCloudAsset.state]
        .filter(Boolean)
        .join(', ')
    )
  );

  if (locations.length === 0) {
    return '';
  }

  const propertyStr = `${deal.assets.length} Property`; // pluralize('Property', deal.assets.length, true);

  if (locations.length === 1) {
    return `${propertyStr} Portfolio in ${locations[0]}`;
  }

  if (locations.length === 2) {
    return `${propertyStr} Portfolio in ${locations[0]} & ${locations[1]}`;
  }

  return `${propertyStr} Portfolio in multiple geographies`;
};

export const DealInfoCard: React.VFC<DealInfoCardProps> = ({
  taxAssessorId,
}) => {
  const deal = useDealPortfolioByTaxId(taxAssessorId);
  const isPortfolio = (deal?.assets.length || 0) > 1;
  const theme = useTheme();

  const classes = useStyles({ isPortfolio });

  const [viewProperties, viewPropertiesLoading] = useViewProperties();

  if (!deal) {
    return null;
  }

  const portfolioDescription = getPortfolioSummary(deal);

  return (
    <Box className={`${classes.card} js-deal-info-card`}>
      {isPortfolio && (
        <Chip label={deal.dealName} className={classes.portfolio} />
      )}
      <Typography
        gutterBottom={!isPortfolio}
        variant='h6'
        className={classes.deal}
      >
        {isPortfolio ? 'Portfolio Deal' : 'Deal Info'}
      </Typography>
      {isPortfolio ? (
        <Typography className={classes.caption}>
          {portfolioDescription}
        </Typography>
      ) : null}
      <Box className={classes.container}>
        <Box gridArea='status'>
          <Typography className={classes.header}>Stage</Typography>
          <DealStage name={deal.stage} />
        </Box>
        <Box gridArea='lead'>
          <Typography className={classes.header}>
            Acquisitions Associate
          </Typography>
          <Typography>
            <Truncate>{deal.lead}</Truncate>
          </Typography>
        </Box>
        <>
          <Box gridArea='ilo'>
            <Typography className={classes.header}>ILO</Typography>
            <Typography>
              <Truncate>{deal.ilo || <i>{longDash}</i>}</Truncate>
            </Typography>
          </Box>
          <Box gridArea='broker'>
            <Typography className={classes.header}>Broker</Typography>
            <Typography>
              <Truncate>{deal.broker || <i>{longDash}</i>}</Truncate>
            </Typography>
          </Box>
        </>
        <Box gridArea='seller'>
          <Typography className={classes.header}>Seller</Typography>
          <Typography>
            <Truncate>{deal.seller || <i>{longDash}</i>}</Truncate>
          </Typography>
        </Box>
        <Box gridArea='description'>
          <Typography className={classes.header}>Deal Description</Typography>
          <Typography>
            <Truncate
              style={{
                whiteSpace: 'normal',
                display: '-webkit-box',
                WebkitLineClamp: 5,
                WebkitBoxOrient: 'vertical',
              }}
            >
              {deal.description || <i>{longDash}</i>}
            </Truncate>
          </Typography>
        </Box>
        <Box gridArea='note'>
          <Typography className={classes.header}>Latest Note</Typography>
          <Typography>
            <Truncate
              style={{
                whiteSpace: 'normal',
                display: '-webkit-box',
                WebkitLineClamp: 5,
                WebkitBoxOrient: 'vertical',
              }}
            >
              {deal.lastActivityNote || <i>{longDash}</i>}
            </Truncate>
          </Typography>
        </Box>
        {isPortfolio && (
          <Box gridArea='properties'>
            <ButtonProgress
              startIcon={
                viewPropertiesLoading ? undefined : <ViewPropertiesIcon />
              }
              variant='text'
              color='primary'
              className={classes.propertiesButton}
              loading={viewPropertiesLoading}
              onClick={() => viewProperties(deal.dealId)}
              spinnerStyle={{
                color: theme.palette.primary.main,
              }}
            >
              View Properties
            </ButtonProgress>
          </Box>
        )}
      </Box>
    </Box>
  );
};
