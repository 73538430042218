import { selectorFamily, waitForAll } from 'recoil';
import { lotData, lotAddress } from '../../../recoil';
import { Tax_AssessorQueryVariables } from '../../../recoil/__generated__/taxAssessor';
import { CommercialSaleCompsData } from '../types';

export const commercialSaleComps = selectorFamily<
  CommercialSaleCompsData,
  Tax_AssessorQueryVariables
>({
  key: 'LOT-PAGE/COMMERCIAL-SALE-COMPS',
  get:
    (params) =>
    ({ get }) => {
      const [lot_address, lot_data] = get(
        waitForAll([lotAddress(params), lotData(params)])
      );
      return { data: { lot_data, lot_address } };
    },
});
