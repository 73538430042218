import React from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  FormControlLabel,
  FormGroup,
  makeStyles,
  Switch,
} from '@material-ui/core';

import constants from 'src/const';
import {
  CORE_PROSPECT_VIEWS,
  RESULTS_TYPES,
} from 'src/products/core-prospect/constants';
import {
  getSearchParameters,
  isCondoFilterAvailable,
} from 'src/products/core-prospect/search-pages/selectors';
import { includeCondoUnits } from 'src/products/core-prospect/redux/query/actions';
import {
  changeOffset,
  selectAllRows,
} from 'src/products/core-prospect/redux/table/actions';
import { execQuery } from 'src/products/core-prospect/redux/query/actions/execQuery';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 13,
    marginTop: -5,
    marginBottom: 10,
  },
  switchRoot: {
    width: 40,
    height: 18,
    padding: 0,
    marginRight: 10,
  },
  switchBase: {
    padding: 4,
  },
  switchTrack: {
    borderRadius: 20,
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
  switchThumb: {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 10,
    height: 10,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
}));

const CondoUnitsSwitch = ({
  isCondoFilterAvailable,
  areCondoUnitsIncluded,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <div className={classes.root}>
      <FormGroup
        style={{
          visibility: isCondoFilterAvailable ? 'visible' : 'hidden',
        }}
        row
      >
        <FormControlLabel
          control={
            <Switch
              classes={{
                root: classes.switchRoot,
                switchBase: classes.switchBase,
                track: classes.switchTrack,
                thumb: classes.switchThumb,
              }}
              checked={areCondoUnitsIncluded}
              onChange={(e) => {
                dispatch(includeCondoUnits(e.target.checked));
                dispatch(changeOffset(0));
                dispatch(
                  execQuery({
                    id: 'CondoSwitch',
                    cards: true,
                    count: true,
                    results: true,
                    viewportOnly: true,
                    rebound: false,
                  })
                );
                dispatch(selectAllRows(false, null));
              }}
              color='primary'
            />
          }
          label='Include condo units'
        />
      </FormGroup>
    </div>
  );
};

function mapStateToProps(state) {
  const {
    coreProspect: {
      query: { areCondoUnitsIncluded },
      view: { viewerName, resultsType },
    },
  } = state;

  const isTableVisible =
    viewerName === CORE_PROSPECT_VIEWS.TABLE ||
    resultsType !== RESULTS_TYPES.TAX_RECORDS;

  if (isTableVisible) {
    return {
      isTableVisible,
      areCondoUnitsIncluded,
      isCondoFilterAvailable: isCondoFilterAvailable(
        getSearchParameters(state)
      ),
      resultsFetchStatus: constants.LOADED,
    };
  }

  return {
    areCondoUnitsIncluded,
    isCondoFilterAvailable: isCondoFilterAvailable(getSearchParameters(state)),
    isTableVisible,
  };
}

export default connect(mapStateToProps)(CondoUnitsSwitch);
