import React from 'react';
import MultipleSelection from '../MultipleSelection/component';
import { Sectors } from '../../../../../utils';

const SectorsSelection = (args) => {
  return (
    <MultipleSelection
      {...args}
      data={{
        options: Sectors,
      }}
    />
  );
};

export default SectorsSelection;
