import {
  Checkbox,
  Chip,
  CircularProgress,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { paginatedCities, paginatedZipCodes } from './recoil';
import { getSearchParameters } from 'src/products/core-prospect/search-pages/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { resetSearchParameters } from 'src/products/core-prospect/redux/query/actions';
import Search from '@material-ui/icons/Search';
import Clear from '@material-ui/icons/Clear';
import { removeGeoSearchParameters } from 'src/products/core-prospect/redux/query/utils';

// STYLE

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '10px 26px',
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'auto auto 1fr auto',
    overflow: 'auto',
    gap: 10,
  },
  zipCodeContainer: {
    flex: 1,
    overflow: 'auto',
    borderRadius: 5,
    border: `solid 1px ${theme.palette.grey[500]}`,
  },
  zipCodeCell: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    padding: '0px 5px',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.grey[100],
    },
  },
  searchCell: {
    padding: '5px 10px',
    width: '100%',
  },
  checkboxRoot: {
    padding: 5,
    color: theme.palette.primary.main,
  },
  progressContainer: {
    display: 'grid',
    placeItems: 'center',
    padding: 10,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.grey[900],
  },
  chipsContainer: {
    display: 'flex',
    gap: 5,
    maxHeight: 63,
    flexWrap: 'wrap',
    width: '280px',
  },
}));

const getPersistedCity = (id: string) =>
  window.localStorage.getItem(`ZIPCODE-CITY-SELECTED:${id}`) || '';

const setPersistedCity = (id: string, newValue: string) =>
  window.localStorage.setItem(`ZIPCODE-CITY-SELECTED:${id}`, newValue);

const ZIPComponent: React.VFC = () => {
  const classes = useStyles();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const currentUser = useSelector((state: any) => state.user.profile.value);

  const [search, setSearch] = React.useState(getPersistedCity(currentUser.id));
  const [city, setCity] = React.useState(getPersistedCity(currentUser.id));

  React.useEffect(() => {
    setPersistedCity(currentUser.id, city);
  }, [city]);

  const [zipCodeSearch, setZipCodeSearch] = React.useState('');

  const cities = useRecoilValue(
    paginatedCities({ city: search ? `%${search}%` : '%' })
  );

  const zipCodes = useRecoilValue(
    paginatedZipCodes({
      city: city || '%',
      zip_code: zipCodeSearch ? `%${zipCodeSearch}%` : '%',
    })
  );

  const searchParameters = useSelector(getSearchParameters);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectedZipCodes: any[] = searchParameters.filter(
    (param) => 'zip_code' in param.attributes
  );

  const isZipCodeSelected = (zipcode: string) =>
    selectedZipCodes.some(
      (param) =>
        param.attributes.zip_code === `${zipcode}` && !param.isAutocomplete
    );

  const dispatch = useDispatch();

  const toggleZipCode = async (zipCodeData: (typeof zipCodes)['data'][0]) => {
    let updatedSearchParameters;

    if (isZipCodeSelected(zipCodeData.zip_code)) {
      updatedSearchParameters = [...searchParameters].filter(
        (param) => param.attributes.zip_code !== `${zipCodeData.zip_code}`
      );

      dispatch(resetSearchParameters(updatedSearchParameters));
    } else {
      updatedSearchParameters = [
        ...removeGeoSearchParameters(searchParameters, {
          isProtected: (searchParameter) => {
            return Boolean(searchParameter.attributes.zip_code);
          },
        }),
        {
          field_name: 'custom_search_area',
          entity_name: 'usa_map_parcel',
          attributes: {
            value: {
              geometry: zipCodeData.geometry,
              properties: {},
              type: 'Feature',
            },
            displayText: `${zipCodeData.zip_code} - ${zipCodeData.city}`,
            ...zipCodeData,
          },
        },
      ];
      dispatch(resetSearchParameters(updatedSearchParameters));
    }
  };

  return (
    <div className={classes.container}>
      <Autocomplete
        options={cities.data}
        loading={cities.loading}
        getOptionLabel={({ city }) => city}
        inputValue={search}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        value={city ? ({ city } as any) : null}
        onInputChange={(_, value) => setSearch(value)}
        renderInput={(props) => (
          <TextField
            {...props}
            label='Select city'
            variant='outlined'
            size='small'
          />
        )}
        onChange={(_, value) => {
          setCity(value?.city || '');
          setZipCodeSearch('');
        }}
        ListboxProps={{
          onScroll: cities.onScrollFetchMore,
        }}
      />
      <Typography className={classes.title}>ZIP Codes</Typography>
      <div
        className={classes.zipCodeContainer}
        onScroll={zipCodes.onScrollFetchMore}
      >
        <div className={classes.searchCell}>
          <TextField
            style={{ width: '100%' }}
            value={zipCodeSearch}
            onChange={(e) => setZipCodeSearch(e.target.value)}
            placeholder='Search'
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position='start'>
                  <Search fontSize='small' />
                </InputAdornment>
              ),
              endAdornment: zipCodeSearch ? (
                <InputAdornment position='end'>
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => setZipCodeSearch('')}
                  >
                    <Clear fontSize='small' />
                  </div>
                </InputAdornment>
              ) : undefined,
            }}
          />
        </div>
        {zipCodes.data.map((zipCodeData) => (
          <div
            key={zipCodeData.zip_code}
            className={classes.zipCodeCell}
            onClick={() => toggleZipCode(zipCodeData)}
          >
            <Checkbox
              color='primary'
              classes={{ root: classes.checkboxRoot }}
              checked={isZipCodeSelected(zipCodeData.zip_code)}
            />
            <Typography>
              {zipCodeData.zip_code} - {zipCodeData.city}
            </Typography>
          </div>
        ))}
        {zipCodes.loading ? (
          <div className={classes.progressContainer}>
            <CircularProgress size={20} />
          </div>
        ) : null}
      </div>
      <div className={classes.chipsContainer}>
        {selectedZipCodes.map((selectedZipCode) => (
          <Chip
            style={{ maxWidth: '100%' }}
            key={selectedZipCode.attributes.zip_code}
            onDelete={() => toggleZipCode(selectedZipCode.attributes)}
            size='small'
            label={`${selectedZipCode.attributes.zip_code} - ${
              selectedZipCode.attributes.city ||
              selectedZipCode.attributes.reference_1
            }`}
          />
        ))}
        <span style={{ height: '10px', width: '100%' }} />
      </div>
    </div>
  );
};

export default ZIPComponent;
