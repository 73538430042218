export const UNMASKED_OWNERS = /* GraphQL */ `
  query Owners($tax_assessor_id: numeric!) {
    tax_assessor_v2(where: { tax_assessor_id: { _eq: $tax_assessor_id } }) {
      cherre_parcel_id
      owner: usa_owner_unmask_v2__tax_assessor_id {
        owner_type
        last_seen_date
        cherre_address__address {
          display_address
        }
        occurrences_count
        owner_id
        owner_name
        owner_state
        owner_type
        tax_assessor_id
        cherre_parcel_id
      }
    }
  }
`;
