import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  container: {
    breakInside: 'avoid',
    display: 'grid',
    padding: '24px 0px',
    gap: '24px',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '2fr 1fr',
    },
    '@media print': {
      gridTemplateColumns: '2fr 1fr',
    },
  },
}));
