import * as QUERY_TYPES from '../query/types';
import { get } from 'lodash';
import { FetchStatus } from '../query/reducers';
import { Reducer } from 'redux';

type Lot = {
  cherre_parcel_id: string;
  tax_assessor_id: string;
  latitude: number;
  longitude: number;
};

type Cluster = {
  count: number;
  latitude: number;
  longitude: number;
};

type RCASale = {
  status_date: string;
  price: number;
  price_per_sq_ft_unit: string;
  sq_ft: number;
  unit_count: number;
  closed_cap_rate: string;
  buyer_name_1: string;
  seller_name_1: string;
};

type Card = {
  zone_code: string;
  tax_assessor_id: number;
  state: string;
  city: string;
  state_fips_code: string;
  cherre_state_from_fips_code: string;
  zip: string;
  one_line_address: string;
  county_name: string;
  county_fips_code: string;
  unit_number: string;
  units_count: number;
  cherre_parcel_id: string;
  tax_bill_amount: number;
  assessed_value_total: number;
  assessed_value_improvements: number;
  assessed_value_land: number;
  assessed_tax_year: string;
  market_value_total: number;
  market_value_improvements: number;
  market_value_land: number;
  property_use_code_mapped: number;
  last_sale_amount: number;
  tax_assessor_last_update_date: string;
  property_use_standardized_code: string;
  assessor_parcel_number_raw: string;
  msa_name: string;
  address: string;
  location: {
    lat: string;
    lon: string;
  };
  tax_assessor_owner: Array<{
    owner_name: string;
    owner_type: string;
  }>;
  usa_owner_unmask: Array<{
    owner_id: string;
    owner_name: string;
    owner_type: string;
  }>;
  recorder_summary: Array<{
    cherre_latest_mortgage_lender: string;
    cherre_latest_mortgage_borrower: string;
    cherre_latest_deed_date: string;
    cherre_latest_deed_amount: number;
  }>;
  tax_assessor_reis_boundaries: Array<{
    cherre_reis_boundaries_pk: string;
  }>;
  rca_sales_demo: RCASale[];
  brookfield_rca_sales: RCASale[];
  starwood_rca_sales: RCASale[];
  trepp_loan_demo: Array<{
    cutoff_balance: number;
    allocated_balance_at_securitization: number;
    origination_date: string;
    maturity_date: string;
    securitization_ltv: string;
    note_rate_at_securitization: string;
    remaining_term: number;
    most_recent_physical_occupancy: number;
    is_newly_on_watchlist: boolean;
    cherre_trepp_property_pk: string;
  }>;
  geotiles: Record<string, string>;
  unmasked_owners_by_type: {
    individual: string[];
    company: string[];
    education: string[];
    government: string[];
    unknown: string[];
  };
  recorded_owners_by_type: {
    individual: string[];
    company: string[];
    education: string[];
    government: string[];
    unknown: string[];
  };
};

type MapResultsState = {
  lots: Array<Lot | Cluster>;
  cards: Card[];
  resultsCount: number;
  resultsCountFetchStatus: FetchStatus;
  resultType?: string;
  selectedLot: Lot | Record<any, any>;
  exec_query_timestamp?: number;
  exec_count_query_timestamp?: number;
  exec_map_cards_query_timestamp?: number;
};

const initialState: MapResultsState = {
  lots: [],
  cards: [],
  resultsCount: 0,
  resultsCountFetchStatus: {},
  resultType: undefined,
  selectedLot: {},
  exec_query_timestamp: undefined,
  exec_count_query_timestamp: undefined,
  exec_map_cards_query_timestamp: undefined,
};

type MapResultsReducer = Reducer<MapResultsState>;

export const mapPinResults: MapResultsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case QUERY_TYPES.EXEC_MAP_QUERY_REQUEST: {
      const { timestamp } = action;

      return {
        ...state,
        exec_query_timestamp: timestamp,
        selectedLot: {},
      };
    }

    case QUERY_TYPES.EXEC_MAP_QUERY_SUCCESS: {
      const { timestamp, data } = action;

      if (timestamp !== state.exec_query_timestamp) {
        return state;
      }

      return {
        ...state,
        resultType: data.resultType,
        lots: data.result || [],
      };
    }

    case QUERY_TYPES.EXEC_MAP_CARDS_QUERY_REQUEST: {
      const { timestamp } = action;
      return {
        ...state,
        exec_map_cards_query_timestamp: timestamp,
      };
    }

    case QUERY_TYPES.EXEC_MAP_CARDS_QUERY_SUCCESS: {
      const { data, startOver, timestamp } = action;
      const newCards = data.result || [];

      if (timestamp !== state.exec_map_cards_query_timestamp) {
        return state;
      }

      return {
        ...state,
        cards: startOver ? newCards : state.cards.concat(newCards),
      };
    }

    case QUERY_TYPES.EXEC_MAP_COUNT_QUERY_REQUEST: {
      const { timestamp } = action;

      return {
        ...state,
        exec_count_query_timestamp: timestamp,
        resultsCountFetchStatus: { value: 'LOADING' },
      };
    }

    case QUERY_TYPES.EXEC_MAP_COUNT_QUERY_SUCCESS: {
      const { timestamp } = action;

      if (timestamp !== state.exec_count_query_timestamp) {
        return state;
      }

      return {
        ...state,
        resultsCount: get(action, 'data.result', 0),
        resultsCountFetchStatus: { value: 'LOADED' },
      };
    }

    case QUERY_TYPES.EXEC_COUNT_ALL_QUERY_REQUEST: {
      return {
        ...state,
        resultsCountFetchStatus: { value: 'LOADING' },
      };
    }
    case QUERY_TYPES.EXEC_COUNT_ALL_QUERY_SUCCESS: {
      return {
        ...state,
        resultsCount: get(action, 'data.result.1.1', 0),
        resultsCountFetchStatus: { value: 'LOADED' },
        resultType: get(action, 'data.result.4.0', 0),
        lots: get(action, 'data.result.4.1', 0) || [],
      };
    }

    default:
      return state;
  }
};
