import * as TYPES from './types';
import { CORE_PROSPECT_VIEWS } from 'src/products/core-prospect/constants';
import { MARKETS_TABS, RESULTS_TYPES } from '../../constants';
import { Reducer } from 'redux';

type ViewState = {
  viewerName: string;
  resultsType: string;
  marketTab: string;
  isSaveSearchDialogOpen: boolean;
  isSidebarCollapsed: boolean;
  isCardsVisible: boolean;
};

const initialState: ViewState = {
  viewerName: CORE_PROSPECT_VIEWS.MAP,
  resultsType: RESULTS_TYPES.TAX_RECORDS,
  marketTab: MARKETS_TABS.ZIPCODES.value,
  isSaveSearchDialogOpen: false,
  isSidebarCollapsed: false,
  isCardsVisible: false,
};

type ViewReducer = Reducer<ViewState>;

export const view: ViewReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.TOGGLE_CORE_PROSPECT_VIEW: {
      const { viewerName } = action;
      return { ...state, viewerName };
    }

    case TYPES.SET_RESULTS_TYPE: {
      const { resultsType } = action;
      return { ...state, resultsType };
    }

    case TYPES.SET_MARKET_TAB: {
      const { marketTab } = action;
      return { ...state, marketTab };
    }

    case TYPES.OPEN_SAVE_SEARCH_DIALOG:
      return {
        ...state,
        isSaveSearchDialogOpen: true,
      };

    case TYPES.CLOSE_SAVE_SEARCH_DIALOG:
      return {
        ...state,
        isSaveSearchDialogOpen: false,
      };

    case TYPES.COLLAPSE_SIDEBAR: {
      return {
        ...state,
        isSidebarCollapsed: true,
      };
    }
    case TYPES.EXPAND_SIDEBAR: {
      return {
        ...state,
        isSidebarCollapsed: false,
      };
    }

    case TYPES.HIDE_CARDS: {
      return {
        ...state,
        isCardsVisible: false,
      };
    }
    case TYPES.DISPLAY_CARDS: {
      return {
        ...state,
        isCardsVisible: true,
      };
    }

    default:
      return state;
  }
};
