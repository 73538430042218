import React from 'react';
import { TYPE_TRANSACTION, TYPE_MORTGAGE, TYPE_LOAN } from '../../common';

const textComponents = {
  [TYPE_TRANSACTION]: require('./TimelineTransactionText').default,
  [TYPE_MORTGAGE]: require('./TimelineMortgageText').default,
  [TYPE_LOAN]: require('./TimelineLoanText').default,
};

const TimelineItemBottomRowText = ({ item, isNyc }) => {
  const TextComponent = textComponents[item.textType];

  return (
    <React.Fragment>
      <TextComponent item={item} isNyc={isNyc} />
    </React.Fragment>
  );
};

export default TimelineItemBottomRowText;
