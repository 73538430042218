import { Typography } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import React from 'react';
import ListIcon from 'src/components/InteractiveList/ListIcon';
import List from 'src/components/InteractiveList/List';
import useStyles from '../../styles';
import * as T from './types';

const UnitSelector: T.UnitSelector = ({
  options = [],
  selected,
  selectUnitAction,
}) => {
  const classes = useStyles();
  const ref = React.useRef<any>(null);

  if (options.length <= 1) {
    return null;
  }

  return (
    <ListIcon
      component={
        options.length > 1 ? (
          <span className={classes.unitSelectorWrap}>
            <Typography variant='h3'>{selected?.label}</Typography>
            <ChevronLeft className={classes.chevrone} />
          </span>
        ) : (
          <span />
        )
      }
      ref={ref}
      title='Add to property lists'
      disableText='Property Lists Coming Soon'
      id='lot-page-property-units-icon'
    >
      <div>
        <List
          id='core-prospect-unit-selector-list'
          placeholder='Search'
          items={options}
          selected={selected ? [selected] : undefined}
          onItemSelect={(item) => {
            selectUnitAction?.(item);
            ref.current.close();
          }}
        />
      </div>
    </ListIcon>
  );
};

export default UnitSelector;
