import { Link } from '@material-ui/core';
import React from 'react';
import { TYPE_PERSON } from 'src/products/core-prospect/pages/lot-page/panels/PropertyOwners/common';
import { PIPL } from 'src/products/core-prospect/pages/lot-page/panels/PropertyOwners/pipl';

type Props = {
  ownerId: string;
  street?: string;
  city?: string;
};

const Contacts: React.FC<Props> = ({ ownerId, street, city }) => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState<boolean>(false);

  const [name, ownerType, state] = ownerId?.split('::') || [];

  const piplQuery = React.useMemo(
    () => ({
      raw_name: name,
      one_line_address: `${street}, ${city} ${state}`,
    }),
    [name, city, state, street]
  );

  if (ownerType === TYPE_PERSON) {
    return (
      <>
        <div
          onClick={() => {
            setIsDrawerOpen(!isDrawerOpen);
          }}
        >
          <Link>View contact info</Link>
        </div>
        {isDrawerOpen && (
          <PIPL query={piplQuery} onClose={() => setIsDrawerOpen(false)} />
        )}
      </>
    );
  }

  return null;
};

export default Contacts;
