import { selectorFamily } from 'recoil';
import { nycDetailsWhenAvailable, taxAssessorQuery } from '../../../recoil';
import { lotAddress } from '../../../recoil/lotAddress';
import { lotData } from '../../../recoil/lotData';
import { Tax_AssessorQueryVariables } from '../../../recoil/__generated__/taxAssessor';
import { PropertyOwnersData } from '../types';
import {
  nycDetailsToPropertyOwners,
  taxAssessorToPropertyOwners,
} from './formatters';

export const propertyOwners = selectorFamily<
  PropertyOwnersData,
  Tax_AssessorQueryVariables
>({
  key: 'LOT-PAGE/PROPERTY-OWNERS',
  get:
    (params) =>
    ({ get }) => {
      const taxAssessorResult = get(taxAssessorQuery(params));

      let owner = taxAssessorToPropertyOwners(taxAssessorResult);

      const nycDetailsResult = get(
        nycDetailsWhenAvailable(
          taxAssessorResult.tax_assessor_v2?.[0]?.cherre_bbl
        )
      );

      if (nycDetailsResult) {
        owner = nycDetailsToPropertyOwners(nycDetailsResult);
      }

      const lotAddressResult = get(lotAddress(params));
      const lotDataResult = get(lotData(params));
      return {
        data: { lot_data: lotDataResult, lot_address: lotAddressResult, owner },
      };
    },
});
