import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { atom, useRecoilState, useSetRecoilState } from 'recoil';
import { resetCoreExplore } from '../../redux/getActions';

const isResetCoreExploreEnabledAtom = atom({
  key: 'CORE-EXPLORE/SEARCH-PAGE/RESET_ENABLED',
  default: true,
});

export const useDisableNextCoreExploreReset = () => {
  const setIsResetCoreExploreEnabled = useSetRecoilState(
    isResetCoreExploreEnabledAtom
  );

  return {
    disableNextReset: () => setIsResetCoreExploreEnabled(false),
  };
};

export const useResetCoreExplore = () => {
  const dispatch = useDispatch();

  const [isResetCoreExploreEnabled, setIsResetCoreExploreEnabled] =
    useRecoilState(isResetCoreExploreEnabledAtom);

  // On Mount Hook
  useEffect(() => {
    if (isResetCoreExploreEnabled) {
      dispatch(resetCoreExplore);
    } else {
      setIsResetCoreExploreEnabled(true);
    }
  }, []);
};
