import { Visibility } from 'mapbox-gl';
import { LayerProps } from 'src/components/Map/types';
import { LABELS_FONT, LAYERS, SOURCES } from '../const';

const LAYER_APARTMENT = 'apartment';
const LAYER_FLEX = 'flex';
const LAYER_OFFICE = 'office';
const LAYER_RETAIL = 'retail';
const LAYER_WAREHOUSE = 'warehouse';

type GetLayerArg = {
  id: string;
  layer: string;
  visibility: Visibility;
};

function getLines({ id, layer, visibility }: GetLayerArg): LayerProps {
  return {
    id,
    source: SOURCES.REIS_BOUNDARIES,
    'source-layer': layer,
    type: 'line',
    paint: {
      'line-color': 'hsl(22, 80%, 50%)',
      'line-width': ['interpolate', ['linear'], ['zoom'], 7, 0.5, 12, 1],
    },
    layout: {
      visibility,
    },
  };
}

function getLabels({ id, layer, visibility }: GetLayerArg): LayerProps {
  return {
    id,
    source: SOURCES.REIS_BOUNDARIES,
    'source-layer': layer,
    type: 'symbol',
    minzoom: 7,
    layout: {
      'text-field': '{submarket_name}',
      'text-size': 18,
      'text-allow-overlap': true,
      'text-ignore-placement': true,
      'text-font': LABELS_FONT,
      visibility,
    },
    paint: {
      'text-color': 'hsl(22, 80%, 50%)',
      'text-halo-color': '#eee',
      'text-halo-width': 1,
    },
  };
}

type GetLayersArg = {
  apartmentVisibility: 'visible' | 'none';
  flexVisibility: 'visible' | 'none';
  officeVisibility: 'visible' | 'none';
  retailVisibility: 'visible' | 'none';
  warehouseVisibility: 'visible' | 'none';
};

function getReisBoundariesLayers({
  apartmentVisibility,
  flexVisibility,
  officeVisibility,
  retailVisibility,
  warehouseVisibility,
}: GetLayersArg): LayerProps[] {
  return [
    getLines({
      id: LAYERS.REIS_BOUNDARIES_APARTMENT_LINES,
      layer: LAYER_APARTMENT,
      visibility: apartmentVisibility,
    }),
    getLabels({
      id: LAYERS.REIS_BOUNDARIES_APARTMENT_LABELS,
      layer: LAYER_APARTMENT,
      visibility: apartmentVisibility,
    }),
    getLines({
      id: LAYERS.REIS_BOUNDARIES_FLEX_LINES,
      layer: LAYER_FLEX,
      visibility: flexVisibility,
    }),
    getLabels({
      id: LAYERS.REIS_BOUNDARIES_FLEX_LABELS,
      layer: LAYER_FLEX,
      visibility: flexVisibility,
    }),
    getLines({
      id: LAYERS.REIS_BOUNDARIES_OFFICE_LINES,
      layer: LAYER_OFFICE,
      visibility: officeVisibility,
    }),
    getLabels({
      id: LAYERS.REIS_BOUNDARIES_OFFICE_LABELS,
      layer: LAYER_OFFICE,
      visibility: officeVisibility,
    }),
    getLines({
      id: LAYERS.REIS_BOUNDARIES_RETAIL_LINES,
      layer: LAYER_RETAIL,
      visibility: retailVisibility,
    }),
    getLabels({
      id: LAYERS.REIS_BOUNDARIES_RETAIL_LABELS,
      layer: LAYER_RETAIL,
      visibility: retailVisibility,
    }),
    getLines({
      id: LAYERS.REIS_BOUNDARIES_WAREHOUSE_LINES,
      layer: LAYER_WAREHOUSE,
      visibility: warehouseVisibility,
    }),
    getLabels({
      id: LAYERS.REIS_BOUNDARIES_WAREHOUSE_LABELS,
      layer: LAYER_WAREHOUSE,
      visibility: warehouseVisibility,
    }),
  ];
}

export default getReisBoundariesLayers;
