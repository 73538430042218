import React from 'react';
import { useSelector } from 'react-redux';

import Title from 'src/products/core-prospect/search-pages/components/Sidebar/components/Title.js';
import Searches from './Searches';

import Tabs from './Tabs';

import { getViews as getViewsSelector } from 'src/products/core-prospect/search-pages/selectors';

import useLocation from 'src/hooks/useLocation';

const SearchesView = () => {
  const {
    query: { tab },
  } = useLocation();

  const views = useSelector(getViewsSelector);

  return (
    <React.Fragment>
      <Title title='Saved searches' id='js-core-prospect-searches-title' />
      <div style={{ margin: '0 20px' }}>
        <Tabs />
        <br />
        <Searches
          id='js-core-prospect-searches-list'
          views={!tab || tab === 'searches' ? views : []}
        />
      </div>
    </React.Fragment>
  );
};

SearchesView.displayName = 'SearchesView';

export default SearchesView;
