import axios from 'axios';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRecoilCallback } from 'recoil';
import { teamInfo } from 'src/products/customerAdmin/pages/Teams/recoil';
import { showModal } from 'src/products/shell/redux/modals/actions';
import { useSnackbarCherre } from './useSnackbarCherre';

export const useTeamAddMembersModal = (
  teamId: number,
  onRequestClose: () => void
) => {
  const [isSaving, setIsSaving] = useState(false);
  const { enqueueSnackbar } = useSnackbarCherre();

  const onClickAdd = useRecoilCallback(
    ({ refresh }) =>
      async (memberIds: number[]) => {
        setIsSaving(true);

        try {
          await axios.post(`/api/v1/teams/${teamId}/members`, { memberIds });

          enqueueSnackbar(`Members successfully added`, {
            variant: 'success',
          });

          refresh(teamInfo({ id: teamId }));

          onRequestClose();
        } catch (e) {
          console.error(e);
          enqueueSnackbar('Failed add members', {
            variant: 'error',
          });
          setIsSaving(false);
        }
      },
    [setIsSaving, teamId]
  );

  return {
    onClickAdd,
    isSaving,
  };
};

export const useTeamAddMembers = () => {
  const dispatch = useDispatch();

  return useRecoilCallback(
    () => async (teamId: number, currentMembers: number[]) => {
      dispatch(
        showModal('TEAMS_ADD_MEMBERS', {
          teamId,
          currentMembers,
        })
      );
    },
    []
  );
};
