import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { updateList } from 'src/products/core-prospect/redux/propertyLists/actions';
import Title from 'src/products/core-prospect/search-pages/components/Sidebar/components/Title';

import LotsList from '../LotCardsView';
import { expandSidebar } from 'src/products/core-prospect/redux/view/actions';
import useLocation from 'src/hooks/useLocation';

const PropertyListView = () => {
  const dispatch = useDispatch();

  const {
    query: { list_id },
  } = useLocation();

  const { propertyLists, isSidebarCollapsed } = useSelector((state) => ({
    propertyLists: state.coreProspect.propertyLists.propertyLists.value,
    isSidebarCollapsed: state.coreProspect.view.isSidebarCollapsed,
  }));

  const propertyList = propertyLists.find(
    (propertyList) => propertyList.id === Number(list_id)
  );

  if (!propertyList) {
    return null;
  }

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Title
        allowEditing
        onRename={(data) => {
          dispatch(updateList(propertyList.id, data));
        }}
        onBackClick={() => dispatch(expandSidebar())}
        backUrl='/properties?sidebar=property-lists'
        title={propertyList.name}
        id='js-core-prospect-property-list-item-title'
      />
      {!isSidebarCollapsed && <LotsList />}
    </div>
  );
};

export default PropertyListView;
