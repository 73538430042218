import { Typography } from '@material-ui/core';
import React from 'react';
import { HorizontalKeyValueProps } from './types';

const HorizontalKeyValue: React.VFC<HorizontalKeyValueProps> = ({
  label,
  value,
}) => {
  return (
    <div
      style={{
        marginBottom: '10px',
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        gap: 5,
      }}
    >
      <Typography variant='body1'>{label}:</Typography>
      <Typography
        variant='body1'
        style={{ fontWeight: 700 }}
        className='suspend'
      >
        {value}
      </Typography>
    </div>
  );
};

HorizontalKeyValue.displayName = 'HorizontalKeyValue';

export default HorizontalKeyValue;
