import { MapBounds } from 'src/components/Map/index.new';
import * as TYPES from './types';

export const toggleSatelliteVisibility = (isSatelliteVisible) => {
  return {
    type: TYPES.TOGGLE_SATELLITE_VISIBILITY,
    isSatelliteVisible,
  };
};

export const syncMapViewport = (
  bounds,
  zoom,
  latitude,
  longitude,
  width,
  height
) => {
  return {
    type: TYPES.SYNC_MAP_VIEWPORT,
    currentViewport: {
      bounds,
      width,
      height,
      zoom,
      latitude,
      longitude,
    },
  };
};

export const setDrawingMode = (drawingMode) => {
  if (drawingMode) {
    window.analytics?.track('Draw Tool Used');
  }
  return {
    type: TYPES.SET_DRAWING_MODE,
    drawingMode,
  };
};

export const setFullMapMode = (isInFullMode) => {
  return {
    type: TYPES.SET_FULL_MAP_MODE,
    isInFullMode,
  };
};

export type RecenterMapArgs = {
  zoom?: number;
  longitude?: number;
  latitude?: number;
  bounds?: MapBounds;
  padding?: number;
};

export type RecenterMapActionPayload = {
  type: string;
  zoom?: number;
  longitude?: number;
  latitude?: number;
  bounds?: MapBounds;
  padding?: number;
};

export const recenterMap = ({
  zoom,
  longitude,
  latitude,
  bounds,
  padding,
}: RecenterMapArgs): RecenterMapActionPayload => {
  return {
    type: TYPES.RECENTER_MAP,
    zoom,
    longitude,
    latitude,
    bounds,
    padding,
  };
};
