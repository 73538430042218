import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';

import { Context } from 'src/products/core-prospect/search-pages/USALot';

const DeleteSnackbar = ({
  onClose,
  isOpen,
  text,
  actionText = 'UNDO',
  onActionClick,
  classes,
  ...otherProps
}) => {
  const { isCompact } = React.useContext(Context);

  return (
    <Snackbar
      style={{
        marginBottom: isCompact ? 0 : 20,
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      ContentProps={{
        classes: {
          message: classes.break,
        },
      }}
      autoHideDuration={5000}
      open={isOpen}
      onClose={onClose}
      message={<span id='message-id'>{text}</span>}
      action={[
        onActionClick ? (
          <Button
            id='js-core-prospect-delete-undo'
            key='undo'
            color='primary'
            size='small'
            onClick={onActionClick}
          >
            {actionText}
          </Button>
        ) : null,
        <IconButton
          key='close'
          aria-label='Close'
          color='inherit'
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>,
      ].filter(Boolean)}
      {...otherProps}
    />
  );
};
const styles = {
  break: {
    wordBreak: 'break-word',
  },
};

export default withStyles(styles)(DeleteSnackbar);
