import { GEOGRAPHY_BY_ZOOM_LEVEL } from './const';
import { LayerGroup, LayerGroupName, LayerName } from '../../types';

export const getSelectedLayerNames = (
  layerGroups: LayerGroup[]
): LayerName[] => {
  return layerGroups.reduce<LayerName[]>((layerNames, group) => {
    if (group.radio && group.checked && group.checkedLayerName) {
      layerNames.push(group.checkedLayerName);
    } else {
      group.layers.forEach((layer) => {
        if (layer.checked) {
          if (layer.checkedLayerName) {
            layerNames.push(layer.checkedLayerName);
          } else {
            layerNames.push(layer.name);
          }
        }
      });
    }

    return layerNames;
  }, []);
};

export const getSelectedDemographicsLayerName = (
  layerGroups: LayerGroup[]
): LayerName | undefined => {
  const demographicsLayersGroup = layerGroups.find(
    (group) => group.groupName === LayerGroupName.demographicsLayers
  );

  if (!demographicsLayersGroup) {
    return;
  }

  return demographicsLayersGroup.checkedLayerName;
};

export const getDemographicsGeographyNameByZoom = (zoom?: number) => {
  if (!zoom) {
    return;
  }

  const zoomIndex = GEOGRAPHY_BY_ZOOM_LEVEL.find((zoomIndex) => {
    return zoom >= zoomIndex.zoom.min && zoom < zoomIndex.zoom.max;
  });

  return zoomIndex ? zoomIndex.geographyName : undefined;
};
