export default /* GraphQL */ `
  query LinkedAddresses($cherre_parcel_ids: [String!]) {
    tax_assessor_v2(
      where: { cherre_parcel_id: { _in: $cherre_parcel_ids } }
      order_by: { cherre_parcel_id: desc }
      distinct_on: cherre_parcel_id
    ) {
      tax_assessor_id
      cherre_parcel_id
      cherre_address__property_address {
        display_address
      }
    }
  }
`;
