import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  header: {
    padding: '12px 0px',
  },
  container: {
    display: 'grid',
    gap: 15,
  },
}));
