import { number } from '../../../utils';
import { TaxAssessorToLotSizeFormatter } from '../types';

export const taxAssessorToLotSize: TaxAssessorToLotSizeFormatter = (data) => {
  const tax_assessor = data.tax_assessor_v2[0];

  const size = number(tax_assessor.lot_size_sq_ft, 'sqft');

  const width = number(tax_assessor.lot_width, 'ft');

  const depth = number(tax_assessor.lot_depth_ft, 'ft');

  const grossArea = number(tax_assessor.gross_sq_ft, 'sqft');

  const lotSizeAcre = number(tax_assessor.lot_size_acre);

  return { size, width, depth, grossArea, lotSizeAcre };
};
