import { GetUsaDemographicsQuery } from './__generated__/query';

export type FormattedDemographicsStats = ReturnType<
  typeof formatDemographicsStats
>;

export const formatDemographicsStats = (data?: GetUsaDemographicsQuery) => {
  const demographics = data?.usa_demographics_v2[0] || {};

  return {
    household_income: {
      average: {
        label: 'Median Income',
        value: demographics.median_household_income,
      },
      rows: [
        {
          label: '$0 - 10k',
          value: demographics.household_income_0_10_count,
        },
        {
          label: '$10 - 15k',
          value: demographics.household_income_10_15_count,
        },
        {
          label: '$15 - 20k',
          value: demographics.household_income_15_20_count,
        },
        {
          label: '$20 - 25k',
          value: demographics.household_income_20_25_count,
        },
        {
          label: '$25 - 30k',
          value: demographics.household_income_25_30_count,
        },
        {
          label: '$30 - 35k',
          value: demographics.household_income_30_35_count,
        },
        {
          label: '$35 - 40k',
          value: demographics.household_income_35_40_count,
        },
        {
          label: '$40 - 45k',
          value: demographics.household_income_40_45_count,
        },
        {
          label: '$45 - 50k',
          value: demographics.household_income_45_50_count,
        },
        {
          label: '$50 - 60k',
          value: demographics.household_income_50_60_count,
        },
        {
          label: '$60 - 75k',
          value: demographics.household_income_60_75_count,
        },
        {
          label: '$75 - 100k',
          value: demographics.household_income_75_100_count,
        },
        {
          label: '$100 - 125k',
          value: demographics.household_income_100_125_count,
        },
        {
          label: '$125 - 150k',
          value: demographics.household_income_125_150_count,
        },
        {
          label: '$150 - 200k',
          value: demographics.household_income_150_200_count,
        },
        {
          label: '$200 - 250k',
          value: demographics.household_income_200_250_count,
        },
        {
          label: '$250 - 300k',
          value: demographics.household_income_250_300_count,
        },
        {
          label: '$500+',
          value: demographics.household_income_over_500_count,
        },
      ],
    },
    crime: {
      average: {
        label: 'Total Crime Risk',
        value: demographics.crime_total_risk,
      },
      rows: [
        {
          label: 'Assault Risk',
          value: demographics.crime_assault_risk,
        },
        {
          label: 'Burglary Risk',
          value: demographics.crime_burglary_risk,
        },
        {
          label: 'Larceny Risk',
          value: demographics.crime_larceny_risk,
        },
        {
          label: 'Murder Risk',
          value: demographics.crime_murder_risk,
        },
        {
          label: 'Motor Vehicle Risk',
          value: demographics.crime_motor_vehicle_risk,
        },
        {
          label: 'Personal Risk',
          value: demographics.crime_personal_risk,
        },
        {
          label: 'Property Risk',
          value: demographics.crime_property_risk,
        },
        {
          label: 'Rape Risk',
          value: demographics.crime_rape_risk,
        },
        {
          label: 'Robbery Risk',
          value: demographics.crime_robbery_risk,
        },
      ],
    },
    population: {
      average: {
        label: 'Median Age (Years)',
        value: demographics.population_median_age,
      },
      rows: [
        {
          label: '0 - 4',
          value: demographics.population_age_00_04_count,
        },
        {
          label: '5 - 9',
          value: demographics.population_age_05_09_count,
        },
        {
          label: '10 - 14',
          value: demographics.population_age_10_14_count,
        },
        {
          label: '15 - 19',
          value: demographics.population_age_15_19_count,
        },
        {
          label: '20 - 24',
          value: demographics.population_age_20_24_count,
        },
        {
          label: '25 - 29',
          value: demographics.population_age_25_29_count,
        },
        {
          label: '30 - 34',
          value: demographics.population_age_30_34_count,
        },
        {
          label: '35 - 39',
          value: demographics.population_age_35_39_count,
        },
        {
          label: '40 - 44',
          value: demographics.population_age_40_44_count,
        },
        {
          label: '45 - 49',
          value: demographics.population_age_45_49_count,
        },
        {
          label: '50 - 54',
          value: demographics.population_age_50_54_count,
        },
        {
          label: '55 - 59',
          value: demographics.population_age_55_59_count,
        },
        {
          label: '60 - 64',
          value: demographics.population_age_60_64_count,
        },
        {
          label: '65 - 69',
          value: demographics.population_age_65_69_count,
        },
        {
          label: '70 - 74',
          value: demographics.population_age_70_74_count,
        },
        {
          label: '75 - 79',
          value: demographics.population_age_75_79_count,
        },
        {
          label: '80 - 84',
          value: demographics.population_age_80_84_count,
        },
        {
          label: '85+',
          value: demographics.population_age_over_85_count,
        },
      ],
    },
    education: {
      rows: [
        {
          label: 'Less than 9 years',
          value: demographics.education_less_than_9_count,
        },
        {
          label: 'Some high school',
          value: demographics.education_some_high_school_count,
        },
        {
          label: 'High school',
          value: demographics.education_high_school_graduate_count,
        },
        {
          label: 'Some college',
          value: demographics.education_some_college_count,
        },
        {
          label: "Associate's degree",
          value: demographics.education_associate_degree_count,
        },
        {
          label: "Bachelor's degree",
          value: demographics.education_bachelors_degree_count,
        },
        {
          label: 'Graduate degree',
          value: demographics.education_graduate_degree_count,
        },
      ],
    },
    employment: {
      rows: [
        {
          label: 'Business/Finance',
          value: demographics.employee_business_and_financial_operations_count,
        },
        {
          label: 'The Arts',
          value: demographics.employee_arts_count,
        },
        {
          label: 'Cleaning/Maintenance',
          value: demographics.employee_cleaning_maintenance_count,
        },
        {
          label: 'Office/Admin/Support',
          value: demographics.employee_office_and_admin_count,
        },
        {
          label: 'Computers/Math/Science',
          value: demographics.employee_computer_math_science_count,
        },
        {
          label: 'Construction',
          value: demographics.employee_construction_count,
        },
        {
          label: 'Social Services',
          value: demographics.employee_social_services_count,
        },
        {
          label: 'Education',
          value: demographics.employee_education_count,
        },
        {
          label: 'Architecture/Engineering',
          value: demographics.employee_architecture_engineering_count,
        },
        {
          label: 'Food Service',
          value: demographics.employee_food_service_count,
        },
        {
          label: 'Healthcare',
          value: demographics.employee_healthcare_count,
        },
        {
          label: 'Healthcare Practitioner',
          value: demographics.employee_healthcare_practitioner_count,
        },
        {
          label: 'Repair',
          value: demographics.employee_repair_count,
        },
        {
          label: 'Military',
          value: demographics.employee_military_count,
        },

        {
          label: 'Personal Care',
          value: demographics.employee_personal_care_count,
        },
        {
          label: 'Farming',
          value: demographics.employee_farming_count,
        },
        {
          label: 'Production',
          value: demographics.employee_production_count,
        },
        {
          label: 'Protective Service',
          value: demographics.employee_protective_service_count,
        },
        {
          label: 'Sales',
          value: demographics.employee_sales_count,
        },
        {
          label: 'Social Science',
          value: demographics.employee_social_science_count,
        },
        {
          label: 'Transportation',
          value: demographics.employee_transportation_count,
        },
        {
          label: 'Executive',
          value: demographics.employee_executive_count,
        },
      ],
    },
  };
};
