import {
  IconButton,
  IconButtonProps,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuItemProps,
  MenuProps,
  Tooltip,
} from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';
import React, { useCallback, useMemo, useState } from 'react';

export type ActionsAutoCollapseItem = {
  icon: React.ReactElement;
  text: string;
  id?: string;
  onClick?: (event: any) => void | Promise<void>;
  iconLoading?: React.ReactElement;
  iconButtonProps?: Partial<IconButtonProps<any, any>>;
  menuItemProps?: Partial<MenuItemProps<any, any>>;
};

export type ActionsAutoCollapseProps = {
  actions: ActionsAutoCollapseItem[];
  collapseCount: number;
  menuProps?: MenuProps;
};

const onClickWithLoadingWrapper = (
  onClick,
  setLoadingAction,
  i,
  iconLoading
) => {
  if (!iconLoading) {
    return onClick;
  }

  return onClick
    ? async (event: any) => {
        setLoadingAction((s) => {
          const newState = [...s];
          newState[i] = true;
          return newState;
        });

        try {
          await onClick(event);
        } finally {
          setLoadingAction((s) => {
            const newState = [...s];
            newState[i] = false;
            return newState;
          });
        }
      }
    : undefined;
};

export const ActionsAutoCollapse: React.FC<ActionsAutoCollapseProps> = ({
  children,
  actions,
  collapseCount,
  menuProps,
}) => {
  const [loadingAction, setLoadingAction] = useState(actions.map(() => false));

  if (!actions) {
    return null;
  }

  const visibleCount =
    actions.length <= collapseCount ? actions.length : collapseCount - 1;

  const visibleActions = useMemo(() => {
    const visible = [] as React.ReactElement[];

    for (let i = 0; i < visibleCount && i < actions.length; ++i) {
      const { text, icon, iconLoading, onClick, iconButtonProps } = actions[i];

      const onClickWrapper = onClickWithLoadingWrapper(
        onClick,
        setLoadingAction,
        i,
        iconLoading
      );

      const isLoading = loadingAction[i];

      visible.push(
        <Tooltip title={text} key={`action-icon-button-${i}`}>
          <IconButton
            id={`action-icon-${i}-${actions[i].id}`}
            size='small'
            onClick={onClickWrapper}
            {...iconButtonProps}
          >
            {isLoading ? iconLoading : icon}
          </IconButton>
        </Tooltip>
      );
    }

    return visible;
  }, [actions, visibleCount, loadingAction]);

  const collapsedActions = useMemo(() => {
    const collapsed = [] as React.ReactElement[];

    for (let i = visibleCount; i < actions.length; ++i) {
      const { text, icon, iconLoading, menuItemProps, onClick } = actions[i];

      const onClickWrapper = onClickWithLoadingWrapper(
        onClick,
        setLoadingAction,
        i,
        iconLoading
      );

      const isLoading = loadingAction[i];

      collapsed.push(
        <MenuItem
          key={`action-menu-item-${i}`}
          onClick={onClickWrapper}
          {...menuItemProps}
        >
          <ListItemIcon>{isLoading ? iconLoading : icon}</ListItemIcon>
          <ListItemText>{text}</ListItemText>
        </MenuItem>
      );
    }

    return collapsed;
  }, [actions, visibleCount, loadingAction]);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      {visibleActions}
      {collapsedActions.length > 0 && (
        <>
          <IconButton size='small' onClick={handleClick}>
            <MoreVert fontSize='small' color='primary' />
          </IconButton>
          <Menu
            keepMounted
            {...menuProps}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {collapsedActions}
          </Menu>
        </>
      )}
      {children}
    </>
  );
};
