import React from 'react';
import ArrowBack from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import grey from '@material-ui/core/colors/grey';
import GoogleStreetViewStatic from 'src/components/GoogleStreetViewStatic';

export const getAddress = (addressData) =>
  [
    addressData.house_number || addressData.street_number,
    addressData.street_direction,
    addressData.street_name,
    addressData.street_suffix,
    addressData.street_post_direction,
  ]
    .filter(Boolean)
    .join(' ');

const StreetSide = ({
  latitude,
  longitude,
  onBackClick,
  style = {},
  ...otherProps
}) => {
  return (
    <div
      {...otherProps}
      style={{ width: '100%', height: 200, position: 'relative', ...style }}
    >
      {onBackClick && (
        <IconButton
          onClick={onBackClick}
          style={{
            position: 'absolute',
            left: 15,
            top: 15,
            background: '#fff',
            zIndex: 1,
            boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.2)',
          }}
        >
          <ArrowBack style={{ color: grey[800] }} />
        </IconButton>
      )}
      <GoogleStreetViewStatic latitude={latitude} longitude={longitude} />
    </div>
  );
};

export default StreetSide;
