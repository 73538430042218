import React, { useImperativeHandle, useRef } from 'react';
import pluralize from 'pluralize';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  addPropertyListEntries,
  createPropertyList,
  getPropertyListEntriesByPropertyIds,
  getPropertyLists,
} from 'src/products/core-prospect/redux/propertyLists/actions';
import { idFieldName } from 'src/products/core-prospect/redux/table/actions';
import List from 'src/components/InteractiveList/List';
import LotsListButton from 'src/products/core-prospect/search-pages/components/Buttons/LotsListButton';
import Bookmark from '@material-ui/icons/Bookmark';
import { makeStyles, Popover } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  list: {
    minWidth: 350,
  },
}));

const AddToListButton = (props) => {
  const { propertyLists, getPropertyLists, ...otherProps } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const popover = useRef();

  const [anchorEl, setAnchorEl] = React.useState(null);

  React.useEffect(() => {
    getPropertyLists();
  }, []);

  const { selectedRows } = useSelector((state) => {
    const {
      coreProspect: {
        table: { selectedRows },
      },
    } = state;

    return { selectedRows: selectedRows.map((row) => row.tax_assessor_id) };
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = async (list_id) => {
    popover.current.close();
    await dispatch(
      addPropertyListEntries({
        list_id,
        property_code_type: idFieldName,
        property_ids: selectedRows,
      })
    );
    getPropertyLists();
    dispatch(getPropertyListEntriesByPropertyIds(selectedRows));
  };

  const handleCreate = async (name) => {
    popover.current.close();
    const list = await dispatch(createPropertyList(name));
    await handleSelect(list.id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useImperativeHandle(popover, () => ({
    close: handleClose,
  }));

  const items = (propertyLists || []).map((list) => ({
    label: list.name,
    value: list.id,
    secondary: pluralize('Property', list.entries_count, true),
  }));

  const open = Boolean(anchorEl);

  return (
    <div className={classes.root}>
      <LotsListButton
        {...otherProps}
        id='add-to-list'
        text={'Add to lists'}
        tooltip={'Add to property lists'}
        startIcon={<Bookmark />}
        onClick={handleClick}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.list}>
          <List
            onItemCreate={handleCreate}
            items={items}
            selected={[]}
            onItemSelect={(item) => handleSelect(item.value)}
          />
        </div>
      </Popover>
    </div>
  );
};

function mapStateToProps(state) {
  const {
    coreProspect: {
      propertyLists: {
        propertyLists: { value: propertyLists },
      },
    },
  } = state;

  return {
    propertyLists,
  };
}

export default connect(mapStateToProps, {
  getPropertyLists,
})(AddToListButton);
