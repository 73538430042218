import { Typography } from '@material-ui/core';
import React from 'react';

const Title = ({ children }) => (
  <Typography style={{ marginBottom: 10 }} variant='h5'>
    {children}
  </Typography>
);

export default Title;
