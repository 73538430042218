import { ClickAwayListener } from '@material-ui/core';
import React, { useCallback } from 'react';
import { Popup, PopupProps } from 'react-map-gl';
import { LotPopupPropertiesList, LotProperty, StreetView } from './components';

import { usePopupData } from './hook';
import { DealOverlaySuspense } from '../../DealOverlay';
import { useStyles } from './styles';

export type LotPopupProps = {
  onClose: () => void;
  latitude: number;
  longitude: number;
  parcel_id: string;
  portfolio?: boolean;
};

const LotPopup: React.VFC<LotPopupProps> = ({
  onClose,
  latitude,
  longitude,
  parcel_id,
  portfolio,
}) => {
  const classes = useStyles();

  const onClickOutside = useCallback(() => {
    onClose();
  }, [onClose]);

  const {
    isLoading,
    count,
    properties,
    fetchMoreProperties,
    taxAssessorForStreetView,
    dealTaxAssessor,
  } = usePopupData(parcel_id);

  const popupProps: PopupProps = {
    latitude,
    longitude,
    closeOnClick: false,
    closeButton: false,
    captureScroll: true,
    captureDrag: true,
    captureClick: true,
    onClose,
    anchor: 'bottom',
  };

  if (isLoading) {
    return (
      <ClickAwayListener onClickAway={onClickOutside}>
        <div>
          <Popup {...popupProps}>
            <div style={{ padding: 20 }}>Loading...</div>
          </Popup>
        </div>
      </ClickAwayListener>
    );
  }

  if (count === 0) {
    return (
      <ClickAwayListener onClickAway={onClickOutside}>
        <div>
          <Popup {...popupProps}>
            <div style={{ padding: 20 }}>No tax records found</div>
          </Popup>
        </div>
      </ClickAwayListener>
    );
  }

  const isSingleProperty = properties.length === 1;

  const TAX_RECORD_HEIGHT_MULTIPLE = 105;

  const listHeight = Math.min(
    properties.length * TAX_RECORD_HEIGHT_MULTIPLE,
    3 * TAX_RECORD_HEIGHT_MULTIPLE
  );

  return (
    <ClickAwayListener onClickAway={onClickOutside}>
      <div>
        <Popup key='list' {...popupProps}>
          <div
            onScroll={(e) => e.stopPropagation()}
            className={classes.root}
            style={{ width: 300 }}
          >
            {isSingleProperty && dealTaxAssessor && (
              <DealOverlaySuspense
                taxAssessorId={dealTaxAssessor}
                cherreParcelId={parcel_id}
                portfolio={portfolio}
                property={properties[0]}
                onClose={onClose}
              />
            )}
            <StreetView
              width={300}
              count={count || 0}
              location={{
                latitude: taxAssessorForStreetView?.latitude,
                longitude: taxAssessorForStreetView?.longitude,
              }}
            />
            {isSingleProperty && (
              <LotProperty property={properties[0]} rowHeight={'auto'} single />
            )}
            {!isSingleProperty && (
              <LotPopupPropertiesList
                properties={properties}
                fetchMoreProperties={fetchMoreProperties}
                height={listHeight}
                rowHeight={TAX_RECORD_HEIGHT_MULTIPLE}
                portfolio={portfolio}
                onClose={onClose}
              />
            )}
          </div>
        </Popup>
      </div>
    </ClickAwayListener>
  );
};

export default LotPopup;
