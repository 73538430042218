import React from 'react';
import pluralize from 'pluralize';

import Pill from '../../../components/Pill';
import useLocation from 'src/hooks/useLocation';

const PropertyLists = ({
  propertyLists = [],
  filterText = '',
  deleteList,
  ...otherProps
}) => {
  const filteredPropertyLists = (propertyLists || []).filter((propertyList) => {
    return propertyList.name.toLowerCase().match(filterText.toLowerCase());
  });

  const { query } = useLocation();

  return (
    <div {...otherProps}>
      {filteredPropertyLists.map((list, index) => {
        const secondaryText = pluralize(
          'Property',
          Number(list.entries_count),
          true
        );

        return (
          <Pill
            active={Number(query.list_id) === list.id}
            id={`js-core-prospect-search-page-property-list-item-${index}`}
            url={`/properties?sidebar=property-list&list_id=${list.id}`}
            primaryText={list.name}
            secondaryText={secondaryText}
            filterText={filterText}
            key={index}
            onDelete={{
              title: `Delete ${list.name}`,
              action: () => {
                deleteList(list);
              },
            }}
          />
        );
      })}
    </div>
  );
};

export default PropertyLists;
