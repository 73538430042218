import { useRecoilValueLoadable } from 'recoil';
import { ownerDetailsSelector } from '../recoil';

export const useOwnerDetails = (owner_id: string) => {
  const value = useRecoilValueLoadable(ownerDetailsSelector(owner_id));

  return {
    loading: value.state !== 'hasValue',
    data: value.valueMaybe(),
  };
};
