import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useSetRecoilState } from 'recoil';

import useLocation from 'src/hooks/useLocation';
import { resetSearchParameters } from 'src/products/core-prospect/redux/query/actions';
import * as coreProspectUtils from 'src/products/core-prospect/redux/query/utils';
import * as selectors from 'src/products/core-prospect/search-pages/selectors';

import {
  AutocompleteSuggestion,
  AutocompleteSuggestionGroup,
  autocompletValueAtom,
} from '../../../AddressAutocomplete/recoil/selectors';

import { push } from 'connected-react-router';
import { recenterMap } from 'src/products/core-prospect/redux/map/actions';
import { execQuery } from 'src/products/core-prospect/redux/query/actions/execQuery';
import {
  geocode,
  getDefaultInputValue,
  getRedirectQuery,
  suggestionToSearchParameters,
} from '../../../AddressAutocomplete/utils';
import { AddressAutocomplete } from '../../../AddressAutocomplete';

interface AutocompleteComponentProps {
  currentViewport: any;
  onBeforeSuggestionSelected?: () => void;
  where?: 'HomePage' | 'SearchPage' | undefined;
}

const AutocompleteComponent: React.FC<AutocompleteComponentProps> = ({
  currentViewport,
  onBeforeSuggestionSelected,
  where,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const searchParameters = useSelector((state) =>
    selectors.getSearchParameters(state)
  );
  const hasFeature = !!useSelector(selectors.getSearchParametersFeature);

  const onAppsPage = location?.pathname === '/apps';

  const cleanedSearchParameters = coreProspectUtils.removeGeoSearchParameters(
    coreProspectUtils.removeAutocompleteSearchParameters(searchParameters)
  );

  const setAutocompleteValue = useSetRecoilState(autocompletValueAtom);

  const onAutocompleteValueChange = async (
    suggestion: AutocompleteSuggestion | null,
    source?: 'onInputChange' | 'onChange',
    id?: string
  ) => {
    onBeforeSuggestionSelected?.();
    setAutocompleteValue(suggestion);

    if (source === 'onInputChange') {
      return;
    }

    if (where === 'HomePage') {
      window.analytics?.track('Home Page - Autocomplete Used');
    } else {
      window.analytics?.track('Autocomplete Used');
    }

    const options = {
      cards: true,
      count: true,
      results: true,
      viewportOnly: true,
      rebound: true,
      customZoom: undefined as number | undefined,
      id: id,
      skipQuery: false,
    };

    if (suggestion) {
      options.viewportOnly = false;

      const isCity =
        suggestion?.group_name === AutocompleteSuggestionGroup.City;

      if (isCity) {
        options.customZoom = 10;
      }
    }

    if (
      suggestion &&
      [
        AutocompleteSuggestionGroup.Address,
        AutocompleteSuggestionGroup.Street,
        AutocompleteSuggestionGroup.Intersection,
      ].includes(suggestion.group_name)
    ) {
      const { latitude, longitude } = await geocode(suggestion.text);

      suggestion = {
        ...suggestion,
        data: { latitude, longitude, currentViewport },
      };

      options.skipQuery =
        suggestion.group_name !== AutocompleteSuggestionGroup.Address;
    }

    const searchParameters =
      (await suggestionToSearchParameters(suggestion, dispatch)) ?? [];

    dispatch(
      resetSearchParameters(
        [...cleanedSearchParameters, ...searchParameters],
        options
      )
    );

    const redirectQuery = await getRedirectQuery(
      suggestion,
      searchParameters[0]
    );

    if (redirectQuery?.latitude && redirectQuery.longitude) {
      dispatch(
        recenterMap({
          latitude: redirectQuery.latitude,
          longitude: redirectQuery.longitude,
          zoom: 14,
        })
      );
    }

    if (redirectQuery) {
      dispatch(
        // @ts-expect-error: TODO: investigate this TS error
        push({
          pathname: '/properties',
          query: {
            ...location.query,
            ...redirectQuery,
            from: onAppsPage ? 'apps' : undefined,
          },
        })
      );
    }

    if (
      suggestion &&
      [
        AutocompleteSuggestionGroup.Street,
        AutocompleteSuggestionGroup.Intersection,
      ].includes(suggestion.group_name)
    ) {
      dispatch(
        recenterMap({
          zoom: 16,
          latitude: suggestion.data.latitude,
          longitude: suggestion.data.longitude,
        })
      );

      dispatch(
        execQuery({
          id: 'Autocomplete suggestion',
          cards: true,
          count: true,
          results: true,
          viewportOnly: true,
          rebound: false,
        })
      );
    }
  };

  return (
    <AddressAutocomplete
      latitude={currentViewport.latitude}
      longitude={currentViewport.longitude}
      getDefaultInputValue={() =>
        getDefaultInputValue(searchParameters, hasFeature)
      }
      onAutocompleteValueChange={onAutocompleteValueChange}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    currentViewport: state.coreProspect.map.currentViewport,
  };
};

export const Autocomplete = connect(mapStateToProps)(AutocompleteComponent);
