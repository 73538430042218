import { dollars, number } from '../../../utils';
import {
  NYCDetailsToTaxesDataFormatter,
  TaxAssessorToTaxesDataFormatter,
} from '../types';

export const taxAssessorToTaxesData: TaxAssessorToTaxesDataFormatter = (
  data
) => {
  const tax_assessor = data.tax_assessor_v2[0] ?? {};

  const currentTax = dollars(tax_assessor.tax_bill_amount ?? undefined);

  const jurisdiction = tax_assessor.jurisdiction || undefined;

  const totalAssessedValue = dollars(tax_assessor.assessed_value_total);

  const assessedTaxYear =
    tax_assessor.assessed_tax_year?.toString() || undefined;

  const assessedImprovements = dollars(
    tax_assessor.assessed_value_improvements
  );

  const assessedLandValue = dollars(tax_assessor.assessed_value_land);

  const totalMarketValue = dollars(tax_assessor.market_value_total);

  const marketValueYear =
    tax_assessor.market_value_year?.toString() || undefined;

  const marketImprovements = dollars(tax_assessor.market_value_improvements);

  const marketLandValue = dollars(tax_assessor.the_value_land);

  return {
    currentTax,
    jurisdiction,
    totalAssessedValue,
    assessedTaxYear,
    assessedImprovements,
    assessedLandValue,
    totalMarketValue,
    marketValueYear,
    marketImprovements,
    marketLandValue,
  };
};

export const nycDetailsToTaxesData: NYCDetailsToTaxesDataFormatter = (data) => {
  const nyc_tax = data?.nyc_tax[0] ?? {};
  const nyc_lot = data?.nyc_lot[0];

  const currentTax = dollars(nyc_tax.final_tax);

  const jurisdiction = number(nyc_lot?.borough);

  const totalAssessedValue = dollars(nyc_tax.total_actual_assessed_value);

  const assessedTaxYear = nyc_tax.tax_year || undefined;

  const totalMarketValue = dollars(nyc_tax.total_market_value);

  const marketValueYear = nyc_tax.tax_year || undefined;

  return {
    currentTax,
    jurisdiction,
    totalAssessedValue,
    assessedTaxYear,
    totalMarketValue,
    marketValueYear,
  };
};
