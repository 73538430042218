import pluralize from 'pluralize';
import { Drawer, IconButton, makeStyles, Typography } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Close from '@material-ui/icons/Close';
import Email from '@material-ui/icons/Email';
import Phone from '@material-ui/icons/Phone';
import Search from '@material-ui/icons/Search';
import useIsCompact from 'src/hooks/useIsCompact';
import React from 'react';
import { Flex } from 'src/components/Flex';
import grey from '@material-ui/core/colors/grey';
import * as T from './types';
import { usePIPL } from './recoil';
import { formatPhoneType, getPerson, getPhones } from './utils';

const useStyles = makeStyles({
  icon: {
    color: grey[600],
    marginRight: 20,
    position: 'relative',
    top: 2,
    fontSize: 28,
  },

  grey: {
    color: grey[700],
  },
  section: {
    display: 'flex',
    marginBottom: 35,
  },
  address: {
    marginBottom: 10,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  phone: {
    marginBottom: 2,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  phoneType: {
    fontSize: 12,
    color: grey[700],
  },
  emptyHolder: {
    borderRadius: 10,
    border: `solid 1px ${grey[400]}`,
    padding: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  emptyHolderIcon: {
    fontSize: 36,
    margin: '20px 0',
  },
});

interface PIPLProps {
  query: T.Query;
  onClose: () => void;
}

/**
 * SECONDARY COMPONENTS
 */

interface NameSectionProps {
  person?: T.Response['pipl_search']['people'][number];
  name?: T.Name;
}

const NameSection: React.FC<NameSectionProps> = ({ person, name }) => {
  const classes = useStyles();
  const hasJobs = Boolean(person?.jobs?.length);
  const job = [...(person?.jobs || [])].sort((a, b) => {
    if (!a.date_range?.start) {
      return 0;
    }
    if (!b.date_range?.start) {
      return 0;
    }
    const aDate = Date.parse(a.date_range.start);
    const bDate = Date.parse(b.date_range.start);
    return bDate - aDate;
  })[0];

  return (
    <>
      <div className={classes.section}>
        <AccountCircle className={classes.icon} />
        <div>
          <Typography variant='h6'>
            <b>{name}</b>
          </Typography>
          {hasJobs && (
            <Typography className={classes.grey} variant='body1'>
              {job.display}
            </Typography>
          )}
        </div>
      </div>
      {!person && (
        <div className={classes.emptyHolder}>
          <Search className={classes.emptyHolderIcon} />
          <Typography variant='subtitle1'>No Match Found</Typography>
          <Typography variant='body2'>
            No contact info found for this person.
          </Typography>
        </div>
      )}
    </>
  );
};

interface PhonesSectionProps {
  person?: T.Response['pipl_search']['people'][number];
}

const PhonesSection: React.FC<PhonesSectionProps> = ({ person }) => {
  const classes = useStyles();
  const phones = getPhones(person?.phones);

  return (
    <div className={classes.section}>
      <Phone className={classes.icon} />
      <div>
        <Typography variant='body1' className={classes.grey}>
          {pluralize('Phone', phones.length)}
        </Typography>
        {phones.map((phone, index) => {
          return (
            <Typography key={index} className={classes.phone}>
              <a href={`tel:${phone.display}`}>{phone.display}</a>{' '}
              <span className={classes.phoneType}>
                {formatPhoneType(phone.type || '')}
              </span>
            </Typography>
          );
        })}
      </div>
    </div>
  );
};

interface EmailsSectionsProps {
  person?: T.Response['pipl_search']['people'][number];
}

const EmailsSection: React.FC<EmailsSectionsProps> = ({ person }) => {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <Email className={classes.icon} />
      <div>
        <Typography variant='body1' className={classes.grey}>
          Emails
        </Typography>
        {person?.emails.map((email, index) => {
          return (
            <Typography key={index} className={classes.phone}>
              <a href={`mailto:${email.address}`}>{email.address}</a>{' '}
            </Typography>
          );
        })}
      </div>
    </div>
  );
};

const PIPL: React.FC<PIPLProps> = ({ query, onClose }) => {
  const loadable = usePIPL(query);

  const isCompact = useIsCompact();

  const getBody = () => {
    if (loadable.state !== 'hasValue') {
      return 'Loading...';
    }

    if (!loadable.contents) {
      return;
    }

    const person = getPerson(loadable.contents);

    const hasPhones = getPhones(person?.phones).length > 0;
    const hasEmails = Boolean(person?.emails?.length);

    return (
      <>
        {<NameSection person={person} />}
        {hasPhones && <PhonesSection person={person} />}
        {hasEmails && <EmailsSection person={person} />}
      </>
    );
  };

  return (
    <Drawer anchor='right' id='js-navigation-drawer' open onClose={onClose}>
      <div
        style={{
          width: isCompact ? '100%' : 350,
        }}
      >
        <Flex
          style={{
            padding: '5px 5px 20px 20px',
          }}
          alignItems='center'
          justifyContent='space-between'
        >
          <Typography variant='subtitle1'>Contact Details</Typography>

          <IconButton
            style={{ position: 'relative' }}
            color='default'
            onClick={onClose}
          >
            <Close />
          </IconButton>
        </Flex>
        <div style={{ padding: '0px 20px' }}>{getBody()}</div>
      </div>
    </Drawer>
  );
};

export default PIPL;
