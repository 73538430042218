import * as TYPES from './types';
import findIndex from 'lodash/findIndex';
import { FetchStatus } from '../query/reducers';
import { Reducer } from 'redux';

type PropertyListsState = {
  propertyLists: {
    value: Array<{
      entries_count: number;
      id: number;
      name: string;
      deleted: boolean;
      propertyListEntry: Array<{
        id: string;
        listId: number;
        propertyId: string;
        propertyCodeType: string;
      }>;
      created_at: string;
      usersCount: number;
      teamsCount: number;
    }>;
    fetchStatus: FetchStatus;
  };
  propertyListEntriesForTable: {
    value: Array<{
      entry_id: string;
      property_id: number;
      list_id: number;
      name: string;
    }>;
    fetchStatus: FetchStatus;
  };
  propertyListEntries: {
    value: Array<{
      id: string;
      list_id: number;
      property_id: number;
      property_code_type: string;
      cherre_parcel_id: string;
    }>;
    fetchStatus: FetchStatus;
  };
  createPropertyList: {
    fetchStatus: FetchStatus;
  };
  addPropertyListEntry: {
    fetchStatus: FetchStatus;
  };
  removePropertyListEntry: {
    fetchStatus: FetchStatus;
  };
  deletePropertyList: {
    fetchStatus: FetchStatus;
  };
  undoDeletePropertyList: {
    fetchStatus: FetchStatus;
  };
};

type PropertyListsReducer = Reducer<PropertyListsState>;

const initialState: PropertyListsState = {
  propertyLists: {
    value: [],
    fetchStatus: {},
  },
  propertyListEntriesForTable: {
    value: [],
    fetchStatus: {},
  },
  propertyListEntries: {
    value: [],
    fetchStatus: {},
  },
  createPropertyList: {
    fetchStatus: {},
  },
  addPropertyListEntry: {
    fetchStatus: {},
  },
  removePropertyListEntry: {
    fetchStatus: {},
  },
  deletePropertyList: {
    fetchStatus: {},
  },
  undoDeletePropertyList: {
    fetchStatus: {},
  },
};

export const propertyLists: PropertyListsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case TYPES.GET_PROPERTY_LISTS_ENTRIES_FOR_TABLE_SUCCESS: {
      return {
        ...state,
        propertyListEntriesForTable: {
          value: action.data,
          fetchStatus: { value: 'LOADED' },
        },
      };
    }

    case TYPES.CREATE_PROPERTY_LIST_REQUEST:
      return {
        ...state,
        createPropertyList: {
          fetchStatus: { value: 'LOADING' },
        },
      };

    case TYPES.CREATE_PROPERTY_LIST_SUCCESS:
      return {
        ...state,
        createPropertyList: {
          fetchStatus: { value: 'LOADED' },
        },
        propertyLists: {
          value: [
            ...state.propertyLists.value,
            {
              ...action.data,
              entries_count: 0,
            },
          ],
          fetchStatus: {
            value: 'LOADED',
          },
        },
      };

    case TYPES.UPDATE_PROPERTY_LIST_SUCCESS: {
      const listIndex = findIndex(
        state.propertyLists.value,
        (list) => list.id === action.data.id
      );

      return {
        ...state,
        propertyLists: {
          fetchStatus: {
            value: 'LOADED',
          },
          value: [
            ...state.propertyLists.value.slice(0, listIndex),
            {
              ...state.propertyLists.value[listIndex],
              ...action.data,
            },
            ...state.propertyLists.value.slice(listIndex + 1),
          ],
        },
      };
    }

    case TYPES.GET_PROPERTY_LISTS_REQUEST:
      return {
        ...state,
        propertyLists: {
          ...state.propertyLists,
          fetchStatus: { value: 'LOADING' },
        },
      };

    case TYPES.GET_PROPERTY_LISTS_SUCCESS:
      return {
        ...state,
        propertyLists: {
          value: action.data,
          fetchStatus: { value: 'LOADED' },
        },
      };

    case TYPES.GET_PROPERTY_LISTS_ENTRIES_REQUEST:
      return {
        ...state,
        propertyListEntries: {
          ...state.propertyListEntries,
          fetchStatus: { value: 'LOADING' },
        },
      };

    case TYPES.GET_PROPERTY_LISTS_ENTRIES_SUCCESS:
      return {
        ...state,
        propertyListEntries: {
          value: action.data,
          fetchStatus: { value: 'LOADED' },
        },
      };

    case TYPES.ADD_PROPERTY_LIST_ENTRY_REQUEST:
      return {
        ...state,
        addPropertyListEntry: {
          fetchStatus: { value: 'LOADING' },
        },
      };

    case TYPES.ADD_PROPERTY_LIST_ENTRY_SUCCESS: {
      const listIndex = findIndex(
        state.propertyLists.value,
        (list) => list.id === action.data.list_id
      );

      const getNewPropertyLists = (state, propertyListIndex) => {
        return {
          ...state.propertyLists,
          value: [
            ...state.propertyLists.value.slice(0, propertyListIndex),
            {
              ...state.propertyLists.value[propertyListIndex],
              entries_count:
                Number(
                  state.propertyLists.value[propertyListIndex].entries_count
                ) + 1,
            },
            ...state.propertyLists.value.slice(propertyListIndex + 1),
          ],
        };
      };

      return {
        ...state,
        addPropertyListEntry: {
          fetchStatus: { value: 'LOADED' },
        },
        propertyListEntries: {
          ...state.propertyListEntries,
          value: [...state.propertyListEntries.value, action.data],
        },
        propertyLists: getNewPropertyLists(state, listIndex),
      };
    }

    case TYPES.REMOVE_PROPERTY_LIST_ENTRY_REQUEST:
      return {
        ...state,
        removePropertyListEntry: {
          fetchStatus: { value: 'LOADING' },
        },
      };

    case TYPES.REMOVE_PROPERTY_LIST_ENTRIES_REQUEST:
      return {
        ...state,
        removePropertyListEntries: {
          fetchStatus: { value: 'LOADING' },
        },
      };

    case TYPES.REMOVE_PROPERTY_LIST_ENTRY_SUCCESS: {
      const listEntryIndex = findIndex(
        state.propertyListEntries.value,
        (entry) => Number(entry.id) === Number(action.data[0].id)
      );

      const listIndex = findIndex(
        state.propertyLists.value,
        (list) => Number(list.id) === Number(action.data[0].list_id)
      );

      const getNewpropertyListEntries = (state, deletedListEntryIndex) => {
        return {
          ...state.propertyListEntries,
          value: [
            ...state.propertyListEntries.value.slice(0, deletedListEntryIndex),
            ...state.propertyListEntries.value.slice(deletedListEntryIndex + 1),
          ],
        };
      };

      const getNewPropertyLists = (state, propertyListIndex) => {
        return {
          ...state.propertyLists,
          value: [
            ...state.propertyLists.value.slice(0, propertyListIndex),
            {
              ...state.propertyLists.value[propertyListIndex],
              entries_count:
                Number(
                  state.propertyLists.value[propertyListIndex].entries_count
                ) - 1,
            },
            ...state.propertyLists.value.slice(propertyListIndex + 1),
          ],
        };
      };

      return {
        ...state,
        removePropertyListEntry: {
          fetchStatus: { value: 'LOADED' },
        },
        propertyListEntries: getNewpropertyListEntries(state, listEntryIndex),
        propertyLists: getNewPropertyLists(state, listIndex),
        propertyListEntriesForTable: {
          ...state.propertyListEntriesForTable,
          value: state.propertyListEntriesForTable.value.filter(
            (entry) => +entry.entry_id !== +action.data[0].id
          ),
        },
      };
    }

    case TYPES.REMOVE_PROPERTY_LIST_ENTRIES_SUCCESS: {
      const listEntriesIndexes = action.data.map((d) =>
        findIndex(
          state.propertyListEntries.value,
          (entry) => Number(entry.id) === Number(d.id)
        )
      );

      const listIndexes = action.data.map((d) =>
        findIndex(
          state.propertyLists.value,
          (list) => Number(list.id) === Number(d.list_id)
        )
      );

      const listEntriesIds = action.data.map((d) => d.id);

      const getNewpropertyListEntries = (state, deletedListEntryIndexes) => {
        const entries = state.propertyListEntries.value.filter(
          (_, i) => !deletedListEntryIndexes.includes(i)
        );
        return {
          ...state.propertyListEntries,
          value: entries,
        };
      };

      const getNewPropertyLists = (state, propertyListIndexes) => {
        const lists = state.propertyLists.value.map((l, i) => {
          const removed_count = action.data.filter(
            (a) => a.list_id === l.id
          ).length;
          if (propertyListIndexes.includes(i)) {
            return {
              ...l,
              entries_count: Number(l.entries_count) - removed_count,
            };
          } else {
            return l;
          }
        });
        return {
          ...state.propertyLists,
          value: lists,
        };
      };

      return {
        ...state,
        removePropertyListEntries: {
          fetchStatus: { value: 'LOADED' },
        },
        propertyListEntries: getNewpropertyListEntries(
          state,
          listEntriesIndexes
        ),
        propertyLists: getNewPropertyLists(state, listIndexes),
        propertyListEntriesForTable: {
          ...state.propertyListEntriesForTable,
          value: state.propertyListEntriesForTable.value.filter(
            (entry) => !listEntriesIds.includes(entry.entry_id)
          ),
        },
      };
    }

    case TYPES.DELETE_PROPERTY_LIST_REQUEST: {
      const { id } = action;

      const getUpdatedPropertyLists = () => {
        const index = findIndex(state.propertyLists.value, (list) => {
          return list.id === id;
        });

        return [
          ...state.propertyLists.value.slice(0, index),
          {
            ...state.propertyLists.value[index],
            deleted: true,
          },
          ...state.propertyLists.value.slice(index + 1),
        ];
      };

      return {
        ...state,
        propertyLists: {
          ...state.propertyLists,
          value: getUpdatedPropertyLists(),
        },
        deletePropertyList: {
          fetchStatus: {
            value: 'LOADING',
          },
        },
      };
    }

    case TYPES.UNDO_DELETE_PROPERTY_LIST_REQUEST: {
      const { id } = action;

      const getUpdatedPropertyLists = () => {
        const index = findIndex(state.propertyLists.value, (list) => {
          return list.id === id;
        });

        return [
          ...state.propertyLists.value.slice(0, index),
          {
            ...state.propertyLists.value[index],
            deleted: false,
          },
          ...state.propertyLists.value.slice(index + 1),
        ];
      };

      return {
        ...state,
        propertyLists: {
          ...state.propertyLists,
          value: getUpdatedPropertyLists(),
        },
        undoDeletePropertyList: {
          fetchStatus: {
            value: 'LOADING',
          },
        },
      };
    }

    case TYPES.DELETE_PROPERTY_LIST_SUCCESS: {
      return {
        ...state,
        deletePropertyList: {
          fetchStatus: {
            value: 'LOADED',
          },
        },
      };
    }

    case TYPES.UNDO_DELETE_PROPERTY_LIST_SUCCESS: {
      return {
        ...state,
        undoDeletePropertyList: {
          fetchStatus: {
            value: 'LOADED',
          },
        },
      };
    }

    default:
      return state;
  }
};
