import React, { useState } from 'react';
import { RCABody } from './components';
import RCAMapLotsPageHeader from './RCAMapLotsPageHeader';
import * as constants from 'src/products/core-prospect/search-pages/components/NavBar/constants';
import { useQuery } from 'graphql-hooks';
import {
  SQ_FT,
  PRICE,
  YEAR_BUILT,
  STATUS_DATE,
  MAIN_PROPERTY_TYPE,
  DEFAULT_ARRAY,
  DEFAULT_MIN_MAX,
  PRICE_PER_SQUARE_FOOT_UNIT,
} from './constants';
import {
  mainPropertyTypeSelections,
  getRcaVariables,
  getRcaCompsList,
  getYearSelectionRange,
  getCompsBounds,
  createCherreParcelIdToLotData,
  getMapLatLogId,
} from './rcaMapLotsPageHelpers';
import { useSelector } from 'react-redux';
import { getRcaSalesEntityName } from './getRcaSalesEntityName';
import { getRcaSalesQuery } from './query';

const propertyUseCodeToMainPropertyType = {
  22: 'Apartment',
  23: 'Apartment',
  24: 'Office',
  25: 'Office',
  26: 'Office',
  39: 'Residential',
  40: 'Residential',
  41: 'Residential',
  42: 'Residential',
  43: 'Residential',
  44: 'Residential',
  14: 'Industrial',
  15: 'Industrial',
  16: 'Industrial',
  45: 'Retail',
  46: 'Retail',
  47: 'Retail',
};

const RCA = ({ data }) => {
  const user = useSelector((state) => state.user.profile.value);

  const rcaEntityName = getRcaSalesEntityName(user);

  const [showMap, setShowMap] = useState(true);
  // FILTERS
  const [squareFeetRange, setSquareFeetRange] = useState(DEFAULT_MIN_MAX);
  const [salePriceRange, setSalePriceRange] = useState(DEFAULT_MIN_MAX);
  const [yearBuiltRange, setYearBuiltRange] = useState(DEFAULT_MIN_MAX);
  const [statusDateRange, setStatusDateRange] = useState(DEFAULT_MIN_MAX);
  const [pricePerSquareFootUnitRange, setPricePerSquareFootUnitRange] =
    useState(DEFAULT_MIN_MAX);
  const [showPropertyDetails, setShowPropertyDetails] = useState(false);
  const [cherreParcelIdToLotData, setCherreParcelIdToLotData] = useState({});
  const [selectedParcelData, setSelectedParcelData] = useState([]);
  const [selectedMapData, setSelectedMapData] = useState([
    { id: null, latitude: null, longitude: null },
  ]);
  const [queryMapData, setQueryMapData] = useState([
    { id: null, latitude: null, longitude: null },
  ]);
  const [selectedMainPropertyTypes, setMainPropertyTypes] =
    useState(DEFAULT_ARRAY);
  const [rcaSalesQuery, setRcaSalesQuery] = useState(
    getRcaSalesQuery({
      entityName: rcaEntityName,
      squareFeetRange,
      salePriceRange,
      yearBuiltRange,
      statusDateRange,
      selectedMainPropertyTypes,
      pricePerSquareFootUnitRange,
    })
  );
  const [rcaVariables, setRcaVariables] = useState(
    getRcaVariables({
      squareFeetRange,
      salePriceRange,
      yearBuiltRange,
      statusDateRange,
      selectedMainPropertyTypes,
      pricePerSquareFootUnitRange,
    })
  );

  const [yearSelectionRange, setYearSelectionRange] = useState([]);
  const [rcaCompsList, setRcaCompsList] = useState([]);
  const [rcaDataState, setRcaDataState] = useState(undefined);
  const [compsBounds, setCompsBounds] = useState(undefined);

  const view = showMap ? constants.VIEW_MAP : constants.VIEW_TABLE;
  const { lot_data, lot_address } = data;
  const { property_use_code_mapped } = lot_data;

  const rcaFilterFunctions = {
    [SQ_FT]: {
      value: squareFeetRange,
      setValue: setSquareFeetRange,
      defaultValue: DEFAULT_MIN_MAX,
    },
    [PRICE]: {
      value: salePriceRange,
      setValue: setSalePriceRange,
      defaultValue: DEFAULT_MIN_MAX,
    },
    [YEAR_BUILT]: {
      value: yearBuiltRange,
      setValue: setYearBuiltRange,
      selectionRange: yearSelectionRange,
      defaultValue: DEFAULT_MIN_MAX,
    },
    [STATUS_DATE]: {
      value: statusDateRange,
      setValue: setStatusDateRange,
      defaultValue: DEFAULT_MIN_MAX,
    },
    [MAIN_PROPERTY_TYPE]: {
      value: selectedMainPropertyTypes,
      setValue: setMainPropertyTypes,
      selectionRange: mainPropertyTypeSelections,
      defaultValue: DEFAULT_ARRAY,
    },
    [PRICE_PER_SQUARE_FOOT_UNIT]: {
      value: pricePerSquareFootUnitRange,
      setValue: setPricePerSquareFootUnitRange,
      defaultValue: DEFAULT_MIN_MAX,
    },
  };

  const updateSearch = () => {
    setRcaSalesQuery(
      getRcaSalesQuery({
        entityName: rcaEntityName,
        squareFeetRange: rcaFilterFunctions[SQ_FT].value,
        salePriceRange: rcaFilterFunctions[PRICE].value,
        yearBuiltRange: rcaFilterFunctions[YEAR_BUILT].value,
        statusDateRange: rcaFilterFunctions[STATUS_DATE].value,
        pricePerSquareFootUnitRange:
          rcaFilterFunctions[PRICE_PER_SQUARE_FOOT_UNIT].value,
        selectedMainPropertyTypes: rcaFilterFunctions[MAIN_PROPERTY_TYPE].value,
      })
    );
    setRcaVariables(
      getRcaVariables({
        squareFeetRange: rcaFilterFunctions[SQ_FT].value,
        salePriceRange: rcaFilterFunctions[PRICE].value,
        yearBuiltRange: rcaFilterFunctions[YEAR_BUILT].value,
        statusDateRange: rcaFilterFunctions[STATUS_DATE].value,
        selectedMainPropertyTypes: rcaFilterFunctions[MAIN_PROPERTY_TYPE].value,
        pricePerSquareFootUnitRange:
          rcaFilterFunctions[PRICE_PER_SQUARE_FOOT_UNIT].value,
      })
    );
  };

  const clearFilter = (filter) => {
    const filterProps = rcaFilterFunctions[filter];
    filterProps.setValue(filterProps.defaultValue);
    filterProps.value = filterProps.defaultValue;
    updateSearch();
  };

  const initialMainPropertyTypes = [
    propertyUseCodeToMainPropertyType[property_use_code_mapped] || 'Apartment',
    'Industrial',
    'Retail',
  ];

  const {
    data: rcaData,
    loading,
    error,
  } = useQuery(rcaSalesQuery, {
    variables: {
      ...rcaVariables,
      zip: lot_address.zip,
      main_property_types:
        selectedMainPropertyTypes.length === 0
          ? initialMainPropertyTypes
          : selectedMainPropertyTypes,
    },
  });

  // React.useEffect(() => {
  //   setMainPropertyTypes(initialMainPropertyTypes);
  // }, [initialMainPropertyTypes]);

  const hidePropertyDetails = () => {
    setShowPropertyDetails(false);
    const compsLatLong = rcaData?.[rcaEntityName]
      ? rcaData[rcaEntityName].map((data) => [data.longitude, data.latitude])
      : [];

    const bounds = getCompsBounds(compsLatLong);
    setCompsBounds(bounds);
  };

  const getCherreParcelGemo = (longitude, latitude, cherre_parcel_id) => {
    const bounds = getCompsBounds([[longitude, latitude]]);
    setCompsBounds(bounds);
    setSelectedMapData([{ id: cherre_parcel_id, latitude, longitude }]);
    const selectedCherreParcel = cherreParcelIdToLotData[cherre_parcel_id];

    setShowPropertyDetails(true);
    selectedCherreParcel
      ? setSelectedParcelData([selectedCherreParcel])
      : setSelectedParcelData([]);
  };

  const yearBuiltSelectionRange = getYearSelectionRange(
    rcaDataState?.[rcaEntityName]
  );
  const rcaCompsListGenerated = getRcaCompsList(rcaDataState?.[rcaEntityName]);
  const cherreParcelIdToLotDataGenerated = createCherreParcelIdToLotData(
    rcaCompsListGenerated
  );

  const mapLatLongId = rcaCompsListGenerated.length
    ? getMapLatLogId(rcaCompsListGenerated)
    : [{ id: null, latitude: null, longitude: null }];

  const compsLatLong = rcaData?.[rcaEntityName]
    ? rcaData[rcaEntityName].map((data) => [data.longitude, data.latitude])
    : [];

  const bounds = getCompsBounds(compsLatLong);

  React.useEffect(() => {
    setCompsBounds(bounds);
  }, [rcaData]);

  React.useEffect(() => {
    setQueryMapData(mapLatLongId);
    setRcaCompsList(rcaCompsListGenerated);
    setYearSelectionRange(yearBuiltSelectionRange);
    setCherreParcelIdToLotData(cherreParcelIdToLotDataGenerated);
  }, [rcaDataState]);

  React.useEffect(() => {
    if (rcaData !== undefined) {
      setRcaDataState(rcaData);
    }
  }, [loading, updateSearch, rcaData]);

  return (
    <div>
      <RCAMapLotsPageHeader
        setShowMap={setShowMap}
        showMap={showMap}
        view={view}
        updateSearch={updateSearch}
        setUpdateSearch={updateSearch}
        rcaFilterFunctions={rcaFilterFunctions}
        clearFilter={clearFilter}
      />
      <RCABody
        data={data}
        showMap={showMap}
        bounds={compsBounds}
        rcaCompsList={rcaCompsList}
        mapLatLongId={showPropertyDetails ? selectedMapData : queryMapData}
        loading={loading}
        error={error}
        getCherreParcelGemo={getCherreParcelGemo}
        selectedParcelData={selectedParcelData}
        setSelectedParcelData={setSelectedParcelData}
        showPropertyDetails={showPropertyDetails}
        hidePropertyDetails={hidePropertyDetails}
      />
    </div>
  );
};

export default RCA;
