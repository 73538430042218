import { Checkbox, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useCallback } from 'react';
import { RootState, useDispatch, useSelector } from 'react-redux';
import { useRecoilValueLoadable } from 'recoil';
import { changeSearchParameter } from 'src/products/core-prospect/redux/query/actions';
import useStyles from './styles';
import { getSearchParameters } from 'src/products/core-prospect/search-pages/selectors';
import {
  dealLeadersSelector,
  dealStagesSelector,
  dealTeamsSelector,
  dealILOsSelector,
  dealSellersSelector,
} from 'src/services/Dealcloud/recoil';

const getFilterValue = (field_name: string) => (state: RootState) => {
  const search_parameters = getSearchParameters(state);
  return search_parameters.find((search_parameter) => {
    return (
      search_parameter.entity_name === 'deal' &&
      search_parameter.field_name === field_name
    );
  })?.attributes?.value;
};

const DealFilter: React.FC = () => {
  const classes = useStyles();

  const leadersLoadable = useRecoilValueLoadable(dealLeadersSelector);
  const leaders = leadersLoadable.valueMaybe() || [];

  const teamsLoadable = useRecoilValueLoadable(dealTeamsSelector);
  const teams = teamsLoadable.valueMaybe() || [];

  const stagesLoadable = useRecoilValueLoadable(dealStagesSelector);
  const stages =
    stagesLoadable.valueMaybe()?.filter((s) => s !== 'Inactive') || [];

  const ilosLoadable = useRecoilValueLoadable(dealILOsSelector);
  const ilos = ilosLoadable.valueMaybe() || [];

  const sellersLoadable = useRecoilValueLoadable(dealSellersSelector);
  const sellers = sellersLoadable.valueMaybe() || [];

  const dispatch = useDispatch();

  const onChange = useCallback((field_name: string, value: any) => {
    dispatch(
      changeSearchParameter(
        {
          id: 38,
          entity: 'deal',
          filter_set_id: 12,
          filter_type: 'DealFilter',
          field_name,
        },
        value
      )
    );
  }, []);

  const leadValue = useSelector(getFilterValue('leader'));
  const iloValue = useSelector(getFilterValue('ilo'));
  const sellerValue = useSelector(getFilterValue('seller'));
  const stageValues = useSelector(getFilterValue('stage')) || [];
  const teamValues = useSelector(getFilterValue('team')) || [];

  const selectedLeader = leaders.find((l) => l.name === leadValue) || null;
  const selectedIlo = ilos.find((l) => l.name === iloValue) || null;
  const selectedSeller = sellers.find((l) => l.name === sellerValue) || null;

  const toggleStage = (stageName: string) => () => {
    if (stageValues.includes(stageName)) {
      onChange(
        'stage',
        stageValues.filter((s) => s !== stageName)
      );
    } else {
      onChange('stage', [...stageValues, stageName]);
    }
  };

  const toggleTeam = (teamName: string) => () => {
    if (teamValues.includes(teamName)) {
      onChange(
        'team',
        teamValues.filter((s) => s !== teamName)
      );
    } else {
      onChange('team', [...teamValues, teamName]);
    }
  };

  return (
    <div className={classes.container}>
      <div>
        <Typography variant='body1' className={classes.title}>
          Deal Team
        </Typography>
        <div className={classes.teamsContainer}>
          {teams.map((team) => {
            const selected = teamValues.includes(team.name);
            return (
              <div
                key={team.name}
                className={
                  selected
                    ? classes.selectedCheckbox
                    : classes.unselectedCheckbox
                }
                onClick={toggleTeam(team.name)}
                title={team.name}
                style={{ gridArea: team.name.split(' ')[0] }}
              >
                <Checkbox
                  color='primary'
                  disableRipple
                  style={{ height: 30, width: 34 }}
                  checked={selected}
                />
                <Typography variant='body1' className={classes.checkboxText}>
                  {team.name}
                </Typography>
              </div>
            );
          })}
        </div>
      </div>
      <div>
        <Typography variant='body1' className={classes.title}>
          Deal Stage
        </Typography>
        <div className={classes.stagesContainer}>
          {stages.map((stage) => {
            const selected = stageValues.includes(stage);
            return (
              <div
                key={stage}
                className={
                  selected
                    ? classes.selectedCheckbox
                    : classes.unselectedCheckbox
                }
                onClick={toggleStage(stage)}
                title={stage}
              >
                <Checkbox
                  color='primary'
                  disableRipple
                  style={{ height: 30, width: 34 }}
                  checked={selected}
                />
                <Typography variant='body1' className={classes.checkboxText}>
                  {stage}
                </Typography>
              </div>
            );
          })}
        </div>
      </div>
      <div>
        <Typography variant='body1' className={classes.title}>
          Acquisitions Associate
        </Typography>
        <Autocomplete
          value={selectedLeader}
          loading={leadersLoadable.state === 'loading'}
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              size='small'
              placeholder='Select'
              style={{ backgroundColor: 'white' }}
            />
          )}
          onChange={(_, lead) => onChange('leader', lead?.name)}
          options={leaders}
          getOptionLabel={(obj) => obj.name}
        />
      </div>
      <div>
        <Typography variant='body1' className={classes.title}>
          ILO
        </Typography>
        <Autocomplete
          value={selectedIlo}
          loading={ilosLoadable.state === 'loading'}
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              size='small'
              placeholder='Select'
              style={{ backgroundColor: 'white' }}
            />
          )}
          onChange={(_, ilo) => onChange('ilo', ilo?.name)}
          options={ilos}
          getOptionLabel={(obj) => obj.name}
        />
      </div>
      <div>
        <Typography variant='body1' className={classes.title}>
          Seller
        </Typography>
        <Autocomplete
          value={selectedSeller}
          loading={sellersLoadable.state === 'loading'}
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              size='small'
              placeholder='Select'
              style={{ backgroundColor: 'white' }}
            />
          )}
          onChange={(_, seller) => onChange('seller', seller?.name)}
          options={sellers}
          getOptionLabel={(obj) => obj.name}
        />
      </div>
    </div>
  );
};

DealFilter.displayName = 'DealFilter';

export default DealFilter;
