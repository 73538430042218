import React from 'react';
import { useParams } from 'react-router';
import { errorSelector, useRecoilValue } from 'recoil';
import { lotsWithParcelIdQuery, taxAssessorQuery } from '../../../../recoil';
import { LotPageParams } from '../../../../types';
import * as T from './types';

const error = errorSelector('no cherre parcel id');

const hook: T.UseUnitSelector = () => {
  const { id } = useParams<LotPageParams>();
  const { tax_assessor_v2 } = useRecoilValue(
    taxAssessorQuery({ tax_assessor_id: Number(id) })
  );
  const cherre_parcel_id = tax_assessor_v2[0].cherre_parcel_id;
  const data = useRecoilValue(
    cherre_parcel_id
      ? lotsWithParcelIdQuery({
          cherre_parcel_id,
        })
      : error
  );

  const options = React.useMemo<T.UnitOption[]>(
    () =>
      (data.tax_assessor_v2 || [])
        .map((item) => ({
          label:
            item.unit_number?.toString() ||
            item.assessor_parcel_number_raw?.toString() ||
            item.tax_assessor_id?.toString() ||
            '',
          value: item.tax_assessor_id?.toString() || '',
        }))
        .sort((a, b) => {
          if (!isNaN(Number(a.label)) && !isNaN(Number(b.label))) {
            return +a.label > +b.label ? 1 : -1;
          } else {
            return a.label > b.label ? 1 : -1;
          }
        }),
    [data]
  );

  const selected = React.useMemo(
    () => options.find((option) => option.value === id),
    [options, id]
  );

  return { options, selected };
};

export default hook;
