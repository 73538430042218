import { makeStyles } from '@material-ui/core';

interface StylesProps {
  columnCount: number;
}

const createTemplateColumns = (props: StylesProps) =>
  Array.from(Array(props.columnCount))
    .map(() => '1fr')
    .join(' ');

export default makeStyles((theme) => ({
  container: {
    display: 'grid',
    gap: '12px',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: createTemplateColumns,
    },
    '@media print': {
      gridTemplateColumns: createTemplateColumns,
    },
  },
}));
