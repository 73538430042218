import React from 'react';
import grey from '@material-ui/core/colors/grey';

const Component = ({ style = {}, height = 3 }) => {
  return (
    <div
      className='separator'
      style={{
        height: height,
        margin: '15px 0',
        backgroundColor: grey[300],
        ...style,
      }}
    />
  );
};

export default Component;
