import { GQL, gql, paginate, AllAssigned } from 'src/utils/recoil/gql';
import * as T from './__generated__';

const keyFor = (txt: string) => `CORE-PROSPECT/GEOGRAPHY-PICKER/REIS/${txt}`;

export const sectors = {
  Apartment: 'Apt',
  Flex: 'Flx',
  Office: 'Off',
  Retail: 'Ret',
  Warehouse: 'Dis',
} as const;

export type SectorDict = typeof sectors;

export type SectorName = keyof SectorDict;

export type SectorValue<K extends SectorName = SectorName> = SectorDict[K];

export const getSectorValue = (sectorName: SectorName): SectorValue => {
  return sectors[sectorName];
};

export const getSectorName = (sectorValue: SectorValue): SectorName | void => {
  return Object.keys(sectors).find(
    (key) => sectors[key] === sectorValue
  ) as SectorName;
};

export const DEFAULT_SECTOR_NAME: SectorName = 'Apartment';

const marketsQuery = gql`
  query market_names($submarket_name: String!, $offset: Int!, $limit: Int!) {
    reis_boundaries(
      offset: $offset
      limit: $limit
      distinct_on: submarket_name
      order_by: { submarket_name: asc }
      where: {
        is_submarket: { _eq: false }
        submarket_name: { _ilike: $submarket_name }
      }
    ) {
      submarket_name
      metro_code
    }
  }
` as GQL<AllAssigned<T.Market_NamesQuery>, T.Market_NamesQueryVariables>;

export const paginatedMarkets = paginate({
  key: keyFor('PAGINATED-MARKETS'),
  query: marketsQuery,
  fieldName: 'reis_boundaries',
});

const subMarketsQuery = gql`
  query markets(
    $metro_code: String!
    $sector: String!
    $submarket_name: String!
    $offset: Int!
    $limit: Int!
  ) {
    reis_boundaries(
      offset: $offset
      limit: $limit
      distinct_on: submarket_name
      order_by: { submarket_name: asc }
      where: {
        is_submarket: { _eq: true }
        metro_code: { _ilike: $metro_code }
        sector: { _eq: $sector }
        submarket_name: { _ilike: $submarket_name }
      }
    ) {
      submarket_name
      sector
      geometry
    }
  }
` as GQL<AllAssigned<T.MarketsQuery>, T.MarketsQueryVariables>;

export const paginatedSubMarkets = paginate({
  key: keyFor('PAGINATED-SUB-MARKETS'),
  query: subMarketsQuery,
  fieldName: 'reis_boundaries',
});
