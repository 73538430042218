import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import ResultsTable from './ResultsTable';

const styles = {
  container: {
    width: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
};

const ResultsTableView = ({ classes, isVisible, items, count, isLoading }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <div id='js-core-prospect-table-results' className={classes.container}>
      <ResultsTable isLoading={isLoading} items={items} count={count} />
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    coreProspect: {
      table: {
        tableResultsFetchStatus: { value: fetchStatus },
      },
    },
  } = state;

  return {
    items: state.coreProspect.table.tableResults,
    count: state.coreProspect.mapPinResults.resultsCount,
    isLoading: [fetchStatus].includes('LOADING'),
  };
};

export default connect(mapStateToProps)(withStyles(styles)(ResultsTableView));
