import { gql, GQL } from 'src/utils/recoil/gql';
import * as T from './__generated__/taxAssessor';

export const taxAssessorQuery = gql`
  query tax_assessor($tax_assessor_id: numeric!) {
    tax_assessor_v2(where: { tax_assessor_id: { _eq: $tax_assessor_id } }) {
      tax_assessor_id
      cherre_bbl
      cherre_parcel_id
      unit_number
      unit_prefix
      house_number
      street_name
      street_suffix
      street_direction
      street_post_direction

      year_built
      units_count
      buildings_count
      building_sq_ft
      building_sq_ft_code

      address
      cherre_address__property_address {
        display_address
      }
      city
      state
      zip
      mailing_address
      mailing_city
      mailing_state
      mailing_zip

      assessor_parcel_number_raw
      property_group_type
      property_use_standardized_code
      property_use_code_mapped
      zone_code
      last_update_date

      lot_size_sq_ft
      lot_size_acre
      gross_sq_ft
      lot_depth_ft
      lot_width

      bed_count
      bath_count
      partial_bath_count

      floor_1_sq_ft
      sewer_usage_code
      water_source_code
      foundation_code
      construction_code
      interior_structure_code
      flooring_material_code
      structure_style_code
      exterior_code
      roof_material_code
      roof_construction_code
      topography_code

      has_attic
      attic_sq_ft
      basement_sq_ft
      basement_finished_sq_ft
      basement_unfinished_sq_ft
      parking_sq_ft
      parking_garage_code
      has_parking_carport
      parking_sq_ft
      hvacc_cooling_code
      hvacc_heating_code
      hvacc_heating_fuel_code
      has_elevator
      is_handicap_accessible
      has_central_vacuum_system
      has_intercom
      has_installed_sound_system
      has_wet_bar
      has_alarm_system
      has_storm_shutter
      porch_code
      has_deck
      driveway_material_code
      pool_code
      has_sauna
      has_arbor_pergola
      has_tennis_court
      has_other_sport_court
      has_water_feature
      has_boat_lift
      has_boat_access
      has_outdoor_kitchen_fireplace
      has_sprinklers

      tax_assessor_owner_v2__tax_assessor_id(
        where: {
          owner_name: { _is_null: false }
          owner_type: { _is_null: false }
        }
      ) {
        owner_name
        owner_type
      }

      parcel_boundary_v2__tax_assessor_id {
        geom
        latitude
        longitude
        parcel_centroid_geometry
      }

      recorder_summary: recorder_v2_summary_v2__tax_assessor_id {
        cherre_latest_deed_date
        cherre_latest_deed_amount
      }

      tax_bill_amount
      # tax_district_code
      jurisdiction
      assessed_value_total
      assessed_tax_year
      assessed_value_land
      assessed_value_improvements
      market_value_total
      market_value_year
      market_value_improvements
      # market_value_land               ????????
      last_sale_amount
      last_sale_date
      the_value_land
      cherre_address__property_address {
        reis_property_attributes__address {
          floors
          sector
          propertytype
          sizesqft
          sizeunit
          yearbuilt
        }
      }
    }
    recorder_v2(
      where: {
        document_type_code: {
          _in: [
            "DTAD"
            "DTAO"
            "DTAS"
            "DTBD"
            "DTBS"
            "DTCD"
            "DTCF"
            "DTCH"
            "DTCM"
            "DTCO"
            "DTCP"
            "DTCR"
            "DTDB"
            "DTDE"
            "DTDG"
            "DTDL"
            "DTDX"
            "DTEX"
            "DTFD"
            "DTGD"
            "DTGE"
            "DTGF"
            "DTID"
            "DTJT"
            "DTLW"
            "DTMD"
            "DTPD"
            "DTPR"
            "DTQC"
            "DTRC"
            "DTRD"
            "DTRE"
            "DTRF"
            "DTSD"
            "DTSS"
            "DTSV"
            "DTSW"
            "DTTD"
            "DTTR"
            "DTWD"
          ]
        }
        tax_assessor_id: { _eq: $tax_assessor_id }
      }
      order_by: { document_recorded_date: desc, document_instrument_date: desc }
      limit: 1
    ) {
      document_amount
      document_recorded_date
      document_instrument_date
      cherre_recorded_instrument_date
    }
  }
` as GQL<T.Tax_AssessorQuery, T.Tax_AssessorQueryVariables>;

export const lotsWithParcelIdQuery = gql`
  query lotsWithParcelId($cherre_parcel_id: String!) {
    tax_assessor_v2(
      limit: 1000
      where: { cherre_parcel_id: { _eq: $cherre_parcel_id } }
    ) {
      address
      unit_number
      assessor_parcel_number_raw
      jurisdiction
      cherre_parcel_id
      tax_assessor_id
    }
  }
` as GQL<T.LotsWithParcelIdQuery, T.LotsWithParcelIdQueryVariables>;
