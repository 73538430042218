import React from 'react';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import List from 'src/components/InteractiveList/List';
import ListIcon from 'src/components/InteractiveList/ListIcon';
import {
  getPropertyLists,
  createPropertyList,
  getPropertyListEntries,
  addPropertyListEntry,
  removePropertyListEntry,
} from 'src/products/core-prospect/redux/propertyLists/actions';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  list: {
    minWidth: 350,
  },
}));

const PropertyList = ({
  propertyLists,
  propertyListEntries,

  getPropertyLists,
  createPropertyList,

  getPropertyListEntries,
  addPropertyListEntry,
  removePropertyListEntry,

  property_id,
  property_code_type,
  disabled,
  user,
}) => {
  const classes = useStyles();

  const handleItemSelect = (item) => {
    addPropertyListEntry({
      list_id: item.value,
      property_code_type,
      property_id: property_id.toString(),
    });
  };

  const handleItemDeselect = (item) => {
    const entry = propertyListEntries.find(
      (entry) => entry.list_id === item.value
    );

    if (!entry) {
      return;
    }

    removePropertyListEntry({ id: entry.id });
  };

  const handleCreate = async (label) => {
    const list = await createPropertyList(label);

    addPropertyListEntry({
      list_id: list.id,
      property_code_type,
      property_id,
    });
  };

  React.useEffect(() => {
    getPropertyLists();
  }, []);

  React.useEffect(() => {
    getPropertyListEntries({ property_id, property_code_type });
  }, [property_id, property_code_type]);

  const items = (propertyLists || []).map((list) => ({
    label: list.name,
    value: list.id,
    secondary: pluralize('Property', list.entries_count, true),
  }));

  const selected = (propertyLists || [])
    .filter((list) => {
      return propertyListEntries.some((entry) => entry.list_id === list.id);
    })
    .map((list) => ({
      label: list.name,
      value: list.id,
    }));

  if (!user.featureFlags.cp_PropertyLists) {
    return null;
  }

  return (
    <div className={classes.root}>
      <ListIcon
        title='Add to property lists'
        disableText='Property Lists Coming Soon'
        className={
          disabled ? 'js-core-prospect-property-list__icon-disabled' : ''
        }
        id='lot-page-property-list-icon'
        disabled={disabled}
      >
        <div className={classes.list}>
          <List
            id='js-core-prospect-property-list__root'
            onItemCreate={handleCreate}
            items={items}
            selected={selected}
            onItemSelect={handleItemSelect}
            onItemDeselect={handleItemDeselect}
          />
        </div>
      </ListIcon>
    </div>
  );
};

PropertyList.propTypes = {
  showChips: PropTypes.bool,
};

function mapStateToProps(state) {
  const {
    coreProspect: {
      propertyLists: {
        propertyLists: { value: propertyLists },
        propertyListEntries: { value: propertyListEntries },
      },
    },
  } = state;

  return {
    propertyLists,
    propertyListEntries,
    user: state.user.profile.value,
  };
}

export default connect(mapStateToProps, {
  getPropertyLists,
  createPropertyList,

  getPropertyListEntries,
  addPropertyListEntry,
  removePropertyListEntry,
})(PropertyList);
