import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSearchParameters } from 'src/products/core-prospect/search-pages/selectors';
import { changeSearchParameter } from 'src/products/core-prospect/redux/query/actions';
import { cherrePropertyUseHierarchical } from 'src/const/lookups';

import { getFilterComponent } from '../CoreProspectFilters';

import CoreProspectFilterTitle from './CoreProspectFilterTitle';
import options from './options';

const getFilterSearchParameter = (search_parameters, filter) => {
  return search_parameters.find((search_parameter) => {
    return (
      search_parameter.entity_name === filter.entity &&
      search_parameter.field_name === filter.field_name
    );
  });
};

export const Filter = ({ filter, onBlur }) => {
  const dispatch = useDispatch();

  const Component = getFilterComponent(filter);

  const search_parameters = useSelector(getSearchParameters);
  const search_parameter = getFilterSearchParameter(search_parameters, filter);

  let nodes = [];

  if (filter.filter_type === 'HierarchicalFilter') {
    nodes = cherrePropertyUseHierarchical.map((lookup) => {
      return {
        id: lookup.lookup_code,
        label: lookup.lookup_value,
        parent: lookup.parent_id,
      };
    });
  }

  return (
    <div
      id={`js-cp-filter-${filter.entity}__${filter.field_name}`}
      style={{ marginBottom: 30 }}
    >
      <CoreProspectFilterTitle filter={filter} />
      <Component
        search_parameters={search_parameters}
        onSubmit={() => {}}
        setFilterValue={(val) => {
          dispatch(changeSearchParameter(filter, val));
        }}
        filterValue={search_parameter?.attributes?.value}
        filter={filter}
        data={{
          nodes,
          parentNodeId: 'root',
          options: options[filter.field_name],
        }}
        onBlur={onBlur}
      />
    </div>
  );
};
