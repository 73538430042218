import { selectorFamily } from 'recoil';
import { cherrePropertyUseHierarchical } from 'src/const/lookups';
import { merge } from 'src/utils/recoil/utils';
import { lookupQuery, taxAssessorQuery } from '../../../recoil';
import { Tax_AssessorQueryVariables } from '../../../recoil/__generated__/taxAssessor';
import { LotInformationData } from '../types';
import {
  propertyUseStdCodeToLotInformation,
  propertyUseCodeMappedToLotInformation,
  taxAssessorToLotInformation,
} from './formatters';

export const lotInformation = selectorFamily<
  LotInformationData,
  Tax_AssessorQueryVariables
>({
  key: 'LOT-PAGE/LOT-INFORMATION-DATA',
  get:
    (params) =>
    ({ get }) => {
      const defaultValue = {
        apn: '—',
        propertyType: '—',
        propertyUse: '—',
        zone: '—',
        lastCountyAssessorUpdate: '—',
      };

      const taxAssessorValue = get(taxAssessorQuery(params));
      const taxAssessorFormatted =
        taxAssessorToLotInformation(taxAssessorValue);

      const stdCode =
        taxAssessorValue?.tax_assessor_v2?.[0]?.property_use_standardized_code;

      const propertyUseStdCode = get(
        lookupQuery({
          object_name: 'tax_assessor_v2',
          field_name: 'property_use_standardized_code',
          lookup_code: stdCode,
        })
      );
      const propertyUseStdCodeFormatted =
        propertyUseStdCodeToLotInformation(propertyUseStdCode);

      const mappedCode =
        taxAssessorValue?.tax_assessor_v2?.[0]?.property_use_code_mapped;

      const propertyUseCodeMapped = cherrePropertyUseHierarchical.find(
        (a) => a.lookup_code === mappedCode
      )?.lookup_value;

      const propertyUseCodeMappedFormatted =
        propertyUseCodeMappedToLotInformation(propertyUseCodeMapped);

      return merge(
        defaultValue,
        taxAssessorFormatted,
        propertyUseStdCodeFormatted,
        propertyUseCodeMappedFormatted
      );
    },
});
