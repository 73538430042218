import React from 'react';
import { Box, Button, useTheme } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useCreateDeal } from 'src/services/Dealcloud/createDeal';
import { CloseButton } from './CloseButton';
import { useStyles } from './style';
import ButtonProgress from 'src/components/ButtonProgress';
import { useOrganizationPropsValue } from 'src/hooks';
import PropertyLists from 'src/products/core-prospect/Layout/LotpageNavigation/PropertyList';
import Bookmark from '@material-ui/icons/Bookmark';
import { NoPortfolioDealOverlay } from './NoPortfolioDealOverlay';
import { DealOverlayProps } from './DealOverlay';
import { DashboardIcon } from '@cherre-frontend/ui';
import { useDashboardPropertyLink } from 'src/hooks/useDashboardPropertyLink';

export const NoDealOverlay: React.VFC<DealOverlayProps> = ({
  taxAssessorId,
  cherreParcelId,
  onClose,
  portfolio,
  property,
  showOnClose,
}) => {
  const classes = useStyles();
  const [createDeal, loading] = useCreateDeal();

  const isDealsIntegrationAvailable =
    useOrganizationPropsValue('deals_enabled');

  const dashboardPropertyLink = useDashboardPropertyLink(cherreParcelId);

  const theme = useTheme();

  if (portfolio) {
    return (
      <NoPortfolioDealOverlay
        taxAssessorId={taxAssessorId}
        cherreParcelId={cherreParcelId}
        onClose={onClose}
        property={property}
        showOnClose={showOnClose}
      />
    );
  }

  return (
    <Box className={classes.nodeal}>
      {isDealsIntegrationAvailable ? (
        <ButtonProgress
          startIcon={loading ? undefined : <AddCircleIcon color='primary' />}
          className={`${classes.addbtn} js-add-deal-button`}
          onClick={() => createDeal(taxAssessorId)}
          loading={loading}
          spinnerStyle={{ color: theme.palette.primary.main }}
        >
          Add Deal
        </ButtonProgress>
      ) : (
        <div />
      )}

      {dashboardPropertyLink && (
        <Button
          variant='contained'
          size='small'
          className={classes.button}
          onClick={() => window.open(dashboardPropertyLink, '_blank')}
        >
          <DashboardIcon className={classes.icon} color='primary' />
        </Button>
      )}

      <PropertyLists
        showChips={false}
        property_id={taxAssessorId}
        property_code_type='tax_assessor_id'
        noStyle
        buttonComponent={
          <Button variant='contained' size='small' className={classes.button}>
            <Bookmark className={classes.icon} color='primary' />
          </Button>
        }
      />

      {onClose && showOnClose && <CloseButton onClose={onClose} />}
    </Box>
  );
};
