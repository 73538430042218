import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

export const useUpdateUrlSearchParams = () => {
  const dispatch = useDispatch();
  // Mapbox is capturing original react router location value and it is not updating in this callback
  // We have to use window.location to get latest search params.
  return (params = {}, pathname = window.location.pathname) => {
    dispatch(
      // @ts-expect-error: TODO: investigate push arguments
      push({
        pathname,
        query: {
          ...Object.fromEntries(new URLSearchParams(window.location.search)),
          ...params,
        },
      })
    );
  };
};
