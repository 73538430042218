import React, { useCallback } from 'react';
import { ButtonBase, CircularProgress, Tooltip } from '@material-ui/core';
import EditOutlined from '@material-ui/icons/EditOutlined';
import { useAllDealsPortfolioByTaxId } from 'src/services/Dealcloud/hooks';
import { useEditDeal } from 'src/services/Dealcloud/editDeal';

export const EditDealButton: React.VFC<{
  taxAssessorId: number;
  cherreParcelId: string;
}> = ({ taxAssessorId }) => {
  const [editDeal, loading] = useEditDeal();

  const dealsInfo = useAllDealsPortfolioByTaxId({});
  const deal = dealsInfo.get(taxAssessorId);

  const onClickCallback = useCallback(() => {
    if (!deal) {
      return null;
    }
    editDeal(deal.dealId);
  }, [deal]);

  if (!deal) {
    return null;
  }

  return (
    <div className='editDeal'>
      <Tooltip title='Edit Deal'>
        <ButtonBase disabled={loading} onClick={onClickCallback}>
          {loading ? (
            <CircularProgress size={20} color='primary' />
          ) : (
            <EditOutlined color='primary' />
          )}
        </ButtonBase>
      </Tooltip>
    </div>
  );
};
