import { idFieldName } from '../table/actions';
import * as TYPES from './types';

export const getPropertyListEntriesByPropertyIds = (ids) => {
  return (dispatch) => {
    return dispatch({
      url: '/propertyListEntry/byPropertyId',
      method: 'post',
      send: { ids },
      types: [],
    });
  };
};

export const getPropertyListEntriesForTable = () => {
  return (dispatch, getState) => {
    const tableItems = getState().coreProspect.table.tableResults;
    const ids = tableItems.map((item) => item[idFieldName]);

    if (ids.length > 0) {
      return dispatch({
        url: '/propertyListEntry/byPropertyId',
        method: 'post',
        send: {
          ids,
        },
        types: [
          TYPES.GET_PROPERTY_LISTS_ENTRIES_FOR_TABLE_REQUEST,
          TYPES.GET_PROPERTY_LISTS_ENTRIES_FOR_TABLE_SUCCESS,
          TYPES.GET_PROPERTY_LISTS_ENTRIES_FOR_TABLE_FAILED,
        ],
      });
    }
  };
};

export const getPropertyLists = () => {
  return {
    url: '/propertyList',
    types: [
      TYPES.GET_PROPERTY_LISTS_REQUEST,
      TYPES.GET_PROPERTY_LISTS_SUCCESS,
      TYPES.GET_PROPERTY_LISTS_FAILED,
    ],
  };
};

export const createPropertyList = (name) => {
  window.analytics?.track('Property List Created');
  return {
    url: '/propertyList',
    method: 'post',
    send: { name },
    types: [
      TYPES.CREATE_PROPERTY_LIST_REQUEST,
      TYPES.CREATE_PROPERTY_LIST_SUCCESS,
      TYPES.CREATE_PROPERTY_LIST_FAILED,
    ],
  };
};

export const getPropertyListEntries = ({ property_id, property_code_type }) => {
  return {
    url: '/propertyListEntry',
    query: { property_id, property_code_type },
    types: [
      TYPES.GET_PROPERTY_LISTS_ENTRIES_REQUEST,
      TYPES.GET_PROPERTY_LISTS_ENTRIES_SUCCESS,
      TYPES.GET_PROPERTY_LISTS_ENTRIES_FAILED,
    ],
  };
};

export const addPropertyListEntries = ({
  list_id,
  property_ids,
  property_code_type,
}) => {
  return {
    url: `/propertyListEntries`,
    method: 'post',
    send: {
      list_id,
      property_ids,
      property_code_type,
    },
    types: [],
  };
};

export const addPropertyListEntry = ({
  list_id,
  property_id,
  property_code_type,
  where = undefined,
}: {
  list_id: number;
  property_id: string;
  property_code_type: string;
  where?: string;
}) => {
  if (where === 'preview-card') {
    window.analytics?.track('Preview Card - Add to Property List', {
      property_id,
    });
  }
  window.analytics?.track('Property Added to List', { property_id });
  return {
    url: `/propertyListEntry`,
    method: 'post',
    send: {
      list_id,
      property_id,
      property_code_type,
    },
    types: [
      TYPES.ADD_PROPERTY_LIST_ENTRY_REQUEST,
      TYPES.ADD_PROPERTY_LIST_ENTRY_SUCCESS,
      TYPES.ADD_PROPERTY_LIST_ENTRY_FAILED,
    ],
  };
};

export const removePropertyListEntry = ({ id }) => {
  return {
    url: `/propertyListEntry`,
    method: 'delete',
    send: {
      ids: [Number(id)],
    },
    types: [
      TYPES.REMOVE_PROPERTY_LIST_ENTRY_REQUEST,
      TYPES.REMOVE_PROPERTY_LIST_ENTRY_SUCCESS,
      TYPES.REMOVE_PROPERTY_LIST_ENTRY_FAILED,
    ],
  };
};

export const removePropertyListEntries = ({ ids }) => {
  return {
    url: `/propertyListEntry`,
    method: 'delete',
    send: {
      ids,
    },
    types: [
      TYPES.REMOVE_PROPERTY_LIST_ENTRIES_REQUEST,
      TYPES.REMOVE_PROPERTY_LIST_ENTRIES_SUCCESS,
      TYPES.REMOVE_PROPERTY_LIST_ENTRIES_FAILED,
    ],
  };
};

export const undoDeleteList = (listId) => {
  return {
    url: `/propertyList/${listId}/soft-delete-undo`,
    method: 'post',
    types: [
      TYPES.UNDO_DELETE_PROPERTY_LIST_REQUEST,
      TYPES.UNDO_DELETE_PROPERTY_LIST_SUCCESS,
      TYPES.UNDO_DELETE_PROPERTY_LIST_FAILED,
    ],
    dataToDispatch: { id: listId },
  };
};

export const deleteList = (listId) => {
  return {
    url: `/propertyList/${listId}/soft-delete`,
    method: 'post',
    types: [
      TYPES.DELETE_PROPERTY_LIST_REQUEST,
      TYPES.DELETE_PROPERTY_LIST_SUCCESS,
      TYPES.DELETE_PROPERTY_LIST_FAILED,
    ],
    dataToDispatch: { id: listId },
  };
};

export const updateList = (listId, data) => {
  return {
    url: `/propertyList/${listId}`,
    method: 'put',
    types: [
      TYPES.UPDATE_PROPERTY_LIST_REQUEST,
      TYPES.UPDATE_PROPERTY_LIST_SUCCESS,
      TYPES.UPDATE_PROPERTY_LIST_FAILED,
    ],
    send: data,
  };
};
