import React from 'react';

import ToggleButton from '@material-ui/lab/ToggleButton';
import Tooltip from '@material-ui/core/Tooltip';
import grey from '@material-ui/core/colors/grey';
import { useDispatch } from 'react-redux';

import { toggleCoreProspectView } from 'src/products/core-prospect/redux/view/actions';
import { useParams } from 'react-router';
import { useTheme } from '@material-ui/core';

const ViewToggleButton = ({
  id,
  view,
  viewerName,
  text,
  icon,
  value,
  isTable,
  style,
  setShowMap,
  showMap,
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const isSelected = view === value;
  const handleLotPageClick = () => {
    setShowMap(!showMap);
  };
  const handleCoreProspectSearchClick = () => {
    dispatch(toggleCoreProspectView(viewerName));
  };
  const onClickHandler = params.cherre_parcel_id
    ? handleLotPageClick
    : handleCoreProspectSearchClick;

  const theme = useTheme();

  return (
    <ToggleButton
      id={id}
      value={value}
      selected={isSelected}
      disableFocusRipple={isSelected}
      disableRipple={isSelected}
      onClick={
        !isSelected
          ? () => {
              onClickHandler();
            }
          : undefined
      }
      style={{
        ...style,
        height: '100%',
        color: isSelected ? theme.palette.primary.main : grey[400],
        border: 'none',
        cursor: isSelected ? 'auto' : 'pointer',
        backgroundColor: isTable ? 'rgb(250, 250, 250)' : '#fff',
        transition:
          'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      }}
    >
      <Tooltip title={text}>{icon}</Tooltip>
    </ToggleButton>
  );
};
export default ViewToggleButton;
