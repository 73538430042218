import * as TYPES from './types';

export const ZIPCODES_ID_FIELD = 'zip';

export const changeOffset = (offset) => {
  return {
    type: TYPES.CHANGE_OFFSET,
    offset,
  };
};

export const changeRowsPerPage = (rowsPerPage) => {
  return {
    type: TYPES.CHANGE_ROWS_PER_PAGE,
    rowsPerPage,
  };
};

export const changeOrderBy = (orderBy) => {
  return (dispatch) => {
    return dispatch({
      type: TYPES.CHANGE_ORDER_BY,
      orderBy,
    });
  };
};
