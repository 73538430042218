import React from 'react';
import Document from './Document';

const NA = '-';
const LatestTransaction = ({ tax_assessor }) => {
  const [transaction] = tax_assessor?.recorder_v2_summary_v2__tax_assessor_id;

  if (!transaction) {
    return null;
  }

  const amount = transaction.cherre_latest_deed_amount;
  const date = transaction.cherre_latest_deed_date;
  const grantor = transaction.cherre_latest_deed_grantor?.split('🞈').join(', ');
  const grantee = transaction.cherre_latest_deed_grantee?.split('🞈').join(', ');

  if (!(amount || date || grantor || grantee)) {
    return null;
  }

  return (
    <Document
      viewAllHref={`/properties/${tax_assessor.cherre_parcel_id}/${tax_assessor.tax_assessor_id}`}
      title='Latest Transaction'
      amount={amount || NA}
      date={date || NA}
      persone1={{ name: grantor || NA, label: 'Grantor' }}
      persone2={{ name: grantee || NA, label: 'Grantee' }}
    />
  );
};

export default LatestTransaction;
