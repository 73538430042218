import { uniqBy } from 'lodash';

export const getTreppData = (trepp) => {
  if (trepp.length) {
    const first = [];
    trepp.forEach((item) => {
      first.push(item.cherre_address__property_address);
    });

    const second = [];
    if (first.length) {
      first.forEach((item_2) => {
        item_2.forEach((item) => second.push(item.trepp__address));
      });
    }
    const third = [];
    if (second.length) {
      second.forEach((item_3) => {
        item_3.forEach((item) => {
          const {
            address,
            largest_tenant,
            largest_tenant_percent,
            largest_tenant_square_footage,
            second_largest_tenant,
            second_largest_tenant_percent,
            second_largest_tenant_square_footage,
            third_largest_tenant,
            third_largest_tenant_percent,
            third_largest_tenant_square_footage,
            property_name,
            property_status,
            property_status_change_date,
            trepp_loan_2__trepp_master_loan_id,
            trepp_loan__trepp_master_loan_id,
          } = item;

          third.push(
            Object.assign(
              {},
              {
                address,
                largest_tenant,
                largest_tenant_percent,
                largest_tenant_square_footage,
                second_largest_tenant,
                second_largest_tenant_percent,
                second_largest_tenant_square_footage,
                third_largest_tenant,
                third_largest_tenant_percent,
                third_largest_tenant_square_footage,
                property_name,
                property_status,
                property_status_change_date,
              },
              { ...trepp_loan_2__trepp_master_loan_id },
              { ...trepp_loan__trepp_master_loan_id }
            )
          );
        });
      });
    }
    return uniqBy(third, 'loan_id');
  }
};
