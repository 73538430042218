import React from 'react';

import Typography from '@material-ui/core/Typography';
import useIsCompact from 'src/hooks/useIsCompact';
import TimelineText from './TimelineText';
import TimelineTextItem from './TimelineTextItem';
import { VALUE_UNKNOWN } from '../../common';

const TimelineMortgageText = ({ item, isNyc }) => {
  const isCompact = useIsCompact();

  return (
    <TimelineText>
      {isNyc ? null : item.term_type_code ? (
        <TimelineTextItem>
          <TimelineTextItem>
            <Typography variant='body2'>
              {`${item.term}${item.term_type_code}`}
            </Typography>
          </TimelineTextItem>
          {!isCompact ? <TimelineTextItem>&bull;</TimelineTextItem> : null}
        </TimelineTextItem>
      ) : null}
      <TimelineTextItem>
        <TimelineTextItem>
          <Typography variant='body2'>
            {(isNyc
              ? item.grantors?.map((item) => item.name).join(', ')
              : item.lender_name) || VALUE_UNKNOWN}
          </Typography>
        </TimelineTextItem>
        <TimelineTextItem>&rarr;</TimelineTextItem>
        <TimelineTextItem>
          <Typography variant='body2'>
            {(isNyc
              ? item.grantees?.map((item) => item.name).join(', ')
              : item.recorder_v2__recorder_id?.recorder_grantee_v2__recorder_id
                  ?.map((item) => item.grantee_name)
                  .join(', ')) || VALUE_UNKNOWN}
          </Typography>
        </TimelineTextItem>
      </TimelineTextItem>
    </TimelineText>
  );
};

export default TimelineMortgageText;
