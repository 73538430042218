import {
  Typography,
  Tooltip,
  IconButton,
  Box,
  Popover,
  List,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import Delete from '@material-ui/icons/Delete';
import { showModal } from 'src/products/shell/redux/modals/actions';
import Close from '@material-ui/icons/Close';
import SupervisedUserCircle from '@material-ui/icons/SupervisedUserCircle';
import { useShareListEdit } from '../hooks/useShareListEdit';
import { ListShareWithOption } from 'src/components/modals/ShareList';
import { ResourceType } from '../recoil/types';
import { sharedResourcesSelectorFamily } from '../recoil';
import { useRecoilValueLoadable } from 'recoil';
import { grey } from '@material-ui/core/colors';
import pluralize from 'pluralize';

interface RowDataProps {
  id: string;
  type: 'Saved Search' | 'Property List';
  created: string;
  name: string;
  usersCount: number;
  teamsCount: number;
}

const useStyles = makeStyles((theme) => ({
  cellHover: {
    '&:hover': {
      color: theme.palette.primary.main,
      '& .MuiTypography-root': {
        color: `${theme.palette.primary.main} !important`,
      },
    },
  },
  header: {
    margin: '15px 35px 21px 15px',
    fontSize: '16px',
    color: grey[900],
    fontWeight: 'bold',
  },
  sharedWithList: {
    marginLeft: '18.5px',
    marginRight: '18.5px',
    marginBottom: '15px',
    maxHeight: 500,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
}));

const SharedWithPopoverContent: React.FC<{
  resourceId: number;
  resourceType: ResourceType;
  onClose: () => void;
}> = ({ resourceId, resourceType, onClose }) => {
  const classes = useStyles();

  const value = useRecoilValueLoadable(
    sharedResourcesSelectorFamily({
      resourceId,
      resourceType,
    })
  );

  const sharedWith = value.valueMaybe() || [];

  return (
    <Box>
      <IconButton
        onClick={onClose}
        size='small'
        style={{
          position: 'absolute',
          top: 3,
          right: 3,
        }}
      >
        <Close />
      </IconButton>
      <Typography className={classes.header}>Shared With</Typography>
      <List className={classes.sharedWithList}>
        {value.state === 'loading' ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: 1,
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          sharedWith.map((shared) => (
            <ListShareWithOption
              key={`${shared.targetType}-${shared.targetId}`}
              option={shared}
            />
          ))
        )}
      </List>
    </Box>
  );
};

const SharingMembers: React.FC<RowDataProps> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = props.id.split('-')[1];

  const classes = useStyles();

  return (
    <>
      <Box
        className={classes.cellHover}
        display='flex'
        alignItems='center'
        gridGap='7.5px'
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup='true'
        onClick={handlePopoverOpen}
      >
        <SupervisedUserCircle />
        <Typography className='details'>
          {props.usersCount > 0
            ? pluralize('member', props.usersCount, true)
            : null}
          {props.usersCount && props.teamsCount ? <>,&nbsp;</> : null}
          {props.teamsCount > 0
            ? pluralize('group', props.teamsCount, true)
            : null}
        </Typography>
      </Box>
      <Popover
        id='mouse-over-popover'
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
      >
        <SharedWithPopoverContent
          onClose={handlePopoverClose}
          resourceId={Number(id)}
          resourceType={ResourceType.PropertyList}
        />
      </Popover>
    </>
  );
};

const CreatedAndDelete: React.FC<RowDataProps> = (props) => {
  const dispatch = useDispatch();
  const isList = props.type === 'Property List';

  const shareListEdit = useShareListEdit();

  const id = props.id.split('-')[1];

  const { teamsCount, usersCount } = props;

  const sharesCount = usersCount + teamsCount;

  return (
    <>
      <Typography className='created'>{props.created}</Typography>
      <div className='delete'>
        {isList && (
          <>
            {sharesCount > 0 && (
              <Tooltip title={`Manage Sharing`}>
                <IconButton
                  size='small'
                  onClick={() => shareListEdit(Number(id))}
                >
                  <SupervisedUserCircle
                    style={{
                      width: 24,
                      height: 24,
                    }}
                    color='primary'
                  />
                </IconButton>
              </Tooltip>
            )}

            {!sharesCount && (
              <Tooltip title={`Share`}>
                <IconButton
                  size='small'
                  onClick={() => shareListEdit(Number(id))}
                >
                  <img src='/assets/share.svg' width={24} />
                </IconButton>
              </Tooltip>
            )}
          </>
        )}

        <Tooltip title={`Delete`}>
          <IconButton
            onClick={(e) => {
              dispatch(showModal('DELETE_LIST_OR_SEARCH', props));
              e.stopPropagation();
              e.preventDefault();
            }}
            size='small'
          >
            <Delete
              id={`${props.id}-delete`}
              style={{
                width: 24,
                height: 24,
              }}
              color='primary'
            />
          </IconButton>
        </Tooltip>
      </div>
    </>
  );
};

export const columns = (width: number) => [
  {
    dataKey: 'name',
    label: 'Name',
    isSortable: true,
    width: width * 0.2,
    cellRenderer: ({ rowData }) => {
      const id = rowData.id.split('-')[1];
      const href =
        rowData.type === 'Property List'
          ? `/properties?sidebar=property-list&list_id=${id}`
          : `/properties/${id}`;
      const onClick = () => {
        if (rowData.type === 'Property List') {
          window.analytics?.track('Property List Opened');
        } else {
          window.analytics?.track('Saved Search Opened - Landing Page');
        }
      };
      return (
        <a href={href} onClick={onClick} className='name'>
          {rowData.name}
        </a>
      );
    },
  },
  {
    dataKey: 'type',
    label: 'Type',
    isSortable: true,
    width: width * 0.15,
    cellRenderer: ({ rowData }) => {
      return <Typography className='details'>{rowData.type}</Typography>;
    },
  },
  {
    dataKey: 'details',
    label: 'Filters / Properties',
    isSortable: true,
    width: width * 0.35,
    cellRenderer: ({ rowData }) => {
      return <Typography className='details'>{rowData.details}</Typography>;
    },
  },
  {
    dataKey: 'sharing',
    label: 'Sharing',
    isSortable: false,
    width: width * 0.15,
    cellRenderer: ({ rowData }) => {
      const isList = rowData.type === 'Property List';

      if (!isList || (!rowData.usersCount && !rowData.teamsCount)) {
        return null;
      }

      return <SharingMembers {...rowData} />;
    },
  },
  {
    dataKey: 'created',
    label: 'Created',
    isSortable: true,
    width: width * 0.15,
    cellRenderer: ({ rowData }) => <CreatedAndDelete {...rowData} />,
  },
];
