import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

import * as constants from './constants';
import CONST from 'src/const';
import { Flex } from 'src/components/Flex';

const NavBar = ({
  isLoading = false,
  view,
  leftSideComponent,
  rightSideComponent,
}) => {
  const isTable = view === constants.VIEW_TABLE;
  const sectionStyle = {
    height: '100%',
    gap: '10px',
  };

  return (
    <>
      {isLoading && (
        <LinearProgress
          id='js-core-prospect-loader'
          style={{
            width: '100%',
            position: isTable ? undefined : 'absolute',
            zIndex: isTable ? undefined : 1099,
          }}
        />
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: CONST.CORE_PROSPECT_NAV_BAR_HEIGHT,
          position: isTable ? undefined : 'absolute',
          zIndex: 1,
          width: '100%',
          padding: '18px 15px',
          marginTop: isLoading && isTable ? -4 : undefined,
        }}
      >
        <Flex
          style={sectionStyle}
          justifyContent='flex-start'
          alignItems='center'
        >
          {leftSideComponent}
        </Flex>
        <Flex
          style={sectionStyle}
          justifyContent='flex-end'
          alignItems='center'
        >
          {rightSideComponent}
        </Flex>
      </div>
    </>
  );
};

export default NavBar;
