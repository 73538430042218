import { GetOwnersQuery } from '../queries/__generated__/getOwners';

export const getContacts = (
  ownerId: string,
  unmaskedOwners: GetOwnersQuery['usa_owner_unmask_v2']
) => {
  const owner = unmaskedOwners.find((owner) => ownerId === owner.owner_id);

  const address = owner?.cherre_address__address?.[0]?.display_address || '';

  return {
    address,
  };
};
