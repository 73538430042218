import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { Loadable } from 'recoil';
import { HeatmapSettings } from '../hooks/useMapLayers/heatmaps';
import { getBreaks, getColorScale } from '../layers/utils';
import { LayerName } from '../types';

type Props = {
  demographicsData?: Loadable<string[][]>;
  heatmapSettings?: HeatmapSettings;
  layerName?: LayerName;
};

const useStyles = makeStyles({
  root: {
    background: '#fff',
    boxShadow: '0 2px 5px 0 #00000033',
    borderRadius: 5,
    padding: '.4em 1em 1em 1em',
    alignSelf: 'flex-end',
  },
  loaderRoot: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const DemographicsLegend: React.FC<Props> = ({
  layerName,
  demographicsData,
  heatmapSettings,
}) => {
  const data = demographicsData?.valueMaybe() || [];
  const isDataLoading = demographicsData?.state === 'loading';
  const classes = useStyles();

  if (!layerName || !heatmapSettings) {
    return null;
  }

  const title =
    {
      average_household_income: 'Average household income',
      crime_total_risk: 'Crime risk',
      population_median_age: 'Median age',
    }[layerName] || layerName;

  if (isDataLoading) {
    return (
      <div id='map-stats-legend' className={classes.root}>
        <Typography gutterBottom variant='h6'>
          {isDataLoading ? 'Loading demographics data...' : title}
        </Typography>
        <div className={classes.loaderRoot}>
          <CircularProgress size={30} />
        </div>
      </div>
    );
  }

  if (!Array.isArray(data)) {
    return null;
  }

  if (data.length === 0) {
    return null;
  }

  const rows = data.map((r) => [r[0], Number(r[1])]);
  const colorScheme = heatmapSettings.colorScheme;
  const colorScale = getColorScale(rows, colorScheme);
  const breaks = getBreaks(rows.map((row) => row[1]));

  return (
    <div id='map-stats-legend' className={classes.root}>
      <Typography gutterBottom variant='h6'>
        {title}
      </Typography>
      <div id='core-prospect-demographics-stats-legend'>
        <div>
          {breaks
            .reverse()
            .slice(0, -1)
            .map((breakPoint, index) => {
              return (
                <div
                  key={index}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <div
                    style={{
                      width: 30,
                      height: 30,
                      background: colorScale(breakPoint),
                    }}
                  />
                  <span style={{ paddingLeft: '1em' }}>
                    {heatmapSettings.numberFormatFunc
                      ? heatmapSettings.numberFormatFunc(breakPoint)
                      : Number(breakPoint).toFixed(1)}
                  </span>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default DemographicsLegend;
