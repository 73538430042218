const options = {
  class: [
    {
      value: '',
      name: 'None',
    },
    {
      value: 'DEEDS AND OTHER CONVEYANCES',
      name: 'DEEDS AND OTHER CONVEYANCES',
    },
    {
      value: 'MORTGAGES & INSTRUMENTS',
      name: 'MORTGAGES & INSTRUMENTS',
    },
  ],
  party_type_code: [
    {
      value: '',
      name: 'None',
    },
    {
      value: 1,
      name: 'SELLER/BORROWER',
    },
    {
      value: 2,
      name: 'BUYER/LENDER',
    },
  ],
};

export default options;
