import { getColorScale, getFillColorProp, handleClick } from './utils';
import { LAYERS } from '../const';
import { LayerProps } from 'src/components/Map/types';
import { Expression } from 'mapbox-gl';
import { OnLayerClick } from '../types';
import { HeatmapSettings } from '../hooks/useMapLayers/heatmaps';

type GetLayersArg = {
  onClick: OnLayerClick;
  heatmapSettings: HeatmapSettings;
  demographicsData: string[][];
};

function getColorplethLayers({
  onClick,
  heatmapSettings,
  demographicsData,
}: GetLayersArg): LayerProps[] {
  const geometryIdField = 'GEO_ID';

  const rows = demographicsData.map((r) => [r[0], Number(r[1])]);
  const sourceLayer = heatmapSettings.sourceLayer;
  const colorScheme = heatmapSettings.colorScheme;
  const colorScale = getColorScale(rows, colorScheme);
  const color = colorScheme[1];

  const fillColor = getFillColorProp({
    colorScale,
    rows,
    geometryIdField,
  }) as Expression;

  const fillOpacity = [
    'interpolate',
    ['linear'],
    ['zoom'],
    13,
    0.5,
    15,
    0.2,
  ] as Expression; // reduce opacity as we zoom in to improve contrast with property boundaries

  return [
    {
      id: LAYERS.COLORPLETH,
      source: heatmapSettings.source,
      'source-layer': sourceLayer,
      type: 'fill',
      paint: {
        'fill-color': fillColor,
        'fill-opacity': fillOpacity,
      },
      onClick: (e) => handleClick(e, 'colorpleth', onClick),
      beforeId: LAYERS.COLORPLETH_LINE,
    },
    {
      id: LAYERS.COLORPLETH_LINE,
      source: heatmapSettings.source,
      'source-layer': sourceLayer,
      type: 'line',
      paint: {
        'line-color': color,
        'line-width': 1,
      },
    },
  ];
}

export default getColorplethLayers;
