import React from 'react';
import Typography from '@material-ui/core/Typography';
import { TYPE_COMPANY } from '../common';
import { useDispatch } from 'react-redux';
import { clickOwner } from 'src/products/core-prospect/redux/view/actions';
import { Link } from '@material-ui/core';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';

type OwnerCardNameProps = {
  name: string;
};

const OwnerCardName: React.FC<OwnerCardNameProps> = ({ name }) => {
  return (
    <Typography variant='h5' style={{ lineHeight: 1 }}>
      {name}
    </Typography>
  );
};

type OwnerCardTitleAndEmployeeCountProps = {
  type: string;
  name: string;
  owner_id?: string;
  showEmployees: boolean;
  employeeCount: number;
};

const OwnerCardTitleAndEmployeeCount: React.FC<
  OwnerCardTitleAndEmployeeCountProps
> = ({ type, name, owner_id }) => {
  const dispatch = useDispatch();

  const ownerDetailsEnabled = useFeatureFlag('CoreExplore_OwnerDetails');

  return (
    <div style={{ marginBottom: 20 }}>
      {type === TYPE_COMPANY && owner_id && ownerDetailsEnabled ? (
        <Link
          onClick={() => dispatch(clickOwner(owner_id, name))}
          href={encodeURI(`/owners/${owner_id}/${name}`)}
        >
          <OwnerCardName name={name} />
        </Link>
      ) : (
        <OwnerCardName name={name} />
      )}
    </div>
  );
};

export default OwnerCardTitleAndEmployeeCount;
