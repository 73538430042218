import {
  GetUsaDemographicsQuery,
  GetUsaDemographicsQueryVariables,
} from './__generated__/query';
import { gql, GQL } from 'src/utils/recoil/gql';

export const getUsaDemographicsQuery = gql`
  query GetUsaDemographics($geographyId: String!, $vintage: String!) {
    usa_demographics_v2(
      limit: 1
      offset: 0
      where: { geography_id: { _eq: $geographyId }, vintage: { _eq: $vintage } }
    ) {
      median_household_income
      household_income_0_10_count
      household_income_10_15_count
      household_income_15_20_count
      household_income_20_25_count
      household_income_25_30_count
      household_income_30_35_count
      household_income_35_40_count
      household_income_40_45_count
      household_income_45_50_count
      household_income_50_60_count
      household_income_60_75_count
      household_income_75_100_count
      household_income_100_125_count
      household_income_125_150_count
      household_income_150_200_count
      household_income_200_250_count
      household_income_250_300_count
      household_income_over_500_count
      crime_total_risk
      crime_assault_risk
      crime_burglary_risk
      crime_larceny_risk
      crime_murder_risk
      crime_motor_vehicle_risk
      crime_personal_risk
      crime_property_risk
      crime_rape_risk
      crime_robbery_risk
      population_median_age
      population_age_00_04_count
      population_age_05_09_count
      population_age_10_14_count
      population_age_15_19_count
      population_age_20_24_count
      population_age_25_29_count
      population_age_30_34_count
      population_age_35_39_count
      population_age_40_44_count
      population_age_45_49_count
      population_age_50_54_count
      population_age_55_59_count
      population_age_60_64_count
      population_age_65_69_count
      population_age_70_74_count
      population_age_75_79_count
      population_age_80_84_count
      population_age_over_85_count
      education_less_than_9_count
      education_some_high_school_count
      education_high_school_graduate_count
      education_some_college_count
      education_associate_degree_count
      education_bachelors_degree_count
      education_graduate_degree_count
      employee_business_and_financial_operations_count
      employee_arts_count
      employee_cleaning_maintenance_count
      employee_office_and_admin_count
      employee_computer_math_science_count
      employee_construction_count
      employee_social_services_count
      employee_education_count
      employee_architecture_engineering_count
      employee_food_service_count
      employee_healthcare_count
      employee_healthcare_practitioner_count
      employee_repair_count
      employee_military_count
      employee_personal_care_count
      employee_farming_count
      employee_production_count
      employee_protective_service_count
      employee_sales_count
      employee_social_science_count
      employee_transportation_count
      employee_executive_count
    }
  }
` as GQL<GetUsaDemographicsQuery, GetUsaDemographicsQueryVariables>;
