import React, { useCallback, useEffect, useMemo } from 'react';
import { connect, RootState, useDispatch, useSelector } from 'react-redux';
import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

import constants from 'src/const';
import InfiniteList from 'src/components/InfiniteList';

import AddToListButton from 'src/products/core-prospect/search-pages/components/Buttons/AddToListButton';
import DeleteFromListButton from 'src/products/core-prospect/search-pages/components/Buttons/DeleteFromListButton';
import { execMapCardsQuery } from 'src/products/core-prospect/redux/query/actions/execMapCardsQuery';

import Footer from './Footer';
import {
  PROPERTY_ITEM_HEIGHT,
  PropertyAddressSummary,
} from 'src/products/core-prospect/components/PropertyAddressSummary';
import { usaElasticDataFormatter } from 'src/products/core-prospect/components/PropertyAddressSummary/dataFormatter/usaElasticDataFormatter';
import { selectRow } from 'src/products/core-prospect/redux/table/actions';
import { push } from 'connected-react-router';
import { MAP_PARCEL_CLICKED } from 'src/products/shell/redux/middleware/segment';
import { getLotPagePath, getLotPreviewPath } from '../../../utils';
import { FetchStatus } from 'src/products/core-prospect/redux/query/reducers';
import { debounce } from 'lodash';
import { ExportButton } from './ExportButton';
import { useLocation } from 'react-router';
import { useAllDealsPortfolioByTaxId } from 'src/services/Dealcloud/hooks';
import {
  displayCards,
  hideCards,
} from 'src/products/core-prospect/redux/view/actions';
import { execQuery } from 'src/products/core-prospect/redux/query/actions/execQuery';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';

type Card = RootState['coreProspect']['mapPinResults']['cards'][number];

const useStyles = makeStyles({
  filters: {
    flex: 1,
    overflowX: 'hidden',
    overflowY: 'inherit',
  },
  mapOptions: {
    bottom: 0,
    left: 0,
    width: '100%',
    backgroundColor: 'white',
    padding: '20px',
    borderTop: `1px solid ${grey[400]}`,
    display: 'flex',
    justifyContent: 'space-around',
  },
});

const Options: React.VFC = () => {
  const classes = useStyles();
  const { search } = useLocation();

  const list_id = useMemo(
    () => new URLSearchParams(search).get('list_id'),
    [search]
  );

  const { count } = useSelector((state: RootState) => {
    return {
      count: state.coreProspect.table.selectedRows?.length,
    };
  });

  if (!count) {
    return null;
  }

  return (
    <div className={classes.mapOptions}>
      {list_id ? <DeleteFromListButton /> : <AddToListButton />}
      <ExportButton />
    </div>
  );
};

const LotProperty: React.VFC<{
  property: Card;
  selected: boolean;
}> = ({ property, selected }) => {
  const dispatch = useDispatch();
  const data = useMemo(() => usaElasticDataFormatter(property), [property]);
  const deals = useAllDealsPortfolioByTaxId({});
  const deal = deals?.get(data.taxAssessorId || 0);

  const onClickActionCallback = useCallback(() => {
    dispatch({
      type: MAP_PARCEL_CLICKED, // segment
    });

    dispatch(
      // @ts-expect-error: TODO: investigate push arguments
      push({
        pathname: window.location.pathname,
        query: {
          // Mapbox is capturing original react router location value and it is not updating in this callback
          // We have to use window.location to get latest search params.
          ...Object.fromEntries(new URLSearchParams(window.location.search)),
          cherre_parcel_id: property.cherre_parcel_id,
          latitude: property.location.lat,
          longitude: property.location.lon,
          isPopupOpen: true,
        },
      })
    );
  }, [property]);

  const onSelectCallback = useCallback(
    (selected) => {
      dispatch(
        selectRow(
          {
            selected,
            rowData: property,
          },
          'tax_assessor_id'
        )
      );
    },
    [property]
  );

  const onHoverCallback = useMemo(() => {
    return debounce(
      () =>
        dispatch(
          // @ts-expect-error: TODO: investigate push arguments
          push({
            pathname: window.location.pathname,
            query: {
              // Mapbox is capturing original react router location value and it is not updating in this callback
              // We have to use window.location to get latest search params.
              ...Object.fromEntries(
                new URLSearchParams(window.location.search)
              ),
              map_cherre_parcel_id: property.cherre_parcel_id,
            },
          })
        ),
      500
    );
  }, [property]);

  const previewEnabled = useFeatureFlag('CoreExplore_LotCard_preview');

  const buttonLink = previewEnabled
    ? getLotPreviewPath(data.parcelId || '', Number(data.taxAssessorId))
    : undefined;

  const addressLink = getLotPagePath(
    data.parcelId || '',
    Number(data.taxAssessorId)
  );

  const isPortfolio = (deal?.assets.length || 0) > 1;

  return (
    <PropertyAddressSummary
      deal={deal}
      onSelect={onSelectCallback}
      onHoverContainer={onHoverCallback}
      onClickOpenPopupPin={onClickActionCallback}
      selected={selected}
      property={data}
      buttonHref={buttonLink}
      addressHref={addressLink}
      addressHrefExternal
      padding='15px 0px 0px'
      showPropertySubtitle={!isPortfolio}
      showPortfolioSummary={isPortfolio}
      showPropertyLastSale={!deal}
      showPortfolioChip={isPortfolio}
      showDealLeadStageFormatted={Boolean(deal)}
    />
  );
};

export function LotsListView({ cards }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const loadMoreRowsCallback = useCallback(() => {
    dispatch(
      execMapCardsQuery({
        paginate: true,
        viewportOnly: true,
      })
    );
  }, []);

  const selectedRows = useSelector(
    (state: RootState) => state.coreProspect.table.selectedRows
  );

  const rowRenderCallback = useCallback(
    ({ index, style }) => {
      const property = cards[index];
      const selected = !!selectedRows.find(
        (r) => r.tax_assessor_id === property.tax_assessor_id
      );

      return (
        <div
          key={property.tax_assessor_id}
          style={style}
          className='js-core-prospect-lot-card'
        >
          <LotProperty property={property} selected={selected} />
        </div>
      );
    },
    [cards, selectedRows]
  );

  const isRowLoadedCallback = useCallback(({ index }) => cards[index], [cards]);

  useEffect(() => {
    window.analytics?.track('Map Results Opened');
  }, []);

  return (
    <>
      <div id='js-core-prospect-lots-cards' className={classes.filters}>
        {cards.length > 0 && (
          <InfiniteList
            minimumBatchSize={50}
            isRowLoaded={isRowLoadedCallback}
            loadMoreRows={loadMoreRowsCallback}
            rowHeight={PROPERTY_ITEM_HEIGHT}
            rowCount={cards.length}
            rowRenderer={rowRenderCallback}
          />
        )}
      </div>
      <Options />
      <Footer activeTab={0} />
    </>
  );
}

const LotsList: React.VFC<{
  cards: Card[];
  execMapCardsQueryFetchStatus: FetchStatus;
}> = ({ cards = [], execMapCardsQueryFetchStatus }) => {
  const loading = execMapCardsQueryFetchStatus.value === constants.LOADING;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(displayCards());
    dispatch(
      execQuery({
        id: 'Cards',
        viewportOnly: true,
        count: false,
        results: false,
        rebound: false,
        cards: true,
      })
    );
    return () => dispatch(hideCards());
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {loading && <LinearProgress />}
      <LotsListView cards={cards} />
    </div>
  );
};

function mapStateToProps(state: RootState) {
  const {
    coreProspect: {
      mapPinResults: { cards },
      query: { execMapCardsQueryFetchStatus },
    },
  } = state;

  return {
    cards,
    execMapCardsQueryFetchStatus,
  };
}

export default connect(mapStateToProps)(LotsList);
