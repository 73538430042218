import geojsonBoundingBox from 'geojson-bounding-box';
import { MapBounds } from 'src/components/Map/index.new';
import getParcelsPointsFeatureCollection from './getParcelsPointsFeatureCollection';

type Parcel = {
  latitude: number;
  longitude: number;
};

const getParcelsPointsBounds = (parcels: Parcel[]): MapBounds | void => {
  const parcelsWithLocation = parcels.filter(
    (parcel) => parcel.latitude && parcel.longitude
  );

  if (!parcelsWithLocation.length) {
    return;
  }

  const featureCollection =
    getParcelsPointsFeatureCollection(parcelsWithLocation);

  const [lng1, lat1, lng2, lat2] = geojsonBoundingBox(featureCollection);

  return [
    [lng1, lat1],
    [lng2, lat2],
  ];
};

export default getParcelsPointsBounds;
