import { Typography } from '@material-ui/core';
import React from 'react';
import { HorizontalKeyValue } from '../../components';
import { panels } from '../../constants';
import useStyles from './styles';
import * as T from './types';

const LotSize: T.LotSize = ({ size, width, depth, grossArea, lotSizeAcre }) => {
  const classes = useStyles();
  return (
    <div id={panels[1].id} className={classes.container}>
      <Typography variant='h4' style={{ marginBottom: '25px' }}>
        Lot Size
      </Typography>
      <HorizontalKeyValue label='Size' value={size} />
      <HorizontalKeyValue label='Width' value={width} />
      <HorizontalKeyValue label='Depth' value={depth} />
      <HorizontalKeyValue label='Gross Area' value={grossArea} />
      <HorizontalKeyValue label='Lot Size (Acre)' value={lotSizeAcre} />
    </div>
  );
};

LotSize.displayName = 'LotSize';

export default LotSize;
