import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import FiltersView from './Views/FiltersView/index.new';
import SearchesView from './Views/SavedSearchesView';
import PropertyListView from './Views/PropertyListView';
import PropertyListsView from './Views/PropertyListsView';

import { getViews } from 'src/products/core-prospect/redux/query/actions';
import { getPropertyLists } from 'src/products/core-prospect/redux/propertyLists/actions';

import SaveSearchDialog from '../SaveSearchDialog';
import { CORE_PROSPECT_VIEWS } from 'src/products/core-prospect/constants';
import TaxRecordPreview from './components/TaxRecordPreview';
import { clearSelectedRows } from 'src/products/core-prospect/redux/table/actions';
import { useParams } from 'react-router';
import { push } from 'connected-react-router';
import useLocation from 'src/hooks/useLocation';

const SIDEBAR_PROPERTY_LIST = 'property-list';
const SIDEBAR_PROPERTY_LISTS = 'property-lists';
const SIDEBAR_FILTERS = 'filters';
const SIDEBAR_SEARCHES = 'searches';
const SIDEBAR_LOTS_LIST = 'sidebar-lots-list';

const Sidebar = ({
  classes,
  style,
  getViews,
  getPropertyLists,
  user,
  viewerName,
}) => {
  const location = useLocation();
  const {
    sidebar,
    isLotPreviewOpen,
    preview_cherre_parcel_id,
    preview_tax_assessor_id,
  } = location.query;

  const dispatch = useDispatch();

  const { id } = useParams();

  React.useEffect(() => {
    getViews();

    if (user?.featureFlags?.cp_PropertyLists) {
      getPropertyLists();
    }
  }, []);

  const getMode = (id, sidebar) => {
    if (sidebar === SIDEBAR_FILTERS) {
      return SIDEBAR_FILTERS;
    }

    if (sidebar === SIDEBAR_SEARCHES) {
      return SIDEBAR_SEARCHES;
    }

    if (sidebar === SIDEBAR_PROPERTY_LISTS) {
      return SIDEBAR_PROPERTY_LISTS;
    }

    if (sidebar === SIDEBAR_LOTS_LIST) {
      return SIDEBAR_LOTS_LIST;
    }

    if (sidebar === SIDEBAR_PROPERTY_LIST) {
      return SIDEBAR_PROPERTY_LIST;
    }

    if (id) {
      return SIDEBAR_FILTERS;
    }

    return SIDEBAR_FILTERS;
  };

  const mode = getMode(id, sidebar);

  return (
    <>
      <div
        id='js-core-prospect-search-sidebar'
        style={{
          position: 'relative',
          width: style.width || '350px',
          minWidth: style.minWidth || '350px',
          height: style.height || 'auto',
          zIndex: 3,
        }}
        className={classes.root}
      >
        {mode === SIDEBAR_FILTERS && <FiltersView mode={SIDEBAR_FILTERS} />}

        {mode === SIDEBAR_SEARCHES && <SearchesView />}

        {mode === SIDEBAR_PROPERTY_LIST &&
          (viewerName === CORE_PROSPECT_VIEWS.TABLE ? (
            <PropertyListsView />
          ) : (
            <PropertyListView />
          ))}

        {mode === SIDEBAR_PROPERTY_LISTS && <PropertyListsView />}

        {mode === SIDEBAR_LOTS_LIST && <FiltersView mode={SIDEBAR_LOTS_LIST} />}

        <SaveSearchDialog />
      </div>
      {isLotPreviewOpen && viewerName === CORE_PROSPECT_VIEWS.MAP && (
        <TaxRecordPreview
          style={{ zIndex: 3 }}
          cherre_parcel_id={preview_cherre_parcel_id}
          tax_assessor_id={preview_tax_assessor_id}
          onClose={() => {
            dispatch(
              push({
                ...location,
                query: {
                  ...location.query,
                  isLotPreviewOpen: undefined,
                  preview_cherre_parcel_id: undefined,
                  preview_tax_assessor_id: undefined,
                },
              })
            );
          }}
        />
      )}
    </>
  );
};

const styles = {
  root: {
    background: '#fff',
    boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.5)',
    zIndex: 99,
    overflow: 'auto',
  },
};

const ConnectedSidebar = connect(
  (state) => ({
    user: state.user.profile.value,
    viewerName: state.coreProspect.view.viewerName,
    resultsType: state.coreProspect.view.resultsType,
  }),
  { getViews, getPropertyLists, clearSelectedRows }
)(withStyles(styles)(Sidebar));

export default ConnectedSidebar;
