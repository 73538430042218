import React from 'react';
import Feedback from '@material-ui/icons/Feedback';
import ButtonProgress from 'src/components/ButtonProgress';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '@material-ui/lab';
import { withSnackbar } from 'notistack';
import useIsCompact from 'src/hooks/useIsCompact';
import { LotPageParams } from '../../../../types';
import { useParams } from 'react-router';
import { ButtonBase, Tooltip } from '@material-ui/core';

const {
  useTheme,
  IconButton,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  MenuItem,
  Button,
  makeStyles,
  MuiThemeProvider,
  createMuiTheme,
} = require('@material-ui/core');

const useStyles = makeStyles({
  paper: {
    width: 450,
    maxWidth: '100%',
  },
  alert: {
    marginBottom: 20,
  },
});

const options = [
  'Lot information',
  'Taxes',
  'Timeline',
  'Ownership',
  'RCA - Sales Comps',
  'Compstak - Leases',
];

const TEXT_LIMIT = 1000;

const FeedbackIcon = ({ enqueueSnackbar }) => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [type, setType] = React.useState('');
  const [text, setText] = React.useState('');
  const [isDialogOpen, toggleDialog] = React.useState(false);
  const isCompact = useIsCompact();

  const { id: tax_assessor_id } = useParams<LotPageParams>();

  const { user } = useSelector((state) => ({
    user: state.user.profile.value,
  }));

  // this is used to construct the URL
  const data = { lot_data: { cherre_parcel_id: 'PARCEL_ID', tax_assessor_id } };

  const handleSubmit = React.useCallback(async () => {
    setIsLoading(true);
    try {
      await dispatch({
        method: 'post',
        url: '/data-feedback',
        send: {
          type,
          text,
          data,
        },
      });

      enqueueSnackbar(
        <MuiThemeProvider theme={createMuiTheme(theme)}>
          <Typography>Your feedback was submitted</Typography>
        </MuiThemeProvider>,
        {
          autoHideDuration: 5000,
          anchorOrigin: {
            horizontal: isCompact ? 'center' : 'right',
            vertical: isCompact ? 'bottom' : 'top',
          },
        }
      );
      toggleDialog(false);
    } catch (e: any) {
      setError(e.message);
      enqueueSnackbar(
        <MuiThemeProvider theme={createMuiTheme(theme)}>
          <Typography>Error. Feedback was not submitted</Typography>
        </MuiThemeProvider>,
        {
          variant: 'error',
          autoHideDuration: 5000,
          anchorOrigin: {
            horizontal: isCompact ? 'center' : 'right',
            vertical: isCompact ? 'bottom' : 'top',
          },
        }
      );
    } finally {
      setIsLoading(false);
    }
  }, [type, text, data]);

  if (!user?.featureFlags?.lot_feedback_dialog) {
    return null;
  }

  return (
    <>
      <div
        onClick={() => toggleDialog(true)}
        style={{
          cursor: 'pointer',
          color: theme.palette.primary.main,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Tooltip title='Report issue'>
          <IconButton
            style={{ padding: 5 }}
            component={ButtonBase}
            id='lot-page-feedback-icon'
            className='js-data-feedback-icon'
            color='primary'
          >
            <Feedback />
          </IconButton>
        </Tooltip>
      </div>
      {isDialogOpen && (
        <Dialog
          classes={{
            paper: classes.paper,
          }}
          open={isDialogOpen}
          onClose={() => toggleDialog(false)}
        >
          <DialogTitle>Report an issue</DialogTitle>
          {error && (
            <Alert className={classes.alert} severity='error'>
              {error}
            </Alert>
          )}
          <DialogContent className='js-data-feedback-content'>
            <TextField
              className='js-data-feedback-type-selector'
              name='type'
              size='small'
              select
              fullWidth
              label='Issue type'
              variant='outlined'
              value={type}
              onChange={(e) => {
                setType(e.target.value);
              }}
            >
              {options.map((option) => (
                <MenuItem value={option}>{option}</MenuItem>
              ))}
            </TextField>
            <br />
            <br />
            <TextField
              name='text'
              size='small'
              fullWidth
              variant='outlined'
              multiline
              rows={4}
              value={text}
              label='Add your feedback'
              onChange={(e) => {
                setText(e.target.value);
              }}
            />
          </DialogContent>
          <DialogActions className='js-data-feedback-actions'>
            <span style={{ position: 'absolute', left: 24 }}>
              {text.length}/{TEXT_LIMIT}
            </span>
            <Button
              className='js-data-feedback-close-button'
              onClick={() => toggleDialog(false)}
            >
              Cancel
            </Button>
            <ButtonProgress
              type='submit'
              onClick={handleSubmit}
              loading={isLoading}
              color='primary'
              variant='contained'
              disabled={!text || !type || text.length > TEXT_LIMIT}
            >
              Submit
            </ButtonProgress>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default withSnackbar(FeedbackIcon);
