import React, { useRef } from 'react';
import pluralize from 'pluralize';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import List from 'src/components/InteractiveList/List';
import ListIcon from 'src/components/InteractiveList/ListIcon';
import { getPropertyLists } from 'src/products/core-prospect/redux/propertyLists/actions';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  list: {
    minWidth: 350,
  },
}));

const PropertyList = ({ disabled, disableText, onSelect, onCreate, title }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { propertyLists } = useSelector((state) => {
    return {
      propertyLists: state.coreProspect.propertyLists.propertyLists.value,
    };
  });

  const popover = useRef();

  const handleItemSelect = async (item) => {
    popover.current.close();
    await onSelect(item.value);
  };

  const handleCreate = async (name) => {
    popover.current.close();
    await onCreate(name);
  };

  React.useEffect(() => {
    dispatch(getPropertyLists());
  }, []);

  const items = (propertyLists || []).map((list) => ({
    label: list.name,
    value: list.id,
    secondary: pluralize('Property', list.entries_count, true),
  }));

  return (
    <div className={classes.root}>
      <ListIcon
        title={title}
        disableText={disableText}
        ref={popover}
        disabled={disabled}
      >
        <div className={classes.list}>
          <List
            onItemCreate={handleCreate}
            items={items}
            selected={[]}
            onItemSelect={handleItemSelect}
          />
        </div>
      </ListIcon>
    </div>
  );
};

export default PropertyList;
