import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  link: {
    marginTop: 10,
    display: 'block',
    textAlign: 'right',
  },
});

const ViewAllLink = ({ to, external = true, children }) => {
  const classes = useStyles();

  return (
    <Link className={classes.link} target={external ? '_blank' : null} to={to}>
      <Typography color='primary'>{children}</Typography>
    </Link>
  );
};

export default ViewAllLink;
