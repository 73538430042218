import React from 'react';
import { makeStyles } from '@material-ui/core';
import ZIPComponent from './ZIP';
import REISSubmarketComponent from './REIS';
import MSAComponent from './MSA';
import { selectedSubFilterAtom } from './recoil';
import * as T from './types';
import { useRecoilState } from 'recoil';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateRows: 'max-content 1fr',
    overflow: 'auto',
    minHeight: '100%',
  },
  geographyTypePicker: {
    display: 'flex',
    gap: 8,
    overflow: 'auto',
    padding: 21,
  },
  geographyTypeChip: {
    padding: '5px 12px 6px 13px',
    borderRadius: 14.5,
    background: theme.palette.grey[200],
    cursor: 'pointer',
  },
  geographyTypeChipSelected: {
    background: theme.palette.primary.main,
    color: 'white',
  },
}));

const components: Record<T.GeoTypeKeys, React.VFC> = {
  ZIP: ZIPComponent,
  MSA: MSAComponent,
  REISSubMarket: REISSubmarketComponent,
};

const GeographyPicker: React.VFC = () => {
  const classes = useStyles();
  const [geoType, setGeoType] = useRecoilState(selectedSubFilterAtom);
  const SelectedComponent = components[geoType];
  return (
    <div className={classes.container}>
      <div className={classes.geographyTypePicker}>
        {Object.entries(T.GeoTypes).map(([key, value]) => (
          <div
            key={key}
            className={
              classes.geographyTypeChip +
              (geoType === key ? ' ' + classes.geographyTypeChipSelected : '')
            }
            onClick={() => setGeoType(key as any)}
          >
            {value}
          </div>
        ))}
      </div>
      <SelectedComponent />
    </div>
  );
};

export default GeographyPicker;
