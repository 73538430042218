import React from 'react';

import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { useDispatch } from 'react-redux';
import { resetAllFilters } from 'src/products/core-prospect/redux/query/actions/resetAllFilters';
import Refresh from '@material-ui/icons/Refresh';

const ResetAllButton: React.VFC = () => {
  const dispatch = useDispatch();

  return (
    <Tooltip title='Clear Applied Filters'>
      <Button
        id='js-core-prospect-reset-all-filters-button'
        onClick={() => dispatch(resetAllFilters())}
        startIcon={<Refresh />}
        color='primary'
      >
        Clear Filters
      </Button>
    </Tooltip>
  );
};

export default ResetAllButton;
