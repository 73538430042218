import {
  LinearProgress,
  Link,
  makeStyles,
  Popover,
  Theme,
} from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { execMapCardsQueryForOwner } from 'src/products/core-prospect/redux/query/actions/execMapCardsQueryForOwner';
import { RowData } from '../types';
import TaxRecordItem, { TAX_RECORD_HEIGHT } from './TaxRecordItem';

interface Props {
  rowData: RowData;
  type: 'matching' | 'all';
}

const useStyles = makeStyles<Theme, Props>(() => ({
  clickable: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  popoverPaper: {
    height: (props) => {
      let n = 1;
      if (props.type === 'all') {
        n = Number(props.rowData.totalTaxRecordsCount || 1);
      } else {
        n = Number(props.rowData.propertyCount || 1);
      }
      return Math.min(n, 4) * TAX_RECORD_HEIGHT;
    },
    minWidth: 300,
    borderRadius: '6px',
  },
  tooltipContainer: {
    pointerEvents: 'none',
  },
  tooltipPaper: {
    width: 200,
    background: 'rgba(66, 66, 66, 0.9)',
    color: 'white',
    textAlign: 'center',
    padding: '3px 7px 3px 5px',
    borderRadius: '3px',
    position: 'absolute',
    zIndex: 10,
    top: '60%',
    left: '15px',
    fontSize: '12px',
  },
}));

const TaxRecordCell: React.FC<Props> = ({ rowData, type }) => {
  const classes = useStyles({ rowData, type });
  const [matchingRecords, setMatchingRecords] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const dispatch: any = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const [anchorTooltip, setAnchorTooltip] = React.useState<Element | null>(
    null
  );
  const fetch = () => {
    if (matchingRecords || loading) {
      return;
    }
    setLoading(true);
    dispatch(execMapCardsQueryForOwner({ ownerId: rowData.ownerId }))
      .then(({ result }) =>
        setMatchingRecords(
          result.map((entry) => ({
            ...entry,
            recorder: entry.recorder_summary,
          }))
        )
      )
      .finally(() => setLoading(false));
  };
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (type === 'matching') {
      fetch();
    }
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
    setAnchorTooltip(e.currentTarget);
  };
  const handleMouseLeave = () => {
    setAnchorTooltip(null);
  };
  let className = '';
  if (rowData.totalTaxRecordsCount !== 'Loading' || type === 'matching') {
    className += ' ' + classes.clickable;
  }

  const records =
    (type === 'all' ? rowData.totalTaxRecords : matchingRecords) || [];

  const totalRecords =
    type === 'all' ? rowData.totalTaxRecordsCount : rowData.propertyCount;

  return (
    <>
      {totalRecords && (
        <div
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleClick}
          className={className}
        >
          <Link>{totalRecords}</Link>
        </div>
      )}
      <Popover
        onClose={handleClose}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        classes={{
          paper: classes.popoverPaper,
        }}
      >
        {loading && <LinearProgress />}
        {records
          .filter((tr) => !!tr)
          .map((taxRecord, i) => {
            return (
              <TaxRecordItem
                key={i}
                address={taxRecord.address}
                type={taxRecord.property_group_type}
                size={taxRecord.lot_size_sq_ft}
                saleAmount={taxRecord.recorder[0]?.cherre_latest_deed_amount}
                saleDate={taxRecord.recorder[0]?.cherre_latest_deed_date}
                parcel_id={taxRecord.cherre_parcel_id}
                tax_assessor_id={taxRecord.tax_assessor_id}
              />
            );
          })}
      </Popover>
      <Popover
        open={Boolean(anchorTooltip)}
        anchorEl={anchorTooltip}
        className={classes.tooltipContainer}
        classes={{
          paper: classes.tooltipPaper,
        }}
      >
        {type === 'all'
          ? 'Show all owner tax records'
          : 'Show matching owner tax records'}
      </Popover>
    </>
  );
};

export default TaxRecordCell;
