import { Feature, Geometry } from '@turf/helpers';
import { MapEvent } from 'react-map-gl';
import { FeatureFlag } from 'src/products/shell/redux/reducers/user';
import { DealStageType } from 'src/services/Dealcloud/types';

export enum LayerGroupName {
  mapLayers = 'Area boundaries',
  eqtCustomLayers = 'Custom Layers',
  demographicsLayers = 'Heatmaps',
}

export enum DemographyLayerName {
  crime = 'crime_total_risk',
  income = 'average_household_income',
  population = 'population_median_age',
}

export enum GeographyLayerName {
  leaseCompsBuildings = 'leaseCompsBuildings',
  leaseCompsInternal = 'leaseCompsInternal',
  leaseCompsExternal = 'leaseCompsExternal',
  parcels = 'parcels',
  neighborhoods = 'neighborhoods',
  zipcodes = 'zipcodes',
  counties = 'counties',
  states = 'states',
  schools = 'saz',
  reisBoundaries = 'reisBoundaries',
  reisBoundariesApartments = 'reisBoundaries.apartment',
  reisBoundariesFlex = 'reisBoundaries.flex',
  reisBoundariesOffice = 'reisBoundaries.office',
  reisBoundariesRetail = 'reisBoundaries.retail',
  reisBoundariesWarehouse = 'reisBoundaries.warehouse',
}

export type LayerName = GeographyLayerName | DemographyLayerName;

export type Layer = {
  label: string;
  name: LayerName;
  checked?: boolean;
  icon?: string;
  layers?: Layer[];
  checkedLayerName?: LayerName;
};

export type LayerGroup = {
  featureFlag?: FeatureFlag;
  groupName: LayerGroupName;
  layers: Layer[];
  radio?: boolean;
  checked?: boolean;
  checkedLayerName?: LayerName;
};

export type MapDeal = {
  taxAssessorId: number;
  cherreParcelId: string;
  latitude: number;
  longitude: number;
  stage: DealStageType;
};

export type MapCluster = {
  latitude: number;
  longitude: number;
  count: number;
};

export type MapParcel = {
  id: string;
  selected?: boolean;
  hovered?: boolean;
};

export type DemographicsGeographyLayerName =
  | GeographyLayerName.counties
  | GeographyLayerName.states
  | GeographyLayerName.zipcodes;

export type DemographicsGeographyByZoomLevel = {
  zoom: { min: number; max: number };
  geographyName: DemographicsGeographyLayerName;
};

export type OnLayerClick<T = any> = (data: {
  e: MapEvent;
  feature: Feature<Geometry, T>;
  layer: any;
  properties: T;
}) => void;
