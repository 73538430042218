import { Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { useIsSuspended } from 'src/utils/suspense-companion/createSuspenseComponent';
import Component from './CommercialSaleComps.old';
import useStyles from './styles';
import * as T from './types';
import { panels } from '../../constants';

const CommercialSaleComps: T.CommercialSaleComps = (props) => {
  const isSuspended = useIsSuspended();
  const user = useSelector((state: any) => state.user.profile.value);
  const classes = useStyles();
  if (isSuspended) {
    return null;
  }
  if (!user.rcaEnabled) {
    return (
      <Typography variant='body2' style={{ marginBottom: 64 }}>
        Want access to RCA Data? Contact us to to start a trial!
      </Typography>
    );
  }
  return (
    <div id={panels[6].id} className={classes.container}>
      <div className={classes.header}>
        <Typography variant='h4' style={{ marginBottom: '25px' }}>
          Commercial Sale Comps
        </Typography>
        <img src='/assets/RCA-logo.svg' height={23} />
      </div>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <Component {...props} />
    </div>
  );
};

CommercialSaleComps.displayName = 'CommercialSaleComps';

export default CommercialSaleComps;
