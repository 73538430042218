import React from 'react';
import { Alert } from '@material-ui/lab';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const TEXT =
  'This feature is currently in development. As we improve our unmasking algorithm, you may see some results that are inaccurate.';

const useStyles = makeStyles({
  alert: {
    background: '#ffeffb',
    color: '#9f4182',
    display: 'flex',
    alignItems: 'center',
  },
  chip: {
    fontWeight: 600,
    fontSize: 10,
    marginRight: 5,
    height: 16,
    borderRadius: 3,
    backgroundColor: '#9f4182',
    color: '#ffffff',
  },
});

const AlphaAlert: React.FC = () => {
  const classes = useStyles();

  return (
    <Alert
      icon={false}
      classes={{
        root: classes.alert,
      }}
    >
      <Chip
        label='ALPHA'
        size='small'
        classes={{
          root: classes.chip,
        }}
      />
      {TEXT}
    </Alert>
  );
};

export default AlphaAlert;
