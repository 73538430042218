import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 400,
    backgroundColor: '#fff',
  },
  title: {
    padding: '15px',
    fontSize: 18,
    fontWeight: 500,
    paddingRight: 50,
  },
  list: {
    overflow: 'auto',
    cursor: 'auto',
  },
}));
