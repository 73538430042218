import React from 'react';

import HotelIcon from '@material-ui/icons/Hotel';
import BathtubIcon from '@material-ui/icons/Bathtub';
import Grid from '@material-ui/core/Grid';

import { TYPE_BOOLEAN, TYPE_NUMBER } from './common';
import LeftPanelItem from './LeftPanelItem';
import RightPanelItem from './RightPanelItem';
import PANELS, { Panels } from './panels';
import { PropertyDetailsType } from './usePropertyDetails';

type PropertyDetailsProps = {
  data: { lot_data: PropertyDetailsType };
};

const PropertyDetails: React.FC<PropertyDetailsProps> = ({ data: lotInfo }) => {
  if (!lotInfo?.lot_data) {
    return null;
  }

  const lotData = lotInfo.lot_data;
  const { bed_count, bath_count, partial_bath_count } = lotData;
  const visiblePanels = PANELS.reduce((acc, panel) => {
    const details = panel.details.filter((detail) => {
      const value = lotData[detail.field];

      if (detail.type === TYPE_BOOLEAN) {
        return Boolean(value);
      }

      if (detail.type === TYPE_NUMBER) {
        if (value === '0') {
          return false;
        } else {
          return Boolean(value);
        }
      }

      return Boolean(value);
    });

    if (details.length) {
      acc.push({
        label: panel.label,
        details,
      });
    }

    return acc;
  }, [] as Panels);

  return (
    <Grid container spacing={3}>
      {bed_count || bath_count ? (
        <Grid item xs={12} sm={4} lg={3}>
          <LeftPanelItem icon={<HotelIcon />} label='Bed' count={bed_count} />
          <LeftPanelItem
            icon={<BathtubIcon />}
            label='Bath'
            count={bath_count}
          />
          {partial_bath_count ? (
            <LeftPanelItem
              icon={<BathtubIcon />}
              label='Partial Bath'
              count={partial_bath_count}
            />
          ) : null}
        </Grid>
      ) : null}
      <Grid item xs={6} sm={4} lg={3}>
        {visiblePanels
          .filter((_panel, i) => i % 2 === 0)
          .map((panel, i) => (
            <RightPanelItem key={i} {...panel} lotData={lotData} />
          ))}
      </Grid>
      <Grid item xs={6} sm={4} lg={3}>
        {visiblePanels
          .filter((_panel, i) => i % 2 !== 0)
          .map((panel, i) => (
            <RightPanelItem key={i} {...panel} lotData={lotData} />
          ))}
      </Grid>
    </Grid>
  );
};

export default PropertyDetails;
