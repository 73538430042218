import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import { getMaskedValue } from 'src/utils/mask';
import MUITooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles({
  root: {
    padding: 5,
    marginBottom: 3,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: grey[200],
      borderRadius: 5,
    },
  },
});

const RCAComp = ({ comp, index, getCherreParcelGemo }) => {
  const classes = useStyles();
  const {
    address,
    main_property_type,
    price,
    prior_sale_date,
    prior_sale_price_amount,
    transaction_type,
    cherre_parcel_id,
    longitude,
    latitude,
  } = comp;

  return (
    <li key={index} className={classes.root}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
        }}
        onClick={() =>
          getCherreParcelGemo(longitude, latitude, cherre_parcel_id)
        }
      >
        <div>
          <Typography variant='h6' style={{ fontSize: 15 }}>
            {address}
          </Typography>
          {prior_sale_date || prior_sale_price_amount ? (
            <Typography variant='body1' style={{ fontSize: 13 }}>
              <MUITooltip placement='bottom' title={'Prior sale'}>
                <span>
                  {getMaskedValue(prior_sale_date, 'date')}{' '}
                  {prior_sale_date ?? prior_sale_price_amount ? (
                    <span> &middot; </span>
                  ) : null}{' '}
                  {getMaskedValue(prior_sale_price_amount, 'dollars')}
                </span>
              </MUITooltip>
            </Typography>
          ) : null}
          <Typography variant='body1' style={{ fontSize: 13 }}>
            <MUITooltip placement='bottom' title={transaction_type}>
              <span>
                {main_property_type} <span> &middot; </span>{' '}
                {getMaskedValue(price, 'dollars')}
              </span>
            </MUITooltip>
          </Typography>
        </div>
      </div>
    </li>
  );
};

export default RCAComp;

//{`${main_property_type} ${getMaskedValue(price, 'dollars')}`}
//transaction_type
