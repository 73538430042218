import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import Search from '@material-ui/icons/Search';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    padding: '15px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${grey[300]}`,
    borderRadius: 9,
    color: grey[700],
  },
}));

const EmptyResults = ({ text }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Search /> {text}
    </div>
  );
};

export default EmptyResults;
