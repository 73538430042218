import { combineReducers } from 'redux';

import { query } from './query/reducers';
import { map } from './map/reducers';
import { mapPinResults } from './mapPinResults/reducers';
import { view } from './view/reducers';
import { table } from './table/reducers';
import { propertyLists } from './propertyLists/reducers';
import { topFactorsMap } from './topFactorsMap/reducers';
import { graphqlRequests } from './graphqlRequests/reducers';
import { owners } from './owners/reducers';
import { reisSubmarkets } from './reisSubmarkets/reducers';
import { zipcodes } from './zipcodes/reducers';
import { RESET_CORE_EXPLORE } from './getActions';

const appReducer = combineReducers({
  propertyLists,
  topFactorsMap,
  graphqlRequests,
  query,
  map,
  mapPinResults,
  view,
  table,
  owners,
  reisSubmarkets,
  zipcodes,
});

const rootReducer = (state, action) => {
  if (action.type === RESET_CORE_EXPLORE) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
