import { merge } from 'src/utils/recoil/utils';
import { selectorFamilyWithDefaultValue } from 'src/utils/recoil/withDefaultValue';
import { nycDetailsWhenAvailable } from './nycDetails';
import { taxAssessorQuery } from './taxAssessor';
import { NycDetailsQuery } from './__generated__/nycDetails';
import {
  Tax_AssessorQuery,
  Tax_AssessorQueryVariables,
} from './__generated__/taxAssessor';

type TaxAssessor = Tax_AssessorQuery['tax_assessor_v2'][0];
type NYCDetails = NycDetailsQuery['nyc_lot'][0];

interface LotDataDerivates {
  centroid_geom: any;
}

export type LotData = Partial<TaxAssessor> &
  Partial<NYCDetails> &
  Partial<LotDataDerivates>;

const taxAssessorToLotDataDerivates = (data: Tax_AssessorQuery) => {
  const taxAssessor = data.tax_assessor_v2[0] || {};
  const centroid_geom =
    taxAssessor.parcel_boundary_v2__tax_assessor_id?.parcel_centroid_geometry;
  return { centroid_geom };
};

export const lotData = selectorFamilyWithDefaultValue<
  LotData,
  Tax_AssessorQueryVariables
>({
  defaultValue: {},
  key: 'LOT-PAGE/LOT-DATA',
  get:
    (params) =>
    ({ get }) => {
      const taxAssessorResult = get(taxAssessorQuery(params));
      const taxAssessor = taxAssessorResult.tax_assessor_v2[0] || {};
      const nycResults = get(nycDetailsWhenAvailable(taxAssessor.cherre_bbl));
      const nyc = nycResults?.nyc_lot[0] || {};
      const lotDataDerivates = taxAssessorToLotDataDerivates(taxAssessorResult);
      return merge<LotData>(taxAssessor, nyc, lotDataDerivates);
    },
});
