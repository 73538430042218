import React from 'react';
import PagedTable from 'src/components/PagedTable';
import { getMaskedValue } from 'src/utils/mask';
import LotPageLink from 'src/products/core-prospect/search-pages/components/ResultsTableView/LotPageLink';
import { goToUsaLotPage } from 'src/products/core-prospect/search-pages/components/utils';
import { sortBy } from 'lodash';
import EnhancedTable from 'src/components/TablePage/components/Table';
import EmptyTable from 'src/components/BaseTable/EmptyTable';

const printColumns = [
  {
    column_label: 'Date',
    field_name: 'status_date',
    mask: 'date',
  },
  {
    column_label: 'Price',
    field_name: 'price',
    mask: 'dollars',
  },
  {
    column_label: 'Address',
    field_name: 'address',
  },
  {
    column_label: 'Seller',
    field_name: 'seller_name_1',
  },
  {
    column_label: 'Buyer',
    field_name: 'buyer_name_1',
  },
  {
    column_label: 'Transaction Type',
    field_name: 'transaction_type',
  },
];

const columns = [
  {
    label: 'Date',
    dataKey: 'status_date',
    width: 120,
    cellRenderer: ({ cellData }) => {
      return getMaskedValue(cellData, 'date');
    },
  },
  {
    label: 'Price',
    dataKey: 'price',
    width: 130,
    cellRenderer: ({ cellData }) => {
      return getMaskedValue(cellData, 'dollars');
    },
  },
  {
    label: 'Address',
    dataKey: 'address',
    exportCellRenderer: ({ cellData }) => cellData,
    cellRenderer: ({ cellData, rowData }) => {
      if (rowData.cherre_parcel_id && rowData.tax_assessor_id) {
        return (
          <LotPageLink
            value={cellData}
            onClick={async (e) => {
              e.preventDefault();

              goToUsaLotPage(rowData.cherre_parcel_id, rowData.tax_assessor_id);
            }}
          />
        );
      }

      return <span>{cellData}</span>;
    },
    width: 250,
  },
  {
    label: 'Seller',
    dataKey: 'seller_name_1',
    width: 120,
  },
  {
    label: 'Buyer',
    dataKey: 'buyer_name_1',
    width: 120,
  },
  {
    label: 'Transaction Type',
    dataKey: 'transaction_type',
    width: 140,
  },
];

const RCACompsTable = (props) => {
  const { rcaCompsList, height } = props;
  const rcaCompsListSortedByDate = sortBy(rcaCompsList, [
    'status_date',
  ]).reverse();

  return (
    <>
      <div className='print-only rca-table'>
        <EnhancedTable
          page={0}
          rowsPerPage={rcaCompsListSortedByDate.length}
          columns={printColumns}
          data={rcaCompsListSortedByDate}
        />
        {rcaCompsListSortedByDate.length === 0 ? (
          <div style={{ height: 300 }}>
            <EmptyTable />
          </div>
        ) : null}
      </div>
      <div
        className='print-hide'
        style={{
          width: '100%',
          height: height || 500,
          backgroundColor: '#fff',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <PagedTable
          rowKey={['status_date', 'price', 'address']}
          columns={columns}
          items={rcaCompsListSortedByDate}
          showPagination={false}
          selection={false}
        />
      </div>
    </>
  );
};

export default RCACompsTable;
