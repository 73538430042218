import React from 'react';
import CompanyDetailsTopMarketsRow from './CompanyDetailsTopMarketsRow';

const CompanyDetailsTopMarkets = ({ cities = [], maxValue }) => {
  return cities.map((city, index) => (
    <CompanyDetailsTopMarketsRow
      key={index}
      city={city}
      index={index}
      maxValue={maxValue}
    />
  ));
};

export default CompanyDetailsTopMarkets;
