import React from 'react';
import * as C from './components';
import * as T from './types';
import useStyles from './styles';
import { Typography } from '@material-ui/core';
import { panels } from '../../constants';

const Summary: T.Summary = ({
  latitude,
  longitude,
  propertyType,
  propertySubtype,
  lotSizeSqft,
  lastSale,
  owner,
}) => {
  const classes = useStyles();
  return (
    <div id={panels[0].id} className={classes.container}>
      <C.StreetView latitude={latitude} longitude={longitude} />
      <div>
        <Typography variant='h4' style={{ marginBottom: '25px' }}>
          Summary Information
        </Typography>
        <C.KeyValue label='Property type' value={propertyType} />
        <C.KeyValue label='Property subtype' value={propertySubtype} />
        <C.KeyValue label='Lot area' value={lotSizeSqft} />
        <C.KeyValue label='Last sale' value={lastSale} />
        <C.KeyValue label='Owners' value={owner} />
      </div>
    </div>
  );
};

Summary.displayName = 'Summary';

export default Summary;
