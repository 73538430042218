import moment from 'moment';
import {
  PropertyUseToLotInformationFormatter,
  TaxAssessorToLotInformationFormatter,
} from '../types';
import { cherrePropertyUseHierarchical } from 'src/const/lookups';

export const taxAssessorToLotInformation: TaxAssessorToLotInformationFormatter =
  (data) => {
    const tax_assessor = data.tax_assessor_v2[0];

    const apn =
      tax_assessor.cherre_bbl?.toString() ||
      tax_assessor.assessor_parcel_number_raw ||
      '';

    const lookup_type = cherrePropertyUseHierarchical.find((lookup) => {
      return lookup.lookup_code === tax_assessor.property_use_code_mapped;
    });
    const propertyType = lookup_type ? lookup_type.lookup_value : undefined;

    const zone = tax_assessor.zone_code || undefined;

    const lastCountyAssessorUpdate = tax_assessor.last_update_date
      ? moment(tax_assessor.last_update_date).fromNow()
      : undefined;

    return {
      apn,
      propertyType,
      zone,
      lastCountyAssessorUpdate,
    };
  };

export const propertyUseCodeMappedToLotInformation: PropertyUseToLotInformationFormatter =
  (data) => {
    return { propertyType: data || undefined };
  };

export const propertyUseStdCodeToLotInformation: PropertyUseToLotInformationFormatter =
  (data) => {
    return { propertySubtype: data || undefined };
  };
