import { Tax_AssessorQuery } from '../../../recoil/__generated__/taxAssessor';
import { number } from '../../../utils';

export const taxAssessorToBuildingInfo = (data: Tax_AssessorQuery) => {
  const tax_assessor = data.tax_assessor_v2[0];

  const yearBuilt = tax_assessor.year_built?.toString() || '';
  const numberOfUnits = number(tax_assessor.units_count) || '';
  const buildingArea = number(tax_assessor.gross_sq_ft, 'sqft') || '';

  return {
    yearBuilt,
    numberOfUnits,
    buildingArea,
  };
};
