export type Lease = {
  address: string;
  avg_rent_bumps_in_percentage: string;
  building_deal_name: string;
  building_id: string;
  building_size_sf: string;
  building_type: string;
  cherre_geo_point: any;
  cherre_hash: number;
  cherre_ingest_datetime: string;
  cherre_eqt_leases_pk: number;
  city: string;
  class: string;
  clear_height_ft: string;
  commencement_date: string;
  comments: string;
  comp_id: string;
  comp_source: string;
  comp_type: string;
  country: string;
  date_added: string;
  deal_size_sf: string;
  deal_type: string;
  escalation: string;
  escalation_type: string;
  free_rent_months: string;
  full_address: string;
  in_place_base_rent_per_square_unit: number;
  in_place_gross_rent_per_square_unit: number;
  in_place_other_rent_per_square_unit: number;
  in_place_tenant: string;
  landlord: string;
  lat: number;
  lease_commencement_date: string;
  lease_expiration_date: string;
  lease_id: string;
  loading_configuration: string;
  long: number;
  market: string;
  mla_avg_rent: string;
  mri_id: string;
  opex: string;
  postal_code: string;
  powerbi_charge_schedule: string;
  re_subsector: string;
  recoveries: string;
  sign_date: string;
  space_id: string;
  squareunitsinspace: number;
  state_region: string;
  status: string;
  stories: string;
  submarket: string;
  tax_abatement: string;
  tenant: string;
  tenant_entity: string;
  term_months: string;
  term_years: number;
  ti: string;
  unit_type: string;
  year_1_rent: string;
  year_built: number;
};

export enum ResourceType {
  PropertyList = '00000001',
  SavedSearch = '00000010',
}

export enum TargetType {
  User = '00000001',
  Group = '00000010',
  Organization = '00000011',
}

export type ShareWithAutocompleteResponse = {
  targetType: TargetType;
  title: string;
  subtitle: string;
  targetId: number;
}[];

export type SharedResourcesResponse = {
  targetType: TargetType;
  title: string;
  subtitle: string;
  targetId: number;
}[];

export type TeamOrganizationMembersResponse = {
  id: number;
  firstName: string | null;
  lastName: string | null;
  email: string;
}[];
