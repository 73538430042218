import * as TYPES from './types';
import * as QUERY_TYPES from '../query/types';
import { findIndex, uniqBy } from 'lodash';
import constants from 'src/const';
import { FetchStatus } from '../query/reducers';
import { Reducer } from 'redux';

type TableState = {
  tableResults: any[];
  tableResultsFetchStatus: FetchStatus;
  rowsPerPage: number;
  offset: number;
  after?: string | null;
  before?: string | null;
  exec_query_timestamp?: number;
  exec_count_query_timestamp?: number;
  orderBy: {
    field: string;
    direction: string;
  };
  selectedRows: any[];
  isAllRowsSelected: boolean;
};

const initialState: TableState = {
  tableResults: [],
  tableResultsFetchStatus: {},
  rowsPerPage: 25,
  offset: 0,
  after: undefined,
  before: undefined,
  exec_query_timestamp: undefined,
  exec_count_query_timestamp: undefined,
  orderBy: {
    field: 'last_update_date',
    direction: constants.ORDER_BY_DIRECTION.DESC,
  },

  selectedRows: [],
  isAllRowsSelected: false,
};

const getIsAllRowsSelected = (tableResults, selectedRows, key) => {
  return tableResults.every((result) => {
    return selectedRows.some((row) => row[key] === result[key]);
  });
};

type TableReducer = Reducer<TableState>;

export const table: TableReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.CLEAR_SELECTED_ROWS: {
      return {
        ...state,
        selectedRows: [],
        isAllRowsSelected: false,
      };
    }

    case TYPES.UNCHECK_SELECT_ALL_ROWS: {
      return {
        ...state,
        isAllRowsSelected: false,
      };
    }

    case TYPES.SELECT_ALL_ROWS: {
      return {
        ...state,
        isAllRowsSelected: action.selected,
        selectedRows: action.selected
          ? uniqBy(
              [...state.selectedRows, ...state.tableResults],
              (row) => row[action.rowKey]
            )
          : state.selectedRows.filter((row) => {
              return !state.tableResults.some(
                (result) => result[action.rowKey] === row[action.rowKey]
              );
            }),
      };
    }

    case TYPES.SELECT_ROWS: {
      const { selected, rowData } = action.row;

      const rowKey = action.rowKey;

      if (selected) {
        const selectedRows = [...state.selectedRows, rowData];
        return {
          ...state,
          selectedRows,
          isAllRowsSelected: getIsAllRowsSelected(
            state.tableResults,
            selectedRows,
            rowKey
          ),
        };
      } else {
        const index = findIndex(
          state.selectedRows,
          (row) => row[rowKey] === rowData[rowKey]
        );

        const selectedRows = [
          ...state.selectedRows.slice(0, index),
          ...state.selectedRows.slice(index + 1),
        ];

        return {
          ...state,
          selectedRows,
          isAllRowsSelected: getIsAllRowsSelected(
            state.tableResults,
            selectedRows,
            rowKey
          ),
        };
      }
    }

    case QUERY_TYPES.RESET_ALL_FILTERS: {
      return initialState;
    }

    case QUERY_TYPES.GET_QUERY_DATA_SUCCESS: {
      return initialState;
    }

    case TYPES.CHANGE_OFFSET: {
      const result = {
        ...state,
        offset: action.offset,
      };

      if (result.offset > state.offset) {
        result.after = state.tableResults[state.tableResults.length - 1];
        result.before = null;
      } else if (result.offset < state.offset) {
        result.after = null;
        result.before = state.tableResults[0];
      }

      return result;
    }

    case TYPES.CHANGE_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.rowsPerPage,
        offset: 0,
        after: null,
        before: null,
      };

    case TYPES.CHANGE_ORDER_BY: {
      const { orderBy } = action;

      return {
        ...state,
        orderBy,
        offset: 0,
        after: null,
        before: null,
      };
    }
    case TYPES.TABLE_RESULTS_REQUEST: {
      const { timestamp, reset } = action;

      return {
        ...state,
        exec_query_timestamp: timestamp,
        tableResultsFetchStatus: { value: 'LOADING' },
        after: reset ? null : state.after,
        before: reset ? null : state.before,
        offset: reset ? 0 : state.offset,
      };
    }

    case TYPES.TABLE_RESULTS_SUCCESS: {
      const { timestamp, idFieldName } = action;

      if (timestamp !== state.exec_query_timestamp) {
        return state;
      }

      return {
        ...state,
        tableResults: action.data.result,
        tableResultsFetchStatus: { value: 'LOADED' },
        isAllRowsSelected: action.data.result.every((result) => {
          return state.selectedRows.some(
            (row) => row[idFieldName] === result[idFieldName]
          );
        }),
      };
    }

    case TYPES.TABLE_RESULTS_FAILED: {
      const { timestamp } = action;

      if (timestamp !== state.exec_query_timestamp) {
        return state;
      }

      return {
        ...state,
        tableResultsFetchStatus: { value: 'FAILED' },
      };
    }

    default:
      return state;
  }
};
