import React, { Suspense } from 'react';
import { Box, Button, Tooltip, useTheme } from '@material-ui/core';
import { default as LaunchIcon } from '@material-ui/icons/LaunchOutlined';
import { default as EditIcon } from '@material-ui/icons/EditOutlined';
import Bookmark from '@material-ui/icons/Bookmark';
import Close from '@material-ui/icons/Close';
import { DealStageChip } from 'src/products/core-prospect/pages/lot-page/components';
import { NoDealOverlay } from './NoDealOverlay';
import { useStyles } from './style';
import { CloseButton } from './CloseButton';
import ButtonProgress from 'src/components/ButtonProgress';
import PropertyLists from 'src/products/core-prospect/Layout/LotpageNavigation/PropertyList';
import { useDealPortfolioByTaxId } from 'src/services/Dealcloud/hooks';
import { PopupPropertyType } from '../SearchMap/LotPopup/hook/usePopupData';
import { useParams } from 'react-router';
import { PortfolioIcon } from 'src/components/ui/icons/PortfolioIcon';
import { getString } from 'src/utils/getString';
import { useEditDeal } from 'src/services/Dealcloud/editDeal';
import { useLocalDeal } from 'src/services/Dealcloud/localDeal';

export type DealOverlayProps = {
  taxAssessorId: number;
  cherreParcelId: string;
  onClose?: () => void;
  portfolio?: boolean;
  property?: PopupPropertyType;
  showOnClose?: boolean;
  where?: string;
};

const DealOverlay: React.VFC<DealOverlayProps> = ({
  taxAssessorId,
  cherreParcelId,
  onClose,
  where,
  portfolio,
  property,
  showOnClose,
}) => {
  const { id } = useParams<{ id: string }>();

  const deal = useDealPortfolioByTaxId(taxAssessorId);

  const dealId = id || deal?.dealId;

  const classes = useStyles();
  const theme = useTheme();
  const [editDeal, loading] = useEditDeal();

  const {
    addAsset,
    removeAsset,
    deal: portfolioDeal,
  } = useLocalDeal(dealId !== 'new' && portfolio ? Number(dealId) : 'new');

  const isPartOfPortfolio = !!portfolioDeal?.assets.find(
    (a) => a.taxAssessorId === taxAssessorId
  );

  if (!deal) {
    return (
      <NoDealOverlay
        taxAssessorId={taxAssessorId}
        cherreParcelId={cherreParcelId}
        onClose={onClose}
        portfolio={portfolio}
        property={property}
        showOnClose={showOnClose}
      />
    );
  }

  return (
    <Box className={`${classes.root} js-deal-overlay`}>
      <DealStageChip name={deal.stage} />
      <Box className={classes.action}>
        <PropertyLists
          showChips={false}
          property_id={taxAssessorId}
          property_code_type='tax_assessor_id'
          noStyle
          buttonComponent={
            <Tooltip title='Add to Property List'>
              <Button
                variant='contained'
                size='small'
                className={classes.button}
              >
                <Bookmark className={classes.icon} color='primary' />
              </Button>
            </Tooltip>
          }
          where={where}
        />
        {portfolio && isPartOfPortfolio && (
          <Tooltip title='Remove from portfolio'>
            <Button
              variant='contained'
              size='small'
              className={classes.button}
              onClick={() => removeAsset(taxAssessorId)}
            >
              <Close className={classes.icon} color='primary' />
            </Button>
          </Tooltip>
        )}
        {portfolio && property && !isPartOfPortfolio && (
          <Tooltip title='Add to portfolio'>
            <Button
              variant='contained'
              size='small'
              className={classes.button}
              onClick={() =>
                addAsset({
                  address: property.address,
                  buildingArea: getString(property.building_sq_ft),
                  city: property.city,
                  country: 'USA',
                  isMultiFam: '',
                  latitude: property.latitude,
                  longitude: property.longitude,
                  state: property.state,
                  taxAssessorId: property.tax_assessor_id,
                  unitType: '',
                  yearBuilt: getString(property.year_built),
                  zipCode: property.zip,
                  parcelId: property.cherre_parcel_id || '',
                  numberOfAppartments: getString(property.units_count),
                })
              }
            >
              <PortfolioIcon className={classes.icon} color='primary' />
            </Button>
          </Tooltip>
        )}
        {!portfolio && (
          <Tooltip title='Edit Deal'>
            <ButtonProgress
              variant='contained'
              size='small'
              className={`${classes.button} js-edit-deal-icon`}
              onClick={() => editDeal(Number(dealId))}
              loading={loading}
              style={{ backgroundColor: 'white' }}
              spinnerStyle={{
                color: theme.palette.primary.main,
                marginTop: -8,
                marginLeft: -8,
              }}
              spinnerSize={17}
            >
              <EditIcon className={classes.icon} color='primary' />
            </ButtonProgress>
          </Tooltip>
        )}

        <Tooltip title='View in DealCloud'>
          <Button
            component={'a'}
            variant='contained'
            size='small'
            href={`https://eqtexeter.dealcloud.com/portal/pages/871/reports/888/entries/${deal.dealId}`}
            className={`${classes.button} js-view-in-deal-cloud`}
            target='_blank'
          >
            <LaunchIcon className={classes.icon} color='primary' />
          </Button>
        </Tooltip>

        {onClose && showOnClose && <CloseButton onClose={onClose} />}
      </Box>
    </Box>
  );
};

const DealOverlaySuspense: React.VFC<DealOverlayProps> = (props) => {
  return (
    <Suspense fallback={null}>
      <DealOverlay {...props} />
    </Suspense>
  );
};

export { DealOverlay, DealOverlaySuspense };
