export const namespace = 'core-prospect-view';

const ns = (name) => `${namespace}/${name}`;

export const TABLE_RESULTS_REQUEST = ns('TABLE_RESULTS_REQUEST');
export const TABLE_RESULTS_SUCCESS = ns('TABLE_RESULTS_SUCCESS');
export const TABLE_RESULTS_FAILED = ns('TABLE_RESULTS_FAILED');

export const CHANGE_OFFSET = ns('CHANGE_OFFSET');
export const CHANGE_ROWS_PER_PAGE = ns('CHANGE_ROWS_PER_PAGE');
export const CHANGE_ORDER_BY = ns('CHANGE_ORDER_BY');

export const CSV_WAS_EXPORTED = ns('CSV_WAS_EXPORTED');
export const CSV_EXPORT_FAILED = ns('CSV_EXPORT_FAILED');

export const SELECT_ROWS = ns('SELECT_ROWS');
export const SELECT_ALL_ROWS = ns('SELECT_ALL_ROWS');
export const UNCHECK_SELECT_ALL_ROWS = ns('UNCHECK_SELECT_ALL_ROWS');

export const CLEAR_SELECTED_ROWS = ns('CLEAR_SELECTED_ROWS');
