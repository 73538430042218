import { makeStyles, useTheme } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  addPropertyListEntries,
  createPropertyList,
  getPropertyLists,
  getPropertyListEntriesForTable,
} from 'src/products/core-prospect/redux/propertyLists/actions';

import { idFieldName } from 'src/products/core-prospect/redux/table/actions';

import { RESULTS_TYPES } from 'src/products/core-prospect/constants';
import ExportButton from '../ResultsTableView/CoreProspectResultsTableNav/ExportButton';
import PropertyList from '../ResultsTableView/CoreProspectResultsTableNav/PropertyList';
import ViewerTabs from './ViewerTabs';
import Separator from './Separator';
import MapToggler from './MapToggler';
import useIsCompact from 'src/hooks/useIsCompact';
import { useOrganizationPropsValue } from 'src/hooks';
import ButtonProgress from 'src/components/ButtonProgress';
import { PortfolioIcon } from 'src/components/ui/icons/PortfolioIcon';
import { useCreateDeal } from 'src/services/Dealcloud/createDeal';

const useStyles = makeStyles(() => ({
  root: {
    padding: '0 25px',
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.2)',
    zIndex: 2,
  },
  portfolioButton: {
    marginRight: '10px',
  },
}));

const CoreExploreToolbar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();

  const { resultsType, viewerName, selectedRows } = useSelector((state) => {
    return {
      resultsType: state.coreProspect.view.resultsType,
      viewerName: state.coreProspect.view.viewerName,
      selectedRows: state.coreProspect.table.selectedRows,
    };
  });

  const isDealsIntegrationAvailable =
    useOrganizationPropsValue('deals_enabled');

  const handleSelect = async (listId) => {
    await dispatch(
      addPropertyListEntries({
        list_id: listId,
        property_code_type: idFieldName,
        property_ids: selectedRows.map((row) => row[idFieldName]),
      })
    );
    dispatch(getPropertyLists());
    dispatch(getPropertyListEntriesForTable());
  };

  const handleCreate = async (label) => {
    const list = await dispatch(createPropertyList(label));
    handleSelect(list.id);
  };

  const getPropertyListDisabledText = () => {
    if (selectedRows.length === 0) {
      return 'Add to property lists';
    }

    if (selectedRows.length > 100) {
      return 'Please add 100 properties at a time to property lists.';
    }
  };

  const isMapTogglerAvailable = React.useMemo(() => {
    return resultsType === RESULTS_TYPES.TAX_RECORDS;
  }, [resultsType]);

  const isListAvailable = React.useMemo(() => {
    return [RESULTS_TYPES.TAX_RECORDS].includes(resultsType);
  }, [resultsType, viewerName]);

  const isExportAvailable = React.useMemo(() => {
    return [RESULTS_TYPES.TAX_RECORDS].includes(resultsType);
  }, [resultsType, viewerName]);

  const isListDisabled = React.useMemo(() => {
    return selectedRows.length === 0 || selectedRows.length > 100;
  }, [selectedRows.length]);

  const isSeparatorVisible = isMapTogglerAvailable;

  const isCompact = useIsCompact();

  const [createDeal, isLoading] = useCreateDeal();

  if (isCompact) {
    return null;
  }

  return (
    <div className={classes.root}>
      <ViewerTabs />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {isDealsIntegrationAvailable && (
          <ButtonProgress
            className={`${classes.portfolioButton} js-create-portfolio-button`}
            variant='outlined'
            color='primary'
            onClick={() => createDeal()}
            loading={isLoading}
            spinnerStyle={{ color: theme.palette.primary.main }}
            startIcon={
              <PortfolioIcon color='primary' style={{ fontSize: '12px' }} />
            }
          >
            Create Portfolio
          </ButtonProgress>
        )}
        {isMapTogglerAvailable && <MapToggler />}
        {isSeparatorVisible && <Separator />}
        {isListAvailable && (
          <>
            <PropertyList
              disableText={getPropertyListDisabledText()}
              title='Add to property lists'
              disabled={isListDisabled}
              onSelect={handleSelect}
              onCreate={handleCreate}
            />
            &nbsp; &nbsp;
          </>
        )}
        {isExportAvailable && <ExportButton />}
      </div>
    </div>
  );
};

export default CoreExploreToolbar;
