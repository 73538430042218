import { Typography } from '@material-ui/core';
import React from 'react';
import * as T from './types';
import useStyles from './styles';

const KeyValue: React.FC<T.KeyValueProps> = ({ label, value }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography variant='body2'>{label}:</Typography>
      <Typography variant='body2' className='suspend'>
        {value}
      </Typography>
    </div>
  );
};

KeyValue.displayName = 'KeyValue';

export default KeyValue;
