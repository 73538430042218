import React, { useState } from 'react';
import Chip from '@material-ui/core/Chip';
import Popover from '@material-ui/core/Popover';
import RCAMapFilterPopover from './RCAMapFilterPopover';
import { getMaskedValue } from 'src/utils/mask';

import { MAIN_PROPERTY_TYPE } from '../../constants';

const getTitle = (chipFilterData) => {
  const {
    titleTemplate,
    value = { min: '', max: '' },
    mask,
    onlyMax,
    onlyMin,
  } = chipFilterData;
  const { min, max } = value;
  if (min && max) {
    return titleTemplate
      .replace('MIN_VAL', getMaskedValue(min, mask ? mask : undefined))
      .replace('MAX_VAL', getMaskedValue(max, mask ? mask : undefined));
  }
  if (!min && max) {
    return titleTemplate
      .replace('MIN_VAL to', onlyMax)
      .replace('MAX_VAL', getMaskedValue(max, mask ? mask : undefined));
  }
  if (min && !max) {
    return titleTemplate
      .replace('MIN_VAL to', onlyMin)
      .replace('MAX_VAL', getMaskedValue(min, mask ? mask : undefined));
  }
};

const getChipTitle = (chipFilterData) => {
  const { filterField, value, title } = chipFilterData;
  let displayTitle;
  if (filterField === MAIN_PROPERTY_TYPE) {
    displayTitle = value.length ? value.join(', ') : title;
  } else {
    displayTitle = getTitle(chipFilterData);
  }

  return displayTitle;
};

const RCAChipFilter = (props) => {
  const { setUpdateSearch, updateSearch } = props;
  const { chipFilterData } = props;
  const { value } = chipFilterData;
  const chipTitle =
    value.min !== '' || value.max !== ''
      ? getChipTitle(chipFilterData)
      : chipFilterData.title;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setUpdateSearch(updateSearch + 1);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <React.Fragment>
      <Chip
        id={`rca-filter-${chipTitle}`}
        label={chipTitle}
        style={{ margin: 5 }}
        href='#chip'
        onClick={handleClick}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <RCAMapFilterPopover
          chipFilterData={chipFilterData}
          {...props}
          handleClose={handleClose}
        />
      </Popover>
    </React.Fragment>
  );
};

export default RCAChipFilter;
