import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    marginRight: 8,
    '&:last-child': {
      marginRight: 0,
    },
    display: 'flex',
    flexWrap: 'wrap',
  },
});

const TimelineTextItem = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
};

export default TimelineTextItem;
