import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useTheme, withStyles } from '@material-ui/core/styles';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

function getStyles(selected, theme) {
  return {
    fontSize: 13,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontWeight: selected
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

const styles = {
  inputRoot: {
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
};
const MultipleSelection = ({
  data: { options },
  filter,
  filterValue = [],
  setFilterValue,
  multiple = true,
  autocompleteClasses,
}) => {
  const sortedOptions = options.sort((a, b) => (a.name > b.name ? 1 : -1));
  const defaultProps = {
    options: sortedOptions || [],
    getOptionLabel: (option) => option.name,
  };

  const theme = useTheme();

  const selectedOptions = sortedOptions.filter((option) =>
    filterValue.includes(option.value)
  );

  return (
    <Autocomplete
      ChipProps={{
        size: 'small',
      }}
      size='small'
      classes={autocompleteClasses}
      value={multiple ? selectedOptions : selectedOptions[0]}
      onChange={(event, newValues) => {
        if (multiple) {
          const values = newValues.map((newValue) => newValue.value);
          setFilterValue(values);
        } else {
          setFilterValue(newValues);
        }
      }}
      disableCloseOnSelect={multiple}
      multiple={multiple}
      {...defaultProps}
      autoComplete
      includeInputInList
      renderInput={(params) => (
        <TextField
          {...params}
          label={filter.label}
          size='small'
          variant='outlined'
          style={{ background: '#fff' }}
        />
      )}
      renderOption={(option, { selected }) => (
        <div
          key={option.name}
          value={option.value}
          style={getStyles(selected, theme)}
        >
          {multiple && (
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              checked={selected}
            />
          )}
          {option.name}
        </div>
      )}
    />
  );
};

export default withStyles(styles)(MultipleSelection);
