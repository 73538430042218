const STARWOOD_RCA_SALES = 'coreexplore_starwood_rca_sales';
const BROOKFIELD_RCA_SALES = 'coreexplore_brookfield_rca_sales';
const DEFAULT_RCA_SALES = 'rca_sales';

const entities = [
  {
    organizations: ['Starwood'],
    entityName: STARWOOD_RCA_SALES,
  },
  {
    organizations: ['Brookfield - BPG', 'Brookfield - BP'],
    entityName: BROOKFIELD_RCA_SALES,
  },
];

export const getRcaSalesEntityName = (user) => {
  const entity = entities.find((entity) =>
    entity.organizations.includes(user.organizationName)
  );

  return entity?.entityName || DEFAULT_RCA_SALES;
};
