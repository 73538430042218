import React from 'react';
import { MultiColumnRow } from '../../../../components';
import KeyValue from '../KeyValue';
import * as T from './types';

const BuildingDetails: T.BuildingDetails = ({
  floor1sqft,
  floor2sqft,
  upperFloorSqft,
  sewerUsage,
  waterSource,
  foundation,
  construction,
  interiorStructure,
  fireResistanceClass,
  flooringMaterial,
  structureStyle,
  exterior,
  roofMaterial,
  roofConstruction,
  buildingsCount,
  topography,
}) => {
  return (
    <MultiColumnRow columns={3}>
      <KeyValue label='Floor 1 Square Footage' value={floor1sqft} />
      <KeyValue label='Floor 2 Square Footage' value={floor2sqft} />
      <KeyValue label='Upper Floor Square Footage' value={upperFloorSqft} />
      <KeyValue label='Sewer Usage' value={sewerUsage} />
      <KeyValue label='Water Source' value={waterSource} />
      <KeyValue label='Foundation' value={foundation} />
      <KeyValue label='Construction' value={construction} />
      <KeyValue label='Interior Structure' value={interiorStructure} />
      <KeyValue label='Fire Resistance Class' value={fireResistanceClass} />
      <KeyValue label='Flooring Material' value={flooringMaterial} />
      <KeyValue label='Structure Style' value={structureStyle} />
      <KeyValue label='Exterior' value={exterior} />
      <KeyValue label='Roof Material' value={roofMaterial} />
      <KeyValue label='Roof Construction' value={roofConstruction} />
      <KeyValue label='Buildings Count' value={buildingsCount} />
      <KeyValue label='Topography' value={topography} />
    </MultiColumnRow>
  );
};

BuildingDetails.displayName = 'BuildingDetails';

export default BuildingDetails;
