import {
  DemographicsGeographyByZoomLevel,
  GeographyLayerName,
} from '../../types';

export const GEOGRAPHY_BY_ZOOM_LEVEL: DemographicsGeographyByZoomLevel[] = [
  {
    zoom: { min: 1, max: 5 },
    geographyName: GeographyLayerName.states,
  },
  {
    zoom: { min: 5, max: 8 },
    geographyName: GeographyLayerName.counties,
  },
  {
    zoom: { min: 8, max: Infinity },
    geographyName: GeographyLayerName.zipcodes,
  },
];
