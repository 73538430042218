import { get } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MapEvent } from 'react-map-gl';
import { RefProps } from 'src/components/Map/index.new';
import { Source } from 'src/components/Map/types';
import useLocation from 'src/hooks/useLocation';
import { LAYERS } from 'src/products/core-prospect/components/ReactMap/const';
import getParcelsPointsBounds from 'src/products/core-prospect/components/ReactMap/getParcelsPointsBounds';
import getCustomFeatureLayers from 'src/products/core-prospect/components/ReactMap/layers/get-custom-feature-layers';
import {
  geSelectedDealsLayers,
  getDealsLayers,
  getHoveredDealsLayers,
} from 'src/products/core-prospect/components/ReactMap/layers/get-deals-layer';
import getUsaParcelsLayers from 'src/products/core-prospect/components/ReactMap/layers/get-usa-parcels-layers';
import getCustomFeatureSource from 'src/products/core-prospect/components/ReactMap/sources/get-custom-feature-source';
import getUSAParcelsSource from 'src/products/core-prospect/components/ReactMap/sources/get-usa-parcels-source';
import { getDealsSource } from 'src/products/core-prospect/components/ReactMap/sources/getDealsSource';
import { handleHover } from 'src/products/core-prospect/components/ReactMap/utils';
import { useUpdateUrlSearchParams } from 'src/products/core-prospect/hooks/useUpdateUrlSearchParams';
import { DealStageType } from 'src/services/Dealcloud/types';
import { useMapViewport } from './useMapViewport';
import { useTaxAssessors } from './useTaxAssessors';

export const useMap = ({
  assets,
  stage,
}: {
  stage: DealStageType;
  assets: Array<{
    taxAssessorId: number;
  }>;
}) => {
  const location = useLocation();

  const activeParcelID = get(location, 'query.cherre_parcel_id', null);
  const activeParcelLatitude = get(location, 'query.latitude', null);
  const activeParcelLongitude = get(location, 'query.longitude', null);

  const [taxAssessorData] = useTaxAssessors(assets);

  const selectedParcels = taxAssessorData?.tax_assessor_v2 || [];

  const updateUrlSearchParams = useUpdateUrlSearchParams();

  const [hoveredDeals, setHoveredDeals] = useState<string[]>([]);

  const { setHeight, setWidth, rebound, geometry } = useMapViewport();

  const mapRef = useRef<RefProps>(null);

  useEffect(() => {
    rebound(getParcelsPointsBounds(selectedParcels));
  }, [rebound, Boolean(taxAssessorData)]);

  const measuredRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);

  const onHover = useCallback(
    (e: MapEvent): void => {
      handleHover(e, mapRef.current?.map);
      const [feature] = e.features || [];
      if (
        [LAYERS.DEALS_ON_MAP, LAYERS.DEALS_ON_MAP_HOVERED].includes(
          feature?.layer?.id
        )
      ) {
        setHoveredDeals([feature?.properties?.cherreParcelId]);
      } else {
        setHoveredDeals([]);
      }
    },
    [setHoveredDeals]
  );

  const customFeatureSource = useMemo(
    () =>
      geometry
        ? {
            source: getCustomFeatureSource(geometry),
            layers: getCustomFeatureLayers(geometry),
          }
        : undefined,
    [geometry]
  );

  const dealsSource = useMemo(
    () => ({
      source: getDealsSource(
        selectedParcels.map((parcel) => ({
          cherreParcelId: parcel.cherre_parcel_id,
          latitude: parcel.latitude,
          longitude: parcel.longitude,
          stage: stage,
          taxAssessorId: parcel.tax_assessor_id,
        }))
      ),
      layers: [
        ...getDealsLayers(hoveredDeals, [activeParcelID]),
        ...getHoveredDealsLayers(
          hoveredDeals,
          ({ e: { lngLat }, properties }) => {
            updateUrlSearchParams({
              cherre_parcel_id: properties.cherreParcelId,
              latitude: properties.latitude || lngLat[1],
              longitude: properties.longitude || lngLat[0],
              stickToThePin: true,
            });
          }
        ),
        ...geSelectedDealsLayers([activeParcelID]),
      ],
    }),
    [stage, selectedParcels, activeParcelID, hoveredDeals]
  );

  const USAParcelsSource = useMemo(
    () => ({
      source: getUSAParcelsSource(),
      layers: getUsaParcelsLayers({
        selectedParcels: selectedParcels.map((p) => p.cherre_parcel_id),
        activeParcels: [activeParcelID],
        visibility: 'visible',
        onClick: ({ e: { lngLat }, properties }) => {
          updateUrlSearchParams({
            cherre_parcel_id: properties.cherre_parcel_id,
            latitude: properties.latitude || lngLat[1],
            longitude: properties.longitude || lngLat[0],
            stickToThePin: undefined,
          });
        },
      }),
    }),
    [selectedParcels, activeParcelID]
  );

  return {
    mapRef,
    measuredRef,
    onHover,
    hoveredDeals,
    taxAssessorData,
    activeParcelID,
    activeParcelLatitude,
    activeParcelLongitude,
    sources: [USAParcelsSource, dealsSource, customFeatureSource].filter(
      (source): source is Source => !!source
    ),
  };
};
