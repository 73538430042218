import { LinearProgress, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import NotFound from 'src/products/shell/pages/NotFound';
import { useCreateDeal } from 'src/services/Dealcloud/createDeal';
import { useLocalDeal } from 'src/services/Dealcloud/localDeal';
import { DealStageType } from 'src/services/Dealcloud/types';
import PortfolioMap from './components/PortfolioMap';
import { Sidebar } from './components/Sidebar';
import { Topbar } from './components/Topbar';

const useStyles = makeStyles({
  root: {
    height: 'calc(100vh - 60px)',
    display: 'grid',
    gridTemplateColumns: '360px 1fr',
  },
  mapContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    position: 'relative',
  },
});

const DealPage: React.VFC = () => {
  const classes = useStyles();
  const { id: dealId } = useParams<{ id: string }>();

  const { deal, dealState, removeAsset, resetForm, editDealModal } =
    useLocalDeal(dealId !== 'new' ? Number(dealId) : 'new');

  const [createDeal, isCreateLoading] = useCreateDeal();

  const isModalLoading = isCreateLoading;

  const isInitialDataRequired =
    dealId === 'new' && dealState === 'hasValue' && !deal;

  useEffect(() => {
    if (isInitialDataRequired) {
      createDeal();
    }
  }, [isInitialDataRequired]);

  // Reset create/edit modal form on unmount
  useEffect(() => {
    return resetForm;
  }, []);

  if (isInitialDataRequired) {
    return null;
  }

  if (dealState === 'loading') {
    return <LinearProgress />;
  }

  if (dealState === 'hasError') {
    return <span>hasError</span>;
  }

  if (!deal) {
    return <NotFound />;
  }

  const mode = dealId !== 'new' ? 'edit' : 'create';

  return (
    <div className={`${classes.root} js-${mode}-portfolio-page`}>
      <Sidebar
        onRemove={removeAsset}
        title={
          dealId !== 'new' ? 'Edit Portfolio Deal' : 'Create Portfolio Deal'
        }
        name={deal.dealName}
        stage={deal.stage as DealStageType}
        assets={deal.assets}
      />
      <div className={classes.mapContainer}>
        <Topbar loading={isModalLoading} onEditClick={() => editDealModal()} />
        <PortfolioMap
          assets={deal.assets || []}
          stage={deal.stage as DealStageType}
        />
      </div>
    </div>
  );
};

export default DealPage;
