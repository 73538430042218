import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPropertyListEntries } from '../redux/propertyLists/actions';

export const usePropertyListEntries = (
  property_id: string,
  property_code_type: string,
  enabled = true
) => {
  const dispatch = useDispatch();

  const propertyListEntries = useSelector(
    (state) => state.coreProspect.propertyLists.propertyListEntries.value
  );

  const [isLoading, setLoading] = useState(true);

  const propertyListEntriesState = useSelector(
    (state) =>
      state.coreProspect.propertyLists.propertyListEntries.fetchStatus.value
  );

  useEffect(() => {
    if (!enabled) {
      return;
    }

    setLoading(true);
    dispatch(getPropertyListEntries({ property_id, property_code_type }));
  }, [enabled, property_id, property_code_type]);

  useEffect(() => {
    if (!isLoading || propertyListEntriesState !== 'LOADED') {
      return;
    }

    setLoading(false);
  }, [propertyListEntriesState, isLoading]);

  return {
    isLoading,
    propertyListEntries:
      !enabled || isLoading ? undefined : propertyListEntries,
  };
};
