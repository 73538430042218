import React from 'react';
import RCAComp from './RCAComp';

const RCAComps = ({ rcaCompsList, getCherreParcelGemo }) => {
  return (
    <div>
      <ul
        id='rca-comps-list'
        style={{
          listStyle: 'none',
          margin: 0,
          padding: 0,
        }}
      >
        {rcaCompsList?.map((comp, i) => (
          <RCAComp
            getCherreParcelGemo={getCherreParcelGemo}
            comp={comp}
            index={i}
            key={i}
          />
        ))}
      </ul>
    </div>
  );
};

export default RCAComps;
