import { BasicOwner, RowData } from '../types';

export const getBasicRows = (owners: BasicOwner[]): RowData[] => {
  return owners.map((o) => ({
    ...o,
    totalTaxRecordsCount: 'Loading',
    totalTaxRecords: [],
    address: 'Loading',
    contactsCount: 'Loading',
  }));
};
