import { TaxAssessorToHeaderDataFormatter } from '../types';

export const taxAssessorToHeaderData: TaxAssessorToHeaderDataFormatter = (
  data
) => {
  const tax_assessor = data.tax_assessor_v2[0];

  const address = [
    tax_assessor.house_number,
    tax_assessor.street_direction,
    tax_assessor.street_name,
    tax_assessor.street_suffix,
    tax_assessor.street_post_direction,
  ]
    .filter(Boolean)
    .join(' ');

  const cityStateZip = [tax_assessor.city, tax_assessor.state, tax_assessor.zip]
    .filter(Boolean)
    .map((text) => text?.toUpperCase())
    .join(', ');

  const cherre_parcel_id = tax_assessor.cherre_parcel_id || undefined;

  return { address, cityStateZip, cherre_parcel_id };
};
