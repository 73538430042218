import { styled } from '@material-ui/core/styles';

const Separator = styled('div')({
  margin: '0 10px',
  width: 1,
  height: 20,
  background: '#e0e0e0',
});

export default Separator;
