import * as TYPES from '../types';

import {
  getSearchParameters,
  patchByCondoUnitsSearchParameter,
} from 'src/products/core-prospect/search-pages/selectors';
import { getFeature } from '../actions';
import { getAreDealsFiltersEnabled } from '../utils';

type ExecMapLotsQuery = {
  viewportOnly: boolean;
  customZoom?: number;
  rebound: boolean;
};

export const execMapLotsQuery = ({
  viewportOnly,
  customZoom,
  rebound,
}: ExecMapLotsQuery) => {
  return async (dispatch, getState) => {
    const state = getState();
    const searchParameters = getSearchParameters(state);
    const timestamp = Date.now();
    let listId = getState().router.location.query.list_id;

    if (Number.isNaN(Number(listId))) {
      listId = undefined;
    }

    const {
      coreProspect: {
        map: { currentViewport },
      },
    } = state;

    const filters = patchByCondoUnitsSearchParameter(state, searchParameters);
    const areDealsFiltersEnabled = getAreDealsFiltersEnabled(
      listId,
      getState()
    );

    const getData = () =>
      dispatch({
        url: '/search/usa-lots?mapLots',
        method: 'post',
        cancelable: true,
        send: {
          areDealsFiltersEnabled,
          listId,
          filters,
          rebound,
          zoom: customZoom || currentViewport.zoom + 1,
          feature: getFeature({
            viewportOnly,
            state,
          }),
        },
        dataToDispatch: { timestamp },
        types: [
          TYPES.EXEC_MAP_QUERY_REQUEST,
          TYPES.EXEC_MAP_QUERY_SUCCESS,
          TYPES.EXEC_MAP_QUERY_FAILED,
        ],
      });

    return getData();
  };
};
