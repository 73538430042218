import { Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { panels } from '../../constants';
import * as C from './components';
import useStyles from './styles';
// import * as T from './types';

const Leases: React.VFC = () => {
  const user = useSelector((state: any) => state.user.profile.value);
  const classes = useStyles();
  if (!user.compstakEnabled) {
    return (
      <Typography variant='body2' style={{ marginBottom: 64 }}>
        Want access to Compstack Data? Contact us to to start a trial!
      </Typography>
    );
  }
  return (
    <div id={panels[9].id} className={classes.container}>
      <div className={classes.header}>
        <Typography variant='h4' style={{ marginBottom: '25px' }}>
          Leases
        </Typography>
        <img src='/assets/CompStakLogo.png' height={23} />
      </div>
      <C.LeasesTable />
      <C.NearbyLeases />
    </div>
  );
};

Leases.displayName = 'Leases';

export default Leases;
