import { Maybe } from 'graphql/jsutils/Maybe';
import { cherrePropertyUseHierarchical } from 'src/const/lookups';
import { getPropertySubtitle } from 'src/products/core-prospect/utils/getPropertySubtitle';
import { DataFormatter } from './DataFormatter';

export type UsaLotData = {
  cherre_parcel_id?: Maybe<string>;
  tax_assessor_id?: Maybe<number>;
  address?: Maybe<string>;
  zone_code?: Maybe<string>;
  units_count?: Maybe<number>;
  year_built?: Maybe<number>;
  building_sq_ft?: Maybe<number>;
  lot_size_acre?: Maybe<number>;
  property_use_code_mapped?: Maybe<number>;
  recorder_v2_summary_v2__tax_assessor_id?: Maybe<
    Array<{
      cherre_latest_deed_date?: Maybe<string>;
      cherre_latest_deed_amount?: Maybe<number>;
    }>
  >;
  cherre_address__property_address?: Maybe<
    Array<{
      reis_property_attributes__address?: Maybe<
        Array<{
          sector?: Maybe<string>;
          propertytype?: Maybe<string>;
          sizesqft?: Maybe<number>;
          sizeunit?: Maybe<number>;
          yearbuilt?: Maybe<number>;
        }>
      >;
    }>
  >;
};

export const usaDataFormatter = (data: UsaLotData): DataFormatter => {
  const recorder = data.recorder_v2_summary_v2__tax_assessor_id?.[0];

  const buildingSize = data.building_sq_ft;

  const yearBuilt = data.year_built;

  const unitsCount = data.units_count;

  const lastSaleDate = recorder?.cherre_latest_deed_date;

  const lastSaleAmount = recorder?.cherre_latest_deed_amount;

  const type = (() => {
    const propertyUseCode = data.property_use_code_mapped;

    const lookup = cherrePropertyUseHierarchical.find(
      (lookup) => lookup.lookup_code === propertyUseCode
    );

    return lookup?.lookup_value;
  })();

  const subTitle = getPropertySubtitle({
    propertyUseCodeMapped: data.property_use_code_mapped,
    type: type,
    buildingSize: buildingSize,
    lotSizeAcre: data.lot_size_acre,
    unitsCount: unitsCount,
    yearBuilt: yearBuilt,
    zoneCode: data.zone_code,
  });

  return {
    subTitle,
    parcelId: data.cherre_parcel_id || '',
    taxAssessorId: data.tax_assessor_id || 0,
    address: data.address,
    saleAmount: lastSaleAmount,
    saleDate: lastSaleDate,
  };
};
