import React from 'react';
import Value from './Value';
import * as T from './types';
import { MultiColumnRow } from '../../../../components';
import hook from './useNearbyLeases';
import { createSuspenseComponent } from 'src/utils/suspense-companion/createSuspenseComponent';

const component: T.NearbyLeases = ({
  concessions,
  freeRentMonthCount,
  leaseTerm,
  netEffectiveRent,
  startingRentPerSQFT,
  workValue,
}) => {
  return (
    <MultiColumnRow columns={3}>
      <Value
        label='AVG Starting Rent (PSF)'
        type='dollar'
        value={startingRentPerSQFT}
      />
      <Value
        label='AVG Net Effective Rent (PSF)'
        type='dollar'
        value={netEffectiveRent}
      />
      <Value label='AVG Lease Term (Months)' value={leaseTerm} />
      <Value label='AVG Free Rent (Months)' value={freeRentMonthCount} />
      <Value
        label='AVG TI Allowance (PSF Total)'
        type='dollar'
        value={workValue}
      />
      <Value
        label='AVG Total Concessions (PSF)'
        type='dollar'
        value={concessions}
      />
    </MultiColumnRow>
  );
};

component.displayName = 'NearbyLeases';

export default createSuspenseComponent({ component, hook });
