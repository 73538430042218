import { TYPE_BOOLEAN, TYPE_NUMBER } from './common';
import { getMaskedValue } from 'src/utils/mask';
import { PropertyDetailsType } from './usePropertyDetails';

const formatSqft = (value: string) => getMaskedValue(value, 'sqft');

export type Panels = {
  label: string;
  details: {
    label?: string;
    field: keyof PropertyDetailsType;
    format?: (value: string) => string | null | undefined;
    type?: typeof TYPE_BOOLEAN | typeof TYPE_NUMBER;
  }[];
}[];

const PANELS: Panels = [
  {
    label: 'Attic',
    details: [
      {
        field: 'has_attic',
        type: TYPE_BOOLEAN,
      },
      {
        label: 'Area',
        field: 'attic_sq_ft',
        format: formatSqft,
        type: TYPE_NUMBER,
      },
    ],
  },
  {
    label: 'Basement',
    details: [
      {
        label: 'Area',
        field: 'basement_sq_ft',
        format: formatSqft,
        type: TYPE_NUMBER,
      },
      {
        label: 'Finished Area',
        field: 'basement_finished_sq_ft',
        format: formatSqft,
        type: TYPE_NUMBER,
      },
      {
        label: 'Unfinished Area',
        field: 'basement_unfinished_sq_ft',
        format: formatSqft,
        type: TYPE_NUMBER,
      },
    ],
  },
  {
    label: 'Parking',
    details: [
      {
        label: 'Area',
        field: 'parking_sq_ft',
        format: formatSqft,
        type: TYPE_NUMBER,
      },
      {
        label: 'Garage',
        field: 'parking_garage',
      },
    ],
  },
  {
    label: 'Carport',
    details: [
      {
        field: 'has_parking_carport',
        type: TYPE_BOOLEAN,
      },
    ],
  },
  {
    label: 'HVAC',
    details: [
      {
        label: 'Cooling',
        field: 'hvacc_cooling',
      },
      {
        label: 'Heating',
        field: 'hvacc_heating',
      },
      {
        label: 'Heating Fuel',
        field: 'hvacc_heating_fuel',
      },
    ],
  },
  {
    label: 'Elevator',
    details: [
      {
        field: 'has_elevator',
        type: TYPE_BOOLEAN,
      },
    ],
  },
  {
    label: 'Handicap Accessible',
    details: [
      {
        field: 'is_handicap_accessible',
        type: TYPE_BOOLEAN,
      },
    ],
  },
  {
    label: 'Central Vacuum System',
    details: [
      {
        field: 'has_central_vacuum_system',
        type: TYPE_BOOLEAN,
      },
    ],
  },
  {
    label: 'Intercom',
    details: [
      {
        field: 'has_intercom',
        type: TYPE_BOOLEAN,
      },
    ],
  },
  {
    label: 'Installed Sound System',
    details: [
      {
        field: 'has_installed_sound_system',
        type: TYPE_BOOLEAN,
      },
    ],
  },
  {
    label: 'Wet Bar',
    details: [
      {
        field: 'has_wet_bar',
        type: TYPE_BOOLEAN,
      },
    ],
  },
  {
    label: 'Alarm System',
    details: [
      {
        field: 'has_alarm_system',
        type: TYPE_BOOLEAN,
      },
    ],
  },
  {
    label: 'Storm Shutter',
    details: [
      {
        field: 'has_storm_shutter',
        type: TYPE_BOOLEAN,
      },
    ],
  },
  {
    label: 'Porch',
    details: [
      {
        label: 'Porch',
        field: 'porch',
      },
    ],
  },
  {
    label: 'Deck',
    details: [
      {
        field: 'has_deck',
        type: TYPE_BOOLEAN,
      },
    ],
  },
  {
    label: 'Driveway',
    details: [
      {
        label: 'Material',
        field: 'driveway_material',
      },
    ],
  },
  {
    label: 'Pool',
    details: [
      {
        label: 'Pool',
        field: 'pool',
      },
    ],
  },
  {
    label: 'Sauna',
    details: [
      {
        field: 'has_sauna',
        type: TYPE_BOOLEAN,
      },
    ],
  },
  {
    label: 'Arbor Pergola',
    details: [
      {
        field: 'has_arbor_pergola',
        type: TYPE_BOOLEAN,
      },
    ],
  },
  {
    label: 'Tennis Court',
    details: [
      {
        field: 'has_tennis_court',
        type: TYPE_BOOLEAN,
      },
    ],
  },
  {
    label: 'Other Sport Court',
    details: [
      {
        field: 'has_other_sport_court',
        type: TYPE_BOOLEAN,
      },
    ],
  },
  {
    label: 'Water Feature',
    details: [
      {
        field: 'has_water_feature',
        type: TYPE_BOOLEAN,
      },
    ],
  },
  {
    label: 'Boat Lift',
    details: [
      {
        field: 'has_boat_lift',
        type: TYPE_BOOLEAN,
      },
    ],
  },
  {
    label: 'Boat Access',
    details: [
      {
        field: 'has_boat_access',
        type: TYPE_BOOLEAN,
      },
    ],
  },
  {
    label: 'Outdoor Kitchen Fireplace',
    details: [
      {
        field: 'has_outdoor_kitchen_fireplace',
        type: TYPE_BOOLEAN,
      },
    ],
  },
  {
    label: 'Sprinklers',
    details: [
      {
        field: 'has_sprinklers',
        type: TYPE_BOOLEAN,
      },
    ],
  },
];

export default PANELS;
