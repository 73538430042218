import * as TYPES from '../types';

import {
  getSearchParameters,
  patchByCondoUnitsSearchParameter,
} from 'src/products/core-prospect/search-pages/selectors';

import { getFeature } from '../actions';
import { getAreDealsFiltersEnabled } from '../utils';
import { RootState } from 'react-redux';

type ExecCountAllQuery = {
  viewportOnly: boolean;
  rebound: boolean;
};

export const execTableCountAll = ({
  viewportOnly,
  rebound,
}: ExecCountAllQuery) => {
  return async (dispatch, getState) => {
    const timestamp = Date.now();
    const state: RootState = getState();
    const searchParameters = getSearchParameters(state);

    let listId: string | undefined = state.router.location.query.list_id;

    if (Number.isNaN(Number(listId))) {
      listId = undefined;
    }

    const areDealsFiltersEnabled = getAreDealsFiltersEnabled(listId, state);

    const {
      coreProspect: {
        map: { currentViewport },
      },
    } = state;

    const filters = patchByCondoUnitsSearchParameter(state, searchParameters);

    return dispatch({
      url: '/search/usa-lots?countAll',
      method: 'post',
      cancelable: true,
      send: {
        areDealsFiltersEnabled,
        listId,
        filters,
        zoom: currentViewport.zoom + 1,
        rebound,
        isCountAll: true,
        feature: getFeature({
          viewportOnly,
          state,
        }),
      },
      dataToDispatch: { timestamp },
      types: [
        TYPES.EXEC_COUNT_ALL_QUERY_REQUEST,
        TYPES.EXEC_COUNT_ALL_QUERY_SUCCESS,
        TYPES.EXEC_COUNT_ALL_QUERY_FAILED,
      ],
    });
  };
};
