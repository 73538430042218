import * as TYPES from './types';

const initialState = {
  selectedZipCode: undefined,
  showTopFactorZipCodes: false,
};

export const topFactorsMap = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SELECT_ZIP_CODE:
      return {
        ...state,
        selectedZipCode: action.selectedZipCode,
      };
    case TYPES.SHOW_MSA_ZIP_CODE_VALUES:
      return {
        ...state,
        showTopFactorZipCodes: action.showZipCodeValues,
      };
    default:
      return state;
  }
};
