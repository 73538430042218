import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import MapIcon from '@material-ui/icons/Map';
import TableChartIcon from '@material-ui/icons/TableChart';

import * as constants from './constants';
import { CORE_PROSPECT_VIEWS } from 'src/products/core-prospect/constants';
import ViewToggleButton from './ViewToggleButton';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: ({ isTable }) => (isTable ? undefined : '0 2px 5px 0 #00000033'),
    border: 'none',
    height: '100%',
  },
}));

const ViewToggleButtonGroup = ({
  view = constants.VIEW_MAP,
  showMap,
  setShowMap,
}) => {
  const isTable = view === constants.VIEW_TABLE;
  const classes = useStyles({ isTable });

  return (
    <ToggleButtonGroup exclusive value={view} className={classes.root}>
      <ViewToggleButton
        id='js-core-prospect-map-view-btn'
        view={view}
        text='Map'
        viewerName={CORE_PROSPECT_VIEWS.MAP}
        icon={<MapIcon />}
        value={constants.VIEW_MAP}
        isTable={isTable}
        style={{
          borderRadius: '4px 0 0 4px',
        }}
        showMap={showMap}
        setShowMap={setShowMap}
      />
      <ViewToggleButton
        id='js-core-prospect-table-view-btn'
        view={view}
        text='Table'
        viewerName={CORE_PROSPECT_VIEWS.TABLE}
        icon={<TableChartIcon />}
        value={constants.VIEW_TABLE}
        isTable={isTable}
        style={{
          borderRadius: '0 4px 4px 0',
        }}
        showMap={showMap}
        setShowMap={setShowMap}
      />
    </ToggleButtonGroup>
  );
};

export default ViewToggleButtonGroup;
