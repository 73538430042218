import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import Truncate from 'src/components/Truncate';

const useStyles = makeStyles(() => ({
  address: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const Address = ({ address, city, state, zip, type, wrap, link }) => {
  const classes = useStyles();
  return (
    <>
      <Typography
        variant='h5'
        color='primary'
        component={Link}
        to={link}
        target='_blank'
        className={`${classes.address} previewAddressLink`}
        onClick={() =>
          window.analytics?.track('Preview Card - Address Clicked')
        }
      >
        <Truncate wrap={wrap} title={address || 'No address'}>
          {address ? <b>{address}</b> : <span>No address</span>}
        </Truncate>
      </Typography>
      <Typography color='textSecondary' variant='body2'>
        {city}, {state} {zip}
      </Typography>

      <Typography color='textSecondary' variant='body2'>
        {type}
      </Typography>
    </>
  );
};

export default Address;
