import axios from 'axios';
import { atom, selectorFamily } from 'recoil';
import {
  DemographicsGeographyLayerName,
  DemographyLayerName,
  GeographyLayerName,
  LayerGroup,
  LayerGroupName,
  LayerName,
} from '../types';
import { Feature, Geometry } from '@turf/helpers';

export const demographicsFeatureAtom = atom<
  | Feature<
      Geometry,
      {
        GEO_NAME: string;
        OB_GEO_ID: string;
        GEO_ID: string;
        GEO_TYPE: 'ZI' | 'CO' | 'ST';
      }
    >
  | undefined
>({
  key: 'demographicsFeatureAtom',
  default: undefined,
});

export const layerGroupsAtom = atom<LayerGroup[]>({
  key: 'LAYER_GROUPS_ATOM',
  default: [
    {
      groupName: LayerGroupName.mapLayers,
      layers: [
        { label: 'Parcels', name: GeographyLayerName.parcels, checked: true },
        { label: 'Neighborhoods', name: GeographyLayerName.neighborhoods },
        { label: 'Zipcodes', name: GeographyLayerName.zipcodes },
        { label: 'Counties', name: GeographyLayerName.counties },
        { label: 'School Districts', name: GeographyLayerName.schools },
        {
          label: 'REIS Submarkets',
          name: GeographyLayerName.reisBoundaries,
          layers: [
            {
              label: 'Apartment',
              name: GeographyLayerName.reisBoundariesApartments,
            },
            {
              label: 'Flex',
              name: GeographyLayerName.reisBoundariesFlex,
            },
            {
              label: 'Office',
              name: GeographyLayerName.reisBoundariesOffice,
            },
            {
              label: 'Retail',
              name: GeographyLayerName.reisBoundariesRetail,
            },
            {
              label: 'Warehouse',
              name: GeographyLayerName.reisBoundariesWarehouse,
            },
          ],
        },
      ],
    },
    {
      featureFlag: 'isEqtDealsIntegrationAvailable',
      groupName: LayerGroupName.eqtCustomLayers,
      layers: [
        {
          label: 'EQT Buildings',
          name: GeographyLayerName.leaseCompsBuildings,
          checked: false,
        },
        {
          label: 'Lease Deals (Internal)',
          name: GeographyLayerName.leaseCompsInternal,
          checked: false,
        },
        {
          label: 'Lease Deals (External)',
          name: GeographyLayerName.leaseCompsExternal,
          checked: false,
        },
      ],
    },
    {
      groupName: LayerGroupName.demographicsLayers,
      featureFlag: 'CoreExplore_MapLayers_heatmaps',
      radio: true,
      checked: true,
      layers: [
        { label: 'Crime', name: DemographyLayerName.crime, icon: 'security' },
        {
          label: 'Household income',
          name: DemographyLayerName.income,
          icon: 'localAtm',
        },
        {
          label: 'Population age',
          name: DemographyLayerName.population,
          icon: 'supervisorAccount',
        },
      ],
    },
  ],
});

export const demographicsDataSelector = selectorFamily<
  string[][],
  {
    bounds?: number[][];
    zoom?: number;
    demographicsGeographyName?: DemographicsGeographyLayerName;
    selectedDemographicsLayerName?: LayerName;
  }
>({
  key: 'DEMOGRAPHICS_DATA_SELECTOR',
  get:
    ({
      bounds,
      zoom,
      demographicsGeographyName,
      selectedDemographicsLayerName,
    }) =>
    async () => {
      if (
        !bounds ||
        !zoom ||
        !demographicsGeographyName ||
        !selectedDemographicsLayerName
      ) {
        return [];
      }

      const response = await axios.post('/api/v1/demographics/mapCSVData', {
        geographyName: demographicsGeographyName,
        demographicField: selectedDemographicsLayerName,
        geographicBounds: [
          {
            latitude: bounds?.[1]?.[1] + 0.05,
            longitude: bounds?.[1]?.[0] + 0.05,
          },
          {
            latitude: bounds?.[0]?.[1] - 0.05,
            longitude: bounds?.[0]?.[0] - 0.05,
          },
        ],
      });

      if (!response.data) {
        return [];
      }

      const data = response.data
        .split(/\n/g)
        .slice(1)
        .map((line) => line.split(','))
        .filter((row) => ![null, undefined, ''].includes(row[1])) as string[][];

      return data;
    },
});
