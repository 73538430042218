import { LAYERS } from '../const';
import { LayerProps } from 'src/components/Map/types';
import { Visibility } from 'mapbox-gl';

function getLines({ visibility }: GetLayersArg): LayerProps {
  return {
    id: LAYERS.STATES_LINE,
    source: 'census',
    'source-layer': 'census-st',
    type: 'line',
    paint: {
      'line-color': 'hsl(280, 80%, 50%)',
      'line-width': ['interpolate', ['linear'], ['zoom'], 7, 0.5, 12, 1],
    },
    layout: {
      visibility,
    },
  };
}

function getFill({ visibility }: GetLayersArg): LayerProps {
  return {
    id: LAYERS.STATES_FILL,
    source: 'census',
    'source-layer': 'census-st',
    type: 'fill',
    paint: {
      'fill-color': 'hsla(0, 0%, 0%, 0)',
    },
    layout: {
      visibility,
    },
  };
}

function getSelectedLines({ states }: GetLayersArg): LayerProps {
  return {
    id: LAYERS.SELECTED_STATES_LINE,
    source: 'census',
    'source-layer': 'census-st',
    type: 'line',
    paint: {
      'line-color': 'hsl(280, 100%, 66%)',
      'line-width': 5,
    },
    filter: ['in', ['get', 'GEO_ID'], ['literal', states]],
  };
}

function getSelectedFill({ states }: GetLayersArg): LayerProps {
  return {
    id: LAYERS.SELECTED_STATES_FILL,
    source: 'census',
    'source-layer': 'census-st',
    type: 'fill',
    paint: {
      'fill-color': 'hsla(280, 100%, 66%, 0.3)',
    },
    filter: ['in', ['get', 'GEO_ID'], ['literal', states]],
  };
}

type GetLayersArg = {
  visibility?: Visibility;
  states?: string[];
};

function getStatesLayers({
  states = [],
  visibility,
}: GetLayersArg): LayerProps[] {
  return [
    getLines({ visibility }),
    getFill({ visibility }),
    getSelectedLines({ states }),
    getSelectedFill({ states }),
  ];
}

export default getStatesLayers;
