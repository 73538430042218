import React, { useEffect } from 'react';
import { MuiThemeProvider, responsiveFontSizes } from '@material-ui/core';
import theme from './theme';
import * as Panels from './panels';
import { MultiColumnRow } from './components';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSatelliteVisibility } from '../../redux/map/actions';
import { useOrganizationPropsValue } from 'src/hooks';
import { Redirect, useParams } from 'react-router';
import { LotPageParams } from './types';
import { taxAssessorQuery } from './recoil';
import { useRecoilValue } from 'recoil';

const LotPage: React.VFC = () => {
  const dispatch = useDispatch();

  const { id } = useParams<LotPageParams>();
  const result = useRecoilValue(
    taxAssessorQuery({ tax_assessor_id: Number(id) })
  );

  const isDealsIntegrationAvailable =
    useOrganizationPropsValue('deals_enabled');

  const defaultMapToSatelliteView = useSelector(
    (state) => state.user.profile.value.defaultMapToSatelliteView
  );

  useEffect(() => {
    dispatch(toggleSatelliteVisibility(defaultMapToSatelliteView));
  }, [defaultMapToSatelliteView]);

  if (!result.tax_assessor_v2.length) {
    return <Redirect to='/notfound' />;
  }

  return (
    <MuiThemeProvider theme={responsiveFontSizes(theme)}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto 1fr',
          maxWidth: 1600,
          margin: 'auto',
        }}
      >
        <div>
          <Panels.Sidebar item={{}} />
        </div>
        <div>
          <Panels.Header />
          {isDealsIntegrationAvailable ? <Panels.DealCloudInfo /> : null}
          <Panels.Summary />
          <MultiColumnRow>
            <Panels.LotInformation />
            <Panels.LotSize />
            <Panels.BuildingInfo />
          </MultiColumnRow>
          <Panels.Taxes />
          <Panels.PropertyDetails />
          <Panels.Location />
          <Panels.Demographics />
          <Panels.CommercialSaleComps />
          <Panels.PropertyOwners />
          <Panels.Timeline />
          <Panels.Leases />
        </div>
      </div>
    </MuiThemeProvider>
  );
};

export default LotPage;
