import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

import { primaryColor } from 'src/const/theme';
import TitleAndSummary from './TitleAndSummary';
import { Tooltip, IconButton } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';

import { Context } from 'src/products/core-prospect/search-pages/USALot';

const Pill = ({
  classes,
  primaryText,
  secondaryText,
  filterText,
  url,
  id,
  onDelete,
  active,
}) => {
  const { isCompact } = React.useContext(Context);

  const matches = match(primaryText, filterText);
  const parts = parse(primaryText, matches);

  return (
    <Link
      style={{
        border: active ? '1px solid' : '1px solid transparent',
      }}
      className={classes.root}
      id={id}
      to={url}
    >
      <TitleAndSummary parts={parts} summary={secondaryText} />
      {onDelete && (
        <Tooltip title={onDelete.title}>
          <IconButton
            onClick={(e) => {
              onDelete.action();
              e.stopPropagation();
              e.preventDefault();
            }}
            size='small'
          >
            <Delete
              id={`${id}-delete`}
              className={classes.showOnHover}
              style={{
                width: 18,
                height: 18,
                display: isCompact && 'block',
              }}
            />
          </IconButton>
        </Tooltip>
      )}
    </Link>
  );
};

const styles = {
  root: {
    padding: 12,
    paddingRight: 4,
    background: '#f5f5f5',
    fontWeight: 600,
    marginBottom: 12,
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      '& $showOnHover': {
        display: 'block',
      },
    },
  },
  showOnHover: {
    display: 'none',
    color: primaryColor.main,
  },
};

export default withStyles(styles)(Pill);
