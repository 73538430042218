import { Button } from '@material-ui/core';
import TableChart from '@material-ui/icons/TableChart';
import Map from '@material-ui/icons/Map';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleCoreProspectView } from 'src/products/core-prospect/redux/view/actions';
import { VIEW_MAP, VIEW_TABLE } from '../NavBar/constants';

const MapToggler = () => {
  const dispatch = useDispatch();

  const { viewerName } = useSelector((state) => {
    return { viewerName: state.coreProspect.view.viewerName };
  });

  return (
    <>
      {viewerName === VIEW_TABLE ? (
        <Button
          onClick={() => {
            dispatch(toggleCoreProspectView(VIEW_MAP));
          }}
          className='js-map-view-button'
          color='primary'
          startIcon={<Map />}
        >
          Map view
        </Button>
      ) : (
        <Button
          onClick={() => {
            dispatch(toggleCoreProspectView(VIEW_TABLE));
          }}
          className='js-table-view-button'
          color='primary'
          startIcon={<TableChart />}
        >
          Table view
        </Button>
      )}
    </>
  );
};

export default MapToggler;
