import { WebMercatorViewport } from 'react-map-gl';
import { Reducer } from 'redux';
import { DrawingMode } from 'src/components/Map/components/DrawEditor/types';
import { GET_QUERY_DATA_SUCCESS } from '../query/types';
import { RecenterMapActionPayload } from './actions';
import * as TYPES from './types';

type MapState = {
  drawingMode: DrawingMode | null;
  isInFullMode: boolean;
  currentViewport: {
    width: number;
    height: number;
    latitude: number;
    longitude: number;
    zoom: number;
    bounds: [number[], number[]];
  };
  isSatelliteVisible: boolean;
};

const initialState: MapState = {
  drawingMode: null,
  isInFullMode: false,
  currentViewport: {
    width: 1150,
    height: 650,
    latitude: 40.75202353325635,
    longitude: -73.93626282945806,
    zoom: 12,
    bounds: [
      [-73.84341946663652, 40.78687390290622],
      [-74.02910619227858, 40.717154887474464],
    ],
  },
  isSatelliteVisible: false,
};

type MapReducer = Reducer<MapState>;

export const map: MapReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET_DRAWING_MODE: {
      return {
        ...state,
        drawingMode: action.drawingMode,
      };
    }
    case TYPES.SET_FULL_MAP_MODE: {
      return {
        ...state,
        isInFullMode: action.isInFullMode,
      };
    }

    case GET_QUERY_DATA_SUCCESS: {
      return {
        ...state,
        currentViewport:
          action.data.data.viewport || initialState.currentViewport,
      };
    }

    case TYPES.TOGGLE_SATELLITE_VISIBILITY: {
      return {
        ...state,
        isSatelliteVisible: action.isSatelliteVisible,
      };
    }

    case TYPES.SYNC_MAP_VIEWPORT:
      return {
        ...state,
        currentViewport: action.currentViewport,
      };

    case TYPES.RECENTER_MAP: {
      const {
        bounds,
        latitude,
        longitude,
        zoom,
        padding = 40,
      } = action as RecenterMapActionPayload;
      const width = state.currentViewport.width;
      const height = state.currentViewport.height;

      let viewport: WebMercatorViewport | void | undefined;

      const maxPadding = Math.min(width, height) / 2 - 1;

      if (bounds) {
        viewport = new WebMercatorViewport({
          width: width,
          height: height,
        }).fitBounds(bounds, { padding: Math.min(maxPadding, padding) });
      }

      if (!bounds) {
        if (!latitude || !longitude || !zoom) {
          return state;
        }

        viewport = new WebMercatorViewport({
          latitude,
          longitude,
          zoom,
          width,
          height,
        });
      }

      if (!viewport) {
        return state;
      }

      return {
        ...state,
        currentViewport: {
          ...state.currentViewport,
          bounds: viewport?.getBounds(),
          zoom: viewport.zoom,
          latitude: viewport.latitude,
          longitude: viewport.longitude,
        },
      };
    }

    default:
      return state;
  }
};
