import { Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';
import * as C from './components';
import { panels } from '../../constants';

const PropertyDetails: React.FC = () => {
  const classes = useStyles();
  return (
    <div id={panels[3].id} className={classes.container}>
      <Typography variant='h4' style={{ marginBottom: '25px' }}>
        Property Details
      </Typography>
      <C.BuildingDetails />
      <div className={classes.separator} />
      <C.PropertyDetails />
    </div>
  );
};

PropertyDetails.displayName = 'PropertyDetails';

export default PropertyDetails;
