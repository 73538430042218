import React from 'react';
import { useUpdateUrlSearchParams } from 'src/products/core-prospect/hooks/useUpdateUrlSearchParams';
import LotPopup from 'src/products/core-prospect/search-pages/components/SearchMap/LotPopup';

export type PortfolioPopupProps = {
  latitude: number;
  longitude: number;
  parcel_id: string;
};

export const PortfolioPopup: React.VFC<PortfolioPopupProps> = ({
  latitude,
  longitude,
  parcel_id,
}) => {
  const updateUrlSearchParams = useUpdateUrlSearchParams();

  return (
    <LotPopup
      onClose={() =>
        updateUrlSearchParams({
          isPopupOpen: undefined,
          cherre_parcel_id: undefined,
          latitude: undefined,
          longitude: undefined,
        })
      }
      latitude={latitude}
      longitude={longitude}
      parcel_id={parcel_id}
      portfolio
    />
  );
};
