import { parse } from 'json2csv';

import { GET_LOT_OWNER_DATA } from './queries';
import { getPropertyListEntriesByPropertyIds } from 'src/products/core-prospect/redux/propertyLists/actions';
import { OWNER_TYPE } from 'src/const';
import { requestGraphqlDataUserGen } from 'src/products/shell/redux/actions/graphql-query';
import { execTableLotsQuery } from 'src/products/core-prospect/redux/query/actions/execTableLotsQuery';

const downloadCSV = (csv, includeOwnerData) => {
  const link = document.createElement('a');
  const blob = new Blob([csv], {
    type: 'text/csv;charset=utf-8;',
  });
  const url = URL.createObjectURL(blob);

  link.setAttribute('href', url);
  link.setAttribute('download', `${includeOwnerData ? 'owners' : 'lots'}.csv`);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const TYPE_OWNER = 'OWNER';
const TYPE_UNKNOWN = 'UNKNOWN';
const TYPE_RECORDED = 'RECORDED';
const TYPE_UNMASKED = 'UNMASKED';

const transformResults = (results, columns, includeOwnerData) => {
  let flatResults = results;
  let flatColumns = columns;

  if (includeOwnerData) {
    flatColumns = [
      ...flatColumns,
      {
        dataKey: 'entity_type',
        label: 'Entity Type',
      },
      {
        dataKey: 'owner_type',
        label: 'Owner Type',
      },
      {
        dataKey: 'owner_name',
        label: 'Owner Name',
      },
    ];

    flatResults = results.reduce((acc, item) => {
      const ownerData = item.owner_data;
      const recordedOwners = ownerData?.tax_assessor_owner ?? [];

      for (const recordedOwner of recordedOwners) {
        acc.push({
          ...item,
          entity_type: recordedOwner.owner_type || TYPE_UNKNOWN,
          owner_type: TYPE_RECORDED,
          owner_name: recordedOwner.owner_name,
        });
      }

      const unmaskedOwners = ownerData?.usa_owner_unmask ?? [];

      for (const unmaskedOwner of unmaskedOwners) {
        const ownerType = OWNER_TYPE[unmaskedOwner.owner_type] || TYPE_UNKNOWN;
        const row = {
          ...item,
          entity_type: ownerType,
          owner_type: TYPE_UNMASKED,
          owner_name: unmaskedOwner.owner_name,
          contact_type: TYPE_OWNER,
          contact_name: unmaskedOwner.owner_name,
          contact_address:
            unmaskedOwner.cherre_address__address?.[0]?.display_address,
        };

        acc.push(row);
      }

      return acc;
    }, []);
  }

  return flatResults.map((item) => {
    return flatColumns.reduce((acc, column) => {
      const value = item[column.dataKey];

      acc[column.label] = column.exportCellRenderer
        ? column.exportCellRenderer({
            cellData: value,
            rowData: item,
          })
        : column.cellRenderer
        ? column.cellRenderer({
            cellData: value,
            rowData: item,
          })
        : value;

      return acc;
    }, {});
  });
};

async function addPropertyLists(data, dispatch) {
  const propertyLists = await dispatch(
    getPropertyListEntriesByPropertyIds(
      data.map((item) => item.tax_assessor_id)
    )
  );

  data.forEach((item) => {
    const propertyList = propertyLists.filter(
      (propertyList) =>
        String(propertyList.property_id) === String(item.tax_assessor_id)
    );

    item.propertyListEntries = propertyList;
  });
}

export default async function exportTable(
  selectedRows = [],
  exportSettings,
  exportLimit,
  includeOwnerData,
  dispatch
) {
  const size = selectedRows.length || exportLimit;
  const data = await dispatch(
    execTableLotsQuery({
      isExport: true,
      paginate: false,
      selectedRows,
      viewportOnly: true,
      size,
    })
  );
  const result = data.result;

  await addPropertyLists(result, dispatch);

  if (includeOwnerData) {
    let exported = 0;
    let results = [];
    let page = 0;
    const pageLimit = Math.min(exportLimit, 1000);
    const ids = selectedRows
      .slice(0, Math.min(exportLimit, selectedRows.length))
      .map((row) => row.tax_assessor_id);
    const cache = {};

    while (exported < size) {
      const current = page * pageLimit;
      const ownerData = await dispatch(
        requestGraphqlDataUserGen(GET_LOT_OWNER_DATA(), [], undefined, {
          ids: ids.slice(current, current + pageLimit),
        })
      );
      const dataArr = ownerData.tax_assessor_v2;

      for (const item of dataArr) {
        cache[item.tax_assessor_id] = item;
      }

      results = results.concat(dataArr);
      exported += dataArr.length;
      page += 1;

      // Stop if we reached the last page in the data set.
      if (dataArr.length < pageLimit) {
        break;
      }
    }

    for (const item of result) {
      item.owner_data = cache[item.tax_assessor_id];
    }
  }

  const csv = parse(
    transformResults(result, exportSettings.columns, includeOwnerData)
  );

  downloadCSV(csv, includeOwnerData);
}
