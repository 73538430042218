import {
  Box,
  Button,
  ButtonBase,
  Checkbox,
  Chip,
  CircularProgress,
  ListItem,
  Typography,
} from '@material-ui/core';
import AddCircle from '@material-ui/icons/AddCircle';
import Bookmark from '@material-ui/icons/Bookmark';
import Close from '@material-ui/icons/Close';
import EditOutlined from '@material-ui/icons/EditOutlined';
import OpenInNew from '@material-ui/icons/OpenInNew';
import Place from '@material-ui/icons/Place';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Truncate from 'src/components/Truncate';
import { PopupIcon } from 'src/components/ui/icons/PopupIcon';
import { ViewPropertiesIcon } from 'src/components/ui/icons/ViewPropertiesIcon';
import { PortfolioDeal } from 'src/services/Dealcloud/types';
import numberWithCommas from 'src/utils/numberWithCommas';
import { dealStageColorMap } from '../../constants';
import { PropertyListPopover } from '../../Layout/LotpageNavigation/PropertyListPopover';
import { DealStageChip } from '../../pages/lot-page/components';
import { getPortfolioSummary } from '../../search-pages/components/Sidebar/components/TaxRecordPreview/DealInfoCard';
import {
  ActionsAutoCollapse,
  ActionsAutoCollapseItem,
} from './components/ActionsAutoCollapse';
import { DataFormatter } from './dataFormatter/DataFormatter';
import { useStyles } from './useStyles';
import { DashboardIcon } from '@cherre-frontend/ui';

export const PROPERTY_ITEM_HEIGHT = 105;

export type PropertyAddressSummaryEventProps = {
  property: DataFormatter;
  deal?: PortfolioDeal;
};

export type PropertyAddressSummaryEvent = (
  e: PropertyAddressSummaryEventProps
) => void;

export type PropertyAddressSummaryProps = {
  property: DataFormatter;
  deal?: PortfolioDeal;
  selected?: boolean;
  active?: boolean;
  height?: number | string;
  padding?: number | string;
  hoverEffect?: boolean;
  onSelect?: (checked: boolean) => void;
  onHoverContainer?: PropertyAddressSummaryEvent;
  onClickContainer?: PropertyAddressSummaryEvent;
  onClickOpenPopupPin?: PropertyAddressSummaryEvent;
  onClickAddDeal?: PropertyAddressSummaryEvent;
  onClickEditDeal?: PropertyAddressSummaryEvent;
  onClickPortfolioViewProperties?: PropertyAddressSummaryEvent;
  onClickAddPortfolio?: PropertyAddressSummaryEvent;
  onClickRemovePortfolio?: PropertyAddressSummaryEvent;
  addressHref?: string;
  addressHrefExternal?: boolean;
  buttonHref?: string;
  buttonHrefExternal?: boolean;
  dealCloudHref?: string;
  addToListAction?: boolean;
  showPropertySubtitle?: boolean;
  showPropertyLastSale?: boolean;
  showPortfolioCount?: boolean;
  showPortfolioChip?: boolean;
  showPortfolioViewProperties?: boolean;
  showPortfolioSummary?: boolean;
  showDealStageChipAndLead?: boolean;
  showDealLatestNote?: boolean;
  showDealLatestNoteLarge?: boolean;
  showDealLeadStageFormatted?: boolean;
  colorPin?: string;
  dashboardPropertyLink?: string;
};

export const PropertyAddressSummary: React.FC<PropertyAddressSummaryProps> = ({
  property,
  deal,
  selected,
  active,
  onSelect,
  onHoverContainer,
  onClickContainer,
  onClickAddDeal,
  onClickEditDeal,
  onClickPortfolioViewProperties,
  onClickAddPortfolio,
  onClickRemovePortfolio,
  onClickOpenPopupPin,
  addressHref,
  addressHrefExternal,
  buttonHref,
  buttonHrefExternal,
  dealCloudHref,
  addToListAction,
  height = PROPERTY_ITEM_HEIGHT,
  padding = 15,
  hoverEffect = true,
  showPropertySubtitle = true,
  showPropertyLastSale = true,
  showPortfolioCount,
  showPortfolioChip,
  showPortfolioViewProperties,
  showPortfolioSummary,
  showDealStageChipAndLead,
  showDealLatestNote,
  showDealLatestNoteLarge,
  showDealLeadStageFormatted,
  colorPin,
  dashboardPropertyLink,
}) => {
  const classes = useStyles({
    height,
    padding,
    hoverEffect,
  });

  const handleOnChangeCheckbox = useCallback((e) => {
    onSelect?.(e.target.checked);
  }, []);

  const [propertyListAnchorEl, setPropertyListAnchorEl] = useState(null);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = ref.current;
    if (!element || !onHoverContainer) {
      return;
    }

    const hover = () => onHoverContainer({ property, deal });
    element.addEventListener('mouseover', hover);
    return () => {
      element.removeEventListener('mouseover', hover);
    };
  }, [onHoverContainer, property, deal]);

  const handleClickAddToPropertyList = (event) => {
    setPropertyListAnchorEl(event.currentTarget);
  };

  const handleClosePropertyListPopover = () => {
    setPropertyListAnchorEl(null);
  };

  const { address, subTitle, saleAmount, saleDate } = property;

  const formattedAmount = saleAmount ? `$${numberWithCommas(saleAmount)}` : '';
  const fomattedDate = saleDate ? `on ${moment(saleDate).format('ll')}` : '';

  const dealPortfolio =
    deal && deal.assets.length > 1
      ? {
          name: deal.dealName,
          count: deal.assets.length,
        }
      : undefined;

  const propertySubtitle = showPropertySubtitle && (
    <Typography className={classes.smallText}>
      <Truncate>{subTitle}</Truncate>
    </Typography>
  );

  const propertyLastSale = showPropertyLastSale && (
    <Typography className={classes.smallText}>
      <Truncate>
        {formattedAmount || fomattedDate
          ? `Last sale: ${[formattedAmount, fomattedDate]
              .filter(Boolean)
              .join(' - ')}`
          : null}
      </Truncate>
    </Typography>
  );

  const portfolioCount = showPortfolioCount && dealPortfolio && (
    <Typography className={classes.smallText}>
      <Truncate>{dealPortfolio.count} Property Portfolio</Truncate>
    </Typography>
  );

  const dealStageChipAndLead = showDealStageChipAndLead && deal && (
    <Box display='flex' alignItems='center' gridGap={'4px'}>
      <Box flexShrink={0}>
        <DealStageChip name={deal.stage} size='small' />
      </Box>
      {deal.lead && (
        <Box overflow='hidden'>
          <Typography className={classes.smallText}>
            <Truncate title={`${deal.lead}`}>· {deal.lead}</Truncate>
          </Typography>
        </Box>
      )}
    </Box>
  );

  const formattedDealLeadStageString = (
    deal ? [`${deal.lead}`, `Stage: ${deal.stage}`] : []
  )
    .filter(Boolean)
    .join(' · ');

  const formattedDealLeadStage = showDealLeadStageFormatted && (
    <Typography className={classes.smallText}>
      <Truncate title={formattedDealLeadStageString}>
        {formattedDealLeadStageString}
      </Truncate>
    </Typography>
  );

  const portfolioChip = showPortfolioChip && dealPortfolio && (
    <Chip
      label={dealPortfolio.name}
      size='small'
      className={classes.portfolioChip}
    />
  );

  const dealLatestNote = showDealLatestNote && deal?.lastActivityNote && (
    <Box mt='10px' height='29px'>
      <Typography className={classes.smallText}>
        <Truncate
          style={{
            whiteSpace: 'normal',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
          title={`Latest note: ${deal.lastActivityNote}`}
        >
          <strong>Latest note:</strong> {deal.lastActivityNote}
        </Truncate>
      </Typography>
    </Box>
  );

  const dealLatestNoteLarge = showDealLatestNoteLarge &&
    deal?.lastActivityNote && (
      <Box mt='10px' height='29px'>
        <Typography className={classes.smallText}>
          <strong>Latest note:</strong>
        </Typography>
        <Typography className={classes.smallText}>
          <Truncate
            style={{
              whiteSpace: 'normal',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
            }}
            title={`deal.lastActivityNote`}
          >
            {deal.lastActivityNote}
          </Truncate>
        </Typography>
      </Box>
    );

  const portfolioSummary = showPortfolioSummary && deal && (
    <Typography className={classes.smallText}>
      <Truncate>{getPortfolioSummary(deal)}</Truncate>
    </Typography>
  );

  const portfolioViewProperties = onClickPortfolioViewProperties &&
    showPortfolioViewProperties && (
      <Box mt='10px'>
        <Button
          startIcon={<ViewPropertiesIcon />}
          color='primary'
          style={{ fontWeight: 500 }}
          onClick={() => onClickPortfolioViewProperties({ property, deal })}
        >
          View Properties
        </Button>
      </Box>
    );

  const actions = [] as ActionsAutoCollapseItem[];

  if (onClickOpenPopupPin && (colorPin || deal)) {
    actions.push({
      text: 'View on Map',
      icon: (
        <Place
          htmlColor={deal ? dealStageColorMap[deal.stage] : colorPin}
          fontSize='small'
        />
      ),
      onClick: () => onClickOpenPopupPin({ property, deal }),
    });
  }

  if (onClickOpenPopupPin && !colorPin && !deal) {
    actions.push({
      text: 'View on Map',
      icon: (
        <PopupIcon color='primary' fontSize='small' style={{ width: 14 }} />
      ),
      onClick: () => onClickOpenPopupPin({ property, deal }),
      iconButtonProps: { className: 'pin' },
    });
  }

  if (onClickAddPortfolio) {
    actions.push({
      id: 'js-add-portfolio',
      text: 'Add to portfolio',
      icon: <AddCircle fontSize='small' color='primary' />,
      onClick: () => onClickAddPortfolio({ property, deal }),
    });
  }

  if (onClickRemovePortfolio) {
    actions.push({
      id: 'js-remove-from-portfolio',
      text: 'Remove from portfolio',
      icon: <Close fontSize='small' />,
      onClick: () => onClickRemovePortfolio({ property, deal }),
    });
  }

  if (onClickAddDeal) {
    actions.push({
      text: 'Create Deal',
      icon: <AddCircle fontSize='small' color='primary' />,
      onClick: () => onClickAddDeal({ property, deal }),
      iconLoading: <CircularProgress size={13} />,
    });
  }

  if (onClickEditDeal) {
    actions.push({
      text: 'Edit Deal',
      icon: <EditOutlined fontSize='small' color='primary' />,
      onClick: () => onClickEditDeal({ property, deal }),
      iconLoading: <CircularProgress size={13} />,
    });
  }

  if (dashboardPropertyLink) {
    actions.push({
      text: 'Go to Dashboard',
      icon: <DashboardIcon fontSize='small' color='primary' />,
      onClick: () => {
        window.open(dashboardPropertyLink, '_blank');
      },
      iconLoading: <CircularProgress size={13} />,
    });
  }

  if (addToListAction) {
    actions.push({
      text: 'Add to Property List',
      icon: <Bookmark fontSize='small' color='primary' />,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onClick: (event: any) => handleClickAddToPropertyList(event),
    });
  }

  if (dealCloudHref) {
    actions.push({
      text: 'View in DealCloud',
      icon: <OpenInNew fontSize='small' color='primary' />,
      menuItemProps: {
        component: 'a',
        href: dealCloudHref,
      },
      iconButtonProps: {
        component: 'a',
        href: dealCloudHref,
      },
    });
  }

  if (onClickPortfolioViewProperties) {
    actions.push({
      text: 'View Portfolio Properties',
      icon: <ViewPropertiesIcon fontSize='small' color='primary' />,
    });
  }

  const buttonProps = buttonHref
    ? {
        component: Link,
        to: buttonHref,
        href: buttonHref,
        target: buttonHrefExternal ? '_blank' : undefined,
      }
    : undefined;

  const addressProps = addressHref
    ? {
        component: Link,
        to: addressHref,
        href: addressHref,
        target: addressHrefExternal ? '_blank' : undefined,
        className: classes.address,
        onClick: () =>
          window.analytics?.track('Click Card - Detail Page Link Opened'),
      }
    : buttonProps;

  return (
    <div className={classes.container} ref={ref}>
      {onSelect && (
        <Box className={classes.checkbox}>
          <Checkbox
            checked={selected}
            color='primary'
            onChange={handleOnChangeCheckbox}
          />
        </Box>
      )}
      <ListItem
        selected={active}
        className={classes.listItem}
        component={buttonHref ? ButtonBase : 'div'}
        onClick={() => onClickContainer?.({ property, deal })}
      >
        <Box className={classes.listItemContainer}>
          <Typography color='primary' {...addressProps}>
            <Truncate title={address || ''}>
              {address ? <b>{address}</b> : <span>No address</span>}
            </Truncate>
          </Typography>
          <Box className={classes.listItemContainer} {...buttonProps}>
            {propertySubtitle}
            {propertyLastSale}
            {portfolioCount}
            {portfolioSummary}
            {dealStageChipAndLead}
            {formattedDealLeadStage}
            {portfolioChip}
            {dealLatestNote}
            {dealLatestNoteLarge}
            {portfolioViewProperties}
          </Box>
        </Box>
      </ListItem>
      {actions.length > 0 && (
        <Box className={classes.actions}>
          <ActionsAutoCollapse collapseCount={3} actions={actions}>
            {addToListAction && (
              <PropertyListPopover
                anchorEl={propertyListAnchorEl}
                property_id={String(property.taxAssessorId)}
                property_code_type='tax_assessor_id'
                onClose={handleClosePropertyListPopover}
              />
            )}
          </ActionsAutoCollapse>
        </Box>
      )}
    </div>
  );
};
