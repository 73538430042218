import React from 'react';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';

interface PercentageProps {
  diff: number;
}

const Percentage: React.VFC<PercentageProps> = ({ diff }) => {
  return (
    <span
      style={{
        color: diff > 0 ? green[500] : red[800],
        fontWeight: 'bold',
        fontSize: 14,
        marginBottom: 5,
      }}
    >
      {diff > 0 ? '+' : ''}
      {Math.round(diff * 10000) / 100}%
    </span>
  );
};

export default Percentage;
