import { Typography } from '@material-ui/core';
import React from 'react';
import * as T from './types';

const KeyValue: React.FC<T.KeyValueProps> = ({ label, value }) => {
  return (
    <div style={{ marginBottom: '15px' }}>
      <Typography variant='body1' style={{ fontWeight: 700 }}>
        {label}
      </Typography>
      <Typography variant='body1' className='suspend'>
        {value}
      </Typography>
    </div>
  );
};

KeyValue.displayName = 'KeyValue';

export default KeyValue;
