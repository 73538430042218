export const namespace = 'core-prospect-query';
const ns = <T extends string>(name: T) => `${namespace}/${name}` as const;

export const INCLUDE_CONDO_UNITS = ns('INCLUDE_CONDO_UNITS');
export const ENABLE_DEALS_FILTERS = ns('ENABLE_DEAL_FILTERS');
export const CHANGE_SEARCH_PARAMETER = ns('CHANGE_SEARCH_PARAMETER');
export const RESET_SEARCH_PARAMETERS = ns('RESET_SEARCH_PARAMETERS');

export const RESET_ALL_FILTERS = ns('RESET_ALL_FILTERS');

export const GET_QUERY_DATA_REQUEST = ns('GET_QUERY_DATA_REQUEST');
export const GET_QUERY_DATA_SUCCESS = ns('GET_QUERY_DATA_SUCCESS');
export const GET_QUERY_DATA_FAILED = ns('GET_QUERY_DATA_FAILED');

export const EXEC_MAP_QUERY_REQUEST = ns('EXEC_MAP_QUERY_REQUEST');
export const EXEC_MAP_QUERY_SUCCESS = ns('EXEC_MAP_QUERY_SUCCESS');
export const EXEC_MAP_QUERY_FAILED = ns('EXEC_MAP_QUERY_FAILED');

export const EXEC_MAP_CARDS_QUERY_REQUEST = ns('EXEC_MAP_CARDS_QUERY_REQUEST');
export const EXEC_MAP_CARDS_QUERY_SUCCESS = ns('EXEC_MAP_CARDS_QUERY_SUCCESS');
export const EXEC_MAP_CARDS_QUERY_FAILED = ns('EXEC_MAP_CARDS_QUERY_FAILED');

export const EXEC_COUNT_ALL_QUERY_REQUEST = ns('EXEC_COUNT_ALL_QUERY_REQUEST');
export const EXEC_COUNT_ALL_QUERY_SUCCESS = ns('EXEC_COUNT_ALL_QUERY_SUCCESS');
export const EXEC_COUNT_ALL_QUERY_FAILED = ns('EXEC_COUNT_ALL_QUERY_FAILED');

export const EXEC_MAP_COUNT_QUERY_REQUEST = ns('EXEC_MAP_COUNT_QUERY_REQUEST');
export const EXEC_MAP_COUNT_QUERY_SUCCESS = ns('EXEC_MAP_COUNT_QUERY_SUCCESS');
export const EXEC_MAP_COUNT_QUERY_FAILED = ns('EXEC_MAP_COUNT_QUERY_FAILED');

export const SAVE_QUERY_REQUEST = ns('SAVE_QUERY_REQUEST');
export const SAVE_QUERY_SUCCESS = ns('SAVE_QUERY_SUCCESS');
export const SAVE_QUERY_FAILED = ns('SAVE_QUERY_FAILED');

export const GET_QUERIES_REQUEST = ns('GET_QUERIES_REQUEST');
export const GET_QUERIES_SUCCESS = ns('GET_QUERIES_SUCCESS');
export const GET_QUERIES_FAILED = ns('GET_QUERIES_FAILED');

export const DELETE_QUERIES_REQUEST = ns('DELETE_QUERIES_REQUEST');
export const DELETE_QUERIES_SUCCESS = ns('DELETE_QUERIES_SUCCESS');
export const DELETE_QUERIES_FAILED = ns('DELETE_QUERIES_FAILED');

export const UNDO_DELETE_QUERIES_REQUEST = ns('UNDO_DELETE_QUERIES_REQUEST');
export const UNDO_DELETE_QUERIES_SUCCESS = ns('UNDO_DELETE_QUERIES_SUCCESS');
export const UNDO_DELETE_QUERIES_FAILED = ns('UNDO_DELETE_QUERIES_FAILED');

export const GET_DEFAULT_QUERY_DATA_REQUEST = ns(
  'GET_DEFAULT_QUERY_DATA_REQUEST'
);
export const GET_DEFAULT_QUERY_DATA_SUCCESS = ns(
  'GET_DEFAULT_QUERY_DATA_SUCCESS'
);
export const GET_DEFAULT_QUERY_DATA_FAILED = ns(
  'GET_DEFAULT_QUERY_DATA_FAILED'
);

export const SET_SEARCH_FEATURE = ns('SET_SEARCH_FEATURE');
