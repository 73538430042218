import React from 'react';
import Typography from '@material-ui/core/Typography';
import { getMaskedValue } from 'src/utils/mask';

type OwnerCardCommonPropertyCountProps = {
  commonPropertyCount?: number;
};

const OwnerCardCommonPropertyCount: React.FC<
  OwnerCardCommonPropertyCountProps
> = ({ commonPropertyCount }) => {
  return (
    <div>
      {commonPropertyCount ? (
        <Typography variant='body2'>
          {getMaskedValue(commonPropertyCount, 'number')} properties in common
        </Typography>
      ) : undefined}
    </div>
  );
};

export default OwnerCardCommonPropertyCount;
