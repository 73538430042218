import * as TABLE_TYPES from '../../table/types';

import {
  getSearchParameters,
  patchByCondoUnitsSearchParameter,
} from 'src/products/core-prospect/search-pages/selectors';
import { getFeature } from '../actions';
import {
  FilterType,
  SearchEntity,
  SearchOperator,
  TaxAssessorSearchField,
} from 'src/products/core-prospect/types/SearchParameter';
import { getAreDealsFiltersEnabled } from '../utils';

type ExecTableLotsQuery = {
  viewportOnly: boolean;
  paginate: boolean;
  size?: number;
  isExport: boolean;

  selectedRows?: null | Array<{
    tax_assessor_id: number;
  }>;
};

export const execTableLotsQuery = ({
  isExport,
  paginate,
  viewportOnly,
  size,
  selectedRows,
}: ExecTableLotsQuery) => {
  return async (dispatch, getState) => {
    const timestamp = Date.now();
    const state = getState();
    const searchParameters = getSearchParameters(state);
    const orderBy = state.coreProspect.table.orderBy;
    const { after, before, rowsPerPage } = state.coreProspect.table;

    let listId = getState().router.location.query.list_id;

    if (Number.isNaN(Number(listId))) {
      listId = undefined;
    }

    let filters = patchByCondoUnitsSearchParameter(state, searchParameters);

    if (isExport && selectedRows?.length) {
      filters = [
        ...filters,
        {
          entity_name: SearchEntity.taxAssessor,
          field_name: TaxAssessorSearchField.taxAssessorId,
          attributes: {
            filter_type: FilterType.csvExport,
            operator: SearchOperator.in,
            value: selectedRows.map(
              (selectedRow) => selectedRow.tax_assessor_id
            ),
          },
        },
      ];
    }

    const areDealsFiltersEnabled = getAreDealsFiltersEnabled(
      listId,
      getState()
    );

    return dispatch({
      url: '/search/usa-lots?execTableLotsQuery',
      method: 'post',
      cancelable: true,
      send: {
        areDealsFiltersEnabled,
        listId: isExport ? undefined : listId,
        isCards: true,
        after: paginate ? after : undefined,
        before: paginate ? before : undefined,
        filters,
        feature: getFeature({
          viewportOnly,
          state,
        }),
        size: size || rowsPerPage,
        sort: orderBy
          ? {
              direction: orderBy.direction,
              fields: [orderBy.field],
            }
          : undefined,
      },
      dataToDispatch: { timestamp },
      types: isExport
        ? []
        : [
            TABLE_TYPES.TABLE_RESULTS_REQUEST,
            TABLE_TYPES.TABLE_RESULTS_SUCCESS,
            TABLE_TYPES.TABLE_RESULTS_FAILED,
          ],
    });
  };
};
