import { scale } from 'chroma-js';
import { flatten } from 'lodash';
import { ckmeans } from 'simple-statistics';

export const handleClick = (e, layerName, onClick) => {
  const feature = e.features.find((feature) => {
    return feature?.layer?.id === layerName;
  });

  if (!feature) {
    return;
  }

  onClick &&
    onClick({
      e,
      feature,
      layer: feature.layer,
      properties: feature.properties,
    });
};

export const getBreaks = (vals, clustersAmount = 7) => {
  const bins = ckmeans(
    vals,
    Math.min(clustersAmount, [...new Set(vals)].length)
  ).map((bin) => [bin[0], bin[bin.length - 1]]);

  return [bins[0][0], ...bins.map((b) => b[1])];
};

export const getColorScale = (rows, colorScheme) => {
  if (!rows || rows?.length === 0) {
    return null;
  }

  const vals = rows.map((row) => row[1]);
  const breaks = getBreaks(vals);

  if (breaks && breaks.length >= 1) {
    const colorBreaks = scale(colorScheme)
      .domain([breaks[0], breaks[breaks.length - 1]])
      .classes(breaks);

    return colorBreaks;
  }
};

export const getFillColorProp = ({ colorScale, rows, geometryIdField }) => {
  const rowToStop = (row) => [row[0], colorScale(row[1]).hex()];

  return [
    'match',
    ['to-string', ['get', geometryIdField]],
    ...flatten(rows.filter((row) => Number.isFinite(row[1])).map(rowToStop)),
    'transparent', // TODO option for non-numeric values?
  ];
};
