import {
  NYCDetailsToPropertyOwnersFormatter,
  TaxAssessorToPropertyOwnersFormatter,
} from '../types';

export const taxAssessorToPropertyOwners: TaxAssessorToPropertyOwnersFormatter =
  (data) => {
    const tax_assessor = data.tax_assessor_v2[0];
    const owners = tax_assessor.tax_assessor_owner_v2__tax_assessor_id;
    return owners;
  };

export const nycDetailsToPropertyOwners: NYCDetailsToPropertyOwnersFormatter = (
  data
) => {
  const owner = data?.nyc_lot?.[0]?.owner_name || undefined;
  if (!owner) {
    return [];
  }
  return [{ owner_name: owner, owner_type: undefined }];
};
