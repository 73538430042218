import { Visibility } from 'mapbox-gl';
import { LayerProps } from 'src/components/Map/types';
import { LABELS_FONT } from '../const';
import { getNeighborhoodsInfo } from '../sources/get-neighborhoods-source';

const types = {
  rs: {
    color: 'hsla(90,70%,30%,0.7)',
    fillColor: 'hsla(90,70%,30%,0.4)',
    labelColor: 'hsla(90,70%,20%,1)',
    haloColor: 'hsla(100%,100%,100%,1)',
    highlightedColor: 'hsla(90,90%,30%,1)',
    widths: [10, 1, 15, 3],
    labelWidths: [14, 12, 17, 24],
    minzoom: 14,
  },
  mt: {
    color: 'hsla(220,80%,50%,0.5)',
    fillColor: 'hsla(220,80%,50%,0.4)',
    labelColor: 'hsla(220,80%,50%,1)',
    haloColor: 'hsla(100%,100%,100%,1)',
    highlightedColor: 'hsla(220,100%,50%,1)',
    widths: [10, 3, 15, 6],
    labelWidths: [5, 12, 10, 24],
    minzoom: 5,
  },
  nd: {
    color: 'hsla(160,60%,50%,0.5)',
    fillColor: 'hsla(160,60%,50%,0.4)',
    labelColor: 'hsla(100%,100%,100%,1)',
    haloColor: 'hsla(160,60%,30%,0.8)',
    highlightedColor: 'hsla(160,90%,50%,1)',
    widths: [10, 1, 15, 4],
    labelWidths: [12, 12, 15, 18],
    minzoom: 12,
  },
};

function getLines(type: Type, visibility: Visibility): LayerProps {
  const info = getNeighborhoodsInfo(type);
  return {
    id: `${info.name}-line`,
    source: `${info.name}`,
    'source-layer': `${info.boundariesLayer}`,
    type: 'line',
    paint: {
      'line-color': types[type].color,
      'line-width': [
        'interpolate',
        ['exponential', 1.5],
        ['zoom'],
        ...types[type].widths,
      ],
    },
    layout: {
      visibility,
    },
  };
}

function getFill(type: Type, visibility: Visibility): LayerProps {
  const info = getNeighborhoodsInfo(type);
  return {
    id: `${info.name}-fill`,
    source: `${info.name}`,
    'source-layer': `${info.boundariesLayer}`,
    type: 'fill',
    paint: {
      'fill-color': 'hsla(0, 0%, 0%, 0)',
    },
    layout: {
      visibility,
    },
  };
}

function getLabels(type, visibility): LayerProps {
  const info = getNeighborhoodsInfo(type);
  return {
    id: `${info.name}-labels`,
    source: `${info.name}`,
    'source-layer': `${info.labelLayer}`,
    type: 'symbol',
    minzoom: types[type].minzoom,
    layout: {
      'text-field': ['get', 'label'],
      'text-font': LABELS_FONT,
      'text-size': [
        'interpolate',
        ['exponential', 2],
        ['zoom'],
        ...types[type].labelWidths,
      ],
      'text-allow-overlap': true,
      'text-ignore-placement': true,
      visibility,
    },
    paint: {
      'text-color': types[type].labelColor,
      'text-halo-color': types[type].haloColor,
      'text-halo-width': 2,
    },
  };
}

type Type = 'nd';

type GetLayersArg = {
  type: Type;
  visibility: Visibility;
};

function getNeighborhoodsLayers({
  type,
  visibility,
}: GetLayersArg): LayerProps[] {
  return [
    getLines(type, visibility),
    getFill(type, visibility),
    getLabels(type, visibility),
  ];
}

export default getNeighborhoodsLayers;
