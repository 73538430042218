import axios from 'axios';
import { AutocompleteSuggestion } from 'src/products/core-prospect/search-pages/components/AddressAutocomplete/recoil/selectors';

export const geocodeSuggestion = async (
  suggestion?: AutocompleteSuggestion | null
) => {
  if (!suggestion) {
    return [];
  }

  const url = '/api/v1/autocomplete/suggestion-data';
  const suggestionDataResponse = await axios.post(url, {
    id: suggestion.id,
    groupName: suggestion.group_name,
  });

  const suggestionData = suggestionDataResponse.data;

  if (!suggestionData.geometry) {
    throw new Error(`This ${suggestion.group_name} is unavailable.`);
  }

  return suggestionData;
};
