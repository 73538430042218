import { selectorFamily } from 'recoil';
import { merge } from 'src/utils/recoil/utils';
import { taxAssessorQuery } from '../../../recoil';
import { Tax_AssessorQueryVariables } from '../../../recoil/__generated__/taxAssessor';
import { LotSizeData } from '../types';
import { taxAssessorToLotSize } from './formatters';

export const lotSizeData = selectorFamily<
  LotSizeData,
  Tax_AssessorQueryVariables
>({
  key: 'LOT-PAGE/LOT-SIZE-DATA',
  get:
    (params) =>
    ({ get }) => {
      const defaultValue: LotSizeData = {
        depth: '—',
        grossArea: '—',
        lotSizeAcre: '—',
        size: '—',
        width: '—',
      };

      const taxAssessorValue = get(taxAssessorQuery(params));
      const taxAssessorFormatted = taxAssessorToLotSize(taxAssessorValue);

      return merge(defaultValue, taxAssessorFormatted);
    },
});
