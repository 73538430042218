import React from 'react';
import { isEmpty } from 'lodash';
import { FormattedDemographicsStats } from './formatDemographicsStats';
import DemographicPanel from './DemographicPanel';

type StatsProps = {
  panelData: FormattedDemographicsStats;
  panelsOrder?: number[];
  allowPanelsToCollapse?: boolean;
};

const Stats: React.FC<StatsProps> = ({
  panelData,
  panelsOrder = [0, 1, 2, 3, 4],
  allowPanelsToCollapse = true,
}) => {
  if (isEmpty(panelData)) {
    return null;
  }

  const householdIncomeData = panelData['household_income'];
  const crimeData = panelData['crime'];
  const populationData = panelData['population'];
  const educationData = panelData['education'];
  const employmentData = panelData['employment'];

  const panels = [
    <DemographicPanel
      key={1}
      allowToCollapse={allowPanelsToCollapse}
      title='Household Income'
      tooltip='Number of households in the area with income in the given range.'
      color='#945cca'
      average={householdIncomeData.average}
      items={householdIncomeData.rows}
    />,
    <DemographicPanel
      key={2}
      allowToCollapse={allowPanelsToCollapse}
      title='Crime'
      tooltip='A score that represents the risks of the given crime compared to the national average of 100. A score of 200 indicates twice the national average total crime risk, while 50 indicates half the national risk. The different types of crime are given equal weight in the combined score.'
      color='#479abd'
      average={crimeData.average}
      items={crimeData.rows}
    />,
    <DemographicPanel
      key={3}
      allowToCollapse={allowPanelsToCollapse}
      title='Population'
      tooltip='Number of people living within the area in the given age range.'
      color='#945cca'
      average={populationData.average}
      items={populationData.rows}
    />,
    <DemographicPanel
      key={4}
      allowToCollapse={allowPanelsToCollapse}
      title='Education'
      tooltip='Number of people who achieved the given level of education.'
      color='#479abd'
      items={educationData.rows}
    />,
    <DemographicPanel
      key={5}
      allowToCollapse={allowPanelsToCollapse}
      title='Employment'
      tooltip='The number of people living in this area employed in the given industry.'
      color='#945cca'
      items={employmentData.rows}
    />,
  ];

  return (
    <>
      {panels.sort((panelA, panelB) => {
        return (
          panelsOrder[panels.indexOf(panelA)] -
          panelsOrder[panels.indexOf(panelB)]
        );
      })}
    </>
  );
};

export default Stats;
