import {
  getSearchParameters,
  patchByCondoUnitsSearchParameter,
} from 'src/products/core-prospect/search-pages/selectors';
import {
  FilterType,
  SearchEntity,
  SearchOperator,
  UnmaskedOwnerSearchField,
} from 'src/products/core-prospect/types/SearchParameter';
import { getFeature } from '../actions';
import { getAreDealsFiltersEnabled } from '../utils';

type ExecMapCardsQueryForOwner = {
  ownerId: string;
};

export const execMapCardsQueryForOwner = ({
  ownerId,
}: ExecMapCardsQueryForOwner) => {
  return async (dispatch, getState) => {
    const timestamp = Date.now();
    const state = getState();
    const searchParameters = getSearchParameters(state);
    let listId = getState().router.location.query.list_id;

    if (Number.isNaN(Number(listId))) {
      listId = undefined;
    }

    const filters = patchByCondoUnitsSearchParameter(state, searchParameters);
    const areDealsFiltersEnabled = getAreDealsFiltersEnabled(
      listId,
      getState()
    );

    filters.push({
      entity_name: SearchEntity.unmaskedOwner,
      field_name: UnmaskedOwnerSearchField.owner_id,
      attributes: {
        value: ownerId,
        operator: SearchOperator.equals,
        filter_type: FilterType.textInput,
      },
    });

    return dispatch({
      url: '/search/usa-lots?execMapCardsQueryForOwner',
      method: 'post',
      cancelable: true,
      send: {
        areDealsFiltersEnabled,
        listId,
        isCards: true,
        filters,
        feature: getFeature({
          viewportOnly: true,
          state,
        }),
      },
      dataToDispatch: { timestamp, startOver: true },
      types: [],
    });
  };
};
