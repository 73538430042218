import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Map from '@material-ui/icons/Map';
import List from '@material-ui/icons/List';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles({
  root: {
    borderTop: `1px solid ${grey[300]}`,
  },
});

import {
  collapseSidebar,
  expandSidebar,
} from 'src/products/core-prospect/redux/view/actions';

import { Context } from 'src/products/core-prospect/search-pages/USALot';

function SimpleBottomNavigation({
  collapseSidebar,
  expandSidebar,
  activeTab = 0,
}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(activeTab);
  const { isCompact } = React.useContext(Context);

  if (!isCompact) {
    return null;
  }

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction
        onClick={() => {
          expandSidebar();
        }}
        label='List View'
        icon={<List />}
      />
      <BottomNavigationAction
        onClick={() => {
          collapseSidebar();
        }}
        label='Map View'
        icon={<Map />}
      />
    </BottomNavigation>
  );
}

export default connect(() => ({}), { collapseSidebar, expandSidebar })(
  SimpleBottomNavigation
);
