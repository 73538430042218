import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { dealStageColorMap } from 'src/products/core-prospect/constants';
import { dealStagesSelector } from 'src/services/Dealcloud/recoil';
import { DealStagesResponse } from 'src/services/Dealcloud/types';

const useStyles = makeStyles({
  root: {
    display: 'grid',
    // gridTemplateColumns: 'auto auto', // 2 column template
    gridTemplateColumns: 'auto', // 1 column template
    columnGap: 10,
    background: '#fff',
    alignSelf: 'flex-end',
    padding: '1em',
    borderRadius: 5,
    boxShadow: '0 2px 5px 0 #00000033',
  },
  item: {
    display: 'flex',
    paddingTop: 4,
    paddingBottom: 4,
    alignItems: 'center',
  },
  color: {
    width: 11,
    height: 11,
    borderRadius: '100%',
    marginRight: 5,
  },
});

const DealStagesLegend: React.FC = () => {
  const data = useRecoilValueLoadable(dealStagesSelector);
  const classes = useStyles();

  if (data.state !== 'hasValue') {
    return null;
  }

  const stages = data.valueMaybe()?.filter((s) => s !== 'Inactive');

  const renderColumn = (stages: DealStagesResponse) => {
    return stages.map((stage, index) => {
      return (
        <div className={classes.item} key={index}>
          <div
            style={{ backgroundColor: dealStageColorMap[stage] }}
            className={classes.color}
          />
          <Typography>{stage}</Typography>
        </div>
      );
    });
  };

  return (
    <div className={`js-deal-stages-legend ${classes.root}`}>
      <div>{renderColumn(stages.slice(0, Math.ceil(stages.length / 2)))}</div>
      <div>{renderColumn(stages.slice(Math.ceil(stages.length / 2)))}</div>
    </div>
  );
};

export default DealStagesLegend;
