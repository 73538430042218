import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useIsSuspended } from 'src/utils/suspense-companion/createSuspenseComponent';
import Percentage from './Percentage';
import { YearOverYear } from './types';

interface ValueProps {
  label: string;
  type?: 'number' | 'dollar';
  value?: YearOverYear;
}

const Value: React.VFC<ValueProps> = ({ label, type, value }) => {
  const isSuspended = useIsSuspended();
  const { year1, year2 } = value || {};
  const hasValue = Boolean(year1);
  return (
    <div style={{ display: 'grid', gap: 10 }}>
      <Typography variant='body1'>{label}</Typography>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto auto',
          justifyContent: 'flex-start',
          alignItems: 'flex-end',
          gap: 10,
        }}
      >
        <Typography
          variant='h3'
          className='suspend'
          style={{ minWidth: isSuspended ? 100 : undefined }}
        >
          {isSuspended
            ? ''
            : `${type === 'dollar' && hasValue ? '$' : ''}${
                hasValue ? year1 : '—'
              }`}
        </Typography>
        {year1 && year2 ? (
          <Percentage diff={((year1 || 0) - (year2 || 0)) / (year2 || 0)} />
        ) : null}
      </div>
    </div>
  );
};

export default Value;
