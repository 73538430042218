import { SourceProps } from 'react-map-gl';
import { formatNumber } from 'src/utils/numbers';
import { SOURCES } from '../const';

type Cluster = {
  latitude: number;
  longitude: number;
  count: number;
};

export default function getClustersSource(clusters: Cluster[]): SourceProps {
  return {
    id: SOURCES.MAP_CLUSTERS.name,
    type: SOURCES.MAP_CLUSTERS.type,
    data: {
      type: 'FeatureCollection',
      features: clusters.map((cluster) => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [cluster.longitude, cluster.latitude],
        },
        properties: {
          latitude: cluster.latitude,
          longitude: cluster.longitude,
          count: cluster.count,
          label: formatNumber(cluster.count, 1),
        },
      })),
    },
  };
}
