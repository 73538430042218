import React from 'react';

import grey from '@material-ui/core/colors/grey';
import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from '../../common';
import TimelineItemTopRowText from './TimelineItemTopRowText';
import TimelineItemBottomRowText from './TimelineItemBottomRowText';

const useStyles = makeStyles({
  root: {
    marginBottom: 10,
    '&:last-child': {
      marginBottom: 0,
    },
  },
});

const TimelineItem = ({ item, isNyc }) => {
  const classes = useStyles();

  return (
    <div
      className={`${classes.root} print-break-avoid`}
      style={{
        width: '100%',
        display: 'flex',
        minHeight: 50,
      }}
    >
      <div
        style={{
          backgroundColor: COLORS[item.type],
          width: 10,
          borderRadius: '6px 0 0 6px',
        }}
      />
      <div
        style={{
          width: '100%',
          borderRadius: '0 6px 6px 0',
          padding: 10,
          backgroundColor: grey[50],
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <TimelineItemTopRowText item={item} />
        <TimelineItemBottomRowText item={item} isNyc={isNyc} />
      </div>
    </div>
  );
};

export default TimelineItem;
