import { selectorFamily } from 'recoil';
import { merge } from 'src/utils/recoil/utils';
import { nycDetailsWhenAvailable, taxAssessorQuery } from '../../../recoil';
import { Tax_AssessorQueryVariables } from '../../../recoil/__generated__/taxAssessor';
import { TaxesData } from '../types';
import { nycDetailsToTaxesData, taxAssessorToTaxesData } from './formatters';

export const taxesData = selectorFamily<TaxesData, Tax_AssessorQueryVariables>({
  key: 'LOT-PAGE/TAXES-DATA',
  get:
    (params) =>
    ({ get }) => {
      const defaultValue: TaxesData = {
        assessedImprovements: '—',
        assessedLandValue: '—',
        assessedTaxYear: '—',
        currentTax: '—',
        jurisdiction: '—',
        marketImprovements: '—',
        marketLandValue: '—',
        marketValueYear: '—',
        totalAssessedValue: '—',
        totalMarketValue: '—',
      };

      const taxAssessorValue = get(taxAssessorQuery(params));
      const taxAssessorFormatted = taxAssessorToTaxesData(taxAssessorValue);

      const bbl = taxAssessorValue.tax_assessor_v2?.[0]?.cherre_bbl;

      const nycValue = get(nycDetailsWhenAvailable(bbl));
      const nycFormatted = nycDetailsToTaxesData(nycValue);

      return merge(defaultValue, taxAssessorFormatted, nycFormatted);
    },
});
