import { Typography } from '@material-ui/core';
import React from 'react';
import * as T from './types';

const KeyValue: React.FC<T.KeyValueProps> = ({ label, value }) => {
  if (!value) {
    return null;
  }
  return (
    <div>
      <Typography variant='body1'>{label}</Typography>
      <Typography
        variant='body1'
        className='suspend'
        style={{ fontWeight: 700 }}
      >
        {value}
      </Typography>
    </div>
  );
};

KeyValue.displayName = 'KeyValue';

export default KeyValue;
