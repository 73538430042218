import { SourceProps } from 'react-map-gl';
import { MapDeal } from '../types';

export const getDealsSource = (deals: MapDeal[]): SourceProps => {
  return {
    id: 'deals',
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: deals
        .filter((deal) => {
          return deal.latitude && deal.longitude && deal.taxAssessorId;
        })
        .map((deal) => ({
          type: 'Feature',
          id: deal.taxAssessorId,
          geometry: {
            type: 'Point',
            coordinates: [deal.longitude, deal.latitude],
          },
          properties: {
            stage: deal.stage,
            taxAssessorId: deal.taxAssessorId,
            cherreParcelId: deal.cherreParcelId,
          },
        })),
    },
  };
};
