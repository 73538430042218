import React from 'react';
import pluralize from 'pluralize';
import { grey } from '@material-ui/core/colors';
import Link from './Link';
import { useDispatch } from 'react-redux';
import { OTHER_LOT_RECORD_CLICKED } from 'src/products/shell/redux/middleware/segment';
import Title from './Title';
import { PropertyAddressSummary } from 'src/products/core-prospect/components/PropertyAddressSummary';
import { usaDataFormatter } from 'src/products/core-prospect/components/PropertyAddressSummary/dataFormatter/usaDataFormatter';
import { getLotPagePath } from '../../../utils';

const OtherTaxRecords: React.VFC<{
  count: number;
  tax_assessors: any[];
}> = ({ count, tax_assessors = [] }) => {
  const dispatch = useDispatch();

  if (tax_assessors?.length === 0) {
    return null;
  }

  return (
    <>
      <Title>
        {count - 1} Other Tax {pluralize('Record', count - 1)} on this Lot
      </Title>
      <div
        style={{
          border: `1px solid ${grey[300]}`,
          borderRadius: 10,
          overflow: 'hidden',
        }}
      >
        {tax_assessors.map((tax_assessor, index) => {
          const data = usaDataFormatter(tax_assessor);
          return (
            <PropertyAddressSummary
              key={index}
              buttonHref={getLotPagePath(
                data.parcelId || '',
                Number(data.taxAssessorId)
              )}
              buttonHrefExternal
              onClickContainer={() =>
                dispatch({ type: OTHER_LOT_RECORD_CLICKED })
              }
              property={data}
              showPropertySubtitle
              showPropertyLastSale
            />
          );
        })}
      </div>
      {count > 5 && (
        <Link
          to={`/properties/${tax_assessors[0].cherre_parcel_id}/${tax_assessors[0].tax_assessor_id}`}
        >
          View All
        </Link>
      )}
    </>
  );
};

export default OtherTaxRecords;
