import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { dealStageColorMap } from 'src/products/core-prospect/constants';

export type DealStageProps = {
  name: string;
  size?: 'normal' | 'small';
};

const DealStage: React.VFC<DealStageProps> = ({ name, size = 'normal' }) => {
  return (
    <Box display='flex' alignItems='center' height='17px'>
      <Box
        width={11}
        height={11}
        bgcolor={dealStageColorMap[name]}
        borderRadius='50%'
      />
      <Box ml='5px'>
        <Typography
          variant='body1'
          style={{ fontSize: size === 'small' ? '12px' : undefined }}
        >
          {name}
        </Typography>
      </Box>
    </Box>
  );
};

DealStage.displayName = 'DealStage';

export default DealStage;
