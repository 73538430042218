import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Page from './components/Page';
import StatsDrawer from './components/Stats/StatsDrawer';

import useIsCompact from 'src/hooks/useIsCompact';
import { useRecoilValue } from 'recoil';
import { demographicsFeatureAtom } from '../components/ReactMap/recoil';
import { useUpdateSearchResults } from './hooks/useUpdateSearchResults';
import { toggleSatelliteVisibility } from '../redux/map/actions';
import { useResetCoreExplore } from './hooks/useResetCoreExplore';

export const Context = React.createContext({});

const USALotSearch = () => {
  const dispatch = useDispatch();
  const defaultMapToSatelliteView = useSelector(
    (state) => state.user.profile.value.defaultMapToSatelliteView
  );

  useResetCoreExplore();

  // Set default satelite visibility according to organization settings
  useEffect(() => {
    dispatch(toggleSatelliteVisibility(defaultMapToSatelliteView));
  }, [defaultMapToSatelliteView]);

  useUpdateSearchResults();

  const isCompact = useIsCompact();

  const demographicsFeature = useRecoilValue(demographicsFeatureAtom);

  return (
    <Context.Provider value={{ isCompact }}>
      {demographicsFeature && <StatsDrawer isCompact={isCompact} />}
      <Page />
    </Context.Provider>
  );
};

export default USALotSearch;
