import { memoize } from 'lodash';

export const getLotPagePath = (
  cherreParcelId: string,
  taxAssessorId: number
) => {
  return `/properties/${cherreParcelId}/${taxAssessorId}`;
};

export function goToUsaLotPage(cherreParcelId: string, taxAssessorId: number) {
  if (!cherreParcelId || !taxAssessorId) {
    return;
  }

  window.open(getLotPagePath(cherreParcelId, taxAssessorId));
}

const getCurrentQuery = memoize((search) =>
  Object.fromEntries(Array.from(new URLSearchParams(search).entries()))
);

export const getLotPreviewPath = (
  cherreParcelId: string,
  taxAssessorId: number
) => {
  const currentQuery = getCurrentQuery(window.location.search);

  const newQuery = new URLSearchParams({
    ...currentQuery,
    preview_cherre_parcel_id: cherreParcelId || '',
    preview_tax_assessor_id: taxAssessorId.toString() || '',
    isLotPreviewOpen: 'true',
  });

  return `/properties?${newQuery.toString()}`;
};

export const Sectors = [
  {
    value: 'Apt',
    name: 'Apartment',
  },
  {
    value: 'Dis',
    name: 'Warehouse',
  },
  {
    value: 'Flx',
    name: 'Flex',
  },
  {
    value: 'Off',
    name: 'Office',
  },
  {
    value: 'Ret',
    name: 'Retail',
  },
];

export const SectorsMap = new Map<string, string>(
  Sectors.map((s) => [s.value, s.name])
);
