export const namespace = 'core-prospect-owners-table';

const ns = (name) => `${namespace}/${name}`;

export const TABLE_RESULTS_REQUEST = ns('TABLE_RESULTS_REQUEST');
export const TABLE_RESULTS_SUCCESS = ns('TABLE_RESULTS_SUCCESS');
export const TABLE_RESULTS_FAILED = ns('TABLE_RESULTS_FAILED');

export const TABLE_RESULTS_COUNT_REQUEST = ns('TABLE_RESULTS_COUNT_REQUEST');
export const TABLE_RESULTS_COUNT_SUCCESS = ns('TABLE_RESULTS_COUNT_SUCCESS');
export const TABLE_RESULTS_COUNT_FAILED = ns('TABLE_RESULTS_COUNT_FAILED');

export const CHANGE_OFFSET = ns('CHANGE_OFFSET');
export const CHANGE_ROWS_PER_PAGE = ns('CHANGE_ROWS_PER_PAGE');
export const CHANGE_ORDER_BY = ns('CHANGE_ORDER_BY');
