import { LABELS_FONT, LAYERS } from '../const';
import { LayerProps } from 'src/components/Map/types';
import { Visibility } from 'mapbox-gl';

type GetLayerArg = {
  visibility: Visibility;
};

function getLines({ visibility }: GetLayerArg): LayerProps {
  return {
    id: LAYERS.ZIPCODES_LINE,
    source: 'census',
    'source-layer': 'census-zi',
    type: 'line',
    paint: {
      'line-color': 'hsl(200, 80%, 50%)',
      'line-width': ['interpolate', ['linear'], ['zoom'], 7, 0.5, 12, 1],
    },
    layout: {
      visibility,
    },
  };
}

function getFill({ visibility }: GetLayerArg): LayerProps {
  return {
    id: LAYERS.ZIPCODES_FILL,
    source: 'census',
    'source-layer': 'census-zi',
    type: 'fill',
    paint: {
      'fill-color': 'hsla(200, 100%, 66%, 0)',
    },
    layout: {
      visibility,
    },
  };
}

function getSelectedLines({ zipcodes }: { zipcodes: string[] }): LayerProps {
  return {
    id: LAYERS.SELECTED_ZIPCODES_LINE,
    source: 'census',
    'source-layer': 'census-zi',
    type: 'line',
    paint: {
      'line-color': 'hsl(200, 100%, 66%)',
      'line-width': 5,
    },
    filter: ['in', ['get', 'GEO_ID'], ['literal', zipcodes]],
  };
}

function getSelectedFill({ zipcodes }: { zipcodes: string[] }): LayerProps {
  return {
    id: LAYERS.SELECTED_ZIPCODES_FILL,
    source: 'census',
    'source-layer': 'census-zi',
    type: 'fill',
    paint: {
      'fill-color': 'hsla(200, 100%, 66%, 0.3)',
    },
    filter: ['in', ['get', 'GEO_ID'], ['literal', zipcodes]],
  };
}

function getLabels({ visibility }: GetLayerArg): LayerProps {
  return {
    id: LAYERS.ZIPCODES_LABEL,
    source: 'census',
    'source-layer': 'census-zi-labels',
    type: 'symbol',
    minzoom: 7,
    layout: {
      'text-field': '{GEO_ID}',
      'text-size': 24,
      'text-allow-overlap': true,
      'text-ignore-placement': true,
      'text-font': LABELS_FONT,
      visibility,
    },
    paint: {
      'text-color': 'hsl(200, 80%, 30%)',
      'text-halo-color': '#eee',
      'text-halo-width': 2,
    },
  };
}

type GetLayersArg = GetLayerArg & { zipcodes: string[] };

function getZipcodesLayers({
  zipcodes,
  visibility,
}: GetLayersArg): LayerProps[] {
  return [
    getFill({ visibility }),
    getLines({ visibility }),
    getSelectedLines({ zipcodes }),
    getSelectedFill({ zipcodes }),
    getLabels({ visibility }),
  ];
}

export default getZipcodesLayers;
