import * as REIS_SUBMARKETS_TYPES from '../../reisSubmarkets/types';

import {
  getSearchParameters,
  patchByCondoUnitsSearchParameter,
} from 'src/products/core-prospect/search-pages/selectors';
import { REIS_SUBMARKETS_ID_FIELD } from '../../reisSubmarkets/actions';
import { getFeature } from '../actions';

type ExecTableReisSubmarketsQuery = {
  viewportOnly: boolean;
  paginate: boolean;
  isCount: boolean;
  size?: number;
  isExport: boolean;
};

export const execTableReisSubmarketsQuery = ({
  viewportOnly,
  paginate,
  isCount,
  size,
  isExport,
}: ExecTableReisSubmarketsQuery) => {
  return async (dispatch, getState) => {
    const timestamp = Date.now();
    const state = getState();
    const searchParameters = getSearchParameters(state);
    const orderBy = state.coreProspect.reisSubmarkets.orderBy;
    const { after, before, rowsPerPage } = state.coreProspect.reisSubmarkets;

    const filters = patchByCondoUnitsSearchParameter(state, searchParameters);
    const areDealsFiltersEnabled =
      getState().coreProspect.query.areDealsFiltersEnabled;

    let listId = getState().router.location.query.list_id;

    if (Number.isNaN(Number(listId))) {
      listId = undefined;
    }

    return dispatch({
      url: `/search/usa-lots?${
        isCount ? 'reisSubmarketsCount' : 'reisSubmarkets'
      }`,
      method: 'post',
      cancelable: true,
      send: {
        areDealsFiltersEnabled,
        listId: isExport ? undefined : listId,
        isReisSubmarkets: true,
        isReisSubmarketsCount: isCount,
        after: paginate ? after?.[REIS_SUBMARKETS_ID_FIELD] : undefined,
        before: paginate ? before?.[REIS_SUBMARKETS_ID_FIELD] : undefined,
        filters,
        feature: getFeature({
          viewportOnly,
          state,
        }),
        size: size || rowsPerPage,
        sort: orderBy
          ? {
              direction: orderBy.direction,
              fields: [orderBy.field],
            }
          : undefined,
      },
      dataToDispatch: { timestamp },
      types: isExport
        ? []
        : isCount
        ? [
            REIS_SUBMARKETS_TYPES.TABLE_RESULTS_COUNT_REQUEST,
            REIS_SUBMARKETS_TYPES.TABLE_RESULTS_COUNT_SUCCESS,
            REIS_SUBMARKETS_TYPES.TABLE_RESULTS_COUNT_FAILED,
          ]
        : [
            REIS_SUBMARKETS_TYPES.TABLE_RESULTS_REQUEST,
            REIS_SUBMARKETS_TYPES.TABLE_RESULTS_SUCCESS,
            REIS_SUBMARKETS_TYPES.TABLE_RESULTS_FAILED,
          ],
    });
  };
};
