import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { MAX_WIDTH } from './common';

const TimelineLoading = () => {
  return (
    <div
      style={{
        maxWidth: MAX_WIDTH,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </div>
  );
};

export default TimelineLoading;
