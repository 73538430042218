export default [
  {
    name: 'Summary',
    id: 'lot-page-summary',
  },
  {
    name: 'Lot Size',
    id: 'lot-page-lot-size',
  },
  {
    name: 'Taxes',
    id: 'lot-page-taxes',
  },
  {
    name: 'Property Details',
    id: 'lot-page-property-details',
  },
  {
    name: 'Location',
    id: 'lot-page-location',
  },
  {
    name: 'Demographics',
    id: 'lot-page-demographics',
  },
  {
    name: 'Commercial Sale Comps',
    id: 'lot-page-commercial-sale-comps',
  },
  {
    name: 'Property Owners',
    id: 'lot-page-property-owners',
  },
  {
    name: 'Timeline',
    id: 'lot-page-timeline',
  },
  {
    name: 'Leases',
    id: 'lot-page-leases',
  },
];
