import React from 'react';
import { Button, Tooltip } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { useStyles } from './style';

export const CloseButton: React.VFC<{
  onClose: () => void;
}> = ({ onClose }) => {
  const classes = useStyles();

  return (
    <Tooltip title='Close'>
      <Button
        variant='contained'
        size='small'
        className={classes.button}
        onClick={onClose}
      >
        <Close className={classes.icon} color='primary' />
      </Button>
    </Tooltip>
  );
};
