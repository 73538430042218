import { Box } from '@material-ui/core';
import { DealStage } from '..';
import React from 'react';

type DealStageChipProps = {
  name: string;
  size?: 'normal' | 'small';
  bgcolor?: string;
  color?: string;
};

const DealStageChip: React.VFC<DealStageChipProps> = ({
  name,
  size = 'normal',
  bgcolor = '#212121',
  color = '#FFFFFF',
}) => {
  const padding = size === 'normal' ? '6px 10px' : '2px 5px';
  return (
    <Box display='inline-block'>
      <Box
        bgcolor={bgcolor}
        color={color}
        display='flex'
        justifyContent='center'
        alignItems='center'
        borderRadius='20px'
        padding={padding}
        whiteSpace='nowrap'
      >
        <DealStage name={name} size={size} />
      </Box>
    </Box>
  );
};

DealStageChip.displayName = 'DealStageChip';

export default DealStageChip;
