import React from 'react';
import { Typography } from '@material-ui/core';
import Stats from 'src/products/core-prospect/search-pages/components/Stats/Stats';
import useStyles from './styles';
import { useIsSuspended } from 'src/utils/suspense-companion/createSuspenseComponent';
import { MultiColumnRow } from '../../components';
import * as T from './types';
import { panels } from '../../constants';

const Demographics: T.Demographics = (props) => {
  const isSuspended = useIsSuspended();
  const classes = useStyles();
  return (
    <div id={panels[5].id} className={classes.container}>
      <Typography variant='h4' style={{ marginBottom: '25px' }}>
        {`Demographics in ${props.data?.lot_address?.zip || ''}`}
      </Typography>
      <MultiColumnRow columns={3}>
        {isSuspended ? (
          <>
            <div className={`${classes.skeleton} suspend`} />
            <div className={`${classes.skeleton} suspend`} />
            <div className={`${classes.skeleton} suspend`} />
            <div className={`${classes.skeleton} suspend`} />
            <div className={`${classes.skeleton} suspend`} />
          </>
        ) : (
          <Stats
            panelData={props.stats}
            panelsOrder={[0, 4, 2, 3, 1]}
            allowPanelsToCollapse={false}
          />
        )}
      </MultiColumnRow>
    </div>
  );
};

Demographics.displayName = 'Demographics';

export default Demographics;
