type AllDefined = (
  templates: TemplateStringsArray,
  ...values: any[]
) => string | undefined;
export const allDefined: AllDefined = (templateFragments, ...values) => {
  if (values.some((v) => !v)) {
    return undefined;
  }
  return templateFragments.reduce((acc, cur, idx) => {
    return `${acc}${cur}${values[idx]?.toString?.() || ''}`;
  }, '');
};
