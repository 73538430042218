import axios from 'axios';
import { atom, selectorFamily } from 'recoil';

export type AutocompleteSuggestion = {
  group_name: AutocompleteSuggestionGroup;
  text: string;
  id: string;
  data: Record<string, any>;
};

export enum AutocompleteSuggestionGroup {
  Address = 'Address',
  Street = 'Street',
  Intersection = 'Intersection',
  City = 'City',
  State = 'State',
  County = 'County',
  ZipCode = 'ZipCode',
  MSA = 'MSA',
  REISMarket = 'REISMarkets',
  REISSubMarket = 'REISSubMarkets',
}

export type AutocompleteSuggestionOptions = Partial<{
  origin: {
    lat: number;
    lng: number;
  };
}>;

export const suggestionsSelector = selectorFamily<
  AutocompleteSuggestion[],
  { text: string; dontGroup: string; options?: AutocompleteSuggestionOptions }
>({
  get:
    ({ text, dontGroup, options }) =>
    async () => {
      if (!text) {
        return [];
      }
      const url = '/api/v1/autocomplete';
      const response = await axios.post<AutocompleteSuggestion[]>(url, {
        text,
        dontGroup: dontGroup ? true : false,
        options,
      });
      return response.data || [];
    },
  key: 'AUTOCOMPLETE_SUGGESTIONS_SELECTOR',
});

export const autocompletValueAtom = atom<AutocompleteSuggestion | null>({
  key: 'AUTOCOMPLETE_VALUE',
  default: null,
});
