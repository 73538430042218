import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { teamOrganizationMembersSelectorFamily } from '../recoil';
import { useDebouncedValue } from './useDebouncedValue';
import { useLoadable } from './useLoadable';

export const useTeamMembersAutocomplete = ({
  currentMembers = [],
  limit,
}: {
  currentMembers?: number[];
  limit?: number;
}) => {
  const [searchText, searchTextDelayed, setSearchText] = useDebouncedValue('');

  const [value, state] = useLoadable(
    [],
    teamOrganizationMembersSelectorFamily({
      text: searchTextDelayed,
    })
  );

  const isLoading = state === 'loading';

  const currentUserId = useSelector((state) => state.user.profile.value.id);
  const currentMembersFilter = [...currentMembers, currentUserId];

  const options = useMemo(() => {
    const opts = value.filter((v) => !currentMembersFilter.includes(v.id));

    if (!limit) {
      return opts;
    }

    return opts.slice(0, limit);
  }, [value, limit]);

  return {
    searchText,
    setSearchText,
    isLoading,
    options,
  };
};
