import React from 'react';

const ExternalLeaseCompIcon = (props) => {
  return (
    <svg
      {...props}
      width='22'
      height='26'
      viewBox='0 0 22 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11 0.5C5.4775 0.5 1 4.9775 1 10.5C1 16.0225 11 25.5 11 25.5C11 25.5 21 16.0225 21 10.5C21 4.9775 16.5225 0.5 11 0.5Z'
        fill='#00D756'
        stroke='white'
        strokeWidth='0.6'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.25 16.5038V18H9.75V16.5038C8.3275 15.9625 7.25 14.5625 7.25 13H9.75C9.75 13.6188 10.3812 14.25 11 14.25C11.6188 14.25 12.25 13.6188 12.25 13C12.25 12.7487 11.5475 12.55 10.6962 12.3387C9.4075 12.0162 7.25 11.4763 7.25 9.25C7.25 7.6875 8.3275 6.28875 9.75 5.7475V4.25H12.25V5.7475C13.6712 6.28875 14.7462 7.68875 14.7462 9.25H12.2462C12.2462 8.63125 11.6175 8 11 8C10.3812 8 9.75 8.63125 9.75 9.25C9.75 9.50375 10.4538 9.70125 11.3038 9.9125C12.5925 10.235 14.75 10.775 14.75 13C14.75 14.5625 13.6738 15.9625 12.25 16.5038Z'
        fill='white'
      />
    </svg>
  );
};

ExternalLeaseCompIcon.displayName = 'ExternalLeaseCompIcon';

export default ExternalLeaseCompIcon;
