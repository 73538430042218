import React from 'react';
import useLookups from 'src/hooks/useLookups';
import numberWithCommas from 'src/utils/numberWithCommas';
import { LitsItemParcel } from './ListItemParcel';
import StreetSide from './StreetSide';
import LotSummary from './LotSummary';
import LitsItemTaxAssessor from './ListItemTaxAssessor';
import { uniqBy } from 'lodash';
import { Typography } from '@material-ui/core';
import EmptyResults from '../../EmptyResults';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import grey from '@material-ui/core/colors/grey';

const List = ({
  tax_assessors = [],
  onSelectTaxAssessor,
  selectedParcelId,
  showBackButton,
  emptyResultsText = 'No properties found for this owner.',
}) => {
  const { getListNameLookups } = useLookups();

  if (tax_assessors.length === 0) {
    return (
      <div>
        {showBackButton ? (
          <IconButton
            onClick={() => onSelectTaxAssessor(null)}
            style={{
              position: 'relative',
              left: 5,
              top: 5,
              marginBottom: 15,
              background: '#fff',
              zIndex: 1,
              boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.2)',
            }}
          >
            <ArrowBack style={{ color: grey[800] }} />
          </IconButton>
        ) : null}
        <EmptyResults text={emptyResultsText} />
      </div>
    );
  }

  const parcelUnits = tax_assessors.filter(
    (ta) => ta.cherre_parcel_id === selectedParcelId && ta.unit_number
  );

  const parcelLots = tax_assessors.filter(
    (ta) => ta.cherre_parcel_id === selectedParcelId && !ta.unit_number
  );

  const uniqTaxAssessors = uniqBy(tax_assessors, (ta) => ta.cherre_parcel_id);

  const formatTaxAssessor = (tax_assessor) => {
    if (!tax_assessor) {
      return;
    }

    return {
      ...tax_assessor,
      property_use_code_mapped: getListNameLookups(
        'cherre_property_use_hierarchical',
        tax_assessor.property_use_code_mapped
      ),
      size:
        tax_assessor.lot_size_sq_ft &&
        `${numberWithCommas(tax_assessor.lot_size_sq_ft)} SQFT`,
    };
  };

  const taxAssessorForSummary = formatTaxAssessor(parcelLots[0]);

  const lotSummary = {
    address1: taxAssessorForSummary?.address,
    address2: [
      taxAssessorForSummary?.city,
      taxAssessorForSummary?.state,
      taxAssessorForSummary?.zip,
    ]
      .filter(Boolean)
      .join(', '),
    class_description: taxAssessorForSummary?.property_use_code_mapped,
  };

  return (
    <>
      {selectedParcelId && (
        <>
          <StreetSide
            latitude={taxAssessorForSummary?.latitude}
            longitude={taxAssessorForSummary?.longitude}
            onBackClick={() => onSelectTaxAssessor(null)}
          />
          <LotSummary
            property_ids={[
              ...parcelLots.map((item) => item.tax_assessor_id),
              ...parcelUnits.map((item) => item.tax_assessor_id),
            ]}
            lotSummary={lotSummary}
          />
          {parcelLots.length > 0 && (
            <>
              <Typography style={{ padding: '18px 0' }} variant='h4'>
                Lots
              </Typography>
              {parcelLots.map((tax_assessor, index) => {
                return (
                  <LitsItemTaxAssessor
                    key={index}
                    onClick={() => {}}
                    tax_assessor={formatTaxAssessor(tax_assessor)}
                  />
                );
              })}
            </>
          )}
          {parcelUnits.length > 0 && (
            <>
              <Typography style={{ padding: '18px 0' }} variant='h4'>
                Units
              </Typography>
              {parcelUnits.map((tax_assessor, index) => {
                return (
                  <LitsItemTaxAssessor
                    key={index}
                    onClick={() => {}}
                    tax_assessor={formatTaxAssessor(tax_assessor)}
                  />
                );
              })}
            </>
          )}
        </>
      )}
      {!selectedParcelId &&
        uniqTaxAssessors.map((tax_assessor, index) => {
          return (
            <LitsItemParcel
              key={index}
              onClick={onSelectTaxAssessor}
              tax_assessor={{
                ...formatTaxAssessor(tax_assessor),
                address: (tax_assessor.address || '').replace(/#.*/, ''),
              }}
            />
          );
        })}
    </>
  );
};

export default List;
