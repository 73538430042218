import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles(() => ({
  root: {
    borderBottom: `1px solid ${grey[400]}`,
  },
}));

export default function SimpleTabs({ tab, changeTab, tabs }) {
  const handleChange = (event, newValue) => {
    changeTab(newValue);
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Tabs
        style={{
          position: 'relative',
          bottom: -1,
        }}
        variant='scrollable'
        scrollButtons='auto'
        value={tab}
        onChange={handleChange}
        indicatorColor='primary'
        textColor='primary'
      >
        {tabs.map((tab) => (
          <Tab key={tab} value={tab} label={tab} />
        ))}
      </Tabs>
    </div>
  );
}
