import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { primaryColor } from 'src/const/theme';

const useStyles = makeStyles({
  lot: {
    background: '#fafafa',
    borderRadius: 5,
    padding: 10,
    marginTop: 10,
    cursor: 'pointer',
    minHeight: 66,
    '&:hover': {
      background: '#eee',
    },
  },
});

const Lot = ({ title, subtitle }) => {
  const classes = useStyles();

  return (
    <div className={classes.lot}>
      <Typography
        variant='body1'
        style={{
          color: primaryColor.main,
          fontSize: 16,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {title}
        <ArrowForward style={{ height: 16, width: 16 }} />
      </Typography>
      <Typography variant='body1'>{subtitle}</Typography>
    </div>
  );
};

export default Lot;
