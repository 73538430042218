import React from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { useRecoilState } from 'recoil';
import { propertyClassAtom } from './recoil';
import useStyles from './styles';
import NearbyLeases from './NearbyLeases';

const NearbyLeasesContainer: React.VFC = () => {
  const [propertyClass, setPropertyClass] = useRecoilState(propertyClassAtom);
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography variant='h5'>Nearby Leases</Typography>
        <Typography variant='body2'>
          Averages with changes over the past year.
        </Typography>
      </div>
      <div>
        <FormControl size='small' variant='outlined' style={{ minWidth: 150 }}>
          <InputLabel>Property Class</InputLabel>
          <Select
            value={propertyClass}
            label='Property Class'
            onChange={(event) => setPropertyClass(event.target.value as any)}
          >
            <MenuItem value={'Office'}>Office</MenuItem>
            <MenuItem value={'Retail'}>Retail</MenuItem>
            <MenuItem value={'Industrial'}>Industrial</MenuItem>
          </Select>
        </FormControl>
      </div>
      <NearbyLeases />
    </div>
  );
};

NearbyLeasesContainer.displayName = 'NearbyLeases';

export default NearbyLeasesContainer;
