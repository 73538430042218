import { GQL, gql } from 'src/utils/recoil/gql';
import * as T from './__generated__/zipcodes';

export default gql`
  query getZipcodes($zipcodes: [String!], $vintage: String!) {
    usa_demographics_v2(
      where: {
        geography_type: { _eq: "ZI" }
        geography_code: { _in: $zipcodes }
        vintage: { _eq: $vintage }
      }
    ) {
      median_household_income
      crime_total_risk
      population_median_age
      geography_code
    }
  }
` as GQL<T.GetZipcodesQuery, T.GetZipcodesQueryVariables>;
