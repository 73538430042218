import React from 'react';

import AutocompleteCoreProspectInput from './AutocompleteInputs/AutocompleteCoreProspectInput';
import AutocompleteHomePageInput from './AutocompleteInputs/AutocompleteHomePageInput';

const renderInput = (props, customProps = {}) => {
  return customProps?.location === '/apps' ? (
    <AutocompleteHomePageInput {...props} customProps={customProps} />
  ) : (
    <AutocompleteCoreProspectInput {...props} customProps={customProps} />
  );
};

export default renderInput;
