import { gql, GQL } from 'src/utils/recoil/gql';
import {
  CompstakLeasesQuery,
  CompstakLeasesQueryVariables,
} from './__generated__/compstakLeases';

export const compstakLeases = gql`
  query CompstakLeases($tax_assessor_id: numeric) {
    tax_assessor_v2(where: { tax_assessor_id: { _eq: $tax_assessor_id } }) {
      cherre_address__property_address {
        compstak_leases__address(distinct_on: compstak_id) {
          floors_occupied
          transaction_sq_ft
          tenant_name
          starting_rent_per_sq_ft
          net_effective_rent
          lease_term
          commencement_date
          free_rent_month_count
          work_value
          execution_date
          expiration_date
          rent_increase_schedule
        }
      }
    }
  }
` as GQL<CompstakLeasesQuery, CompstakLeasesQueryVariables>;
