export const getAggregatedPropertyDetails = (
  allProperties,
  lookupsByListName
) => {
  const {
    parcelIdGroups,
    countByPropertyType,
    countByCity,
    relatedOwners,
    totalLotSize,
  } = allProperties.reduce(
    (acc, next) => {
      const property_use_code_mapped =
        lookupsByListName && lookupsByListName.cherre_property_use_hierarchical
          ? lookupsByListName.cherre_property_use_hierarchical.find(
              (item) =>
                item.lookup_code === next.property_use_code_mapped.toString()
            )?.lookup_value
          : next.property_use_code_mapped;

      // Count by property type
      if (!acc.countByPropertyType[property_use_code_mapped]) {
        acc.countByPropertyType[property_use_code_mapped] = 0;
      }

      acc.countByPropertyType[property_use_code_mapped] += 1;

      if (!acc.parcelIdGroups[next.cherre_parcel_id]) {
        acc.parcelIdGroups[next.cherre_parcel_id] = [];

        // Count by city
        const city =
          next.city &&
          next.state &&
          next.city !== 'null' &&
          next.state !== 'null'
            ? `${next.city}, ${next.state}`
            : 'OTHER';

        if (!acc.countByCity[city]) {
          acc.countByCity[city] = 0;
        }

        acc.countByCity[city] += 1;

        // Count total lot size
        const lotSize = Number(next.gross_sq_ft);

        if (!Number.isNaN(lotSize)) {
          acc.totalLotSize += lotSize;
        }
      }

      acc.parcelIdGroups[next.cherre_parcel_id].push(next);

      // Organize related owners
      for (const relatedOwner of next.unmasked_owners) {
        if (!acc.relatedOwners[relatedOwner.owner_id]) {
          const lotAddress = {
            street_name:
              next.cherre_address__property_address?.[0]?.street_address,
            city: next.city,
            state: next.state,
          };
          acc.relatedOwners[relatedOwner.owner_id] = {
            id: relatedOwner.owner_id,
            name: relatedOwner.owner_name,
            type: relatedOwner.owner_type,
            commonProperties: new Set(),
            lotAddress,
          };
        }

        acc.relatedOwners[relatedOwner.owner_id].commonProperties.add(
          next.cherre_parcel_id
        );
      }

      return acc;
    },
    {
      parcelIdGroups: {},
      countByPropertyType: {},
      countByCity: {},
      relatedOwners: {},
      totalLotSize: 0,
    }
  );

  return {
    parcelIdGroups,
    countByPropertyType,
    countByCity,
    relatedOwners,
    totalLotSize,
  };
};

const getPieChartDataObject = (values, labels) => {
  const colors = {
    0: '#ff6294',
    1: '#6e0c55',
    2: '#ce75b3',
    3: '#4897f0',
    4: '#d3b41b',
  };

  return {
    values: values || [],
    labels: labels || [],
    type: 'pie',
    textinfo: 'none',
    automargin: true,
    marker: {
      colors: values.map((value, index) => colors[index]),
    },
  };
};

export const getPieChartData = (countByPropertyType) => {
  const values = Object.values(countByPropertyType);
  const keys = Object.keys(countByPropertyType);
  const total = values.reduce((value, acc) => value + acc, 0);
  const percentages = values.map((value) => {
    const percentage = value / total;
    return Math.round((percentage + Number.EPSILON) * 1000) / 1000;
  });
  const data = getPieChartDataObject(percentages, keys);
  return [data];
};
