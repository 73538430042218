import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import Info from '@material-ui/icons/Info';
import { grey } from '@material-ui/core/colors';
import { useParams } from 'react-router';

const useStyles = makeStyles({
  root: {
    padding: '40px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    color: grey[400],
    marginBottom: 5,
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
  },
  desc: {
    paddingLeft: 50,
    paddingRight: 50,
    textAlign: 'center',
  },
});

export const EmptryList = () => {
  const classes = useStyles();

  const { id: dealId } = useParams<{ id: string }>();

  return (
    <div className={classes.root}>
      <Info className={classes.icon} />
      <Typography className={classes.title} gutterBottom>
        No properties yet
      </Typography>
      <Typography className={classes.desc}>
        At least 2 properties required to {dealId === 'new' ? 'create' : 'edit'}{' '}
        a Portfolio Deal
      </Typography>
    </div>
  );
};
