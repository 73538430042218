import { useCallback, useMemo, useState } from 'react';
import { RootState, useDispatch, useSelector } from 'react-redux';
import { useRecoilState } from 'recoil';
import { useOrganizationPropsValue } from 'src/hooks';
import { useLookupsByTableNames } from 'src/hooks/useLookupsByTableName';
import {
  showErrorPopupNotification,
  showWarningPopupNotification,
} from 'src/products/shell/Notifications';
import { showModal } from 'src/products/shell/redux/modals/actions';
import { useAllDealsPortfolioAssetsByTaxId } from 'src/services/Dealcloud/hooks';
import { userExportFilesLimitAtom } from '../recoil';
import { csvExportFailed, csvWasExported } from '../redux/table/actions';
import exportTable from '../search-pages/components/ResultsTableView/CoreProspectResultsTableNav/exportTable';
import getColumns from '../search-pages/components/ResultsTableView/getColumns';

const DEFAULT_TOOLTIP_MESSAGE = 'Select Properties to Export';
const PROPERTIES_SELECTED_TOOLTIP_MESSAGE = 'Export Properties';
const MAX_ROWS_MESSAGE = (rows: number) =>
  `Only the first ${rows} records will be exported due to your export limit.`;
const MAX_DOWNLOADS_MESSAGE =
  'You have reached the max file export limit for the day. Please try again tomorrow.';

export const useExportSelectedRows = () => {
  const { rowExportLimit, selectedRows, fileExportLimit } = useSelector(
    (state: RootState) => ({
      rowExportLimit: state.user.profileForm?.value?.rowExportLimit,
      fileExportLimit: state.user.profileForm?.value?.fileExportLimit,
      selectedRows: state.coreProspect.table.selectedRows,
    })
  );

  const isDealsIntegrationAvailable =
    useOrganizationPropsValue('deals_enabled');

  const areDealsFiltersEnabled = useSelector(
    (state) => state.coreProspect.query.areDealsFiltersEnabled
  );

  const deals = isDealsIntegrationAvailable && areDealsFiltersEnabled;
  const dealsInfo = useAllDealsPortfolioAssetsByTaxId({});

  const lookupsByTableNames = useLookupsByTableNames();

  const exportSettings = useMemo(
    () => ({ columns: getColumns({ lookupsByTableNames, deals, dealsInfo }) }),
    [lookupsByTableNames, deals, dealsInfo]
  );

  const [isExporting, setIsExporting] = useState(false);

  const [userExportHistory, setUserExportHistory] = useRecoilState(
    userExportFilesLimitAtom
  );

  const isFilesExportLimited = !!fileExportLimit;
  const remainingExports = isFilesExportLimited
    ? fileExportLimit - userExportHistory.count
    : 1;

  const dispatch = useDispatch();

  const onClickExport = useCallback(async () => {
    try {
      if (isFilesExportLimited && remainingExports <= 0) {
        dispatch(csvExportFailed('Limit was reached'));

        showWarningPopupNotification(MAX_DOWNLOADS_MESSAGE);
        return;
      }

      setIsExporting(true);

      dispatch(
        showModal(
          'CORE_PROSPECT_EXPORT_TABLE',
          {
            runExport: (includeOwnerData) => {
              exportTable(
                selectedRows,
                exportSettings,
                rowExportLimit,
                includeOwnerData,
                dispatch
              );
            },
          },
          () => {}
        )
      );

      dispatch(csvWasExported(remainingExports));

      setUserExportHistory((state) => ({ ...state, count: state.count + 1 }));
    } catch (err: any) {
      console.log(err);
      dispatch(csvExportFailed(err.message));
      showErrorPopupNotification('Export failed. Please try again.');
    }

    setIsExporting(false);
  }, [
    remainingExports,
    setUserExportHistory,
    isFilesExportLimited,
    selectedRows,
    rowExportLimit,
    setIsExporting,
  ]);

  const tooltipTitle = useMemo(() => {
    if (isFilesExportLimited && remainingExports <= 0) {
      return MAX_DOWNLOADS_MESSAGE;
    }

    if (!!rowExportLimit && selectedRows.length > rowExportLimit) {
      return MAX_ROWS_MESSAGE(rowExportLimit);
    }

    if (selectedRows.length > 0) {
      return PROPERTIES_SELECTED_TOOLTIP_MESSAGE;
    }

    return DEFAULT_TOOLTIP_MESSAGE;
  }, [selectedRows, rowExportLimit, isFilesExportLimited, remainingExports]);

  return {
    isExporting,
    isExportDisabled:
      isExporting || selectedRows.length === 0 || remainingExports <= 0,
    onClickExport,
    tooltipTitle,
  };
};
