import * as FILTER_TYPES from 'src/components/filters/const/filter-types';

import RangeSelection from 'src/components/filters/RangeSelection';
import RangeInput from 'src/components/filters/RangeInput';
import RangeDate from 'src/components/filters/RangeDate';
import TextInput from 'src/components/filters/TextInput';
import MultipleSelection from './MultipleSelection';
import BooleanSelection from 'src/components/filters/BooleanSelection';
import HierarchicalSelection from 'src/components/filters/HierarchicalSelection';
import Select from 'src/components/filters/Select';
import RecordedOwnerTypesSelection from './RecordedOwnerTypesSelection';
import UnmaskedOwnerTypesSelection from './UnmaskedOwnerTypesSelection';
import SectorsSelection from './SectorsSelection';
import DealFilter from './DealFilter';

const filters = {
  [FILTER_TYPES.RANGE_SELECTION]: RangeSelection,
  [FILTER_TYPES.RANGE_INPUT]: RangeInput,
  [FILTER_TYPES.RANGE_DATE]: RangeDate,
  [FILTER_TYPES.TEXT_INPUT]: TextInput,
  [FILTER_TYPES.MULTIPLE_SELECTION]: MultipleSelection,
  [FILTER_TYPES.BOOLEAN_SELECTION]: BooleanSelection,
  [FILTER_TYPES.HIERARCHICAL_SELECTION]: HierarchicalSelection,
  [FILTER_TYPES.SELECT_FILTER]: Select,
  [FILTER_TYPES.RECORDED_OWNER_TYPES_SELECTION]: RecordedOwnerTypesSelection,
  [FILTER_TYPES.UNMASKED_OWNER_TYPES_SELECTION]: UnmaskedOwnerTypesSelection,
  [FILTER_TYPES.SECTORS_SELECTION]: SectorsSelection,
  [FILTER_TYPES.DEAL_FILTER]: DealFilter,
};

export function getFilterComponent(filter) {
  const filterType = Object.prototype.hasOwnProperty.call(filter, 'filter_type')
    ? filter.filter_type
    : filter;
  return filters[filterType].component;
}
