import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FilterGroup from './FilterGroup';

const FilterGroups = ({
  sortedFilterGroups,
  classes,
  filterGroups,
  onBlur,
}) => {
  return (
    <div className={classes.filters}>
      {sortedFilterGroups.map((group, index) => (
        <FilterGroup
          index={index}
          key={index}
          filterGroups={filterGroups}
          group={group}
          onBlur={onBlur}
        />
      ))}
    </div>
  );
};

const styles = () => ({
  filters: {
    flex: 1,
    overflow: 'auto',
  },
});

export default withStyles(styles)(FilterGroups);
