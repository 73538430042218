import React from 'react';
import throttle from 'lodash/throttle';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import NavItem from './NavItem';
import TimelineIcon from '@material-ui/icons/Timeline';
import Typography from '@material-ui/core/Typography';
import { panels } from '../../constants';
import useIsCompact from 'src/hooks/useIsCompact';
import useStyles from './styles';

const Navigation = ({ item }) => {
  const user = useSelector((state) => state.user.profile.value);
  const [activePanelId, makePanelActive] = React.useState(null);
  const findActiveElementId = (panels) => {
    if (panels.length === 0) {
      return null;
    }

    const bodyHeight = window.document.querySelector('body').offsetHeight;
    let activeId;

    for (let i = 0; i < panels.length; i++) {
      const node = document.querySelector(`#${panels[i].id}`);
      const { top } = node.getBoundingClientRect();

      if (top > 0 && top < bodyHeight / 3) {
        activeId = panels[i].id;
        break;
      }
    }

    return activeId;
  };

  const onScroll = throttle(() => {
    const activeElementId = findActiveElementId(panels);

    if (activeElementId !== undefined) {
      makePanelActive(activeElementId);
    }
  }, 100);

  const attachScrollHandler = () => {
    document
      .querySelector('#core-prospect-body-wrap')
      ?.addEventListener('scroll', onScroll);
  };
  const detachScrollHandler = () => {
    document
      .querySelector('#core-prospect-body-wrap')
      ?.removeEventListener('scroll', onScroll);
  };

  React.useEffect(() => {
    attachScrollHandler();

    return detachScrollHandler;
  }, [panels.length]);

  const isCompact = useIsCompact();

  const classes = useStyles();

  if (isCompact) {
    return null;
  }

  return (
    <div className={classes.container}>
      <div
        style={{ paddingLeft: 35, paddingTop: 15 }}
        id='core-prospect-data-nav-panel'
      >
        {panels.map((panel, index) => (
          <NavItem
            key={index}
            isActive={panel.id === activePanelId}
            panel={panel}
          />
        ))}
        <hr />
        {item && item.lot_address && user.featureFlags.marketAnalytics && (
          <div
            style={{ marginLeft: 16, alignItems: 'center', display: 'flex' }}
          >
            <TimelineIcon
              style={{
                height: 19,
                color: '#df2467',
                marginRight: 11,
              }}
            />
            <Link
              to={`/markets/${item.lot_address.city}/${item.lot_address.state}`}
              style={{
                color: '#df2467',
              }}
            >
              <Typography variant='button'>Market Analytics</Typography>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navigation;
