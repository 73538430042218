import { GetOwnersQuery } from '../queries/__generated__/getOwners';
import { BasicOwner } from '../types';
import { getContacts } from './getContacts';

export const getRows = (
  owners: BasicOwner[],
  unmaskedOwners: GetOwnersQuery['usa_owner_unmask_v2']
) => {
  const rows = owners.map((owner) => {
    const filtererd_usa_owner_unmask = unmaskedOwners.filter(
      (unmaskedOwner) => {
        return unmaskedOwner.owner_id === owner.ownerId;
      }
    );

    const totalTaxRecordsCount = filtererd_usa_owner_unmask.length || null;

    const contacts = getContacts(owner.ownerId, unmaskedOwners);

    const firstTaxAssessor = filtererd_usa_owner_unmask[0]?.tax_assessor[0];

    const street = firstTaxAssessor?.address || undefined;
    const city = firstTaxAssessor?.city || undefined;
    const state = firstTaxAssessor?.state || undefined;
    const name = filtererd_usa_owner_unmask[0]?.owner_name;

    const totalTaxRecords = filtererd_usa_owner_unmask.map(
      (entry) => entry.tax_assessor[0]
    );

    return {
      ...owner,
      totalTaxRecords,
      totalTaxRecordsCount,
      address: contacts.address,
      name,
      street,
      city,
      state,
    };
  });

  return rows;
};
