import React from 'react';

import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import { makeStyles } from '@material-ui/core/styles';

import useLookups from 'src/hooks/useLookups';
import { TYPE_BOOLEAN } from './common';
import theme from 'src/const/theme';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    marginBottom: 20,
    '&:last-child': {
      marginBottom: 0,
    },
  },
});

const RightPanelItem = ({ lotData, label, details }) => {
  const classes = useStyles();
  const { getFieldLookups } = useLookups();

  return (
    <div className={classes.root}>
      <CheckIcon
        style={{ color: theme.palette.success.main, marginRight: 10 }}
      />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant='h6' style={{ fontSize: 14 }}>
          {label}
        </Typography>
        {details
          .filter((detail) => Boolean(detail.label))
          .map((detail, i) => {
            const value = lotData[detail.field];
            const text =
              detail.type === TYPE_BOOLEAN
                ? detail.label
                : `${detail.label}: ${
                    detail.format
                      ? detail.format(value, detail.field, getFieldLookups)
                      : value
                  }`;

            return <Typography key={i}>{text}</Typography>;
          })}
      </div>
    </div>
  );
};

export default RightPanelItem;
