import { GQL, gql, paginate, AllAssigned } from 'src/utils/recoil/gql';
import * as T from './__generated__';

const keyFor = (txt: string) => `CORE-PROSPECT/GEOGRAPHY-PICKER/ZIP/${txt}`;

const citiesQuery = gql`
  query cities($city: String!, $offset: Int!, $limit: Int!) {
    usa_zip_code_boundary_v2(
      distinct_on: reference_1
      order_by: { reference_1: asc }
      where: { reference_1: { _is_null: false, _ilike: $city } }
      offset: $offset
      limit: $limit
    ) {
      city: reference_1
    }
  }
` as GQL<AllAssigned<T.CitiesQuery>, T.CitiesQueryVariables>;

export const paginatedCities = paginate({
  key: keyFor('PAGINATED-CITIES'),
  query: citiesQuery,
  fieldName: 'usa_zip_code_boundary_v2',
});

const zipCodesQuery = gql`
  query zipCodes(
    $city: String!
    $zip_code: String!
    $offset: Int!
    $limit: Int!
  ) {
    usa_zip_code_boundary_v2(
      order_by: { zip_code: asc }
      where: {
        reference_1: { _is_null: false, _ilike: $city }
        zip_code: { _is_null: false, _ilike: $zip_code }
      }
      offset: $offset
      limit: $limit
    ) {
      zip_code
      city: reference_1
      geometry: zip_code_geom
    }
  }
` as GQL<AllAssigned<T.ZipCodesQuery>, T.ZipCodesQueryVariables>;

export const paginatedZipCodes = paginate({
  key: keyFor('PAGINATED-ZIP-CODES'),
  query: zipCodesQuery,
  fieldName: 'usa_zip_code_boundary_v2',
});
