import { selectorFamily } from 'recoil';
import { formatDemographicsStats } from 'src/products/core-prospect/search-pages/components/Stats/formatDemographicsStats';
import { getUsaDemographicsQuery } from 'src/products/core-prospect/search-pages/components/Stats/query';
import { lotAddress } from '../../../recoil/lotAddress';
import { Tax_AssessorQueryVariables } from '../../../recoil/__generated__/taxAssessor';
import { DemographicsData } from '../types';
import { getDemographicsVintage } from 'src/products/core-prospect/utils/getDemographicsVintage';

export const demographicsData = selectorFamily<
  DemographicsData,
  Tax_AssessorQueryVariables
>({
  key: 'LOT-PAGE/DEMOGRAPHICS',
  get:
    (params) =>
    ({ get }) => {
      const lotAddressResult = get(lotAddress(params));

      const geoObjectId = `ZI${lotAddressResult.zip}`;

      const vintage = getDemographicsVintage();

      const usaDemographics = get(
        getUsaDemographicsQuery({
          geographyId: geoObjectId,
          vintage,
        })
      );

      const stats = formatDemographicsStats(usaDemographics);

      return { stats, data: { lot_address: lotAddressResult } };
    },
});
