// from https://github.com/facebookexperimental/Recoil/issues/290

import { useEffect, useState } from 'react';
import { RecoilValue, useRecoilValueLoadable } from 'recoil';

export function useLoadable<T>(
  defaultValue: T,
  recoilLoadable: RecoilValue<T>
): [T, 'loading' | 'hasValue' | 'hasError'] {
  const [value, setValue] = useState(defaultValue);
  const recoilValue = useRecoilValueLoadable<T>(recoilLoadable);

  let returnValue: T = defaultValue;

  useEffect(() => {
    if (recoilValue.state === 'hasValue' && recoilValue.contents !== value) {
      setValue(recoilValue.contents);
    }
  }, [recoilValue.contents, recoilValue.state, value]);

  if (recoilValue.state !== 'hasValue' && value) {
    returnValue = value;
  }

  if (recoilValue.state === 'hasValue') {
    returnValue = recoilValue.contents;
  }

  return [returnValue, recoilValue.state];
}
