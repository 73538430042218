import React, { Suspense, useEffect } from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { makeStyles, Paper, Slide, withStyles } from '@material-ui/core';

import PropertyChips from 'src/products/core-prospect/Layout/LotpageNavigation/PropertyChips';
import { usaDataFormatter } from 'src/products/core-prospect/components/PropertyAddressSummary/dataFormatter/usaDataFormatter';

import { StreetView } from '../../../SearchMap/LotPopup/components';

import Address from './Address';
import OtherTaxRecords from './OtherTaxRecords';
import LatestTransaction from './LatestTransaction';
import LatestMortgage from './LatestMortgage';
import RecordedOwners from './RecordedOwners';
import {
  otherParcelTaxAssessorsQuery,
  parcelTaxAssessorsCountQuery,
  taxRecordPreviewQuery,
} from './query';
import { getLotPagePath } from '../../../utils';
import { DealInfoCard } from './DealInfoCard';
import { DealOverlaySuspense } from '../../../DealOverlay';

const PaperStyled = withStyles({
  elevation0: {
    boxShadow: '0 2px 5px 0 rgb(0 0 0 / 50%)',
  },
})(Paper);

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 350,
    width: 400,
  },
  flexWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  body: {
    flex: 1,
    overflow: 'auto',
    padding: 15,
    position: 'relative',
  },
  propertyLists: {
    position: 'absolute',
    top: 5,
    right: 5,
    marginLeft: 5,
  },
  title: {
    marginBottom: 10,
  },
});

type Props = {
  cherre_parcel_id: string;
  tax_assessor_id: number;
  onClose: () => void;
  style: React.CSSProperties;
};

const TaxRecordPreview: React.FC<Props> = ({
  cherre_parcel_id,
  tax_assessor_id,
  onClose,
  style = {},
}) => {
  const classes = useStyles();

  const tax_assessor = useRecoilValueLoadable(
    taxRecordPreviewQuery({
      tax_assessor_id,
    })
  ).valueMaybe()?.tax_assessor_v2[0];

  const count =
    useRecoilValueLoadable(
      parcelTaxAssessorsCountQuery({
        cherre_parcel_id,
      })
    ).valueMaybe()?.tax_assessor_v2_aggregate?.aggregate?.count ?? 0;

  const other_tax_assessors = useRecoilValueLoadable(
    otherParcelTaxAssessorsQuery({
      cherre_parcel_id,
      tax_assessor_id,
    })
  ).valueMaybe()?.tax_assessor_v2;

  useEffect(() => {
    window.analytics?.track('Preview Card Opened');
  }, []);

  const { address, city, state, zip } = tax_assessor || {};
  const link = tax_assessor
    ? getLotPagePath(
        tax_assessor.cherre_parcel_id || '',
        tax_assessor.tax_assessor_id || 0
      )
    : '';

  return (
    <div style={style} className={`${classes.root} js-tax-record-preview`}>
      <Slide direction='right' in mountOnEnter unmountOnExit>
        <PaperStyled elevation={0} style={{ height: '100%' }} square>
          {tax_assessor && (
            <div className={classes.flexWrapper}>
              <div>
                <DealOverlaySuspense
                  cherreParcelId={tax_assessor.cherre_parcel_id || ''}
                  taxAssessorId={tax_assessor.tax_assessor_id || 0}
                  onClose={onClose}
                  showOnClose
                  where='preview-card'
                />
                <StreetView
                  count={count}
                  width={400}
                  height={200}
                  location={{
                    latitude: tax_assessor.latitude || 0,
                    longitude: tax_assessor.longitude || 0,
                  }}
                />
              </div>
              <div className={classes.body}>
                <Address
                  type={usaDataFormatter(tax_assessor).subTitle}
                  zip={zip}
                  state={state}
                  city={city}
                  address={address}
                  link={link}
                  wrap
                />
                <div style={{ margin: '15px 0' }}>
                  <PropertyChips
                    limit={3}
                    taxAssessorId={tax_assessor.tax_assessor_id}
                  />
                </div>
                <Suspense fallback={null}>
                  <DealInfoCard
                    taxAssessorId={tax_assessor.tax_assessor_id || 0}
                  />
                </Suspense>
                <RecordedOwners tax_assessor={tax_assessor} />
                <LatestTransaction tax_assessor={tax_assessor} />
                <LatestMortgage tax_assessor={tax_assessor} />
                {other_tax_assessors && (
                  <OtherTaxRecords
                    count={count}
                    tax_assessors={other_tax_assessors}
                  />
                )}
              </div>
            </div>
          )}
        </PaperStyled>
      </Slide>
    </div>
  );
};

export default TaxRecordPreview;
