import moment from 'moment';
import { atom, selectorFamily } from 'recoil';
import { NearbyLeasesData, YearOverYear } from '../types';
import { rentAggregates } from './aggregates';
import { taxAssessorQuery } from 'src/products/core-prospect/pages/lot-page/recoil';

const round = (value: number) => Math.round(value * 100) / 100;

const roundYoY = (value: YearOverYear) => ({
  year1: round(value.year1),
  year2: round(value.year2),
});

type SpaceType = 'Office' | 'Retail' | 'Industrial';

export const propertyClassAtom = atom<SpaceType>({
  key: 'LOT-PAGE/LEASES/NEARBY-LEASES/PROPERTY-CLASS',
  default: 'Office',
});

export const nearbyLeases = selectorFamily<NearbyLeasesData, number>({
  key: 'LOT-PAGE/LEASES/NEARBY-LEASES',
  get:
    (tax_assessor_id) =>
    ({ get }) => {
      const today = moment();
      const twoYearsAgo = moment().subtract(2, 'years');
      const oneYearAgo = moment().subtract(1, 'years');

      const start_date = twoYearsAgo.format('YYYY-MM-DD');
      const end_date = today.format('YYYY-MM-DD');

      const space_type = get(propertyClassAtom);

      const { tax_assessor_v2 } = get(taxAssessorQuery({ tax_assessor_id }));

      const cherre_parcel_id = tax_assessor_v2[0].cherre_parcel_id;

      const nearbyLeasesResult = cherre_parcel_id
        ? get(
            rentAggregates({
              start_date,
              end_date,
              cherre_parcel_id,
              space_type,
            })
          )
        : { tax_assessor_v2: [] };

      const leases =
        nearbyLeasesResult.tax_assessor_v2[0]
          ?.tax_assessor_v2_compstak_boundary__tax_assessor_id?.[0]
          ?.compstak_market_boundary__id
          ?.compstak_leases__cherre_compstak_leases_pk || [];

      const nearbyLeasesData: NearbyLeasesData = {
        concessions: {
          year1: 0,
          year2: 0,
        },
        freeRentMonthCount: {
          year1: 0,
          year2: 0,
        },
        leaseTerm: {
          year1: 0,
          year2: 0,
        },
        netEffectiveRent: {
          year1: 0,
          year2: 0,
        },
        startingRentPerSQFT: {
          year1: 0,
          year2: 0,
        },
        workValue: {
          year1: 0,
          year2: 0,
        },
      };

      const getYear = (str: string | null | undefined) =>
        moment(str).isAfter(oneYearAgo) ? 'year2' : 'year1';

      const leasesCount = {
        year1: leases.filter((l) => getYear(l.commencement_date) === 'year1')
          .length,
        year2: leases.filter((l) => getYear(l.commencement_date) === 'year2')
          .length,
      };

      const populated = leases.reduce((acc, cur) => {
        const year = getYear(cur.commencement_date);
        const workValueNumber = Number(cur.work_value);
        const freeRentMonthCountNumber = Number(cur.free_rent_month_count);
        const startingRentPerSQFTNumber = Number(cur.starting_rent_per_sq_ft);
        const leaseTermNumber = Number(cur.lease_term);
        const netEffectiveRentNumber = Number(cur.net_effective_rent);
        //concessions
        const currentConcessions =
          workValueNumber +
          freeRentMonthCountNumber * (startingRentPerSQFTNumber / 12);
        acc.concessions[year] += currentConcessions / leasesCount[year];
        //freeRentMonthCount
        acc.freeRentMonthCount[year] +=
          freeRentMonthCountNumber / leasesCount[year];
        //leaseTerm
        acc.leaseTerm[year] += leaseTermNumber / leasesCount[year];
        //netEffectiveRent
        acc.netEffectiveRent[year] +=
          netEffectiveRentNumber / leasesCount[year];
        //startingRentPerSQFT
        acc.startingRentPerSQFT[year] +=
          startingRentPerSQFTNumber / leasesCount[year];
        //workValue
        acc.workValue[year] += workValueNumber / leasesCount[year];
        return acc;
      }, nearbyLeasesData);

      return {
        concessions: roundYoY(populated.concessions),
        freeRentMonthCount: roundYoY(populated.freeRentMonthCount),
        leaseTerm: roundYoY(populated.leaseTerm),
        netEffectiveRent: roundYoY(populated.netEffectiveRent),
        startingRentPerSQFT: roundYoY(populated.startingRentPerSQFT),
        workValue: roundYoY(populated.workValue),
      };
    },
});
