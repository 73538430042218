import React from 'react';
import {
  MULTIPLE_SELECTION,
  SELECT_FILTER,
  TEXT_INPUT,
  HIERARCHICAL_SELECTION,
} from 'src/components/filters/const/filter-types';
import { withStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import grey from '@material-ui/core/colors/grey';
import muiThemeSettings from 'src/const/theme';

const coreProspectBodyStyles = get(muiThemeSettings, 'typography.body1', {});

const CoreProspectFilterTitle = ({ filter, classes }) => {
  return ![
    TEXT_INPUT,
    MULTIPLE_SELECTION,
    SELECT_FILTER,
    HIERARCHICAL_SELECTION,
  ].includes(filter.filter_type) ? (
    <div className={classes.label}>{filter.label}</div>
  ) : null;
};

const styles = {
  label: {
    fontSize: coreProspectBodyStyles.fontSize,
    fontFamily: coreProspectBodyStyles.fontFamily,
    marginBottom: 5,
    color: grey['900'],
  },
};

export default withStyles(styles)(CoreProspectFilterTitle);
