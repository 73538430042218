import { Button, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import { DrawPolygonByDraggingMode } from '@nebula.gl/edit-modes';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setDrawingMode,
  setFullMapMode,
} from 'src/products/core-prospect/redux/map/actions';

import { push } from 'connected-react-router';
import { resetSearchParameters } from 'src/products/core-prospect/redux/query/actions';
import {
  removeAutocompleteSearchParameters,
  removeGeoSearchParameters,
} from 'src/products/core-prospect/redux/query/utils';
import NavBar from 'src/products/core-prospect/search-pages/components/NavBar';
import {
  getSearchParameters,
  getSearchParametersFeature,
} from '../../../selectors';

const useStyles = makeStyles((theme) => ({
  btn: {
    minWidth: 'max-content',
    background: '#fff',
    boxShadow: '0 2px 5px 0 #00000033',
    borderRadius: 5,
    height: '100%',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: grey[100],
      color: theme.palette.primary.light,
    },
  },
  btnCancelDraw: {
    marginLeft: 20,
  },
}));

const CoreProspectMapNavBar = ({ execQueryFetchStatus, drawingMode }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isDrawEnabled = useSelector((state) => {
    return state.user.profile.value.featureFlags.drawToSearch;
  });
  const isInFullMapMode = useSelector((state) => {
    return state.coreProspect.map.isInFullMode;
  });
  const isCustomSearchArea = !!useSelector(getSearchParametersFeature);
  const searchParameters = useSelector(getSearchParameters);

  const getLeftContent = () => {
    if (drawingMode) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: 15,
            background: 'rgba(0,0,0,0.7)',
          }}
        >
          <Typography display='inline' style={{ color: '#fff' }}>
            Click and drag to draw your search
          </Typography>
          <Button
            className={`${classes.btn} ${classes.btnCancelDraw}`}
            onClick={() => {
              dispatch(setDrawingMode(null));
            }}
          >
            Cancel
          </Button>
        </div>
      );
    } else {
      return (
        <>
          {isInFullMapMode ? (
            <Tooltip title='Filters & Results'>
              <Button
                className={classes.btn}
                onClick={() => {
                  dispatch(setFullMapMode(false));
                }}
              >
                <img src='/assets/filter.svg' />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title='Expand Map'>
              <Button
                className={classes.btn}
                onClick={() => {
                  window.analytics?.track('Expand Map Opened');
                  dispatch(setFullMapMode(true));
                }}
              >
                <ChevronLeft />
              </Button>
            </Tooltip>
          )}
          {isDrawEnabled && (
            <Button
              startIcon={<Edit />}
              className={classes.btn}
              onClick={() => {
                dispatch(push('/properties?sidebar=filters'));
                dispatch(setDrawingMode(new DrawPolygonByDraggingMode()));
              }}
            >
              Draw area
            </Button>
          )}
          {isCustomSearchArea && (
            <Button
              startIcon={<Delete />}
              className={classes.btn}
              onClick={() => {
                const cleanedSearchParameters = removeGeoSearchParameters(
                  removeAutocompleteSearchParameters(searchParameters)
                );

                dispatch(
                  resetSearchParameters(cleanedSearchParameters, {
                    id: 'SearchMap: onClick: Remove boundary',
                  })
                );
              }}
            >
              Remove boundary
            </Button>
          )}
        </>
      );
    }
  };
  return (
    <NavBar
      isLoading={execQueryFetchStatus}
      view='map'
      leftSideComponent={getLeftContent()}
    />
  );
};

export default CoreProspectMapNavBar;
