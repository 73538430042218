import { RecoilLoadable, useRecoilValueLoadable } from 'recoil';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { leasesSelectorFamily } from '../recoil';

export const useLeases = ({
  latitude,
  longitude,
  compType,
  fileVersion,
}: {
  latitude: number;
  longitude: number;
  compType: string;
  fileVersion: string;
}) => {
  const isEqtDealsIntegrationAvailable = useFeatureFlag(
    'isEqtDealsIntegrationAvailable'
  );

  if (!isEqtDealsIntegrationAvailable) {
    return RecoilLoadable.of([]);
  }

  return useRecoilValueLoadable(
    leasesSelectorFamily({
      latitude,
      longitude,
      compType,
      fileVersion,
    })
  );
};
