import { get } from 'lodash';
import { useCallback, useEffect } from 'react';
import { WebMercatorViewport } from 'react-map-gl';
import { useSelector } from 'react-redux';
import { useRecoilCallback, useRecoilState } from 'recoil';
import { MapBounds } from 'src/components/Map/index.new';
import useLocation from 'src/hooks/useLocation';
import {
  geometryAtom,
  heightAtom,
  latitudeAtom,
  longitudeAtom,
  widthAtom,
  zoomAtom,
} from '../recoil';

export const useMapViewport = () => {
  const location = useLocation();

  const currentViewport = useSelector(
    (state) => state.coreProspect.map.currentViewport
  );

  const [latitude, setLatitude] = useRecoilState(latitudeAtom);
  const [longitude, setLongitude] = useRecoilState(longitudeAtom);
  const [zoom, setZoom] = useRecoilState(zoomAtom);
  const [width, setWidth] = useRecoilState(widthAtom);
  const [height, setHeight] = useRecoilState(heightAtom);
  const [geometry, setGeometry] = useRecoilState(geometryAtom);

  const activeParcelLatitude = get(location, 'query.latitude', null);
  const activeParcelLongitude = get(location, 'query.longitude', null);
  const stickToThePin = get(location, 'query.stickToThePin', null);

  const recenter = useCallback(
    ({
      latitude,
      longitude,
      zoom,
    }: {
      latitude: number;
      longitude: number;
      zoom: number;
    }) => {
      setLatitude(latitude);
      setLongitude(longitude);
      setZoom(zoom);
    },
    []
  );

  useEffect(() => {
    if (stickToThePin) {
      recenter({
        latitude: Number(activeParcelLatitude),
        longitude: Number(activeParcelLongitude),
        zoom: zoom || 15,
      });
    }
  }, [stickToThePin]);

  const rebound = useRecoilCallback(
    ({ snapshot }) =>
      async (bounds?: MapBounds | void) => {
        const width = await snapshot.getPromise(widthAtom);
        const height = await snapshot.getPromise(heightAtom);

        if (!width || !height) {
          return;
        }

        if (!bounds) {
          setLatitude(currentViewport.latitude);
          setLongitude(currentViewport.longitude);
          setZoom(currentViewport.zoom);
          return;
        }

        const maxPadding = Math.min(width, height) / 2 - 1;

        try {
          const viewport = new WebMercatorViewport({
            width: width || 0,
            height: height || 0,
          }).fitBounds(bounds, { padding: Math.min(maxPadding, 0) });

          setLatitude(viewport.latitude);
          setLongitude(viewport.longitude);
          setZoom(viewport.zoom - 1);
        } catch (e) {
          console.log(e);
        }
      },
    [width, height]
  );

  return {
    width,
    height,
    setWidth,
    setHeight,
    latitude,
    longitude,
    zoom,
    rebound,
    recenter,
    geometry,
    setGeometry,
  };
};
