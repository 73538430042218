import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    bottom: 10,
    left: 10,
    zIndex: 9,
    display: 'flex',
    gap: 10,
  },
});

export const Legends: React.FC = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
};
