import React from 'react';
import GetApp from '@material-ui/icons/GetApp';
import LotsListButton from '../../../Buttons/LotsListButton';
import { useExportSelectedRows } from 'src/products/core-prospect/hooks';

export const ExportButton: React.VFC = () => {
  const { isExportDisabled, tooltipTitle, onClickExport } =
    useExportSelectedRows();

  return (
    <LotsListButton
      disabled={isExportDisabled}
      id='export'
      text={'Export'}
      tooltip={tooltipTitle}
      startIcon={<GetApp />}
      onClick={onClickExport}
    />
  );
};
