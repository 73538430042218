import React from 'react';
import { getMaskedValue } from 'src/utils/mask';

const getColumns = () => {
  return [
    {
      label: 'Zip Code',
      dataKey: 'zip',
      isSortable: true,
      width: 150,
      isResizable: true,
      align: 'right',
    },
    {
      label: 'Matching Tax Records',
      dataKey: 'propertyCount',
      width: 250,
      isResizable: true,
      align: 'right',
    },
    {
      label: 'Median Household Income',
      dataKey: 'median_household_income',
      width: 250,
      isResizable: true,
      align: 'right',
      cellRenderer: ({ cellData }) => {
        return (
          <div style={{ padding: '15px 0' }}>
            {!Number(cellData) ? cellData : getMaskedValue(cellData, 'dollars')}
          </div>
        );
      },
    },
    {
      label: 'Crime Risk Index',
      dataKey: 'crime_total_risk',
      width: 150,
      isResizable: true,
      align: 'right',
    },
    {
      label: 'Median Age',
      dataKey: 'population_median_age',
      width: 150,
      isResizable: true,
      align: 'right',
      cellRenderer: ({ cellData }) => (cellData ? Math.round(cellData) : null),
    },
  ];
};

export default getColumns;
