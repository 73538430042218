import { keyBy } from 'lodash';
import { selectorFamily } from 'recoil';
import { merge } from 'src/utils/recoil/utils';
import { taxAssessorQuery } from '../../../../../recoil';
import { Tax_AssessorQueryVariables } from '../../../../../recoil/__generated__/taxAssessor';
import { BuildingDetailsData } from '../types';
import { taxAssessorToBuildingDetails } from './formatters';
import { lookupQueryBatch } from './queries';
import { LookupQueryBatchQueryVariables } from './__generated__/queries';

const lookups = {
  sewerUsage: 'sewer_usage_code',
  waterSource: 'water_source_code',
  foundation: 'foundation_code',
  construction: 'construction_code',
  interiorStructure: 'interior_structure_code',
  fireResistenceClass: 'fire_resistance_class_code',
  flooringMaterial: 'flooring_material_code',
  structureStyle: 'structure_style_code',
  exterior: 'exterior_code',
  roofMaterial: 'roof_material_code',
  roofConstruction: 'roof_construction_code',
  topography: 'topography_code',
} as const;

export const buildingDetails = selectorFamily<
  Partial<BuildingDetailsData>,
  Tax_AssessorQueryVariables
>({
  key: 'LOT-PAGE/BUILDING-DETAILS',
  get:
    (params) =>
    ({ get }) => {
      const taxAssessorValue = get(taxAssessorQuery(params));
      const taxAssessor = taxAssessorValue.tax_assessor_v2[0];
      const taxAssessorFormatted =
        taxAssessorToBuildingDetails(taxAssessorValue);

      const lookupCodeEntries = Object.entries(lookups);

      const variables: LookupQueryBatchQueryVariables = {
        where: {
          _or: lookupCodeEntries
            .filter(([, fn]) => fn && taxAssessor?.[fn])
            .map(([, fn]) => ({
              object_name: { _eq: 'tax_assessor_v2' },
              field_name: { _eq: fn },
              lookup_code: { _eq: String(taxAssessor?.[fn]) },
            })),
        },
      };

      const lookupValues = get(lookupQueryBatch(variables));

      const lookupFields = keyBy(
        lookupValues.lookups_by_table_name,
        'field_name'
      );

      const lookupValueEntries = lookupCodeEntries.map(([k, fn]) => [
        k,
        lookupFields[fn]?.lookup_value,
      ]);

      const lookupsFormatted = Object.fromEntries(lookupValueEntries);

      return merge(taxAssessorFormatted, lookupsFormatted);
    },
});
