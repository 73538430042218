import React from 'react';
import { getMaskedValue } from 'src/utils/mask';
import { SectorsMap } from '../../utils';

const getColumns = (marketFundamentalsFiltersEnabled, reisEnabled) => {
  let columns = [
    {
      label: 'Submarket',
      dataKey: 'submarket_name',
      width: 200,
      isResizable: true,
      cellRenderer: ({ cellData }) => {
        return <div style={{ padding: '15px 0' }}>{cellData}</div>;
      },
    },
    {
      label: 'Sector',
      dataKey: 'sector',
      width: 150,
      isResizable: true,
      cellRenderer: ({ cellData }) => {
        return SectorsMap.get(cellData) || '';
      },
    },
  ];

  if (marketFundamentalsFiltersEnabled && reisEnabled) {
    columns = columns.concat([
      {
        label: 'Net Absorption',
        dataKey: 'absorption',
        width: 150,
        isResizable: true,
        align: 'right',
      },
      {
        label: 'Net Completions',
        dataKey: 'completions',
        width: 150,
        isResizable: true,
        align: 'right',
      },
      {
        label: 'Vacancy',
        dataKey: 'vacancy',
        width: 150,
        isResizable: true,
        align: 'right',
        cellRenderer: ({ cellData }) =>
          cellData ? getMaskedValue(Math.round(cellData), 'percent') : null,
      },
      {
        label: 'Asking Rent',
        dataKey: 'asking_rent',
        width: 150,
        isResizable: true,
        align: 'right',
        cellRenderer: ({ cellData }) =>
          cellData
            ? getMaskedValue(Math.round(cellData), 'dollarsPerSqft')
            : null,
      },
    ]);
  }

  return columns;
};

export default getColumns;
