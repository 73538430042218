import React from 'react';

import useIsCompact from 'src/hooks/useIsCompact';

const TimelineText = ({ children }) => {
  const isCompact = useIsCompact();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: isCompact ? 'column' : 'row',
      }}
    >
      {children}
    </div>
  );
};

export default TimelineText;
