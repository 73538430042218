import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const renderParts = (parts = []) =>
  parts.map((part, index) => {
    return part.highlight ? (
      <span
        key={index}
        style={{ display: 'inline-block', background: 'rgb(255, 255, 61)' }}
      >
        {part.text}
      </span>
    ) : (
      <span key={index}>{part.text}</span>
    );
  });

const TitleAndSummary = ({ classes, summary, parts, style }) => {
  return (
    <div style={style} className={classes.overflowHidden}>
      <Typography
        className={classes.truncate}
        color='textPrimary'
        variant='button'
        display='block'
      >
        {renderParts(parts)}
      </Typography>
      {summary ? (
        <Typography color='textPrimary' variant='body2'>
          {summary}
        </Typography>
      ) : null}
    </div>
  );
};

const styles = {
  overflowHidden: {
    overflow: 'hidden',
  },
  truncate: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
};

export default withStyles(styles)(TitleAndSummary);
