import { gql, GQL } from 'src/utils/recoil/gql';
import { selectorFamilyWithDefaultValue } from 'src/utils/recoil/withDefaultValue';
import * as T from './__generated__/lookupTable';

export const _lookupQuery = gql.withDefaultValue(null)/* GraphQL */ `
  query lookup(
    $object_name: String!
    $field_name: String!
    $lookup_code: String!
  ) {
    lookups_by_table_name(
      where: {
        object_name: { _eq: $object_name }
        field_name: { _eq: $field_name }
        lookup_code: { _eq: $lookup_code }
      }
    ) {
      lookup_value
    }
  }
` as GQL<T.LookupQuery, T.LookupQueryVariables>;

export type PartialNull<T> = { [K in keyof T]: T[K] | undefined | null };

export const lookupQuery = selectorFamilyWithDefaultValue<
  string | null,
  PartialNull<T.LookupQueryVariables>
>({
  defaultValue: null,
  key: 'PROPERTY-USE-WHEN-AVAILABLE-SELECTOR-FAMILY',
  get:
    ({ object_name, field_name, lookup_code }) =>
    ({ get }) => {
      if (!object_name || !field_name || !lookup_code) {
        return null;
      }

      const result = get(
        _lookupQuery({ object_name, field_name, lookup_code })
      );
      return result.lookups_by_table_name[0]?.lookup_value || null;
    },
});
