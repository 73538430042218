import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import { Flex } from 'src/components/Flex';
import { useExportSelectedRows } from 'src/products/core-prospect/hooks';

const ExportButton = () => {
  const { isExporting, isExportDisabled, onClickExport, tooltipTitle } =
    useExportSelectedRows();

  return (
    <Flex
      // style={{ width: 50 }}
      justifyContent='center'
      alignItems='center'
      flex='0 0 auto'
    >
      {isExporting ? (
        <CircularProgress style={{ height: 25, width: 25 }} />
      ) : (
        <Tooltip title={tooltipTitle}>
          <div>
            <IconButton
              size='small'
              color='primary'
              disabled={isExportDisabled}
              id='core-prospect-csv-export-button'
              onClick={onClickExport}
            >
              <GetAppIcon />
            </IconButton>
          </div>
        </Tooltip>
      )}
    </Flex>
  );
};

export default ExportButton;
