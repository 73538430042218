import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';

const SearchInput = ({ setFilterText, classes, id, placeholder }) => {
  return (
    <div className={classes.inputWrap}>
      <TextField
        id={id}
        fullWidth
        variant='outlined'
        onChange={(e) => {
          setFilterText(e.target.value);
        }}
        className={classes.searchInput}
        InputProps={{
          endAdornment: (
            <InputAdornment>
              <Search style={{ width: 20, height: 20, opacity: 0.4 }} />
            </InputAdornment>
          ),
        }}
        inputProps={{
          style: { padding: 12 },
        }}
        placeholder={placeholder}
      />
    </div>
  );
};

const styles = {
  inputWrap: {
    marginBottom: 15,
  },
};

export default withStyles(styles)(SearchInput);
