import { Typography } from '@material-ui/core';
import React from 'react';
import { HorizontalKeyValue } from '../../components';
import useStyles from './styles';
import * as T from './types';

const LotInformation: T.LotInformation = ({
  apn,
  propertyType,
  propertySubtype,
  zone,
  lastCountyAssessorUpdate,
}) => {
  const classes = useStyles();
  return (
    <div id='lot-page-lot-information' className={classes.container}>
      <Typography variant='h4' style={{ marginBottom: '25px' }}>
        Lot Information
      </Typography>
      <HorizontalKeyValue label='APN' value={apn} />
      <HorizontalKeyValue label='Property type' value={propertyType} />
      <HorizontalKeyValue label='Property subtype' value={propertySubtype} />
      <HorizontalKeyValue label='Zone' value={zone} />
      <HorizontalKeyValue
        label='Last County Assessor Update'
        value={lastCountyAssessorUpdate}
      />
    </div>
  );
};

LotInformation.displayName = 'LotInformation';

export default LotInformation;
