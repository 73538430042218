import { selectorFamilyWithDefaultValue } from 'src/utils/recoil/withDefaultValue';
import { taxAssessorQuery } from './taxAssessor';
import { Tax_AssessorQueryVariables } from './__generated__/taxAssessor';

interface RequiredLotMap {
  geom: any;
  latitude?: number;
  longitude?: number;
}

export type LotMap = Partial<RequiredLotMap> & { cherre_parcel_id: string };

export const lotMap = selectorFamilyWithDefaultValue<
  LotMap,
  Tax_AssessorQueryVariables
>({
  defaultValue: { cherre_parcel_id: '' },
  key: 'LOT-PAGE/LOT-MAP',
  get:
    (params) =>
    ({ get }) => {
      const taxAssessorResult = get(taxAssessorQuery(params));
      const taxAssessor = taxAssessorResult.tax_assessor_v2[0];
      const geom = taxAssessor.parcel_boundary_v2__tax_assessor_id?.geom;
      const latitude =
        taxAssessor.parcel_boundary_v2__tax_assessor_id?.latitude ?? undefined;
      const longitude =
        taxAssessor.parcel_boundary_v2__tax_assessor_id?.longitude ?? undefined;
      const cherre_parcel_id = taxAssessor.cherre_parcel_id || '';

      return { geom, latitude, longitude, cherre_parcel_id };
    },
});
