import React from 'react';
import moment from 'moment';
import { grey } from '@material-ui/core/colors';
import { makeStyles, Typography } from '@material-ui/core';
import numberWithCommas from 'src/utils/numberWithCommas';
import Truncate from 'src/components/Truncate';
import Link from './Link';
import Title from './Title';

const useStyles = makeStyles({
  label: {
    fontSize: 12,
    color: grey[700],
  },
  value: {
    fontWeight: 600,
  },
  body: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    rowGap: 15,
    columnGap: 10,
    padding: 12,
    border: `1px solid ${grey[300]}`,
    borderRadius: 10,
    overflow: 'hidden',
  },
  root: {
    marginBottom: 40,
  },
});

const Block = ({ label, value }) => {
  const classes = useStyles();
  return (
    <div>
      <Typography variant='body2' className={classes.label}>
        {label}
      </Typography>
      <Typography variant='body1' className={classes.value}>
        <Truncate>
          <span title={value}>{value}</span>
        </Truncate>
      </Typography>
    </div>
  );
};

const Document = ({
  viewAllHref,
  title,
  amount,
  date,
  persone1,
  persone2,
  onClickHref = undefined,
}) => {
  amount = amount && `$${numberWithCommas(amount)}`;
  date = date && `${moment(date).format('ll')}`;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Title>{title}</Title>
      <div className={classes.body}>
        <Block label='Amount' value={amount} />
        <Block label='Date' value={date} />
        <Block label={persone1?.label} value={persone1?.name} />
        <Block label={persone2?.label} value={persone2?.name} />
      </div>
      {viewAllHref && (
        <div onClick={onClickHref}>
          <Link to={viewAllHref}>View All</Link>
        </div>
      )}
    </div>
  );
};

export default Document;
