import moment from 'moment';
import { Link } from 'react-router-dom';
import React from 'react';
import numberWithCommas from 'src/utils/numberWithCommas';
import Truncate from 'src/components/Truncate';
import OpenInNew from '@material-ui/icons/OpenInNew';
import { Box, ListItem, makeStyles, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const TAX_RECORD_HEIGHT = 88;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    '&:first-child': {
      borderTop: 'none',
    },
    '&:hover': {
      '& $icon': {
        display: 'block',
      },
      backgroundColor: grey[200],
    },
  },
  checkbox: {
    display: 'flex',
    alignItems: 'baseline',
    height: TAX_RECORD_HEIGHT,
    '&.selected': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    '& > span': {
      paddingTop: '15px',
    },
  },
  listItem: {
    display: 'flex',
    position: 'relative',
    fontSize: 18,
    fontWeight: 500,
    padding: 0,
    cursor: 'pointer',
    height: TAX_RECORD_HEIGHT,
    overflow: 'hidden',
    '&:hover': {
      '& $icon': {
        display: 'block',
      },
      backgroundColor: grey[200],
    },
  },
  link: {
    padding: 15,
    height: '100%',
    width: '100%',
    display: 'block',
  },
  icon: {
    display: 'none',
    position: 'absolute',
    right: 10,
    top: 10,
  },
  smallText: {
    fontSize: 12,
    color: grey[600],
  },
  address: {
    color: theme.palette.text.primary,
  },
}));

const TaxRecordItem = ({
  address,
  type,
  size,
  saleAmount,
  saleDate,
  parcel_id,
  tax_assessor_id,
}) => {
  const classes = useStyles();

  size = size && `${numberWithCommas(size)} SF`;
  saleAmount = saleAmount && `$${numberWithCommas(saleAmount)}`;
  saleDate = saleDate && `on ${moment(saleDate).format('ll')}`;

  const href = `/properties/${parcel_id}/${tax_assessor_id}`;

  return (
    <Box className={classes.container}>
      <ListItem button className={classes.listItem}>
        <Link to={href} href={href} className={classes.link} target='_blank'>
          <OpenInNew className={classes.icon} color='primary' />
          <Typography>
            <Truncate className={classes.address}>
              {address ? <b>{address}</b> : <span>No address</span>}
            </Truncate>
          </Typography>
          <Typography className={classes.smallText}>
            <Truncate>{[type, size].filter(Boolean).join(' - ')}</Truncate>
          </Typography>
          <Typography className={classes.smallText}>
            <Truncate>
              {saleAmount || saleDate
                ? `Last sale: ${[saleAmount, saleDate]
                    .filter(Boolean)
                    .join(' - ')}`
                : null}
            </Truncate>
          </Typography>
        </Link>
      </ListItem>
    </Box>
  );
};

export default TaxRecordItem;
