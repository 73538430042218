import { LayerProps } from 'src/components/Map/types';
import { LAYERS } from '../const';

export default function getCustomFeatureLayers(
  feature?:
    | GeoJSON.Feature<GeoJSON.Geometry>
    | GeoJSON.FeatureCollection<GeoJSON.Geometry>
): LayerProps[] {
  return [
    {
      id: LAYERS.CUSTOM_FEATURE_COLLECTION_LINES,
      source: 'custom-feature-collection',
      type: 'line',
      paint: {
        'line-color': 'hsl(338, 100%, 33%)',
        'line-width': ['interpolate', ['linear'], ['zoom'], 7, 2, 12, 4],
      },
      layout: {
        visibility: !feature ? 'none' : 'visible',
      },
    },
  ];
}
