import { execTableCountAll } from './execTableCountAll';

type ExecCountQueries = {
  viewportOnly: boolean;
  rebound: boolean;
};

export const execCountQueries = ({
  viewportOnly,
  rebound,
}: ExecCountQueries) => {
  return async (dispatch) => {
    return dispatch(
      execTableCountAll({
        viewportOnly,
        rebound,
      })
    );
  };
};
