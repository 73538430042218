import { Box, Button, Typography, useTheme } from '@material-ui/core';
import { default as LaunchIcon } from '@material-ui/icons/LaunchOutlined';
import { default as EditIcon } from '@material-ui/icons/EditOutlined';
import React, { useEffect, useRef } from 'react';
import * as T from './types';
import useStyles from './styles';

import Truncate from 'src/components/Truncate';
import ButtonProgress from 'src/components/ButtonProgress';
import { DealStageChip } from '../../components';
import { useOnScreen } from 'src/products/core-prospect/hooks';
import { useSetRecoilState } from 'recoil';
import { headerDealStageVisibleAtom } from '../Header/recoil';
import { ViewPropertiesIcon } from 'src/components/ui/icons/ViewPropertiesIcon';
import { useViewProperties } from 'src/services/Dealcloud/viewProperties';
import { useEditDeal } from 'src/services/Dealcloud/editDeal';
import { longDash } from 'src/products/core-prospect/constants';

const DealCloudInfo: T.DealCloudInfo = ({ deal }) => {
  const classes = useStyles({ isPortfolio: Boolean(deal?.isPortfolio) });

  const [editDeal, loading] = useEditDeal();

  const theme = useTheme();

  const stageRef = useRef<HTMLDivElement>(null);

  const stageOnScreen = useOnScreen(stageRef, '-150px');

  const setDealStageVisible = useSetRecoilState(headerDealStageVisibleAtom);

  const [viewProperties, viewPropertiesLoading] = useViewProperties();

  useEffect(() => {
    setDealStageVisible(!stageOnScreen);
  }, [stageOnScreen]);

  console.log({ deal });
  if (!deal) {
    return null;
  }

  const {
    isPortfolio,
    acquisitionAssociate,
    broker,
    seller,
    ilo,
    stage,
    lastNote,
    linkTo,
    name,
    description = '',
    portfolioSummary,
    dealCloudId,
  } = deal;

  console.log({ ilo });

  return (
    <Box className={`${classes.container} js-lot-deal-info`}>
      <Box gridArea='header'>
        <Typography variant='h3'>Deal Info</Typography>
        {isPortfolio && (
          <Typography variant='body1' className={classes.title}>
            {portfolioSummary}
          </Typography>
        )}
      </Box>
      <Box
        gridArea='buttons'
        display='grid'
        justifyContent='end'
        gridAutoFlow='column'
        gridGap='24px'
      >
        {isPortfolio && (
          <ButtonProgress
            startIcon={
              viewPropertiesLoading ? undefined : <ViewPropertiesIcon />
            }
            className={classes.button}
            color='primary'
            onClick={() => viewProperties(deal.dealCloudId)}
            loading={viewPropertiesLoading}
            spinnerStyle={{
              color: theme.palette.primary.main,
            }}
            wrapperStyle={{ display: 'flex' }}
          >
            View Properties
          </ButtonProgress>
        )}
        <ButtonProgress
          color='primary'
          startIcon={loading ? undefined : <EditIcon />}
          className={`${classes.button} js-edit-deal-icon`}
          onClick={() => editDeal(dealCloudId)}
          loading={loading}
          spinnerStyle={{ color: theme.palette.primary.main }}
          spinnerSize={20}
          wrapperStyle={{ display: 'flex' }}
        >
          Edit
        </ButtonProgress>
        <Button
          component={'a'}
          href={linkTo}
          color='primary'
          startIcon={<LaunchIcon />}
          className={`${classes.button} js-dealcloud-link`}
          target='_blank'
        >
          DealCloud
        </Button>
      </Box>
      {isPortfolio && (
        <Box gridArea='name'>
          <Typography variant='body1' className={classes.title}>
            Portfolio name
          </Typography>
          <Typography variant='body1'>
            <strong>{name}</strong>
          </Typography>
        </Box>
      )}
      <Box className='js-deal-stage' gridArea='stage'>
        <Typography variant='body1' className={classes.title}>
          Stage
        </Typography>
        <div ref={stageRef}>
          <DealStageChip name={stage} size='small' />
        </div>
      </Box>
      <Box className='js-deal-ilo' gridArea='ilo'>
        <Typography variant='body1' className={classes.title}>
          ILO
        </Typography>
        <Typography variant='body1'>
          <strong>{ilo || <i>{longDash}</i>}</strong>
        </Typography>
      </Box>
      <Box className='js-deal-description' gridArea='description'>
        <Typography variant='body1' className={classes.title}>
          Description
        </Typography>
        <Typography variant='body1'>
          <strong title={description}>
            {description?.length > 80
              ? description?.slice(0, 80) + '...'
              : description || <i>{longDash}</i>}
          </strong>
        </Typography>
      </Box>
      <Box className='js-deal-broker' gridArea='broker'>
        <Typography variant='body1' className={classes.title}>
          Broker
        </Typography>
        <Typography variant='body1'>
          <strong>{broker || <i>{longDash}</i>}</strong>
        </Typography>
      </Box>
      <Box className='js-deal-notes' gridArea='notes'>
        <Typography variant='body1'>Latest Note</Typography>
        <Typography noWrap={false}>
          <Truncate
            style={{
              fontWeight: 600,
              whiteSpace: 'normal',
              display: '-webkit-box',
              WebkitLineClamp: 5,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {lastNote || <i>{longDash}</i>}
          </Truncate>
        </Typography>
      </Box>
      <Box className='js-deal-seller' gridArea='seller'>
        <Typography variant='body1' className={classes.title}>
          Seller
        </Typography>
        <Typography variant='body1'>
          <strong>{seller || <i>{longDash}</i>}</strong>
        </Typography>
      </Box>
      <Box className='js-deal-lead' gridArea='lead'>
        <Typography variant='body1' className={classes.title}>
          Acquisitions Associate
        </Typography>
        <Typography variant='body1'>
          <strong>{acquisitionAssociate || <i>{longDash}</i>}</strong>
        </Typography>
      </Box>
    </Box>
  );
};

DealCloudInfo.displayName = 'DealCloudInfo';

export default DealCloudInfo;
