import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PagedTable from 'src/components/PagedTable';

import query from '../queries/reisSubmarkets';

import getColumns from '../columns/reisSubmarkets';
import { constSelector, useRecoilValueLoadable } from 'recoil';

export const ReisSubmarketsTab: React.FC = () => {
  const {
    reisBoundaries,
    offset,
    rowsPerPage,
    count,
    fetchStatus,
    marketFundamentalsFiltersEnabled,
    reisEnabled,
  } = useSelector((state) => {
    return {
      fetchStatus: state.coreProspect.reisSubmarkets.fetchStatus.value,
      count: state.coreProspect.reisSubmarkets.count,
      reisBoundaries: state.coreProspect.reisSubmarkets.results ?? [],
      offset: state.coreProspect.reisSubmarkets.offset,
      rowsPerPage: state.coreProspect.reisSubmarkets.rowsPerPage,
      orderBy: state.coreProspect.reisSubmarkets.orderBy,
      marketFundamentalsFiltersEnabled:
        state.user?.profile?.value?.featureFlags
          ?.marketFundamentalsFiltersEnabled,
      reisEnabled: state.user?.profileForm?.value?.reisEnabled,
    };
  });

  const page = offset / rowsPerPage;

  const reisSubmarkets = useRecoilValueLoadable(
    reisBoundaries
      ? query({
          reisBoundaries: reisBoundaries.map(
            (r) => r.cherre_reis_boundaries_pk
          ),
        })
      : constSelector({ coreexplore_reis_submarket_data: [] })
  );

  const isLoading =
    fetchStatus !== 'LOADED' || reisSubmarkets.state === 'loading';

  const columns = useMemo(() => {
    return getColumns(marketFundamentalsFiltersEnabled, reisEnabled);
  }, [marketFundamentalsFiltersEnabled, reisEnabled]);

  const rows = useMemo(() => {
    return reisSubmarkets.valueMaybe()?.coreexplore_reis_submarket_data ?? [];
  }, [reisSubmarkets.contents]);

  return (
    <PagedTable
      stickyLeft={1}
      selection={false}
      isAllRowsSelected={false}
      rowsPerPage={count}
      onChangeRowsPerPage={() => {}}
      totalCount={count}
      page={page}
      onSelect={() => {}}
      onSelectAllChange={() => {}}
      onChangePage={() => {}}
      hasNextPage={false}
      hasPreviousPage={false}
      onChangeOrder={() => {}}
      columns={columns}
      items={rows}
      isLoading={isLoading}
      emptyText=''
      rowKey='cherre_reis_submarket_trend_pk'
      rowsPerPageOptions={[]}
    />
  );
};
