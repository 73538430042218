import React from 'react';
import { Flex } from 'src/components/Flex';
import { Button } from '@material-ui/core';
import {
  PIPL,
  useGetPIPL,
  getPerson,
  getPhones,
  usePreloadPIPL,
} from '../pipl';

import { useBeforePrintAction } from 'src/utils/printActionContext';
import { TYPE_COMPANY } from '../common';
import { Query } from '../pipl/types';
import { LotAddress } from '../../../recoil';

type ContactInfoProps = {
  query: Query;
};

const ContactInfo: React.FC<ContactInfoProps> = ({ query }) => {
  const piplData = useGetPIPL(query);
  const preload = usePreloadPIPL();
  useBeforePrintAction(() => preload(query));
  const person = piplData ? getPerson(piplData) : undefined;
  const phones = getPhones(person?.phones || []).map((phone) => phone.display);
  const emails = person?.emails?.map((email) => email.address) || [];
  const contactInfo = [...phones, ...emails]
    .map((info) => <span style={{ whiteSpace: 'nowrap' }}>{info}</span>)
    .reduce(
      (acc, cur, index) => (index ? [...acc, ', ', cur] : [cur]),
      [] as React.ReactNode[]
    );
  return <React.Fragment>{contactInfo}</React.Fragment>;
};

type OwnerCardContactDetailsProps = {
  pipl: boolean;
  setDrawerOpen: (open: boolean) => void;
  isDrawerOpen: boolean;
  lotAddress?: LotAddress;
  name: string;
  type: string;
  owner_id?: string;
};

const OwnerCardContactDetails: React.FC<OwnerCardContactDetailsProps> = ({
  pipl,
  setDrawerOpen,
  isDrawerOpen,
  lotAddress,
  name,
  type,
  owner_id,
}) => {
  const piplQuery = React.useMemo(
    () =>
      lotAddress
        ? {
            one_line_address: lotAddress.display_address ?? '',
            raw_name: name,
          }
        : undefined,
    [name, lotAddress?.display_address]
  );

  if (pipl && piplQuery) {
    return (
      <Flex flex='unset' justifyContent='flex-end'>
        <div className='print-hide'>
          <Button
            style={{ position: 'relative', top: 10, right: -10 }}
            color='primary'
            onClick={() => setDrawerOpen(true)}
          >
            Contact Details
          </Button>
          {isDrawerOpen && (
            <PIPL query={piplQuery} onClose={() => setDrawerOpen(false)} />
          )}
        </div>
        <div
          className='print-only'
          style={{ width: '224px', overflow: 'hidden', marginTop: 10 }}
        >
          <ContactInfo query={piplQuery} />
        </div>
      </Flex>
    );
  } else if (type === TYPE_COMPANY && owner_id) {
    return null;
  }

  return null;
};

export default OwnerCardContactDetails;
