import React from 'react';
import { CircularProgress, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { FlexRow } from 'src/components/Flex';
import UnderBarDrawer from 'src/components/UnderBarDrawer';
import { GEOGRAPHY_TYPES } from 'src/products/core-prospect/constants';
import Stats from './Stats';
import { useRecoilValueLoadable, useRecoilState } from 'recoil';
import { getUsaDemographicsQuery } from './query';
import { formatDemographicsStats } from './formatDemographicsStats';
import { demographicsFeatureAtom } from 'src/products/core-prospect/components/ReactMap/recoil';
import { getDemographicsVintage } from 'src/products/core-prospect/utils/getDemographicsVintage';

const StatsDrawer: React.FC<{ isCompact: boolean }> = (props) => {
  const { isCompact } = props;

  const [demographicFeature, setDemographicFeature] = useRecoilState(
    demographicsFeatureAtom
  );

  const onClose = () => setDemographicFeature(undefined);

  const featureProperties = demographicFeature?.properties;
  const geoId = featureProperties?.GEO_ID;
  const geoName = featureProperties?.GEO_NAME;
  const geoType = featureProperties?.GEO_TYPE ?? 'ZI';
  const geoObjectId = demographicFeature?.properties?.OB_GEO_ID as string;

  const vintage = getDemographicsVintage();

  const usaDemographics = useRecoilValueLoadable(
    getUsaDemographicsQuery({
      geographyId: geoObjectId,
      vintage,
    })
  );

  const stats = formatDemographicsStats(usaDemographics.valueMaybe());

  const isLoading = usaDemographics.state === 'loading';

  return (
    <UnderBarDrawer
      id='core-prospect-detailed-demographics-drawer'
      anchor='right'
      open
      onClose={onClose}
    >
      <div
        style={{
          padding: 20,
          width: isCompact ? '100%' : 400,
          display: 'grid',
          gridGap: 20,
          alignContent: 'start',
        }}
      >
        <FlexRow alignItems='center' justifyContent='space-between'>
          <Typography variant='h4'>Demographic Insights</Typography>
          <IconButton
            id='core-prospect-detailed-demographics-drawer-close-button'
            style={{ padding: 0 }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </FlexRow>
        {geoId && (
          <Typography variant='subtitle1'>{`${GEOGRAPHY_TYPES[geoType].label} ${geoId}, ${geoName}`}</Typography>
        )}
        {isLoading ? (
          <FlexRow justifyContent='center'>
            <CircularProgress />
          </FlexRow>
        ) : (
          <Stats panelData={stats} />
        )}
      </div>
    </UnderBarDrawer>
  );
};

export default StatsDrawer;
