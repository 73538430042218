import { SourceProps } from 'react-map-gl';
import { SOURCES } from '../const';

export function getNeighborhoodsInfo(type) {
  if (type !== 'nd') {
    throw new Error(`Invalid neighborhood type: ${type}`);
  }

  return SOURCES.NEIGHBORHOODS_ND;
}

export default function getNeighborhoodsSource(type): SourceProps {
  const info = getNeighborhoodsInfo(type);

  return {
    id: `${info.name}`,
    type: 'vector',
    url: `mapbox://sbcherre.${info.name}`,
  };
}
