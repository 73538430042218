import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Chip, Typography } from '@material-ui/core';
import {
  addPropertyListEntries,
  createPropertyList,
  getPropertyLists,
  getPropertyListEntriesByPropertyIds,
} from 'src/products/core-prospect/redux/propertyLists/actions';
import { idFieldName } from 'src/products/core-prospect/redux/table/actions';
import { useDispatch } from 'react-redux';

import PropertyList from 'src/products/core-prospect/search-pages/components/ResultsTableView/CoreProspectResultsTableNav/PropertyList';
import { uniqBy } from 'lodash';

const useStyles = makeStyles({
  root: {
    padding: 18,
    borderBottom: '1px solid #e0e0e0',
    display: 'flex',
    position: 'relative',
  },
  propertyLists: {
    position: 'absolute',
    top: 5,
    right: 5,
  },
});

const LotSummary = ({ property_ids = [], summaryRef, lotSummary }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [listEntries, setListEntries] = React.useState([]);

  const handleSelect = async (listId) => {
    await dispatch(
      addPropertyListEntries({
        list_id: listId,
        property_code_type: idFieldName,
        property_ids,
      })
    );
    dispatch(getPropertyLists());
    dispatch(getPropertyListEntriesByPropertyIds(property_ids)).then(
      setListEntries
    );
  };

  const handleCreate = async (label) => {
    const list = await dispatch(createPropertyList(label));
    handleSelect(list.id);
  };

  React.useEffect(() => {
    if (property_ids.length > 0) {
      dispatch(getPropertyListEntriesByPropertyIds(property_ids)).then(
        setListEntries
      );
    }
  }, [property_ids.join()]);

  return (
    <div
      ref={summaryRef}
      id='js-map-selected-parcel-summary'
      className={classes.root}
    >
      <div>
        {property_ids && (
          <div className={classes.propertyLists}>
            <PropertyList
              title='Add to property lists'
              onSelect={handleSelect}
              onCreate={handleCreate}
            />
          </div>
        )}
        {lotSummary ? (
          <>
            {' '}
            <Typography variant='h6'>{lotSummary.address1}</Typography>
            <Typography variant='body1'>{lotSummary.address2}</Typography>
            <Typography variant='body1'>
              {lotSummary.class_description}
            </Typography>{' '}
          </>
        ) : (
          <Typography variant='body1'>
            No match found for this address.
          </Typography>
        )}
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {uniqBy(listEntries, 'list_id').map((entry, index) => {
            return (
              <Chip
                key={index}
                style={{ marginRight: 5, marginTop: 5 }}
                size='small'
                label={
                  entry.name.length > 20
                    ? entry.name.slice(0, 20) + '...'
                    : entry.name
                }
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LotSummary;
