import React from 'react';
import { connect, useDispatch } from 'react-redux';
import compose from 'src/utils/compose';
import { withStyles } from '@material-ui/core/styles';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import useLocation from 'src/hooks/useLocation';
import { push } from 'connected-react-router';

const tabs = [
  {
    value: 'searches',
    label: 'My Searches',
  },
  {
    value: 'templates',
    label: 'Templates',
  },
];

const SearchTabs = ({ classes }) => {
  const { query } = useLocation();
  const dispatch = useDispatch();
  return (
    <Tabs
      id='js-core-prospect-searches-tab'
      value={query.tab || 'searches'}
      onChange={(e, value) => {
        dispatch(push(`/properties?sidebar=searches&tab=${value}`));
      }}
      indicatorColor='primary'
      textColor='primary'
      variant='fullWidth'
      TabIndicatorProps={{
        style: {
          bottom: 'unset',
          top: 1,
        },
      }}
    >
      {tabs.map((tab) => (
        <Tab
          key={tab.value}
          classes={{
            labelContainer: classes.labelContainer,
            root: classes.root,
          }}
          value={tab.value}
          label={tab.label}
          id={`js-core-prospect-searches-tab-${tab.value}`}
        />
      ))}
    </Tabs>
  );
};

function mapStateToProps() {
  return {};
}

const styles = {
  root: {
    textTransform: 'none',
    fontSize: 14,
    minWidth: 'unset',
    padding: 6,
  },
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(SearchTabs);
