import { GQL, gql } from 'src/utils/recoil/gql';
import * as T from './__generated__/queries';

export const lookupQueryBatch = gql`
  query lookupQueryBatch($where: lookups_by_table_name_bool_exp!) {
    lookups_by_table_name(where: $where) {
      field_name
      lookup_value
    }
  }
` as GQL<T.LookupQueryBatchQuery, T.LookupQueryBatchQueryVariables>;
