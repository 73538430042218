import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { LinkedAddressesQuery } from '../__generated__/linkedAddressesQuery';

type OwnerCardAddressProps = {
  matchedAddress: LinkedAddressesQuery['tax_assessor_v2'][number];
  normalizedAddress?: string;
};

const OwnerCardAddress: React.FC<OwnerCardAddressProps> = ({
  matchedAddress,
  normalizedAddress,
}) => {
  return (
    <div>
      {matchedAddress ? (
        <Link
          to={`/properties/${matchedAddress.cherre_parcel_id}/${matchedAddress.tax_assessor_id}`}
        >
          <Typography variant='body2'>
            {
              matchedAddress.cherre_address__property_address?.[0]
                ?.display_address
            }
          </Typography>
        </Link>
      ) : (
        <Typography variant='body2'>{normalizedAddress}</Typography>
      )}
    </div>
  );
};

export default OwnerCardAddress;
