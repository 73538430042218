import React, { useCallback, useMemo } from 'react';
import { connect, RootState, useDispatch, useSelector } from 'react-redux';

import PagedTable from 'src/components/PagedTable';
import {
  changeOffset,
  changeRowsPerPage,
  changeOrderBy,
  selectRow,
  selectAllRows,
  idFieldName,
  uncheckSelectAllCheckbox,
} from 'src/products/core-prospect/redux/table/actions';
import { getOrderBy } from 'src/products/core-prospect/search-pages/selectors';
import { getPropertyListEntriesForTable } from 'src/products/core-prospect/redux/propertyLists/actions';
import getColumns from './getColumns';
import { execTableLotsQuery } from 'src/products/core-prospect/redux/query/actions/execTableLotsQuery';
import { useLookupsByTableNames } from 'src/hooks/useLookupsByTableName';
import { makeStyles } from '@material-ui/styles';
import { useOrganizationPropsValue } from 'src/hooks';
import { useAllDealsPortfolioAssetsByTaxId } from 'src/services/Dealcloud/hooks';

const useStyles = makeStyles(() => ({
  table: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    '& .BaseTable__row': {
      '& .editDeal': {
        visibility: 'hidden',
      },
    },
    '&  .BaseTable__row:hover': {
      '& .editDeal': {
        visibility: 'visible',
      },
    },
  },
}));

type ResultsTableProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectRow: (row: any, idFieldName: string) => void;
  selectAllRows: (checked: boolean, idFieldName: string) => void;
  uncheckSelectAllCheckbox: () => void;
  changeOffset: (offset: number) => void;
  changeRowsPerPage: (rowsPerPage: number) => void;
  changeOrderBy: (
    orderBy: RootState['coreProspect']['table']['orderBy']
  ) => Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: any[];
  count: number;
  isLoading: boolean;
  offset: number;
  rowsPerPage: number;
  orderBy: RootState['coreProspect']['table']['orderBy'];
  isAllRowsSelected: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedRows: any[];
  propertyListEntries: RootState['coreProspect']['propertyLists']['propertyListEntriesForTable']['value'];
};

const ResultsTable: React.VFC<ResultsTableProps> = ({
  selectRow,
  selectAllRows,
  uncheckSelectAllCheckbox,
  changeOffset,
  changeRowsPerPage,
  changeOrderBy,
  items,
  count,
  isLoading,
  offset,
  rowsPerPage,
  orderBy,
  isAllRowsSelected,
  selectedRows,
  propertyListEntries,
}) => {
  const classes = useStyles();

  const lookupsByTableNames = useLookupsByTableNames();

  const isDealsIntegrationAvailable =
    useOrganizationPropsValue('deals_enabled');

  const areDealsFiltersEnabled = useSelector(
    (state) => state.coreProspect.query.areDealsFiltersEnabled
  );

  const deals = isDealsIntegrationAvailable && areDealsFiltersEnabled;
  const dealsInfo = useAllDealsPortfolioAssetsByTaxId({});

  const columns = useMemo(
    () =>
      getColumns({
        lookupsByTableNames,
        deals,
        dealsInfo,
      }),
    [lookupsByTableNames, deals, dealsInfo]
  );

  const selectedRowKeys = useMemo(
    () => selectedRows.map((row) => row[idFieldName]),
    [selectedRows]
  );

  const itemsWithListEntries = useMemo(
    () =>
      items.map((item) => {
        return {
          ...item,
          propertyListEntries: propertyListEntries.filter((entry) => {
            return Number(entry.property_id) === Number(item[idFieldName]);
          }),
        };
      }),
    [items, propertyListEntries]
  );

  const dispatch = useDispatch();
  const page = offset / rowsPerPage;

  React.useEffect(() => {
    dispatch(getPropertyListEntriesForTable());
  }, [items]);

  const onChangeRowsPerPageCallback = useCallback(
    (event) => {
      changeRowsPerPage(parseInt(event.target.value, 10));
      dispatch(
        execTableLotsQuery({
          isExport: false,
          paginate: false,
          viewportOnly: true,
        })
      );
      uncheckSelectAllCheckbox();
    },
    [changeRowsPerPage, uncheckSelectAllCheckbox]
  );

  const onSelectCallback = useCallback(
    (row) => {
      selectRow(row, idFieldName);
    },
    [selectRow]
  );

  const onChangePageCallback = useCallback(
    ({ index }) => {
      changeOffset(index * rowsPerPage);
      dispatch(
        execTableLotsQuery({
          isExport: false,
          paginate: true,
          viewportOnly: true,
        })
      );
      uncheckSelectAllCheckbox();
    },
    [changeOffset, uncheckSelectAllCheckbox, rowsPerPage]
  );

  const onChangeOrderCallback = useCallback(
    async (orderBy) => {
      await changeOrderBy(orderBy);
      dispatch(
        execTableLotsQuery({
          isExport: false,
          paginate: false,
          viewportOnly: true,
        })
      );
    },
    [changeOrderBy]
  );

  return (
    <div className={classes.table}>
      <PagedTable
        stickyLeft={1}
        isAllRowsSelected={isAllRowsSelected}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[25, 50, 100, 500, 1000]}
        onChangeRowsPerPage={onChangeRowsPerPageCallback}
        totalCount={count}
        page={page}
        onSelect={onSelectCallback}
        onSelectAllChange={(checked) => selectAllRows(checked, idFieldName)}
        onChangePage={onChangePageCallback}
        hasNextPage={(page + 1) * rowsPerPage < count}
        hasPreviousPage={page > 0}
        orderBy={orderBy.field}
        orderByDirection={orderBy.direction as 'asc' | 'desc'}
        onChangeOrder={onChangeOrderCallback}
        columns={columns}
        items={itemsWithListEntries}
        isLoading={isLoading}
        emptyText=''
        rowKey={idFieldName}
        selectedRowKeys={selectedRowKeys}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const {
    coreProspect: {
      table: { rowsPerPage, offset, isAllRowsSelected, selectedRows },
    },
  } = state;

  return {
    propertyListEntries:
      state.coreProspect.propertyLists.propertyListEntriesForTable.value,
    orderBy: getOrderBy(state),
    offset,
    rowsPerPage,
    isAllRowsSelected,
    selectedRows,
    isLoading:
      state.coreProspect.propertyLists.propertyListEntriesForTable.fetchStatus
        .value !== 'LOADED',
  };
};

export default connect(mapStateToProps, {
  changeOffset,
  changeOrderBy,
  changeRowsPerPage,
  selectRow,
  selectAllRows,
  uncheckSelectAllCheckbox,
})(ResultsTable);
