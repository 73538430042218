import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  container: {
    '@media print': {
      display: 'none !important',
    },
    position: 'sticky',
    paddingRight: 50,
  },
}));
