import * as ZIPCODES_TYPES from '../../zipcodes/types';

import {
  getSearchParameters,
  patchByCondoUnitsSearchParameter,
} from 'src/products/core-prospect/search-pages/selectors';
import { ZIPCODES_ID_FIELD } from '../../zipcodes/actions';
import { getFeature } from '../actions';

type ExecTableZipcodesQuery = {
  viewportOnly: boolean;
  paginate: boolean;
  isCount: boolean;
  size?: number;
  isExport: boolean;
};

export const execTableZipcodesQuery = ({
  isCount,
  isExport,
  paginate,
  size,
  viewportOnly,
}: ExecTableZipcodesQuery) => {
  return async (dispatch, getState) => {
    const timestamp = Date.now();
    const state = getState();
    const searchParameters = getSearchParameters(state);
    const orderBy = state.coreProspect.zipcodes.orderBy;
    const { after, before, rowsPerPage } = state.coreProspect.zipcodes;
    const areDealsFiltersEnabled =
      getState().coreProspect.query.areDealsFiltersEnabled;

    let listId = getState().router.location.query.list_id;

    if (Number.isNaN(Number(listId))) {
      listId = undefined;
    }

    const filters = patchByCondoUnitsSearchParameter(state, searchParameters);

    return dispatch({
      url: `/search/usa-lots?${isCount ? 'zipcodesCount' : 'zipcodes'}`,
      method: 'post',
      cancelable: true,
      send: {
        areDealsFiltersEnabled,
        listId: isExport ? undefined : listId,
        isZipcodes: true,
        isZipcodesCount: isCount,
        after: paginate ? after?.[ZIPCODES_ID_FIELD] : undefined,
        before: paginate ? before?.[ZIPCODES_ID_FIELD] : undefined,
        filters,
        feature: getFeature({
          viewportOnly,
          state,
        }),
        size: size || rowsPerPage,
        sort: orderBy
          ? {
              direction: orderBy.direction,
              fields: [orderBy.field],
            }
          : undefined,
      },
      dataToDispatch: { timestamp },
      types: isExport
        ? []
        : isCount
        ? [
            ZIPCODES_TYPES.TABLE_RESULTS_COUNT_REQUEST,
            ZIPCODES_TYPES.TABLE_RESULTS_COUNT_SUCCESS,
            ZIPCODES_TYPES.TABLE_RESULTS_COUNT_FAILED,
          ]
        : [
            ZIPCODES_TYPES.TABLE_RESULTS_REQUEST,
            ZIPCODES_TYPES.TABLE_RESULTS_SUCCESS,
            ZIPCODES_TYPES.TABLE_RESULTS_FAILED,
          ],
    });
  };
};
