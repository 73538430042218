import React from 'react';
import Typography from '@material-ui/core/Typography';
import { getMaskedValue } from 'src/utils/mask';
import grey from '@material-ui/core/colors/grey';

const TimelineItemTopRowText = ({ item }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 5,
      }}
    >
      <Typography variant='h6'>
        {item.amount ? getMaskedValue(item.amount, 'dollars') : '$ -'}
      </Typography>
      <Typography variant='body2' style={{ color: grey[500] }}>
        {item.date ? item.date.format('MMM D') : ''}
      </Typography>
    </div>
  );
};

export default TimelineItemTopRowText;
