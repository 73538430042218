import { gql, GQL } from 'src/utils/recoil/gql';
import { selectorFamilyWithDefaultValue } from 'src/utils/recoil/withDefaultValue';
import * as T from './__generated__/nycDetails';

export const nycDetailsQuery = gql`
  query nycDetails($bbl: numeric!) {
    nyc_lot(where: { bbl: { _eq: $bbl } }) {
      zoning_district_1
      area_sq_ft
      bbl
      depth_ft
      width
      owner_name
      owner_code
      borough
      nyc_building__bbl(limit: 1) {
        residential_type
        class_code
        gross_sq_ft
      }
    }
    nyc_tax(
      where: { bbl: { _eq: $bbl } }
      limit: 1
      order_by: { tax_year: desc }
    ) {
      rate
      total_market_value
      final_tax
      total_actual_assessed_value
      tax_year
    }
    acris_master(
      where: {
        acris_legal__document_id: { bbl: { _eq: $bbl } }
        recorded_date: { _is_null: false }
        document_type: {
          _in: [
            "DEED"
            "TIMESHARE DEED"
            "DEED, OTHER"
            "CORRECT INDEX/DEED-OFFICE USE"
            "CORRECTION DEED"
            "LIFE ESTATE DEED"
            "DEED WITH RESTRICTIVE COVENANT"
            "CONFIRMATORY DEED"
            "IN REM DEED"
            "DEED, PRE RPT TAX"
          ]
        }
      }
      limit: 1
      order_by: { recorded_date: desc }
    ) {
      document_amount
      recorded_date
    }
  }
` as GQL<T.NycDetailsQuery, T.NycDetailsQueryVariables>;

export const nycDetailsWhenAvailable = selectorFamilyWithDefaultValue<
  T.NycDetailsQuery | null,
  number | null | undefined
>({
  defaultValue: { acris_master: [], nyc_lot: [], nyc_tax: [] },
  key: 'NYC-DETAILS-WHEN-AVAILABLE-SELECTOR-FAMILY',
  get:
    (bbl) =>
    ({ get }) => {
      if (!bbl) {
        return null;
      }
      return get(nycDetailsQuery({ bbl }));
    },
});
