import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';

const NavItem = ({ panel, isActive }) => {
  return (
    <div
      onClick={() => {
        const el = document.querySelector(`#${panel.id}`);
        el?.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        });
      }}
      id={`sidebar-${panel.id}`}
    >
      <MenuItem
        style={{
          fontWeight: isActive ? 600 : 500,
        }}
      >
        {panel.name}
      </MenuItem>
    </div>
  );
};

export default NavItem;
