import { Popover } from '@material-ui/core';
import React from 'react';
import { usePropertyListPopover } from '../../hooks';
import List from 'src/components/InteractiveList/List';

export type PropertyListPopoverProps = {
  property_id: string;
  property_code_type: string;
  anchorEl: Element | null;
  onClose: () => void;
};

export const PropertyListPopover: React.VFC<PropertyListPopoverProps> = ({
  property_id,
  property_code_type,
  anchorEl,
  onClose,
}) => {
  const enabled = Boolean(anchorEl);

  const {
    handleCreate,
    items,
    selected,
    handleItemSelect,
    handleItemDeselect,
  } = usePropertyListPopover(property_id, property_code_type, enabled);

  return (
    <Popover
      open={enabled}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <List
        onItemCreate={handleCreate}
        items={items}
        selected={selected}
        onItemSelect={handleItemSelect}
        onItemDeselect={handleItemDeselect}
      />
    </Popover>
  );
};
