import React from 'react';
import { push } from 'connected-react-router';
import groupBy from 'lodash/groupBy';
import { RootState, useDispatch, useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { getSearchParameters } from 'src/products/core-prospect/search-pages/selectors';

import Footer from './Footer.new';

import FilterGroups from './FilterGroups';

import LotsList from '../LotCardsView';
import {
  CORE_PROSPECT_VIEWS,
  RESULTS_TYPES,
} from 'src/products/core-prospect/constants';
import { expandSidebar } from 'src/products/core-prospect/redux/view/actions';
import useLocation from 'src/hooks/useLocation';
import { Autocomplete } from '../../components/Autocomplete/index';
import ToggleOff from '@material-ui/icons/ToggleOff';
import ToggleOn from '@material-ui/icons/ToggleOn';
import Tabs from 'src/components/Tabs';
import GeographyPicker from './GeographyPicker';
import { Badge } from '@material-ui/core';
import { execQuery } from 'src/products/core-prospect/redux/query/actions/execQuery';
import Tooltip from '@material-ui/core/Tooltip';
import { DealsSwitch } from './DealsSwitch';
import { filtersConfig } from 'src/products/core-prospect/filtersConfig';
import ResetAllButton from '../../../Buttons/ResetAllButton.new';
import { useOrganizationPropsValue } from 'src/hooks';

interface StylesProps {
  isFilterOpen: boolean;
}

const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
  container: {
    display: 'grid',
    height: '100%',
    gridTemplateRows: 'auto auto 1fr auto',
    overflow: 'hidden',
  },
  header: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignItems: 'center',
    borderBottom: (props) =>
      props.isFilterOpen ? 'unset' : `1px solid ${theme.palette.grey[300]}`,
  },
  filterToggle: {
    display: 'grid',
    cursor: 'pointer',
    width: 40,
    height: 40,
    backgroundColor: (props) =>
      props.isFilterOpen ? theme.palette.primary.main : 'white',
    color: (props) =>
      props.isFilterOpen ? 'white' : theme.palette.primary.main,
    border: (props) =>
      props.isFilterOpen
        ? '1px solid white'
        : `1px solid ${theme.palette.primary.main}`,
    alignContent: 'center',
    justifyContent: 'center',
    borderRadius: 6,
    marginRight: 15,
    '& svg': {
      margin: '-5px 0px',
    },
  },
  tabsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    width: '100%',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  badge: {
    right: '30%',
  },
  options: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridColumn: '1/3',
    justifyItems: 'end',
    alignItems: 'center',
  },
  resetFilters: {
    marginRight: '15px',
  },
}));

const getGroupSearchParameters = (group, sets, filters, searchParameters) => {
  const filteredSets = sets.filter((set) => set.filter_group_id === group.id);

  const filteredFilters = filters.filter((filter) => {
    return filteredSets.map((set) => set.id).includes(filter.filter_set_id);
  });

  return searchParameters
    .filter((searchParameter) => {
      return filteredFilters.some((filter) => {
        return (
          filter.entity === searchParameter.entity_name &&
          filter.field_name === searchParameter.field_name
        );
      });
    })
    .filter((searchParameter) => {
      // filter hierarchical search parameters with 0 nodes checked
      const value = searchParameter.attributes.value;

      if (value.checked || value.expanded) {
        return Object.values(value.checked || {}).filter(Boolean).length > 0;
      }

      return true;
    });
};

function mapStateToProps(state: RootState) {
  const filters = filtersConfig.filters;
  const sets = filtersConfig.filter_sets;
  const groups = filtersConfig.filter_groups;
  const groupPermissions = filtersConfig.filter_group_permissions;

  const searchParameters = getSearchParameters(state);

  const filterGroups = groupBy(filters, (filter) => {
    const set = sets.find((set) => set.id === filter.filter_set_id);
    const group = groups.find((group) => group.id === set?.filter_group_id);

    return group?.id;
  });

  const sortedFilterGroups = groups
    .sort((groupA, groupB) => {
      const permissionA = groupPermissions.find(
        (permission) => permission.filter_group_id === groupA.id
      );

      const permissionB = groupPermissions.find(
        (permission) => permission.filter_group_id === groupB.id
      );

      if (!permissionB || !permissionA) {
        return 0;
      }

      return permissionA.order_num > permissionB.order_num ? 1 : -1;
    })
    .filter((group) => {
      if (!group.hide) {
        return true;
      }
      return !group.hide(state);
    })
    .map((group) => {
      return {
        ...group,
        searchParameters: getGroupSearchParameters(
          group,
          sets,
          filters,
          searchParameters
        ),
      };
    });

  let appliedFiltersCount = searchParameters.filter(
    (sp) => sp.field_name !== 'custom_search_area'
  ).length;

  if (searchParameters.some((sp) => sp.field_name === 'custom_search_area')) {
    appliedFiltersCount += 1;
  }

  return {
    appliedFiltersCount,
    location: state.router.location,
    filterGroups: filterGroups || {},
    sortedFilterGroups,
    isSidebarCollapsed: state.coreProspect.view.isSidebarCollapsed,
    viewerName: state.coreProspect.view.viewerName,
    resultsType: state.coreProspect.view.resultsType,
  };
}

interface FilterPanelProps {
  mode: string;
}

const FilterPanel: React.VFC<FilterPanelProps> = ({ mode }) => {
  const classes = useStyles({ isFilterOpen: mode === 'filters' });
  const isDealsIntegrationAvailable =
    useOrganizationPropsValue('deals_enabled');

  const dispatch = useDispatch();

  const location = useLocation();
  const {
    appliedFiltersCount,
    filterGroups,
    sortedFilterGroups,
    viewerName,
    resultsType,
  } = useSelector(mapStateToProps);

  const showFilters =
    mode === 'filters' ||
    viewerName === CORE_PROSPECT_VIEWS.TABLE ||
    resultsType !== RESULTS_TYPES.TAX_RECORDS;

  const showProperties = !showFilters && mode === 'sidebar-lots-list';

  const toggleFilters = () => {
    let newMode = '';

    if (mode === 'filters') {
      newMode = 'sidebar-lots-list';
    }

    if (mode === 'sidebar-lots-list') {
      newMode = 'filters';
    }

    dispatch(expandSidebar());

    dispatch(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      push({
        ...location,
        query: {
          ...location.query,
          sidebar: newMode,
        },
      })
    );
  };

  return (
    <div id='core-prospect-filters-panel' className={classes.container}>
      <div className={classes.header}>
        <Autocomplete />
        <Badge
          overlap='circle'
          badgeContent={appliedFiltersCount}
          color='primary'
          classes={{
            badge: classes.badge,
          }}
        >
          <Tooltip
            title={mode === 'filters' ? 'Close filters' : 'Open filters'}
          >
            <div
              id='js-core-prospect-toggle-filters'
              onClick={toggleFilters}
              className={classes.filterToggle}
            >
              <ToggleOff />
              <ToggleOn />
            </div>
          </Tooltip>
        </Badge>
        <div className={classes.options}>
          <div>{isDealsIntegrationAvailable ? <DealsSwitch /> : null}</div>
          <div className={classes.resetFilters}>
            <ResetAllButton />
          </div>
        </div>
      </div>
      {showFilters && (
        <React.Fragment>
          <Tabs.Container classes={{ flexContainer: classes.tabsContainer }}>
            <Tabs.Tab label='Filters'>
              <FilterGroups
                sortedFilterGroups={sortedFilterGroups}
                filterGroups={filterGroups}
                onBlur={() => {
                  dispatch(
                    execQuery({
                      id: 'OnFilterBlur',
                      cards: true,
                      count: true,
                      results: true,
                      viewportOnly: true,
                      rebound: false,
                    })
                  );
                }}
              />
            </Tabs.Tab>
            <Tabs.Tab label='Geographies'>
              <GeographyPicker />
            </Tabs.Tab>
          </Tabs.Container>
          <Footer />
        </React.Fragment>
      )}
      {showProperties && (
        <React.Fragment>
          <span />
          <LotsList />
        </React.Fragment>
      )}
    </div>
  );
};

export default FilterPanel;
