import React from 'react';

import Typography from '@material-ui/core/Typography';
import { IconButton, makeStyles } from '@material-ui/core';
import useIsCompact from 'src/hooks/useIsCompact';
import { grey } from '@material-ui/core/colors';
import LabelBold from '../components/Panel/Regular/Element/LabelBold/component';
import Text from '../components/Panel/Regular/Element/Text/component';
import { formatPhoneNumber } from 'src/utils/numbers';
import ArrowBack from '@material-ui/icons/ArrowBack';
import useHistory from 'src/hooks/useHistory';
import { useDispatch } from 'react-redux';
import { goBack, push } from 'connected-react-router';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '25px 0 10px',
  },
  address: {
    marginLeft: 15,
    flex: 1,
    [theme.breakpoints.only('sm')]: {
      marginLeft: 0,
    },
  },
  infoGrid: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    width: 350,
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
  },
  infoMobile: {
    padding: '15px 0',
  },
  info: {
    borderLeft: `1px solid ${grey[300]}`,
    padding: '0 10px',
  },
}));

const Info = ({ contact }) => {
  const classes = useStyles();

  const phones = [
    contact?.phone_number_1,
    contact?.phone_number_2,
    contact?.phone_number_3,
  ]
    .filter(Boolean)
    .map(formatPhoneNumber);

  return (
    <div className={classes.infoGrid}>
      <div className={classes.info}>
        <LabelBold display='block'>Address</LabelBold>
        <Text display='block'>
          {contact?.cherre_address__address?.[0]?.display_address || '-'}
        </Text>
      </div>
      <div className={classes.info}>
        <LabelBold display='block'>Phones</LabelBold>
        {phones.length > 0 ? (
          phones.map((phone) => (
            <Text key={phone} display='block'>
              {phone}
            </Text>
          ))
        ) : (
          <Text display='block'>-</Text>
        )}
      </div>
    </div>
  );
};

const Header = ({ title, subTitle = '', contact = '', ownerType }) => {
  const classes = useStyles();
  const isCompact = useIsCompact();

  const { isHistoryEmpty } = useHistory();

  const dispatch = useDispatch();

  return (
    <>
      <div className={classes.root}>
        <IconButton
          onClick={() => {
            if (!isHistoryEmpty) {
              return dispatch(goBack());
            }

            dispatch(push('/properties'));
          }}
          size='medium'
        >
          <ArrowBack style={{ color: grey[900] }} />
        </IconButton>
        <div className={classes.address}>
          <Typography variant='h2'>{title}</Typography>
          <Typography variant='h5'>
            {ownerType} {subTitle ? `· ${subTitle}` : ''}
          </Typography>
        </div>
        {!isCompact && <Info contact={contact} />}
      </div>
      {isCompact && (
        <div className={classes.infoMobile}>
          <Info contact={contact} />
        </div>
      )}
    </>
  );
};

export default Header;
