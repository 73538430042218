import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import RCAChipFilter from './RCAChipFilter';
import * as FILTER_TYPES from 'src/components/filters/const/filter-types';
import {
  SQ_FT,
  PRICE,
  YEAR_BUILT,
  STATUS_DATE,
  MAIN_PROPERTY_TYPE,
  PRICE_PER_SQUARE_FOOT_UNIT,
} from '../../constants';
import { get } from 'lodash';

const LESS_THAN = 'Less than';
const GREATER_THAN = 'Greater than';
const BEFORE = 'Before';
const AFTER = 'After';

const rcaChipFiltersData = [
  {
    title: 'Year Built',
    titleTemplate: `Built: MIN_VAL to MAX_VAL`,
    onlyMax: BEFORE,
    onlyMin: AFTER,
    filterType: FILTER_TYPES.RANGE_SELECTION,
    filterField: YEAR_BUILT,
  },
  {
    title: 'Square Feet',
    mask: 'number',
    onlyMax: LESS_THAN,
    onlyMin: GREATER_THAN,
    titleTemplate: `MIN_VAL to MAX_VAL SQFT`,
    filterType: FILTER_TYPES.RANGE_INPUT,
    filterField: SQ_FT,
  },
  {
    title: 'Asset Class',
    titleTemplate: ``,
    filterType: FILTER_TYPES.MULTIPLE_SELECTION,
    filterField: MAIN_PROPERTY_TYPE,
  },
  {
    title: 'Transaction Date',
    onlyMax: BEFORE,
    onlyMin: AFTER,
    titleTemplate: `Status Date: MIN_VAL to MAX_VAL`,
    filterType: FILTER_TYPES.RANGE_DATE,
    filterField: STATUS_DATE,
  },
  {
    title: 'Transaction Amount',
    mask: 'dollars',
    onlyMax: LESS_THAN,
    onlyMin: GREATER_THAN,
    titleTemplate: `Transaction Amount: MIN_VAL to MAX_VAL`,
    filterType: FILTER_TYPES.RANGE_INPUT,
    filterField: PRICE,
  },
  {
    title: 'Price per Sq Foot or Unit',
    mask: 'dollars',
    onlyMax: LESS_THAN,
    onlyMin: GREATER_THAN,
    titleTemplate: `Price per Sq Foot or Unit: MIN_VAL to MAX_VAL`,
    filterType: FILTER_TYPES.RANGE_INPUT,
    filterField: PRICE_PER_SQUARE_FOOT_UNIT,
  },
];

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 5,
  },
}));

const RCAFilterChips = (props) => {
  const classes = useStyles();
  const { rcaFilterFunctions } = props;

  return (
    <div id='rca-filters-container' className={classes.root}>
      {rcaChipFiltersData.map((filterData, index) => {
        const selectionRange = get(
          rcaFilterFunctions,
          `${filterData.filterField}.selectionRange`,
          []
        );

        const chipFilterData = Object.assign(
          {},
          filterData,
          { value: rcaFilterFunctions[filterData.filterField].value },
          { setValue: rcaFilterFunctions[filterData.filterField].setValue },
          { selectionRange, filterField: filterData.filterField }
        );
        return (
          <RCAChipFilter
            key={index}
            chipFilterData={chipFilterData}
            {...props}
          />
        );
      })}
    </div>
  );
};

export default RCAFilterChips;
