import React from 'react';
import Typography from '@material-ui/core/Typography';

import { Flex } from 'src/components/Flex';
import { TYPE_RECORDED, INDIVIDUAL_OWNER_TYPES } from './common';
import OwnerCard from './OwnerCard/index';
import { OwnerData } from './types';
import { LotAddress } from '../../recoil';

type RecordedOwnersProps = {
  owners: OwnerData[];
  address: LotAddress;
};

const RecordedOwners: React.FC<RecordedOwnersProps> = ({ owners, address }) => {
  return (
    <>
      {owners.length ? (
        <div style={{ marginBottom: 20 }}>
          <Typography variant='h6' style={{ marginBottom: 10 }}>
            Recorded Owners
          </Typography>
          <Flex
            justifyContent='flex-start'
            flex='unset'
            style={{ flexWrap: 'wrap' }}
          >
            {owners.map((owner, i) => {
              return (
                <OwnerCard
                  key={i}
                  name={owner.owner_name ?? ''}
                  type={TYPE_RECORDED}
                  pipl={INDIVIDUAL_OWNER_TYPES.includes(
                    (owner.owner_type ??
                      null) as (typeof INDIVIDUAL_OWNER_TYPES)[number]
                  )}
                  lotAddress={address}
                  address={
                    address.mailing_address
                      ? `${address.mailing_address} ${address.mailing_city}, ${address.mailing_state} ${address.mailing_zip}`
                      : undefined
                  }
                />
              );
            })}
          </Flex>
        </div>
      ) : null}
    </>
  );
};

export default RecordedOwners;
