import React, { Suspense } from 'react';
import ResultsTableView from '../ResultsTableView';
import SearchMap from '../SearchMap';
import { connect, useSelector } from 'react-redux';
import { CORE_PROSPECT_VIEWS } from 'src/products/core-prospect/constants';
import Footer from '../Sidebar/Views/LotCardsView/Footer';
import CoreExploreToolbar from '../CoreExploreToolbar';
import OwnersTableView from '../OwnersTableView';
import MarketsTableView from '../MarketsTableView';
import { makeStyles } from '@material-ui/core';
import { RESULTS_TYPES } from '../../../constants';
import { SearchMapLoadingOverlay } from '../SearchMap/SearchMapLoadingOverlay';
import { useIsMapLoading } from '../../hooks/useIsMapLoading';

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
}));

const CoreProspectView = (props) => {
  const { pageHeight, viewerName = CORE_PROSPECT_VIEWS.MAP } = props;
  const classes = useStyles();

  const resultsType = useSelector(
    (state) => state.coreProspect.view.resultsType
  );

  const isMapLoading = useIsMapLoading();

  return (
    <div className={classes.root}>
      <CoreExploreToolbar />
      {<SearchMapLoadingOverlay isMapLoading={isMapLoading} />}
      {resultsType === RESULTS_TYPES.MARKETS && <MarketsTableView />}
      {resultsType === RESULTS_TYPES.OWNERS && <OwnersTableView />}
      {resultsType === RESULTS_TYPES.TAX_RECORDS && (
        <>
          {viewerName === CORE_PROSPECT_VIEWS.MAP && (
            <Suspense fallback=''>
              <SearchMap
                pageHeight={pageHeight}
                isVisible={viewerName === CORE_PROSPECT_VIEWS.MAP}
                navigationLocation={'bottom-right'}
                selectedMapLayers={[
                  'stats',
                  'parcels',
                  'neighborhoods',
                  'zipcodes',
                  'counties',
                  'saz',
                  'satellite',
                ]}
                showResultsCount
              />
            </Suspense>
          )}
          {props.isCompact && <Footer activeTab={1} />}
          {viewerName === CORE_PROSPECT_VIEWS.TABLE && (
            <ResultsTableView
              isVisible={viewerName === CORE_PROSPECT_VIEWS.TABLE}
            />
          )}
        </>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    viewerName: state.coreProspect.view.viewerName,
  };
}

export default connect(mapStateToProps)(CoreProspectView);
