import './MapboxControls.less';

import React, { useCallback, useMemo } from 'react';
import { get } from 'lodash';
import { connect, useSelector, useDispatch, RootState } from 'react-redux';

import Map from '../../../components/ReactMap';
import CoreProspectMapNavBar from 'src/products/core-prospect/search-pages/components/SearchMap/CoreProspectMapNavBar';

import {
  syncMapViewport,
  setDrawingMode,
  recenterMap,
} from 'src/products/core-prospect/redux/map/actions';

import { expandSidebar } from 'src/products/core-prospect/redux/view/actions';

import {
  getSearchParametersFeature,
  getSearchParameters,
} from 'src/products/core-prospect/search-pages/selectors';

import {
  MAP_CLUSTER_CLICKED,
  MAP_PARCEL_CLICKED,
} from 'src/products/shell/redux/middleware/segment';

import useLocation from 'src/hooks/useLocation';
import { execQuery } from 'src/products/core-prospect/redux/query/actions/execQuery';
import { resetSearchParameters } from 'src/products/core-prospect/redux/query/actions';

import LotPopup from './LotPopup';
import {
  MapDeal,
  OnLayerClick,
} from 'src/products/core-prospect/components/ReactMap/types';
import { removeGeoSearchParameters } from 'src/products/core-prospect/redux/query/utils';
import {
  FilterType,
  SearchEntity,
  UsaMapParcelSearchField,
} from 'src/products/core-prospect/types/SearchParameter';
import { EqtLeaseCompsPopup } from './EqtLeaseCompsPopup';
import { useUpdateUrlSearchParams } from 'src/products/core-prospect/hooks/useUpdateUrlSearchParams';
import { useAllDealsPortfolio } from 'src/services/Dealcloud/hooks';
import USZoomButton from './USZoomButton';

const RESULT_TYPE_CLUSTERS = 'clusters';

const US_VIEWPORT = {
  zoom: 3.68,
  latitude: 39.564325548113885,
  longitude: -97.04705894206279,
};

const SearchMap = ({
  drawingMode,
  isVisible,
  execMapQueryFetchStatus,
  execMapCountQueryFetchStatus,
  lots,
  resultType,
  syncMapViewport,
  isSatelliteVisible,
  expandSidebar,
  currentViewport,
  customFeature,
}) => {
  const dispatch = useDispatch();
  const search_parameters = useSelector(getSearchParameters);
  const deals = useAllDealsPortfolio({})?.valueMaybe();

  const areDealsFiltersEnabled = useSelector(
    (state) => state.coreProspect.query.areDealsFiltersEnabled
  );

  const location = useLocation();

  const selectedParcelID =
    get(location, 'query.cherre_parcel_id', null) ||
    get(location, 'query.preview_cherre_parcel_id', null);

  const hoverParcelID = get(location, 'query.map_cherre_parcel_id', null);

  const selectedParcelLatitude = get(location, 'query.latitude', null);
  const selectedParcelLongitude = get(location, 'query.longitude', null);
  const listId = get(location, 'query.list_id', null);

  const onMapLoadOrViewportChange = useCallback(
    (map) => {
      const center = map.getCenter();

      syncMapViewport(
        [
          map.getBounds().getNorthEast().toArray(),
          map.getBounds().getSouthWest().toArray(),
        ],
        map.getZoom(),
        center.lat,
        center.lng,
        map.getContainer().offsetWidth,
        map.getContainer().offsetHeight
      );

      dispatch(
        execQuery({
          id: 'SearchMap: viewport changed',
          cards: true,
          count: true,
          results: true,
          viewportOnly: listId ? false : true,
          rebound: false,
        })
      );
    },
    [listId, syncMapViewport]
  );

  const clusters = useMemo(
    () =>
      resultType === RESULT_TYPE_CLUSTERS
        ? lots.map((lot) => ({
            latitude: lot.latitude,
            longitude: lot.longitude,
            count: lot.count,
          }))
        : [],
    [lots, resultType]
  );

  const parcels = useMemo(
    () =>
      lots
        .map((lot) => ({
          latitude: lot.latitude,
          longitude: lot.longitude,
          id: lot.cherre_parcel_id,
          count: lot.count,
          hovered: hoverParcelID
            ? hoverParcelID === lot.cherre_parcel_id
            : false,
          selected: selectedParcelID
            ? selectedParcelID === lot.cherre_parcel_id
            : false,
        }))
        .filter(Boolean),
    [lots, selectedParcelID, hoverParcelID]
  );

  const updateUrlSearchParams = useUpdateUrlSearchParams();

  const onLeaseClick = useCallback<OnLayerClick>(
    ({ properties }) => {
      updateUrlSearchParams({
        compType: properties.comp_type,
        selectedLeaseId: properties.cherre_eqt_leases_pk,
        latitude: properties.lat,
        longitude: properties.long,
        fileVersion: properties.cherre_file_version,
      });
    },
    [updateUrlSearchParams]
  );

  const onParcelClick = useCallback<OnLayerClick>(
    ({ e: { lngLat }, properties }) => {
      dispatch({
        type: MAP_PARCEL_CLICKED, // segment
      });
      updateUrlSearchParams({
        cherre_parcel_id: properties.cherre_parcel_id,
        latitude: properties.latitude || lngLat[1],
        longitude: properties.longitude || lngLat[0],
        isPopupOpen: true,
      });
      expandSidebar();
    },
    [expandSidebar, updateUrlSearchParams]
  );

  const onDealClick = useCallback<OnLayerClick<MapDeal>>(
    ({
      e: { lngLat },
      feature: {
        properties: { cherreParcelId },
      },
    }) => {
      if (!cherreParcelId) {
        return;
      }

      updateUrlSearchParams({
        cherre_parcel_id: cherreParcelId,
        latitude: lngLat[1],
        longitude: lngLat[0],
        isPopupOpen: true,
      });
    },
    [updateUrlSearchParams]
  );

  const taxAssessorIds = useMemo(
    () => lots.map((lot) => Number(lot.tax_assessor_id)),
    [lots]
  );

  const onDrawComplete = useCallback(
    (feature) => {
      dispatch(
        resetSearchParameters(
          removeGeoSearchParameters(search_parameters).concat({
            entity_name: SearchEntity.usaMapParcel,
            field_name: UsaMapParcelSearchField.customSearchArea,
            attributes: {
              filter_type: FilterType.customSearchArea,
              value: feature,
            },
          }),
          {
            id: 'SearchMap:onDrawComplete',
            viewportOnly: true,
            rebound: false,
          }
        )
      );
      dispatch(setDrawingMode(null));
    },
    [search_parameters]
  );

  const onClusterClick = useCallback(
    ({ e: { lngLat } }) => {
      dispatch({
        type: MAP_CLUSTER_CLICKED, // segment
      });
      dispatch(
        recenterMap({
          zoom: currentViewport.zoom + 3,
          longitude: lngLat[0],
          latitude: lngLat[1],
        })
      );
      dispatch(
        execQuery({
          id: 'onClusterClick',
          cards: true,
          count: true,
          results: true,
          viewportOnly: listId ? false : true,
          rebound: false,
        })
      );
    },
    [currentViewport, listId]
  );

  const mapDeals = useMemo(
    () =>
      areDealsFiltersEnabled
        ? deals?.flatMap((deal) => {
            return deal.assets
              .filter(
                (asset) =>
                  asset.cherreAsset &&
                  taxAssessorIds.includes(asset.cherreAsset.taxAssessorId)
              )
              .map((asset) => {
                return {
                  stage: deal.stage,
                  latitude: asset.cherreAsset.latitude,
                  longitude: asset.cherreAsset.longitude,
                  taxAssessorId: asset.cherreAsset.taxAssessorId,
                  cherreParcelId: asset.cherreAsset.parcelId,
                };
              });
          })
        : [],
    [deals, taxAssessorIds, areDealsFiltersEnabled]
  );

  const isUSZoomDisabled =
    currentViewport.zoom === US_VIEWPORT.zoom &&
    currentViewport.latitude === US_VIEWPORT.latitude &&
    currentViewport.longitude === US_VIEWPORT.longitude;

  const onUSZoomButtonClick = () => {
    if (isUSZoomDisabled) {
      return;
    }
    dispatch(recenterMap(US_VIEWPORT));
    dispatch(
      execQuery({
        id: 'onUSZoomButtonClick',
        cards: true,
        count: true,
        results: true,
        viewportOnly: true,
        rebound: false,
      })
    );
  };

  return (
    <div
      style={{
        display: isVisible ? undefined : 'none',
        flex: 1,
        position: 'relative',
        zIndex: 1,
      }}
    >
      <CoreProspectMapNavBar
        drawingMode={drawingMode}
        execQueryFetchStatus={
          execMapQueryFetchStatus === 'LOADING' ||
          execMapCountQueryFetchStatus === 'LOADING'
        }
      />
      <Map
        isLayersSelectorVisible
        isDealsLegendVisible={areDealsFiltersEnabled}
        customFeature={customFeature}
        drawingMode={drawingMode}
        onDrawComplete={onDrawComplete}
        scrollZoom
        satellite={isSatelliteVisible}
        onLoad={onMapLoadOrViewportChange}
        onViewportChange={onMapLoadOrViewportChange}
        zoom={currentViewport.zoom}
        latitude={currentViewport.latitude}
        longitude={currentViewport.longitude}
        selectedLeaseId={Number(location.query.selectedLeaseId)}
        deals={mapDeals}
        clusters={clusters}
        parcels={parcels}
        onDealClick={onDealClick}
        onClusterClick={onClusterClick}
        onParcelClick={onParcelClick}
        onLeaseClick={onLeaseClick}
      >
        {areDealsFiltersEnabled ? (
          <USZoomButton
            onClick={onUSZoomButtonClick}
            disabled={isUSZoomDisabled}
          />
        ) : null}
        {location.query?.selectedLeaseId && (
          <div style={{ position: 'relative', zIndex: 9999 }}>
            <EqtLeaseCompsPopup
              longitude={Number(location.query.longitude)}
              latitude={Number(location.query.latitude)}
              onClose={() => {
                updateUrlSearchParams({
                  selectedLeaseId: undefined,
                  latitude: undefined,
                  longitude: undefined,
                });
              }}
            />
          </div>
        )}
        {location.query?.isPopupOpen && (
          <div style={{ position: 'relative', zIndex: 9999 }}>
            <LotPopup
              onClose={() =>
                updateUrlSearchParams({
                  isPopupOpen: undefined,
                  cherre_parcel_id: undefined,
                  latitude: undefined,
                  longitude: undefined,
                })
              }
              latitude={Number(selectedParcelLatitude)}
              longitude={Number(selectedParcelLongitude)}
              parcel_id={selectedParcelID}
              key={selectedParcelID}
            />
          </div>
        )}
      </Map>
    </div>
  );
};

function mapStateToProps(state: RootState) {
  const {
    coreProspect: {
      mapPinResults: { lots, resultType },
      map: { drawingMode, currentViewport, isSatelliteVisible },
      query: {
        execMapQueryFetchStatus: { value: execMapQueryFetchStatus },
        execMapCountQueryFetchStatus: { value: execMapCountQueryFetchStatus },
      },
    },
  } = state;

  const feature = getSearchParametersFeature(state);

  return {
    customFeature: feature,
    drawingMode,
    lots,
    resultType,
    isSatelliteVisible,
    execMapQueryFetchStatus,
    execMapCountQueryFetchStatus,
    currentViewport,
  };
}

export default connect(mapStateToProps, {
  syncMapViewport,
  expandSidebar,
})(SearchMap);
