import { Feature } from '@turf/helpers';

export type SearchParameter =
  | TaxAssessorSearchParameter
  | UnmaskedOwnerSearchParameter
  | RecordedOwnerSearchParameter
  | RecorderSearchParameter
  | RcaSalesSearchParameter
  | TreoLoadSearchParameter
  | CensusSearchParameter
  | UsaMapParcelSearchParameter
  | DealSearchParameter;
/**
 * Entity Search Parameters
 */
type TaxAssessorSearchParameter = {
  entity_name: SearchEntity.taxAssessor;
  field_name: TaxAssessorSearchField;
  attributes: Attributes;
};

type UnmaskedOwnerSearchParameter = {
  entity_name: SearchEntity.unmaskedOwner;
  field_name: UnmaskedOwnerSearchField;
  attributes: Attributes;
};

type RecordedOwnerSearchParameter = {
  entity_name: SearchEntity.recordedOwner;
  field_name: RecordedOwnerSearchFeild;
  attributes: Attributes;
};

type RecorderSearchParameter = {
  entity_name: SearchEntity.recorder;
  field_name: RecorderSearchField;
  attributes: Attributes;
};

type RcaSalesSearchParameter = {
  entity_name: SearchEntity.rcaSales;
  field_name: RcaSalesSearchField;
  attributes: Attributes;
};

type TreoLoadSearchParameter = {
  entity_name: SearchEntity.treppLoan;
  field_name: TrepLoanSearchField;
  attributes: Attributes;
};

type CensusSearchParameter = {
  entity_name: SearchEntity.census;
  field_name: CensusSearchFeild;
  attributes: Attributes;
};

type UsaMapParcelSearchParameter = {
  entity_name: SearchEntity.usaMapParcel;
  field_name: UsaMapParcelSearchField;
  attributes: Attributes;
};

type DealSearchParameter = {
  entity_name: SearchEntity.deal;
  field_name: DealSearchField;
  attributes: Attributes;
};

/**
 * Search Entities
 */
export enum SearchEntity {
  taxAssessor = 'tax_assessor__parcel_id',
  unmaskedOwner = 'usa_owner_unmask__tax_assessor_id',
  recordedOwner = 'tax_assessor_owner',
  recorder = 'recorder_summary',
  rcaSales = 'rca_sales_demo',
  treppLoan = 'trepp_loan_demo',
  census = 'census',
  usaMapParcel = 'usa_map_parcel',
  deal = 'deal',
}

/**
 * Entity Search Fields
 */
export enum TaxAssessorSearchField {
  taxAssessorId = 'tax_assessor_id',
  propertyType = 'property_use_code_mapped',
  lotSizeSqft = 'lot_size_sq_ft',
  lotSizeAcre = 'lot_size_acre',
  grossSqft = 'gross_sq_ft',
  buildingSqft = 'building_sq_ft',
  unitsCount = 'units_count',
  yearBuilt = 'year_built',
  taxBillAmount = 'tax_bill_amount',
  assessedValueTotal = 'assessed_value_total',
  marketValueTotal = 'market_value_total',
  unitNumber = 'unit_number',
}

export enum UnmaskedOwnerSearchField {
  owner_id = 'owner_id',
  owner_name = 'owner_name',
  owner_type = 'owner_type',
}

enum RecordedOwnerSearchFeild {
  owner_name = 'owner_name',
  owner_type = 'owner_type',
}

enum RecorderSearchField {
  cherre_latest_deed_amount = 'cherre_latest_deed_amount',
  cherre_latest_deed_date = 'cherre_latest_deed_date',
  cherre_latest_mortgage_amount = 'cherre_latest_mortgage_amount',
  cherre_latest_mortgage_date = 'cherre_latest_mortgage_date',
  cherre_latest_mortgage_lender = 'cherre_latest_mortgage_lender',
  cherre_latest_mortgage_borrower = 'cherre_latest_mortgage_borrower',
}

enum RcaSalesSearchField {
  status_date = 'status_date',
  price = 'price',
  price_per_sq_ft_unit = 'price_per_sq_ft_unit',
  sq_ft = 'sq_ft',
  unit_count = 'unit_count',
  closed_cap_rate = 'closed_cap_rate',
  buyer_name_1 = 'buyer_name_1',
  seller_name_1 = 'seller_name_1',
}

enum TrepLoanSearchField {
  cutoff_balance = 'cutoff_balance',
  allocated_balance_at_securitization = 'allocated_balance_at_securitization',
  origination_date = 'origination_date',
  maturity_date = 'maturity_date',
  securitization_ltv = 'securitization_ltv',
  note_rate_at_securitization = 'note_rate_at_securitization',
  remaining_term = 'remaining_term',
  most_recent_dscr_noi = 'most_recent_dscr_noi',
  most_recent_physical_occupancy = 'most_recent_physical_occupancy',
  expiration_date_of_largest_tenant_lease = 'expiration_date_of_largest_tenant_lease',
  is_newly_on_watchlist = 'is_newly_on_watchlist',
  is_interest_only = 'is_interest_only',
}

enum CensusSearchFeild {
  crime_total_risk = 'crime_total_risk',
  median_household_income = 'median_household_income',
  population_median_age = 'population_median_age',
}

export enum UsaMapParcelSearchField {
  customSearchArea = 'custom_search_area',
  parcelId = 'cherre_parcel_id',
  state = 'state',
  city = 'city',
}

export enum DealSearchField {
  leader = 'leader',
  stage = 'stage',
  ilo = 'ilo',
  selleter = 'seller',
}

/**
 * Attributes operator
 */
export enum SearchOperator {
  in = 'part_of_a_list',
  match = 'match',
  equals = 'equals',
  range = 'range',
  exists = 'exists',
}

/**
 * Attributes filter type
 */
export enum FilterType {
  hierarchical = 'HierarchicalFilter',
  unmaskedOwnerType = 'UnmaskedOwnerTypesSelection',
  recordedOwnerType = 'RecordedOwnerTypesSelection',
  textInput = 'TextInputFilter',
  rangeDate = 'RangeDateFilter',
  boolean = 'BooleanFilter',
  rangeInput = 'RangeInputFilter',
  csvExport = 'csvExport',
  customSearchArea = 'customSearchArea',
  customFilter = 'customFilter',
  existsFilter = 'existsFilter',
}

/**
 * Filter values
 */
type HierarchicalFilterValue = {
  expanded: Record<string, boolean>;
  activeNodeId?: number;
  checked: Record<string, boolean>;
  indeterminate: Record<string, boolean>;
};
type UnmaskedOwnerTypeFilterValue = string[];
type RecordedOwnerTypeFilterValue = string[];
type TextInputFilterValue = string;
type RangeDateFilterValue = { min: string; max: string };
type RangeInputFilterValue = { min: string; max: string };
type BooleanFilterValue = boolean;
type CSVExportFilterFilterValue = number[];
type CustomSearchAreaFilterValue = Feature;

/**
 * Filter Attributes
 */
type HierarchicalFilterAttributes = {
  filter_type: FilterType.hierarchical;
  value: HierarchicalFilterValue | null;
  operator: SearchOperator.in;
  type?: 'exclude';
};

type UnmaskedOwnerTypeFilterAttributes = {
  filter_type: FilterType.unmaskedOwnerType;
  value?: UnmaskedOwnerTypeFilterValue | null;
  operator: SearchOperator.equals;
};

type RecordedOwnerTypeFilterAttributes = {
  filter_type: FilterType.recordedOwnerType;
  value?: RecordedOwnerTypeFilterValue | null;
  operator: SearchOperator.equals;
};

type TextInputFilterAttributes = {
  filter_type: FilterType.textInput;
  value?: TextInputFilterValue | null;
  operator: SearchOperator.equals | SearchOperator.match;
};

type RangeDateFilterAttributes = {
  filter_type: FilterType.rangeDate;
  value?: RangeDateFilterValue | null;
  operator: SearchOperator.range;
};

type RangeInputFilterAttributes = {
  filter_type: FilterType.rangeInput;
  value?: RangeInputFilterValue | null;
  operator: SearchOperator.range;
};

type BooleanFilterAttributes = {
  filter_type: FilterType.boolean;
  value?: BooleanFilterValue | null;
  operator: SearchOperator.equals;
};

type ExistsFilterAttributes = {
  filter_type: FilterType.existsFilter;
  operator: SearchOperator;
  type: 'exclude';
  value?: void;
};

export type CustomSearchAreaFilterAttributes = {
  operator?: undefined;
  filter_type: FilterType.customSearchArea;
  value: CustomSearchAreaFilterValue;
};

export type CSVExportFilterAttributes = {
  filter_type: FilterType.csvExport;
  operator: SearchOperator.in;
  value: CSVExportFilterFilterValue;
};

export type CustomFilterAttributes = {
  filter_type: FilterType.customFilter;
  operator: SearchOperator;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
};

type Attributes = {
  isAutocomplete?: boolean;
  displayText?: string;
  type?: 'exclude';
  // review and cleanup custom fields below
  cbsa_code?: string;
  zip_code?: string;
  submarket_name?: string;
} & (
  | HierarchicalFilterAttributes
  | UnmaskedOwnerTypeFilterAttributes
  | RecordedOwnerTypeFilterAttributes
  | TextInputFilterAttributes
  | RangeDateFilterAttributes
  | RangeInputFilterAttributes
  | BooleanFilterAttributes
  | ExistsFilterAttributes
  | CustomSearchAreaFilterAttributes
  | CSVExportFilterAttributes
  | CustomFilterAttributes
);
