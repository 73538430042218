import { atom, selectorFamily } from 'recoil';
import { merge } from 'src/utils/recoil/utils';
import { taxAssessorQuery } from '../../../recoil';
import { Tax_AssessorQueryVariables } from '../../../recoil/__generated__/taxAssessor';
import { HeaderData } from '../types';
import { taxAssessorToHeaderData } from './formatters';

export const headerData = selectorFamily<
  HeaderData,
  Tax_AssessorQueryVariables
>({
  key: 'LOT-PAGE/HEADER-DATA',
  get:
    (params) =>
    ({ get }) => {
      const defaultValue = {
        address: '—',
        cityStateZip: '—',
        cherre_parcel_id: 'parcel_id',
      };

      const taxAssessorValue = get(taxAssessorQuery(params));
      const taxAssessorFormatted = taxAssessorToHeaderData(taxAssessorValue);

      return merge(defaultValue, taxAssessorFormatted);
    },
});

export const headerDealStageVisibleAtom = atom({
  key: 'LOT-PAGE/HEADER-DEAL-STAGE',
  default: false,
});
