import React from 'react';
import pluralize from 'pluralize';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 15,
    '&:last-child': {
      marginBottom: 0,
    },
  },
});

type LeftPanelItemProps = {
  label: string;
  count?: number | null;
  icon: React.ReactNode;
};

const LeftPanelItem: React.FC<LeftPanelItemProps> = ({
  label,
  count,
  icon,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <span style={{ marginRight: 15 }}>{icon}</span>
      <Typography variant='body1'>
        {!count ? '-' : count}
        &nbsp;
        {pluralize(label, count ?? 0, false)}
      </Typography>
    </div>
  );
};

export default LeftPanelItem;
