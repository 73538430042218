import { LAYERS, SOURCES } from '../const';

import { LayerProps } from 'src/components/Map/types';
import { Expression, Visibility } from 'mapbox-gl';
import { OnLayerClick } from '../types';
import { handleClick } from './utils';

type GetLeasesLayersProps = {
  buildingsVisibility?: Visibility;
  externalVisibility?: Visibility;
  internalVisibility?: Visibility;
  onClick?: OnLayerClick;
  selectedComps?: Array<number>;
  hoveredComps?: number[];
};

type Layer = (arg: GetLeasesLayersProps) => LayerProps;

const COMP_TYPES = {
  EXTERNAL: 'External',
  INTERNAL: 'Internal',
  BUILDING: 'Building',
};

const COMP_IMAGES = {
  INTERNAL_IDLE: 'LeasePin-Idle',
  INTERNAL_HOVER: 'LeasePin-Hover',
  INTERNAL_SELECTED: 'LeasePin-Focus',
  EXTERNAL_IDLE: 'LeasePin-Idle-External',
  EXTERNAL_HOVER: 'LeasePin-Hover-External',
  EXTERNAL_SELECTED: 'LeasePin-Focus-External',
  BUILDING_IDLE: 'LeasePin-Idle-Building',
  BUILDING_HOVER: 'LeasePin-Hover-Building',
  BUILDING_SELECTED: 'LeasePin-Focus-Building',
};

const COMP_PRIMARY_KEY = 'cherre_eqt_leases_pk';
const COMP_TYPE_FIELD_NAME = 'comp_type';
const ICON_ALLOW_OVERLAP = true;
const ICON_ANCHOR = 'bottom';
const LOW_ZOOM_SHIFT = 2;
const HIGH_ZOOM_SHIFT = 5;

const onLayerClick =
  (onClick: GetLeasesLayersProps['onClick'], layerName: string) => (e) =>
    handleClick(e, layerName, onClick);

const shift = (val1: number, val2: number) =>
  [
    'interpolate',
    ['linear'],
    ['zoom'],
    10,
    ['literal', [val1, 0]],
    18,
    ['literal', [val2, 0]],
  ] as Expression;

// INTERNAL COMPS LAYERS
const internal: Layer = ({
  internalVisibility,
  hoveredComps = [],
  selectedComps = [],
}) => {
  return {
    id: LAYERS.EQT_LEASE_COMPS_POINTS_INTERNAL,
    type: 'symbol',
    source: SOURCES.EQT_LEASE_COMPS_POINTS.name,
    'source-layer': SOURCES.EQT_LEASE_COMPS_POINTS.sourceLayerName,
    layout: {
      'icon-anchor': ICON_ANCHOR,
      'icon-allow-overlap': ICON_ALLOW_OVERLAP,
      'icon-offset': shift(-LOW_ZOOM_SHIFT, -HIGH_ZOOM_SHIFT),
      'icon-image': COMP_IMAGES.INTERNAL_IDLE,
      visibility: internalVisibility,
    },
    paint: {},
    filter: [
      'all',
      ['==', ['get', COMP_TYPE_FIELD_NAME], COMP_TYPES.INTERNAL],
      [
        '!',
        [
          'in',
          ['get', COMP_PRIMARY_KEY],
          ['literal', [...hoveredComps, ...selectedComps]],
        ],
      ],
    ],
  };
};

const internalHover: Layer = ({
  hoveredComps = [],
  internalVisibility,
  onClick,
}) => ({
  id: LAYERS.EQT_LEASE_COMPS_HOVERED_POINTS_INTERNAL,
  type: 'symbol',
  onClick: onClick
    ? onLayerClick(onClick, LAYERS.EQT_LEASE_COMPS_HOVERED_POINTS_INTERNAL)
    : undefined,
  source: SOURCES.EQT_LEASE_COMPS_POINTS.name,
  'source-layer': SOURCES.EQT_LEASE_COMPS_POINTS.sourceLayerName,
  layout: {
    'icon-anchor': ICON_ANCHOR,
    'icon-allow-overlap': ICON_ALLOW_OVERLAP,
    'icon-offset': shift(-LOW_ZOOM_SHIFT, -HIGH_ZOOM_SHIFT),
    'icon-image': COMP_IMAGES.INTERNAL_HOVER,
    visibility: internalVisibility,
  },
  paint: {},
  filter: [
    'all',
    ['==', ['get', COMP_TYPE_FIELD_NAME], 'Internal'],
    ['in', ['get', COMP_PRIMARY_KEY], ['literal', hoveredComps]],
  ],
});

const internalSelected: Layer = ({
  internalVisibility,
  selectedComps = [],
  onClick,
}) => ({
  id: LAYERS.EQT_LEASE_COMPS_SELECTED_POINTS_INTERNAL,
  type: 'symbol',
  onClick: onClick
    ? onLayerClick(onClick, LAYERS.EQT_LEASE_COMPS_SELECTED_POINTS_INTERNAL)
    : undefined,
  source: SOURCES.EQT_LEASE_COMPS_POINTS.name,
  'source-layer': SOURCES.EQT_LEASE_COMPS_POINTS.sourceLayerName,
  layout: {
    'icon-anchor': ICON_ANCHOR,
    'icon-allow-overlap': ICON_ALLOW_OVERLAP,
    'icon-offset': shift(-LOW_ZOOM_SHIFT, -HIGH_ZOOM_SHIFT),
    'icon-image': COMP_IMAGES.INTERNAL_SELECTED,
    visibility: internalVisibility,
  },
  paint: {},
  filter: [
    'all',
    ['==', ['get', COMP_TYPE_FIELD_NAME], 'Internal'],
    ['in', ['get', COMP_PRIMARY_KEY], ['literal', selectedComps]],
  ],
});

// EXTERNAL COMPS LAYERS
const external: Layer = ({
  externalVisibility,
  hoveredComps = [],
  selectedComps = [],
}) => {
  return {
    id: LAYERS.EQT_LEASE_COMPS_POINTS_EXTERNAL,
    type: 'symbol',
    source: SOURCES.EQT_LEASE_COMPS_POINTS.name,
    'source-layer': SOURCES.EQT_LEASE_COMPS_POINTS.sourceLayerName,
    layout: {
      'icon-anchor': ICON_ANCHOR,
      'icon-allow-overlap': ICON_ALLOW_OVERLAP,
      'icon-offset': shift(-LOW_ZOOM_SHIFT, -HIGH_ZOOM_SHIFT),
      'icon-image': COMP_IMAGES.EXTERNAL_IDLE,
      visibility: externalVisibility,
    },
    paint: {},
    filter: [
      'all',
      ['==', ['get', COMP_TYPE_FIELD_NAME], COMP_TYPES.EXTERNAL],
      [
        '!',
        [
          'in',
          ['get', COMP_PRIMARY_KEY],
          ['literal', [...hoveredComps, ...selectedComps]],
        ],
      ],
    ],
  };
};

const externalHover: Layer = ({
  hoveredComps = [],
  externalVisibility,
  onClick,
}) => ({
  id: LAYERS.EQT_LEASE_COMPS_HOVERED_POINTS_EXTERNAL,
  type: 'symbol',
  onClick: onClick
    ? onLayerClick(onClick, LAYERS.EQT_LEASE_COMPS_HOVERED_POINTS_EXTERNAL)
    : undefined,
  source: SOURCES.EQT_LEASE_COMPS_POINTS.name,
  'source-layer': SOURCES.EQT_LEASE_COMPS_POINTS.sourceLayerName,
  layout: {
    'icon-anchor': ICON_ANCHOR,
    'icon-allow-overlap': ICON_ALLOW_OVERLAP,
    'icon-image': COMP_IMAGES.EXTERNAL_HOVER,
    visibility: externalVisibility,
  },
  paint: {},
  filter: [
    'all',
    ['==', ['get', COMP_TYPE_FIELD_NAME], COMP_TYPES.EXTERNAL],
    ['in', ['get', COMP_PRIMARY_KEY], ['literal', hoveredComps]],
  ],
});

const externalSelected: Layer = ({
  externalVisibility,
  selectedComps = [],
  onClick,
}) => ({
  id: LAYERS.EQT_LEASE_COMPS_SELECTED_POINTS_EXTERNAL,
  type: 'symbol',
  onClick: onClick
    ? onLayerClick(onClick, LAYERS.EQT_LEASE_COMPS_SELECTED_POINTS_EXTERNAL)
    : undefined,
  source: SOURCES.EQT_LEASE_COMPS_POINTS.name,
  'source-layer': SOURCES.EQT_LEASE_COMPS_POINTS.sourceLayerName,
  layout: {
    'icon-anchor': ICON_ANCHOR,
    'icon-allow-overlap': ICON_ALLOW_OVERLAP,
    'icon-offset': shift(LOW_ZOOM_SHIFT, HIGH_ZOOM_SHIFT),
    'icon-image': COMP_IMAGES.EXTERNAL_SELECTED,
    visibility: externalVisibility,
  },
  paint: {},
  filter: [
    'all',
    ['==', ['get', COMP_TYPE_FIELD_NAME], COMP_TYPES.EXTERNAL],
    ['in', ['get', COMP_PRIMARY_KEY], ['literal', selectedComps]],
  ],
});

// BUILDING COMPS LAYERS
const building: Layer = ({
  buildingsVisibility,
  hoveredComps = [],
  selectedComps = [],
}) => {
  return {
    type: 'symbol',
    id: LAYERS.EQT_LEASE_COMPS_POINTS_BUILDING,
    source: SOURCES.EQT_LEASE_COMPS_POINTS.name,
    'source-layer': SOURCES.EQT_LEASE_COMPS_POINTS.sourceLayerName,
    layout: {
      'icon-anchor': ICON_ANCHOR,
      'icon-allow-overlap': ICON_ALLOW_OVERLAP,
      'icon-offset': shift(LOW_ZOOM_SHIFT, HIGH_ZOOM_SHIFT),
      'icon-image': COMP_IMAGES.BUILDING_IDLE,
      visibility: buildingsVisibility,
    },
    paint: {},
    filter: [
      'all',
      ['==', ['get', COMP_TYPE_FIELD_NAME], COMP_TYPES.BUILDING],
      [
        '!',
        [
          'in',
          ['get', COMP_PRIMARY_KEY],
          ['literal', [...hoveredComps, ...selectedComps]],
        ],
      ],
    ],
  };
};

const buildingHover: Layer = ({
  hoveredComps = [],
  buildingsVisibility,
  onClick,
}) => ({
  id: LAYERS.EQT_LEASE_COMPS_HOVERED_POINTS_BUILDING,
  type: 'symbol',
  onClick: onClick
    ? onLayerClick(onClick, LAYERS.EQT_LEASE_COMPS_HOVERED_POINTS_BUILDING)
    : undefined,
  source: SOURCES.EQT_LEASE_COMPS_POINTS.name,
  'source-layer': SOURCES.EQT_LEASE_COMPS_POINTS.sourceLayerName,
  layout: {
    'icon-anchor': ICON_ANCHOR,
    'icon-allow-overlap': ICON_ALLOW_OVERLAP,
    'icon-offset': shift(LOW_ZOOM_SHIFT, HIGH_ZOOM_SHIFT),
    'icon-image': COMP_IMAGES.BUILDING_HOVER,
    visibility: buildingsVisibility,
  },
  paint: {},
  filter: [
    'all',
    ['==', ['get', COMP_TYPE_FIELD_NAME], COMP_TYPES.BUILDING],
    ['in', ['get', COMP_PRIMARY_KEY], ['literal', hoveredComps]],
  ],
});

const buildingSelected: Layer = ({
  selectedComps = [],
  buildingsVisibility,
  onClick,
}) => ({
  id: LAYERS.EQT_LEASE_COMPS_SELECTED_POINTS_BUILDING,
  type: 'symbol',
  onClick: onClick
    ? onLayerClick(onClick, LAYERS.EQT_LEASE_COMPS_SELECTED_POINTS_BUILDING)
    : undefined,
  source: SOURCES.EQT_LEASE_COMPS_POINTS.name,
  'source-layer': SOURCES.EQT_LEASE_COMPS_POINTS.sourceLayerName,
  layout: {
    'icon-anchor': ICON_ANCHOR,
    'icon-allow-overlap': ICON_ALLOW_OVERLAP,
    'icon-offset': shift(LOW_ZOOM_SHIFT, HIGH_ZOOM_SHIFT),
    'icon-image': COMP_IMAGES.BUILDING_SELECTED,
    visibility: buildingsVisibility,
  },
  paint: {},
  filter: [
    'all',
    ['==', ['get', COMP_TYPE_FIELD_NAME], COMP_TYPES.BUILDING],
    ['in', ['get', COMP_PRIMARY_KEY], ['literal', selectedComps]],
  ],
});

export const getEQTLeaseCompsLayers = ({
  hoveredComps,
  selectedComps,
  buildingsVisibility,
  internalVisibility,
  externalVisibility,
  onClick,
}: GetLeasesLayersProps) => [
  internal({ internalVisibility, hoveredComps, selectedComps }),
  internalHover({ internalVisibility, hoveredComps: hoveredComps, onClick }),
  internalSelected({ internalVisibility, selectedComps, onClick }),

  external({ externalVisibility, hoveredComps, selectedComps }),
  externalHover({ externalVisibility, hoveredComps: hoveredComps, onClick }),
  externalSelected({ externalVisibility, selectedComps, onClick }),

  building({ buildingsVisibility, hoveredComps, selectedComps }),
  buildingHover({ buildingsVisibility, hoveredComps: hoveredComps, onClick }),
  buildingSelected({ buildingsVisibility, selectedComps, onClick }),
];
