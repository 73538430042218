import { LABELS_FONT, LAYERS } from '../const';
import { Visibility } from 'mapbox-gl';
import { LayerProps } from 'src/components/Map/types';

function getLines({ visibility }: Partial<GetLayersArg>): LayerProps {
  return {
    id: LAYERS.COUNTIES_LINE,
    source: 'census',
    'source-layer': 'census-co',
    type: 'line',
    paint: {
      'line-color': 'hsl(280, 80%, 50%)',
      'line-width': ['interpolate', ['linear'], ['zoom'], 7, 0.5, 12, 1],
    },
    layout: {
      visibility,
    },
  };
}

function getFill({ visibility }: Partial<GetLayersArg>): LayerProps {
  return {
    id: LAYERS.COUNTIES_FILL,
    source: 'census',
    'source-layer': 'census-co',
    type: 'fill',
    paint: {
      'fill-color': 'hsla(0, 0%, 0%, 0)',
    },
    layout: {
      visibility,
    },
  };
}

function getSelectedLines({ counties }: Partial<GetLayersArg>): LayerProps {
  return {
    id: LAYERS.SELECTED_COUNTIES_LINE,
    source: 'census',
    'source-layer': 'census-co',
    type: 'line',
    paint: {
      'line-color': 'hsl(280, 100%, 66%)',
      'line-width': 5,
    },
    filter: ['in', ['get', 'GEO_ID'], ['literal', counties]],
  };
}

function getSelectedFill({ counties }: Partial<GetLayersArg>): LayerProps {
  return {
    id: LAYERS.SELECTED_COUNTIES_FILL,
    source: 'census',
    'source-layer': 'census-co',
    type: 'fill',
    paint: {
      'fill-color': 'hsla(280, 100%, 66%, 0.3)',
    },
    filter: ['in', ['get', 'GEO_ID'], ['literal', counties]],
  };
}

function getLabels({ visibility }: Partial<GetLayersArg>): LayerProps {
  return {
    id: LAYERS.COUNTIES_LABEL,
    source: 'census',
    'source-layer': 'census-co-labels',
    type: 'symbol',
    layout: {
      'text-field': [
        'format',
        ['get', 'GEO_NAME'],
        {},
        // FIPS codes
        // '\n',{},
        // ['get', 'GEO_ID'], {'font-scale': 0.8},
      ],
      'text-font': LABELS_FONT,
      'text-size': 24,
      'text-allow-overlap': true,
      'text-ignore-placement': true,
      visibility,
    },
    paint: {
      'text-color': 'hsl(280, 80%, 50%)',
      'text-halo-color': '#eee',
      'text-halo-width': 2,
    },
  };
}

type GetLayersArg = {
  counties: string[];
  visibility: Visibility;
};

function getCountiesLayers({
  counties = [],
  visibility,
}: GetLayersArg): LayerProps[] {
  return [
    getLines({ visibility }),
    getFill({ visibility }),
    getSelectedLines({ counties }),
    getSelectedFill({ counties }),
    getLabels({ visibility }),
  ];
}

export default getCountiesLayers;
