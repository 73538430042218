import axios from 'axios';
import {
  selectorFamily,
  useGetRecoilValueInfo_UNSTABLE,
  useRecoilCallback,
  useRecoilValueLoadable,
} from 'recoil';
import * as T from './types';

const piplSelector = selectorFamily<T.Response | undefined, Readonly<T.Query>>({
  key: 'PIPL-SELECTOR',
  get: (query) => async () => {
    const searchParams = new URLSearchParams();
    Object.entries(query).forEach(([k, v]) => searchParams.set(k, `${v}`));
    const queryStr = searchParams.toString();
    const url = `/api/v1/pipl/get-contact?${queryStr}`;

    try {
      const response = await axios.get<T.Response>(url);
      return response.data;
    } catch (e) {
      return;
    }
  },
});

export const usePIPL = (query: T.Query) => {
  return useRecoilValueLoadable(piplSelector(query));
};

export const useGetPIPL = (query: T.Query) => {
  const info = useGetRecoilValueInfo_UNSTABLE()(piplSelector(query));
  return info.loadable?.contents as T.Response | undefined;
};

export const usePreloadPIPL = () => {
  return useRecoilCallback(
    ({ snapshot }) =>
      async (query: T.Query) => {
        const release = snapshot.retain();
        await snapshot.getPromise(piplSelector(query));
        release();
      },
    []
  );
};
