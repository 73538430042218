// RCA FIELD NAMES
export const SQ_FT = 'sq_ft';
export const PRICE = 'price';
export const YEAR_BUILT = 'year_built';
export const STATUS_DATE = 'status_date'; // Sales or Transaction date
export const MAIN_PROPERTY_TYPE = 'main_property_type';
export const PRICE_PER_SQUARE_FOOT_UNIT = 'price_per_sq_ft_unit';

export const DEFAULT_MIN_MAX = { min: '', max: '' };
export const DEFAULT_ARRAY = [];
