import React from 'react';
import { Box, Button } from '@material-ui/core';
import { CloseButton } from './CloseButton';
import { useStyles } from './style';
import { useOrganizationPropsValue } from 'src/hooks';
import PropertyLists from 'src/products/core-prospect/Layout/LotpageNavigation/PropertyList';
import Bookmark from '@material-ui/icons/Bookmark';
import BusinessCenter from '@material-ui/icons/BusinessCenter';
import Close from '@material-ui/icons/Close';
import { useParams } from 'react-router';
import { useLocalDeal } from 'src/services/Dealcloud/localDeal';
import { DealOverlayProps } from './DealOverlay';
import { getString } from 'src/utils/getString';
import { DashboardIcon } from '@cherre-frontend/ui';
import { useDashboardPropertyLink } from 'src/hooks/useDashboardPropertyLink';

export const NoPortfolioDealOverlay: React.VFC<DealOverlayProps> = ({
  taxAssessorId,
  property,
  onClose,
  showOnClose,
  cherreParcelId,
}) => {
  const classes = useStyles();

  const { id: dealId } = useParams<{ id: string }>();

  const { addAsset, removeAsset, deal } = useLocalDeal(
    dealId !== 'new' ? Number(dealId) : 'new'
  );

  const isDealsIntegrationAvailable =
    useOrganizationPropsValue('deals_enabled');

  const dashboardPropertyLink = useDashboardPropertyLink(cherreParcelId);

  const isPartOfPortfolio = !!deal?.assets.find(
    (a) => a.taxAssessorId === taxAssessorId
  );

  if (!isDealsIntegrationAvailable || !property) {
    return null;
  }

  return (
    <Box className={classes.nodeal}>
      <Button
        startIcon={
          !isPartOfPortfolio ? (
            <BusinessCenter color='primary' />
          ) : (
            <Close color='primary' />
          )
        }
        className={`${classes.addbtn} js-${
          isPartOfPortfolio ? 'remove' : 'add'
        }-portfolio-asset`}
        onClick={() => {
          if (isPartOfPortfolio) {
            removeAsset(taxAssessorId);
          } else {
            addAsset({
              address: property.address,
              buildingArea: getString(property.building_sq_ft),
              city: property.city,
              country: 'USA',
              isMultiFam: '',
              latitude: property.latitude,
              longitude: property.longitude,
              state: property.state,
              taxAssessorId: property.tax_assessor_id,
              unitType: '',
              yearBuilt: getString(property.year_built),
              zipCode: property.zip,
              parcelId: property.cherre_parcel_id || '',
              numberOfAppartments: getString(property.units_count),
            });
          }
          // add a delay before closing the overlay to prevent click event
          // from being triggered on the map layer behind the overlay (which would open the overlay again)
          setTimeout(() => onClose?.(), 300);
        }}
      >
        {!isPartOfPortfolio ? 'Add to portfolio' : 'Remove from portfolio'}
      </Button>

      {dashboardPropertyLink && (
        <Button
          variant='contained'
          size='small'
          className={classes.button}
          onClick={() => window.open(dashboardPropertyLink, '_blank')}
        >
          <DashboardIcon className={classes.icon} color='primary' />
        </Button>
      )}

      <PropertyLists
        showChips={false}
        property_id={taxAssessorId}
        property_code_type='tax_assessor_id'
        noStyle
        buttonComponent={
          <Button variant='contained' size='small' className={classes.button}>
            <Bookmark className={classes.icon} color='primary' />
          </Button>
        }
      />

      {onClose && showOnClose && <CloseButton onClose={onClose} />}
    </Box>
  );
};
