import { DemographyLayerName } from '../../types';

export type HeatmapSettings = {
  source: string;
  sourceLayer: string;
  colorScheme: string[];
  geometryIdField?: string;
  numberFormatFunc?: (x: number) => string;
};

export const heatmaps: Record<
  DemographyLayerName,
  Record<string, HeatmapSettings>
> = {
  average_household_income: {
    states: {
      source: 'colorpleth',
      sourceLayer: 'census-st',
      geometryIdField: 'GEO_ID',
      colorScheme: ['hsl(195,60%,80%)', 'hsl(195,60%,30%)'],
      numberFormatFunc: (x) => '$' + Math.round(x).toLocaleString(),
    },
    counties: {
      source: 'colorpleth',
      sourceLayer: 'census-co',
      geometryIdField: 'GEO_ID',
      colorScheme: ['hsl(195,60%,80%)', 'hsl(195,60%,30%)'],
      numberFormatFunc: (x) => '$' + Math.round(x).toLocaleString(),
    },
    zipcodes: {
      source: 'colorpleth',
      sourceLayer: 'census-zi',
      geometryIdField: 'GEO_ID',
      colorScheme: ['hsl(195,60%,80%)', 'hsl(195,60%,30%)'],
      numberFormatFunc: (x) => '$' + Math.round(x).toLocaleString(),
    },
  },
  population_median_age: {
    states: {
      source: 'colorpleth',
      sourceLayer: 'census-st',
      // colorScheme: 'YlGnBu',
      colorScheme: ['hsl(60, 100%, 92%)', 'hsl(224, 83%, 28%)'],
    },
    counties: {
      source: 'colorpleth',
      sourceLayer: 'census-co',
      // colorScheme: 'YlGnBu',
      colorScheme: ['hsl(60, 100%, 92%)', 'hsl(224, 83%, 28%)'],
    },
    zipcodes: {
      source: 'colorpleth',
      sourceLayer: 'census-zi',
      // colorScheme: 'YlGnBu',
      colorScheme: ['hsl(60, 100%, 92%)', 'hsl(224, 83%, 28%)'],
    },
  },
  crime_total_risk: {
    states: {
      source: 'colorpleth',
      sourceLayer: 'census-st',
      colorScheme: ['hsl(195,60%,80%)', 'hsl(195,60%,30%)'],
    },
    counties: {
      source: 'colorpleth',
      sourceLayer: 'census-co',
      colorScheme: ['hsl(195,60%,80%)', 'hsl(195,60%,30%)'],
    },
    zipcodes: {
      source: 'colorpleth',
      sourceLayer: 'census-zi',
      colorScheme: ['hsl(195,60%,80%)', 'hsl(195,60%,30%)'],
    },
  },
};
