import axios from 'axios';

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRecoilCallback } from 'recoil';
import { showModal } from 'src/products/shell/redux/modals/actions';
import { sharedResourcesSelectorFamily } from '../recoil';
import { ResourceType, TargetType } from '../recoil/types';
import { usePropertyLists } from './usePropertyLists';
import { useSnackbarCherre } from './useSnackbarCherre';

export const useShareListCreateModal = (
  listId: number,
  onRequestClose: () => void,
  targets: { targetId: number; targetType: TargetType }[],
  mode: 'create' | 'edit'
) => {
  const { propertyLists, propertyListsRefresh } = usePropertyLists();

  const propertyList = propertyLists.find((l) => l.id === listId);

  const [isSaving, setIsSaving] = useState(false);

  const { enqueueSnackbar } = useSnackbarCherre();

  const onClickDone = useRecoilCallback(
    ({ refresh }) =>
      async () => {
        setIsSaving(true);

        try {
          await axios.post(`/api/v1/propertyList/${listId}/share`, targets);

          const successMsg =
            mode === 'create'
              ? `The list was shared successfully`
              : `The shared group was updated`;

          enqueueSnackbar(successMsg, {
            variant: 'success',
          });

          refresh(
            sharedResourcesSelectorFamily({
              resourceType: ResourceType.PropertyList,
              resourceId: listId,
            })
          );
          propertyListsRefresh();
          onRequestClose();
        } catch (e) {
          console.error(e);
          enqueueSnackbar('Failed to share the list', {
            variant: 'error',
          });
          setIsSaving(false);
        }
      },
    [setIsSaving, targets]
  );

  return {
    propertyList,
    isSaving,
    onClickDone,
    sharedWith: undefined,
    isLoadingSharedWith: undefined,
  };
};

export type CreateShareListModalHook = typeof useShareListCreateModal;

export const useShareListCreate = () => {
  const dispatch = useDispatch();

  return useRecoilCallback(
    () => async (listId: number) => {
      dispatch(
        showModal('SHARE_LIST', {
          hook: useShareListCreateModal,
          listId,
          mode: 'create',
        })
      );
    },
    []
  );
};
