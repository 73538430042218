import React from 'react';
import { useDispatch } from 'react-redux';

import SearchInput from '../../../components/SearchInput';
import CoreProspectSavedSearches from './SavedSearches';
import DeleteSnackbar from '../../../components/DeleteSnackbar';
import {
  deleteView,
  undoDeleteView,
} from 'src/products/core-prospect/redux/query/actions';

const Searches = ({ views = [], filterable = true, ...otherProps }) => {
  const [isDeleteSnackbarOpen, toggleDeleteSnackbar] = React.useState(false);
  const [selectedView, selectView] = React.useState(null);
  const [filterText, setFilterText] = React.useState('');
  const dispatch = useDispatch();

  const filteredViews = (views || []).filter((view) => {
    return view.view.name.toLowerCase().match(filterText.toLowerCase());
  });

  return (
    <div {...otherProps}>
      {filterable && (
        <SearchInput
          id='js-core-prospect-filters-searches-list'
          placeholder='Filter Searches'
          filterText={filterText}
          setFilterText={setFilterText}
        />
      )}
      <CoreProspectSavedSearches
        filteredViews={filteredViews}
        filterText={filterText}
        deleteView={async (view) => {
          selectView(view);
          dispatch(deleteView(view.id));
          toggleDeleteSnackbar(true);
        }}
      />
      <DeleteSnackbar
        isOpen={isDeleteSnackbarOpen}
        onClose={() => toggleDeleteSnackbar(false)}
        text={`${selectedView && selectedView.name} deleted`}
        onActionClick={() => {
          dispatch(undoDeleteView(selectedView.id));
          toggleDeleteSnackbar(false);
        }}
      />
    </div>
  );
};

export default Searches;
