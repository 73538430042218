import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Map as MapboxMap } from 'mapbox-gl';
import { Feature, MultiPolygon } from '@turf/helpers';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { demographicsDataSelector, demographicsFeatureAtom } from './recoil';

import Map, { RefProps } from 'src/components/Map/index.new';
import { Source, Viewport } from 'src/components/Map/types';
import {
  DrawingMode,
  OnDrawComplete,
} from 'src/components/Map/components/DrawEditor/types';

import getClustersSource from './sources/get-clusters-source';
import getUSAParcelsSource from './sources/get-usa-parcels-source';
import getNeighborhoodsSource from './sources/get-neighborhoods-source';
import getSchoolsDistrictsSource from './sources/get-schools-districts-source';
import getCountiesSource from './sources/get-counties-source';
import getStatesSource from './sources/get-states-source';
import getZipodesSource from './sources/get-zipcodes-source';
import getReisBoundariesSource from './sources/get-reis-boundaries-source';
import getColorplethSource from './sources/get-colorpleth-source';
import getCustomFeatureSource from './sources/get-custom-feature-source';

import getUsaParcelsLayers from './layers/get-usa-parcels-layers';
import getClustersLayers from './layers/get-clusters-layers';
import getNeighborhoodsLayers from './layers/get-neighborhoods-layers';
import getReisBoundariesLayers from './layers/get-reis-boundaries-layers';
import getZipcodesLayers from './layers/get-zipcodes-layers';
import getCountiesLayers from './layers/get-counties-layers';
import getSchoolsDistrictsLayers from './layers/get-schools-disticts-layers';
import getColorplethLayers from './layers/get-colorpleth-layers';
import getStatesLayers from './layers/get-states-layers';
import getCustomFeatureLayers from './layers/get-custom-feature-layers';

import DemographicsLegend from './components/DemographicsLegend';
import LayersSelector from './components/LayersSelector';
import { getSortedSources, handleHover, getLayerVisibility } from './utils';
import {
  GeographyLayerName,
  MapCluster,
  MapDeal,
  MapParcel,
  OnLayerClick,
} from './types';
import {
  geSelectedDealsLayers,
  getDealsLayers,
  getHoveredDealsLayers,
} from './layers/get-deals-layer';
import { getDealsSource } from './sources/getDealsSource';
import { LAYERS } from './const';
import { Legends } from './components/Legends';
import DealStagesLegend from './components/DealStagesLegend';
import { MapEvent } from 'react-map-gl';
import { CHERRE_SATELLITE_MAP_STYLE } from 'src/const';
import getEQTLeaseCompsSource from './sources/get-eqt-lease-comps-source';
import { getEQTLeaseCompsLayers } from './layers/get-eqt-lease-comps-layers';
import { useMapLayers } from './hooks/useMapLayers';
import LeaseCompsLegend from './components/LeaseCompsLegend';

const NYBoundingBox = {
  zoom: 12.08330183628432,
  latitude: 40.75202353325635,
  longitude: -73.93626282945806,
};

type Props = {
  deals?: MapDeal[];
  zoom: number;
  latitude: number;
  longitude: number;
  selectedLeaseId?: number;
  customFeature?: Feature<MultiPolygon>;
  drawingMode?: DrawingMode;
  clusters?: MapCluster[];
  parcels?: MapParcel[];
  satellite?: boolean;
  scrollZoom?: boolean;
  isLayersSelectorVisible?: boolean;
  isDealsLegendVisible?: boolean;
  onLoad?: (map: MapboxMap) => void;
  onViewportChange?: (map: MapboxMap) => void;
  onDrawComplete?: OnDrawComplete;
  onParcelClick?: OnLayerClick;
  onLeaseClick?: OnLayerClick;
  onClusterClick?: OnLayerClick;
  onDealClick?: OnLayerClick;
};

const ReactMap: React.FC<Props> = (props) => {
  const {
    zoom,
    latitude,
    longitude,
    selectedLeaseId,

    customFeature,
    parcels = [],
    clusters = [],
    deals = [],
    onParcelClick,
    onLeaseClick,
    onClusterClick,
    onDealClick,
    satellite,
    scrollZoom,
    onLoad,
    onViewportChange,
    onDrawComplete,
    drawingMode,
    children,
    isLayersSelectorVisible,
    isDealsLegendVisible,
  } = props;
  const [, setViewport] = useState<Viewport>();

  const mapRef = useRef<RefProps>(null);
  const map = mapRef?.current?.map;

  const defaultBoundingBox = NYBoundingBox;

  const [demographicsFeature, setDemographicsFeature] = useRecoilState(
    demographicsFeatureAtom
  );

  const zipcodes =
    demographicsFeature?.properties?.GEO_TYPE === 'ZI'
      ? demographicsFeature.properties.GEO_ID
      : undefined;

  const counties =
    demographicsFeature?.properties?.GEO_TYPE === 'CO'
      ? demographicsFeature.properties.GEO_ID
      : undefined;

  const states =
    demographicsFeature?.properties?.GEO_TYPE === 'ST'
      ? demographicsFeature.properties.GEO_ID
      : undefined;

  const {
    layerGroups,
    setLayerGroups,
    heatmapSettings,
    selectedLayerNames,
    selectedDemographicsLayerName,
    demographicsGeographyName,
  } = useMapLayers({ zoom: map?.getZoom() });

  const isLeaseCompsLegendVisible = useMemo(() => {
    return (
      selectedLayerNames.includes(GeographyLayerName.leaseCompsExternal) ||
      selectedLayerNames.includes(GeographyLayerName.leaseCompsInternal) ||
      selectedLayerNames.includes(GeographyLayerName.leaseCompsBuildings)
    );
  }, [selectedLayerNames]);

  const demographicsDataLoadable = useRecoilValueLoadable(
    demographicsDataSelector({
      bounds: map?.getBounds().toArray(),
      zoom: map?.getZoom(),
      selectedDemographicsLayerName,
      demographicsGeographyName,
    })
  );

  const demographicsData = demographicsDataLoadable.valueMaybe() || undefined;

  const reisBoundariesSource = useMemo(
    () => ({
      source: getReisBoundariesSource(),
      layers: getReisBoundariesLayers({
        apartmentVisibility: getLayerVisibility(
          selectedLayerNames,
          GeographyLayerName.reisBoundariesApartments
        ),
        flexVisibility: getLayerVisibility(
          selectedLayerNames,
          GeographyLayerName.reisBoundariesFlex
        ),
        officeVisibility: getLayerVisibility(
          selectedLayerNames,
          GeographyLayerName.reisBoundariesOffice
        ),
        retailVisibility: getLayerVisibility(
          selectedLayerNames,
          GeographyLayerName.reisBoundariesRetail
        ),
        warehouseVisibility: getLayerVisibility(
          selectedLayerNames,
          GeographyLayerName.reisBoundariesWarehouse
        ),
      }),
    }),
    [selectedLayerNames]
  );

  const zipcodesSource = useMemo(
    () => ({
      source: getZipodesSource(),
      layers: getZipcodesLayers({
        zipcodes: zipcodes ? [zipcodes] : [],
        visibility: getLayerVisibility(
          selectedLayerNames,
          GeographyLayerName.zipcodes
        ),
      }),
    }),
    [selectedLayerNames]
  );

  const [hoveredEqtComps, setHoveredEqtComps] = useState<number[]>([]);
  const selectedEqtComps = useMemo(
    () => [selectedLeaseId].filter(Boolean) as number[],
    [selectedLeaseId]
  );
  const EQTLeaseCompsSource = useMemo(
    () => ({
      source: getEQTLeaseCompsSource(),
      layers: getEQTLeaseCompsLayers({
        hoveredComps: hoveredEqtComps,
        selectedComps: selectedEqtComps,
        onClick: (data) => onLeaseClick?.(data),
        buildingsVisibility: getLayerVisibility(
          selectedLayerNames,
          GeographyLayerName.leaseCompsBuildings
        ),
        externalVisibility: getLayerVisibility(
          selectedLayerNames,
          GeographyLayerName.leaseCompsExternal
        ),
        internalVisibility: getLayerVisibility(
          selectedLayerNames,
          GeographyLayerName.leaseCompsInternal
        ),
      }),
    }),
    [selectedLayerNames, hoveredEqtComps, selectedEqtComps]
  );

  const USAParcelsSource = useMemo(
    () => ({
      source: getUSAParcelsSource(),
      layers: getUsaParcelsLayers({
        onClick: (data) => onParcelClick?.(data),
        selectedParcels: parcels.map((parcel) => parcel.id),
        visibility: getLayerVisibility(
          selectedLayerNames,
          GeographyLayerName.parcels
        ),
      }),
    }),
    [selectedLayerNames, parcels, onParcelClick]
  );

  const schoolsDistrictsSource = useMemo(
    () => ({
      source: getSchoolsDistrictsSource(),
      layers: getSchoolsDistrictsLayers({
        visibility: getLayerVisibility(
          selectedLayerNames,
          GeographyLayerName.schools
        ),
      }),
    }),
    [selectedLayerNames]
  );

  const countiesSource = useMemo(
    () => ({
      source: getCountiesSource(),
      layers: getCountiesLayers({
        counties: counties ? [counties] : [],
        visibility: getLayerVisibility(
          selectedLayerNames,
          GeographyLayerName.counties
        ),
      }),
    }),
    [selectedLayerNames, counties]
  );

  const statesSource = useMemo(
    () => ({
      source: getStatesSource(),
      layers: getStatesLayers({
        states: states ? [states] : [],
        visibility: getLayerVisibility(
          selectedLayerNames,
          GeographyLayerName.states
        ),
      }),
    }),
    [selectedLayerNames, states]
  );

  const neighborhoodsSource = useMemo(
    () => ({
      source: getNeighborhoodsSource('nd'),
      layers: getNeighborhoodsLayers({
        type: 'nd',
        visibility: getLayerVisibility(
          selectedLayerNames,
          GeographyLayerName.neighborhoods
        ),
      }),
    }),
    [selectedLayerNames]
  );

  const customFeatureSource = useMemo(
    () =>
      customFeature
        ? {
            source: getCustomFeatureSource(customFeature),
            layers: getCustomFeatureLayers(customFeature),
          }
        : undefined,
    [customFeature]
  );

  const clustersSource = useMemo(
    () => ({
      source: getClustersSource(clusters),
      layers: getClustersLayers({
        onClick: (data) => onClusterClick?.(data),
      }),
    }),
    [clusters, onClusterClick]
  );

  const isHeatmapLayerVisible =
    heatmapSettings && demographicsDataLoadable.state === 'hasValue';

  const colorplethSource = useMemo(
    () =>
      isHeatmapLayerVisible
        ? {
            source: getColorplethSource(),
            layers: getColorplethLayers({
              demographicsData: demographicsData ? demographicsData : [],
              heatmapSettings,
              onClick: ({ feature }) => {
                setDemographicsFeature(feature);
              },
            }),
          }
        : undefined,
    [
      isHeatmapLayerVisible,
      demographicsData,
      heatmapSettings,
      setDemographicsFeature,
    ]
  );

  const parcelsHoveredDeals = useMemo(
    () => parcels.filter((p) => p.hovered).map((p) => p.id),
    [parcels]
  );

  const [hoveredDeals, setHoveredDeals] = useState<string[]>([]);

  useEffect(() => {
    setHoveredDeals([...parcelsHoveredDeals]);
  }, [parcelsHoveredDeals]);

  const selectedDeals = useMemo(
    () => parcels.filter((p) => p.selected).map((p) => p.id),
    [parcels]
  );

  const onHover = useCallback(
    (e: MapEvent): void => {
      handleHover(e, mapRef.current?.map);

      const [feature] = e.features || [];

      if (
        [
          LAYERS.EQT_LEASE_COMPS_POINTS_INTERNAL,
          LAYERS.EQT_LEASE_COMPS_POINTS_EXTERNAL,
          LAYERS.EQT_LEASE_COMPS_POINTS_BUILDING,
          LAYERS.EQT_LEASE_COMPS_HOVERED_POINTS_INTERNAL,
          LAYERS.EQT_LEASE_COMPS_HOVERED_POINTS_EXTERNAL,
          LAYERS.EQT_LEASE_COMPS_HOVERED_POINTS_BUILDING,
        ].includes(feature?.layer?.id)
      ) {
        setHoveredEqtComps([Number(feature?.properties?.cherre_eqt_leases_pk)]);
      } else {
        setHoveredEqtComps((s) => (s.length > 0 ? [] : s));
      }

      if (
        [LAYERS.DEALS_ON_MAP, LAYERS.DEALS_ON_MAP_HOVERED].includes(
          feature?.layer?.id
        )
      ) {
        setHoveredDeals([feature?.properties?.cherreParcelId]);
      } else {
        setHoveredDeals((s) => (s.length > 0 ? [] : s));
      }
    },
    [setHoveredDeals]
  );

  const dealsSource = useMemo(
    () => ({
      source: getDealsSource(deals),
      layers: [
        ...getDealsLayers(hoveredDeals, selectedDeals),
        ...getHoveredDealsLayers(hoveredDeals, onDealClick),
        ...geSelectedDealsLayers(selectedDeals),
      ],
    }),
    [deals, hoveredDeals, selectedDeals, onDealClick]
  );

  const sources = useMemo(
    () =>
      [
        reisBoundariesSource,
        zipcodesSource,
        USAParcelsSource,
        EQTLeaseCompsSource,
        schoolsDistrictsSource,
        countiesSource,
        statesSource,
        neighborhoodsSource,
        customFeatureSource,
        clustersSource,
        colorplethSource,
        dealsSource,
      ].filter((source): source is Source => !!source),
    [
      reisBoundariesSource,
      zipcodesSource,
      USAParcelsSource,
      EQTLeaseCompsSource,
      schoolsDistrictsSource,
      countiesSource,
      statesSource,
      neighborhoodsSource,
      customFeatureSource,
      clustersSource,
      colorplethSource,
      dealsSource,
    ]
  );

  const sortedSources = useMemo(() => {
    const sorted = getSortedSources(sources);

    sorted.forEach((source, index) => {
      if (!source.layers[0].beforeId) {
        source.layers[0].beforeId = sorted[index - 1]?.layers[0].id;
      }
    });

    return sorted;
  }, [sources]);

  const interactiveLayerIds = useMemo(
    () =>
      [
        isHeatmapLayerVisible ? LAYERS.COLORPLETH : '',
        LAYERS.DEALS_ON_MAP,
        LAYERS.DEALS_ON_MAP_HOVERED,
        LAYERS.EQT_LEASE_COMPS_POINTS_INTERNAL,
        LAYERS.EQT_LEASE_COMPS_POINTS_EXTERNAL,
        LAYERS.EQT_LEASE_COMPS_POINTS_BUILDING,
        LAYERS.EQT_LEASE_COMPS_HOVERED_POINTS_INTERNAL,
        LAYERS.EQT_LEASE_COMPS_HOVERED_POINTS_EXTERNAL,
        LAYERS.EQT_LEASE_COMPS_HOVERED_POINTS_BUILDING,
        LAYERS.CLUSTER,
        LAYERS.PARCELS_FILL,
        LAYERS.SELECTED_PARCELS_FILL,
      ].filter(Boolean),
    [isHeatmapLayerVisible]
  );

  const onLoadCallback = useCallback(
    (map) => {
      onLoad?.(map);
      setViewport({
        latitude: map.getCenter().lat,
        longitude: map.getCenter().lng,
        zoom: map.getZoom(),
      });
    },
    [onLoad, setViewport]
  );

  const onViewportChangeCallback = useCallback(
    (map) => {
      onViewportChange?.(map);
      setViewport({
        latitude: map.getCenter().lat,
        longitude: map.getCenter().lng,
        zoom: map.getZoom(),
      });
    },
    [onViewportChange, setViewport]
  );

  return (
    <>
      <div
        id='core-prospect-map'
        style={{ width: '100%', height: '100%', position: 'relative' }}
      >
        <Map
          interactiveLayerIds={interactiveLayerIds}
          ref={mapRef}
          onLoad={onLoadCallback}
          onViewportChange={onViewportChangeCallback}
          drawingMode={drawingMode}
          onDrawComplete={onDrawComplete}
          onHover={onHover}
          sources={sortedSources}
          width='100%'
          height='100%'
          zoom={zoom || defaultBoundingBox.zoom}
          latitude={latitude || defaultBoundingBox.latitude}
          longitude={longitude || defaultBoundingBox.longitude}
          scrollZoom={scrollZoom}
          mapStyle={satellite ? CHERRE_SATELLITE_MAP_STYLE : undefined}
        >
          {children}
        </Map>
        {isLayersSelectorVisible && (
          <LayersSelector onChange={setLayerGroups} layerGroups={layerGroups} />
        )}
      </div>
      <Legends>
        {isDealsLegendVisible && <DealStagesLegend />}
        {isLeaseCompsLegendVisible && <LeaseCompsLegend />}
        <DemographicsLegend
          demographicsData={demographicsDataLoadable}
          layerName={selectedDemographicsLayerName}
          heatmapSettings={heatmapSettings}
        />
      </Legends>
    </>
  );
};

export default ReactMap;
