import { useParams } from 'react-router';
import { useRecoilValue } from 'recoil';
import { LotPageParams } from '../../types';
import { commercialSaleComps } from './recoil';
import * as T from './types';

const hook: T.UseCommercialSaleComps = () => {
  const { id } = useParams<LotPageParams>();
  return useRecoilValue(commercialSaleComps({ tax_assessor_id: Number(id) }));
};

export default hook;
