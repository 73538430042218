import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

export const useDebouncedValue = <T>(defaultValue: T, ms = 200) => {
  const [value, setValue] = useState(defaultValue);
  const [valueDelayed, setValueDelayed] = useState(defaultValue);

  const setValueDebounced = useCallback(
    debounce((v: T) => {
      setValueDelayed(v);
    }, ms),
    [setValueDelayed, ms]
  );

  useEffect(() => {
    return () => {
      setValueDebounced.cancel();
    };
  }, [setValueDebounced]);

  const setValueNew = useCallback(
    (v: T) => {
      setValue(v);
      setValueDebounced(v);
    },
    [setValue, setValueDebounced]
  );

  return [value, valueDelayed, setValueNew] as const;
};
