import React from 'react';
import { Chip, Popover } from '@material-ui/core';
import { removePropertyListEntry } from 'src/products/core-prospect/redux/propertyLists/actions';
import { useDispatch } from 'react-redux';

const PropertyLists = ({ cellData }) => {
  const lists = cellData || [];

  const [list, ...otherLists] = lists;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!list) {
    return null;
  }

  const open = Boolean(anchorEl);

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <Chip
        style={{ marginBottom: 5, marginLeft: 5 }}
        onDelete={() => {
          dispatch(removePropertyListEntry({ id: list.entry_id }));
        }}
        size='small'
        label={
          list.name.length > 20 ? list.name.slice(0, 20) + '...' : list.name
        }
      />
      {otherLists.length > 0 && (
        <Chip
          onClick={handleClick}
          style={{ marginBottom: 5, marginLeft: 5 }}
          size='small'
          label={`+${otherLists.length}`}
        />
      )}
      <Popover
        open={open && otherLists.length > 0}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div style={{ padding: '8px 8px 3px 3px' }}>
          {otherLists.map((list, index) => (
            <Chip
              key={index}
              style={{ marginBottom: 5, marginLeft: 5 }}
              onDelete={() => {
                dispatch(removePropertyListEntry({ id: list.entry_id }));
              }}
              size='small'
              label={
                list.name.length > 20
                  ? list.name.slice(0, 20) + '...'
                  : list.name
              }
            />
          ))}
        </div>
      </Popover>
    </div>
  );
};

export default PropertyLists;
