import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { omit } from 'lodash';
import { makeStyles, Input, InputAdornment } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles(() => ({
  container: {
    borderRadius: 10,
    boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    padding: '20px 20px 14px 20px',
  },
  input: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    '& input': {
      padding: 0,
    },
  },
  searchIcon: {
    fontSize: 20,
    color: grey[600],
  },
}));

const AutocompleteHomePageInput = ({ customProps = {}, ...props }) => {
  const classes = useStyles();
  return (
    <div ref={props.inputRef} className={classes.container}>
      <Input
        {...omit(props, ['InputLabelProps', 'InputProps'])}
        {...props.InputProps}
        placeholder={customProps.label || 'Search Address, Market, Zip'}
        className={classes.input}
        disableUnderline
        startAdornment={
          <InputAdornment position='start'>
            <SearchIcon className={classes.searchIcon} />
          </InputAdornment>
        }
      />
    </div>
  );
};

export default AutocompleteHomePageInput;
