export const namespace = 'core-prospect-property-list';
const ns = (name) => `${namespace}/${name}`;

export const DELETE_PROPERTY_LIST_REQUEST = ns('DELETE_PROPERTY_LIST_REQUEST');
export const DELETE_PROPERTY_LIST_SUCCESS = ns('DELETE_PROPERTY_LIST_SUCCESS');
export const DELETE_PROPERTY_LIST_FAILED = ns('DELETE_PROPERTY_LIST_SUCCESS');

export const UPDATE_PROPERTY_LIST_REQUEST = ns('UPDATE_PROPERTY_LIST_REQUEST');
export const UPDATE_PROPERTY_LIST_SUCCESS = ns('UPDATE_PROPERTY_LIST_SUCCESS');
export const UPDATE_PROPERTY_LIST_FAILED = ns('UPDATE_PROPERTY_LIST_SUCCESS');

export const UNDO_DELETE_PROPERTY_LIST_REQUEST = ns(
  'UNDO_DELETE_PROPERTY_LIST_REQUEST'
);
export const UNDO_DELETE_PROPERTY_LIST_SUCCESS = ns(
  'UNDO_DELETE_PROPERTY_LIST_SUCCESS'
);
export const UNDO_DELETE_PROPERTY_LIST_FAILED = ns(
  'UNDO_DELETE_PROPERTY_LIST_SUCCESS'
);

export const GET_PROPERTY_LISTS_REQUEST = ns('GET_PROPERTY_LISTS_REQUEST');
export const GET_PROPERTY_LISTS_SUCCESS = ns('GET_PROPERTY_LISTS_SUCCESS');
export const GET_PROPERTY_LISTS_FAILED = ns('GET_PROPERTY_LISTS_FAILED');

export const GET_PROPERTY_LISTS_ENTRIES_REQUEST = ns(
  'GET_PROPERTY_LISTS_ENTRIES_REQUEST'
);
export const GET_PROPERTY_LISTS_ENTRIES_SUCCESS = ns(
  'GET_PROPERTY_LISTS_ENTRIES_SUCCESS'
);
export const GET_PROPERTY_LISTS_ENTRIES_FAILED = ns(
  'GET_PROPERTY_LISTS_ENTRIES_FAILED'
);

export const CREATE_PROPERTY_LIST_REQUEST = ns('CREATE_PROPERTY_LIST_REQUEST');
export const CREATE_PROPERTY_LIST_SUCCESS = ns('CREATE_PROPERTY_LIST_SUCCESS');
export const CREATE_PROPERTY_LIST_FAILED = ns('CREATE_PROPERTY_LIST_FAILED');

export const ADD_PROPERTY_LIST_ENTRY_REQUEST = ns(
  'ADD_PROPERTY_LIST_ENTRY_REQUEST'
);
export const ADD_PROPERTY_LIST_ENTRY_SUCCESS = ns(
  'ADD_PROPERTY_LIST_ENTRY_SUCCESS'
);
export const ADD_PROPERTY_LIST_ENTRY_FAILED = ns(
  'ADD_PROPERTY_LIST_ENTRY_FAILED'
);

export const REMOVE_PROPERTY_LIST_ENTRY_REQUEST = ns(
  'REMOVE_PROPERTY_LIST_ENTRY_REQUEST'
);
export const REMOVE_PROPERTY_LIST_ENTRY_SUCCESS = ns(
  'REMOVE_PROPERTY_LIST_ENTRY_SUCCESS'
);
export const REMOVE_PROPERTY_LIST_ENTRY_FAILED = ns(
  'REMOVE_PROPERTY_LIST_ENTRY_FAILE'
);

export const REMOVE_PROPERTY_LIST_ENTRIES_REQUEST = ns(
  'REMOVE_PROPERTY_LIST_ENTRIES_REQUEST'
);
export const REMOVE_PROPERTY_LIST_ENTRIES_SUCCESS = ns(
  'REMOVE_PROPERTY_LIST_ENTRIES_SUCCESS'
);
export const REMOVE_PROPERTY_LIST_ENTRIES_FAILED = ns(
  'REMOVE_PROPERTY_LIST_ENTRIES_FAILE'
);

export const GET_PROPERTY_LISTS_ENTRIES_FOR_TABLE_REQUEST = ns(
  'GET_PROPERTY_LISTS_ENTRIES_FOR_TABLE_REQUEST'
);
export const GET_PROPERTY_LISTS_ENTRIES_FOR_TABLE_SUCCESS = ns(
  'GET_PROPERTY_LISTS_ENTRIES_FOR_TABLE_SUCCESS'
);
export const GET_PROPERTY_LISTS_ENTRIES_FOR_TABLE_FAILED = ns(
  'GET_PROPERTY_LISTS_ENTRIES_FOR_TABLE_FAILED'
);
