import * as T from './__generated__/query';

import { AllAssigned, GQL, gql } from 'src/utils/recoil/gql';

export const DealPortfolioAssestsQuery = gql`
  query DealPortfolioAssests($tax_assessor_id: [numeric!]) {
    tax_assessor_v2(where: { tax_assessor_id: { _in: $tax_assessor_id } }) {
      cherre_parcel_id
      tax_assessor_id
      latitude
      longitude
      address
      zone_code
      units_count
      year_built
      building_sq_ft
      lot_size_acre
      property_use_code_mapped
      recorder_v2_summary_v2__tax_assessor_id {
        cherre_latest_deed_date
        cherre_latest_deed_amount
      }
      cherre_address__property_address {
        reis_property_attributes__address {
          sector
          propertytype
          sizesqft
          sizeunit
          yearbuilt
        }
      }
    }
  }
` as GQL<
  AllAssigned<T.DealPortfolioAssestsQuery>,
  T.DealPortfolioAssestsQueryVariables
>;
