import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  container: {
    breakInside: 'avoid',
    display: 'grid',
    gridTemplateColumns: '1fr',
    padding: '24px 0px',
    borderTop: `3px solid ${theme.palette.grey[200]}`,
    alignContent: 'flex-start',
  },
  separator: {
    width: '100%',
    height: '3px',
    backgroundColor: theme.palette.grey[200],
    margin: '24px 0px',
  },
}));
