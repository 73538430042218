import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core';

type Props = {
  height: number | string;
  padding: number | string;
  hoverEffect: boolean;
};

export const useStyles = makeStyles(() => ({
  icon: {
    display: 'none',
    position: 'absolute',
    right: 10,
    top: 10,
  },
  actions: {
    position: 'absolute',
    top: '8.3px',
    right: '8.3px',
    display: 'flex',
  },
  container: ({ hoverEffect }: Props) => ({
    position: 'relative',
    display: 'flex',
    '&:first-child': {
      borderTop: 'none',
    },
    '&:hover': hoverEffect
      ? {
          backgroundColor: grey[200],
        }
      : {
          backgroundColor: 'white',
        },
    '& .pin': {
      visibility: 'hidden',
    },
    '&:hover .pin': {
      visibility: 'visible',
    },
  }),
  checkbox: ({ height }: Props) => ({
    display: 'flex',
    alignItems: 'baseline',
    height,
    '&.selected': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    '& > span': {
      padding: '15px 10px 10px',
    },
  }),
  listItem: ({ height, padding, hoverEffect }: Props) => ({
    flexDirection: 'column',
    alignItems: 'flex-start',
    fontSize: 18,
    fontWeight: 500,
    '&:hover': hoverEffect
      ? {
          backgroundColor: grey[200],
        }
      : {
          backgroundColor: 'white',
        },
    padding: padding,
    height,
    width: '100%',
    minWidth: 0,
  }),
  listItemContainer: {
    display: 'block',
    width: '100%',
    height: '100%',
  },
  smallText: {
    fontSize: 12,
    color: grey[600],
  },
  address: {
    '&:hover': {
      textDecoration: 'underline',
    },
    display: 'flex',
    maxWidth: 230,
  },
  portfolioChip: {
    height: '17px',
    fontSize: '11px',
    backgroundColor: '#FCEDF6',
    color: '#9F4182',
  },
}));
