import { GQL, gql } from 'src/utils/recoil/gql';
import * as T from './__generated__/query';

export const taxAssessorByLocationQuery = gql`
  query taxAssessorByLocation($lat: float8!, $lon: float8!) {
    tax_assessor_point_v2(args: { latitude: $lat, longitude: $lon }) {
      cherre_parcel_id
      tax_assessor_id
    }
  }
` as GQL<T.TaxAssessorByLocationQuery, T.TaxAssessorByLocationQueryVariables>;
