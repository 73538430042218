import { selectorFamily } from 'recoil';
import { gql, GQL } from 'src/utils/recoil/gql';
import * as T from './__generated__/aggregates';

const _rentAggregates = gql`
  query rentAggregates(
    $cherre_parcel_id: String!
    $start_date: date!
    $end_date: date!
    $space_type: String!
  ) {
    tax_assessor_v2(
      where: {
        cherre_parcel_id: { _eq: $cherre_parcel_id }
        tax_assessor_v2_compstak_boundary__tax_assessor_id: {}
      }
      distinct_on: cherre_parcel_id
    ) {
      tax_assessor_v2_compstak_boundary__tax_assessor_id {
        compstak_market_boundary__id {
          compstak_leases__cherre_compstak_leases_pk(
            where: {
              commencement_date: { _gt: $start_date, _lt: $end_date }
              space_type: { _eq: $space_type }
            }
          ) {
            lease_term
            starting_rent_per_sq_ft
            net_effective_rent
            commencement_date
            free_rent_month_count
            work_value
            space_type
          }
        }
      }
    }
  }
` as GQL<T.RentAggregatesQuery, T.RentAggregatesQueryVariables>;

export const rentAggregates = selectorFamily<
  T.RentAggregatesQuery,
  T.RentAggregatesQueryVariables
>({
  key: 'LOT-PAGE/LEASES/RENT-AGGREGATES',
  get:
    (params) =>
    ({ get }) => {
      try {
        return get(_rentAggregates(params));
      } catch (e) {
        if (e instanceof Promise) {
          throw e;
        }
        return {
          tax_assessor_v2: [
            { tax_assessor_v2_compstak_boundary__tax_assessor_id: [{}] },
          ],
        };
      }
    },
});
