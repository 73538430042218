import { Mapper } from 'src/utils/recoil/utils';
import { selectorFamilyWithDefaultValue } from 'src/utils/recoil/withDefaultValue';
import { taxAssessorQuery } from './taxAssessor';
import {
  Tax_AssessorQuery,
  Tax_AssessorQueryVariables,
} from './__generated__/taxAssessor';

interface RequiredLotAddress {
  address: string;
  city: string;
  house_number: string;
  mailing_address: string;
  mailing_city: string;
  mailing_state: string;
  mailing_zip: string;
  display_address: string;
  state: string;
  street_direction: string;
  street_name: string;
  street_post_direction: string;
  street_suffix: string;
  zip: string;
}

export type LotAddress = Partial<RequiredLotAddress>;

type TaxAssessorToLotAddressFormatter = Mapper<Tax_AssessorQuery, LotAddress>;

const taxAssessorToLotAddress: TaxAssessorToLotAddressFormatter = (data) => {
  const taxAssessor = data.tax_assessor_v2[0] || {};
  const address = taxAssessor.address || undefined;
  const city = taxAssessor.city || undefined;
  const house_number = taxAssessor.house_number || undefined;
  const mailing_address = taxAssessor.mailing_address || undefined;
  const mailing_city = taxAssessor.mailing_city || undefined;
  const mailing_state = taxAssessor.mailing_state || undefined;
  const mailing_zip = taxAssessor.mailing_zip || undefined;
  const display_address =
    taxAssessor.cherre_address__property_address?.[0]?.display_address ||
    undefined;
  const state = taxAssessor.state || undefined;
  const street_direction = taxAssessor.street_direction || undefined;
  const street_name = taxAssessor.street_name || undefined;
  const street_post_direction = taxAssessor.street_post_direction || undefined;
  const street_suffix = taxAssessor.street_suffix || undefined;
  const zip = taxAssessor.zip || undefined;
  return {
    address,
    city,
    house_number,
    mailing_address,
    mailing_city,
    mailing_state,
    mailing_zip,
    display_address,
    state,
    street_direction,
    street_name,
    street_post_direction,
    street_suffix,
    zip,
  };
};

export const lotAddress = selectorFamilyWithDefaultValue<
  LotAddress,
  Tax_AssessorQueryVariables
>({
  defaultValue: {},
  key: 'LOT-PAGE/LOT-ADDRESS',
  get:
    (params) =>
    ({ get }) => {
      const taxAssessorResult = get(taxAssessorQuery(params));
      const taxAssessorFormatted = taxAssessorToLotAddress(taxAssessorResult);
      return taxAssessorFormatted;
    },
});
