import { SourceProps } from 'react-map-gl';
import { SOURCES } from '../const';

export default function getEQTLeaseCompsSource(): SourceProps {
  return {
    id: SOURCES.EQT_LEASE_COMPS_POINTS.name,
    type: SOURCES.EQT_LEASE_COMPS_POINTS.type,
    url: SOURCES.EQT_LEASE_COMPS_POINTS.url,
  };
}
