import { makeStyles, Theme } from '@material-ui/core';

type StylesProps = {
  isPortfolio: boolean;
};

export default makeStyles<Theme, StylesProps>((theme) => ({
  input: {
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingBottom: '8.5px',
    paddingTop: '7.5px',
    height: '17px',
  },
  button: {
    fontWeight: 500,
  },
  title: {
    marginBottom: '3px',
  },
  container: {
    padding: '20px',
    backgroundColor: '#F5F5F5',
    display: 'grid',
    gridAutoFlow: 'column',
    gridGap: '13.5px 80px',
    gridTemplateColumns: '1fr 1fr 1fr',

    [theme.breakpoints.down('md')]: {
      gridTemplateAreas: ({ isPortfolio }) =>
        isPortfolio
          ? "'header' 'buttons' 'name' 'description' 'stage' 'lead' 'broker' 'seller' 'ilo' 'notes'"
          : "'header' 'buttons' 'stage' 'lead' 'broker' 'seller' 'ilo' 'notes'",
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateAreas: ({ isPortfolio }) =>
        isPortfolio
          ? "'header buttons buttons' 'stage lead name' 'ilo broker description' 'seller . notes'"
          : "'header buttons buttons' 'stage lead description' 'ilo broker notes' 'seller . .'",
    },
  },
}));
