import React from 'react';
import { useDispatch } from 'react-redux';

import {
  deleteList,
  undoDeleteList,
} from 'src/products/core-prospect/redux/propertyLists/actions';

import DeleteSnackbar from '../../../components/DeleteSnackbar';
import PropertyLists from './Lists';
import SearchInput from '../../../components/SearchInput';

const PropertyListsView = ({ propertyLists, filterable = true }) => {
  const [isDeleteSnackbarOpen, toggleDeleteSnackbar] = React.useState(false);
  const [selectedList, selectList] = React.useState(null);
  const [filterText, setFilterText] = React.useState('');
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      {filterable && (
        <SearchInput
          id='js-core-prospect-search-page-property-lists-search-input'
          placeholder='Filter Property Lists'
          filterText={filterText}
          setFilterText={setFilterText}
        />
      )}
      <PropertyLists
        id='js-core-prospect-property-lists'
        filterText={filterText}
        propertyLists={propertyLists}
        deleteList={async (list) => {
          selectList(list);
          dispatch(deleteList(list.id));
          toggleDeleteSnackbar(true);
        }}
      />
      <DeleteSnackbar
        isOpen={isDeleteSnackbarOpen}
        onClose={() => toggleDeleteSnackbar(false)}
        text={`${selectedList && selectedList.name} deleted`}
        onActionClick={() => {
          dispatch(undoDeleteList(selectedList.id));
          toggleDeleteSnackbar(false);
        }}
      />
    </React.Fragment>
  );
};

export default PropertyListsView;
