import { makeStyles } from '@material-ui/core';
import AddCircle from '@material-ui/icons/AddCircle';
import React from 'react';
import { useCreateDeal } from 'src/services/Dealcloud/createDeal';
import ButtonProgress from 'src/components/ButtonProgress';
import { useOrganizationPropsValue } from 'src/hooks';

const useStyles = makeStyles(() => ({
  btn: {
    borderRadius: 6,
    fontSize: '14px',
    fontWeight: 600,
  },
}));

const AddDealButton: React.VFC<{
  taxAssessorId: number;
}> = ({ taxAssessorId }) => {
  const classes = useStyles();

  const isDealsIntegrationAvailable =
    useOrganizationPropsValue('deals_enabled');

  const [createDeal, loading] = useCreateDeal();

  if (!isDealsIntegrationAvailable) {
    return null;
  }

  return (
    <ButtonProgress
      startIcon={loading ? undefined : <AddCircle />}
      size='small'
      variant='contained'
      color='primary'
      className={classes.btn}
      onClick={() => createDeal(taxAssessorId)}
      loading={loading}
    >
      Add Deal
    </ButtonProgress>
  );
};

AddDealButton.displayName = 'AddDealButton';

export default AddDealButton;
