import * as T from './types';

export const getPerson = (piplResponse: T.Response) => {
  return piplResponse?.pipl_search?.people?.[0];
};

export const getPhones = (phones: T.Phone[] = []) => {
  return phones.filter(
    (phone) => !['home_fax', 'work_fax', 'pager'].includes(phone.type || '')
  );
};

export const formatPhoneType = (phone: string) => phone.replace('_', ' ');
