import { useParams } from 'react-router';
import { getPortfolioSummary } from 'src/products/core-prospect/search-pages/components/Sidebar/components/TaxRecordPreview/DealInfoCard';
import { useDealPortfolioByTaxId } from 'src/services/Dealcloud/hooks';
import { LotPageParams } from '../../types';
import { DealCloudViewAsyncProps } from './types';

export const useDealCloudInfo = (): DealCloudViewAsyncProps => {
  const { id } = useParams<LotPageParams>();
  const dealInfo = useDealPortfolioByTaxId(parseInt(id));

  return {
    deal: dealInfo
      ? {
          isPortfolio: dealInfo.assets.length > 1,
          name: dealInfo.dealName,
          description: dealInfo.description,
          acquisitionAssociate: dealInfo.lead,
          seller: dealInfo.seller,
          broker: dealInfo.broker,
          ilo: dealInfo.ilo,
          stage: dealInfo.stage,
          lastNote: dealInfo.lastActivityNote,
          linkTo: `https://eqtexeter.dealcloud.com/portal/pages/871/reports/888/entries/${dealInfo.dealId}`,
          portfolioSummary: getPortfolioSummary(dealInfo),
          dealCloudId: dealInfo.dealId,
          taxAssessorId: Number(id),
        }
      : null,
  };
};
