import React from 'react';
import Truncate from 'src/components/Truncate';

import { Typography, Tooltip } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const LitsItemParcel = ({ tax_assessor, onClick = () => {}, style }) => (
  <div
    style={{
      ...style,
      padding: '10px 0',
      borderTop: `1px solid ${grey[400]}`,
      cursor: 'pointer',
    }}
    onClick={() => onClick && onClick(tax_assessor)}
  >
    <Typography display='block' component='span' variant='h6'>
      {tax_assessor.address || 'unknown'}
    </Typography>
    <Typography display='block' component='span' variant='body1'>
      {[tax_assessor.city, tax_assessor.state, tax_assessor.zip]
        .filter(Boolean)
        .join(', ')}
    </Typography>
    <Typography display='block' component='span'>
      <span style={{ display: 'flex' }}>
        {tax_assessor.size && (
          <Typography style={{ marginRight: 0 }}>
            {tax_assessor.size}
          </Typography>
        )}
        {tax_assessor.size && tax_assessor.property_use_code_mapped && (
          <span style={{ margin: '0 3px' }}> . </span>
        )}
        {tax_assessor.property_use_code_mapped && (
          <Tooltip title={tax_assessor.property_use_code_mapped}>
            <span style={{ width: 180, marginLeft: 3 }}>
              <Truncate>{tax_assessor.property_use_code_mapped}</Truncate>
            </span>
          </Tooltip>
        )}
      </span>
    </Typography>
  </div>
);

export default LitsItemParcel;
