import React from 'react';

import { makeStyles, Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import useLocation from 'src/hooks/useLocation';
import { AutocompleteSuggestion } from './recoil/selectors';

const icons = {
  Country: '/assets/autocomplete-icons/country.svg',
  Street: '/assets/autocomplete-icons/street.svg',
  Intersection: '/assets/autocomplete-icons/intersection.svg',
  Address: '/assets/autocomplete-icons/address.svg',
  Neighborhood: '/assets/autocomplete-icons/neighborhood.svg',
  City: '/assets/autocomplete-icons/city.svg',
  State: '/assets/autocomplete-icons/state.svg',
  County: '/assets/autocomplete-icons/county.svg',
  ZIP: '/assets/autocomplete-icons/zip-code.svg',
  MSA: '/assets/autocomplete-icons/msa.svg',
  REISMarket: '/assets/autocomplete-icons/reis-market.svg',
  REISSubMarket: '/assets/autocomplete-icons/reis-market.svg',
};

export const subtitles = {
  Country: 'Country',
  Street: 'Street',
  Intersection: 'Intersection',
  Address: 'Address',
  Neighborhood: 'Neighborhood',
  City: 'City',
  State: 'State',
  County: 'County',
  ZIP: 'Zip Code',
  MSA: 'MSA',
  REISMarket: 'REIS Market',
  REISSubMarket: 'REIS Submarket',
};

const useStyles = makeStyles<Theme, { onAppsPage: boolean }>(() => ({
  container: {
    display: 'grid',
    gridTemplateAreas: '"img . title""img . subtitle"',
    gridTemplateColumns: '24px 13px auto',
    padding: '10px 16px',
  },
  img: {
    gridArea: 'img',
    width: '24px',
    height: '24px',
  },
  title: {
    gridArea: 'title',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textTransform: 'uppercase',
    fontFamily: 'Montserrat',
    color: grey[900],
    fontSize: (props) => (props.onAppsPage ? '16px' : '14px'),
  },
  subtitle: {
    gridArea: 'subtitle',
    fontFamily: 'Montserrat',
    fontSize: (props) => (props.onAppsPage ? '14px' : '12px'),
    color: grey[700],
  },
}));

interface RenderOptionProps {
  option: AutocompleteSuggestion;
  inputValue: string;
}

const RenderOption: React.VFC<RenderOptionProps> = ({ option, inputValue }) => {
  const matches = match(option.text, inputValue, {
    insideWords: true,
    findAllOccurrences: true,
  });
  const parts = parse(option.text, matches);

  const location = useLocation();

  const onAppsPage = location?.pathname === '/apps';

  const classes = useStyles({ onAppsPage });

  const icon = icons[option.group_name];

  const subtitle = subtitles[option.group_name];

  return (
    <div className={classes.container}>
      <img className={classes.img} src={icon} />
      <label className={classes.title}>
        {parts.map((part, index) => {
          return part.highlight ? (
            <span key={index} style={{ whiteSpace: 'pre', fontWeight: 900 }}>
              {part.text}
            </span>
          ) : (
            <span key={index} style={{ whiteSpace: 'pre', fontWeight: 500 }}>
              {part.text}
            </span>
          );
        })}
      </label>
      <label className={classes.subtitle}>{subtitle}</label>
    </div>
  );
};

export default React.memo(RenderOption);
