import { withStyles } from '@material-ui/core/styles';
import debounce from 'lodash/debounce';
import React from 'react';
import { connect, useSelector } from 'react-redux';

import Sidebar from '../Sidebar';

import CoreProspectView from 'src/products/core-prospect/search-pages/components/Page/CoreProspectView';
import { Context } from 'src/products/core-prospect/search-pages/USALot';

const Page = ({ classes, isSidebarCollapsed }) => {
  const { isCompact } = React.useContext(Context);

  const [pageHeight, setPageHeight] = React.useState(window.innerHeight - 60);

  const sidebarWidth = isCompact ? '100%' : 350;
  const pageClassName = isCompact ? classes.rootCompact : classes.root;

  const isInFullMapMode = useSelector((state) => {
    return state.coreProspect.map.isInFullMode;
  });

  React.useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setPageHeight(window.innerHeight - 60);
    }, 1000);

    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, []);

  return (
    <div
      id='core-prospect'
      style={{ height: pageHeight }}
      className={pageClassName}
    >
      {!isInFullMapMode && (
        <Sidebar
          style={{
            width: sidebarWidth,
            minWidth: sidebarWidth,
            height: !isSidebarCollapsed && '100%',
          }}
        />
      )}
      {(isSidebarCollapsed || !isCompact) && (
        <CoreProspectView isCompact={isCompact} pageHeight={pageHeight} />
      )}
    </div>
  );
};

const styles = {
  root: {
    display: 'flex',
  },
  rootCompact: {
    display: 'flex',
    flexDirection: 'column',
  },
};

function mapStateToProps(state) {
  return {
    isSidebarCollapsed: state.coreProspect.view.isSidebarCollapsed,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(Page));
