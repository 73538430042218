import React from 'react';
import GoogleStreetView from 'src/components/GoogleStreetView';
import { useIsSuspended } from 'src/utils/suspense-companion/createSuspenseComponent';
import { StreetViewProps } from './types';

const StreetView: React.VFC<StreetViewProps> = ({ latitude, longitude }) => {
  const isSuspended = useIsSuspended();
  return (
    <div className='suspend' style={{ minHeight: 300, width: '100%' }}>
      {!isSuspended && (
        <GoogleStreetView
          style={{ border: 0, minHeight: 300, height: '100%', width: '100%' }}
          point={{ latitude, longitude }}
        />
      )}
    </div>
  );
};

export default StreetView;
