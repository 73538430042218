import React from 'react';

import Chip from '@material-ui/core/Chip';
import grey from '@material-ui/core/colors/grey';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  chipRoot: {
    marginRight: 10,
    '&:last-child': {
      marginRight: 0,
    },
  },
  chipDot: {
    height: 12,
    width: 12,
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: 5,
  },
});

const TimelineChip = ({ label, onClick, selected, color }) => {
  const classes = useStyles();

  return (
    <span className={classes.chipRoot}>
      <Chip
        clickable
        disabled={selected}
        label={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {color && (
              <span
                className={classes.chipDot}
                style={{ backgroundColor: color }}
              />
            )}
            <span>{label}</span>
          </div>
        }
        onClick={onClick}
        style={{ backgroundColor: grey[200] }}
      />
    </span>
  );
};

export default TimelineChip;
