import * as TYPES from './types';

const initialState = {
  queries: [],
};

export const graphqlRequests = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.QUERY_START: {
      const { name, id } = action;

      return {
        ...state,
        queries: [...state.queries, { name, id }],
      };
    }

    case TYPES.QUERY_END: {
      const { id } = action;

      return {
        ...state,
        queries: state.queries.filter(({ id: queryId }) => queryId !== id),
      };
    }

    default:
      return state;
  }
};
