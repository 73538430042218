import React, { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { styled } from '@material-ui/core/styles';
import {
  CircularProgress,
  Container,
  Hidden,
  makeStyles,
  Typography,
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import constants from 'src/const';
import Header from './Header';
import SimpleTabs from './Tabs';
import { getMaskedValue } from 'src/utils/mask';
import useIsCompact from 'src/hooks/useIsCompact';
import PieChart from 'src/products/core-prospect/components/Panel/Regular/Element/CherrePlotlyGraphWrapper/PieChart.js';

import { getPieChartData } from 'src/products/core-prospect/CompanyDetails/compayDetailsHelpers';

import CompanyMarketsCard from './CompanyMarketsCard';
import { useOwnerDetails } from './hooks/useOwnerDetails';
import { cherrePropertyUseHierarchicalMap } from 'src/const/lookups';
import OwnerCard from '../pages/lot-page/panels/PropertyOwners/OwnerCard';
import { OwnerTypes } from '../pages/lot-page/panels/PropertyOwners/common';
import { useHistory, useRouteMatch } from 'react-router';
import { useSearchParam } from 'react-use';

const { NAV_BAR_HEIGHT } = constants;

const Root = styled('div')(() => ({
  minHeight: `calc(100vh - ${NAV_BAR_HEIGHT}px)`,
  background: '#fff',
  display: 'flex',
  flexDirection: 'column',
}));

const PROPERTIES = 'Portfolio';
const RELATED_OWNERS = 'Related Owners';
// const MORTGAGES = 'Mortgages';
// const TRANSACTIONS = 'Transactions';

const useStyles = makeStyles({
  sticky: {
    position: 'sticky',
    background: '#fff',
    zIndex: 101,
    width: '100%',
  },
});

const CompanyDetails: React.FC = () => {
  const { params } = useRouteMatch<{ ownerId: string; ownerName: string }>();
  const { replace, location } = useHistory();

  const classes = useStyles();
  const tabs: string[] = [];

  const user = useSelector((state) => state.user.profile.value);

  if (user?.featureFlags?.cp_OwnerProperties) {
    tabs.unshift(PROPERTIES);
  }

  if (user?.featureFlags?.cp_OwnerRelatedOwners) {
    tabs.push(RELATED_OWNERS);
  }

  const tab = useSearchParam('tab') || tabs[0];
  const isCompact = useIsCompact();

  const changeTab = (tab) => {
    replace({
      ...location,
      search: 'tab=' + tab,
    });
  };

  const { loading: loadingOwnerDetails, data: ownerDetails } = useOwnerDetails(
    params.ownerId
  );

  const formattedCityArray = useMemo(
    () =>
      ownerDetails?.markets.buckets.map((m) => ({
        city: m.key,
        propertyCount: m.doc_count,
      })),
    [ownerDetails]
  );

  const pieChartData = useMemo(() => {
    if (!ownerDetails) {
      return [];
    }

    const countByPropertyType = Object.fromEntries(
      ownerDetails?.property_types.buckets.map((p) => [
        cherrePropertyUseHierarchicalMap[p.key],
        p.doc_count,
      ])
    );

    return getPieChartData(countByPropertyType);
  }, [ownerDetails]);

  const chartWrapRef = useRef<HTMLDivElement>(null);
  const [chartWidth, setChartWidth] = React.useState(100);

  useEffect(() => {
    chartWrapRef.current?.offsetWidth &&
      setChartWidth(chartWrapRef.current?.offsetWidth);
  }, [chartWrapRef.current?.offsetWidth]);

  const type = params.ownerId?.split('::')[1];

  const ownerType =
    {
      I: 'Company',
      P: 'Individual',
      G: 'Government',
      E: 'Education',
    }[type] || 'Unknown';

  return (
    <Root>
      <div className={classes.sticky}>
        <Container disableGutters maxWidth='lg'>
          <Grid container>
            <Grid item xs={12}>
              <Header title={params.ownerName} ownerType={ownerType} />
            </Grid>
          </Grid>
        </Container>

        <Container disableGutters maxWidth='lg'>
          <Grid container>
            <Grid item xs={12}>
              <SimpleTabs tab={tab} changeTab={changeTab} tabs={tabs} />
            </Grid>
          </Grid>
        </Container>
      </div>
      <br />
      <br />
      {tab === RELATED_OWNERS && (
        <Container maxWidth='lg' disableGutters={!isCompact}>
          <Grid spacing={2} container>
            {!loadingOwnerDetails ? (
              ownerDetails?.related_owners.buckets.map((b) => {
                if (b.key === params.ownerId) {
                  return null;
                }

                const [relatedName, relatedType] = b.key.split('::');
                return (
                  <Grid key={b.key} item xs={3}>
                    <OwnerCard
                      key={b.key}
                      showEmployees={false}
                      commonPropertyCount={b.doc_count}
                      type={relatedType as OwnerTypes}
                      name={relatedName}
                    />
                  </Grid>
                );
              })
            ) : (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress />
              </div>
            )}
          </Grid>
        </Container>
      )}
      {tab === PROPERTIES &&
        (loadingOwnerDetails || !ownerDetails ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: 300,
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <Container disableGutters={!isCompact} maxWidth='lg'>
              <Grid container style={{ marginBottom: 20 }}>
                <Grid item xs={3}>
                  <Card elevation={2} style={{ width: '90%' }}>
                    <CardHeader title='Total Properties' />
                    <CardContent style={{ paddingTop: 0, paddingBottom: 16 }}>
                      <Typography style={{ fontSize: 24 }}>
                        {getMaskedValue(
                          ownerDetails.total_properties.value,
                          'number'
                        )}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={3}>
                  <Card elevation={2} style={{ width: '90%' }}>
                    <CardHeader title='Total Area' />
                    <CardContent style={{ paddingTop: 0, paddingBottom: 16 }}>
                      <Typography style={{ fontSize: 24 }}>
                        {getMaskedValue(ownerDetails.total_area.value, 'sqft')}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              {user?.featureFlags?.cp_OwnerRelatedOwners ? (
                <Grid container style={{ marginBottom: 20 }} spacing={3}>
                  <Grid item xs={6}>
                    <CompanyMarketsCard cities={formattedCityArray} />
                  </Grid>
                  <Grid item xs={6}>
                    <Card
                      style={{
                        height: 500,
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <CardHeader
                        title='Property Types'
                        subheader='Portfolio distribution across property types'
                      />
                      <CardContent
                        ref={chartWrapRef}
                        style={{
                          padding: 0,
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <PieChart
                          width={`${chartWidth}px`}
                          data={pieChartData}
                          hovermode
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              ) : undefined}
            </Container>
          </>
        ))}
      <Hidden smDown>
        <br />
        <br />
      </Hidden>
    </Root>
  );
};

export default CompanyDetails;
