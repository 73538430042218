import React from 'react';
import {
  Divider,
  LinearProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { EmptryList } from './EmptyList';
import { useTaxAssessors } from '../../hooks/useTaxAssessors';
import { Assets } from './Assets';
import { Autocomplete } from '../Autocomplete';
import { DealStageType } from 'src/services/Dealcloud/types';

type Props = {
  title: string;
  name: string;
  stage: DealStageType;
  assets: Array<{
    taxAssessorId: number;
  }>;
  onRemove: (taxAssessorId: number) => void;
};

const useStyles = makeStyles({
  root: {
    width: 360,
    minWidth: 360,
    height: '100%',
    overflow: 'auto',
    borderRight: `solid 1px ${grey[400]}`,
  },
  titleWrap: {
    padding: 10,
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
  },
  addressWrap: {
    position: 'relative',
  },
  noRecords: { padding: 15 },
});

export const Sidebar: React.VFC<Props> = ({
  name,
  stage,
  assets,
  title,
  onRemove,
}) => {
  const classes = useStyles();

  const [taxAssessorsData, state] = useTaxAssessors(assets);
  const taxAssessors = taxAssessorsData?.tax_assessor_v2;

  const isLoading = state === 'loading';
  const isFailedToLoad = state === 'hasError';
  const noRecordsFound = assets.length === 0 && state === 'hasValue';

  return (
    <div className={classes.root}>
      <LinearProgress
        style={{ visibility: isLoading ? 'visible' : 'hidden' }}
      />
      <div className={classes.titleWrap}>
        <Typography className={classes.title}>{title}</Typography>
        <Typography>{name}</Typography>
      </div>
      <Divider light />
      <Autocomplete customStyle={{ padding: 10 }} />
      {isFailedToLoad ? (
        <div className={classes.noRecords}>Failed to load</div>
      ) : null}
      {assets ? (
        noRecordsFound ? (
          <EmptryList />
        ) : (
          <Assets
            assets={assets}
            onRemove={onRemove}
            stage={stage}
            taxAssessors={taxAssessors || []}
          />
        )
      ) : null}
    </div>
  );
};
