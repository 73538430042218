import { QUERY_END, QUERY_START } from './types';

export const queryStart = (name, id) => {
  return {
    type: QUERY_START,
    name,
    id,
  };
};

export const queryEnd = (id) => {
  return {
    type: QUERY_END,
    name,
    id,
  };
};
