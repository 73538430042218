import {
  Checkbox,
  Chip,
  CircularProgress,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { paginatedMSA } from './recoil';
import { getSearchParameters } from 'src/products/core-prospect/search-pages/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { resetSearchParameters } from 'src/products/core-prospect/redux/query/actions';
import Search from '@material-ui/icons/Search';
import Clear from '@material-ui/icons/Clear';

// STYLE

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '10px 26px',
    height: '100%',
    width: '100%',
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    overflow: 'auto',
    gap: 10,
  },
  MSAContainer: {
    flex: 1,
    overflow: 'auto',
    borderRadius: 5,
    border: `solid 1px ${theme.palette.grey[500]}`,
  },
  MSACell: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    padding: '0px 5px',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.grey[100],
    },
    textTransform: 'capitalize',
  },
  searchCell: {
    padding: '5px 10px',
    width: '100%',
  },
  checkboxRoot: {
    padding: 5,
    color: theme.palette.primary.main,
  },
  progressContainer: {
    display: 'grid',
    placeItems: 'center',
    padding: 10,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.grey[900],
  },
  chipsContainer: {
    display: 'flex',
    gap: 5,
    maxHeight: 63,
    flexWrap: 'wrap',
    textTransform: 'capitalize',
    width: '280px',
  },
}));

const MSAComponent: React.VFC = () => {
  const classes = useStyles();

  const [MSASearch, setMSASearch] = React.useState('');

  const MSAs = useRecoilValue(
    paginatedMSA({ msa_name: MSASearch ? `%${MSASearch}%` : '%' })
  );

  const searchParameters = useSelector(getSearchParameters);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectedMSAs: any[] = searchParameters.filter(
    (param) =>
      'msa_name' in param.attributes &&
      'cbsa_code' in param.attributes &&
      !param.attributes.isAutocomplete
  );

  const isMSASelected = (cbsa_code: string) =>
    selectedMSAs.some(
      (param) =>
        param.attributes.cbsa_code === cbsa_code && !param.isAutocomplete
    );

  const dispatch = useDispatch();

  const toggleMSA = async (MSAData: (typeof MSAs)['data'][0]) => {
    let updatedSearchParameters;

    if (isMSASelected(MSAData.cbsa_code)) {
      updatedSearchParameters = [...searchParameters].filter(
        (param) => param.attributes.cbsa_code !== MSAData.cbsa_code
      );

      dispatch(resetSearchParameters(updatedSearchParameters));
    } else {
      updatedSearchParameters = [
        ...searchParameters,
        {
          field_name: 'custom_search_area',
          entity_name: 'usa_map_parcel',
          attributes: {
            value: {
              geometry: MSAData.geometry,
              properties: {},
              type: 'Feature',
            },
            isAutocomplete: false,
            ...MSAData,
            isSubmarket: false,
          },
        },
      ];
      dispatch(resetSearchParameters(updatedSearchParameters));
    }
  };

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>MSAs</Typography>
      <div className={classes.MSAContainer} onScroll={MSAs.onScrollFetchMore}>
        <div className={classes.searchCell}>
          <TextField
            style={{ width: '100%' }}
            value={MSASearch}
            onChange={(e) => setMSASearch(e.target.value)}
            placeholder='Search'
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position='start'>
                  <Search fontSize='small' />
                </InputAdornment>
              ),
              endAdornment: MSASearch ? (
                <InputAdornment position='end'>
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => setMSASearch('')}
                  >
                    <Clear fontSize='small' />
                  </div>
                </InputAdornment>
              ) : undefined,
            }}
          />
        </div>
        {MSAs.data.map((MSAData) => (
          <div
            key={MSAData.cbsa_code}
            className={classes.MSACell}
            onClick={() => toggleMSA(MSAData)}
          >
            <Checkbox
              color='primary'
              classes={{ root: classes.checkboxRoot }}
              checked={isMSASelected(MSAData.cbsa_code)}
            />
            <Typography>{MSAData.msa_name}</Typography>
          </div>
        ))}
        {MSAs.loading ? (
          <div className={classes.progressContainer}>
            <CircularProgress size={20} />
          </div>
        ) : null}
      </div>
      <div className={classes.chipsContainer}>
        {selectedMSAs.map((selectedMSA) => (
          <Chip
            style={{ maxWidth: '100%' }}
            key={selectedMSA.attributes.cbsa_code}
            onDelete={() => toggleMSA(selectedMSA.attributes)}
            size='small'
            label={selectedMSA.attributes.msa_name.toLowerCase()}
          />
        ))}
        <span style={{ height: '10px', width: '100%' }} />
      </div>
    </div>
  );
};

export default MSAComponent;
