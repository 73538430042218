import { gql, GQL } from 'src/utils/recoil/gql';
import {
  OtherParcelTaxAssessorsQuery,
  OtherParcelTaxAssessorsQueryVariables,
  ParcelTaxAssessorsCountQuery,
  ParcelTaxAssessorsCountQueryVariables,
  TaxRecordPreviewQuery,
  TaxRecordPreviewQueryVariables,
} from './__generated__';

export const taxRecordPreviewQuery = gql`
  query TaxRecordPreview($tax_assessor_id: numeric!) {
    tax_assessor_v2(where: { tax_assessor_id: { _eq: $tax_assessor_id } }) {
      cherre_address__property_address {
        reis_property_attributes__address {
          sector
          propertytype
          sizesqft
          sizeunit
          yearbuilt
        }
      }
      recorder_v2_summary_v2__tax_assessor_id {
        cherre_latest_deed_amount
        cherre_latest_deed_date
        cherre_latest_deed_grantee
        cherre_latest_deed_grantor
        cherre_latest_mortgage_amount
        cherre_latest_mortgage_borrower
        cherre_latest_mortgage_date
        cherre_latest_mortgage_lender
      }
      tax_assessor_owner_v2__tax_assessor_id(
        where: {
          owner_name: { _is_null: false }
          owner_type: { _is_null: false }
        }
      ) {
        owner_name
        owner_type
      }
      usa_owner_unmask_v2__tax_assessor_id_aggregate {
        aggregate {
          count
        }
      }
      tax_assessor_id
      property_use_code_mapped
      lot_size_sq_ft
      cherre_parcel_id
      tax_assessor_id
      address
      latitude
      longitude
      city
      state
      zip
      zone_code
      units_count
      year_built
      building_sq_ft
      lot_size_acre
    }
  }
` as GQL<TaxRecordPreviewQuery, TaxRecordPreviewQueryVariables>;

export const parcelTaxAssessorsCountQuery = gql`
  query ParcelTaxAssessorsCount($cherre_parcel_id: String!) {
    tax_assessor_v2_aggregate(
      where: { cherre_parcel_id: { _eq: $cherre_parcel_id } }
    ) {
      aggregate {
        count
      }
    }
  }
` as GQL<ParcelTaxAssessorsCountQuery, ParcelTaxAssessorsCountQueryVariables>;

export const otherParcelTaxAssessorsQuery = gql`
  query OtherParcelTaxAssessors(
    $cherre_parcel_id: String!
    $tax_assessor_id: numeric!
  ) {
    tax_assessor_v2(
      limit: 5
      where: {
        cherre_parcel_id: { _eq: $cherre_parcel_id }
        tax_assessor_id: { _neq: $tax_assessor_id }
      }
    ) {
      recorder_v2_summary_v2__tax_assessor_id {
        cherre_latest_deed_date
        cherre_latest_deed_amount
      }
      cherre_address__property_address {
        reis_property_attributes__address {
          sector
          propertytype
        }
      }
      tax_assessor_id
      property_use_code_mapped
      lot_size_sq_ft
      cherre_parcel_id
      tax_assessor_id
      city
      state
      zip
      address
    }
  }
` as GQL<OtherParcelTaxAssessorsQuery, OtherParcelTaxAssessorsQueryVariables>;
