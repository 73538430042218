import React from 'react';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

const PaperComponent = ({
  children,
  onClose,
  isCompact,
  onAppsPage = false,
}) => {
  if (isCompact) {
    return (
      <div style={{ margin: 0 }}>
        {children}
        <Button
          style={{ position: 'fixed', right: 20, top: 150 }}
          color='primary'
          variant='contained'
          onClick={onClose}
        >
          close
        </Button>
      </div>
    );
  }
  return (
    <Paper
      elevation={5}
      style={{
        position: 'relative',
        top: onAppsPage ? 25 : 5,
        left: onAppsPage ? -17 : undefined,
        borderRadius: 10,
        overflow: 'hidden',
      }}
    >
      {children}
    </Paper>
  );
};

export default PaperComponent;
