import { useParams } from 'react-router';
import { useRecoilValue } from 'recoil';
import { LotPageParams } from '../../../../types';
import { nearbyLeases } from './recoil';
import * as T from './types';

const hook: T.UseNearbyLeases = () => {
  const { id } = useParams<LotPageParams>();
  return useRecoilValue(nearbyLeases(Number(id)));
};

export default hook;
