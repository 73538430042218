import React, { useCallback } from 'react';
import { push } from 'connected-react-router';
import geojsonBoundingBox from 'geojson-bounding-box';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { useSetRecoilState } from 'recoil';

import { MapBounds } from 'src/components/Map/index.new';
import useLocation from 'src/hooks/useLocation';
import { AddressAutocomplete } from 'src/products/core-prospect/search-pages/components/AddressAutocomplete';
import {
  AutocompleteSuggestion,
  AutocompleteSuggestionGroup,
  autocompletValueAtom,
} from 'src/products/core-prospect/search-pages/components/AddressAutocomplete/recoil/selectors';
import { useMapViewport } from '../../hooks/useMapViewport';

import { isSuggestionLoadingAtom } from './recoil/atoms';

import { geocodeSuggestion } from './utils';
import {
  geocode,
  getCherreParcelIdsByLatLong,
} from 'src/products/core-prospect/search-pages/components/AddressAutocomplete/utils';

export type AutocompleteProps = {
  customStyle?: React.CSSProperties;
};

export const Autocomplete: React.VFC<AutocompleteProps> = ({ customStyle }) => {
  const setIsSuggestionLoading = useSetRecoilState(isSuggestionLoadingAtom);

  const dispatch = useDispatch();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { recenter, rebound, setGeometry } = useMapViewport();

  const setAutocompleteValue = useSetRecoilState(autocompletValueAtom);

  const handleAddressSuggestion = useCallback(
    async (suggestion: AutocompleteSuggestion) => {
      try {
        const { latitude, longitude } = await geocode(suggestion.text);

        if (!latitude || !longitude) {
          return enqueueSnackbar('Undable to geocode address', {
            variant: 'error',
          });
        }

        recenter({
          latitude,
          longitude,
          zoom: 15,
        });

        if (suggestion?.group_name === AutocompleteSuggestionGroup.Address) {
          const [parcelId] =
            (await getCherreParcelIdsByLatLong({
              latitude,
              longitude,
              dispatch,
            })) || [];

          if (!parcelId) {
            return enqueueSnackbar('Unable to find tax record', {
              variant: 'error',
            });
          }

          dispatch(
            // @ts-expect-error: TODO: investigate this TS error
            push({
              pathname: location.pathname,
              query: {
                cherre_parcel_id: parcelId,
                latitude,
                longitude,
              },
            })
          );
        }
      } catch (e) {
        enqueueSnackbar('Error');
      }
    },
    [location, enqueueSnackbar, recenter]
  );

  const handleNonAddressSuggestion = useCallback(
    async (suggestion: AutocompleteSuggestion) => {
      const response = await geocodeSuggestion(suggestion);

      if (!response.geometry) {
        return enqueueSnackbar('no geometry');
      }

      setGeometry(response.geometry);

      const [lng1, lat1, lng2, lat2] = geojsonBoundingBox(response.geometry);
      const bounds: MapBounds = [
        [lng2, lat2],
        [lng1, lat1],
      ];

      rebound(bounds);
    },
    []
  );

  const onAutocompleteValueChange = async (
    suggestion: AutocompleteSuggestion | null
  ) => {
    try {
      setIsSuggestionLoading(true);

      setAutocompleteValue(suggestion);

      if (!suggestion) {
        return;
      }
      if (
        [
          AutocompleteSuggestionGroup.Address,
          AutocompleteSuggestionGroup.Street,
          AutocompleteSuggestionGroup.Intersection,
          AutocompleteSuggestionGroup.City,
        ].includes(suggestion.group_name)
      ) {
        await handleAddressSuggestion(suggestion);
      } else {
        await handleNonAddressSuggestion(suggestion);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsSuggestionLoading(false);
    }
  };

  return (
    <AddressAutocomplete
      onAutocompleteValueChange={onAutocompleteValueChange}
      customStyle={customStyle}
    />
  );
};
