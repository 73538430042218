import React from 'react';
import cx from 'classnames';
import isEqual from 'lodash/isEqual';
import { makeStyles, Box, Button, ButtonGroup } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

import { useDispatch, useSelector } from 'react-redux';
import { MARKETS_TABS } from '../../../../constants';
import { setMarketTab } from '../../../../redux/view/actions';

const useStyles = makeStyles((theme) => ({
  container: {
    color: '#9f4182',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  btn: {
    minWidth: 'max-content',
    background: '#fff',
    boxShadow: '0 2px 5px 0 #00000033',
    borderRadius: 5,
    height: '100%',
    cursor: 'pointer',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: grey[100],
      // color: theme.palette.primary.main,
    },
  },
  btnSelected: {
    color: theme.palette.primary.main,
  },
}));

export const TabSelector: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { marketTab } = useSelector<
    any,
    {
      marketTab: string;
    }
  >((state: any) => {
    return {
      marketTab: state.coreProspect.view.marketTab,
    };
  });

  if (!marketTab) {
    return null;
  }

  return (
    <Box className={classes.container}>
      <ButtonGroup>
        {Object.values(MARKETS_TABS).map((tab) => (
          <Button
            key={tab.value}
            variant={'contained'}
            className={cx(classes.btn, {
              [classes.btnSelected]: isEqual(marketTab, tab.value),
            })}
            onClick={() => dispatch(setMarketTab(tab.value))}
          >
            {`${tab.title}`}
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  );
};
