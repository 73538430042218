import React from 'react';
import { INITIAL_LIMIT, MAX_WIDTH } from './common';
import Button from '@material-ui/core/Button';

const TimelineLoadMore = ({ setLimit, limit }) => {
  return (
    <div
      className='cp-timeline-load-more'
      style={{
        maxWidth: MAX_WIDTH,
        display: 'flex',
        justifyContent: 'center',
        marginTop: 20,
      }}
    >
      <Button
        variant='text'
        color='primary'
        onClick={() => setLimit(limit + INITIAL_LIMIT)}
      >
        Load more
      </Button>
    </div>
  );
};

export default TimelineLoadMore;
