export const TYPE_TRANSACTION = 'transaction';
export const TYPE_MORTGAGE = 'mortgage';
export const TYPE_LOAN = 'loan';

export const COLORS = {
  [TYPE_TRANSACTION]: '#dec825',
  [TYPE_MORTGAGE]: '#feafea',
  [TYPE_LOAN]: '#feafea',
};

export const VALUE_UNKNOWN = 'UNKNOWN';

export const MAX_WIDTH = 800;

export const INITIAL_LIMIT = 5;
