import axios from 'axios';
import { selectorFamily } from 'recoil';

export type OwnerDetailsResponse = {
  total_properties: { value: number };
  markets: { buckets: { key: string; doc_count: number }[] };
  property_types: { buckets: { key: string; doc_count: number }[] };
  related_owners: { buckets: { key: string; doc_count: number }[] };
  total_area: { value: number };
};

export const ownerDetailsSelector = selectorFamily<
  OwnerDetailsResponse,
  string
>({
  key: 'COMPANY-DETAILS/OWNER',
  get: (owner_id) => async () => {
    const response = await axios.post<OwnerDetailsResponse>(
      '/api/v1/elastic/owners',
      { owner_id }
    );

    return response.data;
  },
});
