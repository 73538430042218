import React from 'react';
import compose from 'src/utils/compose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { withSnackbar } from 'notistack';

import Button from '@material-ui/core/Button';
import ButtonProgress from 'src/components/ButtonProgress';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import {
  getSaveSearchDialogState,
  getSaveSearchFetchStatus,
} from '../../selectors';

import { saveQuery } from 'src/products/core-prospect/redux/query/actions';

import { Context } from 'src/products/core-prospect/search-pages/USALot';
import { closeSaveSearchDialog } from 'src/products/core-prospect/redux/view/actions';

const FullWrapper = ({ children, onClose, ...otherProps }) => (
  <Dialog {...otherProps} open onClose={onClose}>
    {children}
  </Dialog>
);

const CompactWrapper = ({ children, ...otherProps }) => (
  <div
    {...otherProps}
    style={{
      zIndex: 2,
      position: 'fixed',
      background: '#fff',
      top: '60px',
      left: '0px',
      right: '0px',
      bottom: '0px',
    }}
  >
    {children}
  </div>
);

const SaveSearchDialog = ({
  isSaveSearchDialogOpen,
  closeSaveSearchDialog,
  saveQuery,
  fetchStatus,
  enqueueSnackbar,
  closeSnackbar,
  classes,
}) => {
  if (!isSaveSearchDialogOpen) {
    return null;
  }

  const { isCompact } = React.useContext(Context);

  const [name, setName] = React.useState('');

  const Wrapper = isCompact ? CompactWrapper : FullWrapper;

  return (
    <Wrapper
      id='js-core-prospect-save-search-dialog'
      onClose={closeSaveSearchDialog}
    >
      <DialogTitle style={{ paddingBottom: 5 }}>
        <span style={{ fontSize: 21 }}>Save Search</span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Save your current filters for easy access.
        </DialogContentText>
        <br />
        <TextField
          autoFocus
          placeholder='Enter search name'
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions style={{ margin: 20 }}>
        <Button onClick={closeSaveSearchDialog} color='primary'>
          cancel
        </Button>
        <ButtonProgress
          onClick={() => {
            if (!name) {
              return;
            }
            saveQuery(name).then(() => {
              closeSaveSearchDialog();
              enqueueSnackbar(<span>{name} search saved</span>, {
                autoHideDuration: 5000,
                ContentProps: {
                  classes: {
                    message: classes.break,
                  },
                },
                action: (key) => (
                  <IconButton
                    key='close'
                    aria-label='Close'
                    color='inherit'
                    onClick={() => closeSnackbar(key)}
                  >
                    <CloseIcon />
                  </IconButton>
                ),
                anchorOrigin: {
                  horizontal: isCompact ? 'center' : 'right',
                  vertical: isCompact ? 'bottom' : 'top',
                },
              });
            });
          }}
          color='primary'
          variant='contained'
          loading={fetchStatus === 'LOADING'}
        >
          save
        </ButtonProgress>
      </DialogActions>
    </Wrapper>
  );
};

function mapStateToProps(state) {
  return {
    isSaveSearchDialogOpen: getSaveSearchDialogState(state),
    fetchStatus: getSaveSearchFetchStatus(state),
  };
}

const styles = {
  break: {
    wordBreak: 'break-word',
  },
  title: {
    fontSize: 21,
  },
};

export default compose(
  withStyles(styles),
  withSnackbar,
  connect(mapStateToProps, {
    closeSaveSearchDialog,
    saveQuery,
  })
)(SaveSearchDialog);
