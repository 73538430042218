import React from 'react';
import { Typography, Tooltip, Box } from '@material-ui/core';

import { Flex } from 'src/components/Flex';
import CollapsiblePanel from 'src/components/CollapsiblePanel';
import numberWithCommas from 'src/utils/numberWithCommas';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { FormattedDemographicsStats } from './formatDemographicsStats';

type DemographicItemProps = {
  value: number | null | undefined;
  color: string;
  width: number;
};

const DemographicItem: React.FC<DemographicItemProps> = ({
  value,
  width,
  color,
}) => {
  return (
    <Flex style={{ alignItems: 'center' }}>
      <div
        style={{
          height: 10,
          width: `${width * 100}%`,
          backgroundColor: color,
          marginRight: 5,
        }}
      />
      <Typography variant='body2'>{numberWithCommas(value)}</Typography>
    </Flex>
  );
};

type DemographicAverageProps = FormattedDemographicsStats['crime']['average'];

const DemographicAverage = ({ label, value }: DemographicAverageProps) => {
  return (
    <React.Fragment>
      <Typography variant='body2'>{label}</Typography>
      <Box display='flex'>
        <Typography variant='body2'>{value}</Typography>
      </Box>
    </React.Fragment>
  );
};

export type DemographicPanelProps = {
  tooltip: string;
  title: string;
  items:
    | FormattedDemographicsStats['crime']['rows']
    | FormattedDemographicsStats['education']['rows']
    | FormattedDemographicsStats['employment']['rows']
    | FormattedDemographicsStats['household_income']['rows'];
  color: string;
  average?: FormattedDemographicsStats['household_income']['average'];
  allowToCollapse?: boolean;
};

const DemographicPanel: React.FC<DemographicPanelProps> = ({
  tooltip,
  title,
  items,
  color,
  average,
  allowToCollapse,
}) => {
  const maxValue = items.reduce((acc, item) => {
    const value = Number(item.value);

    if (value > acc) {
      return value;
    }

    return acc;
  }, 0);

  const titleElement = tooltip ? (
    <span style={{ display: 'flex', alignItems: 'center' }}>
      {title}
      <Tooltip title={tooltip}>
        <InfoOutlined style={{ marginLeft: 5 }} />
      </Tooltip>
    </span>
  ) : (
    title
  );

  return (
    <CollapsiblePanel title={titleElement} allowToCollapse={allowToCollapse}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '3fr 5fr',
          gridTemplateRows: average ? '30px auto' : 'auto',
        }}
      >
        <Flex
          style={{
            display: 'contents',
            alignItems: 'center',
          }}
        >
          {average && <DemographicAverage {...average} />}
        </Flex>
        {items.map(({ label, value }, i) => {
          return (
            <div
              key={i}
              style={{
                display: 'contents',
              }}
            >
              <Typography style={{ marginRight: 10, fontSize: 12 }}>
                {label}
              </Typography>
              <DemographicItem
                value={value}
                width={(value ?? 0) / maxValue}
                color={color}
              />
            </div>
          );
        })}
      </div>
    </CollapsiblePanel>
  );
};

export default DemographicPanel;
