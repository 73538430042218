import { lazy } from 'react';

export const Header = lazy(() => import('./Header'));
export const DealCloudInfo = lazy(() => import('./DealCloudInfo'));
export const Summary = lazy(() => import('./Summary'));
export const LotInformation = lazy(() => import('./LotInformation'));
export const LotSize = lazy(() => import('./LotSize'));
export const BuildingInfo = lazy(() => import('./BuildingInfo'));
export const Taxes = lazy(() => import('./Taxes'));
export const PropertyDetails = lazy(() => import('./PropertyDetails'));
export const Location = lazy(() => import('./Location'));
export const Demographics = lazy(() => import('./Demographics'));
export const CommercialSaleComps = lazy(() => import('./CommercialSaleComps'));
export const PropertyOwners = lazy(() => import('./PropertyOwners'));
export const Timeline = lazy(() => import('./Timeline'));
export const Leases = lazy(() => import('./Leases'));
export const Sidebar = lazy(() => import('./Sidebar'));
