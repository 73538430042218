import React from 'react';

import Map from 'src/components/Map/index.new';
import { LAYERS } from 'src/products/core-prospect/components/ReactMap/const';

import { useMapViewport } from '../hooks/useMapViewport';
import { useMap } from '../hooks/useMap';

import { PortfolioPopup } from './PortfolioPopup';
import { isSuggestionLoadingAtom } from './Autocomplete/recoil/atoms';
import { useRecoilValue } from 'recoil';
import { LinearProgress } from '@material-ui/core';
import { DealStageType } from 'src/services/Dealcloud/types';
import { useSelector } from 'react-redux';
import { CHERRE_SATELLITE_MAP_STYLE } from 'src/const';

type PortfolioMapProps = {
  stage: DealStageType;
  assets: Array<{
    taxAssessorId: number;
  }>;
};

const PortfolioMap: React.VFC<PortfolioMapProps> = ({ assets, stage }) => {
  const { recenter, latitude, longitude, zoom } = useMapViewport();

  const {
    activeParcelID,
    activeParcelLatitude,
    activeParcelLongitude,
    mapRef,
    measuredRef,
    onHover,
    sources,
    taxAssessorData,
  } = useMap({
    assets,
    stage,
  });

  const defaultMapToSatelliteView = useSelector(
    (state) => state.user.profile.value.defaultMapToSatelliteView
  );

  const isSuggestionLoading = useRecoilValue(isSuggestionLoadingAtom);

  if (!taxAssessorData) {
    return null;
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
      ref={measuredRef}
    >
      {isSuggestionLoading ? (
        <LinearProgress
          style={{
            zIndex: 1,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
          }}
        />
      ) : null}
      {latitude && longitude && zoom ? (
        <Map
          mapStyle={
            defaultMapToSatelliteView ? CHERRE_SATELLITE_MAP_STYLE : undefined
          }
          ref={mapRef}
          sources={sources}
          interactiveLayerIds={
            activeParcelID
              ? []
              : [
                  LAYERS.PARCELS_FILL,
                  LAYERS.DEALS_ON_MAP,
                  LAYERS.DEALS_ON_MAP_HOVERED,
                ]
          }
          onHover={onHover}
          width='100%'
          height='100%'
          zoom={zoom}
          latitude={latitude}
          longitude={longitude}
          scrollZoom
          onViewportChange={(map) => {
            const center = map.getCenter();
            recenter({
              latitude: center.lat,
              longitude: center.lng,
              zoom: map.getZoom(),
            });
          }}
        >
          {activeParcelID ? (
            <div style={{ position: 'relative', zIndex: 9999 }}>
              <PortfolioPopup
                latitude={Number(activeParcelLatitude)}
                longitude={Number(activeParcelLongitude)}
                parcel_id={activeParcelID}
                key={activeParcelID}
              />
            </div>
          ) : null}
        </Map>
      ) : null}
    </div>
  );
};

export default PortfolioMap;
