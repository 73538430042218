import React from 'react';
import Typography from '@material-ui/core/Typography';
import useIsCompact from 'src/hooks/useIsCompact';
import TimelineText from './TimelineText';
import TimelineTextItem from './TimelineTextItem';
import { VALUE_UNKNOWN } from '../../common';
import redux from 'src/products/shell/redux/store/configureStore';
import { get } from 'lodash';

export const getLookup = (key) => {
  const state = redux.store.getState();
  return state.common.lookups.lookupsDictionary[key];
};

export const getLookupValueByListName = (listName, lookup_code) => {
  const state = redux.store.getState();
  const { lookupsByListName } = state.common.lookups;
  const lookupsList = lookupsByListName[listName] || [];
  const lookup = lookupsList.find(
    (lookup) => lookup.lookup_code === lookup_code
  );
  const value = get(lookup, 'lookup_value', lookup_code);

  return value;
};

const TimelineTransactionText = ({ item, isNyc }) => {
  const isCompact = useIsCompact();

  return (
    <TimelineText>
      {isNyc ? (
        item.document_type ? (
          <TimelineTextItem>
            <TimelineTextItem>
              <Typography variant='body2'>{item.document_type}</Typography>
            </TimelineTextItem>
            {!isCompact ? <TimelineTextItem>&bull;</TimelineTextItem> : null}
          </TimelineTextItem>
        ) : null
      ) : item.document_type_code ? (
        <TimelineTextItem>
          <TimelineTextItem>
            <Typography variant='body2'>
              {getLookupValueByListName(
                'Document Type Code Description',
                item.document_type_code
              ).toUpperCase()}
            </Typography>
          </TimelineTextItem>
          {!isCompact ? <TimelineTextItem>&bull;</TimelineTextItem> : null}
        </TimelineTextItem>
      ) : null}
      <TimelineTextItem>
        <TimelineTextItem>
          <Typography variant='body2'>
            {(isNyc
              ? item.grantors?.map((item) => item.name).join(', ')
              : item.recorder_grantor_v2__recorder_id
                  ?.map((item) => item.grantor_name)
                  .join(', ')) || VALUE_UNKNOWN}
          </Typography>
        </TimelineTextItem>
        <TimelineTextItem>&rarr;</TimelineTextItem>
        <TimelineTextItem>
          <Typography variant='body2'>
            {(isNyc
              ? item.grantees?.map((item) => item.name).join(', ')
              : item.recorder_grantee_v2__recorder_id
                  ?.map((item) => item.grantee_name)
                  .join(', ')) || VALUE_UNKNOWN}
          </Typography>
        </TimelineTextItem>
      </TimelineTextItem>
    </TimelineText>
  );
};

export default TimelineTransactionText;
