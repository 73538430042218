import { Button, makeStyles, Tooltip } from '@material-ui/core';
import React from 'react';

const useClasses = makeStyles(() => ({
  container: {
    position: 'absolute',
    right: '20px',
    bottom: '200px',
  },
  iconButton: {
    background: '#FFFFFF',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    padding: '4px',
    minWidth: 'unset',
    width: '40px',
    height: '40px',
    '&:hover': {
      background: '#EEEEEE',
    },
    '&:disabled': {
      background: '#CCCCCC',
    },
  },
}));

type USZoomButtonProps = {
  onClick: () => void;
  disabled: boolean;
};

const USZoomButton: React.FC<USZoomButtonProps> = ({ onClick, disabled }) => {
  const classes = useClasses();
  return (
    <div className={classes.container}>
      <Tooltip title='Zoom to US'>
        <Button
          disabled={disabled}
          onClick={onClick}
          className={classes.iconButton}
        >
          <img src='/assets/core-explore/us-zoom-icon.svg' />
        </Button>
      </Tooltip>
    </div>
  );
};

export default USZoomButton;
