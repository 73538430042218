import { DEFAULT_MIN_MAX } from './constants';

const getSquareFeetWhere = (min, max) => {
  if (!min && !max) {
    return '';
  }
  if (min && max) {
    return 'sq_ft: {_gte: $min_sq_ft, _lte: $max_sq_ft},';
  }
  if (min && !max) {
    return 'sq_ft: {_gte: $min_sq_ft},';
  }
  if (max && !min) {
    return 'sq_ft: {_lte: $max_sq_ft},';
  }
};
// 'price: {_gte: $min_price, _lte: $max_price},'
const getSalesPriceWhere = (min, max) => {
  if (!min && !max) {
    return '';
  }
  if (min && max) {
    return 'price: {_gte: $min_price, _lte: $max_price},';
  }
  if (min && !max) {
    return 'price: {_gte: $min_price},';
  }
  if (max && !min) {
    return 'price: {_lte: $max_price},';
  }
};

// 'year_built: {_gte: $min_year_built, _lte: $max_year_built},'
const getYearBuiltWhere = (min, max) => {
  if (!min && !max) {
    return '';
  }
  if (min && max) {
    return 'year_built: {_gte: $min_year_built, _lte: $max_year_built},';
  }
  if (min && !max) {
    return 'year_built: {_gte: $min_year_built},';
  }
  if (max && !min) {
    return 'year_built: {_lte: $max_year_built},';
  }
};

// 'status_date: {_gte: $min_status_date, _lte: $max_status_date},'
const getStatusDateWhere = (min, max) => {
  if (!min && !max) {
    return '';
  }
  if (min && max) {
    return 'status_date: {_gte: $min_status_date, _lte: $max_status_date},';
  }
  if (min && !max) {
    return 'status_date: {_gte: $min_status_date},';
  }
  if (max && !min) {
    return 'status_date: {_gte: $max_status_date},';
  }
};

// price_per_sq_ft_unit
const getPricePerSquareFootOrUnit = (min, max) => {
  if (!min && !max) {
    return '';
  }
  if (min && max) {
    return 'price_per_sq_ft_unit: {_gte: $min_price_per_sq_ft_unit, _lte: $max_price_per_sq_ft_unit},';
  }
  if (min && !max) {
    return 'price_per_sq_ft_unit: {_gte: $min_price_per_sq_ft_unit},';
  }
  if (max && !min) {
    return 'price_per_sq_ft_unit: {_gte: $max_price_per_sq_ft_unit},';
  }
};

export const getRcaSalesQuery = ({
  entityName,
  squareFeetRange = DEFAULT_MIN_MAX,
  salePriceRange = DEFAULT_MIN_MAX,
  yearBuiltRange = DEFAULT_MIN_MAX,
  statusDateRange = DEFAULT_MIN_MAX,
  pricePerSquareFootUnitRange = DEFAULT_MIN_MAX,
}) => {
  // SQUARE FEET
  const squareFeetMinVariable = squareFeetRange.min
    ? '$min_sq_ft: numeric'
    : '';
  const squareFeetMaxVariable = squareFeetRange.max
    ? '$max_sq_ft: numeric'
    : '';
  const squareFeetWhere = getSquareFeetWhere(
    squareFeetRange.min,
    squareFeetRange.max
  );
  // PRICE
  const salesPriceMinVariable = salePriceRange.min ? '$min_price: numeric' : '';
  const salesPriceMaxVariable = salePriceRange.max ? '$max_price: numeric' : '';
  const salesPriceWhere = getSalesPriceWhere(
    salePriceRange.min,
    salePriceRange.max
  );
  // YEAR BUILT
  const yearBuiltMinVariable = yearBuiltRange.min ? '$min_year_built: Int' : '';
  const yearBuiltMaxVariable = yearBuiltRange.max ? '$max_year_built: Int' : '';
  const yearBuiltWhere = getYearBuiltWhere(
    yearBuiltRange.min,
    yearBuiltRange.max
  );
  // STATUS DATE
  const statusDateMinVariable = statusDateRange.min
    ? '$min_status_date: date'
    : '';
  const statusDateMaxVariable = statusDateRange.max
    ? '$max_status_date: date'
    : '';
  const statusDateWhere = getStatusDateWhere(
    statusDateRange.min,
    statusDateRange.max
  );
  // PRICE PER SQUARE FOOT OR UNIT
  const pricePerSquareFootMinVariable = pricePerSquareFootUnitRange.min
    ? '$min_price_per_sq_ft_unit: numeric'
    : '';
  const pricePerSquareFootMaxVariable = pricePerSquareFootUnitRange.max
    ? '$max_price_per_sq_ft_unit: numeric'
    : '';
  const pricePerSquareFootWhere = getPricePerSquareFootOrUnit(
    pricePerSquareFootUnitRange.min,
    pricePerSquareFootUnitRange.max
  );

  return `
    query RCASalesQuery(
    $zip: String!
    $main_property_types: [String!]
    ${squareFeetMinVariable}
    ${squareFeetMaxVariable}
    ${salesPriceMinVariable}
    ${salesPriceMaxVariable}
    ${yearBuiltMinVariable}
    ${yearBuiltMaxVariable}
    ${statusDateMinVariable}
    ${statusDateMaxVariable}
    ${pricePerSquareFootMinVariable}
    ${pricePerSquareFootMaxVariable}
  ) {
    ${entityName}(
      where: {
        _and: [
          {
            transaction_type: { _eq: "Sale" },
            zip: { _eq: $zip },
            main_property_type: {_in: $main_property_types},
            ${squareFeetWhere}
            ${salesPriceWhere}
            ${yearBuiltWhere}
            ${statusDateWhere}
            ${pricePerSquareFootWhere}
          }
        ]
      }
    ) {
      address
      adjusted_price
      buildable_sq_ft
      buyer_name_1
      seller_name_1
      city
      longitude
      latitude
      main_property_type
      price
      price_per_building_sq_ft
      price_per_sq_ft_unit
      prior_sale_date
      prior_sale_price_amount
      property_id
      property_name
      sq_ft
      state
      status_date
      transaction_type
      year_built
      zip
      cherre_address__address {
        tax_assessor_v2__property_address {
          tax_assessor_id
          assessor_parcel_number_raw
          lot_size_sq_ft
          property_use_code_mapped
          cherre_parcel_id
        }
      }
    }
  }`;
};

export const parcelGeomQuery = /* GraphQL */ `
  query RCACherreParcelIdGemo($cherre_parcel_id: String!) {
    usa_map_parcel_v2(where: { cherre_parcel_id: { _eq: $cherre_parcel_id } }) {
      cherre_parcel_id
      geom
    }
  }
`;
