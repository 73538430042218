import React from 'react';
import TimelineChip from './TimelineChip';
import { COLORS, TYPE_MORTGAGE, TYPE_TRANSACTION } from './common';

const TimelineFilterChips = ({ setFilter, filter }) => {
  return (
    <div style={{ marginBottom: 30 }}>
      <TimelineChip
        label='All'
        selected={filter == null}
        onClick={() => {
          setFilter(null);
        }}
      />
      <TimelineChip
        label='Transactions'
        selected={filter === TYPE_TRANSACTION}
        onClick={() => {
          setFilter(TYPE_TRANSACTION);
        }}
        color={COLORS[TYPE_TRANSACTION]}
      />
      <TimelineChip
        label='Mortgages'
        selected={filter === TYPE_MORTGAGE}
        onClick={() => {
          setFilter(TYPE_MORTGAGE);
        }}
        color={COLORS[TYPE_MORTGAGE]}
      />
    </div>
  );
};

export default TimelineFilterChips;
