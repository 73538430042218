import { keyBy } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import InfiniteList from 'src/components/InfiniteList';
import { PropertyAddressSummaryEvent } from 'src/products/core-prospect/components/PropertyAddressSummary';
import { useCreateDeal } from 'src/services/Dealcloud/createDeal';
import { useLocalDeal } from 'src/services/Dealcloud/localDeal';
import { useEditDeal } from 'src/services/Dealcloud/editDeal';
import { usePopupData } from '../hook';
import { PopupPropertyType, PopupPropertiesType } from '../hook/usePopupData';
import { useStyles } from '../styles';
import { LotProperty, LotPropertyProps } from './LotProperty';

const getString = (val) => (val ? String(val) : '');

export const LotPopupPropertiesList: React.VFC<{
  properties: PopupPropertiesType;
  fetchMoreProperties: ReturnType<typeof usePopupData>['fetchMoreProperties'];
  rowHeight: number;
  height: number;
  lotPropertyProps?: Partial<LotPropertyProps>;
  portfolio?: boolean;
  onClose?: () => void;
}> = ({
  properties,
  fetchMoreProperties,
  rowHeight,
  height,
  lotPropertyProps,
  portfolio,
  onClose,
}) => {
  const classes = useStyles();

  const { id: dealId } = useParams<{ id: string }>();

  const {
    addAsset,
    removeAsset,
    deal: portfolioDeal,
  } = useLocalDeal(dealId !== 'new' && portfolio ? Number(dealId) : 'new');

  const onClickAddPortfolio = useCallback(
    async ({ property }: { property: PopupPropertyType }) => {
      await addAsset({
        address: property.address || '',
        buildingArea: getString(property.building_sq_ft),
        city: property.city,
        country: 'USA',
        isMultiFam: '',
        latitude: property.latitude,
        longitude: property.longitude,
        state: property.state,
        taxAssessorId: property.tax_assessor_id,
        unitType: '',
        yearBuilt: getString(property.year_built),
        zipCode: property.zip,
        parcelId: property.cherre_parcel_id || '',
        numberOfAppartments: getString(property.units_count),
      });

      // add a delay before closing the overlay to prevent click event
      // from being triggered on the map layer behind the overlay (which would open the overlay again)
      setTimeout(() => onClose?.(), 300);
    },
    []
  );

  const onClickRemovePortfolio = useCallback<PropertyAddressSummaryEvent>(
    ({ property }) => {
      removeAsset(property.taxAssessorId || 0);
      // add a delay before closing the overlay to prevent click event
      // from being triggered on the map layer behind the overlay (which would open the overlay again)
      setTimeout(() => onClose?.(), 300);
    },
    []
  );

  const portfolioAssetsByTaxId = useMemo(() => {
    return keyBy(portfolioDeal?.assets || [], 'taxAssessorId');
  }, [portfolioDeal?.assets]);

  const [createDeal] = useCreateDeal();
  const [editDeal] = useEditDeal();

  const onClickAddDeal = useCallback<PropertyAddressSummaryEvent>(
    async ({ property }) => {
      await createDeal(property.taxAssessorId || 0);
    },
    [createDeal]
  );

  const onClickEditDeal = useCallback<PropertyAddressSummaryEvent>(
    async ({ deal }) => {
      if (!deal) {
        return;
      }
      await editDeal(deal.dealId);
    },
    [editDeal]
  );

  return (
    <div className={classes.list} id='test'>
      <InfiniteList
        width={300}
        height={height}
        minimumBatchSize={10}
        isRowLoaded={({ index }) => properties[index]}
        loadMoreRows={() => {
          fetchMoreProperties();
        }}
        rowHeight={rowHeight}
        rowCount={properties.length}
        rowRenderer={({ index, style }) => {
          const property = properties[index];
          const inPortfolio =
            portfolio && !!portfolioAssetsByTaxId[property.tax_assessor_id];

          return (
            <div key={index} style={style}>
              <LotProperty
                property={property}
                rowHeight={rowHeight}
                onClickAddDeal={!portfolio ? onClickAddDeal : undefined}
                onClickEditDeal={!portfolio ? onClickEditDeal : undefined}
                onClickRemovePortfolio={
                  portfolio && inPortfolio ? onClickRemovePortfolio : undefined
                }
                onClickAddPortfolio={
                  portfolio && !inPortfolio ? onClickAddPortfolio : undefined
                }
                {...lotPropertyProps}
              />
            </div>
          );
        }}
      />
    </div>
  );
};
