import React from 'react';
import MultipleSelection from '../MultipleSelection/component';

const RecordedOwnerTypesSelection = (args) => {
  return (
    <MultipleSelection
      {...args}
      data={{
        options: [
          {
            value: 'individual',
            name: 'Individual',
          },
          {
            value: 'company',
            name: 'Company',
          },
          {
            value: 'government',
            name: 'Government',
          },
          {
            value: 'unknown',
            name: 'Unknown',
          },
        ],
      }}
    />
  );
};

export default RecordedOwnerTypesSelection;
