import { atom } from 'recoil';

export const latitudeAtom = atom<number | null>({
  key: 'CREATE/EDIT/PORTFOLIO_MAP_CENTER_LATITUDE',
  default: null,
});

export const longitudeAtom = atom<number | null>({
  key: 'CREATE/EDIT/PORTFOLIO_MAP_CENTER_LONGITUDE',
  default: null,
});

export const zoomAtom = atom<number | null>({
  key: 'CREATE/EDIT/PORTFOLIO_MAP_ZOOM',
  default: null,
});

export const widthAtom = atom<number | null>({
  key: 'CREATE/EDIT/PORTFOLIO_MAP_WIDTH',
  default: 0,
});

export const heightAtom = atom<number | null>({
  key: 'CREATE/EDIT/PORTFOLIO_MAP_HEIGHT',
  default: 0,
});

export const geometryAtom = atom<GeoJSON.Feature<GeoJSON.Geometry> | null>({
  key: 'CREATE/EDIT/PORTFOLIO_MAP_GEOMETRY',
  default: null,
});
