import { selectorFamily } from 'recoil';
import { cherrePropertyUseHierarchical } from 'src/const/lookups';
import { merge } from 'src/utils/recoil/utils';
import {
  taxAssessorQuery,
  nycDetailsWhenAvailable,
  lookupQuery,
} from '../../../recoil';
import { Tax_AssessorQueryVariables } from '../../../recoil/__generated__/taxAssessor';
import { SummaryData } from '../types';
import {
  nycDetailsToSummary,
  taxAssessorToSummary,
  propertyUseStdCodeToSummary,
  propertyUseCodeMappedToSummary,
} from './formatters';

export const summaryData = selectorFamily<
  SummaryData,
  Tax_AssessorQueryVariables
>({
  key: 'LOT-PAGE/SUMMARY-DATA-TRADITIONAL',
  get:
    (params) =>
    ({ get }) => {
      const defaultValue = {
        propertyGroupType: '—',
        lotSizeSqft: '—',
        lastSale: '—',
        owner: '—',
      };

      const taxAssessorValue = get(taxAssessorQuery(params));
      const taxAssessorValueFormatted = taxAssessorToSummary(taxAssessorValue);

      const bbl = taxAssessorValue.tax_assessor_v2?.[0]?.cherre_bbl;

      const nycValue = get(nycDetailsWhenAvailable(bbl));
      const nycValueFormatted = nycDetailsToSummary(nycValue);

      const stdCode =
        taxAssessorValue?.tax_assessor_v2?.[0]?.property_use_standardized_code;

      const propertyUseStdCode = get(
        lookupQuery({
          object_name: 'tax_assessor_v2',
          field_name: 'property_use_standardized_code',
          lookup_code: stdCode,
        })
      );
      const propertyUseStdCodeFormatted =
        propertyUseStdCodeToSummary(propertyUseStdCode);

      const mappedCode =
        taxAssessorValue?.tax_assessor_v2?.[0]?.property_use_code_mapped;

      const propertyUseCodeMapped = cherrePropertyUseHierarchical.find(
        (a) => a.lookup_code.toString() === mappedCode?.toString()
      )?.lookup_value;

      const propertyUseCodeMappedFormatted = propertyUseCodeMappedToSummary(
        propertyUseCodeMapped
      );

      return merge(
        defaultValue,
        taxAssessorValueFormatted,
        propertyUseStdCodeFormatted,
        propertyUseCodeMappedFormatted,
        nycValueFormatted
      );
    },
});
