import { useParams } from 'react-router';
import { useRecoilValue } from 'recoil';
import { lotMap } from '../../recoil/lotMap';
import { LotPageParams } from '../../types';
import * as T from './types';

const hook: T.UseLocation = () => {
  const { id } = useParams<LotPageParams>();
  const values = useRecoilValue(lotMap({ tax_assessor_id: Number(id) }));
  return values;
};

export default hook;
