import { execQuery } from '../query/actions/execQuery';
import { clearSelectedRows } from '../table/actions';
import * as TYPES from './types';

export const toggleCoreProspectView = (viewerName) => {
  return (dispatch) => {
    dispatch({
      type: TYPES.TOGGLE_CORE_PROSPECT_VIEW,
      viewerName,
    });

    dispatch(clearSelectedRows());

    dispatch(
      execQuery({
        cards: false,
        count: false,
        rebound: viewerName === 'map',
        results: true,
        viewportOnly: true,
        id: 'Toggle core explore view',
      })
    );
  };
};

export const setResultsType = (resultsType) => {
  return (dispatch) => {
    dispatch({
      type: TYPES.SET_RESULTS_TYPE,
      resultsType,
    });

    dispatch(
      execQuery({
        cards: false,
        count: false,
        rebound: false,
        results: true,
        viewportOnly: true,
        id: 'Change results type',
      })
    );
  };
};

export const setMarketTab = (marketTab) => {
  return {
    type: TYPES.SET_MARKET_TAB,
    marketTab,
  };
};

export const openSaveSearchDialog = () => {
  return {
    type: TYPES.OPEN_SAVE_SEARCH_DIALOG,
  };
};

export const closeSaveSearchDialog = () => {
  return {
    type: TYPES.CLOSE_SAVE_SEARCH_DIALOG,
  };
};

export const clickOwner = (id, name) => {
  return {
    type: TYPES.OWNER_CLICKED,
    id,
    name,
  };
};

export const collapseSidebar = () => ({
  type: TYPES.COLLAPSE_SIDEBAR,
});

export const expandSidebar = () => ({
  type: TYPES.EXPAND_SIDEBAR,
});

export const hideCards = () => ({
  type: TYPES.HIDE_CARDS,
});

export const displayCards = () => ({
  type: TYPES.DISPLAY_CARDS,
});
