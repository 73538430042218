import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  count: {
    position: 'absolute',
    right: 20,
    top: 18,
  },
  title: {
    position: 'relative',
    padding: '14px 0',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  partner: {
    marginLeft: 10,
  },
});

const FilterGroup = ({ group, icon, onClick, className = '' }) => {
  const count = group.searchParameters.length;
  const classes = useStyles();

  return (
    <>
      <Typography
        onClick={onClick}
        className={`${classes.title} ${className}`}
        variant='h6'
      >
        {icon}{' '}
        <span style={{ lineHeight: 1.3 }}>
          {group.label} <br />
          <span style={{ fontSize: 12, fontWeight: 400, color: '#424242' }}>
            {group.description}
          </span>
        </span>
        {group.partner && (
          <Typography
            color='textSecondary'
            className={classes.partner}
            variant='body2'
          >
            {group.partner}
          </Typography>
        )}
        {count > 0 ? (
          <Typography color='primary' className={classes.count}>
            {count}
          </Typography>
        ) : null}
      </Typography>
    </>
  );
};

export default FilterGroup;
