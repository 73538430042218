import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export default makeStyles((theme) => ({
  container: {
    display: 'grid',
    gap: '20px',
  },
  title: {
    marginBottom: 5,
    color: grey['900'],
  },
  stagesContainer: {
    gap: '0px 20px',
    marginLeft: '-8px',
    columnCount: 2,
  },
  selectedCheckbox: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    fontWeight: theme.typography.fontWeightMedium as number,
  },
  unselectedCheckbox: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    fontWeight: theme.typography.fontWeightRegular as number,
  },
  checkboxText: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  teamsContainer: {
    gap: '0px 20px',
    marginLeft: '-8px',
    display: 'grid',
    gridTemplateAreas: `
      "Industrial Multi-Family"
      "Office Office"
    `,
    gridTemplateColumns: '1fr 1fr',
  },
}));
