import * as OWNERS_TYPES from '../../owners/types';
import {
  getSearchParameters,
  patchByCondoUnitsSearchParameter,
} from 'src/products/core-prospect/search-pages/selectors';
import { OWNERS_ID_FIELD } from '../../owners/actions';
import { getFeature } from '../actions';
import { getAreDealsFiltersEnabled } from '../utils';

type ExecTableOwnersQuery = {
  viewportOnly: boolean;
  paginate: boolean;
  isCount: boolean;
  size?: number;
  isExport: boolean;
};

export const execTableOwnersQuery = ({
  viewportOnly,
  paginate,
  isCount,
  size,
  isExport,
}: ExecTableOwnersQuery) => {
  return async (dispatch, getState) => {
    const timestamp = Date.now();
    const state = getState();
    const searchParameters = getSearchParameters(state);
    const orderBy = state.coreProspect.owners.orderBy;
    const { after, before, rowsPerPage } = state.coreProspect.owners;

    let listId = getState().router.location.query.list_id;

    if (Number.isNaN(Number(listId))) {
      listId = undefined;
    }

    size = size || rowsPerPage;

    const filters = patchByCondoUnitsSearchParameter(state, searchParameters);
    const areDealsFiltersEnabled = getAreDealsFiltersEnabled(
      listId,
      getState()
    );

    return dispatch({
      url: '/search/usa-lots?execTableOwnersQuery',
      method: 'post',
      cancelable: true,
      send: {
        areDealsFiltersEnabled,
        listId: isExport ? undefined : listId,
        isOwners: !isCount,
        isOwnersCount: isCount,
        after: paginate ? after?.[OWNERS_ID_FIELD] : undefined,
        before: paginate ? before?.[OWNERS_ID_FIELD] : undefined,
        filters,
        feature: getFeature({
          viewportOnly,
          state,
        }),
        size: size || rowsPerPage,
        sort: orderBy
          ? {
              direction: orderBy.direction,
              fields: [orderBy.field],
            }
          : undefined,
      },
      dataToDispatch: { timestamp },
      types: isExport
        ? []
        : isCount
        ? [
            OWNERS_TYPES.TABLE_RESULTS_COUNT_REQUEST,
            OWNERS_TYPES.TABLE_RESULTS_COUNT_SUCCESS,
            OWNERS_TYPES.TABLE_RESULTS_COUNT_FAILED,
          ]
        : [
            OWNERS_TYPES.TABLE_RESULTS_REQUEST,
            OWNERS_TYPES.TABLE_RESULTS_SUCCESS,
            OWNERS_TYPES.TABLE_RESULTS_FAILED,
          ],
    });
  };
};
