import { makeStyles, Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'grid',
    position: 'absolute',
    zIndex: 998,
    padding: '10px',
    gridAutoFlow: 'column',
    gridAutoColumns: 'auto 1fr',
    width: '100%',
    justifyItems: 'end',
    gridGap: '10px',
  },
  button: {
    minWidth: '32px',
    minHeight: '32px',
    maxWidth: '32px',
    maxHeight: '32px',
    background: '#FFFFFF',
  },
  icon: {
    width: '17px',
    height: '17px',
  },
  action: {
    gridGap: '10px',
    display: 'grid',
    gridAutoFlow: 'column',
  },
  nodeal: {
    display: 'grid',
    position: 'absolute',
    zIndex: 998,
    padding: '10px',
    gridAutoFlow: 'column',
    gridAutoColumns: '1fr auto auto auto',
    width: '100%',
    justifyItems: 'start',
    gridGap: '10px',
  },
  addbtn: {
    minWidth: 'max-content',
    background: '#FFFFFF',
    borderRadius: 6,
    height: '100%',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    textTransform: 'none',
    padding: '8px 12px',
    '&:hover': {
      backgroundColor: grey[100],
      color: theme.palette.primary.light,
    },
  },
}));
