import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme) => ({
  root: ({ loading }: { loading: boolean }) => ({
    width: loading ? undefined : 450,
    backgroundColor: '#fff',
    padding: 10,
  }),
  address: {
    display: 'flex',
    alignItems: 'center',
    '&:hover $addressIcon': {
      display: 'block',
    },
  },
  addressText: {
    display: 'block',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  addressIcon: {
    display: 'none',
    fontSize: 17,
    marginLeft: 3,
  },
  divider: { height: 10 },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  footerLink: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: 600,
  },
  resetDecoration: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.primary.light,
    },
  },
  footerIcon: {
    fontSize: 20,
    marginRight: 5,
  },
  pagination: {
    display: 'flex',
    alignItems: 'center',
  },
  fieldName: {
    fontSize: 12,
    color: grey[700],
  },
  fieldValue: {
    fontSize: 12,
  },
  leaseIcon: {
    width: 15,
    height: 19,
    marginRight: 5,
  },
}));
