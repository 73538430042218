import React from 'react';
import Link from '@material-ui/core/Link';

const LotPageLink = ({ value, onClick }) => {
  return (
    <Link
      title='Go to lot page'
      style={{ cursor: 'pointer' }}
      onClick={onClick}
    >
      {value}
    </Link>
  );
};

export default LotPageLink;
