import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import {
  getLookupsByTableName,
  requestLookups,
} from 'src/products/shell/redux/lookups/actions';
import {
  mapPopupUsaLotPropertiesCountQuery,
  paginatedUsaLotProperties,
} from '../query';

export const usePopupData = (parcel_id) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestLookups());
    dispatch(getLookupsByTableName());
  }, []);

  const {
    data: properties,
    loading: arePropertiesLoading,
    fetchMore: fetchMoreProperties,
  } = useRecoilValue(
    paginatedUsaLotProperties({
      id: parcel_id,
    })
  );

  const propertiesCountData = useRecoilValueLoadable(
    mapPopupUsaLotPropertiesCountQuery({
      id: parcel_id,
    })
  );

  const taxAssessorForStreetView = (properties || []).find((tax_assessor) => {
    return tax_assessor.latitude && tax_assessor.longitude;
  });

  const isCountLoading = propertiesCountData.state === 'loading';

  const count =
    propertiesCountData.valueMaybe()?.tax_assessor_v2_aggregate?.aggregate
      ?.count ?? 0;

  const isLoading =
    isCountLoading || (properties.length === 0 && arePropertiesLoading);

  const isLoadingMore = properties.length > 0 && arePropertiesLoading;

  const dealTaxAssessor =
    properties.length === 1
      ? Number(properties?.[0].tax_assessor_id)
      : undefined;

  return {
    isLoading,
    isLoadingMore,
    count,
    properties,
    fetchMoreProperties,
    taxAssessorForStreetView,
    dealTaxAssessor,
  };
};

export type PopupPropertyType = ReturnType<
  typeof usePopupData
>['properties'][number];

export type PopupPropertiesType = ReturnType<typeof usePopupData>['properties'];
