import { makeStyles, Tab, Tabs } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import sum from 'lodash/sum';

import numberWithCommas from 'src/utils/numberWithCommas';
import constants from 'src/const';
import { setResultsType } from '../../../redux/view/actions';
import { RESULTS_TYPES } from '../../../constants';

const { NAV_BAR_HEIGHT } = constants;

const useStyles = makeStyles((theme) => ({
  tabsRoot: {
    height: NAV_BAR_HEIGHT,
  },
  tabsFlexContainer: {
    height: NAV_BAR_HEIGHT,
  },
  tabIndicator: {
    backgroundColor: theme.palette.primary.main,
    height: 4,
    borderRadius: '10px 10px 0 0',
  },
  tabRoot: {
    textTransform: 'none',
    height: NAV_BAR_HEIGHT,
    fontSize: 14,
    minWidth: 'unset',
    padding: 6,
  },
}));

const joinCountStatuses = (statuses) => {
  if (statuses.every((status) => status?.value === 'LOADED')) {
    return { value: 'LOADED' };
  }
  if (statuses.some((status) => status?.value === 'FAILED')) {
    return { value: 'FAILED' };
  }
  return { value: 'LOADING' };
};

const ViewerTabs = () => {
  const classes = useStyles();
  const {
    resultsType,
    featureFlags,
    propertyCount,
    propertyCountFetchStatus,
    ownerCount,
    ownerCountFetchStatus,
    reisSubmarketsCount,
    reisSubmarketsCountFetchStatus,
    zipcodeCount,
    zipcodeCountFetchStatus,
  } = useSelector((state) => {
    return {
      resultsType: state.coreProspect.view.resultsType,
      featureFlags: state.user.profile.value.featureFlags,
      propertyCount: state.coreProspect.mapPinResults.resultsCount,
      propertyCountFetchStatus:
        state.coreProspect.mapPinResults.resultsCountFetchStatus,
      ownerCount: state.coreProspect.owners.count,
      ownerCountFetchStatus: state.coreProspect.owners.countFetchStatus,
      reisSubmarketsCount: state.coreProspect.reisSubmarkets.count,
      reisSubmarketsCountFetchStatus:
        state.coreProspect.reisSubmarkets.countFetchStatus,
      zipcodeCount: state.coreProspect.zipcodes.count,
      zipcodeCountFetchStatus: state.coreProspect.zipcodes.countFetchStatus,
    };
  });

  const dispatch = useDispatch();

  const lastValidPropertyCount = React.useRef(propertyCount);

  React.useEffect(() => {
    if (propertyCount >= 0) {
      lastValidPropertyCount.current = propertyCount;
    }
  }, [propertyCount]);

  const validPropertyCount =
    propertyCount >= 0 ? propertyCount : lastValidPropertyCount.current;

  const lastValidOwnerCount = React.useRef(ownerCount);

  React.useEffect(() => {
    if (ownerCount >= 0) {
      lastValidOwnerCount.current = ownerCount;
    }
  }, [ownerCount]);

  const validOwnerCount =
    ownerCount >= 0 ? ownerCount : lastValidOwnerCount.current;

  const marketsCount = sum([zipcodeCount, reisSubmarketsCount]);

  const lastValidMarketsCount = React.useRef(marketsCount);

  React.useEffect(() => {
    if (marketsCount >= 0) {
      lastValidMarketsCount.current = marketsCount;
    }
  }, [marketsCount]);

  const validMarketsCount =
    marketsCount >= 0 ? marketsCount : lastValidMarketsCount.current;

  const tabs = [
    {
      label: 'Properties',
      type: RESULTS_TYPES.TAX_RECORDS,
      count: validPropertyCount,
      countFetchStatus: propertyCountFetchStatus,
    },
    featureFlags.CoreExploreTabs_owners
      ? {
          label: 'Owners',
          type: RESULTS_TYPES.OWNERS,
          count: validOwnerCount,
          countFetchStatus: ownerCountFetchStatus,
        }
      : null,
    featureFlags.CoreExploreTabs_zipcodes
      ? {
          label: 'Markets',
          type: RESULTS_TYPES.MARKETS,
          count: validMarketsCount,
          countFetchStatus: joinCountStatuses([
            zipcodeCountFetchStatus,
            reisSubmarketsCountFetchStatus,
          ]),
        }
      : null,
  ].filter(Boolean);

  return (
    <Tabs
      style={{
        visibility: featureFlags.CoreExploreTabs ? 'visible' : 'hidden',
      }}
      classes={{
        root: classes.tabsRoot,
        indicator: classes.tabIndicator,
        flexContainer: classes.tabsFlexContainer,
      }}
      value={resultsType}
      indicatorColor='primary'
    >
      {tabs.map((tab) => (
        <Tab
          disabled={tab.disabled}
          classes={{
            root: classes.root,
          }}
          key={tab.type}
          onClick={() => {
            window.analytics?.track(`${tab.label} Tab Clicked`);
            dispatch(setResultsType(tab.type));
          }}
          value={tab.type}
          label={`${tab.count >= 0 ? numberWithCommas(tab.count) : ''} ${
            tab.label
          }`}
        />
      ))}
    </Tabs>
  );
};

export default ViewerTabs;
