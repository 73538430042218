import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

import { Flex } from 'src/components/Flex';
import {
  TYPE_RECORDED,
  TYPE_PERSON,
  TYPE_COMPANY,
  OwnerTypes,
} from '../common';

import OwnerCardTitleAndEmployeeCount from './OwnerCardTitleAndEmployeeCount';
import OwnerCardCommonPropertyCount from './OwnerCardCommonPropertyCount';
import OwnerCardAddress from './OwnerCardAddress';
import OwnerCardContactDetails from './OwnerCardContactDetails';
import { LinkedAddressesQuery } from '../__generated__/linkedAddressesQuery';
import { LotAddress } from '../../../recoil';

const icons = {
  [TYPE_RECORDED]: 'icon-certificate',
  [TYPE_PERSON]: 'icon-User',
  [TYPE_COMPANY]: 'icon-type-newdev',
} as const;

const useStyles = makeStyles({
  root: {
    border: `1px solid ${grey[200]}`,
    borderRadius: 10,
    padding: 20,
    marginRight: 20,
    marginBottom: 20,
    minWidth: 300,
    width: 300,
    backgroundColor: grey[50],
  },
  icon: {
    fontSize: 14,
    marginRight: 20,
    color: grey[500],
  },
});

type OwnerCardProps = {
  type: OwnerTypes;
  name: string;
  owner_id?: string;
  address?: string;
  cherreParcelId?: string;
  taxAssessorId?: number;
  linkedAddresses?: LinkedAddressesQuery['tax_assessor_v2'];
  lotAddress?: LotAddress;
  pipl?: boolean;
  showEmployees?: boolean;
  commonPropertyCount?: number;
};

const OwnerCard: React.FC<OwnerCardProps> = ({
  type,
  name,
  owner_id,
  address,
  cherreParcelId,
  linkedAddresses = [],
  lotAddress,
  pipl,
  showEmployees = true,
  commonPropertyCount,
}) => {
  const classes = useStyles({ type });

  const matchedAddress = linkedAddresses.filter(
    (linkedAddress) => linkedAddress.cherre_parcel_id === cherreParcelId
  )[0];

  const normalizedAddress = address;

  const [isDrawerOpen, setDrawerOpen] = React.useState(false);

  return (
    <Flex flex='unset' className={`${classes.root} owner-card`}>
      <i className={`icon ${icons[type]} ${classes.icon}`} />
      <Flex justifyContent='space-between' direction='column'>
        <div>
          <OwnerCardTitleAndEmployeeCount
            type={type}
            name={name}
            owner_id={owner_id}
            showEmployees={showEmployees}
            employeeCount={0}
          />
          <OwnerCardCommonPropertyCount
            commonPropertyCount={commonPropertyCount}
          />
          <OwnerCardAddress
            matchedAddress={matchedAddress}
            normalizedAddress={normalizedAddress}
          />
        </div>
        <OwnerCardContactDetails
          type={type}
          name={name}
          pipl={pipl ?? false}
          owner_id={owner_id}
          setDrawerOpen={setDrawerOpen}
          isDrawerOpen={isDrawerOpen}
          lotAddress={lotAddress}
        />
      </Flex>
    </Flex>
  );
};

export default OwnerCard;
