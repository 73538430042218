import React from 'react';

import Map from 'src/products/core-prospect/components/ReactMap';
import { useSelector } from 'react-redux';

import * as T from './types';
import useStyles from './styles';
import { Typography } from '@material-ui/core';
import { useIsSuspended } from 'src/utils/suspense-companion/createSuspenseComponent';
import { panels } from '../../constants';

const Location: T.Location = ({ cherre_parcel_id, latitude, longitude }) => {
  const isSatelliteVisible = useSelector(
    (state) => state.coreProspect.map.isSatelliteVisible
  );

  const classes = useStyles();
  const isSuspended = useIsSuspended();

  return (
    <div id={panels[4].id} className={classes.container}>
      <Typography variant='h4' style={{ marginBottom: '25px' }}>
        Location
      </Typography>
      <div
        style={{ height: 350, position: 'relative', width: '100%' }}
        className='suspend'
      >
        {!isSuspended && (
          <Map
            latitude={latitude || 40.75202353325635}
            longitude={longitude || -73.93626282945806}
            zoom={16}
            satellite={isSatelliteVisible}
            isLayersSelectorVisible
            parcels={
              latitude && longitude && cherre_parcel_id
                ? [
                    {
                      selected: true,
                      id: cherre_parcel_id,
                    },
                  ]
                : []
            }
          />
        )}
      </div>
    </div>
  );
};

Location.displayName = 'Location';

export default Location;
