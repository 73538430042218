import { SourceProps } from 'react-map-gl';
import { SOURCES } from '../const';

export default function getUSAParcelsSource(): SourceProps {
  return {
    id: SOURCES.USA_MAP_PARCELS.name,
    type: SOURCES.USA_MAP_PARCELS.type,
    url: SOURCES.USA_MAP_PARCELS.url,
  };
}
