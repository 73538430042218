import React from 'react';
import { RootState, connect } from 'react-redux';
import { get } from 'lodash';

import { useManualQuery } from 'graphql-hooks';
import { UNMASKED_OWNERS } from './usaQuery';
import linkedAddressesQuery from './linkedAddressesQuery';
import RecordedOwners from './RecordedOwners';
import UnmaskedOwners from './UnmaskedOwners';
import { useIsSuspended } from 'src/utils/suspense-companion/createSuspenseComponent';
import useStyles from './styles';
import { Typography } from '@material-ui/core';
import { panels } from '../../constants';
import {
  LinkedAddressesQuery,
  LinkedAddressesQueryVariables,
} from './__generated__/linkedAddressesQuery';
import { OwnersQuery, OwnersQueryVariables } from './__generated__/usaQuery';
import { PropertyOwnersData } from './types';

type USAUnmaskedOwnersProps = {
  data: PropertyOwnersData['data'];
} & ReturnType<typeof mapStateToProps>;

const USAUnmaskedOwners: React.FC<USAUnmaskedOwnersProps> = (props) => {
  const { user } = props;
  const query = UNMASKED_OWNERS;
  const [fetchData, { data: queryData }] = useManualQuery<
    OwnersQuery | undefined,
    OwnersQueryVariables
  >(query, {
    variables: {
      tax_assessor_id: props.data.lot_data?.tax_assessor_id ?? 0,
    },
  });
  const tax_assessor = get(queryData, 'tax_assessor_v2', undefined);
  const first_tax_assessor =
    tax_assessor && tax_assessor[0] ? tax_assessor[0] : undefined;
  const owner = first_tax_assessor?.owner;
  const cherre_parcel_id = first_tax_assessor?.cherre_parcel_id;

  const cherre_parcel_ids = new Set<string>();

  if (owner) {
    owner.forEach(
      (owner) =>
        owner.cherre_parcel_id && cherre_parcel_ids.add(owner.cherre_parcel_id)
    );
  }

  const [fetchLinkedAddresses, { data: linkedAddressData }] = useManualQuery<
    LinkedAddressesQuery,
    LinkedAddressesQueryVariables
  >(linkedAddressesQuery, {
    variables: {
      cherre_parcel_ids: Array.from(cherre_parcel_ids),
    },
  });

  React.useEffect(() => {
    fetchData();
  }, [query]);

  React.useEffect(() => {
    const cIds = Array.from(cherre_parcel_ids);

    if (
      queryData &&
      cherre_parcel_id &&
      !cIds.includes(cherre_parcel_id) &&
      user.featureFlags?.mailingAddressLink
    ) {
      fetchLinkedAddresses();
    }
  }, [query, queryData]);

  const unmaskedOwners = queryData
    ? Object.values(
        queryData.tax_assessor_v2
          .reduce((memo, unmaskedOwners) => {
            if (unmaskedOwners.owner && unmaskedOwners.owner.length > 0) {
              return memo.concat(unmaskedOwners.owner);
            }

            return memo;
          }, [] as OwnersQuery['tax_assessor_v2'][number]['owner'])
          .reduce((memo, unmaskedOwner) => {
            if (unmaskedOwner.owner_id && !memo[unmaskedOwner.owner_id]) {
              memo[unmaskedOwner.owner_id] = unmaskedOwner;
            }

            return memo;
          }, {} as Record<string, OwnersQuery['tax_assessor_v2'][number]['owner'][number]>)
      )
    : [];

  let recordedOwners = props.data.owner || [];

  if (!Array.isArray(recordedOwners)) {
    recordedOwners = [recordedOwners];
  }

  const lotAddress = props.data.lot_address;

  return (
    <>
      <RecordedOwners owners={recordedOwners} address={lotAddress} />
      <UnmaskedOwners
        address={lotAddress}
        owners={unmaskedOwners}
        linkedAddressData={linkedAddressData}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    user: state.user.profile.value,
  };
};

const PropertyOwners = connect(mapStateToProps)(USAUnmaskedOwners);

const PropertyOwnersWrapper: React.FC<USAUnmaskedOwnersProps> = (props) => {
  const isSuspended = useIsSuspended();
  const classes = useStyles();
  if (isSuspended) {
    return null;
  }
  return (
    <div id={panels[7].id} className={classes.container}>
      <Typography variant='h4' style={{ marginBottom: '25px' }}>
        Property Owners
      </Typography>
      <PropertyOwners {...props} />
    </div>
  );
};

export default PropertyOwnersWrapper;
