import { makeStyles } from '@material-ui/core';
import React from 'react';
import { PropertyAddressSummary } from 'src/products/core-prospect/components/PropertyAddressSummary';
import { usaDataFormatter } from 'src/products/core-prospect/components/PropertyAddressSummary/dataFormatter/usaDataFormatter';
import { dealStageColorMap } from 'src/products/core-prospect/constants';
import { useUpdateUrlSearchParams } from 'src/products/core-prospect/hooks/useUpdateUrlSearchParams';
import { getLotPagePath } from 'src/products/core-prospect/search-pages/components/utils';
import { DealStageType } from 'src/services/Dealcloud/types';
import { DealPortfolioAssestsQuery } from '../../__generated__/query';

type AssetsProps = {
  assets: Array<{
    taxAssessorId: number;
  }>;
  onRemove: (taxAssessorId: number) => void;
  taxAssessors: DealPortfolioAssestsQuery['tax_assessor_v2'];
  stage: DealStageType;
};

const useStyles = makeStyles({
  addressWrap: {
    position: 'relative',
  },
});

export const Assets: React.VFC<AssetsProps> = ({
  assets,
  taxAssessors,
  onRemove,
  stage,
}) => {
  const classes = useStyles();
  const updateUrlSearchParams = useUpdateUrlSearchParams();
  return (
    <>
      {assets?.map((asset, index) => {
        const taxAssessor = taxAssessors?.find(
          (taxAssessor) => taxAssessor.tax_assessor_id === asset.taxAssessorId
        );

        const data = taxAssessor ? usaDataFormatter(taxAssessor) : null;

        if (!taxAssessor) {
          return null;
        }

        if (!data) {
          return null;
        }

        return (
          <div
            className={`${classes.addressWrap} js-portfolio-asset-list-item-${index}`}
            key={index}
          >
            <PropertyAddressSummary
              key={index}
              property={data}
              height='75px'
              padding={10}
              addressHref={getLotPagePath(
                data.parcelId || '',
                data.taxAssessorId || 0
              )}
              addressHrefExternal
              onClickOpenPopupPin={() =>
                updateUrlSearchParams({
                  cherre_parcel_id: taxAssessor.cherre_parcel_id,
                  latitude: taxAssessor.latitude,
                  longitude: taxAssessor.longitude,
                  stickToThePin: 1,
                })
              }
              onClickRemovePortfolio={({ property }) =>
                onRemove(property.taxAssessorId || 0)
              }
              colorPin={dealStageColorMap[stage]}
            />
          </div>
        );
      })}
    </>
  );
};
