import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { shareWithAutocompleteOptionsSelectorFamily } from '../recoil';
import { useLoadable } from './useLoadable';

export const useShareWithAutocompleteOptions = () => {
  const [searchText, setSearchTextNormal] = useState('');
  const [searchTextDelayed, setSearchTextDelayed] = useState('');

  const setSearchTextDebounced = useCallback(
    debounce((value: string) => {
      setSearchTextDelayed(value);
    }, 200),
    [setSearchTextDelayed]
  );

  useEffect(() => {
    return () => {
      setSearchTextDebounced.cancel();
    };
  }, [setSearchTextDebounced]);

  const setSearchText = useCallback(
    (value: string) => {
      setSearchTextNormal(value);
      setSearchTextDebounced(value);
    },
    [setSearchTextNormal, setSearchTextDebounced]
  );

  const [value, state] = useLoadable(
    [],
    shareWithAutocompleteOptionsSelectorFamily({
      text: searchTextDelayed,
    })
  );

  const isLoading = state === 'loading';

  const currentUserEmail = useSelector(
    (state) => state.user.profile.value.email
  );

  const options = useMemo(
    () => value.filter((v) => v.subtitle !== currentUserEmail),
    [value]
  );

  return {
    searchText,
    setSearchText,
    isLoading,
    options,
  };
};
