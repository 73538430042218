import React from 'react';
import { useSelector } from 'react-redux';

import { MARKETS_TABS } from '../../../constants';
import { ReisSubmarketsTab, TabSelector, ZipcodesTab } from './components';

const MarketsTableView = () => {
  const marketTab = useSelector((state) => state.coreProspect.view.marketTab);
  return (
    <>
      <TabSelector />
      {marketTab === MARKETS_TABS.ZIPCODES.value && <ZipcodesTab />}
      {marketTab === MARKETS_TABS.SUBMARKETS.value && <ReisSubmarketsTab />}
    </>
  );
};

export default MarketsTableView;
