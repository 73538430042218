import React from 'react';
import Popper from '@material-ui/core/Popper';

const PopperComponent = ({
  isCompact,
  visible = true,
  onAppsPage = false,
  width,
  ...otherProps
}) => {
  if (isCompact) {
    return (
      <div
        children={otherProps.children}
        style={{
          visibility: visible ? 'visible' : 'hidden',
          position: 'absolute',
          left: 0,
          right: 0,
          top: 133,
          bottom: 0,
          zIndex: 2,
          background: '#fff',
          overflow: 'auto',
        }}
      />
    );
  }

  return (
    <Popper
      {...otherProps}
      style={{
        visibility: visible ? 'visible' : 'hidden',
        width: onAppsPage ? width : 'auto',
        minWidth: onAppsPage ? width : 315,
      }}
      placement='bottom-start'
    />
  );
};

export default PopperComponent;
