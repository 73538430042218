import React from 'react';
import Document from './Document';

const NA = '-';

const LatestMortgage = ({ tax_assessor }) => {
  const [transaction] = tax_assessor.recorder_v2_summary_v2__tax_assessor_id;

  if (!transaction) {
    return null;
  }

  const amount = transaction.cherre_latest_mortgage_amount;
  const date = transaction.cherre_latest_mortgage_date;
  const borrower = transaction.cherre_latest_mortgage_borrower
    ?.split('🞈')
    .join(', ');
  const lender = transaction.cherre_latest_mortgage_lender
    ?.split('🞈')
    .join(', ');

  if (!(amount || date || borrower || lender)) {
    return null;
  }

  return (
    <>
      <Document
        onClickHref={() =>
          window.analytics?.track('Preview Card - View All on Mortgage', {
            tax_assessor_id: tax_assessor.tax_assessor_id,
          })
        }
        viewAllHref={`/properties/${tax_assessor.cherre_parcel_id}/${tax_assessor.tax_assessor_id}`}
        title='Latest Mortgage'
        amount={amount || NA}
        date={date || NA}
        persone1={{ name: lender || NA, label: 'Lender' }}
        persone2={{ name: borrower || NA, label: 'Borrower' }}
      />
    </>
  );
};

export default LatestMortgage;
