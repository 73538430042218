import { useParams } from 'react-router';
import { useRecoilValue } from 'recoil';
import { LotPageParams } from '../../../../types';
import { leases } from './recoil';
import * as T from './types';

const hook: T.UseLeasesTable = () => {
  const { id } = useParams<LotPageParams>();
  const leasesResult = useRecoilValue(leases({ tax_assessor_id: Number(id) }));
  return { leases: leasesResult };
};

export default hook;
