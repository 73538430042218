export const filtersConfig = {
  filter_templates: [
    {
      id: 1,
      name: 'USA Lot Search By Parcel 2',
      organization_id: null,
      entity_name: 'usa_map_parcel',
    },
  ],
  filter_group_permissions: [
    {
      id: 0,
      filter_template_id: 1,
      filter_group_id: 0,
      order_num: 1,
    },
    {
      id: 1,
      filter_template_id: 1,
      filter_group_id: 6,
      order_num: 7,
    },
    {
      id: 9,
      filter_template_id: 1,
      filter_group_id: 9,
      order_num: 3,
    },
    {
      id: 2,
      filter_template_id: 1,
      filter_group_id: 5,
      order_num: 6,
    },
    {
      id: 3,
      filter_template_id: 1,
      filter_group_id: 4,
      order_num: 5,
    },
    {
      id: 4,
      filter_template_id: 1,
      filter_group_id: 3,
      order_num: 4,
    },
    {
      id: 5,
      filter_template_id: 1,
      filter_group_id: 2,
      order_num: 3.1,
    },
    {
      id: 6,
      filter_template_id: 1,
      filter_group_id: 1,
      order_num: 2,
    },
    {
      id: 7,
      filter_template_id: 1,
      filter_group_id: 7,
      order_num: 8,
    },
    {
      id: 8,
      filter_template_id: 1,
      filter_group_id: 8,
      order_num: 9,
    },
    {
      id: 10,
      filter_template_id: 1,
      filter_group_id: 10,
      order_num: 11,
    },
    {
      id: 11,
      filter_template_id: 1,
      filter_group_id: 11,
      order_num: 12,
    },
    {
      id: 12,
      filter_template_id: 1,
      filter_group_id: 12,
      order_num: 0,
    },
  ],
  filter_groups: [
    {
      id: 1,
      label: 'Property Type',
    },
    {
      id: 2,
      label: 'Lot Size',
    },
    {
      id: 3,
      label: 'Owner',
      hide: (state) =>
        !state.user?.profile.value.featureFlags.CoreExplore_owners_filters,
    },
    {
      id: 4,
      label: 'Transactions',
    },
    {
      id: 5,
      label: 'Mortgages',
    },
    {
      id: 6,
      label: 'Taxes',
    },
    {
      id: 7,
      label: 'Transactions',
      partner: 'by RCA',
      hide: (state) => !state.user?.profileForm?.value?.rcaEnabled,
    },
    {
      id: 8,
      label: 'Loans',
      partner: 'by Trepp',
      hide: (state) => !state.user?.profileForm?.value?.treppEnabled,
    },
    {
      id: 9,
      label: 'Building Details',
    },
    {
      id: 10,
      label: 'Census Demographics',
      description: 'Filters apply to ZIP code areas.',
      hide: (state) =>
        !state.user?.profile?.value?.featureFlags.censusFiltersEnabled,
    },
    {
      id: 11,
      label: 'Market Fundamentals',
      partner: 'by Reis',
      hide: (state) =>
        !state.user?.profile?.value?.featureFlags
          .marketFundamentalsFiltersEnabled ||
        !state.user?.profileForm?.value?.reisEnabled,
    },
    {
      id: 12,
      label: 'Deal Info',
      hide: (state) =>
        !state.coreProspect.query.areDealsFiltersEnabled ||
        !state.user?.profile?.value?.organizationProps?.deals_enabled,
    },
  ],
  filter_sets: [
    {
      id: 0,
      label: 'Submarket',
      filter_group_id: 0,
    },
    {
      id: 1,
      label: 'Property Type',
      filter_group_id: 1,
    },
    {
      id: 2,
      label: 'Lot Size',
      filter_group_id: 2,
    },
    {
      id: 3,
      label: 'Owner',
      filter_group_id: 3,
    },
    {
      id: 4,
      label: 'Transactions',
      filter_group_id: 4,
    },
    {
      id: 5,
      label: 'Mortgages',
      filter_group_id: 5,
    },
    {
      id: 6,
      label: 'Taxes',
      filter_group_id: 6,
    },
    {
      id: 7,
      label: 'Transactions',
      filter_group_id: 7,
    },
    {
      id: 8,
      label: 'Loans by Trepp',
      filter_group_id: 8,
    },
    {
      id: 9,
      label: 'Building Details',
      filter_group_id: 9,
    },
    {
      id: 10,
      label: 'Census Demographics',
      filter_group_id: 10,
    },
    {
      id: 11,
      label: 'Market Fundamentals',
      filter_group_id: 11,
    },
    {
      id: 12,
      label: 'Deal Info',
      filter_group_id: 12,
    },
  ],
  filters: [
    {
      id: 38,
      entity: 'deal',
      filter_set_id: 12,
      filter_type: 'DealFilter',
      field_name: 'deal',
    },
    {
      id: 4,
      entity: 'tax_assessor__parcel_id',
      filter_set_id: 2,
      label: 'Lot size (sqft)',
      filter_type: 'RangeInputFilter',
      field_name: 'lot_size_sq_ft',
      number_format_props: {
        suffix: ' sqft',
      },
    },
    {
      id: 5,
      entity: 'tax_assessor__parcel_id',
      filter_set_id: 2,
      label: 'Lot size (acre)',
      filter_type: 'RangeInputFilter',
      field_name: 'lot_size_acre',
      number_format_props: {},
    },
    {
      id: 3,
      entity: 'tax_assessor__parcel_id',
      filter_set_id: 2,
      label: 'Gross area (sqft)',
      filter_type: 'RangeInputFilter',
      field_name: 'gross_sq_ft',
      number_format_props: { suffix: ' sqft' },
    },
    {
      id: 6,
      entity: 'tax_assessor__parcel_id',
      filter_set_id: 9,
      label: 'Building area (sqft)',
      filter_type: 'RangeInputFilter',
      field_name: 'building_sq_ft',
      number_format_props: { suffix: ' sqft' },
    },
    {
      id: 7.1,
      entity: 'usa_owner_unmask__tax_assessor_id',
      filter_set_id: 3,
      label: 'Unmasked owner name',
      filter_type: 'TextInputFilter',
      field_name: 'owner_name',
    },
    {
      id: 7.2,
      entity: 'usa_owner_unmask__tax_assessor_id',
      filter_set_id: 3,
      label: 'Unmasked owner type',
      filter_type: 'UnmaskedOwnerTypesSelection',
      field_name: 'owner_type',
    },
    {
      id: 7,
      entity: 'tax_assessor_owner',
      filter_set_id: 3,
      label: 'Owner name',
      filter_type: 'TextInputFilter',
      field_name: 'owner_name',
    },
    {
      id: 8,
      entity: 'tax_assessor_owner',
      filter_set_id: 3,
      label: 'Owner type',
      filter_type: 'RecordedOwnerTypesSelection',
      field_name: 'owner_type',
    },
    {
      id: 9,
      entity: 'recorder_summary',
      filter_set_id: 4,
      label: 'Last sale amount',
      filter_type: 'RangeInputFilter',
      field_name: 'cherre_latest_deed_amount',
      number_format_props: {
        prefix: '$',
      },
    },
    {
      id: 10,
      entity: 'recorder_summary',
      filter_set_id: 4,
      label: 'Last sale period',
      filter_type: 'RangeDateFilter',
      field_name: 'cherre_latest_deed_date',
    },
    {
      id: 11,
      entity: 'recorder_summary',
      filter_set_id: 5,
      label: 'Last mortgage amount',
      filter_type: 'RangeInputFilter',
      field_name: 'cherre_latest_mortgage_amount',
      number_format_props: { prefix: '$' },
    },
    {
      id: 12,
      entity: 'recorder_summary',
      filter_set_id: 5,
      label: 'Last mortgage period',
      filter_type: 'RangeDateFilter',
      field_name: 'cherre_latest_mortgage_date',
    },
    {
      id: 13,
      entity: 'recorder_summary',
      filter_set_id: 5,
      label: 'Last mortgage lender',
      filter_type: 'TextInputFilter',
      field_name: 'cherre_latest_mortgage_lender',
    },
    {
      id: 14,
      entity: 'recorder_summary',
      filter_set_id: 5,
      label: 'Last mortgage borrower',
      filter_type: 'TextInputFilter',
      field_name: 'cherre_latest_mortgage_borrower',
    },
    {
      id: 15,
      entity: 'tax_assessor__parcel_id',
      filter_set_id: 6,
      label: 'Tax amount',
      filter_type: 'RangeInputFilter',
      field_name: 'tax_bill_amount',
      number_format_props: {
        prefix: '$',
      },
    },
    {
      id: 16,
      entity: 'tax_assessor__parcel_id',
      filter_set_id: 6,
      label: 'Assessed property value',
      filter_type: 'RangeInputFilter',
      field_name: 'assessed_value_total',
      number_format_props: {
        prefix: '$',
      },
    },
    {
      id: 17,
      entity: 'tax_assessor__parcel_id',
      filter_set_id: 6,
      label: 'Market property value',
      filter_type: 'RangeInputFilter',
      field_name: 'market_value_total',
      number_format_props: {
        prefix: '$',
      },
    },
    {
      id: 2,
      entity: 'tax_assessor__parcel_id',
      filter_set_id: 1,
      label: 'Property use',
      filter_type: 'HierarchicalFilter',
      field_name: 'property_use_code_mapped',
    },
    {
      id: 18,
      entity: 'rca_sales_demo',
      filter_set_id: 7,
      label: 'Timeframe',
      filter_type: 'RangeDateFilter',
      field_name: 'status_date',
    },
    {
      id: 19,
      entity: 'rca_sales_demo',
      filter_set_id: 7,
      label: 'Price',
      filter_type: 'RangeInputFilter',
      field_name: 'price',
      number_format_props: {
        prefix: '$',
      },
    },
    {
      id: 20,
      entity: 'rca_sales_demo',
      filter_set_id: 7,
      label: 'Price per square foot',
      filter_type: 'RangeInputFilter',
      field_name: 'price_per_sq_ft_unit',
      number_format_props: {
        prefix: '$',
      },
    },
    {
      id: 21,
      entity: 'rca_sales_demo',
      filter_set_id: 7,
      label: 'Square feet',
      filter_type: 'RangeInputFilter',
      field_name: 'sq_ft',
      number_format_props: {
        suffix: ' sqft',
      },
    },
    {
      id: 22,
      entity: 'rca_sales_demo',
      filter_set_id: 7,
      label: 'Unit count',
      filter_type: 'RangeInputFilter',
      field_name: 'unit_count',
      number_format_props: {},
    },
    {
      id: 23,
      entity: 'rca_sales_demo',
      filter_set_id: 7,
      label: 'Closed cap rate',
      filter_type: 'RangeInputFilter',
      field_name: 'closed_cap_rate',
    },
    {
      id: 24,
      entity: 'rca_sales_demo',
      filter_set_id: 7,
      label: 'Buyer',
      filter_type: 'TextInputFilter',
      field_name: 'buyer_name_1',
    },
    {
      id: 25,
      entity: 'rca_sales_demo',
      filter_set_id: 7,
      label: 'Seller',
      filter_type: 'TextInputFilter',
      field_name: 'seller_name_1',
    },

    {
      id: 26,
      entity: 'trepp_loan_demo',
      filter_set_id: 8,
      label: 'Loan Amount',
      filter_type: 'RangeInputFilter',
      field_name: 'cutoff_balance',
    },
    {
      id: 27,
      entity: 'trepp_loan_demo',
      filter_set_id: 8,
      label: 'Allocated Balance',
      filter_type: 'RangeInputFilter',
      field_name: 'allocated_balance_at_securitization',
    },
    {
      id: 28,
      entity: 'trepp_loan_demo',
      filter_set_id: 8,
      label: 'Origination Date',
      filter_type: 'RangeDateFilter',
      field_name: 'origination_date',
    },
    {
      id: 29,
      entity: 'trepp_loan_demo',
      filter_set_id: 8,
      label: 'Maturity Date',
      filter_type: 'RangeDateFilter',
      field_name: 'maturity_date',
    },
    {
      id: 30,
      entity: 'trepp_loan_demo',
      filter_set_id: 8,
      label: 'Securitization LTV',
      filter_type: 'RangeInputFilter',
      field_name: 'securitization_ltv',
    },
    {
      id: 31,
      entity: 'trepp_loan_demo',
      filter_set_id: 8,
      label: 'Rate at Securitization',
      filter_type: 'RangeInputFilter',
      field_name: 'note_rate_at_securitization',
    },
    {
      id: 32,
      entity: 'trepp_loan_demo',
      filter_set_id: 8,
      label: 'Remaining Term (months)',
      filter_type: 'RangeInputFilter',
      field_name: 'remaining_term',
    },
    {
      id: 33,
      entity: 'trepp_loan_demo',
      filter_set_id: 8,
      label: 'Most Recent DSCR (NOI)',
      filter_type: 'RangeInputFilter',
      field_name: 'most_recent_dscr_noi',
    },
    {
      id: 34,
      entity: 'trepp_loan_demo',
      filter_set_id: 8,
      label: 'Most Recent Occupancy',
      filter_type: 'RangeInputFilter',
      field_name: 'most_recent_physical_occupancy',
    },
    {
      id: 35,
      entity: 'trepp_loan_demo',
      filter_set_id: 8,
      label: 'Expiration Date of Largest Tenant',
      filter_type: 'RangeDateFilter',
      field_name: 'expiration_date_of_largest_tenant_lease',
    },
    {
      id: 36,
      entity: 'trepp_loan_demo',
      filter_set_id: 8,
      label: 'Is Newly on Watchlist',
      filter_type: 'BooleanFilter',
      field_name: 'is_newly_on_watchlist',
    },
    {
      id: 37,
      entity: 'trepp_loan_demo',
      filter_set_id: 8,
      label: 'Is Interest Only',
      filter_type: 'BooleanFilter',
      field_name: 'is_interest_only',
    },
    {
      id: 26,
      entity: 'tax_assessor__parcel_id',
      filter_set_id: 9,
      label: 'Number of Units',
      filter_type: 'RangeInputFilter',
      field_name: 'units_count',
      number_format_props: {},
    },
    {
      id: 27,
      entity: 'tax_assessor__parcel_id',
      filter_set_id: 9,
      label: 'Year Built',
      filter_type: 'RangeInputFilter',
      field_name: 'year_built',
      number_format_props: { format: '####' },
    },
    {
      id: 28,
      entity: 'census',
      filter_set_id: 10,
      label: 'Crime Risk Index (100 = National AVG)',
      filter_type: 'RangeInputFilter',
      field_name: 'crime_total_risk',
      number_format_props: {
        thousandSeparator: '',
      },
    },
    {
      id: 29,
      entity: 'census',
      filter_set_id: 10,
      label: 'Median Household Income',
      filter_type: 'RangeInputFilter',
      field_name: 'median_household_income',
      number_format_props: {
        prefix: '$',
      },
    },
    {
      id: 30,
      entity: 'census',
      filter_set_id: 10,
      label: 'Median Population Age',
      filter_type: 'RangeInputFilter',
      field_name: 'population_median_age',
      number_format_props: {
        thousandSeparator: '',
      },
    },
    {
      id: 31,
      entity: 'reis_absolutes',
      filter_set_id: 11,
      label: 'Sector',
      filter_type: 'SectorsSelection',
      field_name: 'sector',
    },
    {
      id: 32,
      entity: 'reis_absolutes',
      filter_set_id: 11,
      label: 'Current Net Absorption',
      filter_type: 'RangeInputFilter',
      field_name: 'absorption',
      filter_type_props: {
        allow_negative_values: true,
      },
    },
    {
      id: 33,
      entity: 'reis_absolutes',
      filter_set_id: 11,
      label: 'Current Net Completions',
      filter_type: 'RangeInputFilter',
      field_name: 'completions',
    },
    {
      id: 34,
      entity: 'reis_absolutes',
      filter_set_id: 11,
      label: 'Current Vacancy',
      filter_type: 'RangeInputFilter',
      field_name: 'vacancy',
      filter_type_props: { mask: 'percent' },
      number_format_props: {
        suffix: '%',
      },
    },
    {
      id: 35,
      entity: 'reis_absolutes',
      filter_set_id: 11,
      label: 'Current Asking Rent',
      filter_type: 'RangeInputFilter',
      field_name: 'asking_rent',
      number_format_props: {
        suffix: '%',
      },
    },
  ],
};
