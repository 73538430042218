import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import TimelineItem from './TimelineItem';
import { MAX_WIDTH } from '../common';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    maxWidth: MAX_WIDTH,
    marginBottom: 20,
    '&:last-child': {
      marginBottom: 0,
    },
  },
});

const TimelineYear = ({ year, items, isNyc }) => {
  const classes = useStyles();

  return (
    <div className={classes.root} id='timeline-group'>
      <div style={{ width: 60 }}>
        <Typography variant='body1'>{year}</Typography>
      </div>
      <div style={{ width: '100%' }}>
        {items.map((item, i) => {
          return <TimelineItem key={i} item={item} isNyc={isNyc} />;
        })}
      </div>
    </div>
  );
};

export default TimelineYear;
