export const LOT_TRANSACTIONS = /* GraphQL */ `
  query LotTransactions(
    $tax_assessor_id: numeric!
    $orderBy: [recorder_v2_order_by!]
  ) {
    recorder_v2(
      where: {
        _and: [
          {
            tax_assessor_v2__tax_assessor_id: {
              tax_assessor_id: { _eq: $tax_assessor_id }
            }
          }
          {
            _or: [
              { document_amount: { _is_null: false } }
              { cherre_recorded_instrument_date: { _is_null: false } }
            ]
          }
        ]
      }
      order_by: $orderBy
    ) {
      document_amount
      cherre_recorded_instrument_date
      document_type_code
      recorder_grantee_v2__recorder_id(limit: 3) {
        grantee_name
      }
      recorder_grantor_v2__recorder_id(limit: 3) {
        grantor_name
      }
    }
  }
`;

export const NYC_TRANSACTIONS = /* GraphQL */ `
  query NycTransactions($bbl: numeric!, $orderBy: [acris_master_order_by!]) {
    acris_master(
      where: {
        acris_legal__document_id: { bbl: { _eq: $bbl } }
        recorded_date: { _is_null: false }
        class_code: { _eq: "DEEDS AND OTHER CONVEYANCES" }
      }
      order_by: $orderBy
    ) {
      document_amount
      recorded_date
      document_type
      grantors: acris_party__document_id(
        where: { party_type_code: { _eq: 1 } }
      ) {
        name
      }
      grantees: acris_party__document_id(
        where: { party_type_code: { _eq: 2 } }
      ) {
        name
      }
    }
  }
`;

export const LOT_MORTGAGES = /* GraphQL */ `
  query LotMortgages(
    $tax_assessor_id: numeric!
    $cherre_parcel_id: String!
    $orderBy: [recorder_mortgage_v2_order_by!]
  ) {
    recorder_mortgage_v2(
      where: {
        _and: [
          {
            recorder_v2__recorder_id: {
              tax_assessor_v2__tax_assessor_id: {
                tax_assessor_id: { _eq: $tax_assessor_id }
                cherre_parcel_id: { _eq: $cherre_parcel_id }
              }
            }
          }
        ]
      }
      order_by: $orderBy
    ) {
      amount
      document_recorded_date
      term
      term_type_code
      lender_name
      interest_rate
      interest_rate_type_code
      recorder_v2__recorder_id {
        recorder_grantee_v2__recorder_id {
          grantee_name
        }
      }
    }
  }
`;

export const NYC_MORTGAGES = /* GraphQL */ `
  query LotMortgages($bbl: numeric!, $orderBy: [acris_master_order_by!]) {
    acris_master(
      where: {
        acris_legal__document_id: { bbl: { _eq: $bbl } }
        document_type: { _eq: "MORTGAGE" }
      }
      order_by: $orderBy
    ) {
      document_amount
      recorded_date
      document_type
      grantees: acris_party(where: { party_type_code: { _eq: 1 } }) {
        name
      }
      grantors: acris_party(where: { party_type_code: { _eq: 2 } }) {
        name
      }
    }
  }
`;

export const TREPP_LOANS = /* GraphQL */ `
  query TreppLoans($cherre_parcel_id: String!) {
    tax_assessor_v2(where: { cherre_parcel_id: { _eq: $cherre_parcel_id } }) {
      cherre_address__property_address {
        trepp__address {
          address
          property_name
          property_status
          property_status_change_date
          largest_tenant
          largest_tenant_percent
          largest_tenant_square_footage
          second_largest_tenant
          second_largest_tenant_percent
          second_largest_tenant_square_footage
          third_largest_tenant
          third_largest_tenant_percent
          third_largest_tenant_square_footage
          trepp_loan__trepp_master_loan_id {
            maturity_date
            actual_balance
            address
            city
            state
            zip_code
            distribution_date
            trepp_deal_name
            arm_margin
            bankruptcy_date
            borrower
            crefc_property_type
            currd_balance
            currf_balance
            original_term_of_loan
            original_balance
            current_note_rate
            derived_ltv
            loan_id
            preceding_fiscal_year_dscr_noi
            preceding_fiscal_year_noi
            securitzation_dscr_noi
            securitization_noi
            derived_appraised_value
            securitization_appraised_value
            securitization_occupancy
            securitization_ltv
            transaction_id
            cutoff_balance
            remaining_term
            most_recent_value
            note_rate_at_securitization
            current_beginning_scheduled_balance
            preceding_fiscal_year_physical_occupancy
          }
          trepp_loan_2__trepp_master_loan_id {
            special_servicer
            master_servicer
            is_newly_on_watchlist
          }
        }
      }
    }
  }
`;
