import { useParams } from 'react-router';
import { useRecoilValue } from 'recoil';
import { LotPageParams } from '../../types';
import { lotSizeData } from './recoil';
import * as T from './types';

const hook: T.UseLotSize = () => {
  const { id } = useParams<LotPageParams>();
  return useRecoilValue(lotSizeData({ tax_assessor_id: Number(id) }));
};

export default hook;
