import React from 'react';

const BuildingLeaseCompIcon = (props) => {
  return (
    <svg
      {...props}
      width='22'
      height='27'
      viewBox='0 0 22 27'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11 1C5.4775 1 1 5.4775 1 11C1 16.5225 11 26 11 26C11 26 21 16.5225 21 11C21 5.4775 16.5225 1 11 1Z'
        fill='#039BE5'
        stroke='white'
        strokeWidth='0.6'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.9167 4.75H8.08333C7.62333 4.75 7.25 5.15 7.25 5.64286V6.53571H12.25V8.32143H7.25V10.1071H10.5833V11.8929H7.25V16.3571C7.25 16.8509 7.62333 17.25 8.08333 17.25H9.75V14.5714H12.25V17.25H13.9167C14.3767 17.25 14.75 16.8509 14.75 16.3571V5.64286C14.75 5.15 14.3767 4.75 13.9167 4.75Z'
        fill='white'
      />
    </svg>
  );
};

BuildingLeaseCompIcon.displayName = 'ExternalLeaseCompIcon';

export default BuildingLeaseCompIcon;
