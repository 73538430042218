import { SourceProps } from 'react-map-gl';
import { SOURCES } from '../const';

export default function getReisBoundariesSource(): SourceProps {
  return {
    id: SOURCES.REIS_BOUNDARIES,
    type: 'vector',
    url: 'mapbox://sbcherre.reis-boundaries-v_2502061840627',
  };
}
