export const namespace = 'core-prospect-view';
const ns = (name) => `${namespace}/${name}`;

export const TOGGLE_CORE_PROSPECT_VIEW = ns('TOGGLE_CORE_PROSPECT_VIEW');
export const SET_RESULTS_TYPE = ns('SET_RESULTS_TYPE');
export const SET_MARKET_TAB = ns('SET_MARKET_TAB');

export const OWNER_CLICKED = ns('OWNER_CLICKED');
export const OPEN_SAVE_SEARCH_DIALOG = ns('OPEN_SAVE_SEARCH_DIALOG');
export const CLOSE_SAVE_SEARCH_DIALOG = ns('CLOSE_SAVE_SEARCH_DIALOG');

export const COLLAPSE_SIDEBAR = ns('COLLAPSE_SIDEBAR');
export const EXPAND_SIDEBAR = ns('EXPAND_SIDEBAR');

export const HIDE_CARDS = ns('HIDE_CARDS');
export const DISPLAY_CARDS = ns('DISPLAY_CARDS');
