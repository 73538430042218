import { keyBy } from 'lodash';
import { useParams } from 'react-router';
import { selectorFamily, useRecoilValue } from 'recoil';
import { LotData, lotData } from '../../../../recoil/lotData';
import { Tax_AssessorQueryVariables } from '../../../../recoil/__generated__/taxAssessor';
import { LotPageParams } from '../../../../types';
import { lookupQueryBatch } from '../BuildingDetails/recoil/queries';
import { LookupQueryBatchQueryVariables } from '../BuildingDetails/recoil/__generated__/queries';

const lookups = {
  parking_garage: 'parking_garage_code',
  hvacc_cooling: 'hvacc_cooling_code',
  hvacc_heating: 'hvacc_heating_code',
  hvacc_heating_fuel: 'hvacc_heating_fuel_code',
  porch: 'porch_code',
  driveway_material: 'driveway_material_code',
  pool: 'pool_code',
  fence: 'fence_code',
} as const;

type LookupsFormattedType = {
  [K in keyof typeof lookups]?: string;
};

export type PropertyDetailsType = LookupsFormattedType & LotData;

const propertyDetails = selectorFamily({
  key: 'LOT-PAGE/PROPERTY-DETAILS/PROPERTY-DETAILS',
  get:
    (params: Tax_AssessorQueryVariables) =>
    ({ get }) => {
      const lotDataResult = get(lotData(params));

      const lookupCodeEntries = Object.entries(lookups);

      const variables: LookupQueryBatchQueryVariables = {
        where: {
          _or: lookupCodeEntries
            .filter(([, fn]) => fn && lotDataResult?.[fn])
            .map(([, fn]) => ({
              object_name: { _eq: 'tax_assessor_v2' },
              field_name: { _eq: fn },
              lookup_code: { _eq: String(lotDataResult?.[fn]) },
            })),
        },
      };

      const lookupValues = get(lookupQueryBatch(variables));
      const lookupFields = keyBy(
        lookupValues.lookups_by_table_name,
        'field_name'
      );

      const lookupValueEntries = lookupCodeEntries.map(([k, fn]) => [
        k,
        lookupFields[fn]?.lookup_value,
      ]);

      const lookupsFormatted = Object.fromEntries(
        lookupValueEntries
      ) as LookupsFormattedType;

      return {
        ...lotDataResult,
        ...lookupsFormatted,
      } as const;
    },
});

const hook = () => {
  const { id } = useParams<LotPageParams>();
  const lot_data = useRecoilValue(
    propertyDetails({ tax_assessor_id: Number(id) })
  );
  return { data: { lot_data } };
};

export default hook;
