import { Maybe } from 'graphql/jsutils/Maybe';
import numberWithCommas from 'src/utils/numberWithCommas';

const dot = '\u00B7';

const OFFICE_CODES = [6, 24, 25, 26];
const INDUSTRIAL_CODES = [3, 14, 15, 16];
const RETAIL_CODES = [9, 45, 46, 47];
const OTHER_CODES = [7, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38];
const HOTEL_CODES = [2, 12, 13];
const MULTY_FAMILY_CODES = [5, 22, 23];
const LAND_CODES = [4, 17, 18, 19, 20, 21];
const RESIDENTIAL_CODES = [39, 43];
const AGRICULTURE_CODES = [1, 10, 11];

type Formatter = {
  propertyUseCodeMapped: number[];
  format: (data: {
    type: Maybe<string>;
    unitsCount: Maybe<number>;
    yearBuilt: Maybe<number>;
    zoneCode: Maybe<string>;
    buildingSize: Maybe<number>;
    lotSizeAcre: Maybe<number>;
  }) => string;
};

const formatters: Formatter[] = [
  {
    // Example: Affordable Housing - 450 Units • Built 1990 • Zoned DI
    propertyUseCodeMapped: [...MULTY_FAMILY_CODES, ...HOTEL_CODES],
    format: ({ type, unitsCount, yearBuilt, zoneCode }) => {
      let subtitle = type || '';

      if (unitsCount) {
        subtitle += ` - ${unitsCount} Unit${unitsCount > 1 ? 's' : ''}`;
      }

      if (yearBuilt) {
        subtitle += ` ${dot} Built ${yearBuilt}`;
      }

      if (zoneCode) {
        subtitle += ` ${dot} Zoned ${zoneCode}`;
      }

      return subtitle;
    },
  },
  {
    // Example: Office - 440,500 SF • Built 1990 • Zoned DI
    propertyUseCodeMapped: [
      ...OFFICE_CODES,
      ...INDUSTRIAL_CODES,
      ...RETAIL_CODES,
      ...OTHER_CODES,
    ],
    format: ({ type, yearBuilt, zoneCode, buildingSize }) => {
      let subtitle = type || '';

      if (buildingSize) {
        subtitle += ` - ${numberWithCommas(buildingSize)} SF`;
      }

      if (yearBuilt) {
        subtitle += ` ${dot} Built ${yearBuilt}`;
      }

      if (zoneCode) {
        subtitle += ` ${dot} Zoned ${zoneCode}`;
      }

      return subtitle;
    },
  },
  {
    // Example: Commercial Land - 33.33 AC • Zoned RS-I
    propertyUseCodeMapped: [...AGRICULTURE_CODES, ...LAND_CODES],
    format: ({ type, zoneCode, lotSizeAcre }) => {
      let subtitle = '';

      if (type === 'Vacant') {
        subtitle += 'Land';
      } else {
        subtitle += `${(type || '').replace('Vacant - ', '')} Land`;
      }

      if (lotSizeAcre) {
        subtitle += ` - ${lotSizeAcre} AC`;
      }
      if (zoneCode) {
        subtitle += ` ${dot} Zoned ${zoneCode}`;
      }

      return subtitle;
    },
  },
  {
    // Example: Sing Family Residence - 2,400 SF • Built 1990
    propertyUseCodeMapped: [...RESIDENTIAL_CODES],
    format: ({ type, buildingSize, lotSizeAcre, yearBuilt }) => {
      let subtitle = type || '';

      if (buildingSize) {
        subtitle += ` - ${numberWithCommas(buildingSize)} SF`;
      } else if (lotSizeAcre) {
        subtitle += ` - ${lotSizeAcre} AC`;
      }

      if (yearBuilt) {
        subtitle += ` ${dot} Built ${yearBuilt}`;
      }

      return subtitle;
    },
  },
];

type Props = {
  propertyUseCodeMapped?: Maybe<number>;
  type?: Maybe<string>;
  unitsCount?: Maybe<number>;
  zoneCode?: Maybe<string>;
  lotSizeAcre?: Maybe<number>;
  yearBuilt?: Maybe<number>;
  buildingSize?: Maybe<number>;
};

const getFormatter = ({
  propertyUseCodeMapped,
}: {
  propertyUseCodeMapped: Maybe<number>;
}): Maybe<Formatter> => {
  if (propertyUseCodeMapped) {
    return formatters.find((formatter) =>
      formatter.propertyUseCodeMapped.includes(propertyUseCodeMapped)
    );
  }
};

export function getPropertySubtitle({
  buildingSize,
  lotSizeAcre,
  type,
  propertyUseCodeMapped,
  unitsCount,
  yearBuilt,
  zoneCode,
}: Props) {
  const formatter = getFormatter({
    propertyUseCodeMapped,
  });

  if (formatter) {
    return formatter.format({
      buildingSize,
      lotSizeAcre,
      type,
      unitsCount,
      yearBuilt,
      zoneCode,
    });
  }

  return type;
}
