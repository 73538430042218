import React from 'react';
import TextField from '@material-ui/core/TextField';
import { omit } from 'lodash';

const AutocompleteCoreProspectInput = ({ customProps = {}, ...props }) => {
  return (
    <TextField
      {...omit(props, ['customProps'])}
      title={props.inputProps.value.length > 20 ? props.inputProps.value : null}
      fullWidth
      size='small'
      label={customProps.label || 'Search Address, Market, Zip'}
      variant='outlined'
      InputProps={{
        ...props.InputProps,
        endAdornment: customProps.icon
          ? customProps.icon
          : props.InputProps.endAdornment,
      }}
      inputProps={{
        ...props.inputProps,
        style: {},
        autoComplete: 'new-password', // disable autocomplete and autofill
      }}
    />
  );
};

export default AutocompleteCoreProspectInput;
