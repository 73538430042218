import React from 'react';
import Plotly from 'plotly.js/lib/core';

Plotly.register([require('plotly.js/lib/pie')]);

import createPlotlyComponent from 'react-plotly.js/factory';
const Plot = createPlotlyComponent(Plotly);

const CherrePlotlPieChartWrapper = ({
  data,
  height = '100%',
  width = '100%',
  removeLegend = false,
  responsive = true,
  displayLogo = false,
  displayModeBar = false,
  marginLeft = 30,
  marginRight = 30,
  marginBottom = 30,
  marginTop = 30,
  pad = 30,
  hovermode = false,
}) => {
  return (
    <Plot
      data={data}
      layout={{
        height: height,
        width: width,
        margin: {
          l: marginLeft,
          r: marginRight,
          b: marginBottom,
          t: marginTop,
          pad: pad,
        },
        showlegend: removeLegend ? false : true,
        hovermode,
        font: {
          family: 'Montserrat',
        },
      }}
      config={{
        responsive: responsive,
        displaylogo: displayLogo,
        displayModeBar: displayModeBar,
        modeBarButtonsToRemove: [
          'pan2d',
          'select2d',
          'lasso2d',
          'resetScale2d',
          'zoomOut2d',
        ],
      }}
    />
  );
};

export default CherrePlotlPieChartWrapper;
