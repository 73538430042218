import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addPropertyListEntries,
  getPropertyLists,
  removePropertyListEntries,
} from 'src/products/core-prospect/redux/propertyLists/actions';
import { getPropertyLists as getPropertyListsSelector } from 'src/products/core-prospect/search-pages/selectors';
import { useLocation } from 'react-router';
import { resetSearchParameters } from 'src/products/core-prospect/redux/query/actions';
import DeleteSnackbar from '../Sidebar/components/DeleteSnackbar';
import ButtonProgress from 'src/components/ButtonProgress';
import { clearSelectedRows } from 'src/products/core-prospect/redux/table/actions';

const DeleteFromListButton: React.FC = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const list_id = useMemo(
    () => new URLSearchParams(search).get('list_id'),
    [search]
  );

  React.useEffect(() => {
    dispatch(getPropertyLists());
  }, []);

  const selectedRows = useSelector((state) => {
    const {
      coreProspect: {
        table: { selectedRows },
      },
    } = state;

    return selectedRows;
  });

  const list = useSelector((state) => {
    const lists = getPropertyListsSelector(state);
    return lists.find((l) => l.id === Number(list_id));
  });

  const handleClick = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    const taxIds = selectedRows.map((s) => s.tax_assessor_id);
    const ids = list?.propertyListEntry
      .filter((p) => taxIds.includes(Number(p.propertyId)))
      .map((p) => Number(p.id));
    await dispatch(removePropertyListEntries({ ids }));
    await dispatch(
      resetSearchParameters([], {
        cards: true,
        count: true,
        results: true,
        rebound: true,
        viewportOnly: false,
        id: `Query on property list selected`,
      })
    );
    setSnackbarOpen(true);
    setIsLoading(false);
  };

  const handleUndo = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    setSnackbarOpen(false);
    const property_ids = selectedRows.map((s) => s.tax_assessor_id);
    await dispatch(
      addPropertyListEntries({
        list_id: Number(list_id),
        property_code_type: 'tax_assessor_id',
        property_ids,
      })
    );
    await dispatch(
      resetSearchParameters([], {
        cards: true,
        count: true,
        results: true,
        rebound: true,
        viewportOnly: false,
        id: `Query on property list selected`,
      })
    );
    setIsLoading(false);
  };

  const handleOnCloseSnackbar = () => {
    dispatch(clearSelectedRows());
    setSnackbarOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonProgress
        loading={isLoading}
        variant='contained'
        color='primary'
        style={{
          textTransform: 'none',
          pointerEvents: 'all',
        }}
        onClick={handleClick}
      >
        Remove
      </ButtonProgress>
      <DeleteSnackbar
        isOpen={isSnackbarOpen}
        onClose={handleOnCloseSnackbar}
        text={`${selectedRows.length} properties removed`}
        onActionClick={handleUndo}
      />
    </React.Fragment>
  );
};

export default DeleteFromListButton;
