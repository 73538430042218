import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import LayersButton from 'src/products/core-prospect/components/ReactMap/components/LayersButton';
import LayerSelector from 'src/components/LayerSelector';
import { LayerGroup } from '../types';

type Props = {
  layerGroups: LayerGroup[];
  onChange: (layerGroups: LayerGroup[]) => void;
};

const LayersSelector: React.FC<Props> = ({ layerGroups, onChange }) => {
  const mapLayersRef = useRef<typeof LayerSelector>(null);
  const [domReady, setDomReady] = useState(false);

  const appEl = window.document.querySelector('#app-content');

  useEffect(() => {
    setDomReady(true);
  }, []);

  return (
    <div style={{ position: 'absolute', top: 15, right: 15, zIndex: 1 }}>
      <LayersButton
        onClick={() => {
          if (mapLayersRef.current) {
            mapLayersRef.current.toggle();
          }
        }}
      />
      {domReady && appEl
        ? ReactDOM.createPortal(
            <LayerSelector
              ref={mapLayersRef}
              layers={layerGroups}
              onChange={onChange}
              isCompact={false}
            />,
            appEl
          )
        : null}
    </div>
  );
};

export default LayersSelector;
