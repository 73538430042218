import React from 'react';
import FilterGroupTitle from './FilterGroupTitle';
import FilterGroupFilters from 'src/products/core-prospect/search-pages/components/Sidebar/Views/FiltersView/FilterGroup/FilterGroupFilters';
import { Collapse, makeStyles, Theme } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { grey } from '@material-ui/core/colors';
import { atomFamily, useRecoilState } from 'recoil';

const useStyles = makeStyles<Theme, { open: boolean }>({
  wrap: {
    paddingLeft: 15,
    backgroundColor: ({ open }) => (open ? grey[100] : '#fff'),
    borderBottom: ({ open }) => (open ? `1px solid ${grey[300]}` : 'none'),
    borderTop: ({ open }) => (open ? `1px solid ${grey[300]}` : 'none'),
  },
  group: {
    marginBottom: 0,
  },
  icon: {
    marginRight: 5,
  },
  filters: {
    paddingRight: 5,
  },
});

export const isGroupOpenAtom = atomFamily<boolean, number>({
  key: 'SEARCH-PAGE/SIDEBAR/IS-GROUP-OPEN-ATOM',
  default: (groupId) => groupId === 1,
});

const FilterGroup = ({ filterGroups, group, index, onBlur }) => {
  const [open, setOpen] = useRecoilState(isGroupOpenAtom(group.id));
  const classes = useStyles({ open });

  return (
    <div className={classes.wrap}>
      <div className={classes.group} key={group.label}>
        <FilterGroupTitle
          className={`js-filter-group-${index}`}
          onClick={() => {
            setOpen(!open);
          }}
          icon={
            open ? (
              <ExpandLess className={classes.icon} />
            ) : (
              <ExpandMore className={classes.icon} />
            )
          }
          group={group}
        />
        <Collapse in={open}>
          <div className={classes.filters}>
            <FilterGroupFilters
              filterGroups={filterGroups}
              group={group}
              onBlur={onBlur}
            />
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default FilterGroup;
