import { getSearchParametersFeature } from 'src/products/core-prospect/search-pages/selectors';
import * as FILTER_TYPES from 'src/components/filters/const/filter-types';
import { filtersConfig } from '../filtersConfig';
import { RootState } from 'react-redux';
import { SearchParameter } from '../types/SearchParameter';

export const createFiltersString = (
  state: RootState,
  search_parameters: SearchParameter[]
) => {
  const searchFeature = getSearchParametersFeature(state);

  console.log('searchFeature', searchFeature);

  // we don't have city_boundary data for now, thats why we have 2
  // search parameters when city is selected (state and city)
  // we have to delete state when we are showing filter string.
  const isCitySelected = ['city', 'state'].every((fieldName) =>
    search_parameters.some((searchParameter) => {
      return searchParameter.field_name === fieldName;
    })
  );

  const geo_search_parameters = search_parameters
    .filter(
      (search_parameter) =>
        search_parameter.attributes.isAutocomplete ||
        search_parameter.field_name === 'custom_search_area'
    )
    .filter((search_parameter) => {
      if (!isCitySelected) {
        return true;
      }

      if (search_parameter.field_name === 'state') {
        return false;
      }

      return true;
    })
    .sort((a) => {
      return a.field_name === 'city' ? -1 : 1;
    });

  const defaultLocalInputValue = geo_search_parameters
    .map((sp) => sp.attributes.displayText || 'Map Area')
    .join(', ');

  const texts: string[] = [];
  if (defaultLocalInputValue) {
    texts.push('Location: ' + defaultLocalInputValue);
  }
  const remainingSearchParameters = search_parameters.filter(
    (search_parameter) => {
      return search_parameter.field_name !== 'custom_search_area';
    }
  );
  remainingSearchParameters.forEach((parameter) => {
    const filter = filtersConfig.filters.find(
      (_filter) =>
        _filter.entity === parameter.entity_name &&
        _filter.field_name === parameter.field_name
    );
    if (!filter) {
      return;
    }
    switch (filter.filter_type) {
      case FILTER_TYPES.HIERARCHICAL_SELECTION: {
        return;
      }
      case FILTER_TYPES.RANGE_DATE: {
        const { min, max } = parameter.attributes.value;
        if (min && max) {
          texts.push(`${filter.label}: ${min} - ${max}`);
          return;
        }
        if (min) {
          texts.push(`${filter.label}: Before ${min}`);
          return;
        }
        if (max) {
          texts.push(`${filter.label}: After ${max}`);
          return;
        }
        return;
      }
      default: {
        switch (typeof parameter.attributes.value) {
          case 'boolean':
          case 'number':
          case 'string': {
            texts.push(`${filter.label}: ${parameter.attributes.value}`);
            return;
          }
          case 'object': {
            const { value } = parameter.attributes;
            if (Array.isArray(value)) {
              texts.push(`${filter.label}: ${value.join(', ')}`);
              return;
            }
            if (value.max || value.min) {
              const { min, max } = value;
              if (min && max) {
                texts.push(`${filter.label}: ${min}-${max}`);
                return;
              }
              if (min) {
                texts.push(`${filter.label}: Less than ${min}`);
                return;
              }
              if (max) {
                texts.push(`${filter.label}: More than ${max}`);
                return;
              }
              return;
            }
          }
        }
      }
    }
  });
  return texts.join('; ');
};
