import { selectorFamily } from 'recoil';
import { merge } from 'src/utils/recoil/utils';
import { taxAssessorQuery } from '../../../recoil';
import { Tax_AssessorQueryVariables } from '../../../recoil/__generated__/taxAssessor';
import { BuildingInfoData } from '../types';
import { taxAssessorToBuildingInfo } from './formatters';

export const buildingInfoData = selectorFamily<
  BuildingInfoData,
  Tax_AssessorQueryVariables
>({
  key: 'LOT-PAGE/BUILDING-INFO-DATA',
  get:
    (params) =>
    ({ get }) => {
      const defaultValue = {
        yearBuilt: '—',
        numberOfUnits: '—',
        buildingArea: '—',
      };

      const taxAssessorValue = get(taxAssessorQuery(params));
      const taxAssessorFormatted = taxAssessorToBuildingInfo(taxAssessorValue);

      return merge(defaultValue, taxAssessorFormatted);
    },
});
