import React from 'react';
import PagedTable from 'src/components/PagedTable';
import { RootState, useDispatch, useSelector } from 'react-redux';
import {
  deleteList,
  getPropertyLists,
  undoDeleteList,
} from 'src/products/core-prospect/redux/propertyLists/actions';
import {
  deleteView,
  getViews,
  undoDeleteView,
} from 'src/products/core-prospect/redux/query/actions';
import { getViews as getViewsSelector } from 'src/products/core-prospect/search-pages/selectors';
import { columns } from './constants';
import {
  Container,
  CssBaseline,
  InputAdornment,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import pluralize from 'pluralize';
import DeleteSnackbar from '../search-pages/components/Sidebar/components/DeleteSnackbar';
import { createFiltersString } from './utils';
import Search from '@material-ui/icons/Search';

// TYPES

interface GridItem {
  name: string;
  id: string;
  type: 'Saved Search' | 'Property List';
  details: string;
  created: string;
  usersCount: number;
}

interface SortBy {
  field: React.Key;
  direction: 'asc' | 'desc';
}

// STYLES

const useStyle = makeStyles((theme) => ({
  table: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& .BaseTable__row': {
      cursor: 'pointer',
      padding: '10.9px 0px',
      '& .name': {
        color: '#212121',
        fontSize: '14px',
      },
      '& .details': {
        fontSize: '12px',
        color: '#616161',
      },
      '& .delete': {
        display: 'none',
      },
    },
    '& .BaseTable__row:hover': {
      '& .name': {
        color: theme.palette.primary.main,
      },
      '& .created': {
        display: 'none',
      },
      '& .delete': {
        display: 'block !important',
      },
    },
  },
  header: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    padding: '25px 0px 34px 0px',
    alignItems: 'center',
  },
  title: {
    fontSize: '32px',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
  },
  searchInput: {
    borderRadius: '10px',
    boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    padding: '5px 10px',
  },
}));

// SELECTORS

const propertyListsSelector = (state: RootState): GridItem[] => {
  const list = state.coreProspect.propertyLists.propertyLists.value;
  return (list || [])
    .filter((item) => !item.deleted)
    .map((item) => ({
      name: item.name,
      id: 'list-' + item.id,
      type: 'Property List',
      details: pluralize('property', Number(item.entries_count), true),
      created: moment(item.created_at).format('MMM DD, YYYY'),
      usersCount: Number(item.usersCount),
      teamsCount: Number(item.teamsCount),
    }));
};

const viewsSelector = (state: RootState): GridItem[] => {
  const views = getViewsSelector(state);
  return (views || []).map((item) => ({
    name: item.view.name,
    id: 'search-' + item.view.id,
    type: 'Saved Search',
    details: item.search_parameters
      ? createFiltersString(state, item.search_parameters)
      : '',
    created: moment(item.view.created_at).format('MMM DD, YYYY'),
    usersCount: 0,
    teamsCount: 0,
  }));
};

// MAIN COMPONENT

const ListsAndSearches: React.FC = () => {
  const [sortBy, setSortBy] = React.useState<SortBy>({
    field: 'name',
    direction: 'asc',
  });

  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const [page, setPage] = React.useState(0);

  const dispatch = useDispatch();

  const [selectedList, selectList] = React.useState<GridItem | null>(null);

  const propertyLists = useSelector(propertyListsSelector);

  const views = useSelector(viewsSelector);

  const items = React.useMemo(() => {
    return [...propertyLists, ...views].map((item) => ({
      ...item,
      onDelete: () => {
        selectList(item);
        const _id = Number(item.id.split('-')[1]);
        const isList = item.type === 'Property List';
        dispatch((isList ? deleteList : deleteView)(_id));
      },
    }));
  }, [propertyLists, views]);

  const [search, setSearch] = React.useState('');

  const _items = React.useMemo(() => {
    return [...items]
      .filter((item) => {
        if (!search) {
          return true;
        }
        return item.name.toLowerCase().includes(search.toLowerCase());
      })
      .sort((a, b) => {
        const _order = sortBy.direction === 'asc' ? 1 : -1;
        if (a[sortBy.field] > b[sortBy.field]) {
          return _order;
        }
        if (a[sortBy.field] < b[sortBy.field]) {
          return -_order;
        }
        return 0;
      });
  }, [items, sortBy, search]);

  const _pagedItems = React.useMemo(() => {
    return _items.slice(rowsPerPage * page, rowsPerPage * (page + 1));
  }, [_items, rowsPerPage, page]);

  React.useEffect(() => setPage(0), [items]);

  React.useEffect(() => {
    dispatch(getPropertyLists());
    dispatch(getViews());
  }, []);

  const classes = useStyle();

  return (
    <Container
      style={{
        paddingBottom: '50px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <CssBaseline />
      <div className={classes.header}>
        <Typography className={classes.title}>
          Saved Searches {'&'} Property Lists
        </Typography>
        <TextField
          className={classes.searchInput}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder='Search'
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position='start'>
                <Search style={{ color: '#757575' }} />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <Paper
        style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <div className={classes.table}>
          <PagedTable
            columns={columns(Math.min(window.innerWidth, 1280) - 60)}
            items={_pagedItems}
            onChangeOrder={setSortBy}
            rowKey={'id'}
            selection={false}
            orderBy={sortBy.field}
            orderByDirection={sortBy.direction}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={(e) => setRowsPerPage(Number(e.target.value))}
            page={page}
            onChangePage={({ index }) => setPage(index)}
            hasNextPage={page + 1 < _items.length / rowsPerPage}
            hasPreviousPage={page > 0}
            totalCount={_items.length}
          />
        </div>
      </Paper>
      <DeleteSnackbar
        isOpen={!!selectedList}
        onClose={() => selectList(null)}
        text={`${selectedList?.name} deleted`}
        onActionClick={() => {
          if (!selectedList) {
            return;
          }
          const _id = Number(selectedList.id.split('-')[1]);
          const isList = selectedList.type === 'Property List';
          dispatch((isList ? undoDeleteList : undoDeleteView)(_id));
          selectList(null);
        }}
      />
    </Container>
  );
};

ListsAndSearches.displayName = 'ListsAndSearches';

export default ListsAndSearches;
