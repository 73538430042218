import moment from 'moment';
import numberWithCommas from 'src/utils/numberWithCommas';

export const dollars = (number?: number | null | string) => {
  if (typeof number === 'string') {
    return isNaN(Number(number)) ? undefined : dollars(Number(number));
  }

  return typeof number === 'number'
    ? `$${number
        .toFixed(Number.isInteger(number) ? 0 : 2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
    : undefined;
};

export const longDate = (date?: any) =>
  date ? moment(date).format('MMM DD, YYYY') : undefined;

export const number = (number?: any, unit?: string) =>
  typeof number === 'number'
    ? `${numberWithCommas(number)}${unit ? ` ${unit}` : ''}`
    : undefined;
