import { selectorFamily } from 'recoil';
import { compstakLeases } from './compstakLeases';
import * as T from './__generated__/compstakLeases';

type Defined<T> = Exclude<T, null | undefined>;

export type Lease = Defined<
  Defined<
    T.CompstakLeasesQuery['tax_assessor_v2'][0]['cherre_address__property_address']
  >[0]['compstak_leases__address']
>[0] & { id: number };

export const leases = selectorFamily<Lease[], T.CompstakLeasesQueryVariables>({
  key: 'LOT-PAGE/LEASES',
  get:
    (params) =>
    ({ get }) => {
      const leasesResult = get(compstakLeases(params));

      return (leasesResult?.tax_assessor_v2 || [])
        .flatMap((a) => a?.cherre_address__property_address)
        .flatMap((b) => b?.compstak_leases__address)
        .filter(Boolean)
        .map((lease, id) => ({ ...lease, id }));
    },
});
