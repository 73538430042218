import { Chip, makeStyles, Tooltip } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import pluralize from 'pluralize';
import React from 'react';
import { DealStageChip } from 'src/products/core-prospect/pages/lot-page/components';
import { useAllDealsPortfolioByTaxId } from 'src/services/Dealcloud/hooks';

const useStyles = makeStyles(() => ({
  portfolioChip: {
    color: '#9F4182',
    backgroundColor: '#FCEDF6',
    fontSize: '12px',
    height: '19px',
    fontWeight: 500,
    maxWidth: '220px',
    overflow: 'hidden',
  },
}));

export const DealColumn: React.VFC<{
  taxAssessorId: number;
  column: 'stage' | 'lead' | 'note' | 'name' | 'description';
}> = ({ taxAssessorId, column }) => {
  const dealsInfo = useAllDealsPortfolioByTaxId({});
  const deal = dealsInfo.get(taxAssessorId);
  const classes = useStyles();

  if (!deal) {
    return null;
  }

  if (column === 'stage') {
    return (
      <DealStageChip
        color={grey[900]}
        bgcolor={grey[200]}
        size='small'
        name={deal.stage}
      />
    );
  }

  if (column === 'lead') {
    return <>{deal.lead}</>;
  }

  if (column === 'description') {
    return (
      <Tooltip title={deal.description}>
        <div
          style={{
            whiteSpace: 'normal',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
          }}
        >
          {deal.description}
        </div>
      </Tooltip>
    );
  }

  if (column === 'name' && deal.assets.length > 1 && deal.dealName) {
    return (
      <Tooltip
        title={`${deal.dealName} - ${pluralize(
          'property',
          deal.assets.length,
          true
        )} portfolio`}
      >
        <Chip label={deal.dealName} className={classes.portfolioChip} />
      </Tooltip>
    );
  }

  if (column === 'note') {
    return (
      <Tooltip title={deal.lastActivityNote}>
        <div
          style={{
            whiteSpace: 'normal',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
          }}
        >
          {deal.lastActivityNote}
        </div>
      </Tooltip>
    );
  }

  return null;
};
