import React from 'react';
import {
  PropertyAddressSummary,
  PropertyAddressSummaryEvent,
  PropertyAddressSummaryProps,
} from 'src/products/core-prospect/components/PropertyAddressSummary';
import { usaDataFormatter } from 'src/products/core-prospect/components/PropertyAddressSummary/dataFormatter/usaDataFormatter';
import { useAllDealsPortfolioByTaxId } from 'src/services/Dealcloud/hooks';
import { getLotPagePath } from '../../../utils';
import { PopupPropertyType } from '../hook/usePopupData';
import { useDashboardPropertyLink } from 'src/hooks/useDashboardPropertyLink';

export type LotPropertyProps = {
  single?: boolean;
  property: PopupPropertyType;
  rowHeight?: number | string;
  onClickAddPortfolio?: ({ property }: { property: PopupPropertyType }) => void;
  onClickRemovePortfolio?: PropertyAddressSummaryEvent;
  onClickAddDeal?: PropertyAddressSummaryEvent;
  onClickEditDeal?: PropertyAddressSummaryEvent;
  onClickPortfolioViewProperties?: PropertyAddressSummaryEvent;
};

export const LotProperty: React.VFC<LotPropertyProps> = ({
  single = false,
  property,
  rowHeight,
  onClickAddPortfolio,
  onClickRemovePortfolio,
  onClickAddDeal,
  onClickEditDeal,
  onClickPortfolioViewProperties,
}) => {
  const data = usaDataFormatter(property);
  const parcelId = data.parcelId || '';
  const taxAssessorId = Number(data.taxAssessorId);

  const dashboardPropertyLink = useDashboardPropertyLink(
    property.cherre_parcel_id
  );

  const deals = useAllDealsPortfolioByTaxId({});
  const deal = deals?.get(taxAssessorId);

  const isPortfolio = (deal?.assets.length || 0) > 1;

  const props: PropertyAddressSummaryProps = {
    property: data,
    deal,
  };

  if (single && isPortfolio) {
    props.showPropertyLastSale = false;
    props.showPropertySubtitle = false;
    props.showPortfolioCount = true;
    props.showPortfolioChip = true;
    props.showDealLeadStageFormatted = true;
    props.showDealLatestNoteLarge = true;
    props.showPortfolioViewProperties = true;
    props.onClickPortfolioViewProperties = onClickPortfolioViewProperties;
    props.height = 'auto';
  }

  if (single && !isPortfolio) {
    props.showPropertySubtitle = true;
    props.showPropertyLastSale = false;
    props.showDealLeadStageFormatted = true;
    props.showDealLatestNoteLarge = true;
    props.height = 'auto';
  }

  if (!single && isPortfolio) {
    props.showPropertySubtitle = false;
    props.showPropertyLastSale = false;
    props.showPortfolioCount = true;
    props.showDealStageChipAndLead = true;
    props.showPortfolioChip = true;
    props.onClickPortfolioViewProperties = onClickPortfolioViewProperties;
    props.addToListAction = true;
    props.dealCloudHref = `https://eqtexeter.dealcloud.com/portal/pages/871/reports/888/entries/${deal?.dealId}`;
  }

  if (!single && !isPortfolio) {
    props.showPropertySubtitle = true;
    props.showPropertyLastSale = false;
    props.showDealStageChipAndLead = true;
    props.addToListAction = true;
    props.dealCloudHref = deal
      ? `https://eqtexeter.dealcloud.com/portal/pages/871/reports/888/entries/${deal.dealId}`
      : undefined;
  }

  props.padding = 10;
  props.height = rowHeight;
  props.hoverEffect = false;
  props.addressHref = getLotPagePath(parcelId, taxAssessorId);
  props.addressHrefExternal = true;

  if (!single) {
    props.onClickAddDeal = deal ? undefined : onClickAddDeal;
    props.onClickEditDeal = deal ? onClickEditDeal : undefined;
    props.onClickAddPortfolio = onClickAddPortfolio
      ? () => onClickAddPortfolio?.({ property })
      : undefined;
    props.onClickRemovePortfolio = onClickRemovePortfolio
      ? onClickRemovePortfolio
      : undefined;
    props.dashboardPropertyLink = dashboardPropertyLink;
  }

  return <PropertyAddressSummary {...props} />;
};
