import { useRecoilState } from 'recoil';
import { heatmaps, HeatmapSettings } from './heatmaps';
import {
  DemographicsGeographyLayerName,
  LayerGroup,
  LayerName,
} from '../../types';
import { useSelector } from 'react-redux';
import {
  getDemographicsGeographyNameByZoom,
  getSelectedDemographicsLayerName,
  getSelectedLayerNames,
} from './utils';
import { layerGroupsAtom } from '../../recoil';
import { useMemo } from 'react';
import { memoize } from 'lodash';

const getSelectedLayerNamesMemoized = memoize(getSelectedLayerNames);

export const useMapLayers = ({
  zoom,
}: {
  zoom?: number;
}): {
  layerGroups: LayerGroup[];
  setLayerGroups: (val: LayerGroup[]) => void;
  selectedLayerNames: LayerName[];
  selectedDemographicsLayerName: LayerName | undefined;
  demographicsGeographyName: DemographicsGeographyLayerName | undefined;
  heatmapSettings?: HeatmapSettings;
} => {
  const featureFlags =
    useSelector((state) => state.user?.profile?.value?.featureFlags) || {};

  const [layerGroups, setLayerGroups] = useRecoilState(layerGroupsAtom);

  const availableLayerGroups = useMemo(
    () =>
      layerGroups.filter(
        (group) => !group.featureFlag || featureFlags[group.featureFlag || '']
      ),
    [layerGroups, featureFlags]
  );

  const demographicsGeographyName = getDemographicsGeographyNameByZoom(zoom);

  const selectedLayerNames = useMemo(
    () => getSelectedLayerNamesMemoized(layerGroups),
    [layerGroups]
  );

  const selectedDemographicsLayerName =
    getSelectedDemographicsLayerName(layerGroups);

  const heatmapSettings: HeatmapSettings | undefined =
    selectedDemographicsLayerName && demographicsGeographyName
      ? heatmaps[selectedDemographicsLayerName]?.[demographicsGeographyName]
      : undefined;

  return {
    selectedLayerNames,
    layerGroups: availableLayerGroups,
    heatmapSettings,
    selectedDemographicsLayerName,
    demographicsGeographyName,
    setLayerGroups,
  };
};
