import React from 'react';
import { capitalize } from 'lodash';
import { Link } from '@material-ui/core';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';

export const OwnerCell = ({ rowData }) => {
  const ownerDetailsEnabled = useFeatureFlag('CoreExplore_OwnerDetails');

  const name = rowData.ownerId?.split('::')[0];

  return (
    <div style={{ padding: '15px 0' }}>
      {ownerDetailsEnabled ? (
        <Link
          target='_blank'
          href={encodeURI(`/owners/${rowData.ownerId}/${name}`)}
        >
          {capitalize(name)}
        </Link>
      ) : (
        capitalize(name)
      )}
    </div>
  );
};
