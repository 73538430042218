import React from 'react';
import SearchFilterSummary from 'src/components/SearchFilterSummary';
import Pill from '../../../components/Pill';

const SavedSearches = ({ filteredViews, filterText, deleteView }) => {
  return filteredViews.map((view, index) => {
    const filterSummary = <SearchFilterSummary view={view} />;

    return (
      <Pill
        key={index}
        id={`js-core-prospect-search-item-${index}`}
        filterText={filterText}
        primaryText={view.view.name}
        secondaryText={filterSummary}
        url={`/properties/${view.view.id}`}
        onDelete={{
          title: `Delete ${view.view.name}`,
          action: () => {
            deleteView(view.view);
          },
        }}
      />
    );
  });
};

export default SavedSearches;
