import { Typography } from '@material-ui/core';
import React from 'react';
import { HorizontalKeyValue } from '../../components';
import useStyles from './styles';
import * as T from './types';

const BuildingInfo: T.BuildingInfo = ({
  yearBuilt,
  numberOfUnits,
  buildingArea,
}) => {
  const classes = useStyles();
  return (
    <div id='lot-page-building-info' className={classes.container}>
      <Typography variant='h4' style={{ marginBottom: '25px' }}>
        Building Info
      </Typography>
      <HorizontalKeyValue label='Year built' value={yearBuilt} />
      <HorizontalKeyValue label='Number of units' value={numberOfUnits} />
      <HorizontalKeyValue label='Building area' value={buildingArea} />
    </div>
  );
};

BuildingInfo.displayName = 'BuildingInfo';

export default BuildingInfo;
