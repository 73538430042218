import { makeStyles, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import Person from '@material-ui/icons/Person';
import React from 'react';
import Truncate from 'src/components/Truncate';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    border: `1px solid ${grey[300]}`,
    borderRadius: 10,
    padding: 10,
    marginBottom: 10,
  },
  icon: {
    color: theme.palette.secondary.main,
    fontSize: 20,
  },
  text: {
    marginLeft: 10,
    flex: 1,
    overflow: 'hidden',
  },
}));

const RecordedOwner = ({ name, type }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.icon}>
        {type === 'Individual' ? (
          <Person />
        ) : (
          <i className='icon icon-type-newdev' />
        )}
      </div>
      <div className={classes.text}>
        <Typography variant='body1'>
          <Truncate>{name}</Truncate>
        </Typography>
      </div>
    </div>
  );
};

export default RecordedOwner;
