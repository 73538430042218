import React from 'react';
import ArrowBack from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import Truncate from 'src/components/Truncate';
import { Tooltip, TextField, makeStyles } from '@material-ui/core';
import Done from '@material-ui/icons/Done';
import Close from '@material-ui/icons/Close';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0',
    position: 'relative',
    paddingRight: 10,
  },
  title: {
    flex: 1,
    overflow: 'hidden',
  },
  titleWrap: {
    overflow: 'hidden',
    padding: '11px 0',
    width: '100%',
    height: '100%',
    '&:hover': {
      background: (props) => (props.allowEditing ? '#eee' : '#fff'),
    },
  },
  icon: {
    background: '#fff',
    borderRadius: 5,
    padding: 5,
    position: 'absolute',
    boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.2)',
    zIndex: 100,
    display: 'flex',
    cursor: 'pointer',
    '&:hover': {
      background: '#eee',
    },
  },
});

const Title = ({
  onBackClick,
  onRename,
  title,
  action,
  backUrl = '/properties',
  hideBackButton,
  allowEditing,
  id,
}) => {
  const classes = useStyles({ allowEditing });

  const [prevInputValue, setPrevInputValue] = React.useState(title);
  const [inputValue, setInputValue] = React.useState(title);
  const [isEditable, toggleIsEditable] = React.useState(false);

  React.useEffect(() => {
    if (title !== inputValue) {
      setInputValue(title);
    }
  }, [title]);

  const cancel = () => {
    setInputValue(prevInputValue);
    toggleIsEditable(false);
  };

  const rename = () => {
    if (!inputValue) {
      return cancel();
    }

    setPrevInputValue(inputValue);
    toggleIsEditable(false);
    onRename({ name: inputValue });
  };

  const dispatch = useDispatch();

  const ref = React.useRef();

  return (
    <div className={classes.root}>
      {!hideBackButton && (
        <IconButton
          onClick={() => {
            onBackClick && onBackClick();
            dispatch(push(backUrl));
          }}
        >
          <ArrowBack id='js-core-prospect-arrow-back' />
        </IconButton>
      )}
      {isEditable && (
        <>
          <TextField
            id='js-core-prospect-search-page-title-input'
            error={!inputValue}
            label={!inputValue ? 'Required' : ''}
            inputRef={ref}
            fullWidth
            onChange={(e) => setInputValue(e.target.value)}
            size='small'
            variant='outlined'
            onBlur={() => rename()}
            value={inputValue}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                rename();
              }
              if (e.key === 'Escape') {
                cancel();
              }
            }}
          />
          <div
            className={classes.icon}
            style={{
              right: 50,
              bottom: -22,
            }}
            onMouseDown={(e) => {
              e.preventDefault();
              cancel();
            }}
            id='js-title-input-close'
          >
            <Close />
          </div>
          <div
            className={classes.icon}
            style={{
              right: 10,
              bottom: -22,
            }}
            onMouseDown={(e) => {
              e.preventDefault();
              rename();
            }}
            id='js-title-input-done'
          >
            <Done />
          </div>
        </>
      )}
      {!isEditable && (
        <Tooltip title={inputValue}>
          <>
            <div
              className={classes.titleWrap}
              onClick={() => {
                if (!allowEditing) {
                  return;
                }

                toggleIsEditable(true);
                setTimeout(() => {
                  ref.current.focus();
                });
              }}
            >
              <div className={classes.title}>
                <Typography variant='h5' style={{ marginLeft: 5 }} id={id}>
                  <Truncate>{inputValue}</Truncate>
                </Typography>
              </div>
            </div>
          </>
        </Tooltip>
      )}
      <div style={{ position: 'absolute', right: 0 }}>{action}</div>
    </div>
  );
};

export default Title;
