import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PagedTable from 'src/components/PagedTable';

import query from '../queries/zipcodes';
import {
  changeOffset,
  changeOrderBy,
  changeRowsPerPage,
} from '../../../../redux/zipcodes/actions';
import getColumns from '../columns/zipcodes';
import { execTableZipcodesQuery } from 'src/products/core-prospect/redux/query/actions/execTableZipcodesQuery';
import { constSelector, useRecoilValueLoadable } from 'recoil';
import { getDemographicsVintage } from 'src/products/core-prospect/utils/getDemographicsVintage';

export const ZipcodesTab = () => {
  const dispatch = useDispatch();

  const { zipcodes, offset, rowsPerPage, orderBy, count, fetchStatus } =
    useSelector((state) => {
      return {
        fetchStatus: state.coreProspect.zipcodes.fetchStatus.value,
        count: state.coreProspect.zipcodes.count,
        zipcodes: state.coreProspect.zipcodes.results || [],
        offset: state.coreProspect.zipcodes.offset,
        rowsPerPage: state.coreProspect.zipcodes.rowsPerPage,
        orderBy: state.coreProspect.zipcodes.orderBy,
      };
    });

  const page = offset / rowsPerPage;

  const vintage = getDemographicsVintage();

  const usaDemographicsLoadable = useRecoilValueLoadable(
    zipcodes.length
      ? query({
          zipcodes: zipcodes.map((zipcode) => zipcode.zip || ''),
          vintage,
        })
      : constSelector({ usa_demographics_v2: [] })
  );

  const columns = useMemo(() => {
    return getColumns();
  }, []);

  const usaDemographics = usaDemographicsLoadable.valueMaybe();

  const items = useMemo(() => {
    return zipcodes.map((zipcode) => {
      const usaDemographic = usaDemographics?.usa_demographics_v2.find(
        (d) => d.geography_code === zipcode.zip
      );

      return {
        ...zipcode,
        median_household_income: usaDemographic
          ? usaDemographic?.median_household_income?.toString()
          : 'Loading',
        crime_total_risk: usaDemographic
          ? usaDemographic?.crime_total_risk?.toString()
          : 'Loading',
        population_median_age: usaDemographic
          ? usaDemographic?.population_median_age?.toString()
          : undefined,
      };
    });
  }, [zipcodes, usaDemographics]);

  return (
    <PagedTable
      stickyLeft={1}
      selection={false}
      isAllRowsSelected={false}
      rowsPerPage={rowsPerPage}
      onChangeRowsPerPage={(event) => {
        dispatch(changeRowsPerPage(parseInt(event.target.value, 10)));
        dispatch(
          execTableZipcodesQuery({
            isCount: false,
            isExport: false,
            paginate: false,
            viewportOnly: true,
          })
        );
      }}
      totalCount={count}
      page={page}
      onSelect={() => {}}
      onSelectAllChange={() => {}}
      onChangePage={({ index }) => {
        dispatch(changeOffset(index * rowsPerPage));
        dispatch(
          execTableZipcodesQuery({
            isCount: false,
            isExport: false,
            paginate: true,
            viewportOnly: true,
          })
        );
      }}
      hasNextPage={(page + 1) * rowsPerPage < count}
      hasPreviousPage={page > 0}
      orderBy={orderBy.field}
      orderByDirection={orderBy.direction}
      onChangeOrder={async (orderBy) => {
        await dispatch(changeOrderBy(orderBy));
        dispatch(
          execTableZipcodesQuery({
            isCount: false,
            isExport: false,
            paginate: false,
            viewportOnly: true,
          })
        );
      }}
      columns={columns}
      items={items}
      isLoading={fetchStatus !== 'LOADED'}
      emptyText=''
      rowKey='zip'
    />
  );
};
