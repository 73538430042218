import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useRecoilValue } from 'recoil';
import { useDealPortfolioByTaxId } from 'src/services/Dealcloud/hooks';
import { usePrint } from 'src/utils/printActionContext';
import { LotPageParams } from '../../types';
import { headerData } from './recoil';
import * as T from './types';

const hook: T.UseHeader = () => {
  const { id, landLotBBL } = useParams<LotPageParams>();
  const { address, cityStateZip, cherre_parcel_id } = useRecoilValue(
    headerData({ tax_assessor_id: Number(id) })
  );

  const user = useSelector((state) => state.user.profile);
  if (user.fetchStatus.value !== 'LOADED') {
    throw new Promise(() => {});
  }

  const print = usePrint();
  const history = useHistory();
  const selectUnitAction = (item: T.UnitOption) => {
    history.push(`/properties/${cherre_parcel_id}/${item.value}`);
  };

  const deal = useDealPortfolioByTaxId(parseInt(id));

  return {
    address,
    cityStateZip,
    selectUnitAction,
    landLotBBL: Boolean(landLotBBL),
    user: user.value,
    print,
    tax_assessor_id: Number(id),
    cherre_parcel_id,
    deal,
  };
};

export default hook;
