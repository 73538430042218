import React from 'react';
import TreppLoanDataModal from 'src/components/modals/TreppLoanDataModal/index.js';

const TreppModalButton = ({ item }) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <span
        style={{
          color: '#df2467',
          marginLeft: 12,
          cursor: 'pointer',
        }}
        onClick={handleOpen}
      >
        More Info
      </span>
      <TreppLoanDataModal item={item} open={open} handleClose={handleClose} />
    </React.Fragment>
  );
};

export default TreppModalButton;
