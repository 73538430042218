import useWidth from 'src/hooks/useWidth';
import React from 'react';
import { Flex } from 'src/components/Flex';
import CircularProgress from '@material-ui/core/CircularProgress';
import RCAMapOrTable from './RCAMapOrTable';
const HEIGHT = '500px';

const RCABody = ({
  data,
  showMap,
  bounds,
  rcaCompsList = [],
  loading,
  getCherreParcelGemo,
  mapLatLongId,
  selectedParcelData,
  setSelectedParcelData,
  showPropertyDetails,
  hidePropertyDetails,
}) => {
  const width = useWidth();
  const isCompact = ['xs'].includes(width);

  return (
    <Flex
      className='print-height-auto'
      style={{ height: HEIGHT }}
      justifyContent={!isCompact ? 'center' : 'left'}
      alignItems='center'
    >
      {loading && rcaCompsList.length === 0 ? (
        <CircularProgress />
      ) : (
        <RCAMapOrTable
          mapLatLongId={mapLatLongId}
          height={HEIGHT}
          isCompact={isCompact}
          bounds={bounds}
          rcaCompsList={rcaCompsList}
          showMap={showMap}
          data={data}
          loading={loading}
          getCherreParcelGemo={getCherreParcelGemo}
          selectedParcelData={selectedParcelData}
          setSelectedParcelData={setSelectedParcelData}
          showPropertyDetails={showPropertyDetails}
          hidePropertyDetails={hidePropertyDetails}
        />
      )}
    </Flex>
  );
};

export default RCABody;
