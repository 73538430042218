import { useRecoilValue } from 'recoil';
import { useOrganizationPropsValue } from 'src/hooks';
import { dealStagesSelector } from 'src/services/Dealcloud/recoil';

export const useDealStagesList = () => {
  const isDealsIntegrationAvailable =
    useOrganizationPropsValue('deals_enabled');

  if (!isDealsIntegrationAvailable) {
    return [];
  }

  return useRecoilValue(dealStagesSelector);
};
