import React from 'react';
import RCAMapAndCompsList from './RCAMapAndCompsList';
import RCACompsTable from './RCACompsTable';
import { Flex } from 'src/components/Flex';

const RCAMapOrTable = (props) => {
  const { showMap } = props;
  return (
    <React.Fragment>
      <Flex
        className='print-hide'
        id='rca-map-view'
        style={{
          display: showMap ? undefined : 'none',
          height: '100%',
        }}
      >
        <RCAMapAndCompsList {...props} />
      </Flex>
      <div
        className='print-hide'
        id='rca-table-view'
        style={{
          height: '100%',
          width: '100%',
          display: showMap ? 'none' : undefined,
        }}
      >
        <RCACompsTable {...props} limit={10} />
      </div>
      <div
        className='print-only'
        style={{
          height: '100%',
          width: '100%',
        }}
      >
        <RCACompsTable {...props} limit={10} />
      </div>
    </React.Fragment>
  );
};

export default RCAMapOrTable;
