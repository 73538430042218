import { Visibility } from 'mapbox-gl';
import { LayerProps } from 'src/components/Map/types';
import { SOURCES, LAYERS } from '../const';
import { OnLayerClick } from '../types';
import { handleClick } from './utils';

const filter = (parcels?: string[]) => [
  'any',
  [
    'in',
    ['get', 'cherre_parcel_id'],
    ['literal', (parcels || []).filter(Boolean)],
  ],
];

const LINE_COLOR = 'hsl(0%, 20%, 66%)';
const FILL_COLOR = 'hsl(339, 75%, 51%)';

const SELECTED_LINE_COLOR = 'hsl(0, 100%, 66%)';
const SELECTED_FILL_COLOR = 'hsla(0, 100%, 80%, 0.3)';

const ACTIVE_LINE_COLOR = 'hsl(341, 100%, 69%)';
const ACTIVE_FILL_COLOR = 'hsla(339, 75%, 51%, 0.5)';

function getLines({ visibility }: GetLayersProps): LayerProps {
  return {
    id: LAYERS.PARCELS_LINE,
    source: SOURCES.USA_MAP_PARCELS.name,
    'source-layer': SOURCES.USA_MAP_PARCELS.name,
    type: 'line',
    layout: { visibility },
    paint: { 'line-color': LINE_COLOR },
  };
}

function getFill({ onClick, visibility }: GetLayersProps): LayerProps {
  return {
    id: LAYERS.PARCELS_FILL,
    source: SOURCES.USA_MAP_PARCELS.name,
    'source-layer': SOURCES.USA_MAP_PARCELS.name,
    type: 'fill',
    layout: { visibility },
    paint: {
      'fill-color': FILL_COLOR,
      'fill-opacity': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        0.5,
        0,
      ],
    },
    onClick: (e) => handleClick(e, LAYERS.PARCELS_FILL, onClick),
  };
}

function getSelectedLines({ selectedParcels }: GetLayersProps): LayerProps {
  return {
    id: LAYERS.SELECTED_PARCELS_LINE,
    source: SOURCES.USA_MAP_PARCELS.name,
    'source-layer': SOURCES.USA_MAP_PARCELS.name,
    type: 'line',
    paint: {
      'line-color': SELECTED_LINE_COLOR,
      'line-width': 2,
    },
    filter: filter(selectedParcels),
  };
}

function getSelectedFill({
  selectedParcels,
  onClick,
}: GetLayersProps): LayerProps {
  return {
    id: LAYERS.SELECTED_PARCELS_FILL,
    source: SOURCES.USA_MAP_PARCELS.name,
    'source-layer': SOURCES.USA_MAP_PARCELS.name,
    type: 'fill',
    paint: { 'fill-color': SELECTED_FILL_COLOR },
    filter: filter(selectedParcels),
    onClick: (e) => handleClick(e, LAYERS.SELECTED_PARCELS_FILL, onClick),
  };
}

function getActiveLines({ activeParcels }: GetLayersProps): LayerProps {
  return {
    id: LAYERS.ACTIVE_PARCELS_LINE,
    source: SOURCES.USA_MAP_PARCELS.name,
    'source-layer': SOURCES.USA_MAP_PARCELS.name,
    type: 'line',
    paint: {
      'line-color': ACTIVE_LINE_COLOR,
      'line-width': 2,
    },
    filter: filter(activeParcels),
  };
}

function getActiveFill({ activeParcels, onClick }: GetLayersProps): LayerProps {
  return {
    id: LAYERS.ACTIVE_PARCELS_FILL,
    source: SOURCES.USA_MAP_PARCELS.name,
    'source-layer': SOURCES.USA_MAP_PARCELS.name,
    type: 'fill',
    paint: { 'fill-color': ACTIVE_FILL_COLOR },
    filter: filter(activeParcels),
    onClick: (e) => handleClick(e, LAYERS.SELECTED_PARCELS_FILL, onClick),
  };
}

type GetLayersProps = {
  visibility: Visibility;
  onClick?: OnLayerClick;
  activeParcels?: string[];
  selectedParcels?: string[];
};

function getUsaParcelsLayers({
  activeParcels,
  selectedParcels,
  onClick,
  visibility,
}: GetLayersProps): LayerProps[] {
  return [
    getLines({ visibility }),
    getFill({ onClick, visibility }),
    getSelectedLines({ selectedParcels, visibility }),
    getSelectedFill({ selectedParcels, onClick, visibility }),
    getActiveLines({ activeParcels, visibility }),
    getActiveFill({ activeParcels, onClick, visibility }),
  ];
}

export default getUsaParcelsLayers;
