/**
 * This hook is running search query to initialize/refresh search results
 *
 * List of possible triggers when we have to run query:
 *
 * 1. User open core explore map without any search parameters
 * 2. User open core explore map with preselected filters (redirected from home page by autocomplete component)
 * 3. User open core explore with selected saved search
 * 4. User is switching between saved searches
 * 5. User open core explore with selected properties list
 * 6. User is switching between property lists
 * 7. User is switching from saved search to property list
 * 8. User is switching from property list to saved search
 * 9. User is switching from property list to regular search (on logo click)
 * 10. User is swithing from saved search to regular search (on logo click)
 **/

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import {
  getQuerySettings,
  resetSearchParameters,
} from '../../redux/query/actions';
import { execQuery } from '../../redux/query/actions/execQuery';
import { clearSelectedRows } from '../../redux/table/actions';

import useLocation from 'src/hooks/useLocation';
import usePrevious from 'src/hooks/usePrevious';

export const useUpdateSearchResults = () => {
  const dispatch = useDispatch();

  const listId = useLocation()?.query?.list_id as string;
  const savedSearchId = useParams<{ id: string }>()?.id;

  const prevSavedSearchId = usePrevious(savedSearchId);
  const prevListId = usePrevious(listId);

  const isSavedSearchChanged = prevSavedSearchId !== savedSearchId;
  const isSavedSearchSelected = Boolean(savedSearchId);
  const isSavedSearchDeselected = prevSavedSearchId && !savedSearchId;

  const isListChanged = prevListId !== listId;
  const isListSelected = Boolean(listId);
  const isListDeselected = prevListId && !listId;

  useEffect(() => {
    if (isListChanged) {
      dispatch(clearSelectedRows());

      if (isListSelected) {
        dispatch(
          // #5, #6, #7
          resetSearchParameters([], {
            cards: true,
            count: true,
            results: true,
            rebound: true,
            viewportOnly: false,
            id: `Query on property list selected`,
          })
        );
      }

      if (isListDeselected) {
        if (!isSavedSearchSelected) {
          // #9
          dispatch(
            resetSearchParameters([], {
              cards: true,
              count: true,
              results: true,
              rebound: false,
              viewportOnly: true,
              id: `Query on property list deselected`,
            })
          );
        }
      }
    }
    if (isSavedSearchChanged) {
      if (isSavedSearchSelected) {
        dispatch(getQuerySettings(savedSearchId)).then(() => {
          return dispatch(
            // #3, #4, #8
            execQuery({
              id: 'Query on saved search selected',
              cards: true,
              count: true,
              results: true,
              viewportOnly: true,
              rebound: false,
            })
          );
        });
      }

      if (isSavedSearchDeselected) {
        if (!isListSelected) {
          dispatch(
            // #10
            resetSearchParameters([], {
              id: 'Query on saved search deselected',
              cards: true,
              count: true,
              results: true,
              viewportOnly: true,
              rebound: false,
            })
          );
        }
      }
    }
  }, [savedSearchId, listId]);
};
