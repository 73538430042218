import { Reducer } from 'redux';
import * as TYPES from './types';
import findIndex from 'lodash/findIndex';
import { getSearchParameterOperator } from 'src/components/filters';
import {
  FilterType,
  SearchEntity,
  SearchParameter,
  UsaMapParcelSearchField,
} from '../../types/SearchParameter';
import { View } from '../../types/View';
import { removeGeoSearchParameters } from './utils';

const defaultView = {
  view: {
    id: 1,
    name: 'USA Lot Search By Parcel 2',
    user_id: undefined,
    organization_id: undefined,
    share_level_id: 3,
    entity_name: 'usa_map_parcel',
    viewer: 'USA Lot Search By Parcel 2',
    limit: 1000,
    offset: 0,
    created_at: undefined,
    last_modified: undefined,
    order_by: undefined,
    deleted: undefined,
    deleted1: undefined,
    search_parameters: undefined,
  },
  search_parameters: [],
};

export type FetchStatus = {
  value?: 'LOADING' | 'LOADED' | 'FAILED';
  message?: string;
};

type QueryState = {
  data: {
    view: View;
    search_parameters: SearchParameter[];
  };
  areCondoUnitsIncluded: boolean;
  areDealsFiltersEnabled: boolean;
  fetchStatus: FetchStatus;
  views: Array<{
    view: View;
    search_parameters?: SearchParameter[];
  }>;
  saveQueryFetchStatus: FetchStatus;
  getViewsFetchStatus: FetchStatus;
  deleteViewsFetchStatus: FetchStatus;
  execMapQueryFetchStatus: FetchStatus;
  execMapCountQueryFetchStatus: FetchStatus;
  execMapCardsQueryFetchStatus: FetchStatus;
};

const initialState: QueryState = {
  data: defaultView,
  areCondoUnitsIncluded: false,
  areDealsFiltersEnabled: false,
  fetchStatus: {},
  views: [],
  saveQueryFetchStatus: {},
  getViewsFetchStatus: {},
  deleteViewsFetchStatus: {},
  execMapQueryFetchStatus: {},
  execMapCountQueryFetchStatus: {},
  execMapCardsQueryFetchStatus: {},
};

export type QueryReducer = Reducer<QueryState>;

export const query: QueryReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET_SEARCH_FEATURE: {
      let search_parameters: SearchParameter[] = [
        ...state.data.search_parameters,
      ];

      if (action.searchFeature) {
        search_parameters = removeGeoSearchParameters(search_parameters).concat(
          [
            {
              entity_name: SearchEntity.usaMapParcel,
              field_name: UsaMapParcelSearchField.customSearchArea,
              attributes: {
                filter_type: FilterType.customSearchArea,
                value: action.searchFeature,
              },
            },
          ]
        );
      } else {
        search_parameters = search_parameters.filter((search_parameter) => {
          return (
            search_parameter.field_name !==
            UsaMapParcelSearchField.customSearchArea
          );
        });
      }

      return {
        ...state,
        data: {
          ...state.data,
          search_parameters,
        },
      };
    }

    case TYPES.INCLUDE_CONDO_UNITS: {
      return {
        ...state,
        areCondoUnitsIncluded: action.areCondoUnitsIncluded,
      };
    }

    case TYPES.ENABLE_DEALS_FILTERS: {
      return {
        ...state,
        areDealsFiltersEnabled: action.areDealsFiltersEnabled,
      };
    }

    case TYPES.EXEC_MAP_QUERY_REQUEST: {
      return {
        ...state,
        execMapQueryFetchStatus: {
          value: 'LOADING',
        },
      };
    }
    case TYPES.EXEC_MAP_QUERY_SUCCESS: {
      return {
        ...state,
        execMapQueryFetchStatus: {
          value: 'LOADED',
        },
      };
    }
    case TYPES.EXEC_MAP_QUERY_FAILED: {
      return {
        ...state,
        execMapQueryFetchStatus: {
          value: 'FAILED',
        },
      };
    }

    case TYPES.EXEC_MAP_COUNT_QUERY_REQUEST: {
      return {
        ...state,
        execMapCountQueryFetchStatus: {
          value: 'LOADING',
        },
      };
    }
    case TYPES.EXEC_MAP_COUNT_QUERY_SUCCESS: {
      return {
        ...state,
        execMapCountQueryFetchStatus: {
          value: 'LOADED',
        },
      };
    }
    case TYPES.EXEC_MAP_COUNT_QUERY_FAILED: {
      return {
        ...state,
        execMapCountQueryFetchStatus: {
          value: 'FAILED',
        },
      };
    }

    case TYPES.EXEC_COUNT_ALL_QUERY_REQUEST: {
      return {
        ...state,
        execMapCountQueryFetchStatus: {
          value: 'LOADING',
        },
        execMapQueryFetchStatus: {
          value: 'LOADING',
        },
      };
    }
    case TYPES.EXEC_COUNT_ALL_QUERY_SUCCESS: {
      return {
        ...state,
        execMapCountQueryFetchStatus: {
          value: 'LOADED',
        },
        execMapQueryFetchStatus: {
          value: 'LOADED',
        },
      };
    }
    case TYPES.EXEC_COUNT_ALL_QUERY_FAILED: {
      return {
        ...state,
        execMapCountQueryFetchStatus: {
          value: 'FAILED',
        },
        execMapQueryFetchStatus: {
          value: 'FAILED',
        },
      };
    }

    case TYPES.EXEC_MAP_CARDS_QUERY_REQUEST: {
      return {
        ...state,
        execMapCardsQueryFetchStatus: {
          value: 'LOADING',
        },
      };
    }
    case TYPES.EXEC_MAP_CARDS_QUERY_SUCCESS: {
      return {
        ...state,
        execMapCardsQueryFetchStatus: {
          value: 'LOADED',
        },
      };
    }
    case TYPES.EXEC_MAP_CARDS_QUERY_FAILED: {
      return {
        ...state,
        execMapCardsQueryFetchStatus: {
          value: 'FAILED',
        },
      };
    }

    case TYPES.UNDO_DELETE_QUERIES_REQUEST: {
      const { id } = action;

      const index = findIndex(state.views, (view) => view.view.id === id);

      return {
        ...state,
        views: [
          ...state.views.slice(0, index),
          {
            ...state.views[index],
            view: {
              ...state.views[index].view,
              deleted: false,
            },
          },
          ...state.views.slice(index + 1),
        ],
        deleteViewsFetchStatus: {
          value: 'LOADING',
        },
      };
    }

    case TYPES.UNDO_DELETE_QUERIES_SUCCESS: {
      return {
        ...state,
        deleteViewsFetchStatus: {
          value: 'LOADED',
        },
      };
    }

    case TYPES.DELETE_QUERIES_REQUEST: {
      const { id } = action;

      const index = findIndex(state.views, (view) => {
        return view.view.id === id;
      });

      return {
        ...state,
        views: [
          ...state.views.slice(0, index),
          {
            ...state.views[index],
            view: {
              ...state.views[index].view,
              deleted: true,
            },
          },
          ...state.views.slice(index + 1),
        ],
        deleteViewsFetchStatus: {
          value: 'LOADING',
        },
      };
    }

    case TYPES.DELETE_QUERIES_SUCCESS: {
      return {
        ...state,
        deleteViewsFetchStatus: {
          value: 'LOADED',
        },
      };
    }

    case TYPES.GET_QUERY_DATA_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          search_parameters: [],
        },
      };

    case TYPES.RESET_ALL_FILTERS: {
      return {
        ...initialState,
        areDealsFiltersEnabled: state.areDealsFiltersEnabled,
      };
    }

    case TYPES.RESET_SEARCH_PARAMETERS: {
      const { search_parameters } = action;

      return {
        ...state,
        data: {
          ...state.data,
          search_parameters,
        },
      };
    }

    case TYPES.CHANGE_SEARCH_PARAMETER: {
      const { filter, value } = action;
      const search_parameters = state.data.search_parameters;

      let search_parameter;
      let index = search_parameters.findIndex((search_parameter) => {
        return (
          search_parameter.entity_name === filter.entity &&
          search_parameter.field_name === filter.field_name
        );
      });

      if (index === -1) {
        index = search_parameters.length;
        search_parameter = {
          entity_name: filter.entity,
          field_name: filter.field_name,
          attributes: {
            operator: getSearchParameterOperator(filter.filter_type),
            value,
            filter_type: filter.filter_type,
          },
        };
      } else {
        search_parameter = search_parameters[index];
      }

      return {
        ...state,
        data: {
          ...state.data,
          search_parameters: [
            ...search_parameters.slice(0, index),
            {
              ...search_parameter,
              attributes: {
                ...search_parameter.attributes,
                value,
              },
            },
            ...search_parameters.slice(index + 1),
          ],
        },
      };
    }

    case TYPES.GET_QUERY_DATA_SUCCESS: {
      return {
        ...state,
        data: action.data.data,
        fetchStatus: { value: 'LOADED' },
      };
    }

    case TYPES.GET_QUERIES_REQUEST:
      return {
        ...state,
        getViewsFetchStatus: { value: 'LOADING' },
      };

    case TYPES.GET_QUERIES_SUCCESS:
      return {
        ...state,
        getViewsFetchStatus: { value: 'LOADED' },
        views: action.data,
      };

    case TYPES.SAVE_QUERY_REQUEST:
      return {
        ...state,
        saveQueryFetchStatus: { value: 'LOADING' },
      };

    case TYPES.SAVE_QUERY_SUCCESS:
      return {
        ...state,
        saveQueryFetchStatus: { value: 'LOADED' },
        views: [action.data, ...state.views],
      };

    default:
      return state;
  }
};
