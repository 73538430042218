//Apartment, Industrial, Office, Retail, Hotel, Dev Site, Seniors Housing & Care, Other
import geojsonBbox from 'geojson-bounding-box';

export const mainPropertyTypeSelections = [
  { value: 'Apartment', name: 'Apartment' },
  { value: 'Industrial', name: 'Industrial' },
  { value: 'Office', name: 'Office' },
  { value: 'Retail', name: 'Retail' },
  { value: 'Hotel', name: 'Hotel' },
  { value: 'Dev Site', name: 'Dev Site' },
  { value: 'Seniors Housing & Care', name: 'Seniors Housing & Care' },
  { value: 'Other', name: 'Other' },
];

const convertDate = (date) => {
  const dateParts = date.split('/');

  return `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`;
};

export const getRcaVariables = ({
  squareFeetRange,
  salePriceRange,
  yearBuiltRange,
  statusDateRange,
  pricePerSquareFootUnitRange,
  selectedMainPropertyTypes,
}) => {
  const rcaVariables = {};
  // SQUARE FEET
  squareFeetRange.min ? (rcaVariables.min_sq_ft = squareFeetRange.min) : null;
  squareFeetRange.max ? (rcaVariables.max_sq_ft = squareFeetRange.max) : null;
  // SALES PRICE
  salePriceRange.min ? (rcaVariables.min_price = salePriceRange.min) : null;
  salePriceRange.max ? (rcaVariables.max_price = salePriceRange.max) : null;
  // YEAR BUILT
  yearBuiltRange.min
    ? (rcaVariables.min_year_built = yearBuiltRange.min)
    : null;
  yearBuiltRange.max
    ? (rcaVariables.max_year_built = yearBuiltRange.max)
    : null;
  // STATUS DATE
  statusDateRange.min
    ? (rcaVariables.min_status_date = convertDate(statusDateRange.min))
    : null;
  statusDateRange.max
    ? (rcaVariables.max_status_date = convertDate(statusDateRange.max))
    : null;
  // PRICE PER SQUARE FOOT UNIT RANGE
  pricePerSquareFootUnitRange.min
    ? (rcaVariables.min_price_per_sq_ft_unit = pricePerSquareFootUnitRange.min)
    : null;
  pricePerSquareFootUnitRange.max
    ? (rcaVariables.max_price_per_sq_ft_unit = pricePerSquareFootUnitRange.max)
    : null;
  // MAIN PROPERTY TYPE
  selectedMainPropertyTypes.length
    ? (selectedMainPropertyTypes.main_property_types =
        selectedMainPropertyTypes)
    : null;

  return rcaVariables;
};

const mainPropertyTypeToPropertyUseCodeMapped = {
  Apartment: 22,
  Office: 24,
  Residential: 39,
  Industrial: 14,
  Retail: 45,
  Hotel: 0,
  'Dev Site': 0,
  'Seniors Housing & Care': 0,
  Other: 0,
};

export const getRcaCompsList = (rcaData) => {
  return rcaData
    ? rcaData.map((data) => {
        const property_address =
          data?.cherre_address__address?.[0]
            ?.tax_assessor__property_address?.[0] || {};

        return {
          address: data.address,
          main_property_type: data.main_property_type,
          cherre_parcel_id: property_address.cherre_parcel_id,
          property_use_code_mapped:
            property_address.property_use_code_mapped ||
            mainPropertyTypeToPropertyUseCodeMapped[data.main_property_type],
          assessor_parcel_number_raw:
            property_address.assessor_parcel_number_raw,
          city: data.city,
          state: data.state,
          zip: data.zip,
          sq_ft: data.sq_ft,
          lot_size_sq_ft: property_address.lot_size_sq_ft,
          id: data.cherre_parcel_id,
          price: data.price,
          status_date: data.status_date,
          buyer_name_1: data.buyer_name_1,
          seller_name_1: data.seller_name_1,
          transaction_type: data.transaction_type,
          prior_sale_date: data.prior_sale_date,
          price_per_sq_ft_unit: data.price_per_sq_ft_unit,
          prior_sale_price_amount: data.prior_sale_price_amount,
          longitude: data.longitude,
          latitude: data.latitude,
          year_built: data.year_built,
          tax_assessor_id: property_address.tax_assessor_id,
        };
      })
    : [];
};

export const getYearSelectionRange = (rcaData) => {
  const yearBuiltSelectionRange = [];

  const minYearBuilt = rcaData
    ? Math.min(
        ...rcaData.map((data) => {
          return data.year_built !== null ? data.year_built : 1900;
        })
      )
    : 1900;

  const date = new Date();
  const currentYear = date.getFullYear();

  for (let i = minYearBuilt; i <= currentYear; i++) {
    yearBuiltSelectionRange.push({ value: i, name: i });
  }

  return yearBuiltSelectionRange;
};

const getFeaturePoint = (latLong) => {
  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: latLong,
    },
    properties: {},
  };
};

export const getCompsBounds = (compsLatLong) => {
  const compsFeaturePoints =
    compsLatLong && compsLatLong.length
      ? compsLatLong.map((latLong) => getFeaturePoint(latLong))
      : undefined;

  const bbox =
    compsFeaturePoints && compsFeaturePoints.length
      ? geojsonBbox({ type: 'FeatureCollection', features: compsFeaturePoints })
      : undefined;

  return bbox
    ? [
        [bbox[0], bbox[1]],
        [bbox[2], bbox[3]],
      ]
    : undefined;
};

export const createCherreParcelIdToLotData = (rcaCompsListGenerated = []) => {
  const cherreParcelIdToLotData = {};
  rcaCompsListGenerated.length
    ? rcaCompsListGenerated.forEach((rcaCompData) => {
        const { cherre_parcel_id } = rcaCompData;
        if (cherre_parcel_id) {
          cherreParcelIdToLotData[cherre_parcel_id] = rcaCompData;
        }
      })
    : null;

  return cherreParcelIdToLotData;
};

export const getMapLatLogId = (array) => {
  return array.map((data) => {
    return {
      id: data.cherre_parcel_id,
      longitude: data.longitude,
      latitude: data.latitude,
    };
  });
};
