import React from 'react';
import { startCase, toLower, uniqBy } from 'lodash';
import pluralize from 'pluralize';
import { makeStyles } from '@material-ui/core';
import Link from './Link';
import Title from './Title';
import RecordedOwner from './RecordedOwner';
import {
  INDIVIDUAL_OWNER_TYPES,
  TYPE_INDIVIDUAL,
} from 'src/products/core-prospect/pages/lot-page/panels/PropertyOwners/common';

const useStyles = makeStyles({
  root: {
    marginBottom: 40,
  },
});

const getType = (type) => {
  if (!type) {
    return;
  }

  if (INDIVIDUAL_OWNER_TYPES.includes(type)) {
    return 'Individual';
  } else {
    return startCase(toLower(type));
  }
};

const RecordedOwners = ({ tax_assessor }) => {
  const classes = useStyles();
  const unmaskedOwnersCount =
    tax_assessor.usa_owner_unmask_v2__tax_assessor_id_aggregate?.aggregate
      ?.count;

  const recordedOwners = uniqBy(
    tax_assessor.tax_assessor_owner_v2__tax_assessor_id || [],
    (owner) => owner.owner_name
  );

  const hasIndividualRecordedOwners = recordedOwners.some((recordedOwner) => {
    return (getType(recordedOwner.owner_type) || '')
      .toLowerCase()
      .match(TYPE_INDIVIDUAL.toLowerCase());
  });

  return (
    <div className={classes.root}>
      <Title>Recorded Owners</Title>
      {recordedOwners.map((owner, index) => {
        return (
          <RecordedOwner
            key={index}
            name={owner.owner_name}
            type={getType(owner.owner_type)}
          />
        );
      })}
      {!hasIndividualRecordedOwners && unmaskedOwnersCount > 0 && (
        <Link
          to={`/properties/${tax_assessor.cherre_parcel_id}/${tax_assessor.tax_assessor_id}`}
        >
          {unmaskedOwnersCount} Unmasked{' '}
          {pluralize('owner', unmaskedOwnersCount)}
        </Link>
      )}
    </div>
  );
};

export default RecordedOwners;
