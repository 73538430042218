import { GQL, gql } from 'src/utils/recoil/gql';
import * as T from './__generated__/getOwners';

const query = gql`
  query getOwners($ownerIds: [String!]) {
    usa_owner_unmask_v2(where: { owner_id: { _in: $ownerIds } }) {
      owner_id
      cherre_address__address {
        display_address
      }
      owner_name
      tax_assessor: tax_assessor_v2__tax_assessor_id {
        recorder: recorder_v2_summary_v2__tax_assessor_id {
          cherre_latest_deed_date
          cherre_latest_deed_amount
        }
        address
        city
        state
        zip
        property_use_code_mapped
        property_group_type
        lot_size_sq_ft
        cherre_parcel_id
        latitude
        longitude
        tax_assessor_id
      }
    }
  }
` as GQL<T.GetOwnersQuery, T.GetOwnersQueryVariables>;

export default query;
