import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CompanyDetailsTopMarkets from './CompanyDetailsTopMarkets';

const CompanyMarketsData = ({ cities }) => {
  const propertyCountArray = cities.map((city) => city.propertyCount);
  const maxValue = Math.max(...propertyCountArray);

  return (
    <Card style={{ height: 500, display: 'flex', flexDirection: 'column' }}>
      <CardHeader
        title='Markets'
        subheader='Breakdown of properties by market'
      />
      <CardContent
        style={{
          paddingTop: 0,
          paddingBottom: 16,
          height: '100%',
          overflow: 'auto',
        }}
      >
        <Grid container spacing={1}>
          <Grid container item xs={12} spacing={2}>
            <CompanyDetailsTopMarkets cities={cities} maxValue={maxValue} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CompanyMarketsData;
