import React from 'react';
import { useSelector } from 'react-redux';

import { getPropertyLists as getPropertyListsSelector } from 'src/products/core-prospect/search-pages/selectors';

import Lists from './Lists';
import Title from '../../components/Title';

const PropertyListsView = () => {
  const { propertyLists } = useSelector((state) => ({
    propertyLists: getPropertyListsSelector(state),
  }));

  return (
    <>
      <Title
        title='Property lists'
        id='js-core-prospect-property-lists-view-title'
      />
      <div style={{ margin: '0 20px' }}>
        <Lists propertyLists={propertyLists} />
      </div>
    </>
  );
};

export default PropertyListsView;
