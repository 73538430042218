import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import BuildingLeaseCompIcon from 'src/products/core-prospect/search-pages/components/SearchMap/EqtLeaseCompsPopup/icons/BuildingLeaseCompIcon';
import ExternalLeaseCompIcon from 'src/products/core-prospect/search-pages/components/SearchMap/EqtLeaseCompsPopup/icons/ExternalLeaseCompIcon';
import InternalLeaseCompIcon from 'src/products/core-prospect/search-pages/components/SearchMap/EqtLeaseCompsPopup/icons/InternalLeaseCompIcon';

const useStyles = makeStyles({
  root: {
    background: '#fff',
    padding: '1em',
    borderRadius: 5,
    boxShadow: '0 2px 5px 0 #00000033',
    alignSelf: 'flex-end',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 5,
  },
  icon: {
    height: 19,
    width: 15,
    marginRight: 5,
  },
});

const LeaseCompsLegend: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography className={classes.item}>
        <BuildingLeaseCompIcon className={classes.icon} /> EQT Building
      </Typography>
      <Typography className={classes.item}>
        <InternalLeaseCompIcon className={classes.icon} /> Internal Leases
      </Typography>
      <Typography className={classes.item}>
        <ExternalLeaseCompIcon className={classes.icon} /> External Leases
      </Typography>
    </div>
  );
};

export default LeaseCompsLegend;
