import { dollars, allDefined, longDate, number } from '../../../utils';
import {
  TaxAssessorToSummaryFormatter,
  NYCDetailsToSummaryFormatter,
  PropertyUseToSummaryFormatter,
} from '../types';
import { cherrePropertyUseHierarchical } from 'src/const/lookups';

export const taxAssessorToSummary: TaxAssessorToSummaryFormatter = (data) => {
  const tax_assessor = data.tax_assessor_v2[0];

  const centroid =
    tax_assessor.parcel_boundary_v2__tax_assessor_id?.parcel_centroid_geometry;

  const [longitude, latitude] = centroid?.coordinates ?? [];

  const lookup_type = cherrePropertyUseHierarchical.find((lookup) => {
    return lookup.lookup_code === tax_assessor.property_use_code_mapped;
  });
  const propertyType = lookup_type ? lookup_type.lookup_value : undefined;

  const lotSizeSqft = number(tax_assessor.lot_size_sq_ft, 'sqft');

  const recorderSummary = tax_assessor.recorder_summary?.[0];

  const amount = recorderSummary?.cherre_latest_deed_amount ?? undefined;
  const date = recorderSummary?.cherre_latest_deed_date ?? undefined;

  const lastSale = allDefined`${dollars(amount)} (${longDate(date)})`;

  const owner =
    tax_assessor.tax_assessor_owner_v2__tax_assessor_id?.[0]?.owner_name ||
    undefined;

  return {
    longitude,
    latitude,
    propertyType,
    lotSizeSqft,
    lastSale,
    owner,
  };
};

export const nycDetailsToSummary: NYCDetailsToSummaryFormatter = (data) => {
  const acris = data?.acris_master?.[0];

  const amount = acris?.document_amount ?? undefined;
  const date = acris?.recorded_date ?? undefined;
  const lastSale = allDefined`${dollars(amount)} (${longDate(date)})`;

  const owner = data?.nyc_lot?.[0]?.owner_name || undefined;

  return { lastSale, owner };
};

export const propertyUseCodeMappedToSummary: PropertyUseToSummaryFormatter = (
  data
) => {
  return { propertyType: data || undefined };
};

export const propertyUseStdCodeToSummary: PropertyUseToSummaryFormatter = (
  data
) => {
  return { propertySubtype: data || undefined };
};
