import * as TYPES from './types';

export const entityName = 'tax_assessor';
export const idFieldName = 'tax_assessor_id';

export const uncheckSelectAllCheckbox = () => {
  return {
    type: TYPES.UNCHECK_SELECT_ALL_ROWS,
  };
};

export const clearSelectedRows = () => {
  return {
    type: TYPES.CLEAR_SELECTED_ROWS,
  };
};

export const selectAllRows = (selected, rowKey) => {
  return {
    type: TYPES.SELECT_ALL_ROWS,
    selected,
    rowKey,
  };
};

export const selectRow = (row, rowKey) => {
  return {
    type: TYPES.SELECT_ROWS,
    row,
    rowKey,
  };
};

export const changeOffset = (offset) => {
  return {
    type: TYPES.CHANGE_OFFSET,
    offset,
  };
};

export const changeRowsPerPage = (rowsPerPage) => {
  return {
    type: TYPES.CHANGE_ROWS_PER_PAGE,
    rowsPerPage,
  };
};

export const changeOrderBy = (orderBy) => {
  return (dispatch) => {
    return dispatch({
      type: TYPES.CHANGE_ORDER_BY,
      orderBy,
    });
  };
};

export const csvWasExported = (remainingAttemps) => {
  return {
    type: TYPES.CSV_WAS_EXPORTED,
    remainingAttemps,
  };
};

export const csvExportFailed = (reason) => {
  return {
    type: TYPES.CSV_EXPORT_FAILED,
    reason,
  };
};
