import React, { useMemo } from 'react';
import { RootState, useDispatch, useSelector } from 'react-redux';
import PagedTable from 'src/components/PagedTable';
import MUILinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

const LinearProgress = withStyles({
  root: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
  },
})(MUILinearProgress);

import query from './queries/getOwners';

import {
  changeRowsPerPage,
  changeOffset,
  changeOrderBy,
} from '../../../redux/owners/actions';
import { RowData } from './types';
import AlphaAlert from './components/AlphaAlert';
import { getColumns, getRows, getBasicRows } from './utils';
import { execTableOwnersQuery } from 'src/products/core-prospect/redux/query/actions/execTableOwnersQuery';
import { constSelector, useRecoilValueLoadable } from 'recoil';

const OwnersTableView: React.FC = () => {
  const dispatch = useDispatch();

  const { owners, offset, rowsPerPage, orderBy, count, fetchStatus } =
    useSelector((state: RootState) => {
      return {
        fetchStatus: state.coreProspect.owners.fetchStatus.value,
        count: state.coreProspect.owners.count,
        owners: state.coreProspect.owners.results,
        offset: state.coreProspect.owners.offset,
        rowsPerPage: state.coreProspect.owners.rowsPerPage,
        orderBy: state.coreProspect.owners.orderBy,
      };
    });

  const page = offset / rowsPerPage;

  const result = useRecoilValueLoadable(
    owners.length
      ? query({
          ownerIds: owners.map((o) => o.ownerId),
        })
      : constSelector({ usa_owner_unmask_v2: [] })
  );

  const loading = result.state === 'loading';
  const usa_owner_unmask = result.valueMaybe()?.usa_owner_unmask_v2 || [];

  const rows = useMemo(() => {
    return !loading ? getRows(owners, usa_owner_unmask) : [];
  }, [usa_owner_unmask]);

  const columns = useMemo<RowData[]>(() => {
    return getColumns();
  }, []);

  return (
    <>
      <AlphaAlert />
      {fetchStatus === 'LOADING' && <LinearProgress />}
      <PagedTable
        stickyLeft={1}
        selection={false}
        isAllRowsSelected={false}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(event) => {
          dispatch(changeRowsPerPage(parseInt(event.target.value, 10)));
          dispatch(
            execTableOwnersQuery({
              isCount: false,
              isExport: false,
              paginate: true,
              viewportOnly: true,
            })
          );
        }}
        totalCount={count}
        page={page}
        onSelect={() => {}}
        onSelectAllChange={() => {}}
        onChangePage={({ index }) => {
          dispatch(changeOffset(index * rowsPerPage));
          dispatch(
            execTableOwnersQuery({
              isCount: false,
              isExport: false,
              paginate: true,
              viewportOnly: true,
            })
          );
          return;
        }}
        hasNextPage={(page + 1) * rowsPerPage < count}
        hasPreviousPage={page > 0}
        orderBy={orderBy.field}
        orderByDirection={orderBy.direction}
        onChangeOrder={async (orderBy) => {
          await dispatch(changeOrderBy(orderBy));
          dispatch(
            execTableOwnersQuery({
              isCount: false,
              isExport: false,
              paginate: true,
              viewportOnly: true,
            })
          );
        }}
        columns={columns}
        items={!loading ? rows : getBasicRows(owners)}
        isLoading={fetchStatus !== 'LOADED'}
        emptyText=''
        rowKey='ownerId'
      />
    </>
  );
};

export default OwnersTableView;
