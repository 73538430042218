import React from 'react';
import Typography from '@material-ui/core/Typography';

const TextBold = ({ children, display, variant, style }) => {
  return (
    <Typography
      display={display || 'inline'}
      variant={variant || 'body1'}
      style={{ fontWeight: 700, ...style }}
    >
      {children}
    </Typography>
  );
};

export default TextBold;
