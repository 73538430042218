import { FeatureCollection } from '@turf/helpers';

type Parcel = {
  latitude: number;
  longitude: number;
};

const getParcelsPointsFeatureCollection = (
  parcels: Parcel[]
): FeatureCollection => {
  return {
    type: 'FeatureCollection',
    features: parcels
      .filter((parcel) => parcel.latitude && parcel.longitude)
      .map((parcel) => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [parcel.longitude, parcel.latitude],
        },
        properties: {},
      })),
  };
};

export default getParcelsPointsFeatureCollection;
