import { Typography } from '@material-ui/core';
import React from 'react';
import { MultiColumnRow } from '../../components';
import * as T from './types';
import * as C from './components';
import useStyles from './styles';
import { panels } from '../../constants';

const Taxes: T.Taxes = ({
  currentTax,
  totalAssessedValue,
  totalMarketValue,
  jurisdiction,
  assessedTaxYear,
  assessedImprovements,
  assessedLandValue,
  marketValueYear,
  marketImprovements,
  marketLandValue,
}) => {
  const classes = useStyles();
  return (
    <div id={panels[2].id} className={classes.container}>
      <Typography variant='h4' style={{ marginBottom: '25px' }}>
        Taxes
      </Typography>
      <MultiColumnRow>
        <div>
          <Typography variant='body1' style={{ marginBottom: '5px' }}>
            Current Tax
          </Typography>
          <Typography
            variant='h3'
            className='suspend'
            style={{ marginBottom: '10px' }}
          >
            {currentTax}
          </Typography>
          <C.KeyValue label='Jurisdiction' value={jurisdiction} />
        </div>
        <div>
          <Typography variant='body1' style={{ marginBottom: '5px' }}>
            Total Assessed Value
          </Typography>
          <Typography
            variant='h3'
            className='suspend'
            style={{ marginBottom: '10px' }}
          >
            {totalAssessedValue}
          </Typography>
          <C.KeyValue label='Assessed Tax Year' value={assessedTaxYear} />
          <C.KeyValue
            label='Assessed Improvements'
            value={assessedImprovements}
          />
          <C.KeyValue label='Assessed Land Value' value={assessedLandValue} />
        </div>
        <div>
          <Typography variant='body1' style={{ marginBottom: '5px' }}>
            Total Market Value
          </Typography>
          <Typography
            variant='h3'
            className='suspend'
            style={{ marginBottom: '10px' }}
          >
            {totalMarketValue}
          </Typography>
          <C.KeyValue label='Market Value Year' value={marketValueYear} />
          <C.KeyValue label='Market Improvements' value={marketImprovements} />
          <C.KeyValue label='Market Land Value' value={marketLandValue} />
        </div>
      </MultiColumnRow>
    </div>
  );
};

Taxes.displayName = 'Taxes';

export default Taxes;
