import { Polygon } from '@nebula.gl/edit-modes';
import { Feature } from '@turf/helpers';
import { MultiPolygon } from '@turf/turf';
import { isEmpty, memoize } from 'lodash';
import { RootState } from 'react-redux';
import {
  CONDO_PROPERTY_USE_CODE_MAPPED,
  COOPERATIVE_PROPERTY_USE_CODE_MAPPED,
  RESIDENTIAL_PROPERTY_USE_CODE_MAPPED,
} from 'src/products/core-prospect/constants';
import {
  FilterType,
  SearchEntity,
  SearchOperator,
  SearchParameter,
  TaxAssessorSearchField,
  UsaMapParcelSearchField,
} from '../../types/SearchParameter';

// FILTER CONFIG END

export const isSearchParameterEmpty = (
  attributes: SearchParameter['attributes']
): boolean => {
  if (attributes.value === null) {
    return true;
  }

  if (attributes.value === undefined) {
    return true;
  }

  if (attributes.filter_type === FilterType.hierarchical) {
    if (attributes.value?.checked) {
      return (
        Object.values(attributes.value?.checked).filter(Boolean).length === 0
      );
    }
  }

  if (attributes.filter_type === FilterType.boolean) {
    return false;
  }

  if (attributes.filter_type === FilterType.rangeDate) {
    return isEmpty(attributes.value);
  }

  if (attributes.filter_type === FilterType.rangeInput) {
    return isEmpty(attributes.value);
  }

  if (attributes.filter_type === FilterType.recordedOwnerType) {
    return isEmpty(attributes.value);
  }

  if (attributes.filter_type === FilterType.unmaskedOwnerType) {
    return isEmpty(attributes.value);
  }

  if (attributes.filter_type === FilterType.textInput) {
    return attributes.value === '';
  }

  return false;
};

export const getPropertyTypeSearchParameter = (
  searchParameters: SearchParameter[] = []
): SearchParameter | undefined => {
  const searchParameter = searchParameters.find(
    (searchParameter) =>
      searchParameter.entity_name === SearchEntity.taxAssessor &&
      searchParameter.field_name === TaxAssessorSearchField.propertyType
  );

  if (!searchParameter) {
    return;
  }

  if (isSearchParameterEmpty(searchParameter.attributes)) {
    return;
  }

  return searchParameter;
};

// Condo filter is not available only when resedential property types are in search creteria
export const isCondoFilterAvailable = (
  searchParameters: SearchParameter[]
): boolean => {
  const searchParameter = getPropertyTypeSearchParameter(searchParameters);

  if (!searchParameter) {
    return true;
  }

  if (searchParameter?.attributes.filter_type === FilterType.hierarchical) {
    const selectedTypes = Object.entries(
      searchParameter?.attributes?.value?.checked || {}
    )
      .map(([key, value]) => {
        if (value) {
          return key;
        }
      })
      .filter(Boolean);

    if (selectedTypes.length === 0) {
      return true;
    }

    const isResidentialTypeSelected = selectedTypes.some((type) => {
      return RESIDENTIAL_PROPERTY_USE_CODE_MAPPED.includes(Number(type));
    });

    if (isResidentialTypeSelected) {
      return true;
    }
  }

  return false;
};

export const patchByCondoUnitsSearchParameter = (
  state: RootState,
  searchParameters: SearchParameter[]
): SearchParameter[] => {
  if (
    isCondoFilterAvailable(searchParameters) &&
    !state.coreProspect.query.areCondoUnitsIncluded &&
    // Don't exclude condo units if searching for specific records.
    !searchParameters.some(
      (searchParameter) =>
        searchParameter.entity_name === SearchEntity.taxAssessor &&
        searchParameter.field_name === TaxAssessorSearchField.taxAssessorId
    )
  ) {
    return [
      ...searchParameters,
      {
        entity_name: SearchEntity.taxAssessor,
        field_name: TaxAssessorSearchField.propertyType,
        attributes: {
          filter_type: FilterType.hierarchical,
          operator: SearchOperator.in,
          type: 'exclude',
          value: {
            indeterminate: {},
            expanded: {},
            checked: {
              [CONDO_PROPERTY_USE_CODE_MAPPED]: true,
              [COOPERATIVE_PROPERTY_USE_CODE_MAPPED]: true,
            },
          },
        },
      },
      {
        entity_name: SearchEntity.taxAssessor,
        field_name: TaxAssessorSearchField.unitNumber,
        attributes: {
          filter_type: FilterType.existsFilter,
          operator: SearchOperator.exists,
          type: 'exclude',
          value: undefined,
        },
      },
    ];
  }
  return searchParameters;
};

export const getViewerName = (state: RootState) => {
  return state.coreProspect.view.viewerName;
};

export const getSearchParametersFeature = (
  state: RootState
): Feature<MultiPolygon> | undefined => {
  const search_parameters = getSearchParameters(state);

  const area_search_parameters = search_parameters.filter(
    (search_parameter) => {
      return (
        search_parameter.field_name === UsaMapParcelSearchField.customSearchArea
      );
    }
  );

  if (area_search_parameters.length === 0) {
    return;
  }

  const features = area_search_parameters
    .map((search_parameter) => search_parameter.attributes.value)
    .filter(Boolean) as Feature<Polygon | MultiPolygon>[];

  if (features.length === 0) {
    return;
  }

  const geometries = features
    .map((feature) => feature.geometry)
    .filter(Boolean);

  // Combine all features into single multipolygon. Elastic search is not
  // working properly with FeatureCollection
  return {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'MultiPolygon',
      coordinates: geometries.reduce((coordinates, geometry) => {
        if (geometry.type === 'Polygon') {
          coordinates = [...coordinates, geometry.coordinates];
        }

        if (geometry.type === 'MultiPolygon') {
          coordinates = [...coordinates, ...geometry.coordinates];
        }

        return coordinates;
      }, [] as MultiPolygon['coordinates']),
    },
  };
};

const filterSearchParameters = memoize((search_parameters: SearchParameter[]) =>
  search_parameters.filter(
    (search_parameter) => !isSearchParameterEmpty(search_parameter.attributes)
  )
);

export const getSearchParameters = (state: RootState): SearchParameter[] => {
  const search_parameters = (state.coreProspect.query.data?.search_parameters ||
    []) as SearchParameter[];

  const filtered_search_parameters = filterSearchParameters(search_parameters);
  return filtered_search_parameters;
};

export const hasSearchParameters = (state: RootState) =>
  getSearchParameters(state).length > 0;

export const getOrderBy = (state: RootState) => {
  return state.coreProspect.table.orderBy || [];
};

export const getView = (state: RootState) => {
  return state.coreProspect.query.data || [];
};

export const getViews = (state: RootState) =>
  (state.coreProspect.query.views || []).filter((view) => !view.view.deleted);

export const getPropertyLists = (state: RootState) =>
  (state.coreProspect.propertyLists.propertyLists.value || []).filter(
    (propertyList) => !propertyList.deleted
  );

export const getSaveSearchFetchStatus = (state: RootState) =>
  state.coreProspect.query.saveQueryFetchStatus.value;

export const getSaveSearchDialogState = (state: RootState) =>
  state.coreProspect.view.isSaveSearchDialogOpen;
