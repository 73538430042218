import React from 'react';

const CompanyDetailsTopMarketsBar = ({ value, maxValue }) => {
  const relativeValue =
    value && maxValue ? Math.round((value / maxValue) * 100) : null;

  return (
    <div
      style={{
        width: relativeValue + 12,
        height: 12,
        background: '#ff6294',
        borderRadius: 6,
      }}
    />
  );
};

export default CompanyDetailsTopMarketsBar;
