import React from 'react';
import Print from '@material-ui/icons/Print';
import { Box, Fade, IconButton, Tooltip, Typography } from '@material-ui/core';
import * as C from './components';
import useStyles from './styles';
import * as T from './types';
import { PRODUCTS } from 'src/const';
import moment from 'moment';
import { useIsSuspended } from 'src/utils/suspense-companion/createSuspenseComponent';
import { DealStageChip } from '../../components';
import { useRecoilValue } from 'recoil';
import { headerDealStageVisibleAtom } from './recoil';
import { DashboardIcon } from '@cherre-frontend/ui';
import { useDashboardPropertyLink } from 'src/hooks/useDashboardPropertyLink';

const LiveHeader: T.Header = ({
  address,
  cityStateZip,
  selectUnitAction,
  tax_assessor_id,
  landLotBBL,
  user,
  deal,
  cherre_parcel_id,
}) => {
  const classes = useStyles();
  const isSuspended = useIsSuspended();

  const dashboardPropertyLink = useDashboardPropertyLink(
    cherre_parcel_id || ''
  );

  const isHeaderDealStageVisible = useRecoilValue(headerDealStageVisibleAtom);
  return (
    <div id='lot-page-header' className={classes.liveContainer}>
      <div className='address'>
        <div className={classes.addresssDeal}>
          <Typography
            id='lot-page-header-address'
            className='suspend'
            variant='h3'
          >
            {address}
          </Typography>
          <C.UnitSelector selectUnitAction={selectUnitAction} concurrent />
          {deal && (
            <Fade in={isHeaderDealStageVisible}>
              <Box>
                <DealStageChip name={deal.stage} />
              </Box>
            </Fade>
          )}
        </div>
        <Typography
          id='lot-page-header-citystatezip'
          className='suspend'
          variant='h5'
        >
          {cityStateZip}
        </Typography>
      </div>
      {!isSuspended && (
        <div className='actions'>
          {!deal && tax_assessor_id && (
            <C.AddDealButton taxAssessorId={tax_assessor_id} />
          )}
          <C.FeedbackIcon />
          {dashboardPropertyLink && (
            <Tooltip title='Go to Dashboard'>
              <IconButton
                id='lot-page-dashboard-button'
                size='small'
                onClick={() => window.open(dashboardPropertyLink, '_blank')}
              >
                <DashboardIcon color='primary' />
              </IconButton>
            </Tooltip>
          )}
          {tax_assessor_id && (
            <C.PropertyList
              property_id={`${tax_assessor_id}`}
              disabled={landLotBBL}
              property_code_type={landLotBBL ? 'bbl' : 'tax_assessor_id'}
            />
          )}
          {user?.featureFlags?.cp_pdfExport && (
            <Tooltip title='Print'>
              <IconButton
                id='lot-page-print-button'
                size='small'
                onClick={print}
              >
                <Print />
              </IconButton>
            </Tooltip>
          )}
          <div style={{ gridColumn: '1 / -1' }}>
            <C.PropertyChips limit={2} />
          </div>
        </div>
      )}
    </div>
  );
};

const PrintHeader: T.Header = ({ address, cityStateZip }) => {
  const classes = useStyles();
  return (
    <div className={classes.container + ' print-only'}>
      <div className={classes.headerContainer}>
        <div className={classes.title}>
          <Typography variant='h2' color='primary'>
            {PRODUCTS.EXPLORE}
          </Typography>
          <Typography className={classes.date} variant='h5'>
            Property Report from {moment().format('ll')}
          </Typography>
        </div>
        <div>
          <img className={classes.logo} src='/assets/logo-2.png' />
        </div>
      </div>
      <div className={classes.delimiter} />
      <div>
        <div className={classes.addressWrapper}>
          <Typography
            id='js-core-prospect-address-1'
            className={classes.address}
            variant='h3'
            display='inline'
          >
            {address}
          </Typography>
        </div>
        <Typography id='js-core-prospect-address-2' variant='h5'>
          {cityStateZip}
        </Typography>
      </div>
    </div>
  );
};

const Header: T.Header = (props) => {
  return (
    <>
      <PrintHeader {...props} />
      <LiveHeader {...props} />
    </>
  );
};

Header.displayName = 'Header';

export default Header;
