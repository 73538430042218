import pluralize from 'pluralize';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  addPropertyListEntry,
  createPropertyList,
  removePropertyListEntry,
} from '../redux/propertyLists/actions';
import { usePropertyListEntries } from './usePropertyListEntries';
import { usePropertyLists } from './usePropertyLists';

export const usePropertyListPopover = (
  property_id: string,
  property_code_type: string,
  enabled = true,
  where?: string
) => {
  const dispatch = useDispatch();

  const { propertyLists } = usePropertyLists();
  const { propertyListEntries } = usePropertyListEntries(
    property_id,
    property_code_type,
    enabled
  );

  const handleItemSelect = useCallback(
    (item) => {
      dispatch(
        addPropertyListEntry({
          list_id: Number(item.value),
          property_code_type,
          property_id,
          where,
        })
      );
    },
    [property_code_type, property_id]
  );

  const handleItemDeselect = useCallback(
    (item) => {
      if (!propertyListEntries) {
        return;
      }

      const entry = propertyListEntries.find(
        (entry) => String(entry.list_id) === item.value
      );

      if (!entry) {
        return;
      }

      dispatch(removePropertyListEntry({ id: entry.id }));
    },
    [propertyListEntries]
  );

  const handleCreate = useCallback(async (label: string) => {
    const list = await dispatch(createPropertyList(label));

    dispatch(
      addPropertyListEntry({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: For some reason the typescript isn't able to get the types from the dispatch, since we are deprecating redux I'm going to ignore this error.
        list_id: list.id,
        property_code_type,
        property_id: property_id,
      })
    );
  }, []);

  const items = (propertyLists || []).map((list) => ({
    label: list.name,
    value: String(list.id),
    secondary: pluralize('Property', list.entries_count, true),
  }));

  const selected = (propertyLists || [])
    .filter((list) => {
      return (propertyListEntries || []).some(
        (entry) => entry.list_id === list.id
      );
    })
    .map((list) => ({
      label: list.name,
      value: String(list.id),
    }));

  return {
    handleItemSelect,
    handleItemDeselect,
    handleCreate,
    items,
    selected,
  };
};
