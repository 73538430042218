import { Maybe } from 'graphql/jsutils/Maybe';
import { cherrePropertyUseHierarchical } from 'src/const/lookups';
import { getPropertySubtitle } from 'src/products/core-prospect/utils/getPropertySubtitle';
import { DataFormatter } from './DataFormatter';

type Data = {
  address: Maybe<string>;
  zone_code: Maybe<string>;
  cherre_parcel_id: Maybe<string>;
  tax_assessor_id: Maybe<number>;
  units_count: Maybe<number>;
  year_built: Maybe<string>;
  building_sq_ft: Maybe<string>;
  lot_size_acre: Maybe<string>;
  property_use_code_mapped: Maybe<number>;
  recorder_summary: Maybe<
    Array<{
      cherre_latest_deed_date: Maybe<string>;
      cherre_latest_deed_amount: Maybe<number>;
    }>
  >;
};

export const usaElasticDataFormatter = (data: Partial<Data>): DataFormatter => {
  const recorder = data.recorder_summary?.[0];

  const buildingSize = data.building_sq_ft;

  const yearBuilt = data.year_built;

  const unitsCount = data.units_count;

  const lastSaleDate = recorder?.cherre_latest_deed_date;

  const lastSaleAmount = recorder?.cherre_latest_deed_amount;

  const type = (() => {
    const propertyUseCode = data.property_use_code_mapped;

    const lookup = cherrePropertyUseHierarchical.find(
      (lookup) => lookup.lookup_code === propertyUseCode
    );

    return lookup?.lookup_value;
  })();

  return {
    parcelId: data.cherre_parcel_id || '',
    taxAssessorId: data.tax_assessor_id,
    address: data.address,
    subTitle: getPropertySubtitle({
      propertyUseCodeMapped: data.property_use_code_mapped,
      type: type,
      buildingSize: Number(buildingSize),
      lotSizeAcre: Number(data.lot_size_acre),
      unitsCount: Number(unitsCount),
      yearBuilt: Number(yearBuilt),
      zoneCode: data.zone_code,
    }),
    saleAmount: lastSaleAmount,
    saleDate: lastSaleDate,
  };
};
