import {
  createMuiTheme,
  MuiThemeProvider,
  Theme,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';
import React from 'react';
import { Dispatch } from 'redux';
import {
  FilterType,
  SearchEntity,
  SearchOperator,
  SearchParameter,
  UsaMapParcelSearchField,
} from 'src/products/core-prospect/types/SearchParameter';
import { requestGraphqlData } from 'src/products/shell/redux/actions/graphql-query';

import {
  AutocompleteSuggestion,
  AutocompleteSuggestionGroup,
} from './recoil/selectors';

type ShowErrorAlert = {
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject
  ) => SnackbarKey;
  theme: Theme;
  isCompact: boolean;
  text: string;
};

export const showError = ({
  enqueueSnackbar,
  theme,
  isCompact,
  text,
}: ShowErrorAlert) => {
  return enqueueSnackbar(
    <MuiThemeProvider theme={createMuiTheme(theme)}>
      <Typography>{text}</Typography>
    </MuiThemeProvider>,
    {
      variant: 'error',
      autoHideDuration: 5000,
      anchorOrigin: {
        horizontal: isCompact ? 'center' : 'right',
        vertical: isCompact ? 'bottom' : 'top',
      },
    }
  );
};

const taxAssessorQueryByLocation = (): string => /* GraphQL */ `
  query taxAssessorQueryByLocation($latitude: float8!, $longitude: float8!) {
    tax_assessor_point_v2(
      args: { latitude: $latitude, longitude: $longitude }
    ) {
      cherre_parcel_id
      cherre_is_deleted
    }
  }
`;

export const getCherreParcelIdsByLatLong = async ({
  dispatch,
  latitude,
  longitude,
}: {
  dispatch: any;
  latitude: number;
  longitude: number;
}): Promise<string[]> => {
  const { tax_assessor_point_v2 } = await dispatch(
    requestGraphqlData(taxAssessorQueryByLocation(), [], undefined, {
      latitude,
      longitude,
    })
  );

  return tax_assessor_point_v2
    .sort((a, b) => {
      if (a.cherre_is_deleted && !b.cherre_is_deleted) {
        return 1;
      }
      if (!a.cherre_is_deleted && b.cherre_is_deleted) {
        return -1;
      }
      return 0;
    })
    .map((point) => point.cherre_parcel_id)
    .filter(Boolean);
};

const taxAssessorQueryByAddress = (): string => /* GraphQL */ `
  query taxAssessorQueryByAddress($address: String!) {
    address_data: address(address: $address) {
      cherre_address__address {
        tax_assessor_v2__property_address {
          cherre_parcel_id
          cherre_is_deleted
        }
      }
    }
  }
`;

export const getCherreParcelIdsByAddress = async ({
  dispatch,
  address,
}: {
  dispatch: any;
  address: string;
}): Promise<string[]> => {
  const { address_data } = await dispatch(
    requestGraphqlData(taxAssessorQueryByAddress(), [], undefined, {
      address,
    })
  );

  if (
    !address_data?.cherre_address__address?.tax_assessor_v2__property_address
  ) {
    return [];
  }

  return address_data.cherre_address__address.tax_assessor_v2__property_address
    .sort((a, b) => {
      if (a.cherre_is_deleted && !b.cherre_is_deleted) {
        return 1;
      }
      if (!a.cherre_is_deleted && b.cherre_is_deleted) {
        return -1;
      }
      return 0;
    })
    .map((point) => point.cherre_parcel_id)
    .filter(Boolean);
};

const citySuggestionToSearchParameters = async (
  suggestion: AutocompleteSuggestion | null
): Promise<Array<SearchParameter>> => {
  if (!suggestion) {
    return [];
  }

  const url = '/api/v1/autocomplete/suggestion-data';
  const suggestionDataResponse = await axios.post(url, {
    id: suggestion.id,
    groupName: suggestion.group_name,
  });

  const suggestionData = suggestionDataResponse.data;

  const stateSearchParameter: SearchParameter = {
    entity_name: SearchEntity.usaMapParcel,
    field_name: UsaMapParcelSearchField.state,
    attributes: {
      filter_type: FilterType.textInput,
      operator: SearchOperator.equals,
      value: suggestionData.state,
      displayText: suggestionData.city_state,
      isAutocomplete: true,
    },
  };

  const citySearchParameter: SearchParameter = {
    entity_name: SearchEntity.usaMapParcel,
    field_name: UsaMapParcelSearchField.city,
    attributes: {
      filter_type: FilterType.textInput,
      operator: SearchOperator.equals,
      value: suggestionData.city,
      displayText: suggestionData.city_state,
      isAutocomplete: true,
    },
  };

  return [citySearchParameter, stateSearchParameter];
};

export const geocode = async (address: string) => {
  const url = '/api/v1/geocode';

  const geocodedAddress = await axios.get<{
    address: string;
    latitude: number;
    longitude: number;
  }>(url, {
    params: { address },
  });

  return geocodedAddress.data;
};

const addressSuggestionToSearchParameters = async (
  suggestion: AutocompleteSuggestion | null,
  dispatch: Dispatch
): Promise<Array<SearchParameter | null>> => {
  if (!suggestion) {
    return [];
  }

  let cherre_parcel_ids = await getCherreParcelIdsByLatLong({
    dispatch,
    latitude: suggestion.data.latitude,
    longitude: suggestion.data.longitude,
  });

  if (cherre_parcel_ids.length === 0) {
    //backoff to address search
    cherre_parcel_ids = await getCherreParcelIdsByAddress({
      dispatch,
      address: suggestion.text,
    });

    if (cherre_parcel_ids.length === 0) {
      throw new Error('Property information unavailable.');
    }
  }

  return [
    {
      entity_name: SearchEntity.usaMapParcel,
      field_name: UsaMapParcelSearchField.parcelId,
      attributes: {
        filter_type: FilterType.customFilter,
        operator: SearchOperator.in,
        value: cherre_parcel_ids.slice(0, 1) || [],
        displayText: suggestion.text,
        isAutocomplete: true,
      },
    },
  ];
};

const streetAndIntersectionSuggestionToSearchParameters = async (
  suggestion: AutocompleteSuggestion | null
): Promise<Array<SearchParameter | null> | null> => {
  if (!suggestion) {
    return [];
  }

  return null;
};

const geoSuggestionToSearchParameters = async (
  suggestion?: AutocompleteSuggestion | null
): Promise<Array<SearchParameter | null>> => {
  if (!suggestion) {
    return [];
  }

  const isSubmarket =
    suggestion.group_name === AutocompleteSuggestionGroup.REISMarket;

  const url = '/api/v1/autocomplete/suggestion-data';
  const suggestionDataResponse = await axios.post(url, {
    id: suggestion.id,
    groupName: suggestion.group_name,
  });

  const suggestionData = suggestionDataResponse.data;

  if (!suggestionData.geometry) {
    throw new Error(`This ${suggestion.group_name} is unavailable.`);
  }

  return [
    {
      entity_name: SearchEntity.usaMapParcel,
      field_name: UsaMapParcelSearchField.customSearchArea,
      attributes: {
        ...suggestionData,
        filter_type: FilterType.customSearchArea,
        value: {
          geometry: suggestionData.geometry,
          properties: {},
          type: 'Feature',
        },
        displayText: suggestion.text,
        isAutocomplete: true,
        isSubmarket,
      },
    },
  ];
};

export const suggestionToSearchParameters = async (
  suggestion: AutocompleteSuggestion | null,
  dispatch: Dispatch<any>
): Promise<Array<SearchParameter | null> | null> => {
  if (!suggestion) {
    return [];
  }

  if (suggestion.group_name === AutocompleteSuggestionGroup.Address) {
    return addressSuggestionToSearchParameters(suggestion, dispatch);
  }

  if (
    [
      AutocompleteSuggestionGroup.Street,
      AutocompleteSuggestionGroup.Intersection,
    ].includes(suggestion.group_name)
  ) {
    return streetAndIntersectionSuggestionToSearchParameters(suggestion);
  }

  if (suggestion.group_name === AutocompleteSuggestionGroup.City) {
    return citySuggestionToSearchParameters(suggestion);
  }

  return geoSuggestionToSearchParameters(suggestion);
};

type RedirectQuery = {
  isPopupOpen?: boolean;
  sidebar: string;
  cherre_parcel_id?: string;
  latitude?: number;
  longitude?: number;
};

export const getRedirectQuery = (
  suggestion: AutocompleteSuggestion | null,
  searchParameter: SearchParameter | null
): RedirectQuery | undefined => {
  if (!suggestion) {
    return;
  }

  if (suggestion.group_name === AutocompleteSuggestionGroup.Address) {
    return {
      isPopupOpen: true,
      sidebar: 'sidebar-lots-list',
      cherre_parcel_id: searchParameter?.attributes?.value?.[0],
      latitude: suggestion.data.latitude,
      longitude: suggestion.data.longitude,
    };
  } else if (
    [
      AutocompleteSuggestionGroup.Street,
      AutocompleteSuggestionGroup.Intersection,
    ].includes(suggestion.group_name)
  ) {
    return {
      sidebar: 'sidebar-lots-list',
      latitude: suggestion.data.latitude,
      longitude: suggestion.data.longitude,
    };
  } else {
    return { sidebar: 'filters' };
  }
};

export const getDefaultInputValue = (
  searchParameters: SearchParameter[],
  hasFeature: boolean
) => {
  const searchParameter = searchParameters.find((searchParameter) => {
    return searchParameter.attributes.isAutocomplete;
  });

  return (
    searchParameter?.attributes.displayText || (hasFeature ? 'Map Area' : '')
  );
};
