export const SOURCES = {
  USA_MAP_PARCELS: {
    name: 'usaparcelpolygons',
    type: 'vector',
    url: 'mapbox://sbcherre.usa-parcels-v2_25031718153e9',
  },
  MAP_CLUSTERS: {
    name: 'map-clusters',
    type: 'geojson',
  },
  REIS_BOUNDARIES: 'reisBoundaries',
  EQT_LEASE_COMPS_POINTS: {
    sourceLayerName: 'eqt-lease-comps-points',
    name: 'eqt-lease-comps-points',
    type: 'vector',
    url: 'mapbox://sbcherre.eqt-lease-comps-v_221128195828',
  },
  NEIGHBORHOODS_ND: {
    name: 'neighborhoods-nd-v_250206183862d',
    boundariesLayer: 'neighborhoodsnd',
    labelLayer: 'neighborhoodsndlabels',
  },
};

export const LABELS_FONT = ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'];

export const LAYERS = {
  EQT_LEASE_COMPS_POINTS_INTERNAL: 'eqt-lease-comps-points-internal',
  EQT_LEASE_COMPS_POINTS_BUILDING: 'eqt-lease-comps-points-building',
  EQT_LEASE_COMPS_POINTS_EXTERNAL: 'eqt-lease-comps-points-external',
  EQT_LEASE_COMPS_HOVERED_POINTS_INTERNAL:
    'eqt-lease-comps-hovered-points-internal',
  EQT_LEASE_COMPS_HOVERED_POINTS_BUILDING:
    'eqt-lease-comps-hovered-points-building',
  EQT_LEASE_COMPS_HOVERED_POINTS_EXTERNAL:
    'eqt-lease-comps-hovered-points-external',
  EQT_LEASE_COMPS_SELECTED_POINTS_INTERNAL:
    'eqt-lease-comps-selected-points-internal',
  EQT_LEASE_COMPS_SELECTED_POINTS_BUILDING:
    'eqt-lease-comps-selected-points-building',
  EQT_LEASE_COMPS_SELECTED_POINTS_EXTERNAL:
    'eqt-lease-comps-selected-points-external',
  DEALS_ON_MAP: 'deals-on-map',
  DEALS_ON_MAP_HOVERED: 'hovered-deals-on-map',
  DEALS_ON_MAP_SELECTED: 'selected-deals-on-map',
  COLORPLETH_LINE: 'colorpleth-line',
  COLORPLETH: 'colorpleth',
  COUNTIES_LINE: 'counties-line',
  COUNTIES_FILL: 'counties-fill',
  SELECTED_COUNTIES_LINE: 'selected-counties-line',
  SELECTED_COUNTIES_FILL: 'selected-counties-fill',
  COUNTIES_LABEL: 'states-label',
  STATES_LINE: 'states-line',
  STATES_FILL: 'states-fill',
  SELECTED_STATES_LINE: 'selected-states-line',
  SELECTED_STATES_FILL: 'selected-states-fill',
  STATES_LABEL: 'states-label',
  NEIGHBORHOODS_RS_LINE: 'neighborhoods-rs-line',
  NEIGHBORHOODS_MT_LINE: 'neighborhoods-mt-line',
  NEIGHBORHOODS_ND_LINE: `${SOURCES.NEIGHBORHOODS_ND.name}-line`,
  NEIGHBORHOODS_RS_FILL: 'neighborhoods-rs-fill',
  NEIGHBORHOODS_MT_FILL: 'neighborhoods-mt-fill',
  NEIGHBORHOODS_ND_FILL: `${SOURCES.NEIGHBORHOODS_ND.name}-fill`,
  NEIGHBORHOODS_RS_LABELS: 'neighborhoods-rs-labels',
  NEIGHBORHOODS_MT_LABELS: 'neighborhoods-mt-labels',
  NEIGHBORHOODS_ND_LABELS: `${SOURCES.NEIGHBORHOODS_ND.name}-labels`,
  SCHOOLS_DISTRICTS_LINE: 'schools-districts-line',
  SCHOOLS_DISTRICTS_FILL: 'schools-districts-fill',
  SAZ_LABELS: 'saz-labels',
  CLUSTER: 'parcel-point',
  CLUSTER_TEXT: 'parcel-text',
  PARCELS_LINE: 'parcels-line',
  PARCELS_FILL: 'parcels-fill',
  SELECTED_PARCELS_LINE: 'selected-parcels-line',
  SELECTED_PARCELS_FILL: 'selected-parcels-fill',
  ACTIVE_PARCELS_LINE: 'active-parcels-line',
  ACTIVE_PARCELS_FILL: 'active-parcels-fill',
  ZIPCODES_LINE: 'zipcodes-line',
  ZIPCODES_FILL: 'zipcodes-fill',
  SELECTED_ZIPCODES_LINE: 'selected-zipcodes-line',
  SELECTED_ZIPCODES_FILL: 'selected-zipcodes-fill',
  ZIPCODES_LABEL: 'zipcodes-label',
  REIS_BOUNDARIES_APARTMENT_LINES: 'reis-boundaries-apartment-lines',
  REIS_BOUNDARIES_APARTMENT_LABELS: 'reis-boundaries-apartment-labels',
  REIS_BOUNDARIES_FLEX_LINES: 'reis-boundaries-flex-lines',
  REIS_BOUNDARIES_FLEX_LABELS: 'reis-boundaries-flex-labels',
  REIS_BOUNDARIES_OFFICE_LINES: 'reis-boundaries-office-lines',
  REIS_BOUNDARIES_OFFICE_LABELS: 'reis-boundaries-office-labels',
  REIS_BOUNDARIES_RETAIL_LINES: 'reis-boundaries-retail-lines',
  REIS_BOUNDARIES_RETAIL_LABELS: 'reis-boundaries-retail-labels',
  REIS_BOUNDARIES_WAREHOUSE_LINES: 'reis-boundaries-warehouse-lines',
  REIS_BOUNDARIES_WAREHOUSE_LABELS: 'reis-boundaries-warehouse-labels',
  CUSTOM_FEATURE_COLLECTION_LINES: 'custom-feature-collection-line',
};

export const ORDERED_LAYERS_IDS = [
  LAYERS.COUNTIES_LINE,
  LAYERS.COUNTIES_FILL,
  LAYERS.NEIGHBORHOODS_RS_LINE,
  LAYERS.NEIGHBORHOODS_MT_LINE,
  LAYERS.NEIGHBORHOODS_ND_LINE,
  LAYERS.NEIGHBORHOODS_RS_FILL,
  LAYERS.NEIGHBORHOODS_MT_FILL,
  LAYERS.NEIGHBORHOODS_ND_FILL,
  LAYERS.SCHOOLS_DISTRICTS_LINE,
  LAYERS.SCHOOLS_DISTRICTS_FILL,
  LAYERS.PARCELS_LINE,
  LAYERS.PARCELS_FILL,
  LAYERS.SELECTED_PARCELS_LINE,
  LAYERS.SELECTED_PARCELS_FILL,
  LAYERS.ACTIVE_PARCELS_LINE,
  LAYERS.ACTIVE_PARCELS_FILL,
  LAYERS.ZIPCODES_LINE,
  LAYERS.ZIPCODES_FILL,

  LAYERS.SELECTED_COUNTIES_LINE,
  LAYERS.SELECTED_COUNTIES_FILL,
  LAYERS.SELECTED_NEIGHBORHOODS_RS_LINE,
  LAYERS.SELECTED_NEIGHBORHOODS_MT_LINE,
  LAYERS.SELECTED_NEIGHBORHOODS_ND_LINE,
  LAYERS.SELECTED_NEIGHBORHOODS_RS_FILL,
  LAYERS.SELECTED_NEIGHBORHOODS_MT_FILL,
  LAYERS.SELECTED_NEIGHBORHOODS_ND_FILL,
  LAYERS.SELECTED_PARCELS_LINE,
  LAYERS.SELECTED_PARCELS_FILL,
  LAYERS.SELECTED_ZIPCODES_LINE,
  LAYERS.SELECTED_ZIPCODES_FILL,

  LAYERS.NEIGHBORHOODS_RS_LABELS,
  LAYERS.NEIGHBORHOODS_MT_LABELS,
  LAYERS.NEIGHBORHOODS_ND_LABELS,
  LAYERS.ZIPCODES_LABEL,
  LAYERS.SAZ_LABELS,
  LAYERS.COUNTIES_LABEL,
  LAYERS.COLORPLETH,
  LAYERS.COLORPLETH_LINE,
  LAYERS.CUSTOM_FEATURE_COLLECTION_LINES,
  LAYERS.CLUSTER,
  LAYERS.CLUSTER_TEXT,
  LAYERS.DEALS_ON_MAP,
  LAYERS.DEALS_ON_MAP_HOVERED,
  LAYERS.DEALS_ON_MAP_SELECTED,
  LAYERS.EQT_LEASE_COMPS_POINTS_BUILDING,
  LAYERS.EQT_LEASE_COMPS_POINTS_INTERNAL,
  LAYERS.EQT_LEASE_COMPS_POINTS_EXTERNAL,
  LAYERS.EQT_LEASE_COMPS_HOVERED_POINTS_INTERNAL,
  LAYERS.EQT_LEASE_COMPS_HOVERED_POINTS_BUILDING,
  LAYERS.EQT_LEASE_COMPS_HOVERED_POINTS_EXTERNAL,
  LAYERS.EQT_LEASE_COMPS_SELECTED_POINTS_INTERNAL,
  LAYERS.EQT_LEASE_COMPS_SELECTED_POINTS_BUILDING,
  LAYERS.EQT_LEASE_COMPS_SELECTED_POINTS_EXTERNAL,
];
