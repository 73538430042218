import {
  createMuiTheme,
  MuiThemeProvider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';

export const useSnackbarCherre = () => {
  const { enqueueSnackbar: enqueueSnackbarDefault, closeSnackbar } =
    useSnackbar();

  const theme = useTheme();

  const isCompact = useMediaQuery(theme.breakpoints.down('md'));

  const enqueueSnackbar = useCallback<typeof enqueueSnackbarDefault>(
    (message, options) => {
      return enqueueSnackbarDefault(
        <MuiThemeProvider theme={createMuiTheme(theme)}>
          <Typography>{message}</Typography>
        </MuiThemeProvider>,
        {
          autoHideDuration: 2000,
          anchorOrigin: {
            horizontal: isCompact ? 'center' : 'right',
            vertical: isCompact ? 'bottom' : 'top',
          },
          ...options,
        }
      );
    },
    [enqueueSnackbarDefault, isCompact]
  );

  return {
    enqueueSnackbar,
    closeSnackbar,
  };
};
