import React from 'react';
import {
  Card,
  CardHeader,
  Typography,
  CardActions,
  CardContent,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import * as FILTER_TYPES from 'src/components/filters/const/filter-types';

const useStyles = makeStyles(() => ({
  card: {
    borderRadius: 10,
    background: '#ffffff',
    position: 'relative',
    overflow: 'hidden',
    boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.2)',
    width: 330,
  },
  footer: {
    padding: 16,
  },
}));

//frontend/src/products/core-prospect/search-pages/components/Sidebar/Views/FiltersView/CoreProspectFilters/MultipleSelection/component.js

const filters = {
  [FILTER_TYPES.RANGE_INPUT]:
    require('src/components/filters/RangeInput/component').default,
  [FILTER_TYPES.RANGE_DATE]:
    require('src/components/filters/RangeDate/component').default,
  [FILTER_TYPES.MULTIPLE_SELECTION]:
    require('src/products/core-prospect/search-pages/components/Sidebar/Views/FiltersView/CoreProspectFilters/MultipleSelection/component')
      .default,
  [FILTER_TYPES.RANGE_SELECTION]:
    require('src/components/filters/RangeSelection/component').default,
};

export function getFilterComponent(filter) {
  const filterType = Object.prototype.hasOwnProperty.call(filter, 'filter_type')
    ? filter.filter_type
    : filter;
  return filters[filterType].component;
}

const RCAMapFilterPopover = (props) => {
  const { chipFilterData, clearFilter } = props;
  const { setUpdateSearch, updateSearch, handleClose } = props;
  const FilterComponent = filters[chipFilterData.filterType];
  const classes = useStyles();

  return (
    <Card id={`rca-filter-popover`} className={classes.card}>
      <CardHeader
        id={`rca-filter-popover-title`}
        title={
          <Typography style={{ fontSize: 18 }} variant='body1'>
            {chipFilterData.title}
          </Typography>
        }
      />
      <CardContent>
        <FilterComponent
          filter={{ filter_type_props: {} }}
          filterValue={chipFilterData.value}
          setFilterValue={chipFilterData.setValue}
          data={{ options: chipFilterData.selectionRange }}
        />
      </CardContent>
      <CardActions>
        <Button
          style={{
            textTransform: 'Capitalize',
            marginRight: 4,
            backgroundColor: '#FFFFFF',
          }}
          variant='text'
          color='primary'
          onClick={() => {
            handleClose();
            setUpdateSearch(updateSearch + 1);
            //chipFilterData.setValue({ min: '', max: '' });
            clearFilter(chipFilterData.filterField);
            //setTimeout(() => setUpdateSearch(updateSearch + 1), 500);
          }}
        >
          Clear
        </Button>
        <Button
          style={{
            textTransform: 'Capitalize',
            marginRight: 4,
            marginLeft: 'auto',
          }}
          variant={'contained'}
          color='primary'
          onClick={() => {
            handleClose();
            setUpdateSearch();
          }}
        >
          Done
        </Button>
      </CardActions>
    </Card>
  );
};

export default RCAMapFilterPopover;
