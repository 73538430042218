import * as TYPES from './types';
import * as FILTER_TYPES from 'src/components/filters/const/filter-types';

import {
  getSearchParameters,
  getView,
  getSearchParametersFeature,
} from 'src/products/core-prospect/search-pages/selectors';
import { getIntersectionFeature, getFeatureFromMapBounds } from './utils';
import { debounce } from 'lodash';
import { ExecQuery, execQuery } from './actions/execQuery';
import { Feature, MultiPolygon } from '@turf/helpers';
import { RootState } from 'react-redux';
import { geoSearchFields } from '../../constants';

export const getFeature = ({ state, viewportOnly }) => {
  const {
    coreProspect: {
      map: { currentViewport },
    },
  } = state;

  const searchFeature = getSearchParametersFeature(
    state
  ) as Feature<MultiPolygon>;

  if (!viewportOnly) {
    return searchFeature;
  }

  if (!searchFeature) {
    return getFeatureFromMapBounds(currentViewport.bounds);
  }

  const intersectionFeature = getIntersectionFeature(
    searchFeature,
    currentViewport.bounds
  );

  return intersectionFeature || null;
};

export const saveQuery = (name) => {
  return (dispatch, getState) => {
    const state: RootState = getState();
    const viewData = getView(state);
    const search_parameters = getSearchParameters(state);
    const { currentViewport } = state.coreProspect.map;

    return dispatch({
      url: '/savedViews',
      method: 'post',
      send: {
        ...viewData,
        search_parameters,
        currentViewport,
        view: {
          limit: 1000,
          entity_name: viewData.view.entity_name,
          viewer: 'USA Lot Search By User',
          share_level_id: 1,
          name,
        },
      },
      types: [
        TYPES.SAVE_QUERY_REQUEST,
        TYPES.SAVE_QUERY_SUCCESS,
        TYPES.SAVE_QUERY_FAILED,
      ],
    });
  };
};

export const getViews = () => {
  return (dispatch, getState) => {
    const {
      coreProspect: {
        query: {
          getViewsFetchStatus: { value: fetchStatus },
        },
      },
    } = getState();

    if (fetchStatus) {
      return;
    }

    dispatch({
      url: '/search/views/by-viewer',
      query: {
        viewerName: 'USA Lot Search By User',
        include: 'search_parameters',
      },
      types: [
        TYPES.GET_QUERIES_REQUEST,
        TYPES.GET_QUERIES_SUCCESS,
        TYPES.GET_QUERIES_FAILED,
      ],
    });
  };
};

export const undoDeleteView = (viewId) => {
  return {
    url: `/search/views/${viewId}/soft-delete-undo`,
    method: 'delete',
    types: [
      TYPES.UNDO_DELETE_QUERIES_REQUEST,
      TYPES.UNDO_DELETE_QUERIES_SUCCESS,
      TYPES.UNDO_DELETE_QUERIES_FAILED,
    ],
    dataToDispatch: { id: viewId },
  };
};

export const deleteView = (viewId) => {
  return {
    url: `/search/views/${viewId}/soft-delete`,
    method: 'delete',
    types: [
      TYPES.DELETE_QUERIES_REQUEST,
      TYPES.DELETE_QUERIES_SUCCESS,
      TYPES.DELETE_QUERIES_FAILED,
    ],
    dataToDispatch: { id: viewId },
  };
};

export const getQuerySettings = (viewId) => {
  return (dispatch) => {
    return dispatch({
      url: `/search/views/${viewId}`,
      types: [
        TYPES.GET_QUERY_DATA_REQUEST,
        TYPES.GET_QUERY_DATA_SUCCESS,
        TYPES.GET_QUERY_DATA_FAILED,
      ],
    });
  };
};

const getExecQueryParams = (options: Partial<ExecQuery>): ExecQuery => {
  return Object.assign(
    {
      id: options?.id,
      cards: true,
      count: true,
      results: true,
      viewportOnly: true,
      rebound: false,
    },
    options || {}
  );
};

const debouncedExecQuery = debounce((dispatch, options: Partial<ExecQuery>) => {
  dispatch(execQuery(getExecQueryParams(options)));
}, 1000);

const immediateExecQuery = (dispatch: any, options: Partial<ExecQuery>) => {
  dispatch(execQuery(getExecQueryParams(options)));
};

const AVOID_QUERY_ON_TYPES = [
  FILTER_TYPES.RANGE_DATE,
  FILTER_TYPES.RANGE_INPUT,
  FILTER_TYPES.TEXT_INPUT,
];

export const changeSearchParameter =
  (filter, value, { immediate = false } = {}) =>
  (dispatch) => {
    dispatch({
      type: TYPES.CHANGE_SEARCH_PARAMETER,
      filter,
      value,
    });
    if (!AVOID_QUERY_ON_TYPES.includes(filter.filter_type)) {
      if (immediate) {
        immediateExecQuery(dispatch, {
          id: 'changeSearchParameters',
        });
      } else {
        debouncedExecQuery(dispatch, {
          id: 'changeSearchParameters',
        });
      }
    }
  };

export const resetSearchParameters =
  (
    search_parameters,
    options?: Partial<ExecQuery> & {
      immediate?: boolean;
      id?: string;
      skipQuery?: boolean;
    }
  ) =>
  (dispatch) => {
    dispatch({
      type: TYPES.RESET_SEARCH_PARAMETERS,
      search_parameters,
    });

    if (options?.skipQuery) {
      return;
    }

    const geoSearchParameters = search_parameters.filter((searchParameter) => {
      return geoSearchFields.includes(searchParameter.field_name);
    });

    if (geoSearchParameters.length) {
      window.analytics?.track('Geo Filter Applied', {
        filters: geoSearchParameters,
      });
    }

    const has_custom_search_area = search_parameters.some(
      (sp) => sp.field_name === 'custom_search_area'
    );

    if (options?.immediate) {
      immediateExecQuery(
        dispatch,
        Object.assign(
          {
            viewportOnly: !has_custom_search_area,
            rebound: has_custom_search_area,
          },
          options || {}
        )
      );
    } else {
      debouncedExecQuery(
        dispatch,
        Object.assign(
          {
            viewportOnly: !has_custom_search_area,
            rebound: has_custom_search_area,
          },
          options || {}
        )
      );
    }
  };

export const includeCondoUnits = (areCondoUnitsIncluded) => {
  return {
    type: TYPES.INCLUDE_CONDO_UNITS,
    areCondoUnitsIncluded,
  };
};

export const enableDealsFilteres = (areDealsFiltersEnabled: boolean) => {
  return {
    type: TYPES.ENABLE_DEALS_FILTERS,
    areDealsFiltersEnabled,
  };
};

export const setSearchFeature = (searchFeature) => {
  return {
    type: TYPES.SET_SEARCH_FEATURE,
    searchFeature,
  };
};
