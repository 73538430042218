import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import EditOutlined from '@material-ui/icons/EditOutlined';
import Info from '@material-ui/icons/Info';
import {
  CircularProgress,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core';
import ButtonProgress from 'src/components/ButtonProgress';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { useLocalDeal } from 'src/services/Dealcloud/localDeal';
import { useShowModal } from 'src/hooks/useShowModal';

const useStyles = makeStyles({
  root: {
    padding: 10,
    height: 60,
    display: 'grid',
    gridTemplateColumns: '1fr auto auto auto',
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    color: '#039BE5',
  },
  icon: {
    marginRight: 10,
  },
  titleText: {
    lineHeight: 1.3,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    color: '#039BE5',
  },
  split: {
    display: 'flex',
    alignItems: 'center',
  },
  cancel: {
    marginRight: 10,
  },
});

type Props = {
  onEditClick: () => void;
  loading: boolean;
};

export const Topbar: React.VFC<Props> = ({ onEditClick, loading }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { id: dealId } = useParams<{ id: string }>();
  const [isDealSubmitting, setIsDealSubmitting] = useState(false);
  const showConfirmDialog = useShowModal('CONFIRM');

  const { createDeal, editDeal, deal } = useLocalDeal(
    dealId !== 'new' ? Number(dealId) : 'new'
  );

  const dispatch = useDispatch();

  const onClickCancel = () => {
    showConfirmDialog({
      title: 'Cancel Portfolio Deal',
      message: (
        <>
          <Typography>
            Are you sure you want to cancel{' '}
            {dealId === 'new' ? 'creating' : 'editing'} the Portfolio Deal?
          </Typography>
          <Typography>All progress will be lost.</Typography>
        </>
      ),
      okText: 'Continue',
      cancelText: 'Close',
      onClickOk: async () => {
        dispatch(push('/properties'));
      },
    });
  };

  return (
    <div className={classes.root}>
      {isDealSubmitting ? (
        <div
          style={{
            zIndex: 1,
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: 'rgba(255, 255, 255, 0.8)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </div>
      ) : null}
      <div className={classes.title}>
        <Info className={classes.icon} />
        <Typography className={classes.titleText}>
          Build your portfolio property list by searching for a<br /> property
          and adding it to the Portfolio Deal.
        </Typography>
      </div>
      <div className={classes.actions}>
        <ButtonProgress
          color='primary'
          spinnerStyle={{ color: theme.palette.primary.main }}
          loading={loading}
          onClick={onEditClick}
          startIcon={<EditOutlined style={{ fontSize: 15 }} />}
        >
          edit description
        </ButtonProgress>
        &nbsp;
        <div className={classes.split} />
      </div>
      {dealId === 'new' ? (
        <div>
          <Button
            className={classes.cancel}
            color='primary'
            variant='outlined'
            onClick={onClickCancel}
          >
            cancel
          </Button>
        </div>
      ) : null}
      <div>
        <ButtonProgress
          loading={isDealSubmitting}
          onClick={async () => {
            setIsDealSubmitting(true);
            try {
              dealId === 'new' ? await createDeal() : await editDeal();
            } catch (e) {
              console.log(e);
            } finally {
              setIsDealSubmitting(false);
            }
          }}
          color='primary'
          variant='contained'
          disabled={(deal?.assets.length || 0) < 2}
        >
          {dealId === 'new' ? 'Create' : 'Save'}
        </ButtonProgress>
      </div>
    </div>
  );
};
