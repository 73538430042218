import { dealStageImageMap } from 'src/products/core-prospect/constants';
import { LAYERS } from '../const';
import { handleClick } from './utils';
import { OnLayerClick } from '../types';
import { LayerProps } from 'src/components/Map/types';
import { memoize } from 'lodash';
import { DealStageImage, DealStageType } from 'src/services/Dealcloud/types';

const getImageFor = (
  stage: DealStageType
): [['==', ['get', 'stage'], DealStageType], string] => {
  return [['==', ['get', 'stage'], stage], dealStageImageMap[stage]];
};

const dealLayerClick = memoize(
  (onClick: DealsLayersOptions['onClick'], layerName: string) => {
    return (e) => {
      handleClick(e, layerName, onClick);
    };
  }
);

export type DealsLayersOptions = {
  onClick?: OnLayerClick;
  selectedParcelId?: string;
};

export const getDealsLayers = (
  hoveredDeals: string[] = [],
  selectedDeals: string[] = []
): LayerProps[] => [
  {
    id: LAYERS.DEALS_ON_MAP,
    type: 'symbol',
    source: 'deals',
    paint: {},
    layout: {
      'icon-anchor': 'bottom',
      'icon-allow-overlap': true,
      'icon-size': 1,
      'icon-image': [
        'case',
        ...getImageFor('With IC'),
        ...getImageFor('Closed'),
        ...getImageFor('In Due Diligence'),
        ...getImageFor('Offered'),
        ...getImageFor('Underwriting'),
        ...getImageFor('With IC'),
        'Deal Pin S1',
      ],
    },
    filter: [
      '!',
      [
        'in',
        ['get', 'cherreParcelId'],
        ['literal', [...hoveredDeals, ...selectedDeals]],
      ],
    ],
  },
];

export const getHoveredDealsLayers = (
  hoveredDeals: string[] = [],
  onClick?: OnLayerClick
): LayerProps[] => [
  {
    id: LAYERS.DEALS_ON_MAP_HOVERED,
    type: 'symbol',
    onClick: onClick
      ? dealLayerClick(onClick, LAYERS.DEALS_ON_MAP_HOVERED)
      : undefined,
    source: 'deals',
    paint: {},
    layout: {
      'icon-anchor': 'bottom',
      'icon-allow-overlap': true,
      'icon-size': 1,
      'icon-image': 'DealPin-Hover' as DealStageImage,
    },
    filter: ['in', ['get', 'cherreParcelId'], ['literal', hoveredDeals]],
  },
];

export const geSelectedDealsLayers = (
  selectedParcelIds: string[] = []
): LayerProps[] => [
  {
    id: LAYERS.DEALS_ON_MAP_SELECTED,
    type: 'symbol',
    source: 'deals',
    paint: {},
    layout: {
      'icon-anchor': 'bottom',
      'icon-allow-overlap': true,
      'icon-size': 1,
      'icon-image': 'DealPin-Focus' as DealStageImage,
    },
    filter: ['in', ['get', 'cherreParcelId'], ['literal', selectedParcelIds]],
  },
];
