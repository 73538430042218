import intersect from '@turf/intersect';
import { Feature, MultiPolygon, Polygon, polygon } from '@turf/helpers';
import { MapBounds } from 'src/components/Map/index.new';
import { SearchEntity, SearchParameter } from '../../types/SearchParameter';
import { geoSearchFields } from '../../constants';
import { RootState } from 'react-redux';

export const removeGeoSearchParameters = (
  searchParameters: SearchParameter[],
  options?: { isProtected?: (sp: SearchParameter) => boolean }
) => {
  const isProtected = options?.isProtected;

  return searchParameters.filter((searchParameter) => {
    if (isProtected?.(searchParameter)) {
      return true;
    }

    if (searchParameter.entity_name !== SearchEntity.usaMapParcel) {
      return true;
    }

    return !geoSearchFields.includes(searchParameter.field_name);
  });
};

export const removeAutocompleteSearchParameters = (
  searchParameters: SearchParameter[]
): SearchParameter[] =>
  searchParameters.filter(
    (searchParameter) => !searchParameter.attributes.isAutocomplete
  );

export const getFeatureFromMapBounds = (
  bounds: MapBounds
): Feature<Polygon> | undefined => {
  if (!bounds) {
    return;
  }

  return polygon([
    [
      [bounds[0][0], bounds[0][1]],
      [bounds[1][0], bounds[0][1]],
      [bounds[1][0], bounds[1][1]],
      [bounds[0][0], bounds[1][1]],
      [bounds[0][0], bounds[0][1]],
    ],
  ]);
};

export const getIntersectionFeature = (
  feature: Feature<Polygon | MultiPolygon>,
  bounds: MapBounds
): Feature<Polygon | MultiPolygon> | undefined => {
  if (!feature && !bounds) {
    return;
  }

  if (!feature) {
    return getFeatureFromMapBounds(bounds);
  }

  if (!bounds) {
    return feature;
  }

  const viewportFeature = getFeatureFromMapBounds(bounds);

  if (!viewportFeature) {
    return;
  }

  const intersectionFeature = intersect(feature, viewportFeature);

  if (!intersectionFeature) {
    return;
  }

  return intersectionFeature;
};

export const getAreDealsFiltersEnabled = (
  listId: string | undefined,
  state: RootState
) => {
  return !listId && state.coreProspect.query.areDealsFiltersEnabled;
};
