import React from 'react';
import grey from '@material-ui/core/colors/grey';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#fff',
    boxShadow: '0 2px 5px 0 #00000033',
    borderRadius: 5,
    height: '100%',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: grey[100],
      color: theme.palette.primary.light,
    },
  },
  text: {
    textTransform: 'capitalize',
    display: 'flex',
    justifyContent: 'spacte-between',
    alignItems: 'center',
  },
}));

const LayersButton = ({ onClick, ...rest }) => {
  const classes = useStyles();

  return (
    <Button
      data-testid='map-layer-selector-button'
      id='map-layer-selector'
      className={classes.root}
      onClick={onClick}
      {...rest}
    >
      <Typography className={classes.text} variant='button'>
        <i style={{ fontSize: 18 }} className='icon icon-Map-layers' />
        <span style={{ marginLeft: 10 }}>Map Layers</span>
      </Typography>
    </Button>
  );
};

export default LayersButton;
