import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import useLocation from 'src/hooks/useLocation';

function useIsMapLoading() {
  const query = useLocation()?.query;
  const listId = query?.list_id as string;
  const savedSearchId = useParams<{ id: string }>()?.id;
  const from = query?.from;

  const [loading, setLoading] = useState(
    Boolean(listId) || Boolean(savedSearchId) || Boolean(from)
  );

  const execMapQueryFetchStatus = useSelector(
    (state) => state.coreProspect.query.execMapQueryFetchStatus.value
  );

  useEffect(() => {
    if (!loading) {
      return;
    }

    if (loading && execMapQueryFetchStatus === 'LOADED') {
      setLoading(false);
    }
  }, [execMapQueryFetchStatus, loading]);

  return loading;
}

export { useIsMapLoading };
