import React from 'react';
import { Link } from 'react-router-dom';

import Lot from 'src/products/core-prospect/search-pages/components/Sidebar/Views/SelectedLotView/Lot';
import numberWithCommas from 'src/utils/numberWithCommas';
import moment from 'moment';

export const LitsItemTaxAssessor = ({ tax_assessor, style }) => {
  const size =
    tax_assessor.lot_size_sq_ft &&
    `${numberWithCommas(tax_assessor.lot_size_sq_ft)} SQFT`;

  const amount =
    tax_assessor.last_sale_amount &&
    `$${numberWithCommas(tax_assessor.last_sale_amount)}`;

  const date =
    tax_assessor.last_sale_date &&
    moment(tax_assessor.last_sale_date).format('ll');

  const subtitle = [size, amount, date].filter(Boolean).join(' · ');

  const title = tax_assessor?.unit_number
    ? tax_assessor?.unit_number
    : `APN: ${tax_assessor?.assessor_parcel_number_raw}`;

  return (
    <div style={{ ...style }}>
      <Link
        target='_blank'
        style={{ color: 'inherit' }}
        to={encodeURI(
          `/properties/${tax_assessor.cherre_parcel_id}/${tax_assessor.tax_assessor_id} `
        )}
      >
        <Lot subtitle={subtitle} title={title} />
      </Link>
    </div>
  );
};

export default LitsItemTaxAssessor;
