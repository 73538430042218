import React, { useMemo } from 'react';
import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';

import { makeStyles, Theme } from '@material-ui/core';
import { Autocomplete as MUIAutocomplete } from '@material-ui/lab';

import useLocation from 'src/hooks/useLocation';
import { Context } from 'src/products/core-prospect/search-pages/USALot';

import PaperComponent from './PaperComponent';
import PopperComponent from './PopperComponent';

import {
  AutocompleteSuggestion,
  autocompletValueAtom,
  suggestionsSelector,
} from './recoil/selectors';

import renderInput from './renderInput';
import RenderOption from './renderOption.new';
import { useMeasure } from 'react-use';

const useStyles = makeStyles<Theme, { onAppsPage: boolean }>(() => ({
  option: { padding: 0 },
  listbox: {
    padding: 0,
    maxHeight: ({ onAppsPage }) => (onAppsPage ? 440.23 : 371),
  },
}));

interface AutocompleteComponentProps {
  latitude?: number;
  longitude?: number;
  onAutocompleteValueChange: (
    suggestion: AutocompleteSuggestion | null,
    source?: 'onInputChange' | 'onChange',
    id?: string
  ) => void;
  getDefaultInputValue?: () => string;
  customStyle?: React.CSSProperties;
}

export const AddressAutocomplete: React.FC<AutocompleteComponentProps> = ({
  latitude,
  longitude,
  onAutocompleteValueChange,
  getDefaultInputValue,
  customStyle,
}) => {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const defaultInputValue = getDefaultInputValue?.() || '';

  const onAppsPage = location?.pathname === '/apps';

  const classes = useStyles({ onAppsPage });

  const [inputValue, setInputValue] = React.useState(defaultInputValue);

  const [searchValue, setSearchValue] = React.useState(defaultInputValue);

  const [autocompleteValue] = useRecoilState(autocompletValueAtom);

  const setSearchValueDebounce = useMemo(
    () => debounce(setSearchValue, 350),
    []
  );

  const suggestionsLoadable = useRecoilValueLoadable(
    suggestionsSelector({
      text: searchValue,
      dontGroup: location.query.dontGroup,
      options: {
        origin: {
          lat: latitude || 0,
          lng: longitude || 0,
        },
      },
    })
  );

  const suggestions = suggestionsLoadable.valueMaybe() || [];
  const loading = suggestionsLoadable.state === 'loading';

  React.useEffect(() => {
    setInputValue(defaultInputValue);
    setSearchValue(defaultInputValue);
  }, [defaultInputValue]);

  const { isCompact } = React.useContext(Context) as { isCompact: boolean };

  const [inputRef, { width: inputWidth }] = useMeasure();

  return (
    <div style={{ padding: onAppsPage ? '17px 17px 0 0' : 15, ...customStyle }}>
      <MUIAutocomplete
        ref={inputRef}
        classes={{
          option: classes.option,
          listbox: classes.listbox,
        }}
        data-testid='autocomplete-input'
        id='js-core-prospect-autocomplete-input'
        noOptionsText='No matches found'
        inputValue={inputValue}
        value={autocompleteValue}
        autoHighlight
        loading={loading}
        forcePopupIcon={false}
        getOptionSelected={(option, value) => {
          return option.text === value.text;
        }}
        getOptionLabel={(option) => option.text}
        onInputChange={(e, val, reason) => {
          // reset autocomplete value on every change but not refetch
          onAutocompleteValueChange(
            null,
            'onInputChange',
            'Autocomplete: onInputChange'
          );
          if (reason === 'clear') {
            setInputValue('');
            setSearchValue('');
            return;
          }

          if (reason === 'reset' && !val) {
            return;
          }

          setInputValue(val);
          setSearchValueDebounce(val);
        }}
        onChange={async (e, suggestion) => {
          try {
            await onAutocompleteValueChange(
              suggestion,
              'onChange',
              'Autocomplete: onChange'
            );
          } catch (error) {
            let message;

            if (error instanceof Error) {
              message = error.message;
            } else {
              message = String(error);
            }

            enqueueSnackbar(message);
          }
        }}
        fullWidth
        options={suggestions}
        renderInput={(props) =>
          renderInput(
            { ...props },
            {
              label: 'Search address or location',
              location: location?.pathname,
            }
          )
        }
        renderOption={(option, { inputValue }) => (
          <RenderOption option={option} inputValue={inputValue} />
        )}
        PaperComponent={(props) => {
          return (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            <PaperComponent
              {...props}
              onAppsPage={onAppsPage}
              isCompact={isCompact}
            />
          );
        }}
        PopperComponent={(props) => (
          <PopperComponent
            {...props}
            onAppsPage={onAppsPage}
            width={inputWidth}
            visible={!!searchValue && !autocompleteValue}
            isCompact={isCompact}
            id='autocomplete-popper'
          />
        )}
      />
    </div>
  );
};
