import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';

import Button from 'src/components/ButtonProgress';

const LotsListButton = ({
  startIcon,
  text,
  tooltip,
  id,
  style = {},
  ...otherProps
}) => {
  return (
    <Tooltip title={tooltip}>
      <span>
        <Button
          id={`js-core-prospect-map-${id}-button`}
          variant='contained'
          color='primary'
          component={'div'}
          style={{
            textTransform: 'none',
            pointerEvents: 'all',
            ...style,
          }}
          {...otherProps}
          wrapperProps={{
            id: `js-core-prospect-map-${id}-button-wrapper`,
          }}
          startIcon={startIcon}
        >
          {text}
        </Button>
      </span>
    </Tooltip>
  );
};

export default LotsListButton;
