import { LABELS_FONT, LAYERS } from '../const';
import { LayerProps } from 'src/components/Map/types';
import { Visibility } from 'mapbox-gl';

function getLines({ visibility }: GetLayersArgs): LayerProps {
  return {
    id: LAYERS.SCHOOLS_DISTRICTS_LINE,
    source: 'saz',
    'source-layer': 'saz',
    type: 'line',
    paint: {
      'line-color': 'hsl(150, 80%, 50%)',
      'line-width': ['interpolate', ['linear'], ['zoom'], 7, 0.5, 12, 1],
    },
    layout: {
      visibility,
    },
  };
}

function getFill({ visibility }: GetLayersArgs): LayerProps {
  return {
    id: LAYERS.SCHOOLS_DISTRICTS_FILL,
    source: 'saz',
    'source-layer': 'saz',
    type: 'fill',
    paint: {
      'fill-color': 'hsla(0, 0%, 0%, 0)',
    },
    layout: {
      visibility,
    },
  };
}

function getLabels({ visibility }: GetLayersArgs): LayerProps {
  return {
    id: LAYERS.SAZ_LABELS,
    source: 'saz',
    'source-layer': 'saz-labels',
    type: 'symbol',
    layout: {
      'text-field': '{label}',
      'text-size': ['interpolate', ['linear'], ['zoom'], 10, 12, 14, 24],
      'text-allow-overlap': true,
      'text-ignore-placement': true,
      'text-font': LABELS_FONT,
      visibility,
    },
    paint: {
      'text-color': 'hsl(150, 80%, 30%)',
      'text-halo-color': 'hsl(20, 5.9%, 90%)',
      'text-halo-width': 2,
    },
  };
}

type GetLayersArgs = {
  visibility?: Visibility;
  schoolsDistricts?: string[];
};

function getSchoolsLayers({ visibility }: GetLayersArgs): LayerProps[] {
  return [
    getLines({ visibility }),
    getFill({ visibility }),
    getLabels({ visibility }),
  ];
}

export default getSchoolsLayers;
