import React from 'react';
import Typography from '@material-ui/core/Typography';
import RCAComps from './RCAComps';

const RCACompsList = ({ rcaCompsList, getCherreParcelGemo }) => {
  return (
    <span>
      {rcaCompsList?.length ? (
        <RCAComps
          rcaCompsList={rcaCompsList}
          getCherreParcelGemo={getCherreParcelGemo}
        />
      ) : (
        <Typography variant='h6'>
          No RCA data found for this location.
        </Typography>
      )}
    </span>
  );
};

export default RCACompsList;
