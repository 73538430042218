import {
  Box,
  CircularProgress,
  ClickAwayListener,
  IconButton,
  Typography as MUITypography,
  withStyles,
} from '@material-ui/core';
import { get, isNumber } from 'lodash';
import React, { useRef, useCallback, useState } from 'react';
import { Popup, PopupProps } from 'react-map-gl';
import useLocation from 'src/hooks/useLocation';

import Truncate from 'src/components/Truncate';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Close from '@material-ui/icons/Close';
import Launch from '@material-ui/icons/Launch';
import { gridSettings, noValue } from './gridSettings';
import { useStyles } from './styles';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { useLeases } from 'src/products/core-prospect/hooks/useLeases';
import ExternalLeaseCompIcon from './icons/ExternalLeaseCompIcon';
import InternalLeaseCompIcon from './icons/InternalLeaseCompIcon';
import BuildingLeaseCompIcon from './icons/BuildingLeaseCompIcon';
import numberWithCommas from 'src/utils/numberWithCommas';
import { useRecoilValueLoadable } from 'recoil';
import { taxAssessorByLocationQuery } from './query';
import { Link } from 'react-router-dom';

const Typography = withStyles({
  root: { fontSize: 12 },
})(MUITypography);

const TitleTypography = withStyles({
  root: {
    fontSize: 16,
    fontWeight: 600,
  },
})(Typography);

const BoldTypography = withStyles({
  root: { fontWeight: 700 },
})(Typography);

export const EqtLeaseCompsPopup: React.VFC<{
  onClose: () => void;
  latitude: number;
  longitude: number;
}> = ({ onClose, latitude, longitude }) => {
  const isComponentAvailable = useFeatureFlag('isEqtDealsIntegrationAvailable');

  const taxAssessor = useRecoilValueLoadable(
    taxAssessorByLocationQuery({
      lat: latitude,
      lon: longitude,
    })
  );

  if (!isComponentAvailable) {
    return null;
  }

  const location = useLocation();
  const [page, setPage] = useState(0);

  const ref = useRef<HTMLDivElement>(null);

  const onClickOutside = useCallback(() => {
    onClose();
  }, []);

  const eqtLeasesData = useLeases({
    latitude: get(location, 'query.latitude') || 0,
    longitude: get(location, 'query.longitude') || 0,
    compType: get(location, 'query.compType'),
    fileVersion: get(location, 'query.fileVersion'),
  });

  const popupProps: PopupProps = {
    latitude,
    longitude,
    closeOnClick: false,
    closeButton: false,
    captureScroll: true,
    captureDrag: true,
    captureClick: true,
    onClose,
    anchor: 'bottom',
  };

  const leases = eqtLeasesData.valueMaybe() || [];
  const leaseRecord = leases[page] || {};
  const totalPages = leases.length;

  const classes = useStyles({
    loading: eqtLeasesData.state === 'loading',
  });

  if (!latitude || !longitude) {
    return null;
  }

  const isBuilding = leaseRecord.comp_type === 'Building';
  const isExternal = leaseRecord.comp_type === 'External';
  const isInternal = leaseRecord.comp_type === 'Internal';
  const isPaginationAvailable = isInternal || isExternal;
  const isTaxAssessorLoading = taxAssessor.state === 'loading';
  const taxAssessorItem = taxAssessor.valueMaybe()?.tax_assessor_point_v2?.[0];

  const address = [
    leaseRecord.address,
    leaseRecord.city,
    leaseRecord.state_region,
    leaseRecord.postal_code,
  ]
    .filter(Boolean)
    .join(', ');
  return (
    <ClickAwayListener key={eqtLeasesData.state} onClickAway={onClickOutside}>
      <div>
        <Popup offsetTop={-20} {...popupProps}>
          <div className={classes.root} ref={ref}>
            {eqtLeasesData.state === 'loading' ? (
              <CircularProgress size={22} />
            ) : null}
            {eqtLeasesData.state === 'hasError'
              ? 'Failed to load lease data'
              : null}
            {eqtLeasesData.state === 'hasValue' ? (
              <Box padding={0}>
                <IconButton
                  color='primary'
                  onClick={onClose}
                  style={{ position: 'absolute', top: 5, right: 5 }}
                  size='small'
                >
                  <Close />
                </IconButton>
                <Typography
                  gutterBottom
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {isBuilding ? (
                    <BuildingLeaseCompIcon className={classes.leaseIcon} />
                  ) : null}
                  {isExternal ? (
                    <ExternalLeaseCompIcon className={classes.leaseIcon} />
                  ) : null}
                  {isInternal ? (
                    <InternalLeaseCompIcon className={classes.leaseIcon} />
                  ) : null}
                  {isBuilding ? 'EQT Building' : null}
                  {isExternal ? 'EXTERNAL LEASE COMP' : null}
                  {isInternal ? 'INTERNAL LEASE COMP' : null}
                </Typography>
                <div className={classes.divider} />
                <Link
                  target='_blank'
                  onClick={(e) => {
                    if (!taxAssessorItem) {
                      e.preventDefault();
                    }
                  }}
                  to={
                    taxAssessorItem
                      ? `/properties/${taxAssessorItem?.cherre_parcel_id}/${taxAssessorItem?.tax_assessor_id}`
                      : ''
                  }
                >
                  <TitleTypography className={classes.address} color='primary'>
                    <span title={address} className={classes.addressText}>
                      {address}
                    </span>
                    {isTaxAssessorLoading ? (
                      <CircularProgress
                        className={classes.addressIcon}
                        color='primary'
                        size={16}
                      />
                    ) : null}
                    {taxAssessorItem ? (
                      <Launch className={classes.addressIcon} color='primary' />
                    ) : null}
                  </TitleTypography>
                </Link>
                {isBuilding && isNumber(Number(leaseRecord.mla_avg_rent)) ? (
                  <Typography>
                    MLA AVG Rent/SF: $
                    {Number(leaseRecord.mla_avg_rent).toFixed(2)}
                  </Typography>
                ) : null}
                {isInternal || isExternal ? (
                  <>
                    <div className={classes.divider} />
                    <span>
                      <BoldTypography display='inline'>
                        Tenant:&nbsp;
                      </BoldTypography>
                      <Typography display='inline'>
                        {leaseRecord.tenant || noValue}
                      </Typography>
                    </span>
                  </>
                ) : null}
                {isInternal || isExternal ? (
                  <>
                    <div className={classes.divider} />
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                      }}
                    >
                      {gridSettings.map((item, index) => {
                        return (
                          <span key={index}>
                            <BoldTypography display='inline'>
                              {item.label}:&nbsp;
                            </BoldTypography>
                            <Typography display='inline'>
                              {item.format
                                ? item.format(
                                    leaseRecord?.[item.field],
                                    leaseRecord
                                  )
                                : leaseRecord?.[item.field]}
                            </Typography>
                          </span>
                        );
                      })}
                    </div>
                  </>
                ) : null}
                {isBuilding ? (
                  <>
                    <div className={classes.divider} />
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '0.8fr 1fr 1fr',
                      }}
                    >
                      <BoldTypography>Tenant</BoldTypography>
                      <BoldTypography
                        style={{ textAlign: 'right', marginRight: 30 }}
                      >
                        Occupied SF
                      </BoldTypography>
                      <BoldTypography
                        style={{
                          marginBottom: 2,
                          textAlign: 'right',
                          marginRight: 50,
                        }}
                      >
                        Base Rent/SF
                      </BoldTypography>
                      {leases.map((leaseRecord) => {
                        return (
                          <>
                            <Typography style={{ maxWidth: 160 }}>
                              <Truncate
                                title={leaseRecord.in_place_tenant || noValue}
                              >
                                {leaseRecord.in_place_tenant || noValue}
                              </Truncate>
                            </Typography>
                            <Typography
                              style={{ textAlign: 'right', marginRight: 30 }}
                            >
                              {leaseRecord.building_size_sf
                                ? `${numberWithCommas(
                                    leaseRecord.building_size_sf
                                  )}`
                                : noValue}
                            </Typography>
                            <Typography
                              style={{ textAlign: 'right', marginRight: 50 }}
                            >
                              {leaseRecord.in_place_base_rent_per_square_unit
                                ? `$${numberWithCommas(
                                    leaseRecord.in_place_base_rent_per_square_unit.toFixed(
                                      2
                                    )
                                  )}`
                                : noValue}
                            </Typography>
                          </>
                        );
                      })}
                    </div>
                  </>
                ) : null}
                {leaseRecord.comments ? (
                  <>
                    <div className={classes.divider} />
                    <Typography>
                      <b>Comments</b>
                    </Typography>
                    <Typography>
                      <Truncate>{leaseRecord.comments}</Truncate>
                    </Typography>
                  </>
                ) : null}
                {isPaginationAvailable ? (
                  <>
                    <div className={classes.divider} />
                    <div className={classes.footer}>
                      <span />
                      {totalPages > 1 ? (
                        <div className={classes.pagination}>
                          {page + 1} of {totalPages}
                          <IconButton
                            color='primary'
                            onClick={() => {
                              const newPage =
                                page - 1 < 0 ? totalPages - 1 : page - 1;
                              setPage(newPage);
                            }}
                            size='small'
                          >
                            <ChevronLeft />
                          </IconButton>
                          <IconButton
                            color='primary'
                            onClick={() => {
                              const newPage =
                                page + 1 > totalPages - 1 ? 0 : page + 1;
                              setPage(newPage);
                            }}
                            size='small'
                          >
                            <ChevronRight />
                          </IconButton>
                        </div>
                      ) : null}
                    </div>
                  </>
                ) : null}
              </Box>
            ) : null}
          </div>
        </Popup>
      </div>
    </ClickAwayListener>
  );
};
