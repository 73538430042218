import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gap: 4,
    marginBottom: 3,
  },
}));
