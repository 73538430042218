import React from 'react';
import { connect } from 'react-redux';
import { Chip } from '@material-ui/core';

const PropertyChips = ({ selectedLists, limit }) => {
  return (
    <>
      {selectedLists.slice(0, limit).map((list, index) => (
        <Chip
          size='small'
          key={index}
          style={{ maxWidth: 260, marginRight: 5 }}
          label={<span title={list.label}>{list.label}</span>}
        />
      ))}
      {selectedLists.length > limit && (
        <Chip
          size='small'
          style={{ marginRight: 5 }}
          label={`+${selectedLists.length - limit}`}
        />
      )}
    </>
  );
};

function mapStateToProps(state) {
  const {
    coreProspect: {
      propertyLists: {
        propertyLists: { value: propertyLists },
        propertyListEntries: { value: propertyListEntries },
      },
    },
  } = state;

  const selectedLists = (propertyLists || [])
    .filter((list) => {
      return propertyListEntries.some((entry) => entry.list_id === list.id);
    })
    .map((list) => ({
      label: list.name,
      value: list.id,
    }));

  return { selectedLists };
}

export default connect(mapStateToProps)(PropertyChips);
