import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  liveContainer: {
    '@media print': {
      display: 'none !important',
    },
    zIndex: 101,
    position: 'sticky',
    top: 0,
    backgroundColor: 'white',
    display: 'grid',
    padding: '15px 12px',
    gap: 10,
    justifyContent: 'space-between',
    borderBottom: `3px solid ${theme.palette.grey[200]}`,
    '& .address': {
      display: 'grid',
      gridTemplateColumns: '1fr',
    },
    '& .actions': {
      display: 'grid',
      gridTemplateColumns: 'auto auto auto auto auto',
      gap: 5,
      justifyContent: 'flex-end',
      justifyItems: 'end',
      alignItems: 'center',
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
      '& .address': {
        minWidth: '100%',
      },
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'auto 1fr auto',
      '& .address': {
        minWidth: '300px',
      },
    },
  },
  container: {
    breakInside: 'avoid',
    padding: 12,
    display: 'grid',
    gap: 12,
  },
  headerContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    alignItems: 'baseline',
  },
  logo: {
    width: 100,
  },
  date: {
    fontWeight: 500,
    marginLeft: 25,
  },
  delimiter: {
    height: 5,
    background: '#000',
  },
  addressWrapper: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  address: {
    marginRight: 10,
  },
  addresssDeal: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridGap: '10px',
    alignItems: 'center',
  },
  unitSelectorWrap: {
    display: 'flex',
    borderBottom: `1px solid ${theme.palette.text.primary}`,
    cursor: 'pointer',
    marginLeft: 3,
    position: 'relative',
    top: 1,
  },
  chevrone: {
    transform: 'rotate(270deg)',
  },
}));
