export const GET_LOT_OWNER_DATA = () => {
  return /* GraphQL */ `
    query getLotOwnerData($ids: [numeric!]!) {
      tax_assessor_v2(where: { tax_assessor_id: { _in: $ids } }) {
        tax_assessor_id
        usa_owner_unmask: usa_owner_unmask_v2__tax_assessor_id {
          cherre_address__address {
            display_address
          }
          owner_name
          owner_type
        }
        tax_assessor_owner_v2__tax_assessor_id {
          owner_type
          owner_name
        }
        mailing_state
        mailing_zip
        mailing_city
        mailing_address
      }
    }
  `;
};
