import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import SaveButton from '../../../Buttons/SaveButton';
import { Button } from '@material-ui/core';
import { push } from 'connected-react-router';

const useStyles = makeStyles(() => ({
  root: {
    padding: 16,
    display: 'flex',
    justifyContent: 'end',
    borderTop: '1px solid #e0e0e0',
  },
}));

const Footer: React.VFC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  return (
    <div className={classes.root}>
      <div>
        <SaveButton />
        <Button
          id='js-core-prospect-apply-filters-button'
          variant='contained'
          onClick={async () => {
            dispatch(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              push({
                pathname: location.pathname,
                query: {
                  sidebar: 'sidebar-lots-list',
                },
              })
            );
          }}
          color='primary'
          style={{
            marginLeft: 15,
            textTransform: 'capitalize',
            pointerEvents: 'all',
          }}
        >
          Done
        </Button>
      </div>
    </div>
  );
};

export default Footer;
