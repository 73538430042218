import { LayerProps } from 'src/components/Map/types';
import { mapClusterColor } from 'src/const/theme';

import { handleClick } from './utils';
import { LAYERS, SOURCES } from '../const';
import { OnLayerClick } from '../types';

const circleColor = mapClusterColor || 'hsl(315, 80%, 24%)';

type GetLayersArg = {
  onClick: OnLayerClick;
};

function getClusterCirclesText(): LayerProps {
  return {
    id: LAYERS.CLUSTER_TEXT,
    source: SOURCES.MAP_CLUSTERS.name,
    type: 'symbol',
    layout: {
      'text-field': '{label}',
      'text-font': ['Montserrat Bold', 'Arial Unicode MS Bold'],
      'text-size': 13,
      'text-allow-overlap': true,
    },
    paint: {
      'text-color': '#ffffff',
    },
  };
}

function getClusterCircles({ onClick }: GetLayersArg): LayerProps {
  return {
    id: LAYERS.CLUSTER,
    source: SOURCES.MAP_CLUSTERS.name,
    type: 'circle',
    onClick: (e) => handleClick(e, LAYERS.CLUSTER, onClick),
    paint: {
      'circle-color': circleColor,
      'circle-stroke-color': 'hsl(0, 0%, 100%)',
      'circle-stroke-width': [
        'case',
        ['to-boolean', ['feature-state', 'hover']],
        3,
        1,
      ],
      'circle-radius': [
        'step',
        ['get', 'count'],
        10,
        10,
        15,
        100,
        20,
        1000,
        25,
        10000,
        30,
        100000,
        35,
        1000000,
        40,
      ],
    },
  };
}

function getClustersLayers({ onClick }: GetLayersArg): LayerProps[] {
  return [getClusterCircles({ onClick }), getClusterCirclesText()];
}

export default getClustersLayers;
