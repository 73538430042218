import * as TYPES from './types';
import * as QUERY_TYPES from '../query/types';
import constants from 'src/const';
import { FetchStatus } from '../query/reducers';
import { Reducer } from 'redux';
import { get } from 'lodash';

type Result = {
  cherre_reis_boundaries_pk: string;
  submarket_name: string;
  submarket_id: number;
  sector: string;
  metro_code: string;
};

type ReisSubmarketsState = {
  results: Result[];
  fetchStatus: FetchStatus;
  countFetchStatus: FetchStatus;
  count: number;
  rowsPerPage: number;
  offset: number;
  after: Result | null;
  before: Result | null;
  exec_query_timestamp: number | null;
  exec_count_query_timestamp: number | null;
  orderBy: {
    field: string;
    direction: string;
  };
};

const initialState: ReisSubmarketsState = {
  results: [],
  fetchStatus: {},
  count: 0,
  countFetchStatus: {},
  rowsPerPage: 25,
  offset: 0,
  after: null,
  before: null,
  exec_query_timestamp: null,
  exec_count_query_timestamp: null,
  orderBy: {
    field: 'cherre_reis_boundaries_pk',
    direction: constants.ORDER_BY_DIRECTION.ASC,
  },
};

type ReisSubmarketsReducer = Reducer<ReisSubmarketsState>;

export const reisSubmarkets: ReisSubmarketsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case QUERY_TYPES.RESET_ALL_FILTERS: {
      return initialState;
    }

    case TYPES.CHANGE_OFFSET: {
      const result = {
        ...state,
        offset: action.offset,
      };

      if (result.offset > state.offset) {
        result.after = state.results[state.results.length - 1];
        result.before = null;
      } else if (result.offset < state.offset) {
        result.after = null;
        result.before = state.results[0];
      }

      return result;
    }

    case TYPES.CHANGE_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.rowsPerPage,
        offset: 0,
        after: null,
        before: null,
      };

    case TYPES.CHANGE_ORDER_BY: {
      const { orderBy } = action;

      return {
        ...state,
        orderBy,
        offset: 0,
        after: null,
        before: null,
      };
    }

    case TYPES.TABLE_RESULTS_REQUEST: {
      const { timestamp, reset } = action;

      return {
        ...state,
        exec_query_timestamp: timestamp,
        fetchStatus: { value: 'LOADING' },
        after: reset ? null : state.after,
        before: reset ? null : state.before,
        offset: reset ? 0 : state.offset,
      };
    }

    case TYPES.TABLE_RESULTS_SUCCESS: {
      const { timestamp } = action;

      if (timestamp !== state.exec_query_timestamp) {
        return state;
      }

      return {
        ...state,
        results: action.data.result,
        fetchStatus: { value: 'LOADED' },
      };
    }

    case TYPES.TABLE_RESULTS_FAILED: {
      const { timestamp } = action;

      if (timestamp !== state.exec_query_timestamp) {
        return state;
      }

      return {
        ...state,
        fetchStatus: { value: 'FAILED' },
      };
    }

    case TYPES.TABLE_RESULTS_COUNT_REQUEST: {
      const { timestamp } = action;

      return {
        ...state,
        exec_count_query_timestamp: timestamp,
        countFetchStatus: { value: 'LOADING' },
      };
    }

    case TYPES.TABLE_RESULTS_COUNT_SUCCESS: {
      const { timestamp } = action;

      if (timestamp !== state.exec_count_query_timestamp) {
        return state;
      }

      return {
        ...state,
        count: action.data.result,
        countFetchStatus: { value: 'LOADED' },
      };
    }

    case TYPES.TABLE_RESULTS_COUNT_FAILED: {
      const { timestamp } = action;

      if (timestamp !== state.exec_count_query_timestamp) {
        return state;
      }

      return {
        ...state,
        countFetchStatus: { value: 'FAILED' },
      };
    }

    case QUERY_TYPES.EXEC_COUNT_ALL_QUERY_REQUEST: {
      return {
        ...state,
        countFetchStatus: { value: 'LOADING' },
      };
    }
    case QUERY_TYPES.EXEC_COUNT_ALL_QUERY_SUCCESS: {
      return {
        ...state,
        count: get(action, 'data.result.3.1', 0),
        countFetchStatus: { value: 'LOADED' },
      };
    }

    default:
      return state;
  }
};
