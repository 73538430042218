import axios from 'axios';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRecoilCallback } from 'recoil';
import { teamsList } from 'src/products/customerAdmin/pages/Teams/recoil';
import { showModal } from 'src/products/shell/redux/modals/actions';
import { useSnackbarCherre } from './useSnackbarCherre';

export const useTeamCreateModal = (
  teamId: number | undefined,
  onRequestClose: () => void
) => {
  const [isSaving, setIsSaving] = useState(false);
  const { enqueueSnackbar } = useSnackbarCherre();

  const onClickAdd = useRecoilCallback(
    ({ refresh }) =>
      async ({ name, memberIds }: { name: string; memberIds: number[] }) => {
        setIsSaving(true);

        try {
          await axios.post(`/api/v1/teams`, {
            name,
            memberIds,
          });

          enqueueSnackbar(`Group successfully created`, {
            variant: 'success',
          });

          refresh(teamsList);

          onRequestClose();
        } catch (e) {
          console.error(e);
          enqueueSnackbar('Group creation failed', {
            variant: 'error',
          });
          setIsSaving(false);
        }
      },
    [setIsSaving]
  );

  const onClickEditDone = useRecoilCallback(
    ({ refresh }) =>
      async ({ name }: { name: string }) => {
        setIsSaving(true);

        try {
          await axios.put(`/api/v1/teams/${teamId}`, {
            name,
          });

          enqueueSnackbar(`Group successfully renamed`, {
            variant: 'success',
          });

          refresh(teamsList);

          onRequestClose();
        } catch (e) {
          console.error(e);
          enqueueSnackbar('Failed to rename group', {
            variant: 'error',
          });
          setIsSaving(false);
        }
      },
    [setIsSaving, teamId]
  );

  return {
    onClickAdd,
    onClickEditDone,
    isSaving,
  };
};

export const useTeamCreateOrEdit = () => {
  const dispatch = useDispatch();

  return useRecoilCallback(
    () => async (teamId?: number, teamName?: string) => {
      dispatch(showModal('TEAMS_CREATE', { teamId, teamName }));
    },
    []
  );
};
