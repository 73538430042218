import React from 'react';

import pluralize from 'pluralize';
import { Link, makeStyles, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import Close from '@material-ui/icons/Close';
import GoogleStreetViewStatic from 'src/components/GoogleStreetViewStatic';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'rgba(0,0,0,0.3)',
    position: 'relative',
    height: 150,
    cursor: 'auto',
  },
  close: {
    width: 36,
    height: 36,
    padding: 6,
    top: 10,
    right: 10,
    color: theme.palette.primary.main,
  },
  round: {
    borderRadius: 20,
    padding: 6,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    background: '#ffffff',
    position: 'absolute',
    zIndex: 9999,
    cursor: 'pointer',
    '&:hover': {
      background: grey[200],
    },
  },
  moreInfo: {
    height: 36,
    top: 10,
    right: 60,
    display: 'flex',
    alignItems: 'center',
    padding: '6px 10px',
    color: theme.palette.primary.main,
  },
  overlay: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 18,
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 50,
    padding: 10,
    color: '#fff',
    backgroundImage:
      'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.76))',
  },
}));

type Props = {
  location: {
    latitude?: number;
    longitude?: number;
  };
  width: number;
  onHrefClick?: () => void;
  count: number | undefined;
  onClose?: () => void;
  height?: number;
  href?: string;
};

export const StreetView: React.FC<Props> = ({
  location = {},
  width,
  onHrefClick,
  count,
  onClose,
  height,
  href,
}) => {
  const { latitude, longitude } = location;

  const classes = useStyles();

  return (
    <div style={{ width, height }} className={classes.root}>
      {onClose && (
        <Close
          className={`${classes.close} ${classes.round}`}
          onClick={onClose}
        />
      )}
      {href && (
        <Link onClick={onHrefClick} href={href} target='_blank'>
          <Typography className={`${classes.round} ${classes.moreInfo}`}>
            <b>More info</b>
          </Typography>
        </Link>
      )}
      <GoogleStreetViewStatic
        latitude={latitude || 0}
        longitude={longitude || 0}
      />
      {(count || 0) > 1 && (
        <div className={classes.overlay}>
          <Typography variant='subtitle1'>
            {count} Tax {pluralize('record', count)} on this lot
          </Typography>
        </div>
      )}
    </div>
  );
};
