import { gql, paginate, GQL, AllAssigned } from 'src/utils/recoil/gql';
import {
  MapPopupUsaLotPropertiesQuery,
  MapPopupUsaLotPropertiesQueryVariables,
  MapPopupUsaLotPropertiesCountQuery,
  MapPopupUsaLotPropertiesCountQueryVariables,
} from './__generated__/query';

export const mapPopupUsaLotPropertiesCountQuery = gql`
  query MapPopupUsaLotPropertiesCount($id: String!) {
    tax_assessor_v2_aggregate(where: { cherre_parcel_id: { _eq: $id } }) {
      aggregate {
        count
      }
    }
  }
` as GQL<
  AllAssigned<MapPopupUsaLotPropertiesCountQuery>,
  MapPopupUsaLotPropertiesCountQueryVariables
>;

const mapPopupUsaLotPropertiesQuery = gql`
  query MapPopupUsaLotProperties(
    $id: String!
    $offset: Int!
    $limit: Int!
    $orderBy: [tax_assessor_v2_order_by!]
  ) {
    tax_assessor_v2(
      limit: $limit
      offset: $offset
      where: { cherre_parcel_id: { _eq: $id } }
      order_by: $orderBy
    ) {
      recorder_v2_summary_v2__tax_assessor_id {
        cherre_latest_deed_date
        cherre_latest_deed_amount
      }
      tax_assessor_id
      property_use_code_mapped
      property_use_standardized_code
      lot_size_sq_ft
      cherre_parcel_id
      tax_assessor_id
      address
      latitude
      longitude
      units_count
      year_built
      zone_code
      lot_size_acre
      building_sq_ft
      city
      state
      zip
      cherre_address__property_address {
        reis_property_attributes__address {
          sector
          propertytype
          sizesqft
          sizeunit
          yearbuilt
        }
      }
    }
  }
` as GQL<
  AllAssigned<MapPopupUsaLotPropertiesQuery>,
  MapPopupUsaLotPropertiesQueryVariables
>;

export const paginatedUsaLotProperties = paginate({
  key: 'PAGINATED_USA_LOT_PROPERTIES',
  query: mapPopupUsaLotPropertiesQuery,
  fieldName: 'tax_assessor_v2',
});
