import React from 'react';
import { RCAFilterChips } from './components';
import ViewToggleButtonGroup from 'src/products/core-prospect/search-pages/components/NavBar/ViewToggleButtonGroup';

const RCAMapLotsPageHeader = ({
  setShowMap,
  showMap,
  view,
  updateSearch,
  setUpdateSearch,
  rcaFilterFunctions,
  clearFilter,
}) => {
  return (
    <div
      className='print-hide'
      style={{ display: 'flex', alignItems: 'center' }}
    >
      <RCAFilterChips
        updateSearch={updateSearch}
        setUpdateSearch={setUpdateSearch}
        rcaFilterFunctions={rcaFilterFunctions}
        clearFilter={clearFilter}
      />
      <div id='map-table-view-switch' style={{ marginLeft: 'auto' }}>
        <ViewToggleButtonGroup
          setShowMap={setShowMap}
          showMap={showMap}
          view={view}
        />
      </div>
    </div>
  );
};

export default RCAMapLotsPageHeader;
