import geojsonBoundingBox from 'geojson-bounding-box';
import { RootState } from 'react-redux';
import { MapBounds } from 'src/components/Map/index.new';
import getParcelsPointsBounds from 'src/products/core-prospect/components/ReactMap/getParcelsPointsBounds';
import {
  CORE_PROSPECT_VIEWS,
  RESULTS_TYPES,
} from 'src/products/core-prospect/constants';
import { recenterMap } from 'src/products/core-prospect/redux/map/actions';
import { getSearchParametersFeature } from 'src/products/core-prospect/search-pages/selectors';
import { execCountQueries } from './execCountQueries';
import { execMapCardsQuery } from './execMapCardsQuery';
import { execMapLotsQuery } from './execMapLotsQuery';
import { execTableLotsQuery } from './execTableLotsQuery';
import { execTableOwnersQuery } from './execTableOwnersQuery';
import { execTableReisSubmarketsQuery } from './execTableReisSubmarketsQuery';
import { execTableZipcodesQuery } from './execTableZipcodesQuery';

export type ExecQuery = {
  id?: string;
  viewportOnly: boolean;
  count: boolean;
  results: boolean;
  cards: boolean;
  rebound: boolean;
  customZoom?: number;
};

export const reboundOnSearch = () => async (dispatch, getState) => {
  let bounds: MapBounds | void;

  const state: RootState = getState();
  const feature = getSearchParametersFeature(state);

  if (feature) {
    const [lng1, lat1, lng2, lat2] = geojsonBoundingBox(feature);
    bounds = [
      [lng2, lat2],
      [lng1, lat1],
    ];
    dispatch(recenterMap({ bounds }));
  } else {
    const mapResults = state.coreProspect.mapPinResults;
    if (mapResults.resultType === 'clusters' && mapResults.lots.length === 1) {
      const latitude = mapResults.lots[0].latitude;
      const longitude = mapResults.lots[0].longitude;
      dispatch(recenterMap({ latitude, longitude, zoom: 14 }));
    } else {
      const lots = mapResults.lots;
      bounds = getParcelsPointsBounds(lots)!;
      dispatch(recenterMap({ bounds }));
    }
  }
};

export const execQuery = ({
  viewportOnly,
  count,
  results,
  cards,
  rebound,
  customZoom,
  id,
}: ExecQuery) => {
  if (id) {
    console.log('execQuery:', id);
  }
  return async (dispatch, getState) => {
    const state: RootState = getState();
    const viewerName = state.coreProspect.view.viewerName;
    const resultsType = state.coreProspect.view.resultsType;
    const isCardsVisible = state.coreProspect.view.isCardsVisible;

    if (count) {
      await dispatch(
        execCountQueries({
          viewportOnly,
          rebound,
        })
      );

      if (results && rebound) {
        dispatch(reboundOnSearch());
      }
    }

    if (resultsType === RESULTS_TYPES.OWNERS) {
      dispatch(
        execTableOwnersQuery({
          isCount: false,
          isExport: false,
          paginate: false,
          viewportOnly,
        })
      );
      return;
    }

    if (resultsType === RESULTS_TYPES.MARKETS) {
      dispatch(
        execTableZipcodesQuery({
          isCount: false,
          isExport: false,
          paginate: false,
          viewportOnly,
        })
      );

      dispatch(
        execTableReisSubmarketsQuery({
          isCount: false,
          isExport: false,
          paginate: false,
          viewportOnly,
        })
      );
      return;
    }

    if (resultsType === RESULTS_TYPES.TAX_RECORDS) {
      const isTableVisible = viewerName === CORE_PROSPECT_VIEWS.TABLE;

      if (isTableVisible) {
        dispatch(
          execTableLotsQuery({
            isExport: false,
            paginate: false,
            selectedRows: null,
            viewportOnly,
          })
        );
      } else {
        if (results && !count) {
          await dispatch(
            execMapLotsQuery({
              viewportOnly,
              customZoom,
              rebound,
            })
          );
          if (rebound) {
            dispatch(reboundOnSearch());
          }
        }

        if (cards && isCardsVisible) {
          dispatch(
            execMapCardsQuery({
              paginate: false,
              viewportOnly,
            })
          );
        }
      }
    }
  };
};
