import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPropertyLists } from 'src/products/core-prospect/redux/propertyLists/actions';

export const usePropertyLists = () => {
  const dispatch = useDispatch();

  const propertyLists = useSelector(
    (state) => state.coreProspect.propertyLists.propertyLists.value
  );

  const propertyListsState = useSelector(
    (state) => state.coreProspect.propertyLists.propertyLists.fetchStatus.value
  );

  const [isLoading, setLoading] = useState(true);

  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (propertyListsState === 'LOADED' && !refresh) {
      setLoading(false);
      return;
    }

    setLoading(true);
    setRefresh(false);
    dispatch(getPropertyLists());
  }, [propertyListsState, setLoading, setRefresh, refresh]);

  const propertyListsRefresh = useCallback(() => {
    setRefresh(true);
  }, [setRefresh]);

  return {
    propertyLists,
    propertyListsRefresh,
    isLoading,
  };
};
