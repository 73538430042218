import React from 'react';

import Typography from '@material-ui/core/Typography';
import TreppModal from '../TreppModal';
import useIsCompact from 'src/hooks/useIsCompact';

const TimelineLoanText = ({ item }) => {
  const isCompact = useIsCompact();

  return (
    <div
      style={{ display: 'flex', flexDirection: isCompact ? 'column' : 'row' }}
    >
      <div>
        <Typography variant='body2'>
          <span>{`${item.original_term_of_loan} mo`}</span>
          <span> &middot; </span>
          <span>{`${item.current_note_rate}%`}</span>
          <span> &middot; </span>
          <span>{`LTV ${item.derived_ltv}%`}</span>
          <span> &middot; </span>
          <span>
            <span>{item.master_servicer}</span>
            {item.transaction_id ? (
              <span>
                <span
                  style={{
                    marginRight: 8,
                    marginLeft: 8,
                  }}
                >
                  &rarr;
                </span>
                <span>{item.transaction_id}</span>
              </span>
            ) : null}
          </span>
          <TreppModal item={item} />
        </Typography>
      </div>
      <div style={{ color: '#2e5caa', marginLeft: 'auto' }}>
        <Typography variant='body2'>by Trepp</Typography>
      </div>
    </div>
  );
};

export default TimelineLoanText;
