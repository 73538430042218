import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import List from 'src/components/InteractiveList/List';
import ListIcon from 'src/components/InteractiveList/ListIcon';
import PropertyChips from './PropertyChips';
import { usePropertyListPopover } from '../../hooks/usePropertyListPopover';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  list: {
    minWidth: 350,
  },
}));

const PropertyList: React.VFC<{
  property_id: number;
  property_code_type: string;
  disabled?: boolean;
  showChips: boolean;
  noStyle?: boolean;
  buttonComponent?: React.ReactNode;
  where?: string;
}> = ({
  property_id,
  property_code_type,
  disabled = false,
  showChips,
  noStyle = false,
  buttonComponent = undefined,
  where = undefined,
}) => {
  const classes = useStyles();

  const user = useSelector((state) => state.user.profile.value);

  const [enabled, setEnabled] = useState(false);

  const {
    handleItemSelect,
    handleItemDeselect,
    handleCreate,
    items,
    selected,
  } = usePropertyListPopover(
    String(property_id),
    property_code_type,
    enabled,
    where
  );

  if (!user.featureFlags?.cp_PropertyLists) {
    return null;
  }

  return (
    <div className={!noStyle ? classes.root : undefined}>
      {!showChips ? null : <PropertyChips limit={2} />}
      <ListIcon
        title='Add to property lists'
        disableText='Property Lists Coming Soon'
        className={
          disabled ? 'js-core-prospect-property-list__icon-disabled' : ''
        }
        id='js-core-prospect-property-list__icon'
        disabled={disabled}
        component={buttonComponent}
        onOpen={() => setEnabled(true)}
        onClose={() => setEnabled(false)}
      >
        <div className={classes.list}>
          <List
            id='js-core-prospect-property-list__root'
            onItemCreate={handleCreate}
            items={items}
            selected={selected}
            onItemSelect={handleItemSelect}
            onItemDeselect={handleItemDeselect}
          />
        </div>
      </ListIcon>
    </div>
  );
};

export default PropertyList;
