import React from 'react';
import Typography from '@material-ui/core/Typography';

const Text = ({ children, display, variant, ...otherProps }) => {
  return (
    <Typography
      display={display || 'inline'}
      variant={variant || 'body1'}
      {...otherProps}
    >
      {children}
    </Typography>
  );
};

export default Text;
